import React from 'react';
import { Paper, Grid, Typography, makeStyles } from '@material-ui/core';
import { TextFieldLabel } from '../../../../../components';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            padding: 17,
            boxShadow: '0px 10px 25px #0000000A',
            borderRadius: 8
        },
    },
    title: {
        color: '#101010',
        fontSize: 12,
        fontFamily: 'pc_semibold',
        paddingBottom: 12
    }
}))

export const FrequencyDefinition = (props) => {
    const { parent_id, error } = props;
    const classes = useStyles(props);

    return (
        <div
            id={`${parent_id}-parent-div`}
            className={classes.root}>
            <Paper
                id={`${parent_id}-parent-paper`}
            >
                <Typography
                    id={`${parent_id}-title-typography`}
                    variant="body1" className={classes.title}>FREQUENCY DEFINITION</Typography>
                <Grid
                    id={`${parent_id}-parent-grid`}
                    container spacing={2}>
                    <Grid
                        id={`${parent_id}-frequencyCode-grid`}
                        item xs={12} sm={12} md={3}>
                        <TextFieldLabel
                            parent_id={"frequencyCode"}
                            label="Frequency Code"
                            required={true}
                            value={props?.data?.frequencyCode}
                            onChange={(data) => props?.onChangeState('frequencyCode', data?.replaceAll(/[.*+,><?^${}()"|[\]\\" "]/g,'')?.toLocaleUpperCase())}
                            error={error?.frequencyCode}
                            disabled={props?.isEdit}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-shortDescription-grid`}
                        item xs={12} sm={12} md={3}>
                        <TextFieldLabel
                            parent_id={"shortDescription"}
                            label="Short Description"
                            // required={true}
                            value={props?.data?.shortDescription}
                            onChange={(data) => props?.onChangeState('shortDescription', data)}
                        // error={}
                        error={error?.shortDescription}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-longDescription-grid`}
                        item xs={12} sm={12} md={6}>
                        <TextFieldLabel
                            parent_id={"longDescription"}
                            label="Long Description"
                            // required={true}
                            value={props?.data?.longDescription}
                            onChange={(data) => props?.onChangeState('longDescription', data)}
                        // error={}
                        error={error?.longDescription}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

FrequencyDefinition.propTypes = {
    data: PropTypes.object,
    onChangeState: PropTypes.func,
}

FrequencyDefinition.defaultProps = {
    data: {},
    onChangeState: null,
}