import React from 'react';
import { makeStyles, TextField,Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(()=>({
    root:{
       '& .MuiInputBase-root':{
           padding:'2px'
       },
       '& fieldset':{
        borderRadius:8,
        borderColor:'#E0E0E0'
       },
       '& input':{
        fontSize:12,
        fontFamily:'pc_medium',
        color:'#101010',
        padding:10
    }
    },
    label:{
        color:'#6F6F6F',
        fontSize:12,
        fontFamily:'pc_regular',
        marginBottom:8
    }
}))

export const DateTimeWithLabel = (props) =>{

    const classes = useStyles(props);

    return(
            <div className={classes.root}>
                {
                props.label && 
                <Typography className={classes.label}>{props?.label}
                    {props?.required && <span style={{ color: "red" }} >*</span>}
                </Typography>
                }
               <TextField
                    value={props?.value}
                    onChange={(e) => props?.onChange(e.target.value)}
                    variant={props?.variant}
                    fullWidth
                    required={props?.required}
                    type={props?.type}
                    error={props?.error}
                />
        </div>
    )
}



DateTimeWithLabel.propTypes={
    label:PropTypes.string,
    required:PropTypes.bool,
    error:PropTypes.bool,
    value:PropTypes.object,
    onChange:PropTypes.func,
    variant:PropTypes.oneOf('standard'|'outlined'|'filled'),
}

DateTimeWithLabel.defaultProps={
    required:false,
    label:'',
    value:'',
    onChange:null,
    variant:'outlined',
    error:false,
    type:"datetime-local"
}