import React from "react";
import { Div, Row, Col } from "qdm-component-library";
import { Button } from "@material-ui/core";
import TopNavbar from "../navbar/topNavBar";
import EntityCard from "./entitycard";
import LocationDetails from "./locationdetails";
import ContactDetails from "./contactdetails";
import ClinicInfo from "./clinicinfo";
import Pharmacy from "./pharmacy";
import GeoLocationCard from "./geolocation";
import AddressCard from "./addresscard";
import OverRide from "./override";
import WorkingDays from "./workingDays";
//import { withRouter } from "react-router-dom";
import { withAllContexts, withHoverDrawer } from "../../../HOCs";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import { uploadImage, valitationFunc } from "../../../utils";
import { routes } from "../../../router/routes";
import UploadProfile from "./uploadprofile";
import moment from "moment";
import { MemoryTwoTone } from "@material-ui/icons";

// const materialStyles = (theme) => ({});

// const Json = [
//   {
//     contactMode: {
//       name: "Contact Mode",
//       value: { label: "", value: "" },
//     },
//     countryCode: {
//       name: "",
//       value: { label: "", value: "" },
//     },
//     contactModeType: {
//       name: "Contact Mode Type",
//       value: "",
//     },
//     use: {
//       name: "Use",
//       value: { label: "", value: "" },
//     },
//     priority: {
//       name: "Priority",
//       value: { label: "", value: "" },
//     },
//   },
// ];

class AddLocation extends React.Component {
  //masterData = this?.props?.searchPractioner?.data?.[0];
  constructor(props) {
    super(props);
    this.state = {
      override_save: false,
      override_update: false,
      _key_id: null,
      masterData: {
        parent_location_id_description_data: [],
      },
      openOverRide: false,
      isEdit: false,
      editSingle: false,
      editDaterange: false,
      editindex: null,
      clinicDetailCard: false,
      pharmacyDetailCard: false,
      entityType: {},
      entityName: {},
      locationType: { },
      locationId: "",
      shortDescription: "",
      longDescription: "",
      operationalStatus: {},
      parentLocationIdandDescription: {},
      locationRoleType: { },
      speciality: {},
      clinicDetails: "",
      locationAddress: "",
      longitude: "",
      latitude: "",
      altitude: "",
      open_working_days_popup: false,
      workday_edit: false,
      is_visible: false,
      workingday_year: new Date().getFullYear() ,
      workingday_daySlots: [
        {
          day: "Mon",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Tue",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Wed",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Thu",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Fri",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Sat",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Sun",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
      ],
      contactDetails: [
        {
          contactMode: {
            name: "Contact Mode",
            value: { label: "", value: "" },
          },
          countryCode: {
            name: "",
            value: { label: "", value: "" },
          },
          contactModeType: {
            name: "Contact Mode Type",
            value: "",
          },
          use: {
            name: "Use",
            value: { label: "", value: "" },
          },
          priority: {
            name: "Priority",
            value: { label: "", value: "" },
          },
        },
      ],
      overRideCard: [],
      working_days_yes_no: "Yes",

      overRideInitial: [
        {
          type: "singleDate",
          selectDate: "",
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          type: "dateRange",
          fromDate: "",
          toDate: "",
          daySlots: [
            {
              day: "Mon",
              checked: false,
              timeSlots: [
                {
                  fromTime: "",
                  toTime: "",
                },
              ],
            },
            {
              day: "Tue",
              checked: false,
              timeSlots: [
                {
                  fromTime: "",
                  toTime: "",
                },
              ],
            },
            {
              day: "Wed",
              checked: false,
              timeSlots: [
                {
                  fromTime: "",
                  toTime: "",
                },
              ],
            },
            {
              day: "Thu",
              checked: false,
              timeSlots: [
                {
                  fromTime: "",
                  toTime: "",
                },
              ],
            },
            {
              day: "Fri",
              checked: false,
              timeSlots: [
                {
                  fromTime: "",
                  toTime: "",
                },
              ],
            },
            {
              day: "Sat",
              checked: false,
              timeSlots: [
                {
                  fromTime: "",
                  toTime: "",
                },
              ],
            },
            {
              day: "Sun",
              checked: false,
              timeSlots: [
                {
                  fromTime: "",
                  toTime: "",
                },
              ],
            },
          ],
        },
      ],
      photo: [
        {
          //_id: "",
          date: "",
          url: "",
          //id: "",
          fileName: "",
          fileid: "",
          filetype: "",
          objectid: "",
        },
      ],
      overRideType: "singleDate",
      //PHARMACY
      returnAllowed: false,
      otcAllowed: false,
      externalRxAllowed: false,
      storeCode: "",
      maxDaysForReturn: null,
      error:{},
      dateRangeArray : []
    };
    this.successfun = this.successfun.bind(this);
  }
  styles = {
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
  };
  componentDidMount = async () => {
    let urlParams = this.props?.location?.state?.Id ?? null;
    this.state._key_id = urlParams;
    this.setState({ ...this.state });
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Loading...",
    });

    await this.props?.LOCATION_MASTERS_ENTITYTYPE();
    //await this.props?.LOCATION_MASTERS_ENTITYNAME();
    await this.props?.LOCATION_MASTER_CONTACTMODE();
    await this.props?.LOCATION_MASTER_ISD();
    await this.props?.LOCATION_MASTER_USE();
    await this.props?.LOCATION_MASTER_PRIORITY();

    await this.props?.LOCATION_MASTERS_LOCATIONTYPE();
    await this.props?.LOCATION_MASTERS_OPERATIONALSTATUS();
    await this.props?.LOCATION_MASTERS_PARENT_LOCATION_ID_DESCRIPTION();
    await this.props?.LOCATION_MASTERS_LOCATIONROLETYPE();

    if (urlParams) {
      this.getLocationById(urlParams);
    }
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  };

  getLocationById = async (id) => {
    await this.props.LOCATION_MASTER_GET_BY_ID({ id: id });
    let entity_type_id = this.props.locationmaster_by_id?.data?.entityType?.coding?.[0]?._id;
    let d = await this.props?.LOCATION_MASTERS_ENTITYNAME({
      type: entity_type_id,
    });
    let data = this.props.locationmaster_by_id.data;
    
    let entityNameRes = data.entityNameId;
    //let entityName_ = { label: "", value: "" };
    let entity_Name = {};
    if (
      d.payload.data.length > 0
    ) {
      entity_Name = d.payload.data.find(
        (sys) => entityNameRes.indexOf(sys.value) > -1
      )
      // const entityNa = d.payload.data.map((val) => {
      //   // alert(val);
      //   if (val?.label === entityNameRes?.[0]?._id) {
      //     return entityName_.push(val);
      //   }
      // });
    }
    
    this.state = data;
    this.setState({
      ...this.state,
      entityName: entity_Name,
    });
    //let _id = this.props.locationmaster_by_id.data.entityName.id;
    // let _id = this.state?.entityName?.label;
    let _id = this.state?.entityName?.id;
    let speciality_ = { label: "", value: "" };
    if (_id !== "") {
      await this.props?.LOCATION_MASTER_SPECIALITY({ id: _id });
      //for getting selected Category
      
      let specialtyRes = data?.speciality;

      if (this.props.specialityData.data.length > 0 && specialtyRes.length > 0 && Array.isArray(specialtyRes)) {
        speciality_ = this.props.specialityData.data.find(({ label: id1 }) =>
          specialtyRes.some(({ _id: id2 }) => id2 === id1)
        );
      }

      // this.setState({
      //   ...this.state,
      //   speciality: speciality_,
      // });
    }
    let Clininc = data?.locationType?.value === "Clinic" ? true : false;
    let PharmacyDispense =
      data?.locationType?.value === "Pharmacy Dispense Location" ? true : false;


    this.setState({
      ...this.state,
      ...this.props.locationmaster_by_id.data,
      // entityName: entityName_.length > 0 ? entityName_[0] : {},
      speciality: speciality_,
      clinicDetailCard: Clininc,
      pharmacyDetailCard: PharmacyDispense,
    });
  };

  handleworkdayedit = () => {
    const state = this.state;
    state.open_working_days_popup = true;
    state.workday_edit = true;
    this.setState({ ...state });
  };

  handleworkdayClose = (fun, type) => {
    //;
    const state = JSON.parse(JSON.stringify(this.state));

    if (fun === "cancel") {
      state.working_days_yes_no = "Yes";
      state.open_working_days_popup = false;
      state.workingday_daySlots = [
        {
          day: "Mon",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Tue",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Wed",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Thu",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Fri",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Sat",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
        {
          day: "Sun",
          checked: true,
          timeSlots: [
            {
              fromTime: "",
              toTime: "",
            },
          ],
        },
      ];
    } else if (fun === "save") {
      state.working_days_yes_no = "No";
      state.is_visible = true;
      state.open_working_days_popup = false;
    } else if (fun === "update") {
      state.open_working_days_popup = false;
      state.working_days_yes_no = "No";
    }
    state.workday_edit = false;
    this.setState({ ...this.state, ...state });
  };

  getDaysBetweenTwoDays = (from, to) =>  {
    var d = new Date(from),
        t = new Date(to),
        a = [],
        y = ["Sun", 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    while (d < t) {
        a.push(y[d.getDay()]);
        d.setDate(d.getDate() + 1);
    }
    if (d.getDay() === t.getDay()) // include last day
        a.push(y[d.getDay()]);
    return a;
  }

  handleChangecheckbox = (event, i, day_i) => {
    debugger
    const state = JSON.parse(JSON.stringify(this.state)); 
    state.overRideInitial[i].daySlots[day_i].checked = event.target.checked
    this.setState({ ...this.state, ...state });
    debugger
  };

  onDateChange = (field, type, date, i) => {
    const state = JSON.parse(JSON.stringify(this.state));
    //let date_ = date ? moment(date).format("DD-MM-YYYY") : "";
    if (type === "add") {
      if (field === "selectDate") {
        state.overRideInitial[i].selectDate = date;
      } else if (field === "fromDate") {
        state.overRideInitial[i].fromDate = date;
      } else if (field === "toDate") {
        state.overRideInitial[i].toDate = date;
      }
      
      //NEW CODE
      if(state.overRideInitial[i].fromDate && state.overRideInitial[i].toDate) {
        if((moment(state.overRideInitial[i].fromDate) < moment(state.overRideInitial[i].toDate)) || (moment(state.overRideInitial[i].fromDate) === moment(state.overRideInitial[i].toDate))){
          //state.dateRangeArray = [];
          let a = this.getDaysBetweenTwoDays(state.overRideInitial[i].fromDate, state.overRideInitial[i].toDate);
          let uniqueChars = [...new Set(a)];
          state.dateRangeArray = uniqueChars;
          state.overRideInitial[i] = 
            {
              type: "dateRange",
              fromDate: state.overRideInitial[i].fromDate,
              toDate: state.overRideInitial[i].toDate,
              daySlots: [
                {
                  day: "Mon",
                  checked: false,
                  timeSlots: [
                    {
                      fromTime: "",
                      toTime: "",
                    },
                  ],
                },
                {
                  day: "Tue",
                  checked: false,
                  timeSlots: [
                    {
                      fromTime: "",
                      toTime: "",
                    },
                  ],
                },
                {
                  day: "Wed",
                  checked: false,
                  timeSlots: [
                    {
                      fromTime: "",
                      toTime: "",
                    },
                  ],
                },
                {
                  day: "Thu",
                  checked: false,
                  timeSlots: [
                    {
                      fromTime: "",
                      toTime: "",
                    },
                  ],
                },
                {
                  day: "Fri",
                  checked: false,
                  timeSlots: [
                    {
                      fromTime: "",
                      toTime: "",
                    },
                  ],
                },
                {
                  day: "Sat",
                  checked: false,
                  timeSlots: [
                    {
                      fromTime: "",
                      toTime: "",
                    },
                  ],
                },
                {
                  day: "Sun",
                  checked: false,
                  timeSlots: [
                    {
                      fromTime: "",
                      toTime: "",
                    },
                  ],
                },
              ],
            };
        } 
        // else {
        //   this.props.alert.setSnack("error", "To Date should be greater than From Date.");
        //   return;
        // }
      } else {
        state.dateRangeArray = []
      }
    }
    this.setState({ ...this.state, ...state });
  };
  handleEdit = (val, e, i) => {
    //;
    const state = this.state;
    state.override_update = true;
    if (val.type === "singleDate") {
      state.overRideInitial[0] = {
        type: val.type,
        selectDate: val.selectDate,
        timeSlots: val.timeSlots,
      };
    } else if (val.type === "dateRange") {
      let a = this.getDaysBetweenTwoDays(val.fromDate, val.toDate);
      let uniqueChars = [...new Set(a)];
      state.dateRangeArray = uniqueChars;

      state.overRideInitial[1] = {
        type: val.type,
        fromDate: val.fromDate,
        toDate: val.toDate,
        daySlots: val.daySlots,
      };
    }

    state.openOverRide = true;
    state.overRideType = val.type;

    state.isEdit = true;
    state.editindex = i;

    if (val.type === "singleDate") {
      state.editSingle = true;
    } else if (val.type === "dateRange") {
      state.editDaterange = true;
    }

    this.setState({ ...this.state, ...state });
  };
  handleClose = (type, cardtype) => {
    const state = JSON.parse(JSON.stringify(this.state));

    if (cardtype === "workingdays") {
      state.working_days_yes_no = "Yes";
    } else if (cardtype === "override") {
      if (type === "add") {
        state.openOverRide = true;
        state.override_save = true;
      } else if (type === "cancel") {
        state.openOverRide = false;
        state.override_save = false;
        state.override_update = false;
        state.overRideType = "singleDate";
        state.overRideInitial = [
          {
            type: "singleDate",
            selectDate: "",
            timeSlots: [
              {
                fromTime: "",
                toTime: "",
              },
            ],
          },
          {
            type: "dateRange",
            fromDate: "",
            toDate: "",
            daySlots: [
              {
                day: "Mon",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Tue",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Wed",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Thu",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Fri",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Sat",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Sun",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
            ],
          },
        ];
      } else if (type === "saved") {
        state.override_save = false;
        state.overRideInitial.map((val) => {
          if (val.type === state.overRideType) {
            state.overRideCard.push(val);
          }
        });
        state.openOverRide = false;
        state.overRideType = "singleDate";
        state.overRideInitial = [
          {
            type: "singleDate",
            selectDate: "",
            timeSlots: [
              {
                fromTime: "",
                toTime: "",
              },
            ],
          },
          {
            type: "dateRange",
            fromDate: "",
            toDate: "",
            daySlots: [
              {
                day: "Mon",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Tue",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Wed",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Thu",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Fri",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Sat",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Sun",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
            ],
          },
        ];
      } else if (type === "update") {
        state.override_save = false;
        state.override_update = false;
        //state.overRideCard.splice(state.editindex, 1);

        if (state.overRideType === "singleDate") {
          state.overRideCard[state.editindex] = state.overRideInitial[0];
        } else if (state.overRideType === "dateRange") {
          state.overRideCard[state.editindex] = state.overRideInitial[1];
        }

        state.overRideInitial = [
          {
            type: "singleDate",
            selectDate: "",
            timeSlots: [
              {
                fromTime: "",
                toTime: "",
              },
            ],
          },
          {
            type: "dateRange",
            fromDate: "",
            toDate: "",
            daySlots: [
              {
                day: "Mon",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Tue",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Wed",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Thu",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Fri",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Sat",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
              {
                day: "Sun",
                checked: false,
                timeSlots: [
                  {
                    fromTime: "",
                    toTime: "",
                  },
                ],
              },
            ],
          },
        ];
        state.editindex = null;
        state.openOverRide = false;
        state.isEdit = false;
        state.editSingle = false;
        state.editDaterange = false;
        state.overRideType = "singleDate";
      }
    }
    state.dateRangeArray = [];
    this.setState({ ...this.state, ...state });
  };
  async successfun() {
    //const state = this.state;
    let errorList = [
      "entityName",
      "entityType",
      "locationId",
      "locationRoleType",
      "operationalStatus",
      "overRideType",
      "shortDescription",
      "longDescription",
      "speciality",
      "locationType"
    ];
    //;
    let { error, errorState } = await valitationFunc(this.state, errorList);
    if (
      error ||
      (this.state.clinicDetailCard ? !this.state.speciality.value : false)
    ) {
      this.props.alert.setSnack("mandatory");
      this.setState({
        ...this.state,
        error: errorState,
      });
    } else {
      let urlParams = this.props?.location?.state?.Id ?? null;
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: urlParams ? "Updating..." : "Adding...",
      });

      let upsertRec = await this.props.LOCATION_MASTER_INSERT({
        data: this.state,
        _key: urlParams,
      });
      console.log(upsertRec);
      //;
      if (
        upsertRec.payload.data.error &&
        upsertRec.payload.data.error.length > 0
      ) {
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
          message: "",
        });
        if (
          upsertRec?.payload?.data?.validation_error?.[0]?.Errormsg?.includes(
            "unique"
          )
        ) {
          this.props.alert.setSnack("unique");
        } else {
          this.props.alert.setSnack("error");
        }
      } else {
        if (urlParams) {
          this.props.alert.setSnack("update");
        } else {
          this.props.alert.setSnack("success");
        }
        //   // practitionermaster
        //   // let patientData = this.props.patientData;
        //   // patientData.setPatientData({
        //   //   ...practitionerInitialstate,
        //   // });
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
          message: "",
        });
        this.props.history.push({
          pathname: routes.administration,
          state: { index: 2 },
        });
      }
    }
  }

  setActive = (id) => {
    this.setState({
      activeId: id,
    });
  };
  //OVER RIDE, MODE CHANGE
  handleRadioChange = (e, type) => {
    //;
    const state = this.state;
    if (type === "workingday") {
      if (e.target.value === "Yes") {
        state.working_days_yes_no = "Yes";
        state.open_working_days_popup = false;
      } else if (e.target.value === "No") {
        state.working_days_yes_no = "No";
        state.open_working_days_popup = true;
      }
    } else {
      state.overRideType = e.target.value;
    }

    this.setState({ ...this.state, ...state });
  };

  onChangeValue = async (n, v) => {
    const state = this.state;
    if (state.error[n]) {
        state.error[n] = false
    }
    state[n] = v;
    if (n === "locationType") {
      if (v.value === "Pharmacy Dispense Location") {
        state.pharmacyDetailCard = true;
        state.clinicDetailCard = false;
      } else if (v.value === "Clinic") {
        state.clinicDetailCard = true;
        state.pharmacyDetailCard = false;
      } else {
        state.pharmacyDetailCard = false;
        state.clinicDetailCard = false;
      }
    } else if (n === "entityName") {
      
      await this.props?.LOCATION_MASTER_SPECIALITY({ id: v?.id });
      this.state.speciality = { label: "", value: "" };
    } else if (n === "entityType") {
      
      // await this.props?.LOCATION_MASTERS_ENTITYNAME({ type: v.value });
      await this.props?.LOCATION_MASTERS_ENTITYNAME({ type: v.coding?.[0]?._id });
      this.state.entityName = { label: "", value: "" };
      this.state.speciality = { label: "", value: "" };
    }
    this.setState({ ...this.state, ...state });
  };
  onInputChange = (n, v) => {
    const state = this.state;

    state[n] = v;
    this.setState({ ...state });
  };

  handleworkdaytimeChange = (date, pi, ci, type, item) => {
    debugger
    const state = JSON.parse(JSON.stringify(this.state));
    if(type === "fromTime"){
      state.workingday_daySlots[pi].timeSlots[ci] = {
        ...state.workingday_daySlots[pi].timeSlots[ci],
        [type]: date,
        "toTime": ""
      };
    } else if(type === "toTime"){
      debugger
      if(moment(state.workingday_daySlots[pi].timeSlots[ci]["fromTime"]) < moment(date)){
        state.workingday_daySlots[pi].timeSlots[ci] = {
          ...state.workingday_daySlots[pi].timeSlots[ci],
          [type]: date,
        };
      } else {
        this.props.alert.setSnack("error", "To time should be greater than From time.");
      }
    }
    // state.workingday_daySlots[pi].timeSlots[ci] = {
    //   ...state.workingday_daySlots[pi].timeSlots[ci],
    //   [type]: date,
    // };
    this.setState({ ...this.state, ...state });
  };
  onworkdayyearState = (date) => {
    const state = JSON.parse(JSON.stringify(this.state));
    let year = date ? moment(date).format("YYYY") : "";
    state.workingday_year = year ? parseInt(year) : 0;
    this.setState({ ...this.state, ...state });
  };

  addworkdayfield = (e, i, item, time_i) => {
    //;
    const state = JSON.parse(JSON.stringify(this.state));

    state.workingday_daySlots[i]?.timeSlots.push({
      fromTime: "",
      toTime: "",
    });
    this.setState({ ...this.state, ...state });
  };
  deleteworkdayfield = (e, pi, ci) => {
    //;
    const state = JSON.parse(JSON.stringify(this.state));
    //if (i === 0) {
    state.workingday_daySlots[pi]?.timeSlots?.splice(ci, 1);
    //}
    this.setState({ ...this.state, ...state });
  };
  addfield = (e, type, day, parent_i, day_i, time_i) => {
    const state = JSON.parse(JSON.stringify(this.state));
    if (type === "singleDate") {
      let obj = {
        fromTime: "",
        toTime: "",
      };
      state.overRideInitial[parent_i]?.timeSlots.push(obj);
    } else if (type === "dateRange") {
      let obj = {
        fromTime: "",
        toTime: "",
      };
      state.overRideInitial[parent_i]?.daySlots[day_i]?.timeSlots.push(obj);
    }
    this.setState({ ...this.state, ...state });
  };

  deletefield = (e, type, day, pi, ci, _i) => {
    //;
    const state = this.state;
    if (state.overRideInitial[pi].type === "singleDate") {
      state.overRideInitial[pi]?.timeSlots?.splice(ci, 1);
    } else if (state.overRideInitial[pi].type === "dateRange") {
      state.overRideInitial[pi]?.daySlots[ci]?.timeSlots?.splice(_i, 1);
    }
    this.setState({ ...this.state, ...state });
  };
  onTimeChange = (type, name, date, pi, ci, ti) => {
    debugger
    const state = JSON.parse(JSON.stringify(this.state));
    //const state = this.state;

    if (type === "singleDate") {
      if(name === "fromTime"){
        state.overRideInitial[pi].timeSlots[ci]["toTime"] = "";
        state.overRideInitial[pi].timeSlots[ci][name] = date;
      } else if(name === "toTime"){
        if(moment(state.overRideInitial[pi].timeSlots[ci]["fromTime"]) < moment(date)){
          state.overRideInitial[pi].timeSlots[ci][name] = date;
        } else {
          this.props.alert.setSnack("error", "To time should be greater than From time.");
        }
      }
      //state.overRideInitial[pi].timeSlots[ci][name] = date;
    } else if (type === "dateRange") {
      debugger
      if(name === "fromTime"){
        state.overRideInitial[pi].daySlots[ci].timeSlots[ti]["toTime"] = "";
        state.overRideInitial[pi].daySlots[ci].checked = false;
        state.overRideInitial[pi].daySlots[ci].timeSlots[ti][name] = date;
      } else if(name === "toTime"){
        debugger
        if(moment(state.overRideInitial[pi].daySlots[ci].timeSlots[ti]["fromTime"]) < moment(date)){
          if(state.overRideInitial[pi].daySlots[ci].timeSlots[ti]["fromTime"]){
            //&& state.overRideInitial[pi].daySlots[ci].timeSlots[ti]["toTime"]){
            state.overRideInitial[pi].daySlots[ci].checked = true;
            state.overRideInitial[pi].daySlots[ci].timeSlots[ti][name] = date;
          }
        } else {
          this.props.alert.setSnack("error", "To time should be greater than From time.");
        }
      }
    }
    this.setState({ ...this.state, ...state });
  };
  onYearChange = (val) => {
    //;
    const state = JSON.parse(JSON.stringify(this.state));
    state.workingday_year = val;
    this.setState({ ...this.state, ...state });
  };
  //CONTACT DETAIL, SELECT EVENT
  onChangeSelect = (nam, val, index) => {
    //;

   

    const state = JSON.parse(JSON.stringify(this.state));

     if (nam === "contactMode") {
       state.contactDetails[index]["contactModeType"]["value"] = "";
     }
    state.contactDetails[index][nam]["value"] = val;

    // state.contactDetails[index] = {
    //   ...state.contactDetails[index],
    //   [nam]: { name: nam, value: val },
    // };
    this.setState({
      ...state,
    });
  };
  //CONTACT DETAIL, TEXT EVENT
  onChangeText = (name, event, index) => {
    //;
    const state = this.state;
    let val = event.target.value;
    if (state.contactDetails[index].contactMode?.value?.value) {
      state.contactDetails[index][name].value = event.target.value;
      this.setState({ ...this.state, ...state });
    } else {
      let customMsg = "Please select contact mode."
      this.props.alert.setSnack("error", customMsg);
    }
  };
  //CONTACT DETAIL, DELETE CONTACT CARD
  DeleteCard = (i) => {
    //;
    const state = JSON.parse(JSON.stringify(this.state));
    //if (i === 0) {
    state.contactDetails.splice(i, 1);
    //}
    this.setState({ ...this.state, ...state });
  };
  //CONTACT DETAIL, ADD CONTACT CARD
  handleClick = (event) => {
    //;
    const state = JSON.parse(JSON.stringify(this.state));
    let obj = {
      contactMode: {
        name: "Contact Mode",
        value: { label: "Phone", value: "Phone" },
      },
      countryCode: {
        name: "",
        value: { label: "", value: "" },
      },
      contactModeType: {
        name: "Contact Mode Type",
        value: "",
      },
      use: {
        name: "Use",
        value: { label: "", value: "" },
      },
      priority: {
        name: "Priority",
        value: { label: "", value: "" },
      },
    };
    state.contactDetails.push(obj);
    this.setState({ ...this.state, ...state });
  };

  workdaydisabled = () => {
    //;
    let a = this.state.workingday_daySlots;
    var aa = [];
    const b = a.map((v) => {
      var time = v.timeSlots;
      return time.map((l) => {
        aa.push(l);
      });
    });
    let err = aa.filter((l) => !l.fromTime || !l.toTime);
    return err.length > 0 ? true : false;
  };
  overridedisabled = () => {
    //;
    const state = this.state;
    if (state.override_save) {
      if (state.overRideType === "singleDate") {
        let a = state.overRideInitial;
        var aa = [];
        let is_seleDate = false;
        const b = a.map((v, i) => {
          if (v.type === "singleDate") {
            //if (state.editindex === i) {
            //var time = v.timeSlots;
            is_seleDate = v.selectDate ? true : false;
            return v.timeSlots.map((l) => {
              aa.push(l);
            });
            //}
          }
        });
        let err = aa.filter((l) => !l.fromTime || !l.toTime);
        return err.length > 0 ? true : false;
      } else if (state.overRideType === "dateRange") {
        let a = state.overRideInitial;
        var aa = [];
        const b = a.map((v, i) => {
          if (v.type === "dateRange") {
            //if (state.editindex === i) {
            v.daySlots.map((d_s) => {
              if(state.dateRangeArray?.includes(d_s?.day)){
                var t_s = d_s.timeSlots;
                return t_s.map((t) => {
                  aa.push(t);
                });
              }
            });
            //}
          }
        });
        let err = aa.filter((l) => !l.fromTime || !l.toTime);

        var validStartEnd = false

          if(a[1].fromDate === "" || a[1].toDate === ""){
            validStartEnd = true
                }
                
        return validStartEnd ? validStartEnd : err.length > 0 ? true : false;
      }
    } else if (state.override_update) {
      if (state.overRideType === "singleDate") {
        let a = state.overRideCard;
        var aa = [];
        const b = a.map((v, i) => {
          if (v.type === "singleDate") {
            if (state.editindex === i) {
              //var time = v.timeSlots;
              return v.timeSlots.map((l) => {
                aa.push(l);
              });
            }
          }
        });
        let err = aa.filter((l) => !l.fromTime || !l.toTime);
        return err.length > 0 ? true : false;
      } else if (state.overRideType === "dateRange") {
        let a = state.overRideCard;
        var aa = [];
        const b = a.map((v, i) => {
          if (v.type === "dateRange") {
            if (state.editindex === i) {
              v.daySlots.map((d_s, i_) => {
                if(state.dateRangeArray?.includes(d_s[i_]?.day)){
                  var t_s = d_s.timeSlots;
                  return t_s.map((t) => {
                    aa.push(t);
                  });
                }
              });
            }
          }
        });
        let err = aa.filter((l) => !l.fromTime || !l.toTime);
        return err.length > 0 ? true : false;
      }
    }
  };

  setDataUpload = async (name, value, next, limit) => {
    if (!value.type.includes("image")) {
      this.props.alert.setSnack("error","Please Select an Image format only.");
    } else if (value.size > 1000000) {
      this.props.alert.setSnack(
        "error",
        "Please Select an Image with size less than 1MB."
      );
    } else {
      var UploadedFiles = [];
      if (limit) {
        if (limit < value.length) {
          return false;
        }
      }
      UploadedFiles = await uploadImage([value]);
      if (UploadedFiles.length > 0) {
        this.state.photo = [
          {
            //_id: "",
            date: "",
            url: "",
            //id: "",
            fileName: UploadedFiles[0].filename,
            fileid: UploadedFiles[0].fileid,
            filetype: UploadedFiles[0].filetype,
            objectid: UploadedFiles[0].objectid,
          },
        ];
      }

      this.setState({ ...this.state });
    }
  };
  handleChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  // saveAddress = (index) => {};
  render() {
    //const { containedButton } = this.styles;
    const activeId = this.props.IsactiveRole ? 2 : this.state.activeId;
    const patientRegisterTitle = this.state._key_id
      ? "Edit Location"
      : "Add Location";
    return (
      <Div
        id={'addlocation-parent-div'}
        className="light-background-color">
        <TopNavbar
          parent_id={'TopNavbar'}
          activeId={activeId}
          navClick={this.setActive}
          successfun={this.successfun}
          title={patientRegisterTitle}
          handleDrawerClose={this.props.handleDrawerClose}
        />
        <Div
          id={'addlocation-EntityCard-div'}
          style={{
            background: "#F6F6F6",
            height: "100%",
            margin: "20px",
            overflow: "hidden",
          }}
        >
          <EntityCard
            parent_id={'EntityCard'}
            entityNameData={this.props.entityNameData?.data}
            entityTypeData={this.props.entityTypeData?.data}
            onChangeState={this.onChangeValue}
            {...this.state}
          />

          <Div
            id={'addlocation-UploadProfile-and-LocationDetails-div'}
            style={{ marginTop: "10px" }}>
            <Row className="pi-actions-root">
              <Col
                id={'addlocation-UploadProfile-col'}
                md={2}
                lg={2}
                sm={12}
                xs={12}
                inLineStyles={{ padding: 0, paddingRight: "8px" }}
              //style={{ marginRight: "10px" }}
              >
                <UploadProfile
                  parent_id={'UploadProfile'}
                  setDataUpload={this.setDataUpload}
                  {...this.state}
                />
              </Col>
              <Col
                id={'addlocation-LocationDetails-col'}
                md={10}
                lg={10}
                sm={12}
                xs={12}
                inLineStyles={{ padding: 0, paddingLeft: "8px" }}
              >
                <LocationDetails
                  parent_id={'LocationDetails'}
                  locationTypeData={this.props.locationTypeData}
                  operationalStatusData={this.props.operationalStatusData}
                  parentLocationData={this.props.parentLocationData}
                  locationRoleTypeData={this.props.locationRoleTypeData}
                  onChangeState={this.onChangeValue}
                  onTextChangeState={this.onInputChange}
                  parentLocationDat={this.props.parentLocationDat}
                  {...this.state}
                />
              </Col>
            </Row>
          </Div>

          {this.state.clinicDetailCard && (
            <ClinicInfo
              parent_id={'ClinicInfo'}
              specialityData={this.props.specialityData}
              onChangeState={this.onChangeValue}
              onTextChangeState={this.onInputChange}
              {...this.state}
            />
          )}
          {this.state.pharmacyDetailCard && (
            <Pharmacy
              parent_id={'Pharmacy'}
              handleChange={this.handleChange}
              onTextChangeState={this.onInputChange}
              {...this.state}
            />
          )}
          <ContactDetails
            parent_id={'ContactDetails'}
            contactmodeData={this.props.contactmodeData}
            isdData={this.props.isdData}
            useData={this.props.useData}
            priorityData={this.props.priorityData}
            onChangeSelect={this.onChangeSelect}
            onChangeText={this.onChangeText}
            DeleteCard={this.DeleteCard}
            handleClick={this.handleClick}
            saveto={"patientData"}
            setTo={"setPatientData"}
            {...this.state}
          />

          <Div
            id={'addlocation-AddressCard-GeoLocationCard-div'}
            style={{ marginTop: "10px" }}>
            <Row
              id={'addlocation-AddressCard-GeoLocationCard-row'}
              className="pi-actions-root">
              <Col
                id={'addlocation-AddressCard-col'}
                md={6}
                lg={6}
                sm={12}
                xs={12}
                inLineStyles={{ padding: 0, paddingRight: "8px" }}
              >
                <AddressCard
                  parent_id={'AddressCard'}
                  onTextChangeState={this.onInputChange}
                  {...this.state}
                />
              </Col>
              <Col
                id={'addlocation-GeoLocationCard-col'}
                md={6}
                lg={6}
                sm={12}
                xs={12}
                inLineStyles={{ padding: 0, paddingLeft: "8px" }}
              >
                <GeoLocationCard
                  parent_id={'GeoLocationCard'}
                  onTextChangeState={this.onInputChange}
                  {...this.state}
                />
              </Col>
            </Row>
          </Div>

          <WorkingDays
            parent_id={'WorkingDays'}
            handleworkdayedit={this.handleworkdayedit}
            handleworkdayClose={this.handleworkdayClose}
            onworkdayyearState={this.onworkdayyearState}
            addworkdayfield={this.addworkdayfield}
            deleteworkdayfield={this.deleteworkdayfield}
            handleworkdaytimeChange={this.handleworkdaytimeChange}
            handleRadioChange={this.handleRadioChange}
            handleClose={this.handleClose}
            onYearChange={this.onYearChange}
            workdaydisabled={this.workdaydisabled}
            {...this.state}
          />
          <OverRide
            parent_id={'OverRide'}
            addfield={this.addfield}
            deletefield={this.deletefield}
            onDateChange={this.onDateChange}
            onChangeState={this.onChangeValue}
            onTextChangeState={this.onInputChange}
            onTimeChange={this.onTimeChange}
            handleRadioChange={this.handleRadioChange}
            overRideInitial={this.state.overRideInitial}
            dateRangeArray={this.state?.dateRangeArray}
            overRideType={this.state.overRideType}
            overRideCard={this.state.overRideCard}
            handleClose={this.handleClose}
            openOverRide={this.state.openOverRide}
            handleEdit={this.handleEdit}
            editSingle={this.state.editSingle}
            editDaterange={this.state.editDaterange}
            isEdit={this.state.isEdit}
            overridedisabled={this.overridedisabled}
            handleChangecheckbox={this.handleChangecheckbox}
          />

          <Div
            id={'addlocation-buttons-div'}
            className="p-continue-action">
            <Button
              id={'addlocation-Cancel-button'}
              variant="text"
              className={"topNav-actions cancelBtn"}
              onClick={() => {
                this.props.history.push(routes.administration);
              }}
            >
              Cancel
            </Button>
            <Button
              id={'addlocation-Update-Save-button'}
              variant={"contained"}
              color={"primary"}
              //disabled={true}
              disabled={this.props?.location_upsert?.loading ?? false}
              onClick={() => this.successfun()}
              style={{ marginLeft: 10 }}
            >
              {this.props?.location?.state?.Id ? (
                <>
                  {this.props?.location_upsert?.loading
                    ? "Updating..."
                    : "Update"}
                </>
              ) : (
                <>
                  {this.props?.location_upsert?.loading ? "Saving..." : "Save"}
                </>
              )}
            </Button>
          </Div>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (state) => ({
  entityNameData: state?.locationMasterSlice?.location_masters_entityname,
  entityTypeData: state?.locationMasterSlice?.location_masters_entitytype,
  contactmodeData: state?.locationMasterSlice?.location_masters_contactmode,
  isdData: state?.locationMasterSlice?.location_masters_isd,
  useData: state?.locationMasterSlice?.location_masters_use,
  priorityData: state?.locationMasterSlice?.location_masters_priority,
  specialityData: state?.locationMasterSlice?.location_masters_speciality,
  locationTypeData: state?.locationMasterSlice?.location_masters_locationtype,
  operationalStatusData:
    state?.locationMasterSlice?.location_masters_operationalstatus,
  parentLocationData:
    state?.locationMasterSlice?.location_masters_parent_location_id,
  locationRoleTypeData:
    state?.locationMasterSlice?.location_masters_locationroletype,
  location_upsert: state?.locationMasterSlice?.location_insert,
  //LOCATION MASTER EDIT
  locationmaster_by_id: state?.locationMasterSlice?.locationmasterbyid,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(withHoverDrawer(AddLocation)));
