import React from "react";
import { withAllContexts, withHoverDrawer } from "../../../../HOCs";
import { DrugAlertRestrictionComponent } from "clmm-masters";
import { useLocation, withRouter } from "react-router";
import { routes } from "../../../../router/routes";
function Alertrestriction(props) {
  const location = useLocation();
  return (
    <DrugAlertRestrictionComponent
      data={{ ...location?.state }}
      onAlertBackClick={() => {
        props.history.push({
          pathname: routes.addNewDrug,
          state: { ...location?.state },
        }); 
      }}
      onAlertCancelClick={() => {
        props.history.push(routes.DrugMasters);
      }}
      onAlertPreviousClick={() => {
        props.history.push({
          pathname: routes.addNewDrug,
          state: { ...location?.state },
        });
      }}
      onAlertSaveSuccess={() => {
        props.history.push({
          pathname: routes.DrugMasters,
          state: { ...location?.state },
        });
      }}
    />
  );
}

export default withAllContexts(
    withRouter(withHoverDrawer(Alertrestriction))
  );