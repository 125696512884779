import React from "react";
import { Grid, Button } from "@material-ui/core";
import { OverRideAffected, CommonTabList, TTBasicDetails, TTRuleSet, TTSchedulePeriod, TTAppoinmentDuration } from "../../../components"
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { AlertProps } from "../../../utils";
import { withAllContexts } from "../../../HOCs";
import moment from "moment";

let basicDetailsKeys = ["entity_type", "entity_name", "title", "applicable_type", "resource_type", "resource_role"];
let scheduledKeys = ["schedule_mode", "start_date"];
let days = ["Day1", "Day2", "Day3", "Day4", "Day5", "Day6", "Day7"]
let weak = ["Week1", "Week2", "Week3", "Week4", "Week5"]
let month = ["Month1", "Month2", "Month3", "Month4", "Month5", "Month6", "Month7", "Month8", "Month9", "Month10", "Month11", "Month12"]
// let basicDetailsKeys = [];

function TimeTableForm(props) {
    const { closeForm, editData, parent_id } = props;
    let dispatch = useDispatch();
    const stateData = useSelector(state => state.timetableMasterSlice)
    //states
    const [basicDetails, setBasicDetails] = React.useState({});
    const [isedit, setisedit] = React.useState(false);
    const [scheduledPeriod, setScheduledPeriod] = React.useState({});
    const [appointDuration, setAppointDuration] = React.useState([]);
    const [ruleSet, setRuleSet] = React.useState([]);
    const [state, setState] = React.useState({});
    const [err,setErr]=React.useState({})

    React.useEffect(async () => {
        await dispatch(actions.APPLICABLE_APP_TYPE());
        if (!isedit) {
            if (editData) {
                setisedit(true)
                let applicable_app_type = editData.applicable_app_type.map(val => val._id)
                let entType = stateData.entity_type_list.data.filter(val => val.value === editData.entityType)[0]
                await dispatch(actions.GET_ENTITY_NAME_WITH_TYPE({ type: entType.value }))
                let resourceType = stateData.resourceType_list.data.filter(val => val.value === editData.ResourceType?._id)[0]
                let payloadData = await dispatch(actions.GET_RESOURCE_ROLE({ type: resourceType, ename: editData?.organization_id ?? null }))
                let entName = { label: editData.organization_id.name, value: editData.organization_id._id }
                setBasicDetails({
                    entity_type: entType,
                    entity_name: entName,
                    title: editData.timetable_title,
                    applicable_type: stateData.applicable_app_type.data.filter(val => applicable_app_type.indexOf(val.value) > -1),
                    resource_type: resourceType,
                    resource_role: payloadData.payload.data.filter(val => val.value === editData.ResourceRole?._id)[0],
                })
                setScheduledPeriod({
                    Day1: editData.Day1, Day2: editData.Day2, Day3: editData.Day3, Day4: editData.Day4, Day5: editData.Day5, Day6: editData.Day6, Day7: editData.Day7,
                    Week1: editData.Week1, Week2: editData.Week2, Week3: editData.Week3, Week4: editData.Week4, Week5: editData.Week5,
                    Month1: editData.Month1, Month2: editData.Month2, Month3: editData.Month3, Month4: editData.Month4, Month5: editData.Month5, Month6: editData.Month6, Month7: editData.Month7, Month8: editData.Month8, Month9: editData.Month9, Month10: editData.Month10, Month11: editData.Month11, Month12: editData.Month12,
                    start_date: new Date(moment.unix(editData.EffectiveFrom)),
                    end_date: editData.EffectiveTo ? new Date(moment.unix(editData.EffectiveTo)) : null,
                    schedule_mode: stateData.scheduleMode_list.data.filter(val => val.value === editData.Schedulemode)[0],
                    availablity_management: editData.scheduleappmang
                })
                let appointment = editData.AppointmentDuration.map(val => {
                    return {
                        duration_type: val.SlotSlabInd,
                        day_type: stateData.dayType_list.data.filter(value => value.value === val.DayType._id)[0],
                        start_time: val.starttime?.replace(" ",""),
                        // start_time: moment.unix(val.starttime).format("HH:MM"),
                        // end_time: moment.unix(val.endtime).format("HH:MM"),
                        end_time: val.endtime?.replace(" ",""),
                        slot_duration: val.slotduration,
                        max_appoinment: val.maxAppointment,
                        max_appoinment_allowed: val.maxAppAllowed,
                        max_walking: val.maxwalkin,
                        max_over_booking: val.maxoverbooking,
                        max_wait_list: val.maxwaitlist,
                        consultation_type: stateData.consultationType_list.data.filter(value => value.value === val.consultType._id)[0],
                    }
                })
                setAppointDuration(appointment)
                setRuleSet(editData.Resource_Criteria);
            } else {
                let entType = stateData.entity_type_list.data.filter(val => val.label === "Facility")[0]
                await dispatch(actions.GET_ENTITY_NAME_WITH_TYPE({ type: entType?.value ?? "" }))
                setBasicDetails({
                    ...basicDetails,
                    entity_type: entType,
                })
                setScheduledPeriod({
                    ...scheduledPeriod,
                    schedule_mode: stateData.scheduleMode_list.data.filter(val => val.label === "Automatic")[0],
                })
            }
        }
    }, [stateData.resourcerole_list.data])

    const checkError = () => {
        let error = false;
        let errorState={}
        basicDetailsKeys.map(val => {
            if (!basicDetails[val]) {
                error = true;
                errorState[val]=true;
            }
        });
        scheduledKeys.map(val => {
            if (!scheduledPeriod[val]) {
                error = true
                 errorState[val] = true;
            }
        });
        if (appointDuration.length === 0) {
            error = true;
            errorState["appointDuration"] = true;
        }
        setErr(errorState);
        return [error, errorState];
    }

    const successForm = async (reason) => {
        debugger
        const [isError, state]  = checkError();
        if (isError) {
            const errState = Object.keys(state || {});
            if (errState.length === 1 && errState[0] === "appointDuration") {
                props.alert.setSnack("mandatory", "Please Add At Least One Appointment Duration");
            } else  {
                props.alert.setSnack("mandatory");
            }
        } else {
            let dayEnabled = days.filter(val => scheduledPeriod[val])
            let weakEnabled = weak.filter(val => scheduledPeriod[val])
            let monthEnabled = month.filter(val => scheduledPeriod[val])
            if (dayEnabled.length === 0) {
                let customMsg = "select atleast one day";
                props.alert.setSnack("error", customMsg);
            } else if (weakEnabled.length === 0) {
                let customMsg = "select atleast one week";
                props.alert.setSnack("error", customMsg);
            } else if (monthEnabled.length === 0) {
                 let customMsg = "select atleast one month";
                props.alert.setSnack("error", customMsg);
            } else {
                if (props.ttOverRide && !state.isopenAA) {
                    setState({
                        isopenAA: true
                    })
                    return false
                } else {
                    setState({
                        isopenAA: false
                    })
                }
                props.backDrop.setBackDrop({
                    ...props.backDrop,
                    open: true,
                    message: "updating",
                });
                let data = {
                    basicDetails, scheduledPeriod, appointDuration, editData,
                    ruleSet, isOverRide: props.ttOverRide ? props.ttOverRide : editData?.isoverride ?? false
                }



                let upsertData = await dispatch(actions.UPSERT_TIMETABLE(data));

                if (props.ttOverRide) {
                    await dispatch(actions.BLOCK_APPOINTMENT({
                        id: upsertData?.payload?.data?.Result?.[0]?.properties?.doc?._id ?? "",
                        reason: reason?.label
                    }));
                }


                
                // await Promise.all(ruleSet.map(async (val) => {
                // if (ruleSet && ruleSet.length > 0) {
                    let ids = [];
                    if(ruleSet && ruleSet.length > 0 && Array.isArray(ruleSet)){
                        ids = ruleSet.map(val => val?.rule?.value)
                    }
                    debugger
                    await dispatch(actions.RULE_EXCUTION({
                        ruleId: ids?.[0] ?? "",
                        resourceTimeTableId: upsertData?.payload?.data?.Result?.[0]?.properties?.doc?._id ?? "",
                        resourceType: basicDetails?.resource_type?.value ?? "",
                        orgId: basicDetails?.entity_name?.value ?? "",
                    }));
                // }
                // }))


                await dispatch(actions.GET_TIMETABLE_LIST());
                props.backDrop.setBackDrop({
                    ...props.backDrop,
                    open: false,
                    message: "",
                });
                props.alert.setSnack("update");

                closeForm();
            }
        }
    }

    const handleCloseAA = () => {
        setState({
            isopenAA: false
        })
    }

    console.log("stateDate",stateData);
    return (
        <React.Fragment>
            <CommonTabList
                parent_id={'timetableform'}
                backbtn
                backFun={closeForm}
                title={editData ? "Edit Time Table" : "Create new time table"}
                list={[]}
            />
            <Grid
                id={`${parent_id}-TTBasicDetails-grid`}
            >
                <TTBasicDetails
                    applicableData= {stateData?.applicable_app_type?.data}
                    parent_id={'TTBasicDetails'}
                    error={err}
                    seterr={setErr}
                    editData={editData} stateData={basicDetails} setState={setBasicDetails} />
            </Grid>
            <Grid
                id={`${parent_id}-TTRuleSet-grid`}
            >
                <TTRuleSet
                    parent_id={'TTRuleSet'}
                    ttOverRide={props.ttOverRide}
                     error={err}
                    stateData={ruleSet} setState={setRuleSet} editData={editData} />
            </Grid>
            <Grid
                id={`${parent_id}-TTSchedulePeriod-grid`}
            >
                <TTSchedulePeriod ttOverRide={props.ttOverRide}
                    parent_id={'scheduledPeriod'}
                     error={err}
                    stateData={scheduledPeriod} editData={editData} setState={setScheduledPeriod} />
            </Grid>
            <Grid
                id={`${parent_id}-TTAppoinmentDuration-grid`}
            >
                <TTAppoinmentDuration
                    parent_id={'appointDuration'}
                     error={err}
                    stateData={appointDuration} setStateData={setAppointDuration} />
            </Grid>

            {(props.ttOverRide && state.isopenAA) && <Grid>
                <OverRideAffected editData={editData} successForm={successForm} handleCloseAA={handleCloseAA} isopenAA={state.isopenAA} />
            </Grid>}

            <Grid
                id={`${parent_id}-button-grid`}
                style={{ textAlign: "right", padding: 20 }}>
                <Button
                    id={`${parent_id}-Cancel-button`}
                    style={{ marginLeft: 15 }} variant="outlined"
                    onClick={() => closeForm()}
                >Cancel</Button>
                <Button
                    id={`${parent_id}-Save-button`}
                    style={{ marginLeft: 15 }} color="primary"
                    variant="contained"
                    onClick={() => successForm()}
                >Save</Button>
            </Grid>
        </React.Fragment>
    )
}

export default withAllContexts(TimeTableForm);
