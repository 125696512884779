import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import "./card.css";

/**
 *
 * @param  {object} props - required props in card component
 * @returns {React.ReactElement} - returns the card component
 */
const Card = (props) => {
  const {
    children,
    cardTitleType,
    cardTitle,
    optionalHeader,
    toolTip,
    customStyle,
  } = props;
  return (
    <div
      className={`${toolTip && "tooltipHold"}  mainLayout`}
      style={customStyle}
    >
      <Grid container>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography variant={cardTitleType} className="cardTitle">
              {cardTitle}
            </Typography>
          </Grid>
          <Grid className="optionalhead" item>
            {optionalHeader}
          </Grid>
        </Grid>

        {children}
      </Grid>
    </div>
  );
};

export default Card;

Card.propTypes = {
  children: PropTypes.node,
  optionalHeader: PropTypes.node,
  cardTitleType: PropTypes.string,
  cardTitle: PropTypes.string,
  customClass: PropTypes.string,
  toolTip: PropTypes.bool,
};
Card.defaultProps = {
  children: <> </>,
  optionalHeader: <> </>,
  cardTitleType: "",
  cardTitle: "",
  customClass: "",
  toolTip: false,
};
