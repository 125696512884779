import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import Button from "@material-ui/core/Button";
import "../../font.css";
import { Typography, 
  //Grid, Paper, IconButton
 } from "@material-ui/core";
//import styles from "../../availabilitymanagement/styles";
import Delete from "../../../assets/icons8-trash.svg";
//import { TextInput } from "qdm-component-library";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  font: {
    fontSize: "14px",
    marginLeft: "10px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
    fontfamily: "poppin",
  },
  checkbox: {
    "& .MuiTypography-body1": {
      fontSize: "12px",
      fontfamily: "Poppinsemibold !important",
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "40px",
    },
  },
  cbox: {
    "& .Component-root-107": {
      "& .Component-checked-108": {
        color: "#0071F2",
      },
    },
  },
  ml20: {
    marginLeft: "20px",
  },
  trash: {
    margin: "auto",
    // marginTop: "28px",
    // marginLeft: "33px",
  },
}));

export default function AddPublication(props) {
  const classes = useStyles();
  const { parent_id } = props;

  return (
    <div
      id={`${parent_id}-parent-div`}
      style={{ display: "flex" }} className={classes.root}>
      <div
        id={`${parent_id}-Description-div`}
      >
        {/* <Typography
          id={`${parent_id}-Description-title-typography`}
          className={classes.grayfont}>Description</Typography> */}
        <div
          id={`${parent_id}-Description-textfield-div`}
        >
          <TextField
            id={`${parent_id}-Description-textfield`}
            variant="outlined"
            fullWidth
            size="small"
            name="pageName"
            style={{ width: "514px" }}
            required
            inputProps={{ style: { fontFamily: "poppin", fontSize: "12px" } }}
            InputLabelProps={{
              style: { fontFamily: "poppin", fontSize: "12px" },
            }}
            value={props?.value?.description ?? ""}
            onChange={(e) => props.setDetails("description", e.target.value, props?.index)}
          />
        </div>
      </div>
      <div
        id={`${parent_id}-link-div`}
        className={classes.ml20}>
        {/* <Typography
          id={`${parent_id}-link-title-typography`}
          className={classes.grayfont}>Link</Typography> */}
        <TextField
          id={`${parent_id}-link-textfield`}
          variant="outlined"
          fullWidth
          size="small"
          style={{ width: "514px" }}
          name="pageName"
          required
          inputProps={{ style: { fontFamily: "poppin", fontSize: "12px" } }}
          InputLabelProps={{
            style: { fontFamily: "poppin", fontSize: "12px" },
          }}
          value={props?.value?.link ?? ''}
          onChange={(e) => props.setDetails("link", e.target.value, props?.index)}
        />
      </div>
      <div
        id={`${parent_id}-Delete-Icon-div`}
        className={classes.trash}>
        <img
          id={`${parent_id}-Delete-Icon-img`}
          src={Delete} alt="Delete_Icon" onClick={() => props.ondelete(props?.index)} />
      </div>
    </div>
  );
}
