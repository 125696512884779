import React from "react";
import {
    Drawer, makeStyles, Grid, Typography, Avatar, InputAdornment,
    TextField, RadioGroup, Radio, FormControlLabel, Button, Paper
} from "@material-ui/core";
import { SelectBox } from "../../../components";
import { useSelector } from "react-redux";
import { AlertProps } from "../../../utils";
import { withAllContexts } from "../../../HOCs";
import { InputComp } from "../../common/smartForm/component";
import CustomInputDatePicker from "../../common/datePicker/inputDatePicker";
import moment from "moment";
import {setHours, setMinutes} from "date-fns";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        width: 400
        // margin: 15
    },
    addBtn: {
        float: "right",
        fontSize: "14px",
        fontWeight: 600,
        cursor: "pointer"
    },
    title: {
        fontFamily: "poppinsemibold",
        fontSize: "12px",
        display: "inline"
    },
    form: {
        paddingTop: 15
    },
    formtitle: {
        fontSize: "12px",
        textTransform: "capitalize",
        paddingBottom: 10
    },
    formParent: {
        padding: 10
    },
    radioGroup: {
        display: "contents"
    },
    actionbtn: {
        marginLeft: 15
    },
    actionParent: {
        textAlign: "right",
        paddingTop: 15
    },
    titleParent: {
        position: "fixed",
        width: "100%",
        padding: "10px 27px",
        background: "white",
        zIndex: 4
    },
    Headertitle: {
        fontWeight: 600
    },
    large: {
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        position: "fixed",
        right: 420,
        cursor: "pointer",
        top: 10,
        "& img": {
            objectFit: "scale-down",
            backgroundColor: "#fff"
        },
        [theme.breakpoints.down("xs")]: {
            right: "313px",
        },
    },
}));

const componentsSlot = [
    { stateName: "duration_type", label: "duration type", type: "RADIO", options: [{ label: "Slot basis", value: "Slot" }, { label: "Slab basis", value: "Slab" }] },
    { stateName: "day_type", label: "day type", type: "SELECT", optionName: "dayType_list" },
    { stateName: "start_time", label: "start time", type: "TIME" },
    { stateName: "end_time", label: "end time", type: "TIME" },
    { endText: "Mins", stateName: "slot_duration", label: "slot duration", type: "TEXT_FIELD" },
    { stateName: "max_appoinment", label: "max appointment / slot", type: "TEXT_FIELD" },
    { stateName: "max_appoinment_allowed", label: "max appointment allowed", type: "TEXT_FIELD", disabled: true },
    { stateName: "max_walking", label: "Max Walk-ins", type: "TEXT_FIELD" },
    { stateName: "max_over_booking", label: "max over booking", type: "TEXT_FIELD" },
    { stateName: "max_wait_list", label: "max wait list", type: "TEXT_FIELD" },
    { stateName: "consultation_type", label: "consultation type", type: "SELECT", optionName: "consultationType_list" },
]

const componentsSlob = [
    { stateName: "duration_type", label: "duration type", type: "RADIO", options: [{ label: "Slot basis", value: "Slot" }, { label: "Slab basis", value: "Slab" }] },
    { stateName: "day_type", label: "day type", type: "SELECT", optionName: "dayType_list" },
    { stateName: "start_time", label: "start time", type: "TIME" },
    { stateName: "end_time", label: "end time", type: "TIME" },
    { stateName: "max_appoinment", label: "max appointment / Slab", type: "TEXT_FIELD" },
    { stateName: "max_appoinment_allowed", label: "max appointment allowed", type: "TEXT_FIELD" },
    { stateName: "max_walking", label: "Max Walk-ins", type: "TEXT_FIELD" },
    { stateName: "max_over_booking", label: "max over booking", type: "TEXT_FIELD" },
    { stateName: "max_wait_list", label: "max wait list", type: "TEXT_FIELD" },
    { stateName: "consultation_type", label: "consultation type", type: "SELECT", optionName: "consultationType_list" },
]

function TTADForm(props) {
    const { open, closefun = () => null, onsuccess = () => null, editData = {}, parent_id } = props;
    const [state, setState] = React.useState(editData);
    let stateList = useSelector(state => state.timetableMasterSlice)
    const classes = useStyles();
    const checkError = () => {
        let error = false;
        (state.duration_type === "Slab" ? componentsSlob : componentsSlot).map(val => {
            if (!state[val.stateName] || state[val.stateName] < 0) {
                error = true;
            }
        })
        return error
    }
    React.useEffect(() => {
        if (state.start_time && state.end_time && state.slot_duration && state.max_appoinment) {
            let st = parseFloat(state.start_time.replace(":", '.')) * 60;
            let et = parseFloat(state.end_time.replace(":", '.')) * 60;
            let count = Math.abs(et - st) / (parseInt(state.slot_duration));
            setState({ ...state, max_appoinment_allowed: (parseInt(count * state.max_appoinment)) });
        }
    }, [state.start_time, state.end_time, state.slot_duration, state.max_appoinment])
    const updateState = (name, value) => {
        setState({ ...state, [name]: value })
    }
    const successFun = () => {
        if (checkError()) {
            props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: `Please fill all mandatory fields and Please don't add negative values`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
            });
        } else {
            onsuccess(state)
        }
    }

    // minimum Date Set
    const getMinDate = (date,type) => {
        console.log("newDate",date);
        const hour = date?.substring(0,2);
        const minute = date?.substring(5,7)
        return setHours(setMinutes(new Date(), minute), hour);
    }
    return (
        <React.Fragment>
            <Drawer
                id={`${parent_id}-parent-drwer`}
                anchor={"right"} open={Boolean(open)}>
                <Avatar
                    id={`${parent_id}-close-avatar`}
                    src="/images/close.svg" className={classes.large} onClick={() => closefun()} />
                <Grid
                    id={`${parent_id}-title-grid`}
                    style={{ minHeight: 45 }}>
                    <Paper
                        id={`${parent_id}-title-paper`}
                        className={classes.titleParent}>
                        <Typography
                            id={`${parent_id}-Add-Appointment-title-typography`}
                            className={classes.Headertitle}>Add Appointment Duration</Typography>
                    </Paper>
                </Grid>
                <Grid
                    id={`${parent_id}-parent-container-grid`}
                    container className={classes.root}>
                    {(state.duration_type === "Slab" ? componentsSlob : componentsSlot).map((val, i) => <Grid
                        id={`${parent_id}-${i}-parent-title-grid`}
                        item md="12" lg="12" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-${i}-${val.label}-typography`}
                            className={classes.formtitle} variant="body1">{val.label} <span
                                id={`${parent_id}-${i}-${val.label}-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        {val.type === "TEXT_FIELD" &&
                            <InputComp
                                id={`${parent_id}-freqrate-textField`}
                                //value={state?.freqrate}
                                disabled={val.disabled}
                                onChange={(e) =>
                                    updateState(val.stateName, e.target.value)
                                }
                                value={state[val.stateName]}
                                InputProps={{
                                    endAdornment: !val.endText ? null : <InputAdornment
                                        id={`${parent_id}-${i}-inputadornment`}
                                        position="start">{val.endText}</InputAdornment>,
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    );
                                }}
                            />
                        }
                        {val.type === "TIME" &&
                            //     <TextField
                            //     id={`${parent_id}-${i}-textField`}
                            //     type="time" fullWidth variant="outlined" value={state[val.stateName]}
                            //     onChange={e => updateState(val.stateName, e.target.value)
                            //     }
                            // />
                            <CustomInputDatePicker
                                placeholderText="dd / mm / yyyy"
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="HH:mm"
                                minTime={val.stateName === "end_time" && getMinDate(state.start_time,"startDate") }
                                maxTime={val.stateName === "end_time" &&setHours(setMinutes(new Date(), 59), 23)}
                                timeIntervals={15}
                                selectedDate={null}
                                handleChange={(value) =>
                                    // alert(moment(value).format("hh: mm a"))
                                    updateState(val.stateName, moment(value).format("HH: mm"))
                                }
                                inputField={
                                    <TextField
                                        id={`${parent_id}-${i}-textField`}
                                        fullWidth
                                        variant="outlined"
                                        value={
                                            state[val.stateName]
                                        }
                                        inputProps={{
                                            style: {
                                                padding: "10.5px 14px",
                                                fontFamily: "poppin",
                                                fontSize: "12px",
                                                // backgroundColor: data?.disabled ? "#E0E0E0" : "#fff",
                                            },
                                        }}
                                    />
                                }
                            />
                        }
                        {val.type === "SELECT" && <SelectBox
                            id={`${parent_id}-${i}-selectBox`}
                            list={stateList[val.optionName]?.data}
                            onchange={(e, value) => updateState(val.stateName, value)}
                            value={state[val.stateName]}
                        />}
                        {val.type === "RADIO" && <RadioGroup
                            id={`${parent_id}-${i}-radiogroup`}
                            aria-label={"duration"} name={"duration"} className={classes.radioGroup}
                            value={state[val.stateName]}
                            onChange={e => updateState(val.stateName, e.target.value)}
                        >
                            {val.options.map((val, i) => <FormControlLabel
                                id={`${parent_id}-${i}-formControllabel`}
                                value={val.value} control={<Radio color="primary" />} label={val.label} />)}
                        </RadioGroup>}
                    </Grid>)}
                    <Grid
                        id={`${parent_id}-button-grid`}
                        md={12} className={classes.actionParent}>
                        <Button
                            id={`${parent_id}-Cancel-button`}
                            className={classes.actionbtn} onClick={() => closefun()} variant="outlined">Cancel</Button>
                        {/* <Button className={classes.actionbtn} color="primary" variant="contained">Save & Add another</Button> */}
                        <Button
                            id={`${parent_id}-Save-button`}
                            className={classes.actionbtn} color="primary" onClick={() => successFun()} variant="contained">Save</Button>
                    </Grid>
                </Grid>
            </Drawer>
            {/* </Popover> */}
        </React.Fragment >
    )
}



export default withAllContexts(TTADForm);