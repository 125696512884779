import {
    Div,
    Text,
    Image,
} from "qdm-component-library";

export const Filenameui = (props) => {
    const { parent_id } = props;
    return (
        <Div
            id={`${parent_id}-parent-div`}
            className="pi-upload-doc" style={{ display: "grid", width: "unset", justifyContent: "space-between", alignItems: "center" }}>
            <div
                id={`${parent_id}-image-div`}
                style={{ display: "flex", alignItems: "center" }}>
                <Image
                    id={`${parent_id}-document-image`}
                    className="pi-upload-doc-img"
                    style={{ marginRight: "9px" }}
                    src={"images/icons8-document.svg"}
                    alt=""
                />
            </div>
            <Text
                id={`${parent_id}-document-filename-text`}
                className="pi-upload-doc-name"
                style={{
                    color: "#6F6F6F",
                    fontSize: "14px",
                    wordSpacing: "5px",
                }}
            >
                {props?.fileName ?? ""}
            </Text>
            &nbsp;
            <Div
                id={`${parent_id}-multiply-div`}
                style={{
                    width: 15,
                    cursor: "pointer",
                    marginLeft: "11%",
                    width: "15px",
                    height: "13px",
                }}
            >
                <Image
                    id={`${parent_id}-multiply-image`}
                    src="images/icons8-multiply.svg"
                    alt="delete"
                    // onClick={() => this.removedoc(i)}
                    onClick={props.delete}
                />
            </Div>
        </Div>
    )
}