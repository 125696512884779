import React from "react";
import { withRouter } from "react-router-dom";
import * as ALLMCORE from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
// Icons
import { CreateIcon, TrashIcon } from "../svg";
// Styles
import qdmstyles from "./styles.json";

function Availablestrengthtablevalue(props) {
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {}, [state]);

  const { data, index, onDeleteClick, onEditClick } = props;

  const getStringWithSpace = (values) => {
    return values.join(" ");
  };

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"UYSha"}
        container={true}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        lg={"12"}
        md={"12"}
        sm={"12"}
        xl={"12"}
        xs={"12"}
        style={qdmstyles.uurn}
      >
        <Grid
          key={"0"}
          id={"8k4Lv"}
          container={true}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"3"}
          md={"3"}
          sm={"3"}
          xl={"3"}
          xs={"3"}
        >
          <Typography
            key={"0"}
            id={"c3m1q"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            style={qdmstyles.XXlZ}
          >
            {`${getStringWithSpace([
              data?.StrengthValue,
              data?.StrengthUOM?.label ?? "",
            ])} / ${getStringWithSpace([
              data?.StrengthVolume,
              data?.StrengthVolumeUoM?.label ?? "",
            ])}`}
          </Typography>
        </Grid>
        <Grid
          key={"1"}
          id={"XTsif"}
          container={true}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"7"}
          md={"7"}
          sm={"7"}
          xl={"7"}
          xs={"7"}
        >
          <Typography
            key={"0"}
            id={"availableStrengthTablerow_allowedvaluesforders"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            aliasName={"availableStrengthTablerowallowedvaluesforders"}
            style={qdmstyles.vPLF}
          >
            {data?.OrderUOMAllowed?.map(
              (item) =>
                `${getStringWithSpace([
                  item?.AllowedOrderDoseVal,
                  item?.AllowedOrderDoseUOM?.label ?? "",
                ])} / ${getStringWithSpace([
                  item?.AllowedOrderDoseVolVal,
                  item?.AllowedOrderDoseVolUoM?.label ?? "",
                ])}`
            )?.join(", ")}
          </Typography>
        </Grid>
        <Grid
          key={"2"}
          id={"S0IIq"}
          container={true}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          lg={"2"}
          md={"2"}
          sm={"2"}
          xl={"2"}
          xs={"2"}
          style={qdmstyles.nTjXV}
        >
          <ALLMCORE.IconButton
            size="small"
            style={{ minWidth: "30px", height: "30px" }}
            onClick={onDeleteClick}
          >
            <TrashIcon />
          </ALLMCORE.IconButton>
          <ALLMCORE.IconButton
            size="small"
            style={{ minWidth: "30px", height: "30px" }}
            onClick={onEditClick}
          >
            <CreateIcon />
          </ALLMCORE.IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(Availablestrengthtablevalue);
