import React from "react";
const SVG = ({ width, height, className, fill }) => (
    <svg
        // fill={fill} 
        width={width}
        height={height}
        // viewBox={"0 0 24 17.685"}
        viewBox="0 0 14 14"
        x="0px" y="0px"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"

    >
        <g>
            <path
                d="M10.081,6A2.119,2.119,0,0,0,8,8.139v9.722a.568.568,0,1,0,1.135,0V8.139a.951.951,0,0,1,.946-.972h7.946a.951.951,0,0,1,.946.972v8.314L18.05,15.5a.56.56,0,0,0-.407-.177.568.568,0,0,0-.526.364.6.6,0,0,0,.13.638l1.843,1.894a.558.558,0,0,0,.9,0l1.84-1.891a.6.6,0,0,0,0-.825.557.557,0,0,0-.8,0l-.923.949V8.139A2.119,2.119,0,0,0,18.027,6Zm1.514,12.833a.584.584,0,0,0,0,1.167h4.162a.584.584,0,0,0,0-1.167Z"
                transform="translate(-8 -6)"
                fill={fill ? fill : "#6f6f6f"}
            />
        </g>
    </svg>
);
export default SVG;