import React from "react";
import { CommonTable, GenerateForm } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

function DrugRoutes(props) {
  const dispatch = useDispatch();

  const drugRouteslist = useSelector(
    (state) => state?.drugRoutesSlice?.drug_routes_list?.data
  );
  const loading = useSelector(
    (state) => state?.drugRoutesSlice?.drug_routes_list?.loading
  );
  const saveloading = useSelector(
    (state) => state?.drugRoutesSlice?.drug_routes_upsert?.loading
  );

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  React.useLayoutEffect(() => {
    props?.currentTab("drugRoutes");
    dispatch(actions.DRUG_ROUTES_READ({ page: page, perPage: perPage }));
  }, []);

  const [state, setState] = React.useState({
    openEditFrom: false,
    saveloading: false,
    edit_data: null,
    status: true,
  });

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(
      actions.DRUG_ROUTES_READ({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      })
    );
  };

  const handleCloseForm = () => {
    const { changeState } = props;
    changeState("open", false);
    setState({
      openEditFrom: false,
      edit_data: null,
    });
  };

  const handleEdit = (e, data) => {
    setState({
      openEditFrom: true,
      edit_data: data,
      status: data?.status,
    });
  };

  const saveForm = async (values) => {
    let type = "success";
    let data = {
      drug_routes: values?.drug_routes?.toUpperCase?.(),
      short_description: values.short_description,
      long_description: values.long_description,
      route_adverb: values.route_adverb,
      status: true,
    };
    if (state?.edit_data) {
      data["_key"] = state?.edit_data._key;
      //   data["id"] = state?.edit_data.id
      data["status"] = state?.edit_data.status;
      type = "update";
    }
    let save = await dispatch(actions.DRUG_ROUTES_UPSERT(data));
    if (save.payload?.data?.Code === 1111) {
      type = save.payload?.data?.validation_error?.[0]?.Errormsg.includes(
        "unique"
      )
        ? "unique"
        : "error";
      props.alert.setSnack(type);
    } else {
      await dispatch(
        actions.DRUG_ROUTES_READ({ page: page, perPage: perPage })
      );
      await handleCloseForm();
      props.alert.setSnack(type);
    }
  };

  const statusChange = async (e, values, index) => {
    let data = {
      _key: values._key,
      status: e.target.checked,
    };
    await dispatch(actions.DRUG_ROUTES_STATUS_CHANGE(data));
    await dispatch(actions.DRUG_ROUTES_READ({ page: page, perPage: perPage }));
    props.alert.setSnack("update");
  };

  // render() {
  const { open, openIndex, parent_id } = props;
  return (
    <React.Fragment>
      <CommonTable
        style={{ height: "calc(100vh - 245px)" }}
        parent_id={"drugroutes"}
        rightAlign={["Status", "Action"]}
        handleEdit={handleEdit}
        handleCheckBox={statusChange}
        Header={[
          "S.No",
          "Drug Route",
          "Short Description",
          "Long Description",
          "Status",
          "Action",
        ]}
        dataList={drugRouteslist}
        tableData={[
          { type: ["INCRIMENT"], name: "" },
          { type: ["TEXT"], name: "drug_routes" },
          { type: ["TEXT"], name: "short_description" },
          { type: ["TEXT"], name: "long_description" },
          { type: ["CHECKBOX"], name: "status", align: "right" },
          { type: ["EDIT"], name: "", align: "right" },
        ]}
        handlepageChange={(currentPage, PerPage) =>
          handlepageChange(currentPage, PerPage)
        }
        TableCount={drugRouteslist?.[0]?.total_count}
        incrementCount={page}
        showPagination={true}
        rowsPerPageOptions={[
          { label: "10 Rows", value: 10 },
          { label: "50 Rows", value: 50 },
          { label: "100 Rows", value: 100 },
        ]}
      />

      <GenerateForm
        parent_id={"drugroutes"}
        nocancel
        // open={(open && openIndex === 5) }
        open={(open && openIndex === 5) || state?.openEditFrom ? true : false}
        handlecancel={() => handleCloseForm()}
        save={saveForm}
        header={state?.edit_data ? "Edit Drug Routes" : "Add New Drug Routes"}
        json={[
          {
            componet_type: "text",
            label: "Drug Routes",
            state_name: "drug_routes",
            value: state?.edit_data?.drug_routes ?? "",
            required: true,
            isUpperCase: true,
            disabled: state?.edit_data?.drug_routes ? true : false,
            textLength:20
          },
          {
            componet_type: "text",
            label: "Short Description",
            state_name: "short_description",
            value: state?.edit_data?.short_description ?? "",
            required: true,
            textLength:30
          },
          {
            componet_type: "text",
            label: "Long Description",
            state_name: "long_description",
            value: state?.edit_data?.long_description ?? "",
            required: true,
            textLength:60
          },
          {
            componet_type: "text",
            label: "Route Adverb",
            state_name: "route_adverb",
            value: state?.edit_data?.route_adverb ?? "",
            required: true,
            textLength:60
          },
        ]}
      />
    </React.Fragment>
  );
}

export default withAllContexts(DrugRoutes);
