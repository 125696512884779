import React from "react";
import { withStyles, makeStyles } from "@material-ui/core";
import {
  Button,
  Grid,
  CardContent,
  Card,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { SelectBox } from "../../../components/common/smartForm/component";
import moment from "moment";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "12px",
    color: "#6F6F6F",
    marginBottom: 8,
  },
  dialog: {
    minWidth: "700px",
    "& .MuiDialog-paperWidthSm": {
      minWidth: "700px",
    },
  },
  avatar: {},
  cardroot: {
    boxShadow: "none",
    border: "1px solid #E0E0E0 ",
    borderRadius: "8px",
    "& .MuiCardContent-root": {},
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 10,
    },
  },
  content: {
    backgroundColor: "#F0F0F0",
  },
  timediv: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 5,
  },
  timeGrid: {
    margin: 5,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: "700px",
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "center",
  },
}))(MuiDialogActions);

function ReleaseBlock(props) {
  const classes = useStyles();
  const { parent_id,error } = props;
  const dispatch = useDispatch();
  const handleClose = () => {
    props.modalOpen("Release", "false");
  };
  const [releasereason, setReleaseReason] = React.useState([])

  // const reasonOptionList = useSelector(
  //   (state) => state.appointmentApiSlice.reasonForApptCancelModify
  // );

  const handleReleaseBlock = async () => {
    await props.ReleaseBlock();
    // props.modalOpen("Release", "false");
  };

  React.useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    let releaseReason = await dispatch(
      actions.REASON_FOR_APPT_CANCEL_MODIFY({ type: "REASONRLBLK" })
    );
    
    setReleaseReason(releaseReason.payload.data);
  };

  const SlotSlapCount = () => {
    let SlabCount = 0;
    let SlotCount = 0;
    props.generateBlock?.forEach((v) => {
      if (v.type === "Slab") {
        SlabCount = SlabCount + 1;
      } else {
        SlotCount = SlotCount + 1;
      }
    });

    return `${SlotCount} Slots & ${SlabCount} Slab`;
  };

  const {
    handleFormState,
    ReleaseStartDate,
    ReleaseEndDate,
    Reason,
    selected,
    generateBlock,
  } = props;
  return (
    <div id={`${parent_id}-parent-div`}>
      <Dialog
        id={`${parent_id}-parent-dialog`}
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.openrelease}
      >
        <DialogTitle
          id={`${parent_id}-parent-dialogtitle`}
          onClose={handleClose}
        >
          {selected ? (
            <>
              {props.selectedType === "Blocked"
                ? "Release"
                : "Appointments Affected"}
            </>
          ) : (
            "Release Block"
          )}
        </DialogTitle>
        <DialogContent id={`${parent_id}-parent-dialogcontent`} dividers>
          <Grid id={`${parent_id}-container-grid`} container spacing={2}>
            {!selected && (
              <>
                <Grid id={`${parent_id}-Starts-on-grid`} item xs={6}>
                  <Typography className={classes.label}>Starts on</Typography>
                  <TextField
                    id={`${parent_id}-date-Starts-on-textfield`}
                    variant="outlined"
                    type="datetime-local"
                    defaultValue={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
                    //format={moment().format("LL")}
                    value={ReleaseStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      handleFormState("ReleaseStartDate", e.target.value)
                    }
                    error={error?.ReleaseStartDate}
                  />
                </Grid>
                <Grid id={`${parent_id}-date-ends-on-grid`} item xs={6}>
                  <Typography className={classes.label}>Ends on</Typography>
                  <TextField
                    id={`${parent_id}-date-ends-on-textfield`}
                    variant="outlined"
                    type="datetime-local"
                    // defaultValue={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
                    // moment(new Date()).format("YYYY-MM-DD")
                    inputProps={{ min: ReleaseStartDate }}
                    value={ReleaseEndDate}
                    onChange={(e) =>
                      handleFormState("ReleaseEndDate", e.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error?.ReleaseEndDate}
                  />
                </Grid>
              </>
            )}
            {/* {generateBlock.length > 0 &&
            <> ( */}
            <Grid id={`${parent_id}-Release-grid`} item xs={12}>
              <Card
                id={`${parent_id}-Release-card`}
                className={classes.cardroot}
              >
                <CardContent
                  id={`${parent_id}-Release-cardcontent`}
                  className={classes.content}
                >
                  <Typography
                    id={`${parent_id}-Release-typography`}
                    style={{ fontSize: "18px" }}
                    variant="h6"
                    component="p"
                  >
                    {SlotSlapCount()} Release
                  </Typography>
                </CardContent>
                <CardHeader
                  id={`${parent_id}-Release-cardheader`}
                  avatar={
                    <Avatar
                      id={`${parent_id}-R-avatar`}
                      variant="square"
                      aria-label="recipe"
                      className={classes.avatar}
                    >
                      R
                    </Avatar>
                  }
                  title={props?.Name?.label}
                  subheader={props?.Name?.role}
                />

                <Grid
                  id={`${parent_id}-container-grid`}
                  className={classes.timeGrid}
                  container
                  spacing={2}
                >
                  {generateBlock?.map((v, i) => {
                    return (
                      <Grid
                        id={`${parent_id}-${i}-${v.title.replaceAll(
                          " ",
                          "-"
                        )}-grid`}
                        item
                        xs={4}
                      >
                        <Card
                          id={`${parent_id}-${i}-${v.title.replaceAll(
                            " ",
                            "-"
                          )}-card`}
                          className={classes.cardroot}
                        >
                          <CardContent>
                            <Typography
                              id={`${parent_id}-${i}-${v.title.replaceAll(
                                " ",
                                "-"
                              )}-typography`}
                              style={{ fontSize: "14px" }}
                              variant="h6"
                              component="p"
                            >
                              {v.title}
                            </Typography>
                            <div
                              id={`${parent_id}-${i}-appointment-div`}
                              className={classes.timediv}
                            >
                              <div>
                                <img
                                  id={`${parent_id}-${i}-${v.appointment}-img`}
                                  src="/images/icons8-appointment-scheduling.svg"
                                  alt="Generate"
                                />
                                &nbsp; {v.appointment}
                              </div>
                              <div id={`${parent_id}-${i}-slab-div`}>
                                <img
                                  id={`${parent_id}-${i}-${v.slab}-img`}
                                  src="/images/icons8-step-over.svg"
                                  alt="Generate"
                                />
                                &nbsp; {v.slab}
                              </div>
                              <div id={`${parent_id}-${i}-${v.overTime}-div`}>
                                <img
                                  id={`${parent_id}-${i}-${v.overTime}-img`}
                                  src="/images/icons8-clock.svg"
                                  alt="Generate"
                                />
                                &nbsp; {v.overTime}
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Card>
              <SelectBox
                id={`${parent_id}-Reason-selectbox`}
                list={releasereason !== {} ? releasereason : []}
                top_title={"Reason"}
                placeholder={"Select"}
                requireStart
                value={Reason}
                onchange={(e, value) => handleFormState("Reason", value)}
                error={error?.Reason}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions id={`${parent_id}-Confirm-dialogactions`}>
          <Button
            id={`${parent_id}-Confirm-button`}
            variant="contained"
            autoFocus
            onClick={() => handleReleaseBlock()}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReleaseBlock;
