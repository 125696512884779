/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2020-11-27
 * @modify date 2021-01-25
 * @desc Collection of all helper functions.
 */
/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
import Config from "../config";
import moment from "moment";
import { AlertProps } from ".";

export let checkError = (data) => {
  const isError = data?.error || data?.data?.error;
  let errMsg = "Something went wrong! Try Again.";
  if (
    data?.data?.error ||
    data?.data?.errorMessage ||
    data?.data?.error_description
  ) {
    if (typeof data?.data?.error === "string") {
      errMsg =
        data?.data?.error ||
        data?.data?.errorMessage ||
        data?.data?.error_description;
    } else {
      errMsg = data?.data?.errorMessage || data?.data?.error_description;
    }
  }
  if (errMsg === "invalid_grant" || errMsg === "Invalid user credentials") {
    errMsg = "Invalid OTP";
  }
  return {
    isError: !!isError,
    errMsg,
  };
};

export let ConvertToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val) => {
    return {
      ...val,
      value: val[valueKey],
      label: val[labelKey],
    };
  });
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex, opacity) => {
  if (hex) {
    let tempHex = hex.replace("#", "");
    let r = parseInt(tempHex.substring(0, 2), 16);
    let g = parseInt(tempHex.substring(2, 4), 16);
    let b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
  return null;
};

/**
 * The below function will open an document node in a full screen.
 */
export let OpenFullScreen = (id) => {
  let elem = document.getElementById(id);
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

/**
 * The below function will close the full screen of an document
 * node.
 */
export let CloseFullScreen = (id) => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const _calculateAge = (birthday) => {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const UploadFun = async (file) => {
  return new Promise(async (resolve, reject) => {
    let formData = new FormData();
    formData.append("files", file);

    await fetch(Config.uploadUrl, {
      method: "POST",
      body: formData,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((res) => {
        resolve({
          fileid: res.fileid,
          fileName: file.name,
          filetype: file.type,
          objectid: res.objectid,
          date: file?.lastModified,
        });
      })
      .catch((err) => {
        resolve({});
      });
  });
};

export const uploadImage = (fileList) => {
  return new Promise(async (resolve, reject) => {
    if (!fileList && !Array.isArray(fileList)) {
      resolve([]);
      return;
    }
    let calls = fileList.map(async (file) => {
      return await UploadFun(file);
    });
    Promise.all(calls)
      .then((data) => {
        let dataList = [];
        data.map((val) => {
          if (val.fileid) {
            dataList.push({
              fileid: val.fileid,
              filetype: val.filetype,
              objectid: val.objectid,
              filename: val.fileName,
            });
          }
          return false;
        });

        resolve(dataList);
      })
      .catch((err) => {
        resolve([]);
        console.log(err);
      });
  });
  // fetch("http://164.52.210.54:5000/primarycare/11", requestOptions)
};

export const getImgUrl = (fileId) =>
  fileId ? `${Config.downloadUrl}${fileId}` : "";

// Check the versions
export let semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
  const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = async () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    const names = await caches.keys();
    const promArr = [];
    for (const name of names) {
      promArr.push(caches.delete(name));
    }
    await Promise.all(promArr);
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

export const getTimeSlot = (date, type = "date", isUpdate = false) => {
  const currentTime = new Date();
  let comparedate = date;
  if (type === "unix") {
    comparedate = convertTime(date, type, "DD-MM-YYYY");
  }
  let time = 0;
  // if (!date) {
  //   comparedate = this.state.date;
  // }
  if (moment().format("DD-MM-YYYY") === comparedate || isUpdate) {
    let currentHour = currentTime.getHours();
    if (isUpdate) {
      currentHour = new Date(date * 1000).getHours();
    }
    if (currentHour <= 12) {
      time = 0;
    } else if (currentHour > 12 && currentHour <= 15) {
      time = 1;
    } else if (currentHour > 15 && currentHour <= 18) {
      time = 2;
    } else {
      time = 3;
    }
  }
  return time;
};

export const getTimeString = (hour, minutes) => {
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (hour === 0) {
    return `12:${minutes} AM`;
  }
  if (hour === 12) {
    return `12:${minutes} PM`;
  }
  if (hour <= 11) {
    if (hour < 10) {
      hour = `0${hour}`;
    }
    return `${hour}:${minutes} AM`;
  }
  return `${hour - 12 < 10 ? "0" : ""}${hour - 12}:${minutes} PM`;
};

export const convertTime = (time, inputFormat, outputFormat, type = "date") => {
  if (type === "date") {
    if (inputFormat !== "unix") {
      return moment(time, inputFormat).format(outputFormat);
    }
    const date = new Date(time * 1000);
    return moment(date).format(outputFormat);
  }
  if (type === "diff") {
    if (inputFormat === "unix") {
      return moment().diff(moment.unix(time), outputFormat);
    }
  }
  return moment().diff(moment(time, inputFormat), outputFormat);
};

export const treeData = [
  {
    id: 1,
    name: "Apollo group of hospitals",
    checkedValue: "unchecked",
    children: [
      {
        id: 2,
        name: "Apollo hospitals - India",
        checkedValue: "unchecked",
        children: [
          {
            id: 3,
            name: "Apollo hospitals - India",
            checkedValue: "unchecked",
          },
          {
            id: 4,
            name: "Apollo hospitals - India",
            checkedValue: "unchecked",
          },
        ],
      },
      {
        id: 5,
        name: "Apollo hospitals - China",
        checkedValue: "unchecked",
      },
      {
        id: 6,
        name: "Apollo hospitals - Canada",
        checkedValue: "unchecked",
      },
      {
        id: 7,
        name: "Apollo hospitals - USA",
        checkedValue: "unchecked",
      },
      {
        id: 8,
        name: "Apollo hospitals - Japan",
        checkedValue: "unchecked",
      },
      {
        id: 9,
        name: "Apollo hospitals - Brazil",
        checkedValue: "unchecked",
      },
      {
        id: 10,
        name: "Apollo hospitals - France",
        checkedValue: "unchecked",
      },
    ],
  },
];

export const checkMaxDateValidation = (maxDate, value) => {
  let errorMessage = null;
  if (moment(value).diff(maxDate) >= 0) {
    return errorMessage;
  }
  return (errorMessage = `date must be ${moment(maxDate).format(
    "DD/MM/YYYY"
  )} or greater`);
};
export const checkDateValidation = (maxDate, value) => {
  let errorMessage = null;
  if (moment(value).diff(maxDate) >= 0) {
    return errorMessage;
  }
  return (errorMessage = `date must be ${moment(maxDate).format(
    "DD/MM/YYYY"
  )} or greater`);
};

export const btAllMonths = (dateStart, dateEnd) => {
  var timeValues = [];
  while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
    timeValues.push(dateStart.format("MMM"));
    dateStart.add(1, "month");
  }
  return timeValues.filter(onlyUnique);
};

export function enumerateDaysBetweenDates(startDate, endDate) {
  let date = [];
  while (moment(startDate) <= moment(endDate)) {
    date.push(moment(startDate).format("dddd"));
    startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
  }
  return date.filter(onlyUnique);
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function weekOfMonth(input = moment()) {
  const firstDayOfMonth = input.clone().startOf("month");
  const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");
  const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

  return Math.ceil((input.date() + offset) / 7);
}

export function weekOfMonthbasedOnDay(day) {
  if (day < 8) {
    return 1;
  } else if (day > 7 && day < 15) {
    return 2;
  } else if (day > 14 && day < 22) {
    return 3;
  } else if (day > 21 && day < 29) {
    return 4;
  } else {
    return 5;
  }
}

export function alertCall(setSnack, type, CustomMsg) {
  let message = "Created Successfully";
  let severity = AlertProps.severity.success;
  if (type === "update") {
    message = "Updated Successfully";
    severity = AlertProps.severity.success;
  } else if (type === "delete") {
    message = "Deleted Successfully";
    severity = AlertProps.severity.success;
  } else if (type === "mandatory") {
    message = "Please fill all the mandatory field";
    severity = AlertProps.severity.error;
  } else if (type === "processing") {
    message = "Processing";
    severity = AlertProps.severity.info;
  } else if (type === "unique") {
    // message = "Please add unique value"; 
    message = "Entered code already exists. Code value must be unique."; 
    severity = AlertProps.severity.error;
  }
  else if (type === "minMax")
  {
    message = "Field should be larger than the other";
    severity = AlertProps.severity.error;
  }
   else if (type === "error") {
    message = "Something went Wrong";
    severity = AlertProps.severity.error;
  } 
  if (CustomMsg) {
    message = CustomMsg;
  }
  setSnack({
    open: true,
    severity: severity,
    msg: message,
    vertical: AlertProps.vertical.top,
    horizontal: AlertProps.horizontal.right,
  });
}

export const getEntityTypeCode = (type) => {
  switch (type) {
    case "OT002": // Organization
      return "OT001"; // Enterprise
    case "OT003": // Facility
      return "OT002"; // Organization
    default:
      break;
  }
};

export const valitationFunc = (state, list) => {
  let error = false;
  let errorState = {};
  list.forEach((val) => {
    if (state[val] === null || state[val] === undefined) {
      errorState[val] = true;
      error = true;
    } else {
      if (typeof state[val] === "string") {
        if (!state[val]) {
          errorState[val] = true;
          error = true;
        }
      } else if (typeof state[val] === "object") {
        if (Array.isArray(state[val])) {
          if (state[val]?.length === 0) {
            errorState[val] = true;
            error = true;
          }
        } else {
          if (Object.keys(state[val])?.length === 0) {
            errorState[val] = true;
            error = true;
          }
        }
      }
    }
  });

  return {
    error,
    errorState,
  };
};

export const validationForm = (list,states) => {
  let error =false;
  let errorState={}
  list.forEach((val) => {
    if (val.required) {
      if (
        val?.componet_type !== "select" &&
      states[val?.state_name]?.length === 0
      ) {
        errorState[val.state_name] = true;
      } else if (val?.componet_type === "select") {
        if (
         states[val?.state_name]
            ? Object.keys(states[val?.state_name])?.length === 0
            : true
        )
          errorState[val.state_name] = true;
      } else if (val?.componet_type === "textField_select") {
        if (
         states[val?.state_name]?.type
            ? Object.keys(states[val?.state_name]?.type)?.length === 0
            : true
        ) {
          errorState[val.state_name] = true;
        }
        if (states[val?.state_name]?.value?.length === 0) {
          errorState[val.state_name] = true;
        }
      }
    } else {
      errorState[val.state_name] = false;
    }
  });
  if (
    errorState &&
    Object.keys(errorState).length > 0 &&
    Object.keys(errorState).filter((val) => errorState[val] === true).length > 0
  ) {
   
    error= true;
  } else {
    error= false;
  }
  return {
    error,
    errorState,
  };
};


export const validationFormEnbty = (list,states) => {
  let error =false;
  let errorState={}
  
  list.forEach((val) => {
    // if (val.required) {
      if ( val?.componet_type !== "select" && states[val?.state_name]?.length > 0) {
        errorState[val.state_name] = false;
      } else if (val?.componet_type === "select") {
        if (states[val?.state_name] ? Object.keys(states[val?.state_name])?.length > 0 : false)
          errorState[val.state_name] = false;
      } else if (val?.componet_type === "textField_select") {
        if (states[val?.state_name]?.type ? Object.keys(states[val?.state_name]?.type)?.length > 0 : false) {
          errorState[val.state_name] = false;
        }
        if (states[val?.state_name]?.value?.length > 0) {
          errorState[val.state_name] = false;
        }
      } else if(val?.componet_type === "date"){
        if (states[val?.state_name] ? states[val?.state_name] : false)
          errorState[val.state_name] = false;
      }
    // } 
  });

  if (errorState && Object.keys(errorState).length > 0 && Object.keys(errorState).filter((val) => errorState[val] === false).length > 0) {
    error= false;
  } else {
    error= true;
  }


  return {
    error,
    errorState: [],
  };
};

export const isIntegerValue = (value) => {
  const integerPattern = /^[0-9]*$/;
  return integerPattern.test(value);
};

export const uploadFileList = (fileList) => {
  return new Promise(async (resolve, reject) => {
    if (!fileList && !Array.isArray(fileList)) {
      resolve([]);
      return;
    }
    let calls = fileList.map(async (file) => {
      return await UploadFun(file);
    });
    Promise.all(calls)
      .then((data) => {
        let dataList = [];
        data.map((val) => {
          if (val.fileid) {
            dataList.push({
              fileid: val.fileid,
              filetype: val.filetype,
              objectid: val.objectid,
              fileName: val.fileName,
              date: val?.date?.toString(),
            });
          }
          return false;
        });
        resolve(dataList);
      })
      .catch((err) => {
        resolve([]);
        console.log(err);
      });
  });
};