import React from "react";
import { Paper, Typography, makeStyles, Grid, 
  //TextField,
   Button, Drawer, Avatar } from "@material-ui/core";
import { CommonTable } from "../../../components";
import { useSelector } from "react-redux";
//import { useDispatch } from "react-redux";
//import { AlertProps } from "../../../utils";
import styles from "../../../components/common/smartForm/styles";
import { generateComponent } from "../../../components/common/smartForm/formsList";
import { withAllContexts } from "../../../HOCs";
import { validationForm } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    margin: "10px 6px 10px 6px"

  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600
  },
  title: {
    fontFamily: "poppinsemibold",
    display: "inline",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15
  },
}));

function ApplicableFacilityDetails(props) {
  const storeData = useSelector((state) => state?.organizationConfigSlice);
  const classes = useStyles();
  const classes_ = styles();
  const { applicableFacilities, handleFormState, parent_id } = props;
  let ld = storeData?.health_care_service_master_location_description.data ?? []
  let health_care_service_master = storeData?.health_care_service_master?.data?.facilityName ?? []
  let llt = storeData?.health_care_service_master?.data?.locationType ?? []
  const [state, setState] = React.useState({
    open: false,
    saveloading: false,
    edit_data: false,
    editIndex: null,
    loading: false,
    list: [
      {
        componet_type: "select",
        options: health_care_service_master,
        label: "Facility Name",
        state_name: "facilityName",
        value: "",
        required: true,
      },
      {
        componet_type: "select",
        options: llt,
        label: "Location Type",
        state_name: "locationType_",
        value: "",
        required: false,
      },
      {
        componet_type: "select",
        label: "Location ID & Description",
        options: ld,
        state_name: "locationIdDescription",
        value: "",
        required: false,
      },
    ],
    states: {
      facilityName: "",
      locationType_: "",
      locationIdDescription: "",
      _id: "",
    },
    error:{}
  });

  React.useEffect(() => {

  })

  //let dispatch = useDispatch();
  const handleChange = (type, val) => {
    const state_ = state;
    if (type === "locationType_") {
      state_.states.locationIdDescription = "";

      props.getLocationIdByType(type, val?.value);
    }
    state_.states[type] = val;
    setState({ ...state_ })
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      loading: false,
      editIndex: null,
      states: {
        facilityName: "",
        locationType_: "",
        locationIdDescription: "",
        id: "",
      },
    });
  };
  const saveApplicableFacility = () => {
    setState({ ...state, loading: true })
      let { error, errorState } = validationForm(state.list, state.states);
    if (!error) {
      let data = state.states;
      let obj = {};
      obj["facilityName"] = data.facilityName;
      obj["locationType_"] = data.locationType_;
      obj["locationIdDescription"] = data.locationIdDescription;
      obj["_id"] = data._id;
      let list = JSON.parse(JSON.stringify(applicableFacilities));
      if (state.editIndex || state.editIndex === 0) {
        list[state.editIndex] = {
          ...list[state.editIndex],
          ...obj
        }
      } else {
        list.push(data)
      }
      handleFormState("applicableFacilities", list);
      handleDrawerClose()
    } else {
      setState({ ...state, loading: false, error: errorState });
      props.alert.setSnack("mandatory")
    }
  }

  const handleEdit = (e, data, index) => {
    if (data?.locationType_) {
      props.getLocationIdByType("locationType_", data?.locationType_?.value)
    }
    setState({
      ...state,
      editIndex: index,
      open: true,
      states: {
        facilityName: data?.facilityName,
        locationType_: data?.locationType_,
        locationIdDescription: data?.locationIdDescription,
        _id: data?._id,
      },
    });
  };
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes.root}>
        <Typography
          id={`${parent_id}-AGE-CATEGORY-typography`}
          className={classes.title} variant="h6">APPLICABLE FACILITIES</Typography>

        <Button
          id={`${parent_id}-Add-New-button`}
          className={classes.addBtn} onClick={() => setState({ ...state, open: true })} color="primary" >+ Add New</Button>
        <Grid
          id={`${parent_id}-table-grid`}
          container className={classes.form}>
          <CommonTable
            parent_id={"category-details"}
            handleEdit={(e, data, index) => handleEdit(e, data, index)}
            rightAlign={["Action"]}
            Header={["Facility Name", "Location Type", "Location ID & Description", "Action"]}
            dataList={applicableFacilities}
            tableData={[
              { type: ["TEXT"], name: "facilityName", optionLabel: "label" },
              { type: ["TEXT"], name: "locationType_", optionLabel: "label" },
              { type: ["TEXT"], name: "locationIdDescription", optionLabel: "label" },
              { type: ["EDIT"], name: "", align: "right" }
            ]}
          />
        </Grid>

        <Drawer
          id={`${parent_id}-Drawer`}
          className={classes_.drawer}
          anchor="right"
          open={state.open}
          classes={{
            paper: classes_.drawerPaper,
          }}
        >
          {
            <div
              id={`${parent_id}-avatar-div`}
            >
              <Avatar
                id={`${parent_id}-close-avatar`}
                src="/images/close.svg"
                className={classes_.large}
                onClick={() => handleDrawerClose()}
              />
              <div
                id={`${parent_id}-Applicable-title-div`}
              >
                <div
                  id={`${parent_id}-Applicable-title-sub-div`}
                  className={classes_.drawerHead}>
                  <Typography
                    id={`${parent_id}-Applicable-title-typography`}
                    className={classes_.headerMain}>
                    {state.editIndex !== null ? "Edit Applicable Facilities" : 'Add Applicable Facilities'}
                  </Typography>
                </div>

                <Grid
                  id={`${parent_id}-button-grid`}
                  container
                  direction="row"
                  style={{ padding: "10px 20px" }}
                >
                  <Grid
                    id={`${parent_id}-button-sub-grid`}
                    item xs={12}>
                    {state?.list?.length > 0 &&
                      state?.list?.map((val) => {
                        let opt = val.state_name === "locationIdDescription" ? storeData?.health_care_service_master_location_description.data :
                          val.state_name === "facilityName" ? storeData?.health_care_service_master?.data?.facilityName :
                            val.state_name === "locationType_" ? storeData?.health_care_service_master?.data?.locationType : [];
                        return generateComponent({
                          data: { ...val, options: opt },

                          handleChange: handleChange,
                          state: state,
                          classes: classes_,
                          parent_id: parent_id
                        });
                      })}
                    <div
                      id={`${parent_id}-button-div`}
                      className={classes_.btnPosition}>
                      <Button
                        id={`${parent_id}-Saving-Save-button`}
                        disabled={state?.loading ?? false}
                        variant={"contained"}
                        color={"primary"}
                        style={{ marginTop: 20 }}
                        size={"small"}
                        contained
                        onClick={() => saveApplicableFacility()}
                      >
                        {state?.loading ? "Saving..." : "Save"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          }
        </Drawer>
      </Paper>
    </React.Fragment>
  )
}

export default withAllContexts(ApplicableFacilityDetails);