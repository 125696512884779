import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
// import colors from '../../../utils/colors/colors';
import "./status.css";
/**
 *
 * @param {object} props - required props in StatusComponent
 * @returns {React.ReactElement} - returns the StatusComponent
 */
const StatusComponent = (props) => {
  const { enable, overAllStatus, onShowStatus } = props;
  console.log(enable, "onShowStatus");

  return (
    <div
      className={`${overAllStatus && "statusGrid"} statusText`}
      sx={{
        background: overAllStatus && (enable ? "#3bb213" : "#ec6a49"),
      }}
      onClick={(e) => onShowStatus(e)}
    >
      <div
        className={enable ? "activePoint" : "inActivePoint"}
        sx={{
          backgroundColor: enable ? "#3bb213" : "#ec6a49",
        }}
      />
      <div className={enable ? "active" : "inActive"}>
        {enable ? "Active" : "Inactive"}
      </div>
      {/* <Typography
        type="caption"
        text={enable ? "Active" : "Inactive"}
        colorType={enable ? "active" : "inActive"}
      /> */}
    </div>
  );
};

StatusComponent.propTypes = {
  enable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overAllStatus: PropTypes.bool,
  onShowStatus: PropTypes.func,
};
StatusComponent.defaultProps = {
  enable: null,
  overAllStatus: false,
  onShowStatus: () => null,
};
export default StatusComponent;
