import React from "react";
import { VerticalList, CommonTable } from "../../../../components";
import { OrderCategory } from "./orderCategory";
import { OrderNature } from "./orderNature";
import { OrderMode } from "./orderMode";
import { OrderCatalog } from "./orderCatalog";
import { OrderType } from "./orderType";
import { PerformingLocation } from "./performingLocation";
import { AlertRestrictions } from "./alertsRestrictions";
import { connect } from "react-redux";
import { actions } from 'primary_care_admin_binder';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openIndex: null,
      isedit: false,
      currentTab:'',
      search:''
    };
    this.handleAddBtn = this.handleAddBtn.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeState = this.changeState.bind(this);
  }
  handleAddBtn = (index) => {
    let { open } = this.state;
    this.setState({
      open: !open,
      openIndex: index,
      isedit: false
    });
  };
  changeState = (name, value) => {
    this.setState({ [name]: value });
  };
  changeTab = () => {
    this.setState({ open: false });
  };
  iseditfuncy = () => {

    this.setState({ isedit: true });
  };

  currentTab = (val) => {
    this.setState({
      currentTab:val
  })
    return val
  };

  getPlaceHolder = () => {
    if(this.state?.currentTab === "orderCategory" || this.state?.currentTab === "orderMode"){
      return "search code & description"
    }
    if(this.state?.currentTab === "orderNature"){
      return "search Nature & description"
    }
    console.log(this.state?.currentTab,"this.state?.currentTab")
  }

  handleSearch = async(value = "") => {
   debugger
    if(this.state?.currentTab === "orderCategory"){
 
     if (value?.length > 0) {
       await this.props.GET_ALL_ORDER_CATEGORY({
         search:value.trim(),
         page: 0,
         perPage: 10
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.GET_ALL_ORDER_CATEGORY({});
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };

    if(this.state?.currentTab === "orderNature"){
 
     if (value?.length > 0) {
       await this.props.ORDERNATUREREAD({
         search:value.trim(),
         page: 0,
         perPage: 10
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.ORDERNATUREREAD({page: 0, perPage: 10});
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };

    if(this.state?.currentTab === "orderMode"){
 
     if (value?.length > 0) {
       await this.props.GET_ALL_ORDER_MODE({
         search:value.trim(),
         page: 0,
         perPage: 10
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.GET_ALL_ORDER_MODE({page: 0, perPage: 10});
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };
    if(this.state?.currentTab === "orderType"){
 
     if (value?.length > 0) {
       await this.props.ORDERTYPEREAD({
         search:value.trim(),
         page: 0,
         perPage: 10
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.ORDERTYPEREAD({page: 0, perPage: 10});
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };
    if(this.state?.currentTab === "orderCatalog"){
 
     if (value?.length > 0) {
       await this.props.READ_ORDER_CATALOG({
         search:value.trim(),
         page: 0,
         perPage: 10
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.READ_ORDER_CATALOG({page: 0, perPage: 10});
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };
    if(this.state?.currentTab === "Performinglocation"){
 
     if (value?.length > 0) {
       await this.props.READ_PERFORMINGLOCATION({
         search:value.trim(),
         page: 0,
         perPage: 10
       }); 
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.READ_PERFORMINGLOCATION({page: 0, perPage: 10});
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };
    if(this.state?.currentTab === "Approvals"){
 
     if (value?.length > 0) {
       await this.props.READ_ALERT({
         search:value.trim(),
         page: 0,
         perPage: 10
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.READ_ALERT({page: 0, perPage: 10});
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };
 
   }


  render() {
    const { open, openIndex, isedit } = this.state;
    return (
      <React.Fragment>
        <VerticalList
          parent_id={"orders"}
          isLeftList={true}
          addFunc={this.handleAddBtn}
          changeTab={this.changeTab}
           // searchCmpProps starts here
           id={"orders"}
           placeholder={this?.getPlaceHolder()}
           // loading={this.props?.searchLoading}
           onSearchChange={this?.handleSearch}
           hideSearchBar={false}
           hideFilter={true}
           // onFilterClicked={this.props?.onFilterClicked}
           // isFilterApplied={this.props?.isFilterApplied}
          TabList={[
            {
              name: "Order Category",
              component: (
                <OrderCategory
                  parent_id={"OrderCategory"}
                  changeState={this.changeState}
                  open={open}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            {
              name: "Order Nature",
              component: (
                <OrderNature
                  parent_id={"order"}
                  changeState={this.changeState}
                  open={open}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            {
              name: "Order Mode",
              component: (
                <OrderMode
                  parent_id={"order"}
                  changeState={this.changeState}
                  open={open}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
              //  <DrugCategory open={open} changeState={this.changeState} openIndex={openIndex} />
            },
            {
              name: "Order Type",
              component: <OrderType
                parent_id={"order"}
                changeState={this.changeState} open={open} openIndex={openIndex}
                currentTab={this?.currentTab} />
            },
            {
              name: "Order Catalog",
              component: (
                <OrderCatalog
                  parent_id={"order"}
                  changeState={this.changeState}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            {
              name: "Performing Location",
              component: (
                <PerformingLocation
                  parent_id={"order"}
                  changeState={this.changeState}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            {
              name: "Alert, Restrictions, Approvals",
              component: (
                <AlertRestrictions
                  parent_id={"order"}
                  changeState={this.changeState}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                  isedit={isedit}
                  iseditfuncy={this.iseditfuncy}
                  currentTab={this?.currentTab}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  
});

export default connect(mapStateToProps, actions)(Orders)
