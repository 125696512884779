import React from 'react';
import './IconLabel.css';
import PropTypes, { shape } from 'prop-types';
/**
 *
 * @param {object} props - props required in IconLabel
 * @returns {React.ReactElement} - returns the IconLabel
 */
const IconLabel = (props) => {
  const { EntryData } = props;
  return (
    <div className="App">
      {EntryData?.map((Items, index) => {
        const key = index;
        return (
          <div className="Container" key={key}>
            <div className="Icon">
              <img src={Items.Image} alt="" />
            </div>
            <div className="content-container">
              <div className="Title">{Items.head}</div>
              <div className="Content">{Items.body}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default IconLabel;
IconLabel.propTypes = {
  EntryData: PropTypes.arrayOf(shape({}))
};
IconLabel.defaultProps = {
  EntryData: []
};
