import React from "react";
import { GenerateForm, CommonTable } from "../../../../../components";
import { withAllContexts } from "../../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
//import { AlertProps} from "../../../../../utils"
class MeasureCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditFrom: false,
      saveloading: false,
      edit_data: null,
      status: true,
      perPage: 10,
      page: 0,
    };
    this.saveForm = this.saveForm.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    });
    await this.props.GET_VITALS_MEASURE_CODE({ page: (currentPage - 1) * PerPage, perPage: PerPage });
  };

  async componentDidMount() {
    await this.props.GET_VITALS_MEASURE_CODE({page: this.state.page, perPage: this.state.perPage});
    this.props.currentTab("measureCode");
  }

  saveForm = async (data) => {
    this.setState(
      {
        ...data,
      },
      async () => {
        // this.props.backDrop.setBackDrop({
        //     ...this.props.backDrop,
        //     open: true,
        //     message: "Updating...",
        // });
        const upsertMeasureCode = await this.props.UPSERT_MEASURE_CODE({
          dataList: this.state,
        });
        if (
          upsertMeasureCode?.payload?.data?.error === "Validation Not Passed"
        ) {
          this.props.alert.setSnack("unique");
        } else {
          let type = this.state.edit_data ? "update" : "success";
          this.props.alert.setSnack(type);
          this.handleCloseForm();
          await this.props.GET_VITALS_MEASURE_CODE({page: this.state.page, perPage: this.state.perPage});
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "",
          });
        }
        console.log("upsertMeasureCode", upsertMeasureCode);
      }
    );
  };

  handleEdit = (e, data) => {
    this.setState({
      openEditFrom: true,
      edit_data: data,
      status: data.status,
    });
  };
  handleCloseForm = () => {
    const { changeState } = this.props;
    changeState("open", false);
    this.setState({
      openEditFrom: false,
      edit_data: null,
    });
  };
  handleCheckBox = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Updating...",
    });
    await this.props.UPDATE_MEASURE_CODE_STATUS({
      editId: data._id,
      status: e.target.checked,
    });
    await this.props.GET_VITALS_MEASURE_CODE({page: this.state.page, perPage: this.state.perPage});
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
    this.props.alert.setSnack("update");
  };
  render() {
    const { edit_data, openEditFrom } = this.state;
    const {
      open,
      openIndex,
      //parent_id
    } = this.props;
    return (
      <React.Fragment>
        <CommonTable
          rightAlign={["Status", "Action"]}
          handleEdit={this.handleEdit}
          handleCheckBox={this.handleCheckBox}
          Header={[
            "S.No",
            "Code",
            "Short Description",
            "Long Description",
            "Status",
            "Action",
          ]}
          dataList={this.props.measureCodeList.data}
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "ObsValMeasCode" },
            { type: ["TEXT"], name: "shortdesc" },
            { type: ["TEXT"], name: "longdesc" },
            { type: ["CHECKBOX"], name: "status", align: "right" },
            { type: ["EDIT"], name: "", align: "right" },
          ]}
          parent_id={"measure_code"}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={this.props.measureCodeList.data?.[0]?.TotalCount}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <GenerateForm
          parent_id={"measure_code"}
          // loading={saveloading}
          // open={true}
          nocancel
          open={(open && openIndex === 0) || openEditFrom}
          save={this.saveForm}
          handlecancel={() => this.handleCloseForm()}
          header={edit_data ? "Edit Measure Code" : "Add Measure Code"}
          json={[
            {
              componet_type: "text",
              label: "Measure Code",
              state_name: "ObsValMeasCode",
              value: this.state?.edit_data?.ObsValMeasCode ?? "",
              required: true,
              isUpperCase: true,
              disabled: this.state?.edit_data ? true : false,
              textLength: 20
            },
            {
              componet_type: "text",
              label: "Short Description",
              state_name: "shortdesc",
              value: this.state?.edit_data?.shortdesc ?? "",
              required: true,
            },
            {
              componet_type: "text",
              label: "Long Description",
              state_name: "longdesc",
              value: this.state?.edit_data?.longdesc ?? "",
              required: true,
            },
            // {
            //     componet_type: "switch",
            //     label: "Status",
            //     state_name: "status",
            //     value: this.state?.edit_data?.status ?? "",
            //     required: true
            // }
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  measureCodeList: state?.vitalsMeasureCodeSlice?.meadure_code_list,
  state: state,
});

export default connect(mapStateToProps, actions)(withAllContexts(MeasureCode));
