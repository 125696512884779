import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import copy from "fast-copy";
// Components
import {
  CommonTable,
  OverlayCompt,
  StrengthModal,
} from "../../../../../components";
import AddNewDrugMaster from "./addNewDrugMaster";
// Icons
import { LinkIcon } from "../../../../../components/svg";
// Contexts
import { withAllContexts } from "../../../../../HOCs";

const MIN_SEARCH_COUNT = 3;

function DrugMaster(props) {
  const dispatch = useDispatch();
  const { parent_id, search = "" } = props;
  const drugMasterlist = useSelector(
    (state) => state?.drugMasterSlice?.drug_master_list
  );

  React.useLayoutEffect(() => {
    props?.currentTab("drugMaster");
}, [])


  const [state, setState] = React.useState({
    openEditFrom: false,
    saveloading: false,
    edit_data: null,
    status: true,
  });
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [showModal, setshowModal] = React.useState(false);
  const [strength, setStrength] = React.useState([]);
  const [filterLocal, setFilterLocal] = React.useState(false);
  const [masterData, setMasterData] = React.useState({
    fullData: [],
    filteredList: [],
    visibleList: [],
  });

  React.useEffect(() => {
    handleSearch();
  }, [search]);

  const handleSearch = async () => {
    if (
      search?.length === 0 ||
      (search?.length === MIN_SEARCH_COUNT && !filterLocal)
    ) {
      callFullRead(page, perPage, search, search?.length !== 0);
      setFilterLocal(search?.length >= 3);
    } else if (search?.length < MIN_SEARCH_COUNT) {
      setFilterLocal(false);
    } else if (filterLocal) {
      filterLocalList(0, 10);
    }
  };

  const callFullRead = (page, perPage, searchQuery, fetchWithoutLimit) => {
    const withLimitPayload = {
      page: page,
      perpage: perPage,
      searchQuery: searchQuery,
    };

    const withOutLimitPayload = {
      searchQuery: searchQuery,
      isWithoutLimit: true,
    };

    Promise.resolve(
      dispatch(
        actions.DRUG_MASTER_READ(
          fetchWithoutLimit ? withOutLimitPayload : withLimitPayload
        )
      )
    )
      .then((res) => {
        let filteredData = filterData(res?.payload?.data, searchQuery);
        let copiedData = copy(filteredData);

        let listToShow = fetchWithoutLimit
          ? limitData(copiedData, page, perPage)
          : copiedData;

        let filteredMasterData = {
          fullData: res?.payload?.data,
          filteredList: filteredData,
          visibleList: listToShow,
        };
        setMasterData(filteredMasterData);
      })
      .catch((err) => console.error(err));
  };

  const filterData = (data, searchQuery) => {
    if (searchQuery?.length) {
      let res = data?.filter(
        (item) =>
          item?.DrugCode?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
          item?.DrugType?.longdesc
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase()) ||
          item?.ShortDesc?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
      return res;
    }
    return data;
  };

  const filterLocalList = (page, perPage) => {
    let list = drugMasterlist?.data;

    list = filterData(list, search);

    let filteredList = copy(list);
    let copiedList = copy(list);

    let final = limitData(copiedList, page, perPage);

    setMasterData({
      fullData: drugMasterlist?.data,
      filteredList: filteredList,
      visibleList: final,
    });
  };

  const limitData = (data, page, perPage) => {
    let start = page;
    let end = start + perPage;
    return data?.slice(start, end);
  };

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    if (filterLocal) {
      filterLocalList((currentPage - 1) * PerPage, PerPage);
    } else {
      callFullRead((currentPage - 1) * PerPage, PerPage, search);
    }
  };

  const handleEdit = (e, data, index) => {
    setState({
      openEditFrom: true,
      edit_data: data,
      status: data?.status,
      routeData: {
        docId: data?._id,
        docKey: data?._key,
      },
    });
    props.closeFrom(7);
  };

  const statusChange = async (e, values, index) => {
    let data = {
      key: values._key,
      status: e.target.checked,
    };
    await dispatch(actions.DRUG_MASTER_STATUS_CHANGE(data));
    await dispatch(
      actions.DRUG_MASTER_READ({
        page: page,
        perpage: perPage,
        searchQuery: search,
      })
    );
    props.alert.setSnack("update");
  };

  const closeFormStatus = async () => {
    setState({
      openEditFrom: false,
      saveloading: false,
      edit_data: null,
      status: true,
    });
    await props?.setSearch("");
    callFullRead(0, 10, "", false);
    await props?.changeState("open", false);
  };

  const condition = (value, type) => {
    if (type === "DRAFT") {
      if (value.draft_status) {
        return true;
      } else {
        return false;
      }
    } else if (type === "CHECKBOX") {
      if (value.draft_status) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleView = (e, data, index) => {
    setshowModal(true);
    setStrength(data?.StrengthValuesPossible);
  };

  return (
    <div id={`${parent_id}-parent-div`}>
      <CommonTable
        parent_id={"drugMaster"}
        style={{ height: "calc(100vh - 257px)" }}
        handleEdit={handleEdit}
        handleCheckBox={statusChange}
        handleView={handleView}
        Header={[
          "Code & Description",
          "Class",
          "Category",
          "Type",
          "Form",
          "Route",
          "Strength",
          "Status",
          "Action",
        ]}
        loading={drugMasterlist?.loading}
        dataList={masterData.visibleList}
        tableData={[
          { type: ["TEXT"], name: "code_des" },
          { type: ["TEXT"], name: "class" },
          { type: ["TEXT"], name: "category" },
          { type: ["TEXT"], name: "type" },
          { type: ["TEXT"], name: "form" },
          { type: ["TEXT"], name: "route" },
          {
            type: ["VIEWICON"],
            name: "",
            icon: <LinkIcon color="#0071F2" width={14} height={14} />,
            hideLabel: true,
          },
          {
            type: ["STATUS"],
            name: "status",
            align: "center",
          },
          { type: ["EDIT"], name: "", align: "center" },
        ]}
        condition={condition}
        handlepageChange={(currentPage, PerPage) =>
          handlepageChange(currentPage, PerPage)
        }
        TableCount={
          filterLocal
            ? masterData.filteredList?.length
            : drugMasterlist?.data?.[0]?.Total?.[0]
        }
        incrementCount={page}
        showPagination={true}
        rowsPerPageOptions={[
          { label: "10 Rows", value: 10 },
          { label: "50 Rows", value: 50 },
          { label: "100 Rows", value: 100 },
        ]}
      />
      <OverlayCompt
        parent_id={"drugMaster"}
        open={props?.open && props?.openIndex === 7}
        children={
          <React.Fragment>
            {props?.open && props?.openIndex === 7 && (
              <AddNewDrugMaster
                parent_id={"drugMaster"}
                data={state?.routeData ?? null}
                closeForm={() => closeFormStatus()}
              />
            )}
          </React.Fragment>
        }
      />
      <StrengthModal
        showModal={showModal}
        handleCloseModal={() => setshowModal(false)}
        data={strength}
      />
    </div>
  );
}
export default withAllContexts(DrugMaster);
