import React, { useEffect } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  Button,
  Popover,
} from "@material-ui/core";
import { CommonTabList } from "../../../../../components";
import ServiceDrugCatalogTable from "./serviceDrugCatalogTable";
// import { TextInput } from "qdm-component-library";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import Divider from "@material-ui/core/Divider";
// import Switch from "@material-ui/core/Switch";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "@material-ui/icons/Close";
import MonitoringFormModal from "./modal/monitoringModal";
import PharmacyIVFormModal from "./modal/pharmacyIVModal";
import PharmacyMedicationFormModal from "./modal/pharmacyMedicationModal";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  modalHead: {
    letterSpacing: "0px",
    color: "#242424",
    opacity: 1,
    fontSize: "14px",
    fontWeight: "600",
  },
  modalIcon: {
    font: "normal normal medium 14px/20px Poppins",
    letterSpacing: "0px",
    color: "#0071F2",
    textTransform: "capitalize",
    opacity: 1,
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  title: {
    fontFamily: "poppinsemibold",
    display: "inline",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  modalHeader: {
    font: "normal normal 600 16px/25px Poppins",
    color: "#101010",
    textTransform: "capitalize",
    opacity: 1,
    fontSize: "16px",
  },
  label: {
    //letterSpacing: "var(--unnamed-character-spacing-0)",
    //color: "var(--secondary-text-6f6f6f)",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#6F6F6F",
    textTransform: "capitalize",
    opacity: 1,
    fontSize: "12px",
    padding: "10px 0px 5px 0px",
  },
  req_red: {
    color: "#FF4D4A",
  },
  insideLabel: {
    //letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "center",
    font: "normal normal normal 14px/20px Poppins",
    letterSpacing: "0px",
    color: "#323232",
    opacity: 1,
    fontSize: "14px",
  },
}));

function ServiceDrugCatalogList(props) {
  // const json_ = [
  //   {
  //     name: "All",
  //     component: (
  //       <ServiceDrugCatalogTable id={0} tableData={props?.tableData} />
  //     ),
  //   },
  //   {
  //     name: "Monitoring",
  //     component: (
  //       <ServiceDrugCatalogTable id={1} tableData={props?.tableData} />
  //     ),
  //   },

  //   {
  //     name: "Pharmacy",
  //     component: (
  //       <ServiceDrugCatalogTable id={2} tableData={props?.tableData} />
  //     ),
  //   },
  // ];
  // const json = [];
  const [state, setState] = React.useState({
    tableHeader: [],
  });
  const classes = useStyles();
  const { parent_id } = props;
  const storeData = useSelector((state) => state?.orderSetMasterSlice);

  useEffect(() => {
    constructTableHeader();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData?.order_set_masters]);

  useEffect(() => {
    constructTableHeader();
  }, [props?.tableData]);

  const constructTableHeader = async () => {
    const arr = [
      {
        name: "All",
        component: (
          <ServiceDrugCatalogTable
            id={0}
            value={0}
            tableData={props?.tableData}
            handleEditModal={props?.handleEditModal}
            deleteTableRow={props?.deleteTableRow}
          />
        ),
      },
    ];
    storeData?.order_set_masters?.data?.orderCategory?.map((val, i) => {
      if (val?.label === "Pharmacy") {
        let obj = {
          name: val?.label,
          component: (
            <ServiceDrugCatalogTable
              id={2}
              value={val?.value ?? ""}
              tableData={props?.tableData}
              handleEditModal={props?.handleEditModal}
              deleteTableRow={props?.deleteTableRow}
            />
          ),
        };
        arr.push(obj);
      } else {
        let obj = {
          name: val?.label,
          component: (
            <ServiceDrugCatalogTable
              id={1}
              value={val?.value ?? ""}
              tableData={props?.tableData}
              handleEditModal={props?.handleEditModal}
              deleteTableRow={props?.deleteTableRow}
            />
          ),
        };
        arr.push(obj);
      }
    });
    setState({ ...state, tableHeader: arr });
  };

  return (
    <React.Fragment>
      <Paper id={`${parent_id}_parent_paper`} className={classes.root}>
        <Typography
          id={`${parent_id}_AGE_CATEGORY_typography`}
          className={classes.title}
          variant="h6"
        >
          SERVICE / DRUG CATALOG LIST
        </Typography>
        {/* //{JSON.stringify(props.tableData ?? [])} */}
        <Button
          id={`${parent_id}_Add_New_button`}
          className={classes.addBtn}
          onClick={() => props?.handleModal("open")}
          color="primary"
        >
          + Add New
        </Button>
        <div id={"consult-master-parent-div"} className={classes.root}>
          <CommonTabList
            parent_id={"consult-master"}
            title=""
            list={state?.tableHeader}
          />
        </div>
      </Paper>

      <Popover
        id={"123"}
        open={props?.open}
        anchorEl={props?.open}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        PaperProps={{
          style: { width: "80%", padding: 20 },
        }}
      >
        <Grid container>
          <Grid item sm={10}>
            <Typography className={classes.modalHeader}>
              {props?.isEdit
                ? "Edit Service / Drug Catalog"
                : "Add New Service / Drug Catalog"}
            </Typography>
          </Grid>
          <Grid
            item
            sm={2}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => props?.handleModal("close")}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: "10px -20px" }} />
        {/* MONITORING FORM */}
        {props?.modalFormName === "monitoringForm" && (
          <MonitoringFormModal
            handleModalState={() => props?.handleModalState()}
            handleModalChange={() => props?.handleModalChange()}
            handleChange={() => props?.handleChange()}
            parent_id={parent_id}
            {...props}
          />
        )}
        {/* PHARMACY MEDICATION FORM */}
        {props?.modalFormName === "pharmacyMedicationForm" && (
          <PharmacyMedicationFormModal
            handleModalState={() => props?.handleModalState()}
            handleModalChange={() => props?.handleModalChange()}
            handleChange={() => props?.handleChange()}
            parent_id={parent_id}
            {...props}
          />
        )}
        {/* PHARMACY IV FORM */}
        {props?.modalFormName === "pharmacyIVForm" && (
          <PharmacyIVFormModal
            handleModalState={() => props?.handleModalState()}
            handleModalChange={() => props?.handleModalChange()}
            handleChange={() => props?.handleChange()}
            parent_id={parent_id}
            {...props}
          />
        )}
        <div
          id={`${parent_id}-buttons-div`}
          style={{ textAlign: "center", margin: "20px 0px 10px 0px" }}
        >
          <Button
            id={`${parent_id}-Cancel-button`}
            variant={"outlined"}
            color="primary"
            onClick={() => props?.handleModal("close")}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {props?.isEdit ? (
            <Button
              id={`${parent_id}-Update-button`}
              variant={"contained"}
              color="primary"
              onClick={() => props?.handleUpdate()}
            >
              Update
            </Button>
          ) : (
            <Button
              id={`${parent_id}-Save-button`}
              variant={"contained"}
              color="primary"
              onClick={() => props?.handleSave()}
            >
              Save
            </Button>
          )}
        </div>
      </Popover>
    </React.Fragment>
  );
}

export default ServiceDrugCatalogList;
