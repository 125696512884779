import React from "react";
import {
  Div,
  Paper,
  H6,
  Col,
  Row,
  // Select,
  // Text,
  TextInput,
} from "qdm-component-library";
import "./style.css";
import {
  // FormGroup,
  // makeStyles,
  // FormControlLabel,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import { withAllContexts } from "../../../HOCs";
// import Chip from '@material-ui/core/Chip';
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import { actions } from "primary_care_admin_binder";
// import { connect } from "react-redux";
// import Editor from "./editor";

class Pharmacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedOptions: [],
    };
  }

  // componentDidMount = async () => {
  //   await this.props?.LOCATION_MASTER_SPECIALITY();
  // };
  styles = {
    label: {
      //fontWeight: 500,
      margin: 0,
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: "pc_regular !important",
    },
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
    },
  };

  setDetails = (name, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let { patientData } = this.props;
    patientData.communication[name] = value;
    patientData.setPatientData({
      ...patientData,
    });
  };

  setDetailslanguage = (name, value) => {
    let { patientData } = this.props;
    if (patientData.communication?.clanguage?.length > 0) {
      const seen = new Set();
      const filteredArr = value.filter((el) => {
        const duplicate = seen.has(el.value);
        seen.add(el.value);
        return !duplicate;
      });

      patientData.communication[name] = filteredArr;
      patientData.setPatientData({
        ...patientData,
      });
      return;
    }

    patientData.communication[name] = value;
    patientData.setPatientData({
      ...patientData,
    });
  };
  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
        label: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  render() {
    //let { communication } = this.props.patientData;
    const { labelStyle, borderStyle, 
      // outlinedButton, containedButton, 
      label } =
      this.styles;
    const { parent_id } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root" style={{ marginTop: "20px" }}>
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-title-div`}
          >
            <H6
              id={`${parent_id}-title-h6`}
              className="pi-title">PHARMACY</H6>
          </Div>
          <Row
            id={`${parent_id}-parent-row`}
            className="pi-actions-root">
            <Col
              id={`${parent_id}-storeCode-col`}
              md={2} lg={2} sm={6} xs={6} inLineStyles={{ padding: 5 }}>
              <TextInput
                id={`${parent_id}-storeCode-textInput`}
                placeholder=""
                onChange={(e) =>
                  this.props.onTextChangeState("storeCode", e.target.value, 300)
                }
                value={this.props.storeCode ?? ""}
                label="Store Code"
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor="#0071F2"
              />
            </Col>
            <Col
              id={`${parent_id}-Return-Allowed-col`}
              md={2} lg={2} sm={6} xs={6} inLineStyles={{ padding: 5 }}>
              <Typography
                id={`${parent_id}-Return-Allowed-title-typography`}
                style={label}>Return Allowed</Typography>
              <Checkbox
                id={`${parent_id}-Return-Allowed-Checkbox`}
                checked={this.props.returnAllowed}
                onChange={this.props.handleChange}
                name="returnAllowed"
                size="small"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Col>
            <Col
              id={`${parent_id}-maxDaysForReturn-col`}
              md={2}
              lg={2}
              sm={6}
              xs={6}
              inLineStyles={{ padding: 5, marginLeft: "-80px" }}
            >
              <TextInput
                id={`${parent_id}-maxDaysForReturn-textInput`}
                type={"number"}
                placeholder=""
                onChange={(e) =>
                  this.props.onTextChangeState(
                    "maxDaysForReturn",
                    e.target.value,
                    2
                  )
                }
                value={this.props.maxDaysForReturn ?? ""}
                label="Max Days For Return"
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor="#0071F2"
                maxLength="2"
              />
            </Col>
            <Col
              id={`${parent_id}-externalRxAllowed-col`}
              md={2} lg={2} sm={6} xs={6} inLineStyles={{ padding: 5 }}>
              <Typography
                id={`${parent_id}-externalRxAllowed-title-typography`}
                style={label}>External RX Allowed</Typography>
              <Checkbox
                id={`${parent_id}-externalRxAllowed-Checkbox`}
                checked={this.props.externalRxAllowed}
                onChange={this.props.handleChange}
                name="externalRxAllowed"
                size="small"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Col>
            <Col
              id={`${parent_id}-otcAllowed-col`}
              md={2}
              lg={2}
              sm={6}
              xs={6}
              inLineStyles={{ padding: 5, marginLeft: "-60px" }}
            >
              <Typography
                id={`${parent_id}-otcAllowed-title-typography`}
                style={label}>OTC Allowed</Typography>
              <Checkbox
                id={`${parent_id}-otcAllowed-Checkbox`}
                checked={this.props.otcAllowed}
                onChange={this.props.handleChange}
                name="otcAllowed"
                size="small"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Col>
          </Row>
        </Paper>
      </Div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   specialityData: state?.locationMasterSlice?.location_masters_speciality,
// });

// export default connect(mapStateToProps, actions)(withAllContexts(ClinicInfo));
export default withAllContexts(Pharmacy);
