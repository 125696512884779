import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import copy from "fast-copy";
import update from "immutability-helper";
import { withRouter } from "react-router-dom";
import { CustRowTable } from "custom-material-ui-component";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Modal,
  Divider,
} from "@material-ui/core";
// Components
import * as ALL from "../../components";
// Styles
import qdmstyles from "./styles.json";
// Utils
import { isDecimal } from "../../utils";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  grid: {
    outline: 0,
  },
  tableRoot: {
    "& .custom-material-ui-component-MuiTableBody-root": {
      "& div": {
        "&:not(:last-child)": {
          borderRadius: "0px !important",
        },
      },
    },
  },
}));

const initialAllowedOrderState = {
  AllowedOrderDoseVal: "",
  AllowedOrderDoseUOM: "",
  AllowedOrderDoseVolVal: "",
  AllowedOrderDoseVolUoM: "",
};

const initialAvailableStrengthState = {
  StrengthValue: "",
  StrengthUOM: "",
  StrengthVolume: "",
  StrengthVolumeUoM: "",
  OrderUOMAllowed: [copy(initialAllowedOrderState)],
};

function Availablestrengthtabcomp(props) {
  const { data, index, options, statevalue } = props;

  const [state, setState] = React.useState({
    permissionData: null,
    showavailableStrengthmodal: false,
    editIndex: null,

    ...initialAvailableStrengthState,

    // Available Strength States
    AvailableStrength: [],
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      AvailableStrength: Array.isArray(statevalue) ? [...statevalue] : [],
    }));
  }, [statevalue]);

  const changeState = (key, value) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleModalClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      // Reset Data State
      resetDataState();
    }
    changeState("editIndex", null);
    changeState("showavailableStrengthmodal", false);
  };

  const handleModalOpen = (editIndex) => {
    changeState("showavailableStrengthmodal", true);
    changeState("editIndex", editIndex?.toString());
  };

  // Add new available Strength
  const handleAddNewAvailableStrengthClick = () => {
    handleModalOpen();
  };

  const resetDataState = () => {
    setState((prev) => ({
      ...prev,
      ...initialAvailableStrengthState,
    }));
  };

  const changeStateForOrderUOM = (key, value, index) => {
    const newState = update(state, {
      OrderUOMAllowed: {
        [index]: {
          [key]: {
            $set: value,
          },
        },
      },
    });

    changeState("OrderUOMAllowed", newState.OrderUOMAllowed);
  };

  const handleAddNewAllowedOrderClick = () => {
    let newAllowedForOrderState = copy(initialAllowedOrderState);
    changeState("OrderUOMAllowed", [
      ...state?.OrderUOMAllowed,
      newAllowedForOrderState,
    ]);
  };

  const saveNewStrength = () => {
    const {
      StrengthValue,
      StrengthUOM,
      StrengthVolume,
      StrengthVolumeUoM,
      OrderUOMAllowed,
    } = state;

    let newAvailableStrengthState = {
      StrengthValue: StrengthValue ? parseFloat(StrengthValue) : 0,
      StrengthUOM,
      StrengthVolume: StrengthVolume ? parseFloat(StrengthVolume) : 0,
      StrengthVolumeUoM,
      OrderUOMAllowed: OrderUOMAllowed.map((item) => ({
        ...item,
        AllowedOrderDoseVal: item?.AllowedOrderDoseVal
          ? parseFloat(item?.AllowedOrderDoseVal)
          : 0,
        AllowedOrderDoseVolVal: item?.AllowedOrderDoseVolVal
          ? parseFloat(item?.AllowedOrderDoseVolVal)
          : 0,
      })),
    };

    let newAvailableStrength = [
      ...state?.AvailableStrength,
      newAvailableStrengthState,
    ];
    changeState("AvailableStrength", newAvailableStrength);

    // Save value to parent
    props.setFun(newAvailableStrength);
  };

  const handleSaveClick = (editIndex, addAnother) => {
    // 1. Save new Strength / update existing Strength
    if (editIndex) {
      const index = parseInt(editIndex);
      let newAvailableStrength = [...state?.AvailableStrength];
      const {
        StrengthValue,
        StrengthUOM,
        StrengthVolume,
        StrengthVolumeUoM,
        OrderUOMAllowed,
      } = state;
      newAvailableStrength[index] = {
        ...newAvailableStrength[index],
        StrengthValue: StrengthValue ? parseFloat(StrengthValue) : 0,
        StrengthUOM,
        StrengthVolume: StrengthVolume ? parseFloat(StrengthVolume) : 0,
        StrengthVolumeUoM,
        OrderUOMAllowed: OrderUOMAllowed.map((item) => ({
          ...item,
          AllowedOrderDoseVal: item?.AllowedOrderDoseVal
            ? parseFloat(item?.AllowedOrderDoseVal)
            : 0,
          AllowedOrderDoseVolVal: item?.AllowedOrderDoseVolVal
            ? parseFloat(item?.AllowedOrderDoseVolVal)
            : 0,
        })),
      };
      changeState("AvailableStrength", newAvailableStrength);

      // Save value to parent
      props.setFun(newAvailableStrength);
    } else {
      saveNewStrength();
    }

    // 2. Reset the state
    resetDataState();

    // 2 a. Reset edit index
    changeState("editIndex", null);

    // 3. Close the modal if addAnother is false
    if (!addAnother) {
      handleModalClose();
    }
  };

  // Function to delete the available strength using index of the array
  const handleDeleteClick = (index) => {
    let newAvailableStrength = [...state?.AvailableStrength];
    newAvailableStrength.splice(index, 1);
    changeState("AvailableStrength", newAvailableStrength);

    // Save value to parent
    props.setFun(newAvailableStrength);
  };

  const handleEditClick = (index) => {
    let newAvailableStrength = [...state?.AvailableStrength];
    let newAvailableStrengthState = newAvailableStrength[index];

    setState((prev) => ({
      ...prev,
      StrengthValue: newAvailableStrengthState?.StrengthValue,
      StrengthUOM: newAvailableStrengthState?.StrengthUOM,
      StrengthVolume: newAvailableStrengthState?.StrengthVolume,
      StrengthVolumeUoM: newAvailableStrengthState?.StrengthVolumeUoM,
      OrderUOMAllowed: newAvailableStrengthState?.OrderUOMAllowed,
    }));

    handleModalOpen(index);
  };

  React.useEffect(() => {}, [state]);

  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"wXq4l"}
        container={true}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        lg={"12"}
        md={"12"}
        sm={"12"}
        xl={"12"}
        xs={"12"}
      >
        <Grid
          key={"0"}
          id={"jMabS"}
          container={""}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          lg={"12"}
          md={"12"}
          sm={"12"}
          xl={"12"}
          xs={"12"}
        >
          <Paper
            key={"0"}
            id={"WXw2c"}
            elevation={"{3}"}
            style={qdmstyles.LKdSH}
          >
            <Grid
              key={"0"}
              id={"cDHn4"}
              container={true}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
              style={{ margin: "17px 0 9px 0" }}
            >
              <Grid
                key={"0"}
                id={"nP1ez"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"6"}
                item={true}
                md={"6"}
                sm={"6"}
                xl={"6"}
                xs={"6"}
              >
                <Typography
                  key={"0"}
                  id={"list_of_available_strengths_header"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"LIST OF AVAILABLE STRENGTHS"}
                  aliasName={"listofavailablestrengthsheader"}
                  style={qdmstyles.nyjDP}
                />
              </Grid>
              <Grid
                key={"1"}
                id={"anTxp"}
                container={""}
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                lg={"2"}
                md={"2"}
                sm={"2"}
                xl={"2"}
                xs={"2"}
                style={qdmstyles.QdpTU}
              >
                <Button
                  key={"0"}
                  onClick={handleAddNewAvailableStrengthClick}
                  id={"available_strength_add_new_button"}
                  variant={"text"}
                  color={"primary"}
                  children={"+ Add New"}
                  aliasName={"availablestrengthaddnewbutton"}
                  style={qdmstyles.clx}
                />
              </Grid>
            </Grid>
            <Grid
              key={"1"}
              id={"FUAms"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
            >
              <Grid
                key={"0"}
                id={"VMPVS"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
              >
                <CustRowTable
                  key={"0"}
                  id={"iUGPb"}
                  HeaderComp={
                    <ALL.Availablestrengthtableheader
                      propsState={state}
                      setFun={(value) => {
                        changeState("Availablestrengthtableheader", value);
                      }}
                      stateName={"Availablestrengthtableheader"}
                      statevalue={state?.Availablestrengthtableheader}
                    />
                  }
                  BodyComp={
                    <>
                      {state?.AvailableStrength.map((item, index) => {
                        const rowState = state?.AvailableStrength[index];
                        return (
                          <ALL.Availablestrengthtablevalue
                            data={rowState}
                            onDeleteClick={() => handleDeleteClick(index)}
                            onEditClick={() => handleEditClick(index)}
                          />
                        );
                      })}
                    </>
                  }
                  className={classes.tableRoot}
                ></CustRowTable>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Modal for adding available strength */}
      <>
        <Modal
          open={state.showavailableStrengthmodal}
          className={classes.modal}
          onClose={handleModalClose}
        >
          <Grid
            className={classes.grid}
            key={"0"}
            id={"Z6EBo"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            lg={"4"}
            md={"6"}
            sm={"8"}
            xl={"4"}
            xs={"8"}
          >
            <Grid
              key={"0"}
              id={"Z9Hs0"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
            >
              <Paper
                key={"0"}
                id={"pCtsN"}
                elevation={"{3}"}
                style={qdmstyles.wQg}
              >
                <Grid
                  key={"0"}
                  id={"p9FEL"}
                  container={true}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                >
                  {/* Paper Header Starts */}
                  <Grid
                    key={"0"}
                    id={"Y6MRQ"}
                    container={true}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    lg={"12"}
                    item={true}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    style={qdmstyles.TnP}
                  >
                    <Grid
                      key={"0"}
                      id={"Z8H2H"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      lg={"7"}
                      md={"7"}
                      sm={"7"}
                      xl={"7"}
                      xs={"7"}
                    >
                      <Typography
                        key={"0"}
                        id={"6qn9l"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"AVAILABLE STRENGTH"}
                        style={qdmstyles.Orf}
                      />
                    </Grid>
                    <Grid
                      key={"1"}
                      id={"5WDf9"}
                      container={""}
                      direction={"row"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      lg={"2"}
                      md={"2"}
                      sm={"2"}
                      xl={"2"}
                      xs={"2"}
                      style={qdmstyles.IVzt}
                    >
                      <Paper
                        elevation={false}
                        style={{
                          width: "26px",
                          height: "26px",
                          borderRadius: "50%",
                          backgroundColor: "#E5E8EE",
                          display: "grid",
                          cursor: "pointer",
                        }}
                        onClick={() => handleModalClose()}
                      >
                        <CloseRoundedIcon
                          fontSize="small"
                          style={{ placeSelf: "center" }}
                        />
                      </Paper>
                      {/* <Avatar
                              key={"0"}
                              id={"csnok"}
                              alt={"Avatar"}
                              variant={"rounded"}
                              style={qdmstyles.Njo}
                            ></Avatar> */}
                    </Grid>
                  </Grid>
                  {/* Paper Header Ends */}

                  {/* Paper Header Divider Starts */}
                  <Grid
                    key={"1"}
                    id={"PLePk"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    item={true}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    style={qdmstyles.lcX}
                  >
                    <Divider
                      key={"0"}
                      id={"u6B8U"}
                      variant={"fullWidth"}
                      flexItem={""}
                      style={{ margin: "none" }}
                    ></Divider>
                  </Grid>
                  {/* Paper Header Divider Ends */}

                  {/* Strength Value / Strength Volume */}
                  <Grid
                    key={"2"}
                    id={"T7xTu"}
                    container={true}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    lg={"12"}
                    item={true}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    style={qdmstyles.taeFY}
                  >
                    <Grid
                      key={"0"}
                      id={"oEUGh"}
                      container={true}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      lg={"12"}
                      item={true}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                    >
                      {/* Strength value label row starts */}
                      <Grid
                        key={"0"}
                        id={"pYP7X"}
                        container={true}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        lg={"12"}
                        item={true}
                        style={{ margin: "8px 24px" }}
                      >
                        <Grid
                          key={"0"}
                          id={"S5mqN"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          lg={"5"}
                          md={"5"}
                          sm={"5"}
                          xl={"5"}
                          xs={"5"}
                        >
                          <Typography
                            key={"0"}
                            id={"U5nAb"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Strength Value"}
                            style={qdmstyles.WQVX}
                          />
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"w8Av4"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          lg={"5"}
                          xl={"5"}
                          md={"5"}
                          sm={"5"}
                          xs={"5"}
                        >
                          <Typography
                            key={"0"}
                            id={"GFYVy"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Concentration Volume"}
                            style={qdmstyles.TIjFl}
                          />
                        </Grid>
                      </Grid>
                      {/* Strength value label row starts */}

                      {/* Strength value input row starts */}
                      <Grid
                        key={"1"}
                        id={"4eEz8"}
                        container={true}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        item={true}
                        style={qdmstyles.qvoK}
                      >
                        <Grid
                          key={"0"}
                          id={"0yFDe"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          item={true}
                          lg={"5"}
                          xl={"5"}
                          md={"5"}
                          sm={"5"}
                          xs={"5"}
                        >
                          <ALL.Availabletabcomp
                            InputProps={{
                              id: `StrengthValue`,
                              type: "text",
                              fullWidth: true,
                              inputProps: {
                                maxLength: 7,
                              },
                              onKeyPress: (event) => {
                                if (!/[0-9.]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              },
                              value: state.StrengthValue,
                              onChange: (event) => {
                                if (
                                  event.target.value.length === 0 ||
                                  isDecimal(event.target.value)
                                ) {
                                  changeState(
                                    "StrengthValue",
                                    event.target.value
                                  );
                                }
                              },
                            }}
                            AutocompleteProps={{
                              id: `StrengthUOM`,
                              onLoad: () =>
                                changeState("StrengthUOM", state.StrengthUOM),
                              getOptionLabel: (option) => option?.["label"],
                              options: options?.drug_uom ?? [],
                              value: state.StrengthUOM ?? null,
                              onChange: (event, newValue) =>
                                changeState("StrengthUOM", newValue),
                            }}
                          />
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"XLXZi"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"2"}
                          item={true}
                          md={"2"}
                          sm={"2"}
                          xl={"2"}
                          xs={"2"}
                          style={qdmstyles.ZeueI}
                        >
                          <Typography
                            key={"0"}
                            id={"JMvWO"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={" /"}
                            style={qdmstyles.PZDnf}
                          ></Typography>
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"xTaHq"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"5"}
                          item={true}
                          md={"5"}
                          sm={"5"}
                          xl={"5"}
                          xs={"5"}
                        >
                          <ALL.Availabletabcomp
                            InputProps={{
                              id: `StrengthVolume`,
                              type: "text",
                              fullWidth: true,
                              inputProps: {
                                maxLength: 7,
                              },
                              onKeyPress: (event) => {
                                if (!/[0-9.]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              },
                              value: state.StrengthVolume,
                              onChange: (event) => {
                                if (
                                  event.target.value.length === 0 ||
                                  isDecimal(event.target.value)
                                ) {
                                  changeState(
                                    "StrengthVolume",
                                    event.target.value
                                  );
                                }
                              },
                            }}
                            AutocompleteProps={{
                              id: `StrengthVolumeUoM`,
                              onLoad: () =>
                                changeState(
                                  "StrengthVolumeUoM",
                                  state.StrengthVolumeUoM
                                ),
                              getOptionLabel: (option) => option?.["label"],
                              options: options?.drug_uom ?? [],
                              value: state.StrengthVolumeUoM ?? null,
                              onChange: (event, newValue) =>
                                changeState("StrengthVolumeUoM", newValue),
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* Strength value input row Ends */}
                    </Grid>
                  </Grid>

                  <Grid
                    key={"3"}
                    id={"luzoZ"}
                    container={true}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={qdmstyles.luzoZ}
                  >
                    {/* Allowed for Order Row + Button */}
                    <Grid
                      key={"0"}
                      id={"tYC2u"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={{ margin: "8px 24px" }}
                    >
                      <Grid
                        key={"0"}
                        id={"0QRuq"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        lg={"6"}
                        md="6"
                        sm="6"
                        xs="6"
                        xl="6"
                      >
                        <Typography
                          key={"0"}
                          id={"KzFlK"}
                          align={"inherit"}
                          color={"initial"}
                          display={"initial"}
                          variant={"body1"}
                          children={"Allowed For Order"}
                          style={qdmstyles.KzFlK}
                        />
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"InxOQ"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        lg={"6"}
                        md="6"
                        sm="6"
                        xs="6"
                        xl="6"
                      >
                        <Button
                          key={"0"}
                          id={"CJQXn"}
                          variant={"text"}
                          color={"primary"}
                          children={"+ Add New"}
                          style={qdmstyles.CJQXn}
                          onClick={handleAddNewAllowedOrderClick}
                        />
                      </Grid>
                    </Grid>
                    {/* Allowed for Order Row + Button */}

                    {/* Allowed for Order - Divider Start */}
                    <Grid
                      key={"1"}
                      id={"PLePk"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"12"}
                      item={true}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      style={qdmstyles.BaD}
                    >
                      <Divider
                        key={"0"}
                        id={"u6B8U"}
                        variant={"fullWidth"}
                        flexItem={""}
                      />
                    </Grid>
                    {/* Allowed for Order - Divider Ends */}

                    <Grid
                      key={"2"}
                      id={"T7xTu"}
                      container={true}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      lg={"12"}
                      item={true}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      style={qdmstyles.TMwJV}
                    >
                      <Grid
                        key={"0"}
                        id={"oEUGh"}
                        container={true}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        lg={"12"}
                        item={true}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                      >
                        {/* Allowed Value - Starts */}
                        <Grid
                          key={"0"}
                          id={"pYP7X"}
                          container={true}
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          lg={"12"}
                          item={true}
                          style={{ margin: "8px 24px" }}
                        >
                          <Grid
                            key={"0"}
                            id={"S5mqN"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={"5"}
                            md={"5"}
                            sm={"5"}
                            xl={"5"}
                            xs={"5"}
                          >
                            <Typography
                              key={"0"}
                              id={"U5nAb"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Allowed Value"}
                              style={qdmstyles.xgTso}
                            />
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"w8Av4"}
                            container={true}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            lg={"5"}
                            xl={"5"}
                            md={"5"}
                            sm={"5"}
                            xs={"5"}
                          >
                            <Typography
                              key={"0"}
                              id={"GFYVy"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"Allowed Volume"}
                              style={qdmstyles.cruD}
                            ></Typography>
                          </Grid>
                        </Grid>
                        {/* Allowed Value - Ends */}

                        {/* Allowed value input row starts */}
                        {state?.OrderUOMAllowed?.map((item, index) => {
                          const rowState = state?.OrderUOMAllowed?.[index];
                          return (
                            <Grid
                              key={"1"}
                              id={"4eEz8"}
                              container={true}
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              lg={"12"}
                              md={"12"}
                              sm={"12"}
                              xl={"12"}
                              xs={"12"}
                              item={true}
                              style={qdmstyles.WjqH}
                            >
                              <Grid
                                key={"0"}
                                id={"0yFDe"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"5"}
                                xl={"5"}
                                md={"5"}
                                sm={"5"}
                                xs={"5"}
                              >
                                <ALL.Availabletabcomp
                                  InputProps={{
                                    id: `AllowedOrderDoseVal`,
                                    type: "text",
                                    fullWidth: true,
                                    inputProps: {
                                      maxLength: 7,
                                    },
                                    onKeyPress: (event) => {
                                      if (!/[0-9.]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    },
                                    value: rowState?.AllowedOrderDoseVal,
                                    onChange: (event) => {
                                      if (
                                        event.target.value.length === 0 ||
                                        isDecimal(event.target.value)
                                      ) {
                                        changeStateForOrderUOM(
                                          "AllowedOrderDoseVal",
                                          event.target.value,
                                          index
                                        );
                                      }
                                    },
                                  }}
                                  AutocompleteProps={{
                                    id: `AllowedOrderDoseUOM`,
                                    onLoad: () =>
                                      changeStateForOrderUOM(
                                        "AllowedOrderDoseUOM",
                                        rowState.AllowedOrderDoseUOM,
                                        index
                                      ),
                                    getOptionLabel: (option) =>
                                      option?.["label"],
                                    options: options?.drug_uom ?? [],
                                    value: rowState.AllowedOrderDoseUOM ?? null,
                                    onChange: (event, newValue) =>
                                      changeStateForOrderUOM(
                                        "AllowedOrderDoseUOM",
                                        newValue,
                                        index
                                      ),
                                  }}
                                />
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"XLXZi"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"2"}
                                item={true}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                                style={qdmstyles.GXq}
                              >
                                <Typography
                                  key={"0"}
                                  id={"JMvWO"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={" /"}
                                  style={qdmstyles.JMvWO}
                                ></Typography>
                              </Grid>
                              <Grid
                                key={"2"}
                                id={"xTaHq"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"5"}
                                item={true}
                                md={"5"}
                                sm={"5"}
                                xl={"5"}
                                xs={"5"}
                              >
                                <ALL.Availabletabcomp
                                  InputProps={{
                                    id: `AllowedOrderDoseVolVal`,
                                    type: "text",
                                    fullWidth: true,
                                    inputProps: {
                                      maxLength: 7,
                                    },
                                    onKeyPress: (event) => {
                                      if (!/[0-9.]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    },
                                    value: rowState.AllowedOrderDoseVolVal,
                                    onChange: (event) => {
                                      if (
                                        event.target.value.length === 0 ||
                                        isDecimal(event.target.value)
                                      ) {
                                        changeStateForOrderUOM(
                                          "AllowedOrderDoseVolVal",
                                          event.target.value,
                                          index
                                        );
                                      }
                                    },
                                  }}
                                  AutocompleteProps={{
                                    id: `AllowedOrderDoseVolUoM`,
                                    onLoad: () =>
                                      changeStateForOrderUOM(
                                        "AllowedOrderDoseVolUoM",
                                        rowState.AllowedOrderDoseVolUoM,
                                        index
                                      ),
                                    getOptionLabel: (option) =>
                                      option?.["label"],
                                    options: options?.drug_uom ?? [],
                                    value:
                                      rowState.AllowedOrderDoseVolUoM ?? null,
                                    onChange: (event, newValue) =>
                                      changeStateForOrderUOM(
                                        "AllowedOrderDoseVolUoM",
                                        newValue,
                                        index
                                      ),
                                  }}
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                        {/* Allowed value input row Ends */}
                      </Grid>
                    </Grid>
                    {/* Allowed Value - Ends */}

                    {/* Save & Add another starts */}
                    <Grid
                      key={"3"}
                      id={"Y3yHa"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                    >
                      <Grid
                        key={"0"}
                        id={"muwbQ"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        style={qdmstyles.muwbQ}
                      >
                        <Button
                          key={"0"}
                          id={"P1hme"}
                          variant={"text"}
                          color={"primary"}
                          children={
                            state?.editIndex
                              ? "Update & Add another"
                              : "Save & Add another"
                          }
                          style={qdmstyles.Phme}
                          size="small"
                          onClick={() =>
                            handleSaveClick(state?.editIndex, true)
                          }
                        />
                        <Button
                          key={"1"}
                          id={"CP7Ps"}
                          variant={"contained"}
                          color={"primary"}
                          children={state?.editIndex ? "Update" : "Add"}
                          style={qdmstyles.CPPs}
                          size="small"
                          onClick={() =>
                            handleSaveClick(state?.editIndex, false)
                          }
                        />
                      </Grid>
                    </Grid>
                    {/* Save & Add another ends */}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Modal>
      </>
    </React.Fragment>
  );
}

export default withRouter(Availablestrengthtabcomp);
