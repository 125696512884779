import "date-fns";
import React from "react";

import PropTypes from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";
//import { TextField } from "@material-ui/core";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "poppin",
    color: "#6F6F6F",
    fontSize: "12px",
    paddingBottom: "8px",
  },
  root: {},
  timeInput: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    padding: 5,
    height: 40,
    "& input": {
      border: 0,
      flex: 1,
      width:({width}) => width,
      fontFamily: "pc_medium",
      fontSize: "14px",
      "&:focus": {
        outline: "none",
      },
    },
    "& p": {
      margin: 0,
      fontFamily: "pc_regular",
      fontSize: "12px",
      color: "#B6B6B6",
    },
  },
  timeInputAlert: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid red",
    borderRadius: "8px",
    padding: 5,
    height: 40,
    "& input": {
      border: 0,
      flex: 1,
      width:({width}) => width,
      fontFamily: "pc_medium",
      fontSize: "14px",
      "&:focus": {
        outline: "none",
      },
    },
    "& p": {
      margin: 0,
      fontFamily: "pc_regular",
      fontSize: "12px",
      color: "#B6B6B6",
    },
  },
}));

export const TimePicker = (props) => {
  const classes = useStyles(props);
  const { parent_id,width } = props
  const handleChange = (date) => {
    props?.onChange && props?.onChange(JSON.parse(JSON.stringify(date)));
  };

  // const returnValue = () =>{
  //   if(props.value){
  //     return typeof props.value === "object" ? props?.value : new Date(props?.value)
  //   }
  //   return null
  // }

  return (
    <div
      id={`${parent_id}-parent-div`}
      className={classes.root}>
      {props?.label && (
        <Typography
          id={`${parent_id}-${props?.label.replaceAll(" ", "-")}-title-typography`}
          variant="caption" className={classes.title}>
          {props?.label}
          {props?.required && (
            <span
              id={`${parent_id}-title-star-span`}
              style={{
                color: "red",
                marginLeft: 5,
              }}
            >
              *
            </span>
          )}
        </Typography>
      )}

      <div
        id={`${parent_id}-DatePicker-div`}
        className={props.error ? classes.timeInputAlert : classes.timeInput}>
        <DatePicker
          id={`${parent_id}-DatePicker`}
          selected={props?.value ? new Date(props?.value) : null}
          onChange={(e) => handleChange(e)}
          // showTimeSelect
          // showTimeSelectOnly
          // timeIntervals={30}
          // timeCaption="Time"
          // dateFormat="H:mm aa"
          timeFormat={props?.timeFormat ? props?.timeFormat : "HH:mm"}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={5}
          timeCaption="Time"
          dateFormat={props?.timeFormat ? props?.timeFormat : "HH:mm"}
          disabled={props?.disabled ?? false}
        />
        <p>{props?.hrsLabel}</p>
      </div>

      {/* <TextField
        required={props.required}
        error={props.error}
        id="time"
        // label="Alarm clock"
        variant={"outlined"}
        type="time"
        // pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
        defaultValue={props?.defaultValue ?? "08:00"}
        // value={"13:30"}
        value={props?.value ?? ""}
        className={"without"}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 1800, // 5 min
          min: "00:00",
          max: "23:59",
        }}
        onChange={(e) => handleChange(e)}
      /> */}
    </div>
  );
};

TimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  minDate: PropTypes.string,
  defaultValue: PropTypes.string,
  hrsLabel: PropTypes.string,
  disabled: PropTypes.bool
};

TimePicker.defaultProps = {
  dateFormat: "MM/dd/yyyy",
  error: false,
  required: false,
  hrsLabel: "24Hrs",
  disabled: false
};
