import React, { useEffect, useState } from "react";
import {
  CommonTable,
  CommonTabList,
  GenerateForm,
} from "../../../../../../components";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  Button,
  TextField,
  Avatar,
  Drawer,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { generateComponent } from "../../../../../../components/common/smartForm/formsList";
import styles from "../../../../../../components/common/smartForm/styles";
import { withAllContexts } from "../../../../../../HOCs";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    // padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    margin: 16,
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  btn: {
    padding: "6px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    margin: "15px 10px 0px 0px",
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 8,
  },
  formParent: {
    paddingRight: 10,
  },
  subText: {
    fontSize: 14,
    color: "#101010",
    fontFamily: "pc_medium",
  },
  addBtn: {
    color: "#0071F2",
    textTransform: "none",
    fontSize: "14px",
    fontFamily: "pc_regular",
    padding: "0px",
  },
  form: {
    padding: "16px 23px",
  },
}));

function AddFlexAttCriteria(props) {
  const classes = useStyles();
  const classes1 = styles();
  const dispatch = useDispatch();

  const getData = async () => {
    const drop = await dispatch(actions.FLEX_CRITERIA_CODE());
    console.log("drop",drop);
    state.list[1].options = drop?.payload?.data;
    setState({
      ...state,
    });
  };
  useEffect(() => {
    console.log("props?.edit?",props?.edit);
    if(props?.edit?.isEdit){
      setAttributeState({
        attributeLableName: props?.edit?.editData?.winlabelName,
        attributeComponents: props?.edit?.editData?.AttribComponents.map((val) =>{
          return {
            defaultValue: {label: val?.attribdefaultVal},
            seqNo: val?.attribSeq,
            code:{
              label:val?.attribCode?.attriblabel + " (" + val?.attribCode?.attribcode + ")",
              value: val?.attribCode?._id,
              type: val?.attribCode?.attribtype?._id,
              code: val?.attribCode?.attribcode
            } 
          }
        }),
        key: props?.edit?.editData?._key,
        status: props?.edit?.editData?.status
      })
    }
    getData();
  }, []);

  const [attributeState, setAttributeState] = useState({
    attributeComponents: [],
    attributeLableName: "",
  });
  const [state, setState] = React.useState({
    open: false,
    saveloading: false,
    edit_data: false,
    editIndex: null,
    list: [
      {
        componet_type: "text",
        label: "Seq. No",
        state_name: "seqNo",
        disabled: true,
        value: 1,
        required: true,
      },
      {
        componet_type: "select",
        options: [],
        label: "Code And Description",
        state_name: "code",
        value: "",
        required: true,
        disabled :props?.edit?.isEdit ? true :false
      },
      {
        componet_type: "text",
        label: "Default Value",
        state_name: "defaultValue",
        value: "",
        required: false,
      },
    ],
    states: {
      seqNo:
        attributeState?.attributeComponents?.length === 0
          ? 1
          : attributeState?.attributeComponents?.length + 1 ?? "",
      code: "",
      defaultValue: "",
      _id: "",
    },
  });

  const handleFormState = (name, value) => {
    setAttributeState({ ...attributeState, [name]: value });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      editIndex: null,
      states: {
        seqNo: "",
        code: "",
        defaultValue: "",
        _id: "",
      },
    });
  };

  const saveAttribute = () => {
    let obj = {};
    let data = state.states;
    const type = typeof data?.defaultValue;
    obj["seqNo"] = data?.seqNo;
    obj["code"] = data?.code;
    obj["defaultValue"] =
      type === "string" ? { label: data?.defaultValue, value:data?.defaultValue } : data?.defaultValue;

    let list = JSON.parse(JSON.stringify(attributeState?.attributeComponents));

    if (state.editIndex || state.editIndex === 0) {
      list[state.editIndex] = {
        ...list[state.editIndex],
        ...obj,
      };
    } else {
      list.push(obj);
    }
    if (obj.code.label) {
      handleFormState("attributeComponents", list);
      handleDrawerClose();
    } else {
      props.alert.setSnack("mandatory");
    }
  };
  const saveForm = () => {
    if (
      attributeState?.attributeComponents?.length > 0 &&
      attributeState?.attributeLableName
    ) {
      props.saveForm(attributeState);
      props.handleCloseAddNew();
    } else {
      props.alert.setSnack("mandatory");
    }
  };

  const handleChange = async (type, val) => {
    if (type === "code") {
      const defaultDropDown = await dispatch(
        actions.FLEX_CRITERIA_DEFAULT_DROPDOWN({
          list: val?.code,
          id: val?.type,
        })
      );
      const defaultCases = defaultDropDown?.payload?.data[0];
      if (defaultCases?.attribtype === "Numeric") {
        state.list[2] = {
          componet_type: "textCustom",
          label: "Default Value",
          state_name: "defaultValue",
          value: "",
          required: false,
          maxNum: defaultCases?.attribnumMax,
          uomValue: defaultCases?.attribnumUOM,
          type: "number",
        };
      } else if (
        defaultCases?.attribtype === "List" ||
        defaultCases?.attribtype === "Boolean"
      ) {
        let listOption = [];
        listOption.push({
          label: defaultCases?.AttribValidValues,
          value: defaultCases?.AttribValidValues,
        });
        state.list[2] = {
          componet_type: "select",
          options: listOption ?? [],
          label: "Default Value",
          state_name: "defaultValue",
          value: "",
          required: false,
        };
      } else if (defaultCases?.attribtype === "Text Box") {
        state.list[2] = {
          componet_type: "text",
          label: "Default Value",
          state_name: "defaultValue",
          value: "",
          required: false,
        };
      }
      setState({
        ...state,
        states: {
          ...state.states,
          [type]: val,
          defaultValue:''
        },
      });
    }else{
      setState({
        ...state,
        states: {
          ...state.states,
          [type]: val,
        },
      });
    }

  };


  const handleEdit = async(e, data, index) => {
    const defaultEdit = await dispatch(
      actions.FLEX_CRITERIA_DEFAULT_DROPDOWN({
        list: data?.code?.code,
        id: data?.code?.type,
      })
    );
    const defaultSet = defaultEdit?.payload?.data[0];

    if (defaultSet?.attribtype === "Numeric") {
      state.list[2] = {
        componet_type: "textCustom",
        label: "Default Value",
        state_name: "defaultValue",
        value: "",
        required: false,
        maxNum: defaultSet?.attribnumMax,
        uomValue: defaultSet?.attribnumUOM,
        type: "number",
      };
    } else if (
      defaultSet?.attribtype === "List" ||
      defaultSet?.attribtype === "Boolean"
    ) {
      let listOption = [];
      listOption.push({
        label: defaultSet?.AttribValidValues,
        value: defaultSet?.AttribValidValues,
      });
      state.list[2] = {
        componet_type: "select",
        options: listOption ?? [],
        label: "Default Value",
        state_name: "defaultValue",
        value: "",
        required: false,
      };
    } else if (defaultSet?.attribtype === "Text Box") {
      state.list[2] = {
        componet_type: "textCustom",
        label: "Default Value",
        state_name: "defaultValue",
        value: "",
        required: false,
      };
    }
    const type = typeof data?.defaultValue;
    setState({
      ...state,
      editIndex: index,
      open: true,
      states: {
        seqNo: data?.seqNo,
        code: data?.code,
        defaultValue: type === "string" ? { label: data?.defaultValue, value:data?.defaultValue } : data?.defaultValue
      },
    });
  }

  return (
    <React.Fragment>
      <CommonTabList
        //   parent_id={"agerange_form"}
        backbtn
        backFun={props.handleCloseAddNew}
        title={props?.edit?.isEdit ? "Edit Flex Attributes Applicable Criteria" : "Add New Flex Attributes Applicable Criteria"} 
        list={[]}
      />
      <Paper className={classes.root}>
        <Grid container style={{ padding: "20px 20px 0px" }}>
          <Grid item xs={3} className={classes.formParent}>
            <Typography className={classes.formtitle} variant="body1">
               Attribute Label Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              value={attributeState?.attributeLableName}
              onChange={(e) =>
                handleFormState("attributeLableName", e.target.value)
              }
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              inputProps={
                {maxLength: 30}
              }
            />
          </Grid>
        </Grid>
        <div
          style={{
            border: "1px solid #eee",
            margin: "16px 20px 20px",
            borderRadius: "10px",
            padding: "16px",
          }}
        >
          <Typography className={classes.title} variant="h6">
            ATTRIBUTE USAGE CRITERIA <span style={{ color: "red" }}>*</span>
          </Typography>
          <Button
            variant="outlined"
            className={classes.btn}
            style={{ borderColor: "#ff6384", color: "#ff6384" }}
          >
            + Add New Rule Set
          </Button>
        </div>
        <div
          style={{
            padding: "0px 20px 10px",
            borderBottom: "1px solid #e6e6e6",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.subText}>
            Attribute Components<span style={{ color: "red" }}>*</span>
          </Typography>
          <Button
            className={classes.addBtn}
            onClick={() =>
              setState({
                ...state,
                open: true,
                states: {
                  seqNo:
                    attributeState?.attributeComponents.length === 0
                      ? 1
                      : attributeState?.attributeComponents.length + 1 ?? "",
                  code: "",
                  defaultValue: "",
                  _id: "",
                },
              })
            }
          >
            + Add new
          </Button>
        </div>
        <Grid container className={classes.form}>
          <CommonTable
          handleEdit={(e, data, index) => handleEdit(e, data, index)}
            // handleDelete={handleDelete}
            rightAlign={["Action"]}
            Header={["Seq.No", "Code and Description", "Default Value","Action" ]}
            dataList={attributeState?.attributeComponents}
            tableData={[
              { type: ["INCRIMENT"], name: "seqNo" },
              { type: ["TEXT"], name: "code", optionLabel: "label" },
              { type: ["TEXT"], name: "defaultValue", optionLabel: "label" },
              { type: ["EDIT"], name: "", align: "right" } ,
            ]}
            // condition={condition}
          />
        </Grid>

        <Drawer
          className={classes1.drawer}
          // variant="persistent"
          anchor="right"
          open={state.open}
          classes={{
            paper: classes1.drawerPaper,
          }}
        >
          {
            <div>
              <Avatar
                src="/images/close.svg"
                className={classes1.large}
                onClick={() => handleDrawerClose()}
              />
              <div>
                <div className={classes1.drawerHead}>
                  <Typography className={classes1.headerMain}>
                    Add Attributes
                  </Typography>
                </div>

                <Grid
                  container
                  direction="row"
                  style={{ padding: "10px 20px" }}
                >
                  <Grid item xs={12}>
                    {state?.list?.length > 0 &&
                      state?.list?.map((val) => {
                        return generateComponent({
                          data: val,
                          handleChange: handleChange,
                          state: state,
                          classes: classes1,
                        });
                      })}
                    <div className={classes1.btnPosition}>
                      <Button
                        disabled={props?.loading ?? false}
                        variant={"contained"}
                        color={"primary"}
                        style={{ marginTop: 20 }}
                        size={"small"}
                        contained
                        onClick={() => saveAttribute()}
                      >
                        {props?.loading ? "Saving..." : "Save"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          }
        </Drawer>
      </Paper>
      <Grid style={{ padding: "20px" }}>
        <Button
          onClick={saveForm}
          style={{ float: "right" }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
        <Button
          onClick={() => props.handleCloseAddNew()}
          style={{ float: "right", marginRight: "20px" }}
          variant="outlined"
        >
          Cancel
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default withAllContexts(AddFlexAttCriteria);
