import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Paper } from "@material-ui/core";
// Components
import { TextfieldWithSelect } from "../inputs";
// Styles
import qdmstyles from "./styles.json";

function Availabletabcomp(props) {
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {}, [state]);

  const { data, index } = props;

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"3dJV7"}
        container={""}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        lg={"12"}
        md={"12"}
        sm={"12"}
        xl={"12"}
        xs={"12"}
      >
        <Paper key={"0"} id={"etc9W"} elevation={"{3}"} style={qdmstyles.yB}>
          <Grid
            key={"0"}
            id={"mTFOI"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
            style={qdmstyles.TyHVG}
          >
            <TextfieldWithSelect
              InputProps={{ ...props.InputProps }}
              AutocompleteProps={{ ...props.AutocompleteProps }}
            />
            {/* <Grid
                  key={"0"}
                  id={"56Ktj"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"5"}
                  md={"5"}
                  sm={"5"}
                  xl={"5"}
                  xs={"5"}
                  item={true}
                  style={qdmstyles.jeKX}
                >
                  <Typography
                    key={"0"}
                    id={"available_strength_left_side_value"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"25"}
                    aliasName={"availablestrengthleftsidevalue"}
                    style={qdmstyles.RmHu}
                  ></Typography>
                </Grid>
                <Divider
                  key={"1"}
                  id={"i68WA"}
                  variant={"fullWidth"}
                  flexItem={true}
                  orientation={"vertical"}
                ></Divider>
                <Grid
                  key={"2"}
                  id={"VtLuv"}
                  container={""}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  lg={"5"}
                  md={"5"}
                  sm={"5"}
                  xl={"5"}
                  xs={"5"}
                  item={true}
                >
                  <Autocomplete
                    id={"mNi4e"}
                    style={qdmstyles?.mNie}
                    value={state?.availabletabcomp_mni4e ?? null}
                    onLoad={() =>
                      setState({
                        availabletabcomp_mni4e:
                          state?.availabletabcomp_mni4e ?? null,
                      })
                    }
                    getOptionLabel={(option) => option?.["label"]}
                    options={[] ?? []}
                    size={"small"}
                    renderInput={(params) => (
                      <ALLMCORE.TextField
                        {...params}
                        label={""}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid> */}
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(Availabletabcomp);
