import React from "react";
import {
  Paper,
  FormGroup,
  makeStyles,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: "20px 20px 0 20px",
    borderRadius: "0px",
    "& .MuiTypography-body1": {
      fontSize: "12px",

      Fontfamily: "pc_regular",
      color: "#6F6F6F",
    },
    "& .MuiSvgIcon-root": {
      width: 17,
      height: 17,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
    paddingBottom: 10,
  },
}));

function Characteristic(props) {
  const classes = useStyles();
  const { characteristicJson, handleCheckState, parent_id } = props;
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes.root}>
        <FormGroup
          id={`${parent_id}-parent-formgroup`}
          row>
          {characteristicJson?.map((_, i) => {
            return (
              <FormControlLabel
                id={`${parent_id}-formControlLabel`}
                control={
                  <Checkbox
                    id={`${parent_id}-Checkbox_${i}`}
                    color="primary"
                    checked={_.checked}
                    onChange={(e) => handleCheckState(e.target.checked, i)}
                  />
                }
                label={_.label}
              />
            );
          })}
        </FormGroup>
      </Paper>
    </React.Fragment>
  );
}

export default Characteristic;
