import React from "react";
import { withHoverDrawer } from "../../HOCs";
import { AuthorizationAiqoe } from "atp-authorization-aiqoe";

const Repository = () => {

return(
    <div>

  <AuthorizationAiqoe  />

  </div>
)
}

export default withHoverDrawer(Repository);