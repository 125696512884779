import React from "react";
import { CommonTable, GenerateForm } from "../../../../../components";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { AlertProps, checkError } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

class OrderCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditFrom: false,
      categories: [],
      edit_data: {
        category: "",
        shortdesc: "",
        longdesc: "",
        key: "",
        perPage: 10,
        page: 0,
      },
      // saveloading: false,
      // edit_data: null
    };
    // this.saveForm = this.saveForm.bind(this)
    // this.handleEdit = this.handleEdit.bind(this)
    // this.handleCheckBox = this.handleCheckBox.bind(this)
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    });
    await this.props.GET_ALL_ORDER_CATEGORY({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    });
  };

  initFetch = async () => {
    await this.props.GET_ALL_ORDER_CATEGORY({
      page: this.state.page,
      perPage: this.state.perPage,
    });
    this.updateCategories(this.props?.getAllOrderCategory?.data || []);
  };
  // state update on search
  async componentDidUpdate() {
    if (this.props?.getAllOrderCategory?.data !== this.state.categories) {
      this.setState({ categories: this.props?.getAllOrderCategory?.data });
    }
  }

  updateCategories = (cat, cb = () => {}) => {
    this.setState(
      {
        ...this.state,
        categories: cat,
      },
      () => {
        cb();
      }
    );
  };

  componentDidMount() {
    this.initFetch();
    this.props?.currentTab("orderCategory");
  }

  //   componentDidUpdate() {

  // let initState = this.props?.getAllOrderCategory?.data

  //     if(JSON.stringify(this.props?.getAllOrderCategory?.data) !== JSON.stringify(initState)) {

  //       this.setState({
  //         category: this.props?.getAllOrderCategory?.data,
  //       });
  //       console.log("uuuuuu")

  //     }
  //   }

  handleOpenForm = (val) => {
    const { changeState } = this.props;
    changeState("openIndex", 0);
    this.setState({
      openEditFrom: true,
      edit_data: { ...val },
    });
  };

  handleCloseForm = () => {
    const { changeState } = this.props;
    changeState("open", false);
    this.setState({
      openEditFrom: false,
      edit_data: {
        category: "",
        shortdesc: "",
        longdesc: "",
        key: "",
      },
      // edit_data: null
    });
  };

  saveFn = async (val) => {
    let { category, shortdesc, longdesc, status = true } = val;
    const input = {
      category,
      shortdesc,
      longdesc,
      status,
      id: 0,
      isUpdate: false,
      key: "",
    };
    if (this.state.edit_data.key) {
      input.id = this.state.edit_data.id;
      input.status = this.state.edit_data.status;
      input.key = this.state.edit_data.key;
      input.isUpdate = true;
    }
    const upsertStatus = await this.props.UPSERT_ORDER_CATEGORY(input);
    // const { isError, errMsg } = checkError(upsertStatus.payload);
    console.log("upsertStatus", upsertStatus);
    if (upsertStatus?.payload?.data?.category === undefined) {
      this.props.alert.setSnack("unique");
      this.setState({
        openEditFrom: true,
        //   edit_data: ,
      });
    } else {
      const categories = JSON.parse(JSON.stringify(this.state.categories));
      if (input.isUpdate) {
        let cat = categories.find((c) => c.key === input.key);
        if (cat) {
          const { category, shortdesc, longdesc, status } =
            upsertStatus?.payload?.data;
          cat.category = category;
          cat.shortdesc = shortdesc;
          cat.longdesc = longdesc;
          cat.status = status;
        }
      } else {
        categories.push(upsertStatus?.payload?.data);
      }
      let type = this.state?.edit_data?.key ? "update" : "success";
      this.props.alert.setSnack(type);
      this.updateCategories(categories, this.handleCloseForm);
    }
  };

  render() {
    const { open, openIndex, parent_id } = this.props;
    return (
      <React.Fragment>
        <CommonTable
          style={{ height: "calc(100vh - 245px)" }}
          parent_id={"ordercategory"}
          // handleEdit={this.handleEdit}
          Header={[
            "S.No",
            "Order Category Code",
            "Short Description",
            "Long Description",
            "Status",
            "Action",
          ]}
          dataList={this.state.categories}
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "category" },
            { type: ["TEXT"], name: "shortdesc" },
            { type: ["TEXT"], name: "longdesc" },
            { type: ["CHECKBOX"], name: "status" },
            { type: ["EDIT"], name: "" },
          ]}
          handleCheckBox={async (e, val) => {
            const updatedStatus = await this.props.UPDATE_ORDER_CATEGORY_STATUS(
              {
                key: val.key,
                status: !val.status,
              }
            );
            this.props.alert.setSnack("update");
            const { isError, errMsg } = checkError(updatedStatus.payload);
            if (isError) {
              this.props.alert.setSnack("error");
            } else {
              // const categories = JSON.parse(
              //   JSON.stringify(this.state.categories)
              // );
              // const cat = categories.find((c) => c.key === val.key);
              // if (cat) {
              //   cat.status = !val.status;
              // }
              // this.updateCategories(categories);
              this.initFetch();
            }
          }}
          handleEdit={(e, val) => {
            this.handleOpenForm(val);
          }}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={this.state.categories?.[0]?.total_count}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <GenerateForm
          parent_id={"ordercategory"}
          nocancel
          open={(open || this.state.openEditFrom) && openIndex === 0}
          handlecancel={() => this.handleCloseForm()}
          header={
            this.state?.edit_data?.id
              ? "Edit Order Category"
              : "Add New Order Category"
          }
          json={[
            {
              componet_type: "text",
              label: "Order Category Code",
              state_name: "category",
              value: this.state?.edit_data?.category ?? "",
              required: true,
              isUpperCase: true,
              textLength: 10,
              disabled: this.state?.edit_data?.category ? true : false,
            },
            {
              componet_type: "text",
              label: "Short Description",
              state_name: "shortdesc",
              value: this.state?.edit_data?.shortdesc ?? "",
              required: true,
              textLength: 30,
            },
            {
              componet_type: "text",
              label: "Long Description",
              state_name: "longdesc",
              value: this.state?.edit_data?.longdesc ?? "",
              required: true,
              textLength: 60,
            },
          ]}
          loading={this.props.upsertOrderCategory?.loading}
          save={(val) => this.saveFn(val)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  getAllOrderCategory: state?.orderMasterSlice?.getAllOrderCategory,
  upsertOrderCategory: state?.orderMasterSlice?.upsertOrderCategory,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(OrderCategory));
