import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Avatar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { ArrowIcon } from "../../../components/icons/arrowIcon";
import ainqaLogo from "../../../assets/AINQA_Logo-WHITE.svg";
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { routes } from "../../../router/routes";
import { useHistory } from "react-router";
import { LocalStorageKeys } from "../../../utils"

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#0071F2",
    position: "fixed",
    width: "100%",
    boxShadow: "none",
  },
  title: {
    display: "block",
  },
  titleContainer: {
    display: "flex",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  square: {
    borderRadius: "8px",
  },
  rightFont: {
    fontFamily: "poppin",
    color: "#fff",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    id={'topnavbar-menu'}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const TopNavBar = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    openSideNavBar: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // localStorage.clear();
    localStorage.removeItem("token")
    localStorage.removeItem("role_name")
    localStorage.removeItem("role_data")
    history.push({
      pathname: routes.signIn,
    });
    setAnchorEl(null);
  };

  const toogleSideNavBar = () => {
    setState({
      ...state,
      openSideNavBar: !state.openSideNavBar,
    });
  };

  return (
    <div
      id={'topnavbar-parent-div'}
      className={classes.grow}>
      <AppBar
        id={'topnavbar-parent-appbar'}
        position="static" className={classes.appBar}>
        <Toolbar
          id={'topnavbar-parent-toolbar'}
        >
          <IconButton
            id={'topnavbar-iconbutton'}
            className={classes.menuIcon} onClick={toogleSideNavBar}>
            <MenuIcon
              id={'topnavbar-menuicon'}
              htmlColor="white" />
          </IconButton>

          <div
            id={'topnavbar-ainqaLogo-div'}
            className={classes.titleContainer}>
            <img
              id={'topnavbar-ainqaLogo-img'}
              src={ainqaLogo} alt="pic" />
          </div>

          <div
            id={'topnavbar-admin-div'}
            className={classes.grow} />

          <div
            id={'topnavbar-admin-sub-div'}
            style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              id={'topnavbar-admin-avatar'}
              variant="square" className={classes.square}>
              A
            </Avatar>
            <div
              id={'topnavbar-admin-div'}
              style={{ padding: "0px 10px" }}>
              <Typography
                id={'topnavbar-admin-typography'}
                className={classes.rightFont}>
                Admin
              </Typography>
              <Typography
                id={'topnavbar-Version-typography'}
                className={classes.rightFont}
                style={{ fontSize: "10px" }}
              >
                {localStorage.getItem(LocalStorageKeys.version) ? "Version:" + localStorage.getItem(LocalStorageKeys.version) : "Version:0.0.1"}
              </Typography>
            </div>
            <div
              id={'topnavbar-Version-arrowicon'}
              onClick={handleUserClick} style={{ cursor: "pointer" }}>
              <ArrowIcon color={"#fff"} />
            </div>
          </div>
          {/* <IconButton
                        aria-label="logout button"
                        aria-controls={"logout_button"}
                        aria-haspopup="true"
                        onClick={handleLogout}
                        color="inherit"
                    >
                        <MeetingRoom />
                    </IconButton> */}

          {/* <Drawer
                        open={state.openSideNavBar}
                        variant={"temporary"}
                        anchor="left"
                        onClose={toogleSideNavBar}>
                        <div style={{ width: 240 }}>
                            <SideNavBar isMobile={true} />
                        </div>
                    </Drawer> */}
        </Toolbar>
      </AppBar>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleUserClose}
      >
        <MenuItem
          id={'topnavbar-Logout-menuitem'}
          onClick={handleLogout}> Logout</MenuItem>
      </StyledMenu>

    </div>
  );
};
