import React, { useState } from "react";
import { Card, Grid, Popover, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import expandarrow from "../../../assets/icons8-expand-arrow.svg";
import { SelectBox } from "../../../components";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  req_red: {
    color: "red",
  },
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  line: {
    borderLeft: "3px solid #e1e1e1",
    marginRight: "8px",
    marginLeft: "8px",
  },
}));
function ResourceDefinitionCard(props) {
  const {
    state = {},
    handlechange,
    ResourceTypeAuto,
    ResourceRoleAuto,
    ResourceDepartmentAuto,
    ResourceNameAuto,
    deleteResource,
    savefunction,
    handleEdit,
    handleUpdate,
    setState,
    setFinalValue,
    finalValue,
  } = props;
  const classes = useStyles();
  const [snackAlert, setSnackAlert] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [resourse, setResourse] = useState({
    resourcetype: "",
    resourcerole: "",
    resourcedepartment: "",
    resourcename: [],
  });
  // console.log(props.state., "skjdkiieir");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    handlechange({
      ResourceName: [],
      ResourceDepartment: [],
      ResourceRole: [],
      resourcedefinition: [],
    });
  };

  const handlesubmit = () => {
    state.curreteditresourceindex ? handleUpdate() : savefunction();
    setAnchorEl(null);
    setFinalValue({
      ...finalValue,
      resourcedefinition: [...finalValue.resourcedefinition, resourse],
    });
  };

  console.log("clear11 state", state);
  return (
    <>
      {/* label : "ICT professional"
       title : "PRACTROLE" 
       value :"CodingMaster/10209" */}
      {/* label : "Outpatient Department" title : "DEPARTMENT" value :
      "CodingMaster/10175" */}
      {/* <Snackbar
        open={snackAlert}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // onClose={handleCloseAlert}
      >
        <Alert severity="error">Fill all Mandatory Fields</Alert>
      </Snackbar> */}
      <Card
        style={{
          padding: "18px",
          width: "95%",
          marginLeft: "16px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} style={{ display: "flex" }}>
              <p style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
                Resource Definition
              </p>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  //   className={classes.newRes}
                  // color="primary"
                  onClick={handleClick}
                  style={{
                    marginTop: "-6px",
                    color: "#0071F2",
                    fontFamily: "poppinsemibold",
                  }}
                  button
                  disableTouchRipple
                  id={"resource_definition_card_Id"}
                >
                  + Add New Resources
                </Button>
                <div style={{ marginBottom: "8px" }}>
                  <img
                    src={expandarrow}
                    alt="icon"
                    // style={{
                    //   transform: show ? "rotate(180deg)" : "rotate(359deg)",
                    // }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {props?.state?.Resourse &&
            props?.state?.Resourse?.map((v, i) => {
              return (
                <Grid
                  container
                  style={{
                    border: "1px solid #E0E0E0",
                    padding: 12,
                    // marginBottom: 8,
                    backgroundColor: "#F9F9F9",
                    position: "relative",
                  }}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={1}
                        style={{
                          backgroundColor: "#E0E0E0",
                          position: "absolute",
                          left: 0,
                          padding: "6px 12px",
                          borderTopRightRadius: 9,
                          borderBottomRightRadius: 9,
                        }}
                      >
                        {i + 1}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginLeft: "4%",
                          display: "flex",
                          padding: 10,
                        }}
                      >
                        <div>{v.type.label}</div>
                        <div>
                          {console.log(v)}
                          {v.role ? v.role.label : v.role ? v.role : " "}
                          {""}
                          <div className={classes.dot} />

                          {v.department.map((v) => v.label).join()}

                          {""}
                          <div className={classes.dot} />

                          {v.namelist.map((v) => v.label).join()}

                          {""}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="EditIcon"
                        className={classes.margin}
                        size="small"
                      >
                        <EditIcon
                          onClick={(e) => {
                            handleClick(e);
                            handleEdit(v, i);
                          }}
                          style={{ color: "#0071F2", fontSize: 18 }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="DeleteIcon"
                        className={classes.margin}
                        size="small"
                        onClick={() => deleteResource(i)}
                      >
                        <DeleteIcon
                          style={{ color: "#FF4D4A", fontSize: 18 }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Card>
      </Card>
      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 18, width: 700 }}>
          <Grid container direction="row">
            <Grid item lg={11} md={11} xl={11}>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: 14,
                  marginBottom: 8,
                  marginTop: 0,
                  fontWeight: "bold",
                }}
              >
                Add Resource
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <CloseIcon
                style={{
                  marginTop: "-18px",
                  cursor: "pointer",
                  marginLeft: "36px",
                }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} lg={12}>
            <Grid item lg={6} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Resource Types <span className={classes.req_red}>*</span>
              </Typography>
              <SelectBox
                parent_id={"resourceDefinition-select"}
                //disabled={Boolean(editData)}
                list={ResourceTypeAuto}
                // list={[]}
                value={state?.ResourceTypeList ?? []}
                // onchange={(e, v) =>
                //   handlechange(
                //     "ResourceTypeList",
                //     v,
                //     "PRACTROLE",
                //     "DEVICEROLE",
                //     "LOCATIONROLE"
                //   )
                // }
                onchange={(e, v) =>
                  handlechange(
                    "ResourceTypeList",
                    [v],
                    true,
                    setResourse({ ...resourse, resourcetype: v.value })
                  )
                }
              />
            </Grid>
            <Grid item lg={6} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Resource Role <span className={classes.req_red}>*</span>
              </Typography>
              <SelectBox
                parent_id={"resourceDefinition-select"}
                //disabled={Boolean(editData)}
                list={ResourceRoleAuto}
                // list={[]}
                value={state?.ResourceRole ?? []}
                onchange={(e, v) =>
                  handlechange(
                    "ResourceRole",
                    [v],
                    true,
                    setResourse({ ...resourse, resourcerole: v.value })
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} lg={12}>
            <Grid item lg={6}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Resource Department
              </Typography>
              <SelectBox
                parent_id={"resourceDefinition-select"}
                //disabled={Boolean(editData)}
                multi={true}
                //disabled={Boolean(editData)}
                list={ResourceRoleAuto}
                // list={[]}
                value={state?.ResourceDepartment ?? []}
                onchange={(e, v) =>
                  handlechange(
                    "ResourceDepartment",
                    [v],
                    true,
                    setResourse({ ...resourse, resourcedepartment: v })
                  )
                }
              />
            </Grid>
            <Grid item lg={6}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Resource Name
              </Typography>
              <SelectBox
                parent_id={"resourceDefinition-select"}
                //disabled={Boolean(editData)}
                multi={true}
                //disabled={Boolean(editData)}
                list={ResourceDepartmentAuto}
                // list={[]}
                value={state?.ResourceName ?? []}
                onchange={(e, v) =>
                  handlechange(
                    "ResourceName",
                    [v],
                    true,
                    setResourse({ ...resourse, resourcedepartment: v })
                  )
                }
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            float: "right",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            style={{ marginRight: "20px" }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            // color="primary"
            style={{
              marginRight: "34px",
              backgroundColor: "#0071F2",
              color: "white",
            }}
            onClick={() => handlesubmit()}
          >
            {state.curreteditresourceindex ? "update" : "save"}
          </Button>
        </div>
        {/* <div
          style={{
            width: "850px",
            height: "40px",
          }}
        ></div> */}
      </Popover>
    </>
  );
}

export default ResourceDefinitionCard;
