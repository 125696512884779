import React from "react";
import { Paper, Typography, makeStyles, Grid, TextField } from "@material-ui/core";
import { SelectBox } from "../../../components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        margin: 15
    },
    title: {
        fontFamily: "poppinsemibold",
        fontSize: "12px",
    },
    form: {
        paddingTop: 15
    },
    formtitle: {
        fontSize: "12px",
        paddingBottom: 10
    },
    formParent: {
        paddingRight: 10
    }
}));

function TTBasicDetails(props) {
    let dispatch = useDispatch();
    const state = useSelector(state => state.timetableMasterSlice)
    const classes = useStyles();
    const { stateData = {}, setState = () => null, editData = null, parent_id } = props;
    const updateState = async (name, value) => {
        setState({ ...stateData, [name]: value })
        if (name === "entity_type") {
            await dispatch(actions.GET_ENTITY_NAME_WITH_TYPE({ type: value.value }))
        }
        if (name === "resource_type") {
            await dispatch(actions.GET_RESOURCE_ROLE({ type: value, ename: stateData?.entity_name ?? null }))
        }
        let errState =JSON.parse(JSON.stringify(props.error))
         errState[name] = false;
        props.seterr(errState);
    }
    return (
        <React.Fragment>
            <Paper
                id={`${parent_id}-parent-paper`}
                className={classes.root}>
                <Typography
                    id={`${parent_id}-ORGANIZATION-title-typography`}
                    className={classes.title} variant="h6">ORGANIZATION</Typography>
                <Grid
                    id={`${parent_id}-ORGANIZATION-parent-grid`}
                    container className={classes.form}>
                    <Grid
                        id={`${parent_id}-Entity-Type-title-grid`}
                        md="3" lg="3" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-Type-title-typography`}
                            className={classes.formtitle} variant="body1">Entity Type <span
                                id={`${parent_id}-Entity-type-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'Entity-type'}
                            disabled={true}
                            // disabled={Boolean(editData)}
                            value={stateData?.entity_type ?? null}
                            onchange={(e, value) => updateState("entity_type", value)}
                            list={state.entity_type_list.data}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Entity-name-grid`}
                        md="5" lg="5" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-name-title-typography`}
                            className={classes.formtitle} variant="body1">Entity Name<span
                                id={`${parent_id}-Entity-name-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'Entity-name'}
                            disabled={Boolean(editData)}
                            value={stateData?.entity_name ?? null}
                            onchange={(e, value) => updateState("entity_name", value)}
                            list={state.entitynamewithType_list.data}
                            error={props.error?.entity_name}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper
                id={`${parent_id}-Timetable-paper`}
                className={classes.root}>
                <Grid
                    id={`${parent_id}-Timetable-container-grid`}
                    container className={classes.form}>
                    <Grid
                        id={`${parent_id}-Timetable-title-grid`}
                        md="4" lg="4" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Timetable-title-typography`}
                            className={classes.formtitle} variant="body1">Timetable title <span
                                id={`${parent_id}-Timetable-title-star`}
                                style={{ color: "red" }}>*</span></Typography>
                        <TextField
                            id={`${parent_id}-Timetable-textField`}
                            disabled={Boolean(editData)}
                            fullWidth variant="outlined" size="small"
                            value={stateData?.title} onChange={e => updateState("title", e.target.value)}
                             error={props.error?.title}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Applicable-App-Types-grid`}
                        md="8" lg="8" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Applicable-App-Types-title-typography`}
                            className={classes.formtitle} variant="body1">Applicable App Types<span
                                id={`${parent_id}-Applicable-App-Types-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'pplicable-type'}
                            disabled={Boolean(editData)}
                            multi={true} list={props.applicableData}
                            value={stateData?.applicable_type ?? []}
                            onchange={(e, value) => updateState("applicable_type", value)}
                            error={props.error?.applicable_type}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper
                id={`${parent_id}-RESOURCE-paper`}
                className={classes.root}>
                <Typography
                    id={`${parent_id}-RESOURCE-title-typography`}
                    className={classes.title} variant="h6">RESOURCE INFORMATION</Typography>
                <Grid
                    id={`${parent_id}-RESOURCE-container-grid`}
                    container className={classes.form}>
                    <Grid
                        id={`${parent_id}-Resource-Type-grid`}
                        md="4" lg="4" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Resource-Type-title-typography`}
                            className={classes.formtitle} variant="body1">Resource Type <span style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'Resource-Type'}
                            disabled={Boolean(editData)}
                            list={state.resourceType_list.data}
                            value={stateData?.resource_type ?? null}
                            onchange={(e, value) => updateState("resource_type", value)}
                           error={props.error?.resource_type}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Resource-role-grid`}
                        md="4" lg="4" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Resource-role-title-typography`}
                            className={classes.formtitle} variant="body1">Resource Role<span style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'Resource-role'}
                            disabled={Boolean(editData)}
                            list={state.resourcerole_list.data}
                            value={stateData?.resource_role ?? null}
                            onchange={(e, value) => updateState("resource_role", value)}
                            error={props.error?.resource_role}
                        />
                    </Grid>
                </Grid>
            </Paper>

        </React.Fragment>
    )
}

export default TTBasicDetails;
