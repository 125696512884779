import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  TableBody,
  Collapse,
  Box,
  IconButton,
  Menu,
  TextField,
  InputAdornment,
  Typography,
  ButtonGroup,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { CustomTableRow } from "./tableRow";
import { CustomTablePagination } from "./tablePagination";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { FilterIcon } from "../../../assets/filterIcon";
import searchicon from "../../../assets/icons - Search.svg";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontFamily: "poppin",
    fontSize: "14px",
    color: "#646464",
    padding: 10,
  },
  tableBody: {
    //padding: "12px",
    fontFamily: "poppinsemibold",
    fontSize: "12px",
    padding: 10,
  },
  circularProgress: {
    color: "#0071F2",
  },
});

const CustomProgress = (props) => {
  const classes = useStyles();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "20px 0",
      }}
    >
      <CircularProgress
        color={"primary"}
        classes={{
          colorPrimary: classes.circularProgress,
        }}
      />
    </div>
  );
};

export default function CommonTable(props) {
  const classes = useStyles();
  const {
    incrementCount = 0,
    showPagination = false,
    rowsPerPageOptions = [
      { label: "10 Rows", value: 10 },
      { label: "20 Rows", value: 20 },
      { label: "30 Rows", value: 30 },
    ],
    Header = [],
    dataList = [],
    tableData = [],
    handleCheckBox = () => null,
    handleTextfield = () => null,
    handleEdit = () => null,
    handleOverRide = () => null,
    rightAlign = [],
    handleDelete = () => null,
    handleView = () => null,
    condition = () => true,
    collapseCondition = () => true,
    style = {},
    handleHyperLink = () => null,
    handleSelect = () => null,
    parent_id,
    enableStart = [],
    handlepageChange = () => null,
    TableCount = 10,
    loading = false,
    customStyles,
  } = props;

  const [state, setState] = React.useState({
    open: null,
    anchorEl: [],
    search: [],
    checked: {},
    filter_state: { 0: [], 1: [] },
  });

  React.useEffect(() => {
    let arr = [];
    let temp_search = [];
    let temp_filter = {};
    for (let x = 0; x < Header.length; x++) {
      arr[x] = null;
      temp_search[x] = "";
      temp_filter[x] = Header[x]?.filter_options || [];
    }

    setState({
      ...state,
      anchorEl: arr,
      search: temp_search,
      filter_state: temp_filter,
    });
  }, [Header]);

  const setOpen = (data, index) => {
    if (state?.open === index) {
      onChangeState("open", null);
    } else {
      onChangeState("open", index);
      if (props.collapseToggle) {
        props.collapseToggle(data, index);
      }
    }
  };

  const onChangeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handleFilterClick = (event, index) => {
    let temp_anchor = state.anchorEl;
    temp_anchor[index] = event.currentTarget;

    setState({
      ...state,
      anchorEl: temp_anchor,
    });
  };

  const handleClose = (index) => {
    let temp_anchor = state.anchorEl;
    temp_anchor[index] = null;

    setState({
      ...state,
      anchorEl: temp_anchor,
    });
  };

  const changeState = (index, val) => {
    let temp_search = state.search;
    temp_search[index] = val;
    setState({
      ...state,
      search: temp_search,
    });
  };

  const handleChange = (event, key, index, i) => {
    console.clear();
    console.log(index);
    console.log(key);
    let temp_checked = state?.filter_state;
    temp_checked[i][index].isChecked = event.target.checked;
    console.log(temp_checked[i][index]);
    setState({
      ...state,
      filter_state: temp_checked,
    });
  };

  return (
    <>
      <TableContainer
        id={`${parent_id}_table_parent_container`}
        component={Paper}
        style={{
          border: "1px solid #e4e4e4",
          boxShadow: "none",
          maxHeight: 476,
          ...style,
        }}
      >
        <Table
          id={`${parent_id}_table_parent_Table`}
          stickyHeader
          //className={classes.table}
          aria-label="simple table"
        >
          <TableHead
            id={`${parent_id}_table_head`}
            style={{ background: "#F9F9F9" }}
          >
            <TableRow>
              {Header.map((val, i) => {
                console.log(val, "value");

                // const filterStyles = customStyles.filter(
                //   (item, index) => item.key === val
                // );
                // const customSty = filterStyles[0] ? filterStyles[0].styles : {};
                // console.log(filterStyles, "customSty");
                return (
                  <TableCell
                    id={`${parent_id}_${i}_table_TableCell`}
                    style={{
                      paddingLeft: i === 0 ? "25px" : "",
                      paddingRight: i === Header.length - 1 ? "25px" : "",
                      // ...customSty,
                    }}
                    className={classes.tableHeader}
                    align={rightAlign.indexOf(val) > -1 ? "right" : "left"}
                  >
                    <Box
                      display="flex"
                      justifyContent={
                        rightAlign.indexOf(val?.label || val) > -1
                          ? "end"
                          : "start"
                      }
                    >
                      <Box>{val?.label || val}</Box>
                      {val?.showSort && (
                        <Box>
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => val?.onclick(val?.label || val)}
                          >
                            {val?.sortValue ? (
                              <KeyboardArrowUpIcon fontSize="small" />
                            ) : (
                              <KeyboardArrowDown fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                      )}
                      {val?.showFilter && (
                        <Box>
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={(e) => handleFilterClick(e, i)}
                          >
                            <FilterIcon fontSize="small" />
                          </IconButton>{" "}
                          <Menu
                            id="simple-menu"
                            anchorEl={state.anchorEl[i]}
                            keepMounted
                            open={Boolean(state.anchorEl[i])}
                            onClose={() => handleClose(i)}
                            style={{ padding: 0 }}
                          >
                            <Box
                              style={{ paddingLeft: 10, paddingRight: 10 }}
                              display="flex"
                              flexDirection={"column"}
                            >
                              <Typography style={{ padding: 2 }}>
                                Filter Selected(2)
                              </Typography>
                              <TextField
                                placeholder={`Search ${val?.label}`}
                                size="small"
                                style={{ width: "231px", height: "34px" }}
                                type="search"
                                variant="outlined"
                                value={state.search[i]}
                                onChange={(e) => changeState(i, e.target.value)}
                                InputProps={{
                                  style: {
                                    fontFamily: "poppin",
                                    fontSize: "12px",
                                    marginRight: "10px",
                                    background: "#f7f7f7",
                                  },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img src={searchicon} alt="Icon" />
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  style: { fontSize: 12 },
                                }}
                              />
                              <div
                                style={{
                                  height: 150,
                                  overflowX: "auto",
                                  padding: 10,
                                }}
                              >
                                <FormGroup>
                                  {console.log(state?.filter_state)}
                                  {state?.filter_state[i]?.map((x, z) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          size="small"
                                          checked={x.isChecked}
                                          name={x.label}
                                          onChange={(event) =>
                                            handleChange(event, x, z, i)
                                          }
                                          value={x.value}
                                        />
                                      }
                                      label={x.label}
                                    />
                                  ))}

                                  {/*  */}
                                </FormGroup>
                              </div>
                            </Box>
                            <ButtonGroup variant="text" fullWidth>
                              <Button> Select All </Button>
                              <Button> Reset </Button>
                            </ButtonGroup>
                          </Menu>
                        </Box>
                      )}
                    </Box>

                    {enableStart.indexOf(val) > -1 ? (
                      <span
                        id={`${parent_id}_${i}_table_star-TableCell`}
                        style={{ marginLeft: "5px", color: "red" }}
                      >
                        *
                      </span>
                    ) : (
                      ""
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody id={`${parent_id}_table_dataList_tablebody`}>
            {Array.isArray(dataList) &&
              dataList.map((row, index) => {
                return (
                  <>
                    <CustomTableRow
                      //parent_id={"CustomTableRow" + index}
                      tableData={tableData}
                      condition={condition}
                      Header={Header}
                      index={index}
                      state={state}
                      row={row}
                      parent_id={parent_id}
                      handleCheckBox={handleCheckBox}
                      handleEdit={handleEdit}
                      handleOverRide={handleOverRide}
                      handleDelete={handleDelete}
                      handleView={handleView}
                      handleTextfield={handleTextfield}
                      handleHyperLink={handleHyperLink}
                      handleSelect={handleSelect}
                      setOpen={setOpen}
                      incrementCount={incrementCount}
                    />
                    {index === state.open && row?.children?.length > 0 && (
                      <>
                        {/* <TableRow id={`${parent_id}_table_dataList_TableRow`}> */}
                        {/* <TableCell
                          id={`${parent_id}_table_dataList_TableCell`}
                          style={{ padding:"0px 8px 0px 0px" }}
                          colSpan={12}
                        > */}
                        {/* <Collapse
                            id={`${parent_id}_table_dataList_Collapse`}
                            in={index === state.open}
                            timeout="auto"
                            unmountOnExit
                          > */}
                        {/* <Table
                              id={`${parent_id}_table_dataList_Table`}
                              className={classes.table}
                              size="small"
                            > */}
                        {/* <TableHead
                               id={`${parent_id}_table_head`}
                               style={{ background: "#F9F9F9" }}
                              >
                                <TableRow>
                                  {Header.map((val, i) => {
                                    return (
                                      <TableCell
                                         id={`${parent_id}_${i}_table_TableCell`}
                                         style={{
                                         paddingLeft: i === 0 ? "25px" : "",
                                         paddingRight: i === Header.length - 1 ? "25px" : "",
                                         }}
                                        className={classes.tableHeader}
                                        align={rightAlign.indexOf(val) > -1 ? "right" : "left"}
                                      >
                                        {val}
                                        {enableStart.indexOf(val) > -1 ? (
                                           <span
                                            id={`${parent_id}_${i}_table_star-TableCell`}
                                            style={{ marginLeft: "5px", color: "red" }}
                                           >
                                              *
                                           </span>
                                         ) : (
                                          ""
                                        )}
                                      </TableCell>
                                      );
                                    })}
                                </TableRow>
                              </TableHead> */}
                        {/* <TableBody
                                id={`${parent_id}_table_dataList_TableBody`}
                              > */}
                        {Array.isArray(row?.children) &&
                          row?.children?.map((row, i) => {
                            return (
                              <CustomTableRow
                                //parent_id={"CustomTableRow" + index}
                                tableData={tableData}
                                condition={(row, type) =>
                                  collapseCondition(row, type, i, index)
                                }
                                Header={Header}
                                index={i}
                                state={state}
                                row={row}
                                parent_id={parent_id}
                                handleCheckBox={(e, row, i) =>
                                  handleCheckBox(e, row, i, index)
                                }
                                handleEdit={(e, row, i) =>
                                  handleEdit(e, row, i, index)
                                }
                                handleView={(e, row, i) =>
                                  handleView(e, row, i, index)
                                }
                                handleOverRide={(e, row, i) =>
                                  handleOverRide(e, row, i, index)
                                }
                                handleDelete={(e, row, i) =>
                                  handleDelete(e, row, i, index)
                                }
                                handleTextfield={(e, row, name, i) =>
                                  handleTextfield(e, row, name, i, index)
                                }
                                handleHyperLink={(e, row, i) =>
                                  handleHyperLink(e, row, i, index)
                                }
                                handleSelect={(data, row, name, i) =>
                                  handleSelect(data, row, name, i, index)
                                }
                                setOpen={setOpen}
                                // tdStyle={{
                                //   borderBottom: 0,
                                // }}
                              />
                            );
                          })}
                        {/* </TableBody> */}
                        {/* </Table> */}
                        {/* </Collapse> */}
                        {/* </TableCell> */}
                        {/* </TableRow> */}
                      </>
                    )}
                  </>
                );
              })}
          </TableBody>
        </Table>
        {loading ? (
          <CustomProgress />
        ) : (
          dataList.length === 0 && (
            <div
              id={`${parent_id}_table_nodataFound_div`}
              style={{
                textAlign: "center",
                padding: "20px",
                color: "#646464",
              }}
            >
              {" "}
              No Data Found!
            </div>
          )
        )}
      </TableContainer>
      {showPagination && dataList?.length > 0 && (
        <CustomTablePagination
          count={TableCount}
          handlepageChange={handlepageChange}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      )}
    </>
  );
}
