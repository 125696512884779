import React from "react";
import CreateServiceDefinition from "../../components/availabilitymanagement/createAppointmentTypes/serviceDefinition";

function Service (){

    return(<>
    
    <CreateServiceDefinition/>
    </>
    )
}
export default Service