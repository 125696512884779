import React from "react";
import {
  Div,
  Paper,
  //Select,
  H6,
  Col,
  Row,
  TextInput,
  Text,
  Image,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
// import { SelectBox } from "../../common/smartForm/component";
// import { Typography } from "@material-ui/core";
import { SelectWithLabel } from "../../../components";
class PatientCotactPoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setDetails = (name, value, index, type) => {
    if (
      (type?.toLocaleLowerCase() === "mobile" ||
        type?.toLocaleLowerCase() === "phone") &&
      value?.length > 10
    ) {
      return false;
    }
    if (this.props?.setCustom) {
      this.props?.setCustom(name, value, index);
      return false;
    }
    let patientData = this.props[this.props.saveto];
    if (patientData.contact?.error) {
      patientData.contact.error[name] = false;
    }
    if (name === "mode") {
     patientData.contact[index]["mobile"] = "";
    }
    patientData.contact[index][name] = value;
    patientData[this.props.setTo]({
      ...patientData,
    });
  };
  OpenAddress = async (e) => {
    if (this.props?.addNewContact) {
      this.props.addNewContact();
      return false;
    }
    let patientData = this.props[this.props.saveto];
    patientData.contact.push({});
    patientData[this.props.setTo]({
      ...patientData,
    });
  };
  handleClear = () => {
    this.setState({
      editadd: {},
      editindex: null,
    });
  };

  styles = {
    labelStyleDes: {
      color: "#6F6F6F",
      fontSize: "9px",
      fontFamily: `pc_regular !important`,
      marginLeft: "10px",
      marginTop: "3px",
    },
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    borderStyle1: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      // width: "98px",
    },
    borderStyle2: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      // width: "98px",
      // marginLeft: "-65px",
    },
    labelStyle2: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
      // marginLeft: "-65px",
    },
  };

  label = (values) => {
    if (values === "phone") {
      return "Phone";
    } else if (values?.toLocaleLowerCase() === "fax") {
      return "Fax";
    } else if (values?.toLocaleLowerCase() === "email") {
      return "E-mail";
    } else if (values?.toLocaleLowerCase() === "url") {
      return "Url";
    } else if (values?.toLocaleLowerCase() === "sms") {
      return "SMS";
    } else if (values?.toLocaleLowerCase() === "other") {
      return "Other";
    } else {
      return values ?? "E-mail";
    }
  };

  ondelete = (index) => {
    if (this.props?.ondelete) {
      this.props.ondelete(index);
    } else {
      let patientData = this.props[this.props.saveto];
      patientData.contact.splice(index, 1);
      patientData[this.props.setTo]({
        ...patientData,
      });
    }
  };

  allowNumbers = (e) => {
    if (!(e.keyCode === 8 || e.keyCode === 46 || (e.which >= 48 && e.which <= 57) || (e.which >= 37 && e.which <= 40))) {
      e.preventDefault()
    }
  }

  render() {
    let contact = [];
    if (this.props?.contactList) {
      contact = this.props?.contactList;
    } else {
      contact = this.props[this.props.saveto].contact;
    }
    const {
      labelStyle,
      borderStyle,
      labelStyleDes
    } = this.styles;
    const { parent_id } = this.props;

    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-CONTACT-DETAILS-div`}
          >
            <H6
              id={`${parent_id}-CONTACT-DETAILS-title-h6`}
              className="pi-title">CONTACT DETAILS
              {/* {contact?.length > 0 ? "": */}
              {this?.props?.subtitle_message &&
                <span style={labelStyleDes}>
                  {this?.props?.subtitle_message}
                </span>}
              {/* } */}
            </H6>
            <Text
              id={`${parent_id}-Add-New-text`}
              className="pi-addbtn"
              onClick={(e) => {
                this.OpenAddress(e);
              }}
            >
              + Add New
            </Text>
          </Div>
          {contact?.length > 0 &&
            contact.map((val, i) => {
              return (
                <>
                  <Row
                    id={`${parent_id}-${i}-contact-row`}
                    className="pi-actions-root">
                    <Col
                      id={`${parent_id}-${i}-contact-mode-col`}
                      md={2}
                      lg={2}
                      sm={6}
                      xs={12}
                      className="no-padding-left"
                      style={{ padding: "0" }}
                    >
                      {/* <Select
                    showArrow
                    label="Contact Mode"
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={borderStyle}
                    hoverColor="#0071F2"
                    options={this.props?.allMasters?.["contact_mode"]}
                    getOptionLabel="label"
                    value={val?.mode?.label ?? null}
                    onChangeValue={(e) => this.setDetails("mode", e, i)}
                  /> */}
                      {/* <Typography
                        style={{
                          fontSize: "12px",

                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                        }}
                        variant="body1"
                      >
                        Contact Mode
                      </Typography>
                      <SelectBox
                        list={this.props?.allMasters?.["contact_mode"] ?? []}
                        placeholder={"select..."}
                        disableClearable
                        value={val?.mode}
                        onchange={(e, value) =>
                          this.setDetails("mode", value, i)
                        }
                      /> */}
                      <SelectWithLabel
                        parent_id={'contact-mode'}
                        label={"Contact Mode"}
                        options={this.props?.allMasters?.["contact_mode"] ?? []}
                        disableClearable
                        value={val?.mode}
                        // value={val?.mode ? val?.mode : (i === 0 ? this.props?.allMasters?.["contact_mode"].filter(val => val.value === "CodeableConceptMaster/11248")?.[0] : {}) }
                        onChange={(value) => this.setDetails("mode", value, i)}
                        placeholder={"Select"}
                      />
                    </Col>
                    {(this.label(val?.mode?.label) === "Mobile" ||
                      this.label(val?.mode?.label) === "Phone") && (
                        <Col
                          id={`${parent_id}-${i}-country-code-col`}
                          md={2}
                          lg={2}
                          sm={6}
                          xs={12}
                          className="no-padding-left"
                          inLineStyles={{ paddingRight: 0 }}
                        >
                          {/* <br /> */}
                          {/* <Select
                      label="Country code"
                      value={val?.country_code ? val.country_code?.label : ""}
                      getOptionLabel="label"
                      // options={[{ value: "+91", label: "+91" }]}
                      onChangeValue={(e) =>
                        this.setDetails("country_code", e, i)
                      }
                      options={this.props?.allMasters?.["country_code"]}
                      placeholder={this.label(val?.mode?.label)}
                      abelStyle={labelStyle}
                      style={borderStyle}
                      showArrow
                      placeholder="select..."
                      labelStyle={labelStyle}
                      inLineStyles={borderStyle}
                      hoverColor="#0071F2"
                    /> */}

                          {/* <Typography
                          style={{
                            fontSize: "12px",

                            Fontfamily: "pc_regular",
                            color: "#6F6F6F",
                          }}
                          variant="body1"
                        >
                          Contact Mode
                        </Typography>
                        <SelectBox
                          list={this.props?.allMasters?.["country_code"] ?? []}
                          placeholder={"select..."}
                          disableClearable
                          value={val?.country_code}
                          onchange={(e, value) =>
                            this.setDetails("country_code", value, i)
                          }
                        /> */}
                          <SelectWithLabel
                            parent_id={'country-code'}
                            label={"Country Code"}
                            options={this.props?.allMasters?.["country_code"] ?? []}
                            disableClearable
                            value={val?.country_code}
                            onChange={(value) => this.setDetails("country_code", value, i)}
                            placeholder={"Select"}
                          />
                        </Col>
                      )}

                    <Col
                      id={`${parent_id}-${i}-Mobile-col`}
                      md={2}
                      lg={2}
                      sm={6}
                      xs={12}
                      className="no-padding-left"
                    // style={{ padding: "0" }}
                    >
                      <TextInput
                        id={`${parent_id}-${i}-Mobile-textinput`}
                        type={
                          this.label(val?.mode?.label) === "Mobile" ||
                            this.label(val?.mode?.label) === "Phone"
                            ? "number"
                            : "type"
                        }
                        helperText={
                          val?.error && val?.error["mobile"]
                            ? "Is required"
                            : ""
                        }
                        value={val.mobile ? val.mobile : ""}
                        onChange={(e) =>
                          this.setDetails(
                            "mobile",
                            e.target.value,
                            i,
                            this.label(val?.mode?.label)
                          )
                        }
                        error={
                          val?.error && val?.error["mobile"] ? true : false
                        }
                        label={this.label(val?.mode?.label)}
                        labelStyle={labelStyle}
                        style={borderStyle}
                        placeholder={this.label(val?.mode?.label)}
                        // isRequired={true}
                        // disabled={contact.mode ? false : true}
                        hoverColor="#0071F2"
                        onKeyDown={(evt) => (this.label(val?.mode?.label) === "Mobile" ||
                          this.label(val?.mode?.label) === "Phone") ? this.allowNumbers(evt) : {}}
                      />
                    </Col>
                    <Col
                      id={`${parent_id}-${i}-Address-use-col`}
                      md={2}
                      lg={2}
                      sm={6}
                      xs={12}
                      className="no-padding-left"
                    >
                      {/* <Select
                    showArrow
                    label="Use"
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={borderStyle1}
                    hoverColor="#0071F2"
                    options={this.props?.allMasters?.["Address_use"]}
                    getOptionLabel="label"
                    value={val?.use?.label ?? null}
                    onChangeValue={(e) => this.setDetails("use", e, i)}
                  /> */}

                      {/* <Typography
                        style={{
                          fontSize: "12px",

                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                        }}
                        variant="body1"
                      >
                        Use
                      </Typography>
                      <SelectBox
                        list={this.props?.allMasters?.["Address_use"] ?? []}
                        placeholder={"select..."}
                        disableClearable
                        value={val?.use}
                        onchange={(e, value) =>
                          this.setDetails("use", value, i)
                        }
                      /> */}

                      <SelectWithLabel
                        parent_id={'Address-use'}
                        label={"Use"}
                        options={this.props?.allMasters?.["Address_use"] ?? []}
                        disableClearable
                        value={val?.use}
                        onChange={(value) => this.setDetails("use", value, i)}
                        placeholder={"Select"}
                      />
                    </Col>
                    <Col
                      id={`${parent_id}-${i}-Priority-col`}
                      md={2}
                      lg={2}
                      sm={6}
                      xs={12}
                      className="no-padding-left"
                      style={{ padding: "0" }}
                    >
                      {/* <Select
                    showArrow
                    label="Priority"
                    placeholder="select..."
                    labelStyle={labelStyle2}
                    inLineStyles={borderStyle2}
                    hoverColor="#0071F2"
                    options={this.props?.allMasters?.["priority"]}
                    getOptionLabel="label"
                    value={val?.priority?.label ?? null}
                    onChangeValue={(e) => this.setDetails("priority", e, i)}
                  /> */}
                      {/* <Typography
                        style={{
                          fontSize: "12px",

                          Fontfamily: "pc_regular",
                          color: "#6F6F6F",
                        }}
                        variant="body1"
                      >
                        Priority
                      </Typography>
                      <SelectBox
                        list={this.props?.allMasters?.["priority"] ?? []}
                        placeholder={"select..."}
                        disableClearable
                        value={val?.priority}
                        onchange={(e, value) =>
                          this.setDetails("priority", value, i)
                        }
                      /> */}

                      <SelectWithLabel
                        parent_id={'priority'}
                        label={"Priority"}
                        options={this.props?.allMasters?.["priority"] ?? []}
                        disableClearable
                        value={val?.priority}
                        onChange={(value) => this.setDetails("priority", value, i)}
                        placeholder={"Select"}
                      />
                    </Col>
                    <Col
                      id={`${parent_id}-${i}-trash-col`}
                      md={2}
                      lg={2}
                      sm={6}
                      xs={12}
                      className="no-padding-left"
                      style={{
                        padding: "0px",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "16px",
                      }}
                    >
                      <Image
                        id={`${parent_id}-${i}-trash-image`}
                        onClick={() => this.ondelete(i)}
                        style={{
                          ursor: "pointer",
                          marginLeft: "15px",
                          width: "20px",
                          padding: "2px",
                          height: "20px",
                        }}
                        src="images/icons8-trash.svg"
                        alt="delete"
                      />
                    </Col>
                  </Row>
                </>
              );
            })}
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(PatientCotactPoint);
