import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";
import "./index.css";

/**
 *
 * @param props
 * @returns
 */
export const YearPicker = (props) => {
  const { label, inputYearPicker, currentYear, prevYear, nextYear } = props;

  return (
    <div
      style={{ paddingTop: 2 }}
      className={!inputYearPicker && "withoutInput"}
    >
      <Typography variant="subtitle2" className="calendarLabel">
        {label}
      </Typography>
      <div className="normalYear">
        <Grid className="padding" onClick={() => prevYear()}>
          <i className="arrow_go left " />
        </Grid>
        <Typography variant="subtitle2" className="currentYear">
          {currentYear}
        </Typography>
        <button className="nextBtn" type="button" onClick={() => nextYear()}>
          <i className="arrow_go right" />
        </button>
      </div>
    </div>
  );
};

YearPicker.propTypes = {
  inputYearPicker: PropTypes.bool,
  customClass: PropTypes.string,
};
YearPicker.defaultProps = {
  inputYearPicker: false,
  customClass: "",
};
