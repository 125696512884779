import React from "react";
import { Typography, Grid, Modal, Paper, Divider } from "@material-ui/core";
import { generateComponentVersion2 } from "./component/formListVersion2";
import Button from "@material-ui/core/Button";
import styles from "./styles";
// import { generateComponent2 } from "./formsListVersion2";
import { withAllContexts } from "../../../HOCs";
import moment from "moment";
import { actions } from "primary_care_admin_binder";
import { useDispatch } from "react-redux";
import { SearchWithFilter } from "../../../components/searchFilter";
import TreeViewStatus from "../../availabilitymanagement/holidayTreeTable/TreeTable/TreeViewStatus";

const initialstates = {
  list: [],
  states: {},
  error: {},
};
function GenerateFormVersion2(props) {
  const classes = styles(props);
  const {
    handleshow,
    isAdd,
    clicked,
    showStatus,
    treeListData,
    setValues,
    values,
    isEditHoliday,
    EditMaster,
    isEdit,
    refresh,
    Thdata,
    controler,
  } = props;
  const [state, setState] = React.useState(initialstates);
  const [yearSelect, setYearSelect] = React.useState();
  const dispatch = useDispatch();
  console.log(isAdd, controler, "tessstwe");
  const [isFirst, setIsFirst] = React.useState(false);
  const { nosave = false, parent_id } = props;

  const handleChange = (type, val, isUpperCase, limit) => {
    console.log(type, val, isUpperCase, limit, "tessterewwwwe");
    if (limit) {
      if (val?.length > limit) {
        return false;
      }
    }
    // if (pattern) {
    //   if (pattern.test(val) === false) {
    //     return false;
    //   }
    // }
    if (props.onchangefun) {
      props.onchangefun(type, val?.value, val);
    }
    setState({
      ...state,
      states: {
        ...state.states,
        [type]: isUpperCase ? val.toUpperCase()?.replaceAll(" ", "") : val,
      },
    });
  };

  const validation = () => {
    let error = {};
    // eslint-disable-next-line array-callback-return
    state.list.map((val) => {
      if (val.required) {
        if (
          val?.componet_type !== "select" &&
          state?.states[val?.state_name]?.length === 0
        ) {
          error[val.state_name] = true;
        } else if (val?.componet_type === "select") {
          if (
            state?.states[val?.state_name]
              ? Object.keys(state?.states[val?.state_name])?.length === 0
              : true
          )
            error[val.state_name] = true;
        } else if (val?.componet_type === "textField_select") {
          if (
            state?.states[val?.state_name]?.type
              ? Object.keys(state?.states[val?.state_name]?.type)?.length === 0
              : true
          ) {
            error[val.state_name] = true;
          }
          if (state?.states[val?.state_name]?.value?.length === 0) {
            error[val.state_name] = true;
          }
        } else if (val?.componet_type === "date") {
          if (moment(state.states?.effFrom) > moment(state?.states.effTo)) {
            error["effTo"] = true;
          }
        }
      } else {
        error[val.state_name] = false;
      }
    });
    if (
      error &&
      Object.keys(error).length > 0 &&
      Object.keys(error).filter((val) => error[val] === true).length > 0
    ) {
      setState({
        ...state,
        error: error,
      });
      return true;
    } else {
      setState({
        ...state,
        error: error,
      });
      return false;
    }
  };
  const handlesave = () => {
    if (!props?.children && validation()) {
      if (moment(state.states?.effFrom) > moment(state?.states.effTo)) {
        props.alert.setSnack(
          "error",
          "From date should not be greater than To date"
        );
      } else {
        props.alert.setSnack("mandatory");
      }
      return false;
    }
    if (props?.save) {
      // setState({})
      props?.children ? props?.save() : props.save(state.states);
    }
  };

  React.useEffect(() => {
    if (!props?.children && !props?.loading && props.open) {
      let statesList = {};
      props.json.map((val) => {
        return (statesList[val.state_name] = val.value);
      });
      setState({
        ...state,
        list: props.json,
        states: statesList,
      });
      setIsFirst(true);
    }
    if (!props.open) {
      setIsFirst(false);
    }
  }, [props.open]);

  React.useEffect(() => {
    if ((state?.list?.length ?? -1) > 0 && isFirst) {
      setState({
        ...state,
        list: props.json,
      });
    }
  }, [props.json]);

  const handlecancel = () => {
    if (props?.handlecancel) {
      setState({});
      props.handlecancel();
    }
  };
  const onSearchChange = (value) => {
    const response = dispatch(actions.ENTITY_MASTERS({ search: value }));
  };
  return (
    <Modal open={props.open} className={classes.modal} onClose={handlecancel}>
      <Paper className={props.modalStructure}>
        <div className={classes.modalHeader}>
          <Typography style={{ fontFamily: "pc_semibold" }}>
            {props.header}
          </Typography>
          <img
            src="/images/close.svg"
            className={classes.closeModal}
            onClick={() => handlecancel()}
            alt="close"
          />
        </div>
        <Divider
          className={classes.modalHeaderDivider}
          style={{ borderBottom: "2px solid lightGray" }}
        />
        <Grid md={12} container className={classes.modalContent}>
          {props?.children
            ? props?.children
            : state?.list?.length > 0 &&
              state?.list?.map((val) => {
                return generateComponentVersion2({
                  data: val,
                  handleChange: handleChange,
                  setYearSelect: setYearSelect,
                  yearSelect: yearSelect,
                  state: state,
                  classes: classes,
                  parent_id: parent_id,
                  handleshow: handleshow,
                  setValues: setValues,
                  values: values,
                });
              })}
        </Grid>

        {isAdd && (
          <>
            {isEditHoliday && isAdd && (
              <Grid className={classes.searchGrid}>
                <Typography className={classes.facility}>Facility</Typography>
                <Grid style={{ padding: "10px" }}>
                  <SearchWithFilter
                    id="Holiday"
                    placeholder="Search & add facilities"
                    hideSearchBar={false}
                    hideFilter={true}
                    onSearchChange={onSearchChange}
                  />
                </Grid>
              </Grid>
            )}
            <div>
              <TreeViewStatus
                isAdd={isAdd}
                clicked={clicked}
                treeListData={treeListData}
                setValues={setValues}
                values={values}
                EditMaster={EditMaster}
                isEdit={isEdit}
                refresh={refresh}
                screeen="AppointmentTyp"
                Thdata={Thdata}
              />
            </div>
          </>
        )}
        <Divider className={classes.modalHeaderDivider} />
        <Grid container item md={12} display="flex" justifyContent="flex-end">
          <Button
            id={`${parent_id}_generateform_Cancel_button`}
            variant={isAdd ? "contained" : "outlined"}
            style={{ marginTop: 20, marginRight: 10 }}
            size={"small"}
            contained
            onClick={() => handlecancel()}
            className={isAdd ? classes.facilityCancel : classes.normalBtn}
            disableRipple
            disableElevation
          >
            Cancel
          </Button>
          {
            <Button
              id={`${parent_id}_generateform_Save_button`}
              disabled={props?.loading ?? false}
              variant={"contained"}
              style={{ marginTop: 20 }}
              size={"small"}
              contained
              onClick={() => {
                handlesave();
              }}
              className={classes.saveBtn}
              disableRipple
              disableElevation
            >
              {props?.loading
                ? "Saving..."
                : nosave
                ? "Confirm"
                : !isEdit
                ? isAdd
                  ? "Add"
                  : "Save"
                : controler
                ? "Add"
                : "Save"}
            </Button>
          }
        </Grid>
      </Paper>
    </Modal>
  );
}

export default withAllContexts(GenerateFormVersion2);
