import React from "react";
import {
    Grid, Dialog, DialogTitle, DialogContent,
    Typography, Avatar, makeStyles, Button
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import OverRideIcon from "../../../assets/overrideIcons";
import ClockIcon from "../../../assets/clock";
import AppointmentPeriod from "../../../assets/appointperiod";
import { SelectWithLabel } from '../../../components';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../../HOCs"
import { AlertProps } from "../../../utils";

const useClass = makeStyles((theme) => ({
    cardParent: {
        border: "1px solid lightgray",
        borderRadius: "6px",
        marginBottom: "12px"
    },
    title: {
        background: "#e5e4e48a",
        padding: "8px 13px",
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px"
    },
    titleText: {
        fontWeight: "bold",
    },
    titleCaption: {
        fontSize: "13px"
    },
    carddetParent: {
        gridTemplateColumns: "52px 1fr",
        display: "grid",
        padding: "7px 0px 13px 0px"
    },
    carddetails: {
        padding: 13
    },
    parentTitle: {
        fontSize: "17px",
        fontWeight: "bolder"
    },
    dot: {
        width: "6px",
        display: "block",
        height: "6px",
        margin: "0 7px",
        display: "inline-block",
        borderRadius: "50%",
        backgroundColor: "rgba(0, 0, 0, 0.26)"
    },
    maa: {
        color: "#68B4E6"
    },
    mo: {
        color: "#9F6BE7"
    },
    mw: {
        color: "#fbc257"
    },
}));

function OverRideAffected(props) {
    const { editData = {}, isopenAA = false, handleCloseAA = () => null, successForm = () => null } = props;
    const dispatch = useDispatch();
    const reasonOptionList = useSelector((state) => state.appointmentApiSlice.reasonForApptCancelModify);
    const affected_list = useSelector((state) => state.timetableMasterSlice.affected_list);
    const classes = useClass();
    const [reason, setReason] = React.useState("")
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        const defaultFun = async () => {
            await dispatch(actions.REASON_FOR_APPT_CANCEL_MODIFY({ type: 'APPCANCELBYORG' }));
            await dispatch(actions.READ_AFFECTED_APPOINTLIST({ timeTableId: editData._id }));
            setLoading(false)
        }
        defaultFun()
    }, [])

    const handleConfirm = () => {
        if (reason) {
            successForm(reason)
        } else {
            props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: `Please select reason`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
            });
        }
    }

    return <React.Fragment>
        <Dialog onClose={() => null} open={isopenAA} fullWidth maxWidth={"sm"}>
            <DialogTitle id="customized-dialog-title" >
                <Typography className={classes.parentTitle}>Appointments Affected</Typography>
                <CloseIcon onClick={() => handleCloseAA()} style={{ float: "right", marginTop: "-25px", cursor: "pointer" }} />
            </DialogTitle>
            <DialogContent dividers>
                {loading ? <Grid style={{ display: "flex", height: "120px" }}>
                    <Grid style={{ display: "grid", margin: "auto", gridTemplateColumns: "34px 1fr" }}>
                        <Grid item>
                            <CircularProgress color="inherit" size={20} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" color="inherit" style={{ fontSize: "13px" }} >Fetching Affected Appointments...</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                    : <Grid container>
                        {affected_list?.data?.length === 0 ? <Grid style={{ display: "flex", height: "80px", width: "100%" }}>
                            <Grid style={{ margin: "auto" }}>
                                <Typography variant="h6" color="inherit" style={{ fontSize: "15px" }} >No Affected Appointment</Typography>
                            </Grid>
                        </Grid> : affected_list?.data.map(val => {
                            return <Grid md={12} sm={12} xs={12} lg={12} className={classes.cardParent}>
                                <Grid md={12} sm={12} xs={12} lg={12} className={classes.title}>
                                    <Typography className={classes.titleText}>{val.bookedCount} Appointments Affected</Typography>
                                </Grid>
                                <Grid md={12} sm={12} xs={12} lg={12} className={classes.carddetails}>
                                    <Grid md={12} sm={12} xs={12} lg={12} className={classes.carddetParent} >
                                        <Grid>
                                            <Avatar variant="rounded" >{val?.name?.[0] ?? ""}</Avatar>
                                        </Grid>
                                        <Grid>
                                            <Typography className={classes.titleText}>{val?.name ?? ""}</Typography>
                                            <Typography variant="caption" className={classes.titleCaption}>{val?.role ?? ""}</Typography>
                                            {val?.type && <div className={classes.dot} />}
                                            <Typography variant="caption" className={classes.titleCaption} >{val?.type ?? ""}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid md={12} sm={12} xs={12} lg={12} className={classes.listParent}>
                                        <Typography variant="caption" className={classes.maa}><span style={{ marginRight: 5 }}><AppointmentPeriod fill="#68B4E6" height={14} width={13} /></span>Max allowed appointments-{val?.bookedCount ?? 0}/{val?.maxAppointment ?? 0}</Typography>
                                        <Typography variant="caption" className={classes.mo}><span style={{ marginLeft: 9, marginRight: 5 }}><OverRideIcon fill="#9F6BE7" height={10} width={10} /></span>Max overbooking-{val?.overBookCount ?? 0}/{val?.Maxbooking ?? 0}</Typography>
                                        <Typography variant="caption" className={classes.mw}><span style={{ marginLeft: 9, marginRight: 5 }}><ClockIcon fill="#fbc257" height={10} width={10} /></span>Max waitlist-{val?.waitListCount ?? 0}/{val?.Maxwaiting ?? 0}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                        <Grid md={12} sm={12} xs={12} lg={12} style={{ paddingTop: "10px" }} >
                            <SelectWithLabel
                                parent_id={'cancel'}
                                options={reasonOptionList?.data}
                                label={`Reason`}
                                required={true}
                                value={reason}
                                onChange={(data) => setReason(data)}
                            // error={props?.data?.error?.category??false}
                            />
                        </Grid>
                        <Grid md={12} sm={12} xs={12} lg={12} style={{ padding: "20px 17px 3px 17px", textAlign: "center" }} >
                            <Button variant="contained" color="primary" onClick={() => handleConfirm()}>confirm</Button>
                        </Grid>
                    </Grid>}

            </DialogContent>
        </Dialog>
    </React.Fragment>
}

export default withAllContexts(OverRideAffected);