/**
 * @author Sri Rahul
 * @email rahul@qdmplatforms.com
 * @create date 2021-06-28 20:37:31
 * @modify date 2021-06-28 20:37:31
 * @desc [description]
 */

import React from 'react';
import { HoverNavBar } from './hoverNavbar';

class HoverNavbarParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <HoverNavBar
            id={'hovernavbar'}
            {...this.props} />
    }
}

export default HoverNavbarParent;