import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

const ConfirmationDialog = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={props?.modalOpen?.open}
        onClose={props?.handleModalClose}
        aria-labelledby="simple-dialog-title"
        classes={{ container: classes.container }}
      >
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.deleteHeader}>Confirmation</Typography>
          {props?.parent_id === "delete_time_table" ? (
            <Typography style={{ fontSize: "14px", lineHeight: 1.2 }}>
              {" "}
              Are you sure you want to delete <br></br>the record??
            </Typography>
          ) : (
            <Typography style={{ fontSize: "14px", lineHeight: 1.2 }}>
              {" "}
              Shortening the period may<br></br> affect already generated
              <br></br>
              schedules, do you want to <br></br>proceed?
            </Typography>
          )}
        </DialogContent>
        {props?.parent_id === "delete_time_table" ? (
          <>
            <div style={{ padding: "0px 24px" }}>
              <Button
                onClick={() => props?.handleDeleteData(props?.modalOpen?.data)}
                className={classes.deleteButton}
                fullWidth
                variant="contained"
              >
                Yes 
              </Button>
            </div>
            <div style={{ padding: "8px 24px 24px" }}>
              <Button
                onClick={props?.handleModalClose}
                variant="outlined"
                style={{backgroundColor:"#FF4D4A"}}
                className={classes.deleteButton2}
                fullWidth
              >
                No
              </Button>
            </div>
          </>
        ) : (
          <>
            <div style={{ padding: "0px 24px" }}>
              <Button
                onClick={() => props?.handleRemove(props?.modalOpen?.end_date)}
                className={classes.deleteButton}
                fullWidth
                variant="contained"
              >
                Yes Remove
              </Button>
            </div>
            <div style={{ padding: "8px 24px 24px" }}>
              <Button
                onClick={props?.handleModalClose}
                variant="outlined"
                className={classes.deleteButton2}
                fullWidth
              >
                No
              </Button>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
