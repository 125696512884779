import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { CommonTable, SearchWithFilter } from "../../components";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import AddLocation from "./addLocation/index";
import { routes } from "../../router/routes";
import { useHistory } from "react-router";
import { withAllContexts } from "../../HOCs";
import { AlertProps } from "../../utils";

const drawerWidth = "94%";
const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#0071F2",
    color: "#fff",
    padding: "6px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "10px",
    width: "100px",
    "&:hover": {
      backgroundColor: "#0e5ab2",
    },
  },
  numbersEdit: {
    marginTop: "4px",
    fontFamily: "poppinsemibold",
  },
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  bgcolor: {
    backgroundColor: "#F9F9F9",
    paddingTop: "10px",
  },
  root: {
    "& .MuiTableCell-sizeSmall": {
      paddingBottom: "0px",
      paddingTop: "0px",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,

    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
    //marginTop: "63px",
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: "63px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function LocationMaster(props) {
  //const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { parent_id } = props;

  const listData = useSelector(
    (state) => state?.locationSlice?.location_list?.data ?? []
  );

  console.log("listData",listData);

  const loading = useSelector(
    (state) => state?.locationSlice?.location_list?.loading
  );
  const saveloading = useSelector(
    (state) => state?.locationSlice?.location_list_upsert?.loading
  );

  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });
  const [role, setRole] = React.useState(false);
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [location_type, setLocationType] = React.useState(false);
  const [location_id, setLocationId] = React.useState(false);
  const [short_desc, setShortDesc] = React.useState(false);
  const [long_desc, setLongDesc] = React.useState(false);

  const handlepageChange = async (currentPage, PerPage) => {
    debugger;
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.LOCATION_READ({ type : props.val , page: (currentPage - 1) * PerPage, perPage: PerPage }));

  };

  const handleDrawerOpen = () => {
    // setState({
    //   ...state,
    //   open: true,
    // });
    history.push(routes.addlocationMater);
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null,
    });
  };

  const userEffFunction = async () => {
    let type = props.val;
    // await dispatch(actions.LOCATION_READ({ type: type }));
    await dispatch(actions.LOCATION_READ({type: type , page: page, perPage: perPage}));
  };
  React.useLayoutEffect(() => {
    userEffFunction();
  }, []);

  const savePractitionerRole = async (values) => {
    let data = {
      code: values.type_code,
      description: values.type_description,
      type: props.type,
      status: values.status,
    };
    if (state?.edit_data) {
      data["_key"] = state?.edit_data._key;
      data["id"] = state?.edit_data.id;
    }
    await dispatch(actions.PRACTITIONER_ROLE_UPSERT(data));
    await dispatch(actions.PRACTITIONER_ROLE_READ({ type: props.type }));
    await handleDrawerClose();
  };

  const handleEdit = (e, values, index) => {
    ;
    // let data = {
    //   code: values.code_type,
    //   description: values.description,
    //   type: props.type,
    //   status: values.status,
    //   _key: values._key,
    //   id: values.id,
    // };

    history.push({
      pathname: routes.addlocationMater,
      state: {
        Id: values._key,
      },
    });
  };

  const statusChange = async (e, values, index) => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Updating...",
    });
    ;
    let data = {
      isStatusChange: true,
      _key: values._key,
      status: e.target.checked ? "Active" : "In-Active",
    };
    setState({
      ...state,
      edit_data: data,
    });
    let dat = await dispatch(actions.LOCATION_LIST_UPSERT(data));
    ;
    if (dat.payload.data.Code === 201) {
      props.alert.setSnack("update");
    } else {
      props.alert.setSnack("error");
    }
    await dispatch(actions.LOCATION_READ({ type: props.val,page: page, perPage: perPage }));
    setState({
      ...state,
      edit_data: null,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  };
 
  const onSearchChange = (value = "") => {
    if (value?.length > 1) {
      dispatch(actions.LOCATION_READ({
        search: value.trim(),
        page: page,
        perPage: perPage
      }));
    } else if (!value) {
      dispatch(actions.LOCATION_READ({
        page: page,
        perPage: perPage
      }));
    }
  };

  const handleSort = async (val, key, direction) => {
    // alert(val)
    let sort_key = ''
    if (val === 'Location Type'){
      sort_key = location_type
      setLocationType(!location_type)
    } else if(val === 'Location ID'){
      sort_key = location_id
      setLocationId(!location_id)
    } else if(val === `Short Description`){
      sort_key = short_desc
      setShortDesc(!short_desc)
    } else if(val === 'Long Description'){
      sort_key = long_desc
      setLongDesc(!long_desc)
    }
      
    await dispatch(actions.LOCATION_READ({ type: props.val,page: page, perPage: perPage, sort: val, direction : sort_key ? '' : 'DESC' }));
  }

  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }}>
      <div
        id={`${parent_id}-parent-div`}
        style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-Card`}
          style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-button-div`}
              style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                id={`${parent_id}-button-sub-div`}
                style={{ marginLeft: "18px" }}>
                <p
                  id={`${parent_id}-header-title-sub-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div
                id={`${parent_id}-button-div`}
                style={{ marginTop: "16px", float: "right", display: 'flex', alignItems: 'center' }}>
                  <SearchWithFilter
                  id="location"
                  placeholder="Search Type and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                //  onFilterClicked={onFilterClicked}
                //  isFilterApplied={props?.isFilterApplied}
                />
                <button
                  id={`${parent_id}-Add-New-button`}
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                    marginRight:"10px"
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
              </div>
            </div>

            {/* table */}
            <div
              id={`${parent_id}-loading-parent-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}>
                <CommonTable
                  parent_id={'locationmaster'}
                  Header={[
                    { label: "S.No", showSort: false, showFilter: false },
                    { label: "Location Type", showSort: true, sortValue: location_type , onclick: (value) => handleSort(value, `location_type`, location_type ), showFilter: false },
                    { label: "Location ID", showSort: true, sortValue:location_id , onclick: (value) => handleSort(value, `location_id`, location_id ), showFilter: false },
                    { label: "Short Description", showSort: true, sortValue: short_desc , onclick: (value) => handleSort(value, `short_desc`, short_desc ), showFilter: false },
                    { label: "Long Description", showSort: true, sortValue: long_desc, onclick: (value) => handleSort(value, `long_desc`, long_desc ), showFilter: false },
                    { label: "Status", showSort: false, showFilter: false },
                    { label: "Action", showSort: false, showFilter: false },
                  ]}
                  dataList={listData}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "location_type" },
                    { type: ["TEXT"], name: "location_id" },
                    { type: ["TEXT"], name: "short_description" },
                    { type: ["TEXT"], name: "long_description" },
                    { type: ["CHECKBOX"], name: "status" },
                    { type: ["EDIT"], name: "" },
                  ]}
                  handleCheckBox={statusChange}
                  handleEdit={handleEdit}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={listData?.[0]?.totalCount}
                  incrementCount={page}
                  showPagination={true}
                  loading={loading && state.edit_data === null ||
                    state?.locationSlice?.location_list_upsert?.loading }
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}

export default withAllContexts(LocationMaster);
