import React, { useState } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
import ShowList from "../../../../../../components/practitionermaster/addPractotioner/showList";
import { AlertProps } from "../../../../../../utils";
import { RuleSet } from "../../../../../../components"

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    // padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    marginBottom: "20px"
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  }, btn: {
    padding: "8px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    margin: "3px 10px 15px 0px",
    borderRadius: "8px"
  },
  lowerdiv: {
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    padding: "15px",
  },
  addRule: {
    borderColor: "#ff6384",
    color: "#ff6384",
    margin: "15px 0px 0px",
    padding: "8px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "8px"
  },
  addBtn: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "poppin",
    textTransform: "none"
  },
  addApproval: {
    border: "1px solid #DCDCDC",
    padding: "15px",
    borderRadius: "8px",
    marginTop: "20px"
  },
  slectedbtn: {
    backgroundColor: "#0071F2",
    color: "#fff",
    padding: "8px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "8px",
    margin: "3px 10px 15px 0px",
    '&:hover': {
      background: "#0071F2",
    },
  }
}));

function ApprovalLevel(props) {
  const classes = useStyles();
  const { approvalOption, handleApprovalLevel, approvalData, handleApproveDelete, handleApproveEdit, parent_id } = props
  const [state, setState] = useState({
  });
  const [addNew, setAddnew] = useState({
    open: false,
    edit: false,
    editIndex: null
  })

  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  }

  const handleApproval = () => {
    if (!state?.approvalDescription || !state?.approvalLevel?.value) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill requird approvel level details!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        transitionDuration: 5000,
      });
      return
    }

    if (addNew.edit === true) {
      handleApproveEdit(state, addNew.editIndex)
    } else {
      handleApprovalLevel(state)
    }
    setState();
    setAddnew({ ...addNew, open: false, edit: false, editIndex: null });
  }

  const handleEdit = (val, i, e) => {
    setAddnew({ ...addNew, open: true, edit: true, editIndex: i })
    setState(val);
  }

  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-parent-paper`} className={classes.root}>
        <div
          id={`${parent_id}-parent-div`}
          style={{ display: "flex", alignItems: "center", padding: "20px 20px 0px 20px" }}>
          <Typography
            id={`${parent_id}-REQUIRED-APPROVAL-typography`}
            className={classes.title} variant="h6">
            REQUIRED APPROVAL LEVELS AND AUTHORITY
          </Typography>
          <div
            id={`${parent_id}-REQUIRED-APPROVAL-div`}
            style={{ flexGrow: 1 }}></div>
          <Button
            id={`${parent_id}-Add-New-button`}
            className={classes.addBtn}
            onClick={() => setAddnew({ ...addNew, open: true })}
            color="primary"
          >
            + Add New
          </Button>
        </div>

        {approvalData?.map((val, i) => {
          return (
            <div
              id={`${parent_id}-${i}-ShowList-div`}
              style={{ padding: "0px 20px 20px 20px" }}>
              <ShowList
                parent_id={"ShowList" + i}
                index={i + 1}
                title={val?.approvalLevel.value ?? ""}
                description={
                  (val?.approvalDescription.length > 0 ? val?.approvalDescription : "")
                }
                ondelete={() => handleApproveDelete(val, i)}
                onedit={(e) => handleEdit(val, i, e)}
              />
            </div>
          );
        })}

        {addNew.open ?
          <div
            id={`${parent_id}-ADD-NEW-div`}
            style={{ borderTop: "1px solid #dfdfdf", padding: "20px" }}>
            <Typography
              id={`${parent_id}-ADD-NEW-label-typography`}
              className={classes.title} variant="h6">
              ADD NEW
            </Typography>
            <div
              id={`${parent_id}-DETAILS-label-div`}
              className={classes.addApproval}>
              <Typography
                id={`${parent_id}-DETAILS-label-typography`}
                className={classes.title} variant="h6">
                DETAILS
              </Typography>
              <Grid
                id={`${parent_id}-DETAILS-parent-grid`}
                container className={classes.form}>
                <Grid
                  id={`${parent_id}-Approval-parent-grid`}
                  md="3" lg="3" className={classes.formParent}>
                  <Typography
                    id={`${parent_id}-Approval-label-typography`}
                    className={classes.formtitle} variant="body1">
                    Approval Level <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <SelectBox
                    parent_id={"Approval"}
                    list={approvalOption ?? []}
                    placeholder={"Select"}
                    value={state?.approvalLevel}
                    onchange={(e, value) => handleChange("approvalLevel", value)}
                  />
                </Grid>
                <Grid
                  id={`${parent_id}-Approval-Description-grid`}
                  md="9" lg="9" className={classes.formParent} style={{ paddingRight: "0px" }}>
                  <Typography
                    id={`${parent_id}-Approval-Description-typography`}
                    className={classes.formtitle} variant="body1">
                    Approval Description <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id={`${parent_id}-Approval-Description-textField`}
                    value={state?.approvalDescription}
                    onChange={(e) => handleChange("approvalDescription", e.target.value)}
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    style={{ marginTop: "3px" }}
                  />
                </Grid>
              </Grid>
              <Grid
                id={`${parent_id}-Approval-AUTHORITY-label-grid`}
                className={classes.lowerdiv}>
                <Typography
                  id={`${parent_id}-Approval-AUTHORITY-label-typography`}
                  className={classes.title} variant="h6">
                  APPROVAL AUTHORITY CRITERIA <span style={{ color: "red" }}>*</span>
                </Typography>
                {/* <Button
                  id={`${parent_id}-Approval-New-Rule-Set-button`}
                  variant="outlined"
                  className={classes.addRule}
                >
                  + Add New Rule Set
                </Button> */}
                <RuleSet
                  parent_id={`${parent_id}-Approval-Rule-Set-button`}
                  stateData={state?.approvalRule ?? []} setState={(data) => handleChange("approvalRule", data)}
                />
              </Grid>
              <div
                id={`${parent_id}-buttons-div`}
                style={{ justifyContent: "center", marginTop: "15px", display: "flex" }}>
                <Button
                  id={`${parent_id}-Add-button`}
                  variant="outlined"
                  className={classes.slectedbtn}
                  onClick={handleApproval}
                >
                  Add
                </Button>
                <Button
                  id={`${parent_id}-Cancel-button`}
                  variant="outlined"
                  className={classes.slectedbtn}
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  onClick={() => setAddnew({ ...addNew, open: false, edit: false, editIndex: null })}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          : null}
      </Paper>
    </React.Fragment>
  );
}

export default ApprovalLevel;
