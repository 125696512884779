
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';


const useStyles = makeStyles((theme) => ({
root: {
	flexGrow: 1,
},
navButton: {
	marginRight: theme.spacing(2),
},
}));


export default function Navbar() {
const classes = useStyles();

return (
	<div className={classes.root}>
	<AppBar position="static" color='default'>
		<Toolbar variant="dense">
		<IconButton edge="start"
			className={classes.navButton}
			color="primary" aria-label="Black">
			<NavigateBeforeIcon />
		</IconButton>
		{/* <Typography variant="h6" color="black">
			Create Appointment Types
		</Typography> */}
		<Typography textColor="black" fontSize="sm" fontWeight="lg"> 
			Create Appointment Types 
			</Typography>
		</Toolbar>
	</AppBar>
	</div>
);
}
