import React from "react";
import { Div } from "qdm-component-library";
import TopNavbar from "../navbar/topNavBar";
import { withAllContexts } from "../../../HOCs";
import { withHoverDrawer } from "../../../HOCs";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import { AlertProps } from "../../../utils";
import { Button, CircularProgress } from "@material-ui/core";
import { routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
import SpecialityDetail from "./specialityDetail";
import ApplicableFacilityDetail from "./applicableFacilitiesDetail";
import { CommonTabList } from "../../../components";
class AddSpeciality extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: 1,
      openForm: false,
      editData: null,
      specialityCode: "",
      specialityDescription: "",
      parentSpeciality: {},
      lastLevel: true,
      facilityMasterData: [],
      parentSpecialityMasterData: [],
      applicableFacilities: [],
      status: true
    };
    this.saveForm = this.saveForm.bind(this);
  }
  async componentDidMount() {
    let urlParams = this.props?.location?.state?._key ?? null;
    this.state._key_id = urlParams;
    this.setState({ ...this.state });
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Loading...",
    });
    this.getallmasters();
    if(urlParams){
      if (urlParams) {
        this.getSpecialityById(urlParams);
      }
    }
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }

  getSpecialityById = async (id) => {
    let d = await this.props.EDIT_SPECIALITY({ id: id });

    this.setState({
      ...this.state,
      ...this.props.specialityEdit.data
    });
    
  };

  onInputChange = (n, v) => {
    const state = this.state;
    state[n] = v;
    this.setState({ ...state });
  };

  handleFormState = (name, value) => {
    this.setState({ [name]: value })
  }

  onChangeState = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  handleForm = () => {
    let { openForm, editData } = this.state;
    this.setState({
        openForm: !openForm,
        editData: openForm ? null : editData
    })
}

  onSelectChange = (n, v) => {
    const state = this.state;
    state[n] = v;
    this.setState({ ...this.state, ...state });
  };

  getallmasters = async () => {
    const data_facility = await this.props.SPECIALITY_MASTER_FACILITY();
    const data_parent = await this.props.SPECIALITY_MASTER_PARENT_SPECIALITY();
    this.setState({
      ...this.state,
      facilityMasterData: data_facility?.payload?.data,
      parentSpecialityMasterData: data_parent?.payload?.data,
    });
    
  };

  saveForm = async () => {
    
    if (
      !this.state.specialityCode ||
      !this.state.specialityDescription
    ) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        transitionDuration: 5000,
      });
    } else {
        let payload = await this.props.SPECIALTY_INSERT({ dataList: this.state })
        
        if (payload?.payload?.data?.validation_error?.[0]?.Errormsg?.includes("unique")) {
            this.props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Specialty code already exits",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
            })
            return false
        } else if (payload?.error === true) {
          this.props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something went wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
          })
          return false
        } else if(payload?.payload?.data?.Code === 201) {
          if(this.state._key_id) {
            this.props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg: "Specialty updated successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            })
          } else {
            this.props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg: "Specialty added successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            })
          }
          this.backHandle();
        } else {
          this.props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something went wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
          })
          return false
        } 
    }
  }
  setActive = (id) => {
    this.setState({
      activeId: id,
    });
  };
  styles = {
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    loadscreen: {
      display: "flex",
      justifyContent: "center",
      margin: "16px",
      borderRadius: "6px",
      background: "#fff",
      alignItems: "center",
      minHeight: "76vh",
    },
  };

  backHandle = () => {
    this.props.history.push({pathname: routes.organizationConfig, state:{index: 2}});
  };

  render() {
    return (
        <React.Fragment>
          <CommonTabList
              parent_id={"agerange-form"}
              backbtn
              backFun={this.backHandle}
              title= {this.state._key_id ? "Edit Specialty" : "Create Specialty"}
              list={[]}
          />

        {/* {this.props.specialityEdit.loading ? (
          <div style={this.styles.loadscreen}>
            <CircularProgress />
          </div>
        ) : ( */}
          <Div
            style={{
              padding: "5px 15px",
              color: "#101010",
              overflow: "hidden",
            }}
          >

            {/* HealthCareServiceDetail */}
            <SpecialityDetail
              allMasters={this?.state?.allMasters}
              onSelectChange={this.onSelectChange}
              onInputChange={this.onInputChange}
              onChangeState={this.onChangeState}
              {...this.state}
            />

            {/* ApplicableFacilities */}
            <ApplicableFacilityDetail
            handleFormState={this.handleFormState} 
            closeForm={this.handleForm}
            {...this.state} 
            />

            <Div className="p-continue-action">
              <Button
                variant="text"
                className={"topNav-actions cancelBtn"}
                onClick={() => {
                  this.backHandle()
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                color={"primary"}
                disabled={true}
                disabled={this.props?.specialityInsert?.loading ?? false}
                onClick={() => this.saveForm()}
                style={{ marginLeft: 10 }}
              >
                {this.state._key_id ? (
                  <>
                    {this.props?.specialityInsert?.loading
                      ? "Updating..."
                      : "Update"}
                  </>
                ) : (
                  <>
                    {this.props?.specialityInsert?.loading
                      ? "Saving..."
                      : "Save"}
                  </>
                )}
              </Button>
            </Div>
          </Div>
       {/* // )} */}
      {/* </Div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  specialityInsert: state?.organizationConfigSlice?.speciality_insert,
  specialityEdit: state?.organizationConfigSlice?.speciality_edit,
});
export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(withHoverDrawer(AddSpeciality))));
