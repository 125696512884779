import React from "react";
import {
  Div,
  Paper,
  H6,
  Text,
  Image
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import { AlertProps } from "../../../utils";
import PatientAddAddress from "./addAddress";
import ShowList from "./showList";

class PatientAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Showfilter: this.props?.[this.props.saveto]?.names,
      limited: false,
      Collapse: false,
      addCollapseForm: false,
      contact: [{}]
    };
  }

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
  };

  validateCheck = (data) => {
    if (data?.addressLine1?.length > 0 && data?.use?.label?.length > 0 && data?.type?.label?.length > 0 && data?.city?.label?.length > 0 && data?.country?.label?.length > 0 && data?.district?.label?.length > 0 && data?.pincode?.label?.length > 0 && data?.state?.label?.length > 0) {
      return false
    } else {
      let errors = {
        addressLine1: data?.addressLine1?.length > 0 ? false : true,
        use: data?.use?.label?.length > 0 ? false : true,
        type: data?.type?.label?.length > 0 ? false : true,
        city: data?.city?.label?.length > 0 ? false : true,
        country: data?.country?.label?.length > 0 ? false : true,
        district: data?.district?.label?.length > 0 ? false : true,
        pincode: data?.pincode?.label?.length > 0 ? false : true,
        state: data?.state?.label?.length > 0 ? false : true
      }
      this.setState({ ...this.state, erorr: errors });
      return true
    }
  }

  saveAddress = (data) => {
    // states
    // addressLine1: "1"
    // addressLine2: "2"
    // addressLine3: ""
    // city: null
    // country: null
    // district: null
    // pincode: "122"
    // state: null
    // type: {value: "Postal", label: "Postal"}
    // use: {value: "Home", label: "Home"}
    if (this.validateCheck(data)) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill address mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      })
      return false;
    }

    if (this.props?.setAddress) {
      let setdata = this.props?.addressList;
      if (this.state?.editindex !== null) {
        setdata[this.state?.editindex] = data;
      } else {
        setdata.push(data);
      }
      this.props?.setAddress(setdata);
      this.Clear();
      return false
    }

    let patientData = this.props[this.props.saveto];
    if (this.state?.editindex !== null) {
      patientData.address[this.state?.editindex] = data;
    } else {
      patientData.address.push(data);
    }
    patientData[this.props.setTo]({
      ...patientData,
    });


    this.Clear();
  };

  Clear = () => {
    this.setState({
      select_role: null,
      speciality: null,
      select_entity: null,
      entity_name: null,
      select_location: null,
      start_date: null,
      end_date: null,
      addCollapseForm: false,
      contact: [{}]
    });
  };

  OpenAddress = async (e) => {
    this.setState({
      addAddress: e?.currentTarget ?? null,
    });
  };
  handleClear = () => {
    this.setState({
      editadd: {},
      editindex: null,
      addCollapseForm: false
    });
  };

  Collapse(e) {
    this.setState({
      Collapse: !this.state.Collapse,
    });
  }

  addCollapse(e) {
    this.setState({
      addCollapseForm: !this.state.addCollapseForm,
      Collapse: true,
    });
  }

  onedit = async (v, i, e) => {
    this.setState({
      ...this.state,
      ...v,
      editadd: { ...v },
      editindex: i
    });
    this.addCollapse(e);
  };

  ondelete = (v, i) => {
    if (this.props?.setAddress) {
      let setdata = this.props?.addressList;
      setdata.splice(i, 1);
      this.props?.setAddress(setdata);
      this.Clear();
    } else {
      let patientData = this.props[this.props.saveto];
      patientData.address.splice(i, 1);
      patientData[this.props.setTo]({
        ...patientData,
      });
      this.Clear();
    }
  };

  render() {
    let { addCollapseForm, Collapse } = this.state;
    const { parent_id } = this.props;
    // const { address } = this.props[this.props.saveto];
    let address = [];
    if (this.props?.addressList) {
      address = this.props?.addressList;
    } else {
      address = this.props[this.props.saveto].address;
    }

    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-Address-div`}
            style={{ display: "flex", alignItems: "center" }}>
            <H6
              id={`${parent_id}-Address-title-h6`}
              className="pi-title"
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                flex: 1,
              }}
            >
              Address
              {address?.length > 0 && (<>
                {" (" + JSON.stringify(address?.length) + ")"}
                <Image
                  id={`${parent_id}-Address-image`}
                  style={{ width: 16, height: 16, marginLeft: 5 }}
                  src="images/icons8-ok.svg"
                  alt="tick"
                /></>
              )}
              {/* )} */}
            </H6>
            <Text
              id={`${parent_id}-Add-New-text`}
              inLineStyles={{
                color: "#0071F2",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
              }}
              className="pi-addbtn"
            >{addCollapseForm ?
              <Text
                id={`${parent_id}-addCollapseForm-Add-New-text`}
                className="pi-addbtn"
                style={{ marginRight: 10, color: "gray" }}
              >
                + Add New
              </Text>
              :
              <Text
                id={`${parent_id}-addCollapse-Add-New-text`}
                className="pi-addbtn"
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  this.handleClear();
                  this.addCollapse(e);
                }}
              >
                + Add New
              </Text>}
              {address?.length > 0 && (
                <React.Fragment>
                  {Collapse ? (
                    //  <i onClick={(e) => this.addCollapse(e)} className="fa fa-angle-down ad-arrow" aria-hidden="true" /> : <i className="fa fa-angle-up ad-arrow" aria-hidden="true" onClick={(e) => this.addCollapse(e)} />}
                    <Image
                      id={`${parent_id}-downArrow-image`}
                      src="images/Group 3873.svg"
                      style={{ width: 20, height: 20 }}
                      alt="downArrow"
                      onClick={(e) => this.Collapse(e)}
                    />
                  ) : (
                    <>
                      <Image
                        id={`${parent_id}-upArrow-image`}
                        style={{ width: 20, height: 20 }}
                        src="images/Group -1.svg"
                        alt="upArrow"
                        onClick={(e) => this.Collapse(e)}
                      />
                    </>
                  )}
                </React.Fragment>
              )}
            </Text>
          </Div>
          {(Collapse && address?.length > 0) && (
            <Div
              id={`${parent_id}-ShowList-div`}
              style={{ paddingTop: "10px" }} >
              {address?.map((val, i) => {
                return (
                  <div
                    id={`${parent_id}-${i}-ShowList-div`}
                    style={{ marginBottom: 12 }}>
                    <ShowList
                      parent_id={'address' + i}
                      index={i + 1}
                      title={val?.type?.label ?? ""}
                      description={
                        (val?.use?.label ?? "") +
                        (val.addressLine1?.length > 0 ? ", " + val.addressLine1 : "") +
                        (val.addressLine2?.length > 0 ? ", " + val.addressLine2 : "") +
                        (val.city?.label ? ", " + val.city?.label : "") +
                        (val.district?.label ? ", " + val.district?.label : "") +
                        (val.state?.label ? ", " + val.state?.label : "") +
                        (val.country?.label ? ", " + val.country?.label : "") +
                        (val.pincode?.label ? ", " + val.pincode?.label: "")
                      }
                      ondelete={() => this.ondelete(val, i)}
                      onedit={(e) => this.onedit(val, i, e)}
                      onswitch={false}
                    />
                  </div>
                );
              })}
            </Div>
          )}

          {addCollapseForm && (
            <Div
              id={`${parent_id}-addCollapseForm-div`}
            >
              <Paper
                id={`${parent_id}-addCollapseForm-paper`}
                style={{ margin: 0, marginTop: 10 }}>
                <PatientAddAddress
                  parent_id={'PatientAddAddress'}
                  saveAddress={this.saveAddress}
                  allMasters={this.props?.allMasters}
                  countryDropdownData={this.props?.countryDropdownData}
                  handleClear={this.handleClear}
                  index={this.state.editindex}
                  editData={this.state.editadd}
                  setTo={this.props.setTo}
                  saveto={this.props.saveto}
                  onclose={() => this.OpenAddress(null)}
                />
              </Paper>
            </Div>
          )}
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(PatientAddress);
