import React from "react";
import { withHoverDrawer } from "../../HOCs";
import PractitionerTabPanel from "./practitionertab";
class PractitionerMaster extends React.Component {
  render() {
    return (
      <div
        id={'Practitioner-div'}
      >
        <PractitionerTabPanel
          id={'Practitioner'}
        />
      </div>
    );
  }
}

export default withHoverDrawer(PractitionerMaster);
