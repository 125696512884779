import React from "react";
import { Paper, Typography, makeStyles, Grid, Button, Divider } from "@material-ui/core";
import InnerCard from "../innercard";
import TTADForm from "./adForm";
const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        margin: 15
    },
    addBtn: {
        float: "right",
        fontSize: "14px",
        fontWeight: 600,
        cursor: "pointer"
    },
    title: {
        fontFamily: "poppinsemibold",
        fontSize: "12px",
        display: "inline",
        minWidth: "300px"
    },
    form: {
        paddingTop: 15
    },
    formtitle: {
        fontSize: "12px",
        paddingBottom: 10
    },
    formParent: {
        paddingRight: 10
    },
    titleData: {
        display: "grid",
        gridTemplateColumns: "155px 1fr 84px"
    },
    divider: {
        marginTop: "9px",
        borderBottom: "2px dotted black",
        background: "none",
        marginLeft: "10px"
    },
    empty: {
        textAlign: "center",
        width: "100%",
        color: "#c3c3c3"
    }
}));

function TTAppoinmentDuration(props) {
    const [state, setState] = React.useState({ open: null, edit_data: false, editIndex: null })
    const [list, setList] = React.useState([])
    const classes = useStyles();

    const { stateData = [], setStateData = () => null, parent_id } = props;
    // const updateState = async (name, value) => {
    //     setStateData({ ...stateData, [name]: value })
    // }
    React.useEffect(() => {
        setList(stateData)
    }, [stateData])
    const successFun = async (data, index) => {
        
        if (state.editIndex || state.editIndex === 0) {
            list[state.editIndex] = data;
        } else {
            list.push(data);
        }
        setList(list)
        setStateData(list)
        setState({ open: null, edit_data: null, editIndex: null })
    }

    const onDelete = async (index) => {
        list.splice(index, 1)
        setList(list)
        setStateData(list)
        setState({ open: null })
    }

    const onEdit = async (data, index) => {
        setState({ open: true, edit_data: data, editIndex: index })
    }

    return (
        <React.Fragment>
            <Paper
                id={`${parent_id}-parent-paper`}
                className={classes.root}>
                <Grid
                    id={`${parent_id}-parent-title-grid`}
                    className={classes.titleData}>
                    <Typography
                        id={`${parent_id}-title-typography`}
                        className={classes.title} variant="h6">APPOINTMENT DURATION <span style={{ color: "red" }}>*</span></Typography>
                    <Divider
                        id={`${parent_id}-title-divider`}
                        className={classes.divider} />
                    <Grid
                        id={`${parent_id}-title-divider`}
                        style={{ textAlign: "right" }}>
                        <Typography
                            id={`${parent_id}-Add-New-typography`}
                            onClick={e => setState({ open: e.currentTarget })} className={classes.addBtn} color="primary" variant="h6">+ Add New</Typography>
                    </Grid>
                </Grid>
                <Grid
                    id={`${parent_id}-InnerCard-grid`}
                    container className={classes.form}>
                    {stateData.length > 0 ? <InnerCard
                        id={`${parent_id}-No-Duration-innercard`}
                        list={stateData} onEdit={onEdit} onDelete={onDelete} />
                        : <Typography
                            id={`${parent_id}-No-Duration-typography`}
                            className={classes.empty}>No Duration Added</Typography>}
                </Grid>
            </Paper>
            {state.open && <TTADForm
                parent_id={'TTADForm'}
                editData={state.edit_data} open={state.open} onsuccess={successFun} closefun={() => setState({ open: null })} />}
        </React.Fragment>
    )
}

export default TTAppoinmentDuration;