import React from "react";
import { withAllContexts } from "../../../../HOCs";
import { DrugListingComponent } from "clmm-masters";
import { useLocation, withRouter } from "react-router";
import { routes } from "../../../../router/routes";

function Drug(props) {
  const location = useLocation();

  return (
    <DrugListingComponent
      onAddClick={() => {
        props.history.push(routes.addNewDrug);
      }}
      onEditClick={(data) => {
        debugger;
        props.history.push({
          pathname: routes.editNewDrug,
          state: { ...data },
        });
      }}
      onViewClick={(data) => {
        props.history.push({
          pathname: routes.editNewDrug,
          state: { ...data },
        });
      }}
    />
  );
}

export default withAllContexts(withRouter(Drug));
