import React from "react";
import { Row, Col } from "qdm-component-library";
import {
	FormLabel,
	makeStyles,
	Grid,
	IconButton,
	Button,
	TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";

const useStyles = makeStyles(() => ({
	main: {
		backgroundColor: "white",
		width: "90vw",
		borderRadius: 8,
		padding: 14,
	},
	paddingLeft0: {
		paddingLeft: 0,
	},
	req_red: {
		color: "red",
	},
	labelStyleNew: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
		marginBottom: 10
	},
}));
const styles = {
	labelStyle: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
	},
	borderStyle: {
		borderRadius: 8,
		borderColor: "#E0E0E0",
	},
	errorBorderStyle: {
		borderRadius: 8,
		border: "1px solid red",
	},
};
const Contact = (props) => {
	const classes = useStyles();
	//const details = {};
	const { labelStyle } = styles;
	const { parent_id } = props;

	const dispatch = useDispatch();
	const organizationSlice = useSelector((state) => state.organizationSlice);

	React.useEffect(() => {
		const getdata = async () => {
			await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILMODE());
			await dispatch(actions.ORGANIZATION_GET_CONNTACTDETAILCODE());
			await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILUSE());
			await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILPRIORITY());
		};
		getdata();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// const [state, setState] = React.useState({
	// 	code: {},
	// 	mode: {},
	// 	number: "",
	// 	use: {},
	// 	priority: {},
	// });
	const handleClick = () => {
		
		props.Contactdata.push({
			code: {},
			mode: {},
			number: "",
			use: {},
			priority: {},
		});
		props.onChangeAll();
	};
	return (
		<Grid
			id={`${parent_id}-parent-row`}
			className={classes.main} container direction="row">
			<Grid
				id={`${parent_id}-parent-sub-grid`}
				container item xs={12}>
				<Grid
					id={`${parent_id}-title-sub-grid`}
					item xs={8} style={{ textAlign: "left" }}>
					<p
						id={`${parent_id}-title-p`}
						style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
						CONTACT DETAILS{" "}
					</p>
				</Grid>
				<Grid
					id={`${parent_id}-Add-New-parent-grid`}
					item xs={4}>
					<Grid
						id={`${parent_id}-Add-New-grid`}
						container
						direction="row"
						justify="flex-end"
						alignItems="center"
					>
						<Button
							id={`${parent_id}-Add-New-button`}
							className={classes.newRes}
							color="primary"
							onClick={handleClick}
							//id={"general_appointment_card_Id"}
						>
							+ Add New
						</Button>
					</Grid>
				</Grid>
				{props?.Contactdata?.length > 0
					? props?.Contactdata?.map((val, i) => {
						
						return (
							<Grid
								id={`${parent_id}-parent-container` + i}
								container direction="column">
								<Row
									id={`${parent_id}-parent-row` + i}
									inLineStyles={{ paddingBottom: "2%" }}>
									<Col
										id={`${parent_id}-Contact-Mode-col` + i}
										md={3}
										lg={3}
										sm={3}
										xs={12}
										className={classes.paddingLeft0}
									>
										<FormLabel
											id={`${parent_id}-Contact-Mode-title-FormLabel` + i}
											component="legend"
											// style={{ ...labelStyle, marginBottom: 10 }}
											classes={{root:classes.labelStyleNew}}
										>
											{/* <FormLabel
												component="legend"
												style={{ ...labelStyle, marginBottom: 10 }}
											> */}
												Contact Mode 
												<span className={classes.req_red}>*</span>
											</FormLabel>
											<Autocomplete
												id={`${parent_id}-Contact-Mode-autocomplete` + i}
												getOptionLabel={(option) => option.title}
												style={{ width: "auto", marginTop: "1%" }}
												size={"small"}
												renderInput={(params) => (
													<TextField
													    id={`${parent_id}-Contact-Mode-autocomplete` + i}
														{...params}
														variant="outlined"
														placeholder={"Contact Mode"}
														error={val?.["error"]?.mode??false}
													/>
												)}
												options={
													organizationSlice?.orgContactDetailMode?.data
														? organizationSlice?.orgContactDetailMode?.data
														: []
												}
												onChange={(e, val) =>
													props.onChangeAll(e, val, "Contact", i, "mode")
												}
												value={val?.mode}
											/>
										</Col>
										
									{val?.mode?.title === "Phone" ? (
										<Col
											id={`${parent_id}-Country-code-col` + i}
											md={2}
											lg={2}
											sm={2}
											xs={12}
											className={classes.paddingLeft0}
										>
											<FormLabel
												id={`${parent_id}-Country-code-title-FormLabel` + i}
												component="legend"
												// style={{ ...labelStyle, marginBottom: 10 }}
												classes={{root:classes.labelStyleNew}}
											>
												Country code{" "}
												<span className={classes.req_red}>*</span>
											</FormLabel>
											<Autocomplete
												id={`${parent_id}-Country-code-autocomplete` + i}
												getOptionLabel={(option) => option.title}
												style={{ width: "auto", marginTop: "1%" }}
												size={"small"}
												renderInput={(params) => (
													<TextField
														id={`${parent_id}-Country-code-textField` + i}
														{...params}
														variant="outlined"
														placeholder={"code"}
														error={val?.["error"]?.code??false}
													/>
												)}
												options={
													organizationSlice?.orgContactDetailCode?.data
														? organizationSlice?.orgContactDetailCode?.data
														: []
												}
												onChange={(e, val) =>
													props.onChangeAll(e, val, "Contact", i, "code")
												}
												value={val?.code}
											/>
										</Col>
									) : null}

									<Col
										id={`${parent_id}-title-col` + i}
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										<FormLabel
											id={`${parent_id}-title-FormLabel` + i}
											component="legend"
											classes={{root:classes.labelStyleNew}}
										>
											{val?.mode?.title ? val?.mode?.title : "Given"}{" "}
											<span className={classes.req_red}>*</span>
										</FormLabel>
										<TextField
											id={`${parent_id}-title-textField` + i}
											onChange={(e, val) =>
												props.onChangeAll(
													e,
													e.target.value,
													"Contact",
													i,
													"number"
												)
											}
											size={"small"}
											type={
												val?.mode?.title === "Phone"
													? "text"
													: val?.mode?.title === "Email"
														? "email"
														: "text"
											}
											variant="outlined"
											placeholder={val?.mode?.title}
											// onKeyDown={(evt) =>
											// evt.key === "e" || evt.key === "." && evt.preventDefault()
											// }
											InputProps={val?.mode?.title === "Phone" &&{
													inputProps: {
													min: 0,
													maxlength: 10,
													},
                                                }}
											value={val?.number}
											error={val?.["error"]?.number??false}
											onInput={(e) =>
												val?.mode?.title === "Phone"
													?  ( e.target.value = e.target.value.replace(/[^0-9]/g, ""))
													: null
											}
										/>
									</Col>
									<Col
										id={`${parent_id}-Use-col` + i}
										//className="no-padding-left"
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										<FormLabel
											id={`${parent_id}-Use-title-FormLabel` + i}
											component="legend"
											classes={{root:classes.labelStyleNew}}
										>
											Use
											<span className={classes.req_red}>*</span>
										</FormLabel>
										<Autocomplete
											id={`${parent_id}-Use-autocomplete` + i}
											getOptionLabel={(option) => option.title}
											style={{ width: "auto", marginTop: "1%" }}
											size={"small"}
											renderInput={(params) => (
												<TextField
													id={`${parent_id}-Use-textField` + i}
													{...params}
													variant="outlined"
													placeholder={"Use"}
													error={val?.["error"]?.use??false}
												/>
											)}
											options={
												organizationSlice?.orgContactDetailUse?.data
													? organizationSlice?.orgContactDetailUse?.data
													: []
											}
											onChange={(e, val) =>
												props.onChangeAll(e, val, "Contact", i, "use")
											}
											value={val?.use}
										/>
									</Col>
									<Col
										id={`${parent_id}-Priority-Col` + i}
										//className="no-padding-left"
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										<FormLabel
											id={`${parent_id}-Priority-title-FormLabel` + i}
											component="legend"
											classes={{root:classes.labelStyleNew}}
										>
											Priority
											<span className={classes.req_red}>*</span>
										</FormLabel>
										<Autocomplete
											id={`${parent_id}-Priority-autocomplete` + i}
											getOptionLabel={(option) => option.title}
											style={{ width: "auto", marginTop: "1%" }}
											size={"small"}
											renderInput={(params) => (
												<TextField
													id={`${parent_id}-Priority-textField` + i}
													{...params}
													variant="outlined"
													placeholder={"Priority"}
													error={val?.["error"]?.priority??false}
												/>
											)}
											options={
												organizationSlice?.orgContactDetailPriority?.data
													? organizationSlice?.orgContactDetailPriority?.data
													: []
											}
											onChange={(e, val) =>
												props.onChangeAll(e, val, "Contact", i, "priority")
											}
											value={val?.priority}
										/>
									</Col>
									<IconButton
										id={`${parent_id}-Priority-IconButton` + i}
										aria-label="DeleteIcon"
										className={classes.margin}
										size="small"
										style={{ marginTop: "1%" }}
										onClick={() => props.deleteFun("Contact", i)}
									>
										<DeleteIcon
											id={`${parent_id}-Priority-DeleteIcon` + i}
											style={{
												color: "#FF4D4A",
												fontSize: 18,
												marginTop: "2%",
											}}
										/>
									</IconButton>
								</Row>
							</Grid>
						);
					})
					: null}
			</Grid>
		</Grid>
	);
};
export default Contact;
