import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    margin: 15,
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 10,
  },
  formParent: {
    paddingRight: 10,
  },
  stitleData: {
    display: "grid",
    gridTemplateColumns: "195px 1fr",
  },
  divider: {
    marginTop: "9px",
    borderBottom: "1px dashed #e9e9e9",
    background: "none",
  },
  input: {
    borderRadius: "8px",
  },
  cancelButton: {
    marginLeft: 15,
    textTransform: "none",
    borderRadius: "8px",
  },
  confirmButton: {
    marginLeft: 15,
    backgroundColor: "#0071F2",
    textTransform: "none",
    borderRadius: "8px",
  },
  deleteHeader: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.2,
    marginBottom: "7px",
  },
  deleteButton: {
    padding: "6px",
    backgroundColor: "white",
    color: "black",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
    boxShadow: "none",
    border: "1px solid #C3C3C3"
  },
  deleteButton2: {
    padding: "6px",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
    backgroundColor: "#0071F2",
    color: "white"
  },
  dialogContent: {
    textAlign: "center",
    padding: "24px",
  },
  deleteAllergyIcon: {
    padding: "6px 10px",
    borderRadius: "50px",
    cursor: "pointer",
    color: "red",
    marginRight: 10,
  },
}));
