import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
//import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
//import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import Person from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import "../font.css";
// import include from "../../assets/RuleBuilder/Include.svg";
// import exclude from "../../assets/RuleBuilder/Exclude.svg";
import And from "../../assets/RuleBuilder/And.svg";
import Or from "../../assets/RuleBuilder/Or.svg";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import expandarrow from "../../assets/icons8-expand-arrow.svg";
import deleteicon from "../../assets/icons8-trash.svg";
import {
  Div,
  // H6,
  // TextInput,
  // Divider,
  Select,
  // Row,
  // Col,
  // Image,
} from "qdm-component-library";

const useStyles = makeStyles((theme) => ({
  auto: {
    width: 350,
    "& > * + *": {
      //marginTop: theme.spacing(3),
    },
    "& .MuiChip-root": {
      height: "17px",
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
      borderRadius: "4px",
    },
    "& .MuiChip-deleteIcon": {
      height: "15px",
    },
  },
  formControl: {
    "&.MuiSelect-outlined": {
      "& .MuiSelect-outlined": {
        fontSize: "12px",
        fontFamily: "poppin",
      },
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    "& .MuiPopover-paper": {
      left: "20px",
      // top: "275px !important",
      border: "2px solid #0071F2",
      padding: "0px !important",
    },
  },

  popover1: {
    "& .MuiPopover-paper": {
      left: "20px",
      // top: "275px !important",
      border: "2px solid #FF6384",
      padding: "0px !important",
    },
  },
  edit: {
    "& .makeStyles-typography-592": {
      padding: "5px !important",
    },
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
    fontFamily: "poppin",
    marginTop: "13px",
    marginRight: "15px",
  },
  person1: {
    marginRight: "5px",
  },
  person2: {
    marginRight: "5px",
  },
  btn: {
    border: "1px solid #FF6384",
    color: "#FF6384",
    paddingLeft: "10px",
    paddingRight: "10px",
    padding: "5px",
    width: "181px",
    height: "40px",
    borderRadius: "10px",
    marginTop: "30px",
    marginLeft: "10px",
    backgroundColor: "transparent",
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#fff",
    border: "none",
    height: "30px",
  },
  heigthinput: {
    border: "blue",

    "& input": {
      height: "30px !important",
      color: "#101010",
    },
  },
  entity: {
    width: "10% ",
    marginTop: "10px",
    color: "#6F6F6F",
    marginLeft: "8px",
    fontSize: "10px",
    fontFamily: "poppin",
  },
  entity1: {
    width: "10% ",
    marginTop: "10px",
    color: "#6F6F6F",
    marginLeft: "8px",
    fontSize: "10px",
    fontFamily: "poppin",
  },
  addbtn: {
    border: "none",
    width: "120px",
    height: "20px",
    color: "#36A2EB",
    marginLeft: "10px",
    marginTop: "20px",
    backgroundColor: "transparent",
    fontFamily: "poppin",
  },
  andorbtn: {
    border: "1px solid #36A2EB",
    color: "#36A2EB",
    width: "68px",
    height: "32px",
    borderRadius: "7px",
  },
  del: {
    marginTop: "30px",
    marginLeft: "15px",
  },
  ruleset: {
    fontSize: "12px",
    fontFamily: "poppinsemibold",
    color: "#6F6F6F",
  },
  popbtn: {
    border: "1px solid lightgray",

    height: "32px",
    width: "70px",
  },
}));
const Json = [
  {
    button: "",
    header: "RuleSet",
    card: [
      {
        select_entity: {
          name: "select Entity",
          value: "",
        },
        select_attribute: {
          name: "select Attribute",
          value: "",
        },
        conditons: {
          name: "conditions",
          value: "",
        },
        Select_value: {
          name: "select value",
          value: [],
        },
        button: {
          value: "",
        },
      },
    ],
  },
];
const top100Films = [
  { label: "Consultation", value: "consultation" },
  { label: "Surgery", value: "surgery" },
];
const optiondata = [{ label: "Facility", value: "Facilityasas" }];

export default function RuleBuilder(props) {
  const classes = useStyles();
 // const element = <RuleBuilder val="select values" />;

  //const [field, setfield] = React.useState("");
  // const [availability, setavailability] = React.useState("");

  // const [name, setName] = React.useState();
  const [parentindex, setParentindex] = React.useState();
  const [childindex, setChildindex] = React.useState();
  // const [value2, setvalue2] = React.useState("");
  // const [dropdown, setdropdown] = React.useState("");
  // const [value, setValue] = React.useState([]);

  const handleChangefield = (v, name, p, i) => {
    ;
    data[p].card[i][name].value = v;
    setdata([...data]);
  };
  //   const handlechangefield2 = (event) => {
  //     setfield2(event.target.value);
  //   };
  //   const handlechangevalue = (event) => {
  //     setvalue(event.target.value);
  //   };
  //   const handlechangevalue2 = (event) => {
  //     setvalue2(event.target.value);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, p, i) => {
    setAnchorEl(event.currentTarget);

    setParentindex(p);
    setChildindex(i);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //const [show, setshow] = useState("and");
  const [data, setdata] = useState(Json);

  const changebtn = (v) => {
    data[parentindex].card[childindex].button.value = v;
    setdata([...data]);
    setAnchorEl(null);
  };

  const handleClick4 = (event, p) => {
    setAnchorE4(event.currentTarget);
    ;
    setParentindex(p);
  };
  const [anchorE4, setAnchorE4] = React.useState(null);
  const open4 = Boolean(anchorE4);
  const id4 = open4 ? "simple-popover" : undefined;
  //const [data2, setdata2] = useState(Json);
  const changebtn3 = (v) => {
    ;
    data[parentindex].button = v;
    setdata([...data]);
    setAnchorE4(null);
    setParentindex("");
  };

  const [anchorE2, setAnchorE2] = React.useState(null);

  const handleClick2 = (event, p) => {
    setAnchorE2(event.currentTarget);
    setParentindex(p);
  };
  const open2 = Boolean(anchorE2);
  const id2 = open2 ? "simple-popover" : undefined;
  //const [show2, setshow2] = useState("and");
  const changebtn2 = (v) => {
    // if (v === "nd") {
    //   setshow2(v);
    // } else if (v === "or") {
    //   setshow2(v);
    // }
    let obj = {
      select_entity: {
        name: "select Entity",
        value: "",
      },
      select_attribute: {
        name: "select Attribute",
        value: "",
      },
      conditons: {
        name: "conditions",
        value: "",
      },
      Select_value: {
        name: "select value",
        value: [],
      },
      button: {
        value: v,
      },
    };
    data[parentindex].card.push(obj);
    setdata([...data]);
    setAnchorE2(null);
  };

  const DeleteCard = (p, i) => {
    if (i === 0) {
      data.splice(i, 1);
    } else {
      data[p].card.splice(i, 1);
    }
    setdata([...data]);
  };

  const AddnewRule = (v) => {
    let obj = {
      button: v,
      header: "RuleSet",
      card: [
        {
          select_entity: {
            name: "select Entity",
            value: "",
          },
          select_attribute: {
            name: "select Attribute",
            value: "",
          },
          conditons: {
            name: "conditions",
            value: "",
          },
          Select_value: {
            name: "select value",
            value: [],
          },
          button: {
            value: "",
          },
        },
      ],
    };
    data.push(obj);
    setdata([...data]);
    setAnchorE3(null);
  };

  const [anchorE3, setAnchorE3] = React.useState(null);

  const handleClick3 = (event) => {
    if (data.length < 1) {
      let obj = {
        button: "",
        header: "RuleSet",
        card: [
          {
            select_entity: {
              name: "select Entity",
              value: "",
            },
            select_attribute: {
              name: "select Attribute",
              value: "",
            },
            conditons: {
              name: "conditions",
              value: "",
            },
            Select_value: {
              name: "select value",
              value: [],
            },
            button: {
              value: "",
            },
          },
        ],
      };
      data.push(obj);
      setdata([...data]);
    } else {
      setAnchorE3(event.currentTarget);
    }

    // setParentindex(p);
  };
  const open3 = Boolean(anchorE3);
  const id3 = open3 ? "simple-popover" : undefined;
  //const [show3, setshow3] = useState("and");

  // const changebtn3 = (v) => {};

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorE3(null);
    setAnchorE4(null);
  };
  return (
    <div>
      <div
        style={{
          //   paddingLeft: "60px",
          width: "100%",
          paddingBottom: "10px",
          paddingTop: "16px",
        }}
      >
        <div>
          {data.map((mval, p) => {
            return (
              <div>
                <div>
                  {mval.button && (
                    <div
                      style={{
                        marginTop: "35px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={(e) => handleClick4(e, p)}
                        style={{
                          border: "1px solid #FF6384",
                          width: "77px",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                      >
                        {mval.button === "And" && (
                          <div style={{ color: "#FF6384" }}>
                            <img src={And} alt="icon" /> And
                          </div>
                        )}
                        {mval.button === "Or" && (
                          <div style={{ color: "#FF6384" }}>
                            <img src={Or} alt="icon" /> Or
                          </div>
                        )}
                      </button>
                    </div>
                  )}
                </div>

                <Card
                  style={{
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <div key={p}>
                    <p className={classes.ruleset}>
                      {mval.header}
                      {p + 1}
                    </p>

                    {mval.card.map((val, i) => {
                      return (
                        <div key={i}>
                          {val.button?.value && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                              }}
                            >
                              <button
                                className={classes.andorbtn}
                                onClick={(e) => handleClick(e, p, i)}
                              >
                                {val.button?.value === "And" && (
                                  <div>
                                    <img src={And} alt="icon" /> And
                                  </div>
                                )}
                                {val.button?.value === "Or" && (
                                  <div>
                                    <img src={Or} alt="icon" /> Or
                                  </div>
                                )}
                              </button>
                            </div>
                          )}
                          <div style={{ display: "flex" }}>
                            <div>
                              <label className={classes.grayfont}>
                                {val.select_entity.name}
                              </label>
                              <Div
                                style={{
                                  display: "flex",
                                  border: "1px solid#E0E0E0",
                                  height: "40px",
                                  width: "180px",
                                  borderRadius: "5px",
                                  backgroundColor: "white",
                                }}
                              >
                                <h5 className={classes.entity}>IN</h5>{" "}
                                <Select
                                  className={classes.selectbox}
                                  options={optiondata}
                                  label={false}
                                  value={val.select_entity.value}
                                  variant="none"
                                  //   onChangeValue={(e) =>
                                  //     this.setDetails("entity_type", e)
                                  //   }
                                  onChangeValue={(newvalue) =>
                                    handleChangefield(
                                      newvalue?.value,
                                      "select_entity",
                                      p,
                                      i
                                    )
                                  }
                                  showArrow
                                  placeholder="select..."
                                  labelStyle={classes.labelStyle}
                                  inLineStyles={{
                                    borderColor: "#fff",
                                    border: "none",
                                    height: "30px",
                                  }}
                                  hoverColor="#0071F2"
                                />
                              </Div>
                            </div>
                            <div>
                              <label
                                className={classes.grayfont}
                                style={{ marginLeft: "20px" }}
                              >
                                {val.select_attribute.name}
                              </label>
                              <Div
                                style={{
                                  display: "flex",
                                  border: "1px solid#E0E0E0",
                                  height: "40px",
                                  width: "180px",
                                  marginLeft: "20px",
                                  borderRadius: "5px",
                                  backgroundColor: "white",
                                }}
                              >
                                <h5 className={classes.entity1}>IF</h5>
                                <Select
                                  options={optiondata}
                                  label={false}
                                  value={val.select_attribute.value}
                                  variant="none"
                                  //   }
                                  onChangeValue={(newvalue) =>
                                    handleChangefield(
                                      newvalue?.value,
                                      "select_attribute",
                                      p,
                                      i
                                    )
                                  }
                                  showArrow
                                  placeholder="select..."
                                  labelStyle={classes.labelStyle}
                                  inLineStyles={{
                                    borderColor: "#fff",
                                    border: "none",
                                    height: "30px",
                                  }}
                                  hoverColor="#0071F2"
                                />
                              </Div>
                            </div>
                            <div>
                              <label
                                className={classes.grayfont}
                                style={{ marginLeft: "22px" }}
                              >
                                {val.conditons.name}
                              </label>
                              <Div
                                style={{
                                  border: "1px solid#E0E0E0",
                                  height: "40px",
                                  width: "163px",
                                  marginLeft: "20px",
                                  borderRadius: "5px",
                                  backgroundColor: "white",
                                }}
                              >
                                <Select
                                  options={optiondata}
                                  label={false}
                                  value={val.conditons.value}
                                  variant="none"
                                  onChangeValue={(newvalue) =>
                                    handleChangefield(
                                      newvalue?.value,
                                      "conditons",
                                      p,
                                      i
                                    )
                                  }
                                  showArrow
                                  placeholder="select..."
                                  labelStyle={classes.labelStyle}
                                  inLineStyles={{
                                    borderColor: "#fff",
                                    border: "none",
                                    height: "30px",
                                  }}
                                  hoverColor="#0071F2"
                                />
                              </Div>
                            </div>
                            <div>
                              <div
                                style={{ marginLeft: "20px", width: "417px" }}
                              >
                                <div className={classes.auto}>
                                  <label className={classes.grayfont}>
                                    {val.Select_value.name}
                                  </label>
                                  <br />
                                  <Autocomplete
                                    style={{ width: "407px" }}
                                    multiple
                                    limitTags={2}
                                    id="tags-outlined"
                                    value={val.Select_value.value}
                                    options={top100Films}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                      ;
                                      data[p].card[i]["Select_value"].value =
                                        newValue;

                                      setdata([...data]);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        // style={{ marginTop: "10px" }}
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        InputLabelProps={{
                                          style: { fontSize: 12 },
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className={classes.del}
                              onClick={() => DeleteCard(p, i)}
                            >
                              <img src={deleteicon} alt="Icon" />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div>
                      <button
                        className={classes.addbtn}
                        onClick={(e) => handleClick2(e, p)}
                      >
                        + Add New Rule
                      </button>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}

          <Popover
            style={{ border: "1px solid blue" }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            className={classes.popover}
            anchorOrigin={{
              vertical: "right",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "top",
            }}
          >
            <Typography className={classes.typography}>
              <div className={classes.edit}>
                <div>
                  <div>
                    <p
                      style={{
                        fontFamily: "poppin",
                        fontSize: "10px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      Join Type
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <button onClick={() => changebtn("And")}>
                      {" "}
                      <img src={And} alt="icon" />
                      And
                    </button>
                    <button
                      style={{
                        marginLeft: "12px",
                        color: "#36A2EB",
                      }}
                      onClick={() => changebtn("Or")}
                    >
                      <img src={Or} alt="icon" />
                      Or
                    </button>
                  </div>
                </div>
              </div>
            </Typography>
          </Popover>
          <Popover
            style={{ border: "1px solid blue" }}
            id={id2}
            open={open2}
            anchorEl={anchorE2}
            onClose={handleClose}
            className={classes.popover}
            anchorOrigin={{
              vertical: "right",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "top",
            }}
          >
            <Typography className={classes.typography}>
              <div className={classes.edit}>
                <div>
                  <div>
                    <p
                      style={{
                        fontFamily: "poppin",
                        fontSize: "10px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      Join Type
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <button
                      onClick={() => changebtn2("And")}
                      className={classes.popbtn}
                    >
                      {" "}
                      <img src={And} alt="icon" />
                      And
                    </button>
                    <button
                      style={{ marginLeft: "12px", color: "#36A2EB" }}
                      className={classes.popbtn}
                      onClick={() => changebtn2("Or")}
                    >
                      <img src={Or} alt="icon" />
                      Or
                    </button>
                  </div>
                </div>
              </div>
            </Typography>
          </Popover>
          {/* Add new Rule set Popover..................... */}

          <Popover
            style={{ border: "1px solid #FF6384" }}
            id={id3}
            open={open3}
            anchorEl={anchorE3}
            onClose={handleClose}
            className={classes.popover1}
            anchorOrigin={{
              vertical: "right",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "top",
            }}
          >
            <Typography className={classes.typography}>
              <div className={classes.edit}>
                <div>
                  <p
                    style={{
                      fontFamily: "poppin",
                      fontSize: "10px",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Join Type
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <button
                    onClick={() => AddnewRule("And")}
                    style={{
                      border: "1px solid lightgray",
                      color: "#FF6384",
                      height: "32px",
                      width: "70px",
                    }}
                  >
                    <img src={And} alt="icon" />
                    And
                  </button>
                  <button
                    style={{
                      marginLeft: "12px",
                      border: "1px solid lightgray",
                      color: "#FF6384",
                      height: "32px",
                      width: "70px",
                    }}
                    onClick={() => AddnewRule("Or")}
                  >
                    <img src={Or} alt="icon" />
                    Or
                  </button>
                </div>
              </div>
            </Typography>
          </Popover>
          {/* Popover 4.................................................... */}

          <Popover
            style={{ border: "1px solid blue" }}
            id={id4}
            open={open4}
            anchorEl={anchorE4}
            onClose={handleClose}
            className={classes.popover1}
            anchorOrigin={{
              vertical: "right",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "top",
            }}
          >
            <Typography className={classes.typography}>
              <div className={classes.edit}>
                <div>
                  <div>
                    <p
                      style={{
                        fontFamily: "poppin",
                        fontSize: "10px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      Join Type
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <button
                      onClick={() => changebtn3("And")}
                      className={classes.popbtn}
                    >
                      {" "}
                      <img src={And} alt="icon" />
                      And
                    </button>
                    <button
                      style={{ marginLeft: "12px", color: "#36A2EB" }}
                      onClick={() => changebtn3("Or")}
                      className={classes.popbtn}
                    >
                      <img src={Or} alt="icon" />
                      Or
                    </button>
                  </div>
                </div>
              </div>
            </Typography>
          </Popover>

          <div>
            <button onClick={handleClick3} className={classes.btn}>
              {" "}
              + Add New Rule Set
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
