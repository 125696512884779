import React from "react";
//import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { generateComponent } from "../../components/common/smartForm/formsList";
import {
  Card,
  Button,
  Typography,
  Drawer,
  Grid,
  Avatar,
} from "@material-ui/core";
import { CommonTable } from "../../components";
import { AlertProps } from "../../utils";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../HOCs";
import { CommonTabList } from "../../components";
import { connect } from "react-redux";

const styles = (theme) => ({
  backicon: {
    borderRadius: "18px",
    background: "#e6e6e6",
    cursor: "pointer",
  },
  drawer: {
    width: 360,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 360,
  },
  large: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    position: "fixed",
    // right: "376px",
    right: 376,
    cursor: "pointer",
    top: 10,
    "& img": {
      objectFit: "scale-down",
      backgroundColor: "#fff",
    },
  },
  drawerHead: {
    display: "flex",
    padding: "19px 20px",
    borderBottom: "1px solid #eee",
  },
  headerMain: {
    fontFamily: "poppinsemibold",
  },
  btnPosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0px",
  },
});

class OrderSetApplicabilityTabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderSetKey: "",
      orderSetName: "",
      edit_data: null,
      isEdit: false,
      list: [
        {
          componet_type: "select",
          options: [],
          label: "Facility",
          state_name: "facility",
          value: {},
          required: true,
        },
        {
          componet_type: "select",
          options: [],
          label: "Specialty",
          state_name: "specialty",
          value: {},
          required: true,
        },
        {
          componet_type: "select",
          options: [],
          label: "Practitioner Type",
          state_name: "practitionerType",
          value: {},
        },
        {
          componet_type: "select",
          options: [],
          label: "Practitioner",
          state_name: "practitioner",
          value: {},
        },
        {
          componet_type: "select",
          options: [],
          label: "Code Set",
          state_name: "codeSet",
          value: {},
        },
        {
          componet_type: "select",
          options: [],
          label: "Diagnosis Group",
          state_name: "diagnosisGroup",
          value: {},
        },
        {
          componet_type: "select",
          options: [],
          label: "Diagnosis Code",
          state_name: "diagnosisCode",
          value: {},
        },
        {
          componet_type: "select",
          options: [],
          label: "Location Type",
          state_name: "locationType",
          value: {},
        },
        {
          componet_type: "select",
          options: [],
          label: "Location",
          state_name: "location",
          value: {},
        },
      ],
      states: {
        facility: "",
        specialty: "",
        practitionerType: "",
        practitioner: "",
        codeSet: "",
        diagnosisGroup: "",
        diagnosisCode: "",
        locationType: "",
        location: "",
        _id: "",
        id: "",
        _key: "",
        status: true,
      },
    };
  }

  async componentDidMount() {
    this.getApplicabiltyData();
  }

  getApplicabiltyData = async () => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Fetching...",
    });
    if (this.props.editData) {
      let d = await this.props.GET_ORDER_SET_APPLICABILITY_LIST({
        _id: this.props.editData,
      });
      this.setState({
        ...this.state,
        orderSetKey:
          this.props.orderSetApplicabilityList?.data?.[0]?.order_set__id ??
          this.props?.edit_id,
        orderSetName:
          this.props.orderSetApplicabilityList?.data?.[0]
            ?.order_set_shortDesc ?? this.props?.editShortDes,
      });
      await this.props.ORDER_SET_APPLICABILITY_MASTER();
    }
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  };

  handleDrawerOpen = () => {
    this.setState({
      ...this.state,
      open: true,
      isEdit: false,
      states: {
        facility: "",
        specialty: "",
        practitionerType: "",
        practitioner: "",
        codeSet: "",
        diagnosisGroup: "",
        diagnosisCode: "",
        locationType: "",
        location: "",
        _id: "",
        id: "",
        _key: "",
        status: true,
      },
    });
  };
  handleDrawerClose = () => {
    this.setState({
      ...this.state,
      open: false,
      edit_data: null,
      isEdit: false,
    });
  };

  handleEdit = async (e, values, index) => {
    if (values) {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: "Fetching...",
      });
      const data___ = await this.props.GET_ORDER_SET_APPLICABILITY_SINGLE_READ({
        _key: values._key,
      });
      // SPECIALTY
      if (data___?.payload?.data?.facility?.value) {
        let datare = await this.props.ORDER_SET_APP_SPECIALTY({
          _id: data___?.payload?.data?.facility?.value,
        });
      }
      //PRACTITIONER
      if (data___?.payload?.data?.practitionerType?.value) {
        let datare = await this.props.ORDER_SET_APP_PRACTITIONER({
          facility: data___?.payload?.data?.facility?.value ?? "",
          speciality: data___?.payload?.data?.specialty?.value ?? "",
          practitionerType:
            data___?.payload?.data?.practitionerType?.value ?? "",
        });
      }
      //DIAGNOSIS GROUP
      if (data___?.payload?.data?.codeSet?.value) {
        await this.props.ORDER_SET_APP_DIAGNOSIS_GROUP({
          _id: data___?.payload?.data?.diagnosisGroup?.icdcode,
        });
      }
      //DIAGNOSIS CODE
      if (data___?.payload?.data?.codeSet?.value) {
        await this.props.ORDER_SET_APP_DIAGNOSIS_CODE({
          _id: data___?.payload?.data?.codeSet?.value,
          search: null,
        });
      }
      //LOCATION
      if (data___?.payload?.data?.locationType?.value) {
        await this.props.ORDER_SET_APP_LOCATION({
          _id: data___?.payload?.data?.locationType?.value,
        });
      }
      this.setState({
        ...this.state,
        open: true,
        isEdit: true,
        states: data___?.payload?.data,
      });
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
    } else {
      this.setState({
        ...this.state,
        open: true,
        isEdit: false,
      });
    }
  };

  condition = (val) => {
    if (val?.hidden) {
      return false;
    } else {
      return true;
    }
  };

  handleChange = async (type, val) => {
    const state_ = JSON.parse(JSON.stringify(this.state));

    //GET SPECIALTY
    if (type === "facility") {
      state_.states.specialty = {};
      state_.states.practitioner = {};
      const data = await this.props.ORDER_SET_APP_SPECIALTY({
        // _id: val?._id,
        _id: val?.value,
      });
      const data1 = await this.props.ORDER_SET_APP_PRACTITIONER({
        // _id: val?._id,
        // _id: val?.value
        facility: val?.value ?? "",
        speciality: this.state?.states?.specialty?.value ?? "",
        practitionerType: this.state?.states?.practitionerType?.value ?? "",
      });
    }

    //GET PRACTITIONER
    if (type === "specialty") {
      state_.states.practitioner = {};
      const data = await this.props.ORDER_SET_APP_PRACTITIONER({
        // _id: val?._id,
        // _id: val?.value
        facility: this.state?.states?.facility?.value ?? "",
        speciality: val?.value ?? "",
        practitionerType: this.state?.states?.practitionerType?.value ?? "",
      });
    }
    if (
      type === "practitionerType" &&
      this.state?.states?.facility?.value &&
      this.state?.states?.specialty?.value
    ) {
      state_.states.practitioner = {};
      const data = await this.props.ORDER_SET_APP_PRACTITIONER({
        // _id: val?._id,
        // _id: val?.value
        facility: this.state?.states?.facility?.value ?? "",
        speciality: this.state?.states?.specialty?.value ?? "",
        // practitionerType: val?.value ?? "",
        practitionerType: this.state?.states?.practitionerType?.value ?? "",
      });
    }
    //GET DIAGNOSIS GROUP
    if (type === "codeSet") {
      // state_.states.diagnosisGroup = {};
      state_.states.diagnosisCode = {};
      const data = await this.props.ORDER_SET_APP_DIAGNOSIS_GROUP({
        _id: val?.value,
      });
    }
    // //GET DIAGNOSIS CODE
    if (type === "codeSet") {
      state_.states.diagnosisCode = {};

      const data = await this.props.ORDER_SET_APP_DIAGNOSIS_CODE({
        _id: val?.label,
        search: val?.value,
      });
    }
    // GET DIAGNOSIS CODE
    // if (type === "diagnosisCode") {
    //   const data = await this.props.ORDER_SET_APP_DIAGNOSIS_CODE({
    //     _id: val?.label,
    //     search: val,
    //   });
    // }
    //GET LOCATION
    if (type === "locationType") {
      state_.states.location = {};
      const data = await this.props.ORDER_SET_APP_LOCATION({
        // _id: val?._id,
        _id: val?.value,
      });
    }
    state_.states[type] = val;
    this.setState({ ...state_ });
  };

  handleDelete = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Deleting",
    });

    let response = await this.props.DELETE_ORDER_SET_APPLICABILITY({
      _key: data?._key,
    });
    if (response?.payload?.data?.Code === 201) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Applicability deleted successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      this.getApplicabiltyData();
    } else {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: response?.payload?.data?.validation_error[0].Errormsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }

    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  };

  saveOrderSetApplicability = async () => {
    if (
      this.state.states?.facility?.value &&
      this.state?.states?.specialty?.value
    ) {
      let state_ = this.state?.states;
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: state_._key ? "Updating..." : "Saving",
      });
      let payload = await this.props.ORDER_SET_APPLICABILITY_UPSERT({
        dataList: state_,
        orderSetKey: this.state?.orderSetKey,
      });
      if (payload?.payload?.data?.Code === 201) {
        if (state_._key) {
          this.props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Order Set Applicability Updated Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        } else {
          this.props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Order Set Applicability Added Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
        this.handleDrawerClose();
        await this.getApplicabiltyData();
        this.props.backDrop.setBackDrop({
          ...this.props.backDrop,
          open: false,
          message: "",
        });
      } else {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Error throws",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      }
    } else {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select Facility & Specialty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  render() {
    const { parent_id, editData, closeForm_ } = this.props;

    // const { backicon, drawer, drawerPaper, large, drawerHead, headerMain } =
    //   this.styles;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div>
          <Card>
            <div
              style={{
                borderRadius: "6px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CommonTabList
                    parent_id={"agerange_form"}
                    backbtn
                    backFun={closeForm_}
                    title={this.props?.editShortDes ?? ""}
                    list={[]}
                  />
                </div>
                <div style={{ margin: "16px", float: "right" }}>
                  <Button
                    id={`${parent_id}-Add-New-button`}
                    disabled={false}
                    variant={"contained"}
                    color={"primary"}
                    onClick={this.handleDrawerOpen}
                  >
                    +Add New
                  </Button>
                </div>
              </div>
            </div>
          </Card>

          <div
            id={`${parent_id}-CommonTable-div`}
            style={{ padding: 20, height: "71vh", overflow: "auto" }}
          >
            <CommonTable
              parent_id={"symptonsAndSpecialtiestable"}
              Header={[
                "S.No",
                "Facility",
                "Specialty",
                "Practitioner Type",
                "Practitioner",
                "Diagnosis Group",
                "Diagnosis Code",
                "Location Type",
                "Location",
                "Action",
              ]}
              dataList={this.props.orderSetApplicabilityList?.data ?? []}
              tableData={[
                { type: ["INCRIMENT"], name: "" },
                { type: ["TEXT"], name: "facility", optionLabel: "label" },
                { type: ["TEXT"], name: "specialty", optionLabel: "label" },
                {
                  type: ["TEXT"],
                  name: "practitionerType",
                  optionLabel: "label",
                },
                {
                  type: ["TEXT"],
                  name: "practitioner",
                  optionLabel: "label",
                },
                {
                  type: ["TEXT"],
                  name: "diagnosisGroup",
                  optionLabel: "label",
                },
                {
                  type: ["TEXT"],
                  name: "diagnosisCode",
                  optionLabel: "label",
                },
                {
                  type: ["TEXT"],
                  name: "locationType",
                  optionLabel: "label",
                },
                { type: ["TEXT"], name: "location", optionLabel: "label" },
                {
                  type: ["EDIT", "DELETE"],
                  name: "action",
                  optionLabel: "label",
                },
              ]}
              handleCheckBox={this.statusChange}
              handleEdit={this.handleEdit}
              handleDelete={this.handleDelete}
            />
          </div>
        </div>

        <Drawer
          anchor="right"
          open={this.state?.open}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {
            <div id={`${parent_id}-parent-drawer-div`}>
              <Avatar
                id={`${parent_id}-close-Avatar`}
                className={classes.large}
                src="/images/close.svg"
                onClick={() => this.handleDrawerClose()}
              />
              <div id={`${parent_id}-sub-parent-div`}>
                <div
                  id={`${parent_id}-title-div`}
                  className={classes.drawerHead}
                >
                  <Typography
                    id={`${parent_id}-title-typography`}
                    className={classes.headerMain}
                  >
                    {this.state.isEdit
                      ? "Edit Order Set Applicability"
                      : "Add New Order set applicability"}
                  </Typography>
                </div>
                <Grid
                  id={`${parent_id}-button-grid`}
                  container
                  direction="row"
                  style={{ padding: "10px 20px" }}
                >
                  <Grid id={`${parent_id}-button-sub-grid`} item xs={12}>
                    {this.state?.list?.length > 0 &&
                      this.state?.list?.map((val) => {
                        let opt =
                          val.state_name === "facility"
                            ? this.props?.orderSetApplicabilityMaster?.data
                                ?.facilityName
                            : val.state_name === "specialty"
                            ? this.props?.orderSetAppSpecialty?.data
                            : val.state_name === "practitionerType"
                            ? this.props?.orderSetApplicabilityMaster?.data
                                ?.practitionerType
                            : val.state_name === "practitioner"
                            ? this.props?.orderSetAppPractitioner?.data
                            : val.state_name === "codeSet"
                            ? this.props?.orderSetApplicabilityMaster?.data
                                ?.codeSet
                            : val.state_name === "diagnosisGroup"
                            ? this.props?.orderSetAppDiagnosisGroup?.data
                            : val.state_name === "diagnosisCode"
                            ? this.props?.orderSetAppDiagnosisCode?.data
                            : val.state_name === "locationType"
                            ? this.props?.orderSetApplicabilityMaster?.data
                                ?.locationType
                            : val.state_name === "location"
                            ? this.props?.orderSetAppLocation?.data
                            : [];
                        if (this.condition(val)) {
                          return generateComponent({
                            data: { ...val, options: opt },
                            handleChange: this.handleChange,
                            // onChangeTextfield: this.onChangeTextfield,
                            state: this.state,
                            //classes: styles
                          });
                        } else {
                          return false;
                        }
                      })}
                    <div
                      id={`${parent_id}-button-sub-div`}
                      className={classes.btnPosition}
                    >
                      {this.state?.isEdit ? (
                        <Button
                          id={`${parent_id}-Saving-and-Save-button`}
                          disabled={
                            this?.props?.orderSetApplicabilityInsert?.data
                              ?.loading ?? false
                          }
                          variant={"contained"}
                          color={"primary"}
                          style={{ marginTop: 20 }}
                          size={"small"}
                          contained
                          onClick={() => this.saveOrderSetApplicability()}
                        >
                          {this?.props?.orderSetApplicabilityInsert?.data
                            ?.loading
                            ? "Updating..."
                            : "Update"}
                        </Button>
                      ) : (
                        <Button
                          id={`${parent_id}-Saving-and-Save-button`}
                          disabled={
                            this?.props?.orderSetApplicabilityInsert?.data
                              ?.loading ?? false
                          }
                          variant={"contained"}
                          color={"primary"}
                          style={{ marginTop: 20 }}
                          size={"small"}
                          contained
                          onClick={() => this.saveOrderSetApplicability()}
                        >
                          {this?.props?.orderSetApplicabilityInsert?.data
                            ?.loading
                            ? "Saving..."
                            : "Save"}
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          }
        </Drawer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orderSetApplicabilityList:
    state?.orderSetApplicabilitySlice?.order_set_applicability_list,
  orderSetApplicabilityMaster:
    state?.orderSetApplicabilitySlice?.order_set_applicability_master,

  orderSetAppPractitioner:
    state?.orderSetApplicabilitySlice?.order_set_app_practitioner,

  orderSetAppDiagnosisGroup:
    state?.orderSetApplicabilitySlice?.order_set_app_diagnosis_group,

  orderSetAppDiagnosisCode:
    state?.orderSetApplicabilitySlice?.order_set_app_diagnosis_code,

  orderSetAppLocation:
    state?.orderSetApplicabilitySlice?.order_set_app_location,

  orderSetApplicabilityInsert:
    state?.orderSetApplicabilitySlice?.order_set_applicability_upsert,

  orderSetAppSpecialty:
    state?.orderSetApplicabilitySlice?.order_set_app_specialty,
});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withAllContexts(OrderSetApplicabilityTabel))
);
