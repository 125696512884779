import React, { useState,useEffect } from "react";
import {
  makeStyles,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { CommonTable, OverlayCompt } from "../../../../../components";
import InputAdornment from "@material-ui/core/InputAdornment";
import searchicon from "../../../../../assets/icons - Search.svg";
import AddFlexAttCriteria from "./form";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import {withAllContexts} from "../../../../../HOCs"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "white",
    padding: 12,
    border: "1px solid #ddd",
    borderRadius: 12,
  },
  titleText: {
    fontSize: 16,
    color: "#101010",
    margin: 0,
    fontFamily: "pc_semibold",
  },
  inputText: {
    fontSize: 14,
    margin: "8px 0px 12px 0px",
    "& input": {
      padding: "10px 12px",
    },
    backgroundColor: "#00000008",
    border: "1px solid #1010100D",
  },
  addBtnGrid: {
    width: "18%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "4px",
  },
  TitleDiv: {
    display: "flex",
    alignItems: "center",
  },
  DivBtn: {
    display: "flex",
  },
  DivBtn1: {
    aliginItem: "center",
    justifyContent: "end",
  },
  DivBtn1Input: {
    width: "58%",
  },
  btnAdd: {
    padding: "5px 12px",
  },
  btnSearch: {
    fontSize: 21,
  },
  btInputDive: {
    justifyContent: "end",
  },
}));

const FlexAttributesApplicableCriteria = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const flexCriteriaRead = useSelector(
    (state) => state?.flexCriteriaSlice?.flex_criteria_fullRead?.data
  );

  const [addNewOpen, setAddNewOpen] = useState(false);
  const handleAddNew = () => {
    setAddNewOpen(true);
  };
  const handleCloseAddNew = () => {
    setAddNewOpen(false);
    setEdit({
      ...edit,
      isEdit: false,
      editData: {}
    })
  };

  const saveForm = async(item) => {
    if(item?.key){
      const editSave = await dispatch(actions.FLEX_CRITERIA_EDIT({list:item}))
      props.alert.setSnack("update");
    }else{
      const saved =await dispatch(actions.FLEX_CRITERIA_INSERT({list:item}));
      props.alert.setSnack("success");
    }
    getCriteriaMasterData();
  }

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(
      actions.FLEX_CRITERIA_FULL_READ({
        page: (currentPage - 1) * PerPage,
        perpage: PerPage,
        search:isSearch
      })
    );
  };

  const getCriteriaMasterData = async () => {
    await dispatch(
      actions.FLEX_CRITERIA_FULL_READ({ page: page, perpage: perPage,search:isSearch })
    );
  };

  useEffect(() => {
    getCriteriaMasterData();
  }, []);

  const statusChange = async (e, val, i) => {
    await dispatch(
      actions.FLEX_CRITERIA_STATUS({ status: e.target.checked, key: val?._key })
    );
    props.alert.setSnack("update")
    await dispatch(
      actions.FLEX_CRITERIA_FULL_READ({ page: page, perpage: perPage,search:isSearch })
    );
  };

  const [edit,setEdit] = useState({
    isEdit:false,
    editData:{}
  })
  const handleEdit = (e, val, i) => {
    setAddNewOpen(true);
    setEdit({
      ...edit,
      isEdit: true,
      editData: val
    })
  }

  const [searchData,setSearch] = useState()
  const [isSearch,setIsSearch] = useState("")
  const searchChange = async (event) => {
    await dispatch(
      actions.FLEX_CRITERIA_FULL_READ({ page: page, perpage: perPage,search:event.target.value })
    );
          setIsSearch( event.target.value)
  };

  return (
    <div id={"flex-master-parent-div"} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={6} className={classes.TitleDiv}>
          <Typography className={classes.titleText}>
            Flex Attributes Applicable Criteria
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container className={classes.btInputDive}>
            <Grid className={classes.DivBtn1Input}>
              <div>
                <TextField
                  id="outlined-search"
                  placeholder="Search Code & Description"
                  size="small"
                  onChange={searchChange}
                  style={{
                    padding: "8px 20px",
                  }}
                  type="search"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "poppin",
                      fontSize: "12px",
                      background: "#f6f6f6",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchicon} alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12 },
                  }}
                />
              </div>
            </Grid>
            <Grid className={classes.addBtnGrid}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btnAdd}
                onClick={handleAddNew}
              >
                + Add New
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CommonTable
        parent_id={"drugMaster"}
        style={{ height: "calc(100vh - 257px)" }}
        handleEdit={handleEdit}
        handleCheckBox={statusChange}
        Header={["S.No", "Attribute Label Name", "Status", "Action"]}
        dataList={flexCriteriaRead}
        tableData={[
          { type: ["INCRIMENT"], name: "" },
          { type: ["TEXT"], name: "winlabelName" },
          { type: ["CHECKBOX"], name: "status", align: "left" },
          { type: ["EDIT"], name: "", align: "left" },
        ]}
        // condition={condition}
        handlepageChange={(currentPage, PerPage) =>
          handlepageChange(currentPage, PerPage)
        }
        TableCount={flexCriteriaRead[0]?.TotalCount}
        incrementCount={page}
        showPagination={true}
        rowsPerPageOptions={[
          { label: "10 Rows", value: 10 },
          { label: "50 Rows", value: 50 },
          { label: "100 Rows", value: 100 },
        ]}
      />
      <OverlayCompt
        open={addNewOpen}
        children={
          <React.Fragment>
            {addNewOpen && (
              <AddFlexAttCriteria
                handleCloseAddNew={handleCloseAddNew}
                saveForm={saveForm}
                edit={edit}
                // attributeState={attributeState}
                // handleFormState={handleFormState}
              />
            )}
          </React.Fragment>
        }
      />
    </div>
  );
};

export default withAllContexts(FlexAttributesApplicableCriteria);
