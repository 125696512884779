import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 23px",
  },
  headerTypo: {
    color: "#001C3C",
    fontSize: "14px",
    fontWeight: 600,
  },
  box: {
    border: "1px solid #DCDCDC",
    borderRadius: "10px",
    marginTop: "12px",
    backgroundColor: "#fff",
    padding: "10px",
  },
  align: {
    display: "flex",
    alignItems: "center",
  },
  subHeaderTypo: {
    color: "#001C3C",
    fontSize: "14px",
  },
  dot: {
    backgroundColor: "#2B2B2B",
    borderRadius: "50%",
    padding: "2px",
  },
  infoTypo: {
    color: "#2B2B2B",
    fontSize: "12px",
    marginRight: "4px",
  },
  noBtn: {
    padding: "0px",
    fontSize: "14px",
    color: "#0071F2",
    textTransform: "none",
    fontWeight: "600",
  },
  perioSelection: {
    paddingLeft: 8,
    borderRadius: "8px",
  },
  atitleData: {
    display: "grid",
    gridTemplateColumns: "160px 1fr 90px",
    paddingBottom: "8px",
  },
  divider: {
    marginTop: "9px",
    borderBottom: "1px dashed #e9e9e9",
    background: "none",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
    width: "38px",
  },
  monthcheckbox: {
    "& .MuiTypography-body1": {
      fontSize: "12px !important",
      fontFamily: "poppin !important",
    },
  },
  marginleft10: {
    marginLeft: "10px",
    fontSize: "12px",
    fontFamily: "poppin",
  },
  formParent: {
    paddingRight: 10,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 10,
  },
  cancelButton: {
    marginLeft: 15,
    textTransform: "none",
    borderRadius: "8px",
  },
  confirmButton: {
    marginLeft: 15,
    backgroundColor: "#0071F2",
    textTransform: "none",
    borderRadius: "8px",
    color:"#fff"
  },
  selected:{
    backgroundColor:"#fff"
  }
}));
