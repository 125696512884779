import React, { useEffect, useState } from "react";
import { Card, Grid, Popover, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import expandarrow from "../../../assets/icons8-expand-arrow.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { SelectBox } from "../../../components";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";

const useStyles = makeStyles((theme) => ({
  req_red: {
    color: "red",
  },
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  line: {
    borderLeft: "3px solid #e1e1e1",
    marginRight: "8px",
    marginLeft: "8px",
  },
  newRes: {
    color: "#0071F2",
  },
}));

function GeneralAppointmentCard(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const drop = useSelector((state) => state.resourceByAppointmentTypesSlice);
  useEffect(() => {
    getMasterData();
  }, []);
  const getMasterData = async () => {
    let a = await dispatch(actions.RES_GENERAL_INSTRUCTION_DROPDOWN());
    debugger;
  };
  const [Alertstate, setAlertState] = React.useState(false);
  const [state, setState] = useState({
    instructionCode: {},
    instructionDesc: "",
  });
  const onClick = (name, val) => {
    if (name === "instructionCode") {
      setState({
        ...state,
        [name]: val,
        ["instructionDesc"]: val?.title,
      });
    } else {
      setState({
        ...state,
        [name]: val,
      });
    }
  };
  const [add, setAdd] = useState({
    instructions: "",
    newInstruction: "",
  });
  const handleChange = () => {
    setAdd({});
  };
  const handleSave = () => {
    const newState = state;
    state.push(add);
    setState(...state);
  };

  return (
    <>
      <Card
        style={{
          padding: "18px",
          width: "95%",
          marginLeft: "16px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} style={{ display: "flex" }}>
              <p
                style={{
                  fontFamily: "poppinsemibold",
                  marginTop: "0px",
                }}
              >
                GENERAL APPOINTMENT INSTRUCTIONS
              </p>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  className={classes.newRes}
                  onClick={handleClick}
                  style={{
                    marginTop: "-6px",
                    fontFamily: "poppinsemibold",
                  }}
                  id={"resource_definition_card_Id"}
                >
                  + Add New
                </Button>
                <IconButton aria-label="ExpandLessIcon" size="small">
                  <ExpandLessIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          <Grid
            container
            style={{
              border: "1px solid #E0E0E0",
              padding: 12,
              backgroundColor: "#F9F9F9",
              position: "relative",
            }}
            direction="row"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid
                  item
                  xs={1}
                  style={{
                    backgroundColor: "#E0E0E0",
                    position: "absolute",
                    left: -1,
                    padding: "8px 8px",
                    borderTopRightRadius: 7,
                    borderBottomRightRadius: 7,
                  }}
                >
                  {" "}
                  1{" "}
                </Grid>
                <Grid item xs={12} style={{ marginLeft: "4%" }}>
                  <div>General Instructions</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <IconButton
                  aria-label="DeleteIcon"
                  className={classes.margin}
                  size="small"
                >
                  <DeleteIcon
                    style={{
                      color: "#FF4D4A",
                      fontSize: 18,
                    }}
                    onClick={() => props.deleteFun("generalInstruction")}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Card>
      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div style={{ padding: 18, width: 700 }}>
          <Grid container direction="row">
            <Grid item lg={11} md={11} xl={11}>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: 14,
                  marginBottom: 8,
                  marginTop: 0,
                  fontWeight: "bold",
                }}
              >
                Add General Appointment Instructions
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <CloseIcon
                style={{
                  marginTop: "-18px",
                  cursor: "pointer",
                  marginLeft: "36px",
                }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} lg={10}>
            <Grid item xs={4} md={4}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 12,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Instruction Code
              </Typography>
              <SelectBox
                parent_id={"generalAppointment-select"}
                value={state?.instructionCode ?? {}}
                onchange={(e, value) => onClick("instructionCode", value)}
                list={
                  Array.isArray(drop?.res_general_instruction_dropdown?.data)
                    ? drop?.res_general_instruction_dropdown?.data
                    : []
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} lg={12}>
            <Grid item xs={30} md={12}>
              <Typography
                style={{
                  color: "#6F6F6F",
                  fontSize: 12,
                  marginBottom: 2,
                  marginTop: 0,
                }}
              >
                Description
              </Typography>
              <TextField
                id="outlined-multiline-static"
                fullWidth
                style={{ margin: 1 }}
                multiline
                rows={4}
                variant="outlined"
                value={state?.instructionDesc ?? ""}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            float: "right",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            style={{
              marginRight: "10px",
              color: "black",
              fontFamily: "poppinsemibold",
              borderRadius: "8px",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            style={{
              marginRight: "34px",
              color: "white",
              backgroundColor: "#0071F2",
              fontFamily: "poppinsemibold",
              borderRadius: "8px",
            }}
            onClick={() => {
              // state.add
            }}
          >
            Save
          </Button>
        </div>
      </Popover>
    </>
  );
}

export default GeneralAppointmentCard;
