import React from "react";
import { CommonTable, GenerateForm } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

function DrugClass(props) {
  const dispatch = useDispatch();

  const drugClasslist = useSelector((state) => state?.drugClassSlice?.drug_class_list?.data);
  const loading = useSelector((state) => state?.drugClassSlice?.drug_class_list?.loading);
  const saveloading = useSelector((state) => state?.drugClassSlice?.drug_class_upsert?.loading);

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  React.useLayoutEffect(() => {
    dispatch(actions.DRUG_CLASS_READ({ page: page, perPage: perPage}));
    props?.currentTab("drugClass");
  }, [])
 
  const [state, setState] = React.useState({
    openEditFrom: false,
    saveloading: false,
    edit_data: null,
    status: true
  })

  

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.DRUG_CLASS_READ({ page: (currentPage - 1) * PerPage, perPage: PerPage }));

  };

  const handleCloseForm = () => {
    const { changeState } = props
    changeState("open", false)
    setState({
      openEditFrom: false,
      edit_data: null
    })
  }

  const handleEdit = (e, data) => {
    setState({
      openEditFrom: true,
      edit_data: data,
      status: data?.status
    })
  }

  const saveForm = async (values) => {
    let type = "success";
    let data = {
      drug_class: values?.drug_class?.toUpperCase?.(),
      short_description: values.short_description,
      long_description: values.long_description,
      status: true,
    }
    if (state?.edit_data) {
      data["_key"] = state?.edit_data._key
      //   data["id"] = state?.edit_data.id
      data["status"] = state?.edit_data.status
      type = "update";
    }
   let save= await dispatch(actions.DRUG_CLASS_UPSERT(data));
   
    if (save.payload?.data?.Code === 1111) {
      let type = save.payload?.data?.validation_error?.[0]?.Errormsg === "[\"drugclass\"] fields must be unique" ? "unique" : "error"
      props.alert.setSnack(type);
    } else {
       await dispatch(actions.DRUG_CLASS_READ({ page: page, perPage: perPage}));
      await handleCloseForm();
      props.alert.setSnack(type);
    }
  }

  const statusChange = async (e, values, index) => {
    let data = {
      _key: values._key,
      status: e.target.checked
    }
    await dispatch(actions.DRUG_CLASS_STATUS_CHANGE(data));
    await dispatch(actions.DRUG_CLASS_READ({ page: page, perPage: perPage}));
    props.alert.setSnack("update")
  }

  // render() {
  const { open, openIndex, parent_id } = props;
  return <React.Fragment>
    <CommonTable
      parent_id={"drugclass"}
      style={{ height: "calc(100vh - 257px)" }}
      rightAlign={["Status", "Action"]}
      handleEdit={handleEdit}
      handleCheckBox={statusChange}
      Header={["S.No", "Drug Class Code", "Short Description", "Long Description", "Status", "Action"]}
      dataList={drugClasslist}
      tableData={[
        { type: ["INCRIMENT"], name: "" },
        { type: ["TEXT"], name: "drug_class" },
        { type: ["TEXT"], name: "short_description" },
        { type: ["TEXT"], name: "long_description" },
        { type: ["CHECKBOX"], name: "status", align: "right" },
        { type: ["EDIT"], name: "", align: "right" },
      ]}
      handlepageChange={(currentPage, PerPage) =>
        handlepageChange(currentPage, PerPage)
      }
      TableCount={drugClasslist?.[0]?.total_count}
      incrementCount={page}
      showPagination={true}
      rowsPerPageOptions={[
        { label: "10 Rows", value: 10 },
        { label: "50 Rows", value: 50 },
        { label: "100 Rows", value: 100 },
      ]}
    />
    <GenerateForm
      parent_id={"drugclass"}
      loading={saveloading}
      nocancel
      open={((open && openIndex === 0) || state?.openEditFrom) ? true : false}
      handlecancel={() => handleCloseForm()}
      save={saveForm}
      header={state?.edit_data ? "Edit Drug Class" : 'Add New Drug Class'}
      json={[{
        componet_type: "text",
        label: "Drug Class Code",
        state_name: "drug_class",
        value: state?.edit_data?.drug_class ?? "",
        required: true,
        isUpperCase: true,
        textLength: 10,
        disabled: state?.edit_data?.drug_class ? true : false
      },
      {
        componet_type: "text",
        label: "Short Description",
        state_name: "short_description",
        value: state?.edit_data?.short_description ?? "",
        required: true,
        textLength: 30,
      },
      {
        componet_type: "text",
        label: "Long Description",
        state_name: "long_description",
        value: state?.edit_data?.long_description ?? "",
        required: true,
        textLength: 60,
      },
      ]}
    />
  </React.Fragment>
}

export default withAllContexts(DrugClass);