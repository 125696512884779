import React from "react";
import {
  Div,
  Paper,
  H6,
  Text,
  Image,
} from "qdm-component-library";
import "./style.css";
import { TextField } from "@material-ui/core"
import { withAllContexts } from "../../../HOCs";

class Biography extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Showfilter: this.props?.[this.props.saveto]?.names,
      limited: false,
      Collapse: true,
      addCollapseForm: true,
      contact: [{}]
    };
  }

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
  };

  onChangeState = (key, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let patientData = this.props[this.props.saveto];
    patientData.details[key] = value;
    patientData[this.props.setTo]({
      ...patientData,
    });
    this.setState({
      ...this.state,
      [key]: value
    })
  };

  Collapse(e) {
    this.setState({
      Collapse: !this.state.Collapse,
    });
  }


  render() {
    let { Collapse } = this.state;
    const { details, parent_id } = this.props[this.props.saveto];

    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-title-div`}
            style={{ display: "flex", alignItems: "center" }}>
            <H6
              id={`${parent_id}-title-h6`}
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                flex: 1,
                fontSize: "12px",
                fontWeight:"600"
              }}
            >
              Biography
              {details.biography_description?.length > 0 && (<>
                <Image
                  id={`${parent_id}-tick-image`}
                  style={{ width: 16, height: 16, marginLeft: 5 }}
                  src="images/icons8-ok.svg"
                  alt="tick"
                /></>
              )}
              {/* )} */}
            </H6>
            <Text
              id={`${parent_id}-tick-image-text`}
              inLineStyles={{
                color: "#0071F2",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
              }}
              className="pi-addbtn"
            >
              {/* {details.biography_description?.length > 0 && ( */}
              <React.Fragment>
                {Collapse ? (
                  <Image
                    id={`${parent_id}-downArrow-image`}
                    src="images/Group 3873.svg"
                    style={{ width: 20, height: 20 }}
                    alt="downArrow"
                    onClick={(e) => this.Collapse(e)}
                  />
                ) : (
                  <>
                    <Image
                      id={`${parent_id}-upArrow-image`}
                      style={{ width: 20, height: 20 }}
                      src="images/Group -1.svg"
                      alt="upArrow"
                      onClick={(e) => this.Collapse(e)}
                    />
                  </>
                )}
              </React.Fragment>
              {/* )} */}
              {" "}
            </Text>
          </Div>

          {Collapse && (
            <Div
              id={`${parent_id}-biography-description-div`}
              style={{ marginTop: 20, marginBottom: 10 }}>
              <TextField
                id={`${parent_id}-biography-description-textfield`}
                value={details?.biography_description ?? ""}
                label="Biography Description"
                placeholder="Type Description here"
                multiline
                minRows={4}
                variant="outlined"
                style={{ width: "100%" }}
                onChange={(e) => this.onChangeState("biography_description", e.target.value)}
              />
            </Div>
          )}
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(Biography);
