import React from "react";
import {
  Div,
  Col,
  Row,
  TextInput,
  //Text,
  Button,
  Upload,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import { Filenameui } from "./commonUI";
import { uploadImage, checkMaxDateValidation } from "../../../utils";
import moment from "moment";

import { Typography } from "@material-ui/core";
import CustomInputDatePicker from "../../common/datePicker/inputDatePicker";

class PatientQualification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      use: null,
      qualification_name: null,
      start_date: "",
      end_date: "",
      issued_by: null,
    };
  }

  onChangeState = (key, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    if (key === "start_date") {
      this.state["end_date"] = "";
      this.state["issued_by"] = "";
    }
    if (key === "end_date") {
      this.state["issued_by"] = "";
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  componentDidMount() {
    const { editData } = this.props;
    if (editData) {
      this.setState({ ...editData });
    } else {
      this.Clear();
    }
  }

  saveAddress = () => {
    if (this.props?.saveAddress) {
      this.props.saveAddress(this.state);
    }
    // const { onclose, index, handleClear } = this.props;
    // let patientData = this.props[this.props.saveto];
    // if (index !== null) {
    //   patientData.qualification[index] = this.state;
    // } else {
    //   patientData.qualification.push(this.state);
    // }
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
    // onclose();
    // handleClear();
    // this.Clear();
  };

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
    },
  };

  Clear = () => {
    this.setState({
      use: null,
      qualification_name: null,
      start_date: "",
      end_date: "",
      issued_by: null,
    });
  };

  handleClear = () => {
    this.Clear();
    if (this.props?.handleClear) {
      this.props.handleClear();
    }
  };

  setDataUpload = async (name, value, next, limit) => {
    var UploadedFiles = [];
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    UploadedFiles = await uploadImage([value]);
    this.onChangeState(name, UploadedFiles);
  };

  render() {
    const {
      //onclose, 
      parent_id } = this.props;
    const { labelStyle, borderStyle, outlinedButton, containedButton } =
      this.styles;

    return (
      <Div
        id={`${parent_id}-parent-div`}
        style={{
          padding: "0px 16px 16px 16px",
        }}
      >
        {/* <Paper className="pi-paper"> */}
        <Row
          id={`${parent_id}-parent-row`}
          className="pi-actions-root">
          <Col
            id={`${parent_id}-name-col`}
            md={2} lg={3} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            <TextInput
              id={`${parent_id}-name-textinput`}
              label="Name"
              placeholder="Enter Name"
              hoverColor="#0071F2"
              onChange={(e) => this.onChangeState("name", e.target.value, 30)}
              value={this.state.name ? this.state.name : ""}
              labelStyle={labelStyle}
              style={borderStyle}
              isRequired={true}
            />
          </Col>

          <Col
            id={`${parent_id}-name-col`}
            md={4} lg={9} sm={6} xs={6} inLineStyles={{ padding: 5 }}>
            <TextInput
              id={`${parent_id}-name-textinput`}
              isRequired={true}
              placeholder="Enter University/Board"
              onChange={(e) =>
                this.onChangeState("qualification_name", e.target.value, 30)
              }
              value={
                this.state.qualification_name
                  ? this.state.qualification_name
                  : ""
              }
              label="University/Board"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
            />
          </Col>
          <Col
            id={`${parent_id}-start-date-col`}
            md={4} lg={2} sm={7} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <TextInput
              id={`${parent_id}-start-date-textinput`}
              placeholder="start date"
              onChange={(e) => this.onChangeState("start_date", e.target.value)}
              type="date"
              value={this.state.start_date}
              label="Issue Date"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
              max={"9999-12-31"}
            /> */}
            <Typography
              style={{
                fontFamily: "poppin",
                color: "#6F6F6F",
                fontSize: "12px",
                paddingBottom: "8px",
              }}
            >
              {"Issue Date"}
              {/* <span style={{ color: "red" }}>*</span> */}
            </Typography>
            <CustomInputDatePicker
              disabled={false}
              placeholderText="dd / mm / yyyy"
              selectedDate={
                this.state.start_date ? new Date(this.state.start_date) : null
              }
              // maxDate={new Date()}
              // minDate={(this?.props?.edit && this.props.oldData?.data?.identification?.[i]?.expiry_date) ? new Date(this.props.oldData?.data?.identification?.[i]?.expiry_date) : new Date()}
              handleChange={(value) => this.onChangeState("start_date", value)}
              inputField={
                <TextInput
                  id={`${parent_id}-start-date-textinput`}
                  fullWidth
                  placeholder="dd / mm / yyyy"
                  variant="outlined"
                  required={true}
                  disabled={false}
                  type={"text"}
                  value={
                    this.state.start_date
                      ? moment(
                        new Date(
                          // utcTOLocal(val?.expiry_date, "MM/DD/YYYY")
                          this.state.start_date
                        )
                      ).format("DD/MM/YYYY")
                      : null
                  }
                  inputProps={{
                    style: {
                      padding: "10.5px 14px",
                      fontFamily: "poppin",
                      fontSize: "12px",
                      borderRadius: 8,
                      borderColor: "#e0e0e0",
                    },
                  }}
                />
              }
            />
          </Col>
          <Col
            id={`${parent_id}-end-date-col`}
            md={4} lg={2} sm={7} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <TextInput
              id={`${parent_id}-end-date-textinput`}
              placeholder="end date"
              onChange={(e) => this.onChangeState("end_date", e.target.value)}
              type="date"
              value={this.state.end_date}
              min={moment(this.state.start_date).format("YYYY-MM-DD")}
              max={"9999-12-31"}
              label="Valid From"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
              helperText={(this.state.start_date?.length > 0 && this.state.end_date?.length > 0) ? checkMaxDateValidation(this.state.start_date, this.state.end_date) ?? " " : " "}
              error={true}
            /> */}
            <Typography
              style={{
                fontFamily: "poppin",
                color: "#6F6F6F",
                fontSize: "12px",
                paddingBottom: "8px",
              }}
            >
              {"Valid From"}
              {/* <span style={{ color: "red" }}>*</span> */}
            </Typography>
            <CustomInputDatePicker
              disabled={false}
              placeholderText="dd / mm / yyyy"
              selectedDate={
                this.state.end_date ? new Date(this.state.end_date) : null
              }
              // maxDate={new Date()}
              minDate={new Date(this.state.start_date)}
              handleChange={(value) => this.onChangeState("end_date", value)}
              inputField={
                <TextInput
                  id={`${parent_id}-end-date-textinput`}
                  fullWidth
                  placeholder="dd / mm / yyyy"
                  variant="outlined"
                  required={true}
                  disabled={false}
                  type={"text"}
                  value={
                    this.state.end_date
                      ? moment(
                        new Date(
                          this.state.end_date
                        )
                      ).format("DD/MM/YYYY")
                      : null
                  }
                  inputProps={{
                    style: {
                      padding: "10.5px 14px",
                      fontFamily: "poppin",
                      fontSize: "12px",
                      borderRadius: 8,
                      borderColor: "#e0e0e0",
                    },
                  }}
                />
              }
            />
          </Col>

          <Col
            id={`${parent_id}-issued-by-col`}
            md={4} lg={2} sm={7} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select showArrow label="pincode" placeholder="select..." labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor="#0071F2" /> */}
            {/* <TextInput
              id={`${parent_id}-issued-by-textinput`}
              placeholder="issued_by"
              onChange={(e) => this.onChangeState("issued_by", e.target.value)}
              value={this.state.issued_by}
              label="Valid To"
              min={moment(this.state.end_date).format("YYYY-MM-DD")}
              max={"9999-12-31"}
              type="date"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
              helperText={(this.state.end_date?.length > 0 && this.state.issued_by?.length > 0) ? checkMaxDateValidation(this.state.end_date, this.state.issued_by) ?? " " : " "}
              error={true}
            /> */}
            <Typography
              style={{
                fontFamily: "poppin",
                color: "#6F6F6F",
                fontSize: "12px",
                paddingBottom: "8px",
              }}
            >
              {"Valid To"}
              {/* <span style={{ color: "red" }}>*</span> */}
            </Typography>
            <CustomInputDatePicker
              disabled={false}
              placeholderText="dd / mm / yyyy"
              selectedDate={
                this.state.issued_by ? new Date(this.state.issued_by) : null
              }
              // maxDate={new Date()}
              minDate={new Date(this.state.end_date)}
              handleChange={(value) => this.onChangeState("issued_by", value)}
              inputField={
                <TextInput
                  id={`${parent_id}-issued-by-textinput`}
                  fullWidth
                  placeholder="dd / mm / yyyy"
                  variant="outlined"
                  required={true}
                  disabled={false}
                  type={"text"}
                  value={
                    this.state.issued_by
                      ? moment(
                        new Date(
                          this.state.issued_by
                        )
                      ).format("DD/MM/YYYY")
                      : null
                  }
                  inputProps={{
                    style: {
                      padding: "10.5px 14px",
                      fontFamily: "poppin",
                      fontSize: "12px",
                      borderRadius: 8,
                      borderColor: "#e0e0e0",
                    },
                  }}
                />
              }
            />
          </Col>
          {this.state?.doc?.[0]?.filename ? (
            <div
              id={`${parent_id}-Filenameui-div`}
              style={{ padding: 10 }}>
              <Filenameui
                parent_id={'addaddress'}
                fileName={this.state?.doc?.[0]?.filename ?? " "}
                delete={() => this.onChangeState("doc", null)}
              />
            </div>
          ) : (
            <div
              id={`${parent_id}-Upload-div`}
              style={{ marginTop: "30px", marginLeft: "15px" }}>
              {/* <UploadFiles /> */}
              <Upload
                id={`${parent_id}-Document-Upload`}
                label="Upload Document"
                className="pi-upload-btn"
                handleChange={(e, value) => this.setDataUpload("doc", value)}
                labelColor="#0071F2"
                iconColor="#0071F2"
                iconSize={14}
                imgUrl="images/uploadBlue.svg"
                labelStyle={{
                  fontSize: 14,
                  fontFamily: "pc_medium !important",
                }}
              />
            </div>
          )}
        </Row>
        <Row
          id={`${parent_id}-buttons-row`}
        >
          <Col
            id={`${parent_id}-buttons-col`}
            className="aa-actions-btn-root" md={12} lg={12} sm={12} xs={12}>
            <Button
              id={`${parent_id}-Cancel-button`}
              className="aa-actions-btn"
              variant="outlined"
              inLineStyles={outlinedButton}
              onClick={() => this.handleClear()}
            >
              Cancel
            </Button>
            <Button
              id={`${parent_id}-Save-button`}
              className="aa-actions-btn"
              variant="outlined"
              backgroundColor="#0071F2"
              inLineStyles={containedButton}
              onClick={() => this.saveAddress()}
            >
              Save
            </Button>
          </Col>
        </Row>
        {/* </Paper> */}
      </Div>
    );
  }
}

export default withAllContexts(PatientQualification);
