import React from "react";
const SVG = ({ width, height, className, fill }) => (
    <svg
        // fill={fill} 
        width={width}
        height={height}
        // viewBox={"0 0 24 17.685"}
        viewBox="0 0 17.205 17.205"
        x="0px" y="0px"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"

    >
        <defs>
           
        </defs>
        <path 
        id="icons8-clock"
        fill={fill ? fill : "#6f6f6f"}
         class="cls-1" d="M10.252,2A8.252,8.252,0,1,0,18.5,10.252,8.258,8.258,0,0,0,10.252,2Zm0,.718a7.535,7.535,0,1,1-7.535,7.535A7.529,7.529,0,0,1,10.252,2.718Zm-.006,1.072a.359.359,0,0,0-.353.364V9.238a1.073,1.073,0,0,0-.612,1.477L7.128,12.869a.359.359,0,1,0,.507.507l2.154-2.153a1.075,1.075,0,1,0,.822-1.984V4.153a.359.359,0,0,0-.364-.364Z" transform="translate(-1.65 -1.65)" />
    </svg>
);
export default SVG;