import React from "react";
import {
  Div,
  Paper,
  //H6,
  Col,
  Row,
  // Select,
  // TextInput,
  Upload,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import { Divider } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { getImgUrl } from "../../../utils";
import LocationCityIcon from "@material-ui/icons/LocationCity";

class UploadProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedOptions: [],
      pic: false,
    };
  }

  // componentDidMount = async () => {};

  styles = {
    divider: {
      marginLeft: "-17px",
      marginRight: "-17px",
      marginTop: "14px",
    },
    paper: {
      margin: "5px 5px 5px 0px !important",
      letterSpacing: "0px",
      color: "#6F6F6F !important",
      backgroundColor: "#00000014",
    },
    uploadButton: { width: "100%", fontSize: "11px", color: "#6F6F6F" },
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
    },
  };

  render() {
    //let { communication } = this.props.patientData;
    const {
      // labelStyle,
      // borderStyle,
      // outlinedButton,
      // containedButton,
      divider,
      // paper,
      // uploadButton,
    } = this.styles;
    const { parent_id } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper"
          style={{ backgroundColor: "#00000014 !important" }}
        >
          {/* {this.props.photo?.[0]?.fileid ? (
            <Avatar
              alt={this.props.photo?.[0]?.fileName}
              variant={"square"}
              style={{ width: "100%", height: "100%" }}
              src={getImgUrl(this.props.photo?.[0]?.fileid)}
            />
          ) : (
            <Upload
              label="Upload Image..."
              className={"pn-image"}
              labelStyle={{
                fontSize: 14,
                letterSpacing: "0px",
                color: "#6F6F6F !important",
                backgroundColor: "#00000014 !important",
                height: "146px",
                width: "150px",
                paddingRight: "10px",
                paddingTop: "63px",
                textAlign: "center",
              }}
              inLineStyles={{ padding: 0, width: "100%" }}
              showIcon={false}
              handleChange={(e, value) =>
                this.props.setDataUpload("photo", value)
              }
              // imgUrl="https://fileupload.dev.ainqaplatform.in/primarycare/10204"
            />
          )} */}
          <div
            id={`${parent_id}-avatar-div`}
            style={{ width: "100%", height: "112px", borderRadius: 5 }}>
            {this.props.photo?.[0]?.fileid ? (
              <Avatar
                id={`${parent_id}-avatar`}
                alt={this.props.photo?.[0]?.fileName}
                variant={"square"}
                style={{ width: "100%", height: "100%" }}
                src={getImgUrl(this.props.photo?.[0]?.fileid)}
              />
            ) : (
              // <Paper
              //   inLineStyles={{ height: "121px !important", margin: "0px" }}
              // >
              //   <div style={paper}></div>
              // </Paper>
              <Avatar
                id={`${parent_id}-LocationCityIcon-avatar`}
                variant="rounded"
                style={{ width: "100%", height: "112px", borderRadius: 5 }}
              >
                <LocationCityIcon
                  id={`${parent_id}-LocationCityIcon`}
                />
              </Avatar>
            )}
          </div>
          <Divider
            id={`${parent_id}-divider`}
            style={divider} />
          <Row
            id={`${parent_id}-Upload-row`}
            className="pi-actions-root">
            <Col
              id={`${parent_id}-Upload-col`}
              md={12} lg={12} sm={12} xs={12} inLineStyles={{ padding: 5 }}>
              {/* <Button
                style={uploadButton}
                onClick={(e, value) => this.props.setDataUpload("photo", value)}
                variant="outlined"
              >
                Upload Image
              </Button> */}
              <Upload
                id={`${parent_id}-Upload`}
                label="Upload Image..."
                className={"pn-image"}
                labelStyle={{
                  margin: 0,
                  color: "#aba9a9",
                  fontSize: "14px",
                  height: "30px",
                  width: "136px",
                  textAlign: "center",
                  borderRadius: "5px",
                  paddingTop: "5px",
                  border: "1px solid #00000014",
                  letterSpacing: "0px",
                }}
                inLineStyles={{
                  padding: 0,
                  width: "100%",
                  //color: "#6F6F6F",
                  //fontSize: "11px",
                }}
                showIcon={false}
                handleChange={(e, value) =>
                  this.props.setDataUpload("photo", value)
                }
              // imgUrl="https://fileupload.dev.ainqaplatform.in/primarycare/10204"
              />
            </Col>
          </Row>
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(UploadProfile);
