import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
// import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import {
  // GenerateForm,
  CommonTable, SearchWithFilter
} from "../../../components";
import { AlertProps } from "../../../utils";
import { AlertContext } from "../../../contexts";

export default function EncounterType(props) {
  const dispatch = useDispatch();
  const { parent_id } = props;
  const alert = React.useContext(AlertContext);
  const listData = useSelector(
    (state) => state?.encounterTypeSlice?.encouterTypeRead?.data
  );
  const loading = useSelector(
    (state) => state?.encounterTypeSlice?.encouterTypeRead?.loading
  );
  //   const saveloading = useSelector(
  //     (state) => state?.encouterTypeSlice?.encouterTypeSlice?.loading
  //   );

  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [code, setCode] = React.useState(false);
  const [desc, setDesc] = React.useState(false)

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.ENCOUNTER_TYPE_READ({ page: (currentPage - 1) * PerPage, perPage: perPage }));
  };

  //   const handleDrawerOpen = () => {
  //     setState({
  //       ...state,
  //       open: true,
  //     });
  //   };
  //   const handleDrawerClose = () => {
  //     setState({
  //       ...state,
  //       open: false,
  //       edit_data: null,
  //     });
  //   };

  React.useLayoutEffect(() => {
    dispatch(actions.ENCOUNTER_TYPE_READ({ page: page, perPage: perPage}));
  }, []);

  //   const savePractitionerRole = async (values) => {
  //     let data = {
  //       code: values.type_code,
  //       description: values.type_description,
  //       type: props.type,
  //       status: true,
  //       id: 0,
  //     };
  //     if (state?.edit_data) {
  //       data["_key"] = state?.edit_data._key;
  //       data["id"] = state?.edit_data.id;
  //       data["status"] = state?.edit_data.status;
  //       data["coding_key"] = state?.edit_data?.coding_key??null;
  //     }

  //     let data_res = await dispatch(actions.PRACTITIONER_ROLE_UPSERT(data));
  //     if(data_res?.payload?.data?.error){
  //       alert.setSnack({
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: data_res?.payload?.data?.validation_error?.[0]?.Errormsg?.replaceAll(/[.*+?^${}()"|[\]\\]/g,'')??"Something went wrong try again",
  //         vertical: AlertProps.vertical.top,
  //         horizontal: AlertProps.horizontal.right,
  //       });
  //     }else{
  //       alert.setSnack({
  //         open: true,
  //         severity: AlertProps.severity.success,
  //         msg: state?.edit_data ? "Updated Successfully" :"Added Successfully",
  //         vertical: AlertProps.vertical.top,
  //         horizontal: AlertProps.horizontal.right,
  //       });
  //     }
  //     await dispatch(actions.PRACTITIONER_ROLE_READ({ type: props.type }));
  //     await handleDrawerClose();
  //   };

  //   const handleEdit = (e, values, index) => {
  //     let data = {
  //       code: values.code_type,
  //       description: values.description,
  //       type: props.type,
  //       status: values.status,
  //       _key: values._key,
  //       id: values.id,
  //       coding_key: values?.coding_key??null
  //     };
  //     setState({
  //       ...state,
  //       edit_data: data,
  //       open: true,
  //     });
  //   };

  const statusChange = async (e, values, index) => {
    let data = {
      status: e.target.checked,
      _key: values._key,
    };
    setState({
      ...state,
      edit_data: values,
    });
    let data_res = await dispatch(actions.ENCOUNTER_TYPE_STATUS_CHANGE(data));
    if (data_res?.payload?.data?.error) {
      alert.setSnack("error");
    } else {
      alert.setSnack("update");
    }
    await dispatch(actions.ENCOUNTER_TYPE_READ({ page: page, perPage: perPage}));
    setState({
      ...state,
      edit_data: null,
    });
  };

  const onSearchChange = (value = "") => {
    if (value?.length > 1) {
      dispatch(actions.ENCOUNTER_TYPE_READ({
        search: value.trim(),
        page: page,
        perPage: perPage
      }));
    } else if (!value) {
      dispatch(actions.ENCOUNTER_TYPE_READ({
        page: page,
        perPage: perPage
      }));
    }
  };

  const handleSort = async (val, key, direction) => {
    let temp_val = ''
    if(val === 'code'){
      temp_val = code
      setCode(!code)
    }else{
      temp_val = desc
      setDesc(!desc)
    }
    await dispatch(actions.ENCOUNTER_TYPE_READ({
      // search: value.trim(),
      page: page,
      perPage: perPage,
      sort: val,
      direction : temp_val ? '' : 'DESC'
    }))
  }

  return (
    <Card id={`${parent_id}-parent-card`} style={{ borderRadius: "12px" }}>
      <div id={`${parent_id}-parent-div`} style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-card`}
          style={{ borderRadius: "12px" }}
        >
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-button-div`}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                id={`${parent_id}-${props.header?.replaceAll(" ", "-")}-div`}
                style={{ marginLeft: "18px" }}
              >
                <p
                  id={`${parent_id}-${props.header?.replaceAll(" ", "-")}-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div style={{ marginTop: "16px", float: "right", display:'flex', marginRight:"20px" }}>

              <SearchWithFilter
                  id="Holiday"
                  placeholder="Search code & description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                  //  onFilterClicked={onFilterClicked}
                  //  isFilterApplied={props?.isFilterApplied}
                  />
                </div>
            </div>

            {/* table */}
            <div
              id={`${parent_id}-circularprogress-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}
            >
              {loading && state.edit_data === null ? (
                <div
                  id={`${parent_id}-circularprogress-div`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "66vh",
                  }}
                >
                  <CircularProgress id={`${parent_id}-circularprogress`} />
                </div>
              ) : (
                <CommonTable
                  parent_id={"practitionerrole"}
                  Header={[
                    "S.No",
                    {label:"Code", showSort: true, sortValue: false, onclick: (value) => handleSort(value, 'code', code)},
                    {label:"Description", showSort: true, sortValue: false, onclick: (value) => handleSort(value, 'desc', desc)},
                    "Encounter Class",
                    "Status",
                    // "Action",
                  ]}
                  dataList={listData}
                  rightAlign={["Status", "Action"]}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "code" },
                    { type: ["TEXT"], name: "shortDesc" },
                    { type: ["TEXT"], name: "encounter_class" },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    // { type: ["EDIT"], name: "", align: "right" },
                  ]}
                  handleCheckBox={statusChange}
                  //   handleEdit={handleEdit}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={listData?.[0]?.total_count}
                  incrementCount={page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}
