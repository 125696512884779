import React from "react";
import { withRouter } from "react-router-dom";
import { actions } from "primary_care_admin_binder";
import { useDispatch } from "react-redux";
import {
  Grid,
  Divider,
  Typography,
  Paper,
  Button,
  Avatar,
  Chip as MuiChip,
} from "@material-ui/core";
import * as ALLMCORE from "@material-ui/core";
import { CreateRule } from "sequence-rule-builder";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import copy from "fast-copy";
// Context
import { withAllContexts } from "../../HOCs";
// Components
import { CustomButton, Autocomplete, TextfieldWithSelect } from "../";
// Icons
import { CreateIcon, TrashIcon } from "../svg";
// Styles
import qdmstyles from "./styles.json";
// Utils
import { isEmptyObject } from "../../utils";

const Chip = withStyles((theme) => ({
  root: {
    borderRadius: "4px",
    height: 24,
    backgroundColor: "#DEE5EC",
  },
  deleteIcon: {
    height: 16,
    width: 16,
  },
}))(MuiChip);

const useStyles = makeStyles((theme) => ({
  removeGutterRight: {
    "&.MuiAutocomplete-hasPopupIcon&.MuiAutocomplete-hasClearIcon": {
      "& .MuiAutocomplete-inputRoot": {
        paddingRight: 30,
      },
    },
  },
  multiAutoComplete: {
    "& .MuiAutocomplete-inputRoot": {
      position: "relative",
      gap: 0,
      "& .MuiInputAdornment-root": {
        position: "absolute",
        right: 2,
      },
    },
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& > .MuiTypography-root": {
      fontSize: 12,
      fontWeight: 400,
      color: "#6A7888",
    },
  },
}));

const { RULE_GROUP_DROPDOWN } = actions;

const initialFrequencyRulesState = {
  id: 0,
  freqcode: "",
  durationcode: 0,
  durnUOM: "",
  doseval: 0,
  doseUOM: "",
  InfuseRateval: 0,
  InfuseRateUom: "",
  InfuseOverDurval: 0,
  InfuseOverDurType: "",
  Criteria: [],
};

const initialCriteriaRuleState = {
  editId: [],
  isRule: false,
  isExist: false,
  isOpen: false,
  group: {},
  rule: {},
};

function Orderdetailscomp(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    data,
    index,
    options,
    setFun = () => {},
    stateName,
    statevalue,
  } = props;

  const [ruleState, setRuleState] = React.useState({
    editId: [],
    isRule: false,
    isExist: false,
    isOpen: false,

    // Existing
    groupData: [],
    ruleData: [],
    group: {},
    rule: {},
  });
  const [state, setState] = React.useState({
    permissionData: null,
    showAdd: false,
    editIndex: "",

    ...initialFrequencyRulesState,

    // Local State
    FrequencyRules: props?.statevalue?.FrequencyRules || [],
  });

  const changeState = (key, value) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const changeRuleState = (key, value) => {
    setRuleState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  React.useEffect(() => {
    if (statevalue?.FrequencyRules?.length === 0) {
      changeState("showAdd", true);
    }
  }, [statevalue]);

  React.useEffect(() => {
    Promise.resolve(dispatch(RULE_GROUP_DROPDOWN()))
      .then((res) => {
        const groupOptions = res?.payload?.result;
        changeRuleState("groupData", groupOptions);
      })
      .catch((e) => console.error(e));
  }, []);

  const handleCancelAddClick = () => {
    resetDataState();
    changeState("showAdd", false);
  };

  const handleAddNewClick = () => {
    changeState("showAdd", true);
  };

  const saveNewFrequencyRule = () => {};

  const resetDataState = () => {
    setState((prev) => ({
      ...prev,
      ...initialFrequencyRulesState,
      editIndex: "",
    }));
    setRuleState((prev) => ({
      ...prev,
      ...initialCriteriaRuleState,
    }));
  };

  const handleSaveClick = (editIndex, addAnother) => {
    const {
      freqcode,
      durationcode,
      durnUOM,
      doseval,
      doseUOM,
      InfuseRateval,
      InfuseRateUom,
      InfuseOverDurval,
      InfuseOverDurType,
      // Criteria,
    } = state;

    const { editId: Criteria } = ruleState;
    if (editIndex) {
      // Take the previous array
      let newFrequencyRules = [...state?.FrequencyRules];

      // Update the index element with new values
      newFrequencyRules[editIndex] = {
        ...newFrequencyRules[editIndex],
        freqcode,
        durationcode,
        durnUOM,
        doseval,
        doseUOM,
        InfuseRateval,
        InfuseRateUom,
        InfuseOverDurval,
        InfuseOverDurType,
        Criteria,
      };

      // Update State
      setState((prev) => ({
        ...prev,
        FrequencyRules: newFrequencyRules,
        ...copy(initialFrequencyRulesState),
        showAdd: addAnother,
        editIndex: "",
      }));

      setRuleState((prev) => ({
        ...prev,
        ...copy(initialCriteriaRuleState),
      }));

      // Save value to parent
      props.setFun(stateName, "FrequencyRules", newFrequencyRules);
    } else {
      // Create New Object for add
      let newFrequencyRuleState = {
        ...initialFrequencyRulesState,
        freqcode,
        durationcode,
        durnUOM,
        doseval,
        doseUOM,
        InfuseRateval,
        InfuseRateUom,
        InfuseOverDurval,
        InfuseOverDurType,
        Criteria,
      };

      // Append to existing array
      let newFrequencyRules = [...state?.FrequencyRules, newFrequencyRuleState];

      // Update State
      setState((prev) => ({
        ...prev,
        FrequencyRules: newFrequencyRules,
        ...copy(initialFrequencyRulesState),
        showAdd: addAnother,
      }));

      setRuleState((prev) => ({
        ...prev,
        ...copy(initialCriteriaRuleState),
      }));

      // Save value to parent
      props.setFun(stateName, "FrequencyRules", newFrequencyRules);
    }

    // // 2. Reset Data State
    // resetDataState();

    // // 3. Close Add
    // if (!addAnother) {
    //   changeState("showAdd", false);
    // }
  };

  const handleEditClick = (index) => {
    let newFrequencyRules = [...state?.FrequencyRules];
    let newFrequencyRuleState = newFrequencyRules[index];

    setState((prev) => ({
      ...prev,
      freqcode: newFrequencyRuleState?.freqcode,
      durationcode: newFrequencyRuleState?.durationcode,
      durnUOM: newFrequencyRuleState?.durnUOM,
      doseval: newFrequencyRuleState?.doseval,
      doseUOM: newFrequencyRuleState?.doseUOM,
      InfuseRateval: newFrequencyRuleState?.InfuseRateval,
      InfuseRateUom: newFrequencyRuleState?.InfuseRateUom,
      InfuseOverDurval: newFrequencyRuleState?.InfuseOverDurval,
      InfuseOverDurType: newFrequencyRuleState?.InfuseOverDurType,
      // Criteria: newFrequencyRuleState?.Criteria,

      // Open the modal
      showAdd: true,

      // Set Edit Index
      editIndex: index?.toString(),
    }));

    setRuleState((prev) => ({
      ...prev,
      editId: newFrequencyRuleState?.Criteria,
      isOpen: newFrequencyRuleState?.Criteria?.length > 0,
    }));

    // handleModalOpen(index);
  };

  const handleDeleteClick = (index) => {
    let newFrequencyRules = [...state?.FrequencyRules];
    newFrequencyRules.splice(index, 1);
    changeState("FrequencyRules", newFrequencyRules);

    // Save value to parent
    props.setFun(stateName, "FrequencyRules", newFrequencyRules);
  };

  const setRuleBuilderAction = (data) => {
    if (!data) {
      setRuleState((prev) => ({
        ...prev,
        isRule: !prev?.isRule,
      }));
      return;
    }

    setRuleState((prev) => ({
      ...prev,
      editId: [data?.Result?.[0]?.properties?.doc],
      isRule: !prev.isRule,
      isOpen: true,
    }));
  };

  // Existing Rule Set
  const handleGroupStateChange = (value) => {
    Promise.resolve(dispatch(RULE_GROUP_DROPDOWN({ groupId: value?.value })))
      .then((res) => {
        changeRuleState("ruleData", res?.payload?.result);
        // setRuleState((prev) => ({
        //   ...prev,
        //   group: value,
        //   ruleData: res?.payload?.result,
        // }));
      })
      .catch((e) => {
        // setRuleState((prev) => ({
        //   ...prev,
        //   group: value,
        // }));
        console.error(e);
      })
      .finally(() => {
        changeRuleState("group", value);
      });
  };

  const handleExistingRuleSave = () => {
    const { group, rule } = ruleState;
    if (isEmptyObject(group)) {
      return props.alert.setSnack({
        open: true,
        msg: `Please choose the group`,
        severity: "error",
      });
    }
    if (isEmptyObject(rule)) {
      return props.alert.setSnack({
        open: true,
        msg: `Please choose the rule`,
        severity: "error",
      });
    }

    setRuleState((prev) => ({
      ...prev,
      editId: [
        {
          group: group,
          rule: rule,
        },
      ],
      isExist: false,
      isOpen: true,
      group: {},
      rule: {},
    }));
  };

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"DIAYz"}
        container={true}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        item={true}
        lg={"12"}
        md={"12"}
        sm={"12"}
        xl={"12"}
        xs={"12"}
      >
        {/* Routes Allowed + Default Route Section Starts */}
        <Grid
          key={"1"}
          id={"CZVZN"}
          container={true}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"12"}
          item={true}
          md={"12"}
          sm={"12"}
          spacing={1}
          xl={"12"}
          xs={"12"}
          style={qdmstyles.vFYR}
        >
          <Grid
            key={"0"}
            id={"2MXs9"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
            lg={"9"}
            md={"9"}
            sm={"9"}
            xl={"9"}
            xs={"9"}
            style={qdmstyles.ixFX}
          >
            <Grid
              key={"0"}
              id={"d2nWC"}
              container={""}
              direction={"column"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              item={true}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
              style={qdmstyles.LLDye}
            >
              <Typography
                key={"0"}
                id={"routesAllowedHeading_typogrpahy"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"Routes Allowed"}
                aliasName={"routesAllowedHeading"}
                style={qdmstyles.oJhzE}
              />
            </Grid>
            <Grid
              key={"1"}
              id={"d2nWC"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
            >
              <Autocomplete
                id={"XhCxn"}
                style={qdmstyles?.XhCxn}
                // Controllers
                getOptionLabel={(option) => option?.["label"]}
                options={options?.routes_allowed ?? []}
                multiple
                className={classNames(
                  classes.removeGutterRight,
                  classes.multiAutoComplete
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option?.label}
                      deleteIcon={<CloseIcon htmlColor="#6A7888" />}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                value={statevalue?.RoutesAllowed}
                onLoad={() =>
                  setFun(stateName, "RoutesAllowed", statevalue?.RoutesAllowed)
                }
                onChange={(event, newValue) =>
                  setFun(stateName, "RoutesAllowed", newValue)
                }
              />
            </Grid>
          </Grid>
          <Grid
            key={"1"}
            id={"2MXs9"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
            lg={"3"}
            sm={"3"}
            md={"3"}
            xl={"3"}
            xs={"3"}
          >
            <Grid
              key={"0"}
              id={"d2nWC"}
              container={""}
              direction={"column"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              item={true}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
              style={qdmstyles.UY}
            >
              <Typography
                key={"0"}
                id={"defaultRouteHeading_Typography"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"Default Route"}
                aliasName={"defaultRouteHeading"}
                style={qdmstyles.PoAZ}
              />
            </Grid>
            <Grid
              key={"1"}
              id={"d2nWC"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
            >
              <Autocomplete
                id={"i43fB"}
                style={qdmstyles?.ifB}
                // Controllers
                getOptionLabel={(option) => option?.["label"]}
                options={options?.default_route ?? []}
                className={classes.removeGutterRight}
                value={statevalue?.DefaultRouteCode}
                onLoad={() =>
                  setFun(
                    stateName,
                    "DefaultRouteCode",
                    statevalue?.DefaultRouteCode
                  )
                }
                onChange={(event, newValue) =>
                  setFun(stateName, "DefaultRouteCode", newValue)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Routes Allowed + Default Route Section Ends */}

        {/* Divider Section Starts */}
        <Grid
          key={"2"}
          id={"n1RM4"}
          container={""}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          lg={"12"}
        >
          <Divider
            key={"0"}
            id={"yohAt"}
            variant={"fullWidth"}
            flexItem={""}
            orientation={"horizontal"}
            light={true}
            style={qdmstyles.divider}
          />
        </Grid>
        {/* Divider Section Ends */}

        {/* Add new frequency Defaults Section Starts */}
        {state?.showAdd && (
          <Grid
            key={"3"}
            id={"b7AAp"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
            style={{ marginBottom: 16 }}
          >
            <Grid
              key={"0"}
              id={"pULoO"}
              container={true}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              lg={"auto"}
              item={true}
              md={"auto"}
              sm={"auto"}
              xl={"auto"}
              xs={"auto"}
              style={qdmstyles.xHdJK}
            >
              <Grid item>
                <Typography
                  key={"0"}
                  id={"addNewFrequencyHeading_typography"}
                  align={"inherit"}
                  color={"primary"}
                  display={"initial"}
                  variant={"body1"}
                  children={"ADD NEW FREQUENCY DEFAULTS"}
                  aliasName={"addNewFrequencyHeading"}
                  style={qdmstyles.JzD}
                />
              </Grid>
              {statevalue?.FrequencyRules?.length > 0 && (
                <Grid item>
                  <Button
                    key={"0"}
                    id={"cancel_add_new_button"}
                    onClick={handleCancelAddClick}
                    variant={"text"}
                    color={"primary"}
                    children={"Cancel"}
                    aliasName={"statusaddnewbutton"}
                    style={qdmstyles.KIyuwwasK}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              key={"1"}
              id={"0NqD2"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              item={true}
              lg={"12"}
              style={qdmstyles.RptnI}
            >
              <Paper
                key={"0"}
                id={"A4OWh"}
                elevation={""}
                variant={"elevation"}
                style={qdmstyles.szBGC}
              >
                <Grid
                  key={"0"}
                  id={"0wzSg"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  style={qdmstyles.jQG}
                >
                  <Grid
                    key={"0"}
                    id={"AySG3"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    item={true}
                    style={qdmstyles.wbZg}
                  >
                    <Typography
                      key={"0"}
                      id={
                        "definecriteriabypateintandPharamacyHeading_Typography"
                      }
                      align={"inherit"}
                      color={"primary"}
                      display={"initial"}
                      variant={"body1"}
                      children={"DEFINE CRITERIA BY PATIENT AND PHARMACY"}
                      aliasName={"definecriteriabypateintandPharamacyHeading"}
                      style={qdmstyles.usJu}
                    />
                  </Grid>
                  {ruleState?.isOpen ? (
                    <Grid container spacing={2} style={{ margin: "0px 16px" }}>
                      <Grid item xs>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <div>
                            <Avatar
                              key={"0"}
                              id={"group_icon"}
                              alt={"Avatar"}
                              variant={"square"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317031738"
                              }
                              aliasName={"groupicon"}
                              style={{
                                width: "30px",
                                height: "30px",
                                background: "red",
                                padding: "7px",
                                borderRadius: "50%",
                                marginRight: "4px",
                                backgroundColor: "#f1f6fc",
                              }}
                            ></Avatar>
                          </div>
                          <div>
                            FREQUENCY DEFAULTS
                            <br />
                            PATIENT AND PHARMACY
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Avatar
                              key={"0"}
                              id={"group_icon"}
                              alt={"Avatar"}
                              variant={"square"}
                              src={
                                "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/317006791"
                              }
                              aliasName={"groupicon"}
                              style={{
                                width: "30px",
                                height: "30px",
                                background: "red",
                                padding: "7px",
                                borderRadius: "50%",
                                marginRight: "4px",
                                backgroundColor: "#f1f6fc",
                              }}
                            />
                          </div>
                          <div>
                            {ruleState.editId?.[0]?.ruleName ||
                              ruleState.editId?.[0]?.group?.label ||
                              ""}
                            <br />
                            {ruleState.editId?.[0]?.priority ||
                              ruleState.editId?.[0]?.rule?.label ||
                              ""}
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            setRuleState((prev) => ({
                              ...prev,
                              ...copy(initialCriteriaRuleState),
                              isOpen: !prev.isOpen,
                            }));
                          }}
                          style={qdmstyles.dLEbQ2}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      key={"1"}
                      id={"h6VuZ"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      lg={"12"}
                      item={""}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      style={qdmstyles.CBPgO}
                    >
                      {ruleState?.isExist && (
                        <Grid
                          id={"i1kt4"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          style={qdmstyles.ikt}
                          spacing={2}
                        >
                          <Grid
                            key={"1"}
                            id={"V7v9F"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"4"}
                            md={"4"}
                            sm={"4"}
                            xl={"4"}
                            xs={"4"}
                            item={true}
                          >
                            <div className={classes.fieldContainer}>
                              <Typography
                                id={"Group_Typography"}
                                children={"Group"}
                              />
                              <Autocomplete
                                // value={this.state?.group ?? {}}
                                // getOptionLabel={(option) => option?.["label"]}
                                // options={this.state?.groupData ?? []}
                                // size={"small"}
                                // onChange={(e, newvalue) =>
                                //   this.handleChange(newvalue, "group")
                                // }
                                // renderInput={(params) => (
                                //   <ALLMCORE.TextField
                                //     {...params}
                                //     margin="dense"
                                //     variant="outlined"
                                //     label="Group"
                                //   />
                                // )}
                                id={"r9IiL"}
                                style={qdmstyles?.rIiL}
                                getOptionLabel={(option) => option?.["label"]}
                                options={ruleState?.groupData ?? []}
                                value={ruleState?.group}
                                onLoad={() =>
                                  changeRuleState("group", ruleState?.group)
                                }
                                onChange={(event, newValue) =>
                                  handleGroupStateChange(newValue)
                                }
                              />
                            </div>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"V7v9F"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"4"}
                            md={"4"}
                            sm={"4"}
                            xl={"4"}
                            xs={"4"}
                            item={true}
                          >
                            <div className={classes.fieldContainer}>
                              <Typography
                                id={"Rule_Typography"}
                                children={"Rule"}
                              />
                              <Autocomplete
                                // value={this.state?.rule ?? {}}
                                // getOptionLabel={(option) => option?.["label"]}
                                // options={this.state?.ruleData ?? []}
                                // size={"small"}
                                // onChange={(e, newvalue) =>
                                //   this.handleChange(newvalue, "rule")
                                // }
                                // renderInput={(params) => (
                                //   <ALLMCORE.TextField
                                //     {...params}
                                //     margin="dense"
                                //     variant="outlined"
                                //     label="Rule"
                                //   />
                                // )}
                                id={"lksad93"}
                                style={qdmstyles?.rIiL}
                                getOptionLabel={(option) => option?.["label"]}
                                options={ruleState?.ruleData ?? []}
                                value={ruleState?.rule}
                                onLoad={() =>
                                  changeRuleState("rule", ruleState?.rule)
                                }
                                onChange={(event, newValue) =>
                                  changeRuleState("rule", newValue)
                                }
                              />
                            </div>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"V7v339F"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"4"}
                            md={"4"}
                            sm={"4"}
                            xl={"4"}
                            xs={"4"}
                            item={true}
                          >
                            <div className={classes.fieldContainer}>
                              <Typography
                                style={{ opacity: 0, userSelect: "none" }}
                              >
                                cta
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 8,
                                }}
                              >
                                <CustomButton
                                  variant={"contained"}
                                  color="#FFFFFF"
                                  backgroundColor="#3f51b5"
                                  onClick={() => handleExistingRuleSave()}
                                >
                                  Save
                                </CustomButton>
                                <CustomButton
                                  variant="text"
                                  color="#2A3C50"
                                  backgroundColor="#DEE5EC"
                                  onClick={(e) => {
                                    setRuleState((prev) => {
                                      return {
                                        ...prev,
                                        isExist: !prev.isExist,
                                      };
                                    });
                                  }}
                                >
                                  Cancel
                                </CustomButton>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      )}

                      {!ruleState?.isExist && (
                        <Grid item container xs={12}>
                          <Grid
                            key={"0"}
                            id={"wDQNk"}
                            container={""}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            item={true}
                            lg={"auto"}
                            style={qdmstyles.JUvC}
                          >
                            <Button
                              key={"0"}
                              id={"addNewRuleSetButton_button"}
                              variant={"text"}
                              color={"primary"}
                              children={"Add New Rule Set"}
                              aliasName={"addNewRuleSetButton"}
                              style={qdmstyles.uoSZ}
                              onClick={() => {
                                setRuleState((prev) => {
                                  return {
                                    ...prev,
                                    isRule: !prev.isRule,
                                  };
                                });
                              }}
                            ></Button>
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"CU1k8"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Typography
                              key={"0"}
                              id={"OrHeading_Typography"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={"(or)"}
                              aliasName={"OrHeading"}
                            ></Typography>
                          </Grid>
                          <Grid
                            key={"2"}
                            id={"DWbgk"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"auto"}
                            style={qdmstyles.VzQap}
                          >
                            <Button
                              key={"0"}
                              id={"addExistingRuleSetButton_Button"}
                              variant={"text"}
                              color={"primary"}
                              children={"Add Existing Rule Set"}
                              aliasName={"addExistingRuleSetButton"}
                              style={qdmstyles.ViHd}
                              onClick={(e) => {
                                setRuleState((prev) => {
                                  return {
                                    ...prev,
                                    isExist: !prev.isExist,
                                  };
                                });
                              }}
                            ></Button>
                          </Grid>
                        </Grid>
                      )}

                      <CreateRule
                        // editId="Rules/10719"
                        editId={ruleState.editId?.[0]?._id}
                        editSchema={ruleState.editId?.[0]}
                        dbName={"primarycareng"}
                        open={ruleState.isRule}
                        handleClose={(params, e) =>
                          setRuleBuilderAction(params ? params : false)
                        }
                      />
                    </Grid>
                  )}
                  <Grid
                    key={"2"}
                    id={"6nXk1"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    style={qdmstyles.kjKZp}
                  >
                    <Divider
                      key={"0"}
                      id={"aL6n6"}
                      variant={"fullWidth"}
                      orientation={"horizontal"}
                      light={""}
                      flexItem={""}
                      absolute={""}
                    ></Divider>
                  </Grid>
                  <Grid
                    key={"3"}
                    id={"C2Q2C"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    style={qdmstyles.zMYKr}
                  >
                    <Grid
                      key={"0"}
                      id={"A0aTF"}
                      container={true}
                      direction={"row"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                    >
                      <Grid
                        key={"0"}
                        id={"K8jKe"}
                        container={true}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        lg={"10"}
                        spacing={"2"}
                        md={"10"}
                        sm={"10"}
                        xl={"10"}
                        xs={"10"}
                      >
                        <Grid
                          key={"0"}
                          id={"MwW3q"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"4"}
                          item={true}
                          md={"4"}
                          sm={"4"}
                          xl={"4"}
                          xs={"4"}
                          style={qdmstyles.TcBm}
                        >
                          <Typography
                            key={"0"}
                            id={"TI1yl"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Frequency"}
                            style={qdmstyles.NvVfV}
                          />
                          <Autocomplete
                            id={"r9IiL"}
                            style={qdmstyles?.rIiL}
                            getOptionLabel={(option) =>
                              option
                                ? `${option?.["freqcode"]} - ${option?.["label"]}`
                                : ""
                            }
                            options={options?.frequency ?? []}
                            value={state?.freqcode}
                            onLoad={() =>
                              changeState("freqcode", state?.freqcode)
                            }
                            onChange={(event, newValue) =>
                              changeState("freqcode", newValue)
                            }
                          />
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"XcWnj"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"2"}
                          item={true}
                          md={"4"}
                          sm={"4"}
                          xl={"2"}
                          xs={"4"}
                        >
                          <Typography
                            key={"0"}
                            id={"b6j0B"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Duration Code & Uom"}
                            style={qdmstyles.OZUK}
                          />
                          <Grid
                            key={"1"}
                            id={"mTFOI"}
                            container={true}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.jYGK}
                          >
                            {/* <Grid
                                key={"0"}
                                id={"56Ktj"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"5"}
                                md={"5"}
                                sm={"5"}
                                xl={"5"}
                                xs={"5"}
                                item={true}
                                style={qdmstyles.JGib}
                              >
                                <TextField
                                  key={"0"}
                                  id={"KOrzv"}
                                  label={""}
                                  placeholder={""}
                                  type={"text"}
                                  variant={"outlined"}
                                  fullWidth={true}
                                  margin={"none"}
                                  size="small"
                                ></TextField>
                              </Grid>
                              <Divider
                                key={"1"}
                                id={"i68WA"}
                                variant={"fullWidth"}
                                flexItem={true}
                                orientation={"vertical"}
                                style={qdmstyles.qFJy}
                              ></Divider>
                              <Grid
                                key={"2"}
                                id={"VtLuv"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"5"}
                                md={"5"}
                                sm={"5"}
                                xl={"5"}
                                xs={"5"}
                                item={true}
                              >
                                <Autocomplete
                                  id={"4hHRU"}
                                  style={qdmstyles?.hHRU}
                                  value={state?.orderdetailscomp_4hhru ?? null}
                                  onLoad={() =>
                                    setState({
                                      orderdetailscomp_4hhru:
                                        state?.orderdetailscomp_4hhru ?? null,
                                    })
                                  }
                                  size="small"
                                  getOptionLabel={(option) => option?.[""]}
                                  options={[] ?? []}
                                  renderInput={(params) => (
                                    <ALLMCORE.TextField
                                      {...params}
                                      label={""}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid> */}

                            <TextfieldWithSelect
                              InputProps={{
                                id: `DurationCode`,
                                type: "text",
                                fullWidth: true,
                                inputProps: {
                                  maxLength: 6,
                                },
                                onKeyPress: (event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                },
                                value: state?.durationcode,
                                onChange: (event) =>
                                  changeState(
                                    "durationcode",
                                    event.target.value
                                      ? parseInt(event.target.value)
                                      : 0
                                  ),
                              }}
                              AutocompleteProps={{
                                id: `DurationUOM`,
                                getOptionLabel: (option) => option?.["label"],
                                options: options?.drug_uom ?? [],
                                value: state?.durnUOM,
                                onLoad: () =>
                                  changeState("durnUOM", state?.durnUOM),
                                onChange: (event, newValue) =>
                                  changeState("durnUOM", newValue),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          key={"2"}
                          id={"XcWnj"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"2"}
                          item={true}
                          md={"4"}
                          sm={"4"}
                          xl={"2"}
                          xs={"4"}
                        >
                          <Typography
                            key={"0"}
                            id={"b6j0B"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Dose Value & Uom"}
                            style={qdmstyles.ir}
                          />
                          <Grid
                            key={"1"}
                            id={"mTFOI"}
                            container={true}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.piT}
                          >
                            {/* <Grid
                                key={"0"}
                                id={"56Ktj"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"5"}
                                md={"5"}
                                sm={"5"}
                                xl={"5"}
                                xs={"5"}
                                item={true}
                                style={qdmstyles.PO}
                              >
                                <TextField
                                  key={"0"}
                                  id={"KOrzv"}
                                  label={""}
                                  placeholder={""}
                                  type={"text"}
                                  variant={"outlined"}
                                  fullWidth={true}
                                  size="small"
                                ></TextField>
                              </Grid>
                              <Divider
                                key={"1"}
                                id={"i68WA"}
                                variant={"fullWidth"}
                                flexItem={true}
                                orientation={"vertical"}
                                style={qdmstyles.mp}
                              ></Divider>
                              <Grid
                                key={"2"}
                                id={"VtLuv"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"5"}
                                md={"5"}
                                sm={"5"}
                                xl={"5"}
                                xs={"5"}
                                item={true}
                              >
                                <Autocomplete
                                  id={"N4cBF"}
                                  style={qdmstyles?.NcBF}
                                  value={state?.orderdetailscomp_n4cbf ?? null}
                                  onLoad={() =>
                                    setState({
                                      orderdetailscomp_n4cbf:
                                        state?.orderdetailscomp_n4cbf ?? null,
                                    })
                                  }
                                  size="small"
                                  getOptionLabel={(option) => option?.[""]}
                                  options={[] ?? []}
                                  renderInput={(params) => (
                                    <ALLMCORE.TextField
                                      {...params}
                                      label={""}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid> */}
                            <TextfieldWithSelect
                              InputProps={{
                                id: `DoseValue`,
                                type: "text",
                                fullWidth: true,
                                inputProps: {
                                  maxLength: 6,
                                },
                                onKeyPress: (event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                },
                                value: state?.doseval,
                                onChange: (event) =>
                                  changeState(
                                    "doseval",
                                    event.target.value
                                      ? parseInt(event.target.value)
                                      : 0
                                  ),
                              }}
                              AutocompleteProps={{
                                id: `DoseUOM`,
                                getOptionLabel: (option) => option?.["label"],
                                options: options?.drug_uom ?? [],
                                value: state?.doseUOM,
                                onLoad: () =>
                                  changeState("doseUOM", state?.doseUOM),
                                onChange: (event, newValue) =>
                                  changeState("doseUOM", newValue),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          key={"3"}
                          id={"XcWnj"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"2"}
                          item={true}
                          md={"6"}
                          sm={"6"}
                          xl={"2"}
                          xs={"6"}
                        >
                          <Typography
                            key={"0"}
                            id={"b6j0B"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Infusion Rate & Uom"}
                            style={qdmstyles.HGhh}
                          />
                          <Grid
                            key={"1"}
                            id={"mTFOI"}
                            container={true}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.lJmR}
                          >
                            {/* <Grid
                                key={"0"}
                                id={"56Ktj"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"5"}
                                md={"5"}
                                sm={"5"}
                                xl={"5"}
                                xs={"5"}
                                item={true}
                                style={qdmstyles.yfdK}
                              >
                                <TextField
                                  key={"0"}
                                  id={"KOrzv"}
                                  label={""}
                                  placeholder={""}
                                  type={"text"}
                                  variant={"outlined"}
                                  fullWidth={true}
                                  size="small"
                                ></TextField>
                              </Grid>
                              <Divider
                                key={"1"}
                                id={"i68WA"}
                                variant={"fullWidth"}
                                flexItem={true}
                                orientation={"vertical"}
                                style={qdmstyles.QKY}
                              ></Divider>
                              <Grid
                                key={"2"}
                                id={"VtLuv"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"5"}
                                md={"5"}
                                sm={"5"}
                                xl={"5"}
                                xs={"5"}
                                item={true}
                              >
                                <Autocomplete
                                  id={"dbedu"}
                                  style={qdmstyles?.dbedu}
                                  value={state?.orderdetailscomp_dbedu ?? null}
                                  onLoad={() =>
                                    setState({
                                      orderdetailscomp_dbedu:
                                        state?.orderdetailscomp_dbedu ?? null,
                                    })
                                  }
                                  size="small"
                                  getOptionLabel={(option) => option?.[""]}
                                  options={[] ?? []}
                                  renderInput={(params) => (
                                    <ALLMCORE.TextField
                                      {...params}
                                      label={""}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid> */}
                            <TextfieldWithSelect
                              InputProps={{
                                id: `InfusionRate`,
                                type: "text",
                                fullWidth: true,
                                inputProps: {
                                  maxLength: 6,
                                },
                                onKeyPress: (event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                },
                                value: state?.InfuseRateval,
                                onChange: (event) =>
                                  changeState(
                                    "InfuseRateval",
                                    event.target.value
                                      ? parseInt(event.target.value)
                                      : 0
                                  ),
                              }}
                              AutocompleteProps={{
                                id: `InfusionUOM`,
                                getOptionLabel: (option) => option?.["label"],
                                options: options?.drug_uom ?? [],
                                value: state?.InfuseRateUom,
                                onLoad: () =>
                                  changeState(
                                    "InfuseRateUom",
                                    state?.InfuseRateUom
                                  ),
                                onChange: (event, newValue) =>
                                  changeState("InfuseRateUom", newValue),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          key={"4"}
                          id={"XcWnj"}
                          container={""}
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          lg={"2"}
                          item={true}
                          md={"6"}
                          sm={"6"}
                          xl={"2"}
                          xs={"6"}
                        >
                          <Typography
                            key={"0"}
                            id={"b6j0B"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Infuse Over Duration & Uom"}
                            style={qdmstyles.kGTbK}
                          />
                          <Grid
                            key={"1"}
                            id={"mTFOI"}
                            container={true}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"12"}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            style={qdmstyles.MkFF}
                          >
                            {/* <Grid
                                key={"0"}
                                id={"56Ktj"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"5"}
                                md={"5"}
                                sm={"5"}
                                xl={"5"}
                                xs={"5"}
                                item={true}
                                style={qdmstyles.yFOl}
                              >
                                <TextField
                                  key={"0"}
                                  id={"KOrzv"}
                                  label={""}
                                  placeholder={""}
                                  type={"text"}
                                  variant={"outlined"}
                                  fullWidth={true}
                                  size="small"
                                ></TextField>
                              </Grid>
                              <Divider
                                key={"1"}
                                id={"i68WA"}
                                variant={"fullWidth"}
                                flexItem={true}
                                orientation={"vertical"}
                                style={qdmstyles.EODfM}
                              ></Divider>
                              <Grid
                                key={"2"}
                                id={"VtLuv"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"5"}
                                md={"5"}
                                sm={"5"}
                                xl={"5"}
                                xs={"5"}
                                item={true}
                              >
                                <Autocomplete
                                  id={"Ase2R"}
                                  style={qdmstyles?.AseR}
                                  value={state?.orderdetailscomp_ase2r ?? null}
                                  onLoad={() =>
                                    setState({
                                      orderdetailscomp_ase2r:
                                        state?.orderdetailscomp_ase2r ?? null,
                                    })
                                  }
                                  size="small"
                                  getOptionLabel={(option) => option?.[""]}
                                  options={[] ?? []}
                                  renderInput={(params) => (
                                    <ALLMCORE.TextField
                                      {...params}
                                      label={""}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid> */}
                            <TextfieldWithSelect
                              InputProps={{
                                id: `InfusionOverDurationRate`,
                                type: "text",
                                fullWidth: true,
                                inputProps: {
                                  maxLength: 6,
                                },
                                onKeyPress: (event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                },
                                value: state?.InfuseOverDurval,
                                onChange: (event) =>
                                  changeState(
                                    "InfuseOverDurval",
                                    event.target.value
                                      ? parseInt(event.target.value)
                                      : 0
                                  ),
                              }}
                              AutocompleteProps={{
                                id: `InfusionOverDurationUOM`,
                                getOptionLabel: (option) => option?.["label"],
                                options: options?.drug_uom ?? [],
                                value: state?.InfuseOverDurType,
                                onLoad: () =>
                                  changeState(
                                    "InfuseOverDurType",
                                    state?.InfuseOverDurType
                                  ),
                                onChange: (event, newValue) =>
                                  changeState("InfuseOverDurType", newValue),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    key={"4"}
                    id={"iRF0G"}
                    container={true}
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    item={true}
                    style={qdmstyles.UVxYh}
                  >
                    <Grid
                      key={"0"}
                      id={"Bf7YT"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"4"}
                      item={true}
                      style={qdmstyles.RfC}
                    >
                      <Button
                        key={"0"}
                        id={"MoXx0"}
                        variant={"text"}
                        color={"primary"}
                        style={qdmstyles.dLEbQ}
                        onClick={() => handleSaveClick(state?.editIndex, true)}
                      >
                        {state?.editIndex
                          ? "Update & Add another"
                          : "Save & Add another"}
                      </Button>
                      <Button
                        key={"1"}
                        id={"MoXx0"}
                        variant={"contained"}
                        color={"primary"}
                        size={"small"}
                        style={qdmstyles.ihHC}
                        onClick={() => handleSaveClick(state?.editIndex, false)}
                      >
                        {state?.editIndex ? "Update" : "Add"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
        {/* Add new frequency Defaults Section Ends */}

        {/* Frequency Defaults - Add new Section Starts */}
        {statevalue?.FrequencyRules?.length > 0 && (
          <Grid
            key={"4"}
            id={"ncT2W"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              key={"0"}
              id={"TZfNs"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
            >
              <Paper
                key={"0"}
                id={"bx6t7"}
                elevation={"{3}"}
                style={{
                  ...qdmstyles.bxt,
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 10,
                }}
              >
                <Grid
                  key={"0"}
                  id={"BTGwG"}
                  container={true}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  style={qdmstyles.BTGwG}
                >
                  <Grid
                    key={"0"}
                    id={"mCpCY"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"4"}
                    item={true}
                  >
                    <Typography
                      key={"0"}
                      id={"1LrwM"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"frequency defaults"}
                      style={{ ...qdmstyles.LrwM, margin: "12px 0px 6px" }}
                    ></Typography>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"Xtuss"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"4"}
                    item={true}
                    style={qdmstyles.Xtuss}
                  >
                    <Button
                      key={"0"}
                      id={"T8AoZ"}
                      variant={"text"}
                      color={"primary"}
                      children={"+ Add new"}
                      style={qdmstyles.TAoZ}
                      onClick={handleAddNewClick}
                    ></Button>
                  </Grid>
                </Grid>
                {statevalue?.FrequencyRules?.map((item, index) => {
                  const rowState = state?.FrequencyRules[index];
                  return (
                    <Grid
                      key={"1"}
                      id={"uo50z"}
                      container={true}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid
                        key={"0"}
                        id={"FP3C7"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"12"}
                      >
                        <Paper
                          key={"0"}
                          id={"bHiFf"}
                          elevation={"{3}"}
                          style={qdmstyles.uzpZ}
                        >
                          <Grid
                            key={"0"}
                            id={"GYTKC"}
                            container={true}
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            lg={"12"}
                            item={true}
                            md={"12"}
                            sm={"12"}
                            xl={"12"}
                            xs={"12"}
                            wrap={"wrap"}
                          >
                            <Grid
                              key={"0"}
                              id={"fQkr3"}
                              container={true}
                              direction={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              lg={"11"}
                              md={"11"}
                              sm={"11"}
                              xl={"11"}
                              xs={true}
                              item={true}
                              spacing={""}
                              style={qdmstyles.cMI}
                            >
                              <Grid
                                key={"0"}
                                id={"Ut94g"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={1}
                                md={1}
                                sm={1}
                                xl={1}
                                xs={1}
                                wrap={"wrap"}
                                style={qdmstyles.BHnWT}
                              >
                                <Grid
                                  key={"0"}
                                  id={"Po8nM"}
                                  container={""}
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  lg={"12"}
                                  item={true}
                                  md={"12"}
                                  sm={"12"}
                                  xl={"12"}
                                  xs={"12"}
                                  wrap={"wrap"}
                                  style={qdmstyles.ReVMC}
                                >
                                  <Typography
                                    key={"0"}
                                    id={"serialnoTypography"}
                                    align={"inherit"}
                                    color={"initial"}
                                    display={"initial"}
                                    variant={"body1"}
                                    children={index + 1}
                                    noWrap={""}
                                    aliasName={"serialnoTypography"}
                                    style={qdmstyles.CbFTC}
                                  ></Typography>
                                  <Paper
                                    key={"1"}
                                    id={"ejYaE"}
                                    elevation={"{3}"}
                                    style={qdmstyles.Zuh}
                                  >
                                    <Avatar
                                      key={"0"}
                                      id={"wg8M8"}
                                      alt={"Avatar"}
                                      variant={"rounded"}
                                      src={
                                        "https://fileupload.dev.ainqaplatform.in/ATP_Platform_DEV/325367543"
                                      }
                                      style={qdmstyles.KQg}
                                    ></Avatar>
                                  </Paper>
                                </Grid>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"CIdwA"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                spacing={""}
                                xs={"2"}
                                item={true}
                              >
                                <Typography
                                  key={"0"}
                                  id={"criteria_typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={"Criteria"}
                                  aliasName={"criteriaheading"}
                                  style={qdmstyles.oXGn}
                                ></Typography>
                                <Typography
                                  key={"1"}
                                  id={"toDisplayTheValue_Typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={`${
                                    rowState?.Criteria?.[0]?.ruleName ||
                                    rowState?.Criteria?.[0]?.group?.label ||
                                    ""
                                  }-${
                                    rowState?.Criteria?.[0]?.priority ||
                                    rowState?.Criteria?.[0]?.rule?.label ||
                                    ""
                                  }`}
                                  aliasName={"toDisplayTheValueTypography"}
                                  style={qdmstyles.dsNK}
                                ></Typography>
                              </Grid>
                              <Grid
                                key={"2"}
                                id={"CIdwA"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={1}
                                md={1}
                                sm={1}
                                xl={1}
                                xs={1}
                              >
                                <Typography
                                  key={"0"}
                                  id={"defaultDispenseDuration&Uom_Typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={"Frequency"}
                                  aliasName={
                                    "defaultDispenseDuration&Uomtypography"
                                  }
                                  style={qdmstyles.mGkLH}
                                ></Typography>
                                <Typography
                                  key={"1"}
                                  id={"TodisplayTheValue_Typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={[
                                    rowState?.freqcode?.freqcode ?? "",
                                    rowState?.freqcode?.shortdesc ?? "",
                                  ].join(" - ")}
                                  aliasName={"TodisplayTheValueTypography"}
                                  style={qdmstyles.ODUkA}
                                ></Typography>
                              </Grid>
                              <Grid
                                key={"3"}
                                id={"CIdwA"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                              >
                                <Typography
                                  key={"0"}
                                  id={"maxdispdurationval&Uom_Typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={"Duration Code & Uom"}
                                  aliasName={"maxdispdurationval&UomTypography"}
                                  style={qdmstyles.gtKUe}
                                ></Typography>
                                <Typography
                                  key={"1"}
                                  id={"toDisplayTheValue_Typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={[
                                    rowState?.durationcode
                                      ?.toString()
                                      ?.padStart(2, "0"),
                                    rowState?.durnUOM?.label,
                                  ].join(" ")}
                                  aliasName={"toDisplayTheValueTypography"}
                                  style={qdmstyles.ENjS}
                                ></Typography>
                              </Grid>
                              <Grid
                                key={"4"}
                                id={"CIdwA"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                              >
                                <Typography
                                  key={"0"}
                                  id={"action_Typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={"Dose Value & Uom"}
                                  aliasName={"actionTypography"}
                                  style={qdmstyles.uHMj}
                                ></Typography>
                                <Typography
                                  key={"1"}
                                  id={"gAydS"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={[
                                    rowState?.doseval
                                      ?.toString()
                                      ?.padStart(2, "0"),
                                    rowState?.doseUOM?.label,
                                  ].join(" ")}
                                  aliasName={"stop"}
                                  style={qdmstyles.SBw}
                                ></Typography>
                              </Grid>
                              <Grid
                                key={"5"}
                                id={"CIdwA"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                              >
                                <Typography
                                  key={"0"}
                                  id={"actionMessage_Id"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={"Infusion Rate & Uom"}
                                  aliasName={"actionMessageId"}
                                  style={qdmstyles.iEX}
                                ></Typography>
                                <Typography
                                  key={"1"}
                                  id={"toDisplayTheValue_Typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={[
                                    rowState?.InfuseRateval?.toString()?.padStart(
                                      2,
                                      "0"
                                    ),
                                    rowState?.InfuseRateUom?.label,
                                  ].join(" ")}
                                  aliasName={"toDisplayTheValueTypography"}
                                  style={qdmstyles.piHGG}
                                ></Typography>
                              </Grid>
                              <Grid
                                key={"6"}
                                id={"CIdwA"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                lg={"2"}
                                md={"2"}
                                sm={"2"}
                                xl={"2"}
                                xs={"2"}
                              >
                                <Typography
                                  key={"0"}
                                  id={"actionMessage_Id"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={"Infuse Over Duration & Uom"}
                                  aliasName={"actionMessageId"}
                                  style={qdmstyles.UiPDr}
                                ></Typography>
                                <Typography
                                  key={"1"}
                                  id={"toDisplayTheValue_Typography"}
                                  align={"inherit"}
                                  color={"initial"}
                                  display={"initial"}
                                  variant={"body1"}
                                  children={[
                                    rowState?.InfuseOverDurval?.toString()?.padStart(
                                      2,
                                      "0"
                                    ),
                                    rowState?.InfuseOverDurType?.label,
                                  ].join(" ")}
                                  aliasName={"toDisplayTheValueTypography"}
                                  style={qdmstyles.LdK}
                                ></Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              key={"1"}
                              id={"vLpha"}
                              container={true}
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              item={true}
                              lg={1}
                              md={1}
                              sm={1}
                              xl={1}
                              xs={1}
                            >
                              <Grid
                                key={"0"}
                                id={"drg92"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"6"}
                                md={"6"}
                                sm={"6"}
                                xl={"6"}
                                xs={"6"}
                              >
                                <ALLMCORE.IconButton
                                  onClick={() => handleEditClick(index)}
                                >
                                  <CreateIcon />
                                </ALLMCORE.IconButton>
                              </Grid>
                              <Grid
                                key={"1"}
                                id={"w7zVn"}
                                container={""}
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                item={true}
                                lg={"6"}
                                md={"6"}
                                sm={"6"}
                                xl={"6"}
                                xs={"6"}
                              >
                                <ALLMCORE.IconButton
                                  onClick={() => handleDeleteClick(index)}
                                >
                                  <TrashIcon />
                                </ALLMCORE.IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  );
                })}
              </Paper>
            </Grid>
          </Grid>
        )}
        {/* Frequency Defaults - Add new Section Ends */}
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(withAllContexts(Orderdetailscomp));
