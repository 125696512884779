import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from "./styles";
import CloseIcon from '@material-ui/icons/Close';
import { SelectBox } from "../../../../components";
import CustomInputDatePicker from '../../../../components/common/datePicker/inputDatePicker';
import ResourceAvailability from '../TimeTableManagementForm/resourceAvailability';
import moment from 'moment';

const OverRideFormManage = (props) => {
    const {scheduleDef={},setScheduledDef = () => null,addNewSchedule = () => null,resourceList = [], handleCancel = () => null} = props
    const classes = useStyles();
    const editData = null;
    const updateState = (name,value) => {
        props?.setScheduledDef({...scheduleDef,[name] : value })
    }
    return (
        <div>
            <div className={classes.align} style={{justifyContent:"space-between", margin:"0px 8px 12px 0px"}}>
          <Typography className={classes.headerTypo}>
            Override Definitation
          </Typography>
          <CloseIcon style={{cursor:"pointer"}} onClick={handleCancel} />
        </div>
        <Grid container className={classes.form}>
          <Grid
            md="4" lg="4" className={classes.formParent}>
            <Typography
                className={classes.formtitle} variant="body1">Resources</Typography>
            <SelectBox
                parent_id={'Entity-name'}
                multi
                disabled={resourceList.length > 0 ? true : false}
                value={[{value:"Doctor",label:"Doctor"},{value:"Doctor1",label:"Doctor1"}]}
                // onchange={(e, value) => updateState("resources", value)}
                list={[]}
                // error={props.error?.resources}
            />
          </Grid>
          <Grid
              md="2" lg="2" className={classes.formParent}>
              <Typography
                  className={classes.formtitle} variant="body1">Start Date <span
                      style={{ color: "red" }}>*</span></Typography>
              <CustomInputDatePicker
                  disabled={resourceList.length > 0 ? true : false}
                  placeholderText="dd / mm / yyyy"
                  minDate={editData ? null : new Date()}
                  selectedDate={
                    scheduleDef.start_date ? new Date(scheduleDef.start_date) : null
                  }
                  handleChange={(value) => updateState("start_date", value)}
                  inputField={
                      <TextField
                          id={"schedule-datetimepicker"}
                          //  label = {data.label}
                          error={props.error?.start_date}
                          fullWidth
                          variant="outlined"
                          required={true}
                          disabled={resourceList.length > 0 ? true : false}
                          type={"text"}
                          value={
                              scheduleDef.start_date
                                  ? moment(new Date(scheduleDef.start_date)).format(
                                      "DD-MM-YYYY"
                                  )
                                  : null
                          }
                          inputProps={{
                              style: {
                                  padding: "10.5px 14px",
                                  fontFamily: "poppin",
                                  fontSize: "12px",
                                  backgroundColor:"#fff",
                              },
                          }}
                      />
                  }
              />
          </Grid>
          <Grid
              md="2" lg="2" className={classes.formParent}>
              <Typography
                  className={classes.formtitle} variant="body1">End Date <span
                      style={{ color: "red" }}>*</span></Typography>
              <CustomInputDatePicker
                  disabled={resourceList.length > 0 ? true : false}
                  placeholderText="dd / mm / yyyy"
                  minDate={editData ? null : new Date()}
                  selectedDate={
                    scheduleDef.end_date ? new Date(scheduleDef.end_date) : null
                    }
                  handleChange={(value) => updateState("end_date", value)}
                  inputField={
                      <TextField
                          id={"schedule-datetimepicker"}
                          //  label = {data.label}
                          error={props.error?.start_date}
                          fullWidth
                          variant="outlined"
                          required={true}
                          disabled={resourceList.length > 0 ? true : false}
                          type={"text"}
                          value={
                            scheduleDef.end_date
                                ? moment(new Date(scheduleDef.end_date)).format(
                                    "DD-MM-YYYY"
                                )
                                : null
                        }
                          inputProps={{
                              style: {
                                  padding: "10.5px 14px",
                                  fontFamily: "poppin",
                                  fontSize: "12px",
                                  backgroundColor:"#fff",
                              },
                          }}
                      />
                  }
              />
          </Grid>
        </Grid>
        <ResourceAvailability
          parent_id={"overRide-resourcePeriod"}
          addNewSchedule={props?.addNewSchedule}
          setScheduledDef={setScheduledDef}
          scheduleDef={scheduleDef}
          stateData={props?.resourcePeriod}
          editData={editData}
          overRide={true}
          setState={props?.setResourcePeriod}
        />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{paddingRight:"15px"}}>
          <Grid >
            <Typography
                  className={classes.formtitle} variant="body1">Reason For Override
            </Typography>
          </Grid>
          <Grid md="4" lg="4" className={classes.formParent} style={{paddingLeft:"8px"}}>
            <SelectBox
                parent_id={'Entity-name'}
                multi
                value={""}
                // onchange={(e, value) => updateState("resources", value)}
                list={[]}
                // error={props.error?.resources}
            />
          </Grid>
          <Grid>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              className={classes.confirmButton}
              variant="outlined"
              onClick={() => addNewSchedule()}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
        </div>
    );
}

export default OverRideFormManage;