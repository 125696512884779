import React from "react";
import { Div } from "qdm-component-library";
import { withAllContexts } from "../../../HOCs";
import { withHoverDrawer } from "../../../HOCs";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
//import { AlertProps } from "../../../utils";
import { Button } from "@material-ui/core";
import { routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
import HealthCareServiceDetail from "./healthCareServiceDetail";
import ApplicableFacilityDetails from "./applicableFacilitiesDetails";
import { CommonTabList } from "../../../components";
import { valitationFunc } from "../../../utils";
class AddHealthCareService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlParams: null,
      activeId: 1,
      allMasters: {},
      openForm: false,
      editData: null,
      serviceCode: "",
      serviceDescription: "",
      serviceCategory: {},
      serviceType: {},
      speciality: {},
      status: true,
      comment: "",
      notes: "",
      applicableFacilities: [],
      locationDescriptionMasterData: [],
      facilityMasterData: [],
      locationTypeMasterData: [],
      error:{}
    }
    this.handleFormState = this.handleFormState.bind(this);
  }
  async componentDidMount() {
    
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Loading...",
    });

    let urlParams = this.props?.location?.state?._key ?? null;
    this.state._key_id = urlParams;
    this.setState({ ...this.state });

    await this.getallmasters();
    if (urlParams) {
      if (urlParams) {
        await this.getHealthCareServiceById(urlParams);
      }
    }
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }

  getHealthCareServiceById = async (id) => {
    await this.props.EDIT_HEALTH_CARE_SERVICE({ id: id });

    this.setState({
      ...this.state,
      ...this.props.healthcare_service_by_id.data
    });
  };
  onInputChange = (n, v, limit,isUppercase) => {
    if(limit){
      if (v?.length > limit) {
        return false;
      }
    }
    const state = this.state;
    let errorObj = JSON.parse(JSON.stringify(this.state.error));
    errorObj[n] = false;
    state.error = errorObj;
    state[n] = isUppercase ? v.toUpperCase()?.replaceAll(" ", "") : v;
    this.setState({ ...state });
  };

  handleFormState = (name, value) => {
    this.setState({ [name]: value});
  };

  handleForm = () => {
    let { openForm, editData } = this.state;
    this.setState({
      openForm: !openForm,
      editData: openForm ? null : editData,
    });
  };

  onSelectChange = (n, v) => {
    const state = this.state;
    state[n] = v;
    this.setState({ ...this.state, ...state });
  };

  getallmasters = async () => {
    const data = await this.props.HEALTH_CARE_SERVICE_MASTER();
    this.setState({
      allMasters: data?.payload?.data
    });
  };

  saveForm = async () => {
    let errorList = [
      "serviceCode",
      "serviceDescription",
      "serviceCategory",
      "serviceType",
      "speciality",
    ];
    let { error, errorState } = await valitationFunc(this.state,errorList)
    if (error) {
      this.props.alert.setSnack("mandatory");
      this.setState({
        ...this.state,
        error: errorState
      });
    } else {
      let payload = await this.props.HEALTH_CARE_SERVICE_INSERT({
        dataList: this.state,
      });
      if (
        payload?.payload?.data?.validation_error?.[0]?.Errormsg?.includes(
          "unique"
        )
      ) {
        this.props.alert.setSnack("unique");
        return false;
      } else if (payload?.error === true) {
        this.props.alert.setSnack("error");
        return false;
      } else {
        if (this.state._key_id) {
          this.props.alert.setSnack("update");
        } else {
          this.props.alert.setSnack();
        }
        this.backHandle();
      }
    }
  }
  setActive = (id) => {
    this.setState({
      activeId: id,
    });
  };
  styles = {
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    loadscreen: {
      display: "flex",
      justifyContent: "center",
      margin: "16px",
      borderRadius: "6px",
      background: "#fff",
      alignItems: "center",
      minHeight: "76vh",
    },
  };

  backHandle = () => {
    this.props.history.push({
      pathname: routes.organizationConfig,
      state: { index: 2 },
    });
  };
  getLocationIdByType = async (type, val) => {
    if (type === "locationType_") {
      let data_ = await this.props.HEALTH_CARE_SERVICE_MASTER_LOCATION_DESCRIPTION({ id: val });
      
      this.setState({
        ...this.state,
        locationDescriptionMasterData: data_?.payload?.data
      })
      return data_?.payload?.data;
    }
  }

  render() {
    return (
      <React.Fragment>
        <CommonTabList
          parent_id={"agerange-form"}
          backbtn
          backFun={this.backHandle}
          title={this.state._key_id ? "Edit Health Care Service" : "Add Health Care Service"}
          list={[]}
        />

        <Div
          id={'addhealthcareservice-parent-div'}
          style={{
            padding: "5px 15px",
            color: "#101010",
            overflow: "hidden",
          }}
        >
          {/* HealthCareServiceDetail */}
          <HealthCareServiceDetail
            parent_id={'HealthCareServiceDetail'}
            allMasters={this?.state?.allMasters}
            onSelectChange={this.onInputChange}
            onInputChange={this.onInputChange}
            {...this.state}
          />

          {/* ApplicableFacilities */}
          <ApplicableFacilityDetails
            parent_id={'ApplicableFacilityDetails'}
            allMasters={this?.state?.allMasters}
            locationDescriptionMasterData={this?.state?.locationDescriptionMasterData}
            handleFormState={this.handleFormState}
            closeForm={this.handleForm}
            getLocationIdByType={this.getLocationIdByType}
            {...this.state}
          />

          <Div
            id={'addhealthcareservice-button-div'}
            className="p-continue-action">
            <Button
              id={'addhealthcareservice-cancel-button'}
              variant="text"
              className={"topNav-actions cancelBtn"}
              onClick={() => {
                this.backHandle();
              }}
            >
              Cancel
            </Button>
            <Button
              id={'addhealthcareservice-Update-and-Save-button'}
              variant={"contained"}
              color={"primary"}
              //disabled={true}
              disabled={this.props?.healthcare_service_insert?.loading ?? false}
              onClick={() => { this.saveForm() }}
              style={{ marginLeft: 10 }}
            >
              {this.state._key_id ? (
                <>
                  {this.props?.healthcare_service_insert?.loading
                    ? "Updating..."
                    : "Update"}
                </>
              ) : (
                <>
                  {this.props?.healthcare_service_insert?.loading
                    ? "Saving..."
                    : "Save"}
                </>
              )}
            </Button>
          </Div>
        </Div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  healthcare_service_masters:
    state?.organizationConfigMasterSlice?.health_care_service_master,
  healthcare_service_location_description_masters: state?.organizationConfigMasterSlice?.health_care_service_master_location_description,
  healthcare_service_insert: state?.organizationConfigSlice?.health_care_service_insert,
  healthcare_service_by_id: state?.organizationConfigSlice?.health_care_service_single_read
});
export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(withHoverDrawer(AddHealthCareService))));
