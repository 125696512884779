import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Direct, Suggested, Videoconsultation } from '../../../../components';

const useStyles = makeStyles(() => ({
    tabStyle: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #E0E0E0',
        borderRadius: 8,
        minWidth: 120,
        minHeight: 32,
        padding: 8,
        cursor: 'pointer',
        justifyContent: 'space-between',
        '& .selectedTimeStyle': {
            color: '#fff',
        },
        '& .unSelectedTimeStyle': {
            color: '#6f6f6f',
        },
    },
    timeStyle: {
        // color:'#101010',
        fontSize: 12,
        fontFamily: 'pc_medium',
        // flex:1,
        // alignItems:'center',
    },
    icon: {
        marginLeft: 7
    },
    rowCenter: {
        display: 'flex',
        alignItems: 'center',
    }
}));

export const CustomTab = (props) => {
    const { parent_id } = props;
    const classes = useStyles(props);

    return (
        <div
            id={`${parent_id}-parent-div`}
            className={classes.rowCenter} style={{ flexWrap: 'wrap' }}>
            {
                props?.data && props?.data?.map((_, i) => {
                    return (
                        <div
                            id={`${parent_id}-parent-div` + i}
                            className={classes.tabStyle}
                            style={{
                                marginRight: 7,
                                marginBottom: 7,
                                backgroundColor: props?.selectedSlot?.value === _?.value ? '#0071F2' : '#fff'
                            }}
                            onClick={() => props?.getSelected(_)}
                        >
                            <Typography
                                id={`${parent_id}-${_?.label.replaceAll(" ", "-")}-typography` + i}
                                variant="body1"
                                className={`${classes.timeStyle} ${props?.selectedSlot?.value === _?.value ? 'selectedTimeStyle' : 'unSelectedTimeStyle'}`}
                            >
                                {_?.label}
                            </Typography>
                            <div
                                id={`${parent_id}-discription-div` + i}
                                className={classes.rowCenter}>
                                {
                                    _?.isdirect &&
                                    <div
                                        id={`${parent_id}-Direct-div` + i}
                                        className={classes.icon}>
                                        <Direct
                                            id={`${parent_id}-Direct` + i}
                                            color={props?.selectedSlot?.value === _?.value ? '#fff' : '#6f6f6f'}
                                        />
                                    </div>
                                }
                                {
                                    _?.issuggest &&
                                    <div
                                        id={`${parent_id}-Suggested-div` + i}
                                        className={classes.icon}>
                                        <Suggested
                                            id={`${parent_id}-Suggested` + i}
                                            color={props?.selectedSlot?.value === _?.value ? '#fff' : '#6f6f6f'}
                                        />
                                    </div>
                                }
                                {
                                    _?.isvideo &&
                                    <div
                                        id={`${parent_id}-Videoconsultation-div` + i}
                                        className={classes.icon}>
                                        <Videoconsultation
                                            id={`${parent_id}-Videoconsultation` + i}
                                            color={props?.selectedSlot?.value === _?.value ? '#fff' : '#6f6f6f'}
                                        />
                                    </div>

                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}