import React from "react";
import { Paper, Typography, makeStyles, Grid, TextField, Button } from "@material-ui/core";
import { SelectBox } from "../";
import { actions } from 'primary_care_admin_binder'
import { useSelector, useDispatch } from "react-redux";
import deleteIcon from "../../assets/icons8-trash.svg";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        margin: 15
    },
    title: {
        fontFamily: "poppinsemibold",
        fontSize: "12px",
    },
    form: {
        paddingTop: 15
    },
    formtitle: {
        fontSize: "12px",
        paddingBottom: 10
    },
    formParent: {
        paddingRight: 10
    }
}));

function AddRuleSection(props) {
    const disPatch = useDispatch();
    const { parent_id } = props;
    const group_list = useSelector(state => state.timetableMasterSlice.group_list)
    const { setState, vl, stateData, index = 0, editData, disabled = false } = props;
    const [ruleList, setRuleList] = React.useState([]);

    React.useEffect(() => {
        const intialCall = async () => {
            if (vl.group && ruleList.length === 0 && editData) {
                let list = await disPatch(actions.READ_RULE({ groupId: vl.group.value }));
                setRuleList(list.payload.data);
            }
        }
        intialCall()
    }, [vl.group])

    const stateUpdate = async (name, value) => {
        const data = JSON.parse(JSON.stringify(stateData));
        data[index][name] = value
        setState(data);
        if (name === "group") {
            let list = await disPatch(actions.READ_RULE({ groupId: value?.value }))
            setRuleList(list.payload.data)
        }
    }

    const removeRow = async () => {
        const data = JSON.parse(JSON.stringify(stateData));
        data.splice(index, 1);
        setState(data);
    }
    return <React.Fragment>
        <Grid
            id={`${parent_id}-container-grid`}
            container style={{ marginBottom: 10 }}>
            <Grid
                id={`${parent_id}-group-grid`}
                md={4} style={{ paddingRight: 15, marginTop: 18 }}>
                <SelectBox
                    parent_id={'group'}
                    disabled={vl.isnew ? false : disabled}
                    top_title="Group"
                    list={group_list?.data ?? []}
                    value={vl.group ? vl.group : null}
                    onchange={(e, value) => { stateUpdate("group", value) }}
                />
            </Grid>
            <Grid
                id={`${parent_id}-rule-grid`}
                md={4} style={{ paddingRight: 15, marginTop: 18 }}>
                <SelectBox
                    parent_id={'rule'}
                    disabled={vl.isnew ? false : disabled}
                    list={ruleList ?? []}
                    value={vl.rule ? vl.rule : null}
                    onchange={(e, value) => { stateUpdate("rule", value) }}
                    top_title="Rule"
                />
            </Grid>
            {(!disabled || vl.isnew) && <Grid md={1} style={{ display: "flex", paddingRight: 15, marginTop: 40 }}>
                <img
                    id={`${parent_id}-deleteIcon-img`}
                    onClick={() => removeRow()} alt="" src={deleteIcon} style={{ margin: "auto", cursor: "pointer" }} />
            </Grid>}
        </Grid>
    </React.Fragment >
}



function RuleSet(props) {
    const disPatch = useDispatch();
    const { stateData = [], ttOverRide = false, setState = () => null, editData = null, parent_id } = props;
    const classes = useStyles();
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    React.useEffect(() => {
        disPatch(actions.READ_GROUPS())
    }, [])
    const addRow = () => {
        let data = JSON.parse(JSON.stringify(stateData));
        data.push({ isnew: true })
        setState(data)
        forceUpdate()
    }
    return (
        <React.Fragment>
            {
                stateData.map((vl, i) => {
                    return <AddRuleSection
                        parent_id={'AddRuleSection' + i}
                        disabled={Boolean(editData)} index={i} {...props} vl={vl} />
                })
            }
            <Grid container className={classes.form}>
                <Button
                    disabled={Boolean(ttOverRide) ? false : Boolean(editData)}
                    onClick={() => addRow()}
                    color="secondary" variant="outlined"
                >+ Add New Rule Set</Button>
            </Grid>
        </React.Fragment>
    )
}

export default RuleSet;