/* eslint-disable require-jsdoc */
import React from "react";
import TreeViewTable from ".";
import "./tree.css";

const TreeViewStatus = (props) => {
  const {
    clicked,
    treeListData,
    setValues,
    values,
    EditMaster,
    isEdit,
    refresh,
    screeen,
    Thdata,
    parent_id,
    steperSave,
  } = props;
  return (
    <div className="tableTree">
      <TreeViewTable
        clicked={clicked}
        treeListData={treeListData}
        setValues={setValues}
        values={values}
        EditMaster={EditMaster}
        isEdit={isEdit}
        refresh={refresh}
        screeen={screeen}
        Thdata={Thdata}
        parent_id={parent_id}
        steperSave={steperSave}
      />
    </div>
  );
};

export default TreeViewStatus;
