import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import "../font.css";
import InputAdornment from "@material-ui/core/InputAdornment";

//import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    "& .MuiPopover-paper": {
      top: "170px !important",
    },
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    margin: "0px !important",
    "&. .makeStyles-formControl-100": {
      margin: "0px !important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
    fontFamily: "poppin",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px !important",
      height: "26px",
    },
    "& .MuiTypography-body1": {
      fontSize: "12px",
    },
  },
}));

export default function SimplePopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [slab, setslab] = React.useState(true);
  const [slot, setslot] = React.useState(false);

  const handleShowslab = () => {
    setslab(true);
    setslot(false);
  };
  const handleshowslot = () => {
    setslot(true);
    setslab(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // <!------------------------Radio------------------------------>
  const [value, setValue] = React.useState("Slab basis");
  const handleChangeradio = (event) => {
    setValue(event.target.value);
  };

  //   <!-------------------------------------Select------------------------->
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [consultation, setconsultation] = React.useState("");
  const handleChangeconsultation = (event) => {
    setconsultation(event.target.value);
  };
  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{
          backgroundColor: "transparent",
          color: "#0071F2",
          border: "none",
          paddingTop: "5px",
          paddingBottom: "5px",
          borderRadius: "5px",
          fontFamily: "poppin",
          fontSize: "12px",
          width: "106px",
          boxShadow: "none",
        }}
      >
        + Add New
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div className={classes.root}>
            <div style={{ display: "flex" }}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Duration Type</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={value}
                    onChange={handleChangeradio}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "130px" }}>
                        <FormControlLabel
                          value="Slot basis"
                          control={
                            <Radio
                              color="primary"
                              fontSize="small"
                              onClick={handleshowslot}
                            />
                          }
                          label="slot basis"
                          InputLabelProps={{ style: { fontSize: 12 } }}
                        />
                      </div>
                      <div style={{ width: "130px" }}>
                        <FormControlLabel
                          value="Slab basis"
                          control={
                            <Radio
                              color="primary"
                              // onSelect={handleShowslab}
                              onClick={handleShowslab}
                            />
                          }
                          label="slab basis"
                          InputLabelProps={{ style: { fontSize: 12 } }}
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
              {slab && (
                <div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <label for="Day type" className={classes.grayfont}>
                        Day type
                      </label>
                      <br />
                      <FormControl
                        variant="outlined"
                        style={{ width: "130px" }}
                        className={classes.formControl}
                        size="small"
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{ fontSize: "12px" }}
                        ></InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={age}
                          className={classes.grayfont}
                          onChange={handleChange}
                          inputProps={{
                            style: { fontSize: 12, fontFamily: "poppin" },
                          }}
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          style={{ height: "26px" }}
                        >
                          <MenuItem
                            value=""
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            <em>None</em>
                          </MenuItem>
                          <MenuItem
                            value={"morning"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            morning
                          </MenuItem>
                          <MenuItem
                            value={"Afternoon"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            Afternoon
                          </MenuItem>
                          <MenuItem
                            value={"Night"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            Night
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div style={{ marginLeft: "12px" }}>
                      <label for="starttime" className={classes.grayfont}>
                        Start Time
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        style={{ width: "130px", height: "26px" }}
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        End Time
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        style={{ width: "130px" }}
                        size="small"
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        Max Appointment /Slab
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        style={{ width: "150px" }}
                        size="small"
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      position: "absolute",
                      left: "15px",
                    }}
                  >
                    <div>
                      <label for="endtime" className={classes.grayfont}>
                        Max Appointment Allowed
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        inputProps={{
                          readOnly: true,
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        style={{ width: "200px" }}
                      />
                    </div>

                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        Max Walkins
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        Max Overbookings
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        Max Waitlist
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>

                    <div style={{ marginLeft: "12px", paddingRight: "20px" }}>
                      <label for="Day type" className={classes.grayfont}>
                        Consultation Type
                      </label>
                      <br />
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                        style={{ width: "130px" }}
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={consultation}
                          onChange={handleChangeconsultation}
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          className={classes.grayfont}
                        >
                          <MenuItem
                            value=""
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            <em>None</em>
                          </MenuItem>
                          <MenuItem
                            value={"morning"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            Direct
                          </MenuItem>
                          <MenuItem
                            value={"Afternoon"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            Remote
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {/* <!---------------------------button----------------------> */}

                  <div
                    style={{
                      display: "flex",
                      marginTop: "120px",
                      float: "right",
                      paddingBottom: "20px",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFFFFF",
                        border: "none",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderRadius: "5px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        width: "90px",
                      }}
                    >
                      cancel
                    </button>
                    <button
                      onClick={handleClick}
                      style={{
                        backgroundColor: "#0071F2",
                        color: "white",
                        border: "none",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderRadius: "5px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                      }}
                    >
                      Save & add another{" "}
                    </button>
                    <button
                      style={{
                        backgroundColor: "#0071F2",
                        color: "white",
                        border: "none",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderRadius: "5px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
              {/* <!----------------------------Slot---------> */}

              {slot && (
                <div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <label for="Day type" className={classes.grayfont}>
                        Day Type
                      </label>
                      <br />
                      <FormControl
                        variant="outlined"
                        style={{ width: "130px" }}
                        className={classes.formControl}
                        size="small"
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          style={{ fontSize: "12px" }}
                        ></InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={age}
                          className={classes.grayfont}
                          onChange={handleChange}
                          inputProps={{
                            style: { fontSize: 12, fontFamily: "poppin" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: 12, fontFamily: "poppin" },
                          }}
                        >
                          <MenuItem
                            value=""
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            <em>None</em>
                          </MenuItem>
                          <MenuItem
                            value={"morning"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            morning
                          </MenuItem>
                          <MenuItem
                            value={"Afternoon"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            Afternoon
                          </MenuItem>
                          <MenuItem
                            value={"Night"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            Night
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div style={{ marginLeft: "12px" }}>
                      <label for="starttime" className={classes.grayfont}>
                        Start Time
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        style={{ width: "130px" }}
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        End Time
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        style={{ width: "130px" }}
                        size="small"
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        Slot Duration
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Mins</InputAdornment>
                          ),
                        }}
                        style={{ width: "150px" }}
                        size="small"
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      position: "absolute",
                      left: "15px",
                    }}
                  >
                    <div>
                      <label for="endtime" className={classes.grayfont}>
                        Max Appointment/Slot
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        //style={{ width: "100px" }}
                        size="small"
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        style={{ width: "160px" }}
                      />
                    </div>

                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        Max Appointment Allowed
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        style={{ width: "180px" }}
                        variant="outlined"
                        size="small"
                        inputProps={{
                          readOnly: true,
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        Max Walkins
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        Max Overbookings
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>

                    <div style={{ marginLeft: "12px" }}>
                      <label for="endtime" className={classes.grayfont}>
                        Max Waitlist
                      </label>
                      <br />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        style={{ paddingRight: "12px" }}
                        size="small"
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    </div>
                  </div>
                  <div
                    style={{ position: "absolute", top: "155px", left: "5px" }}
                  >
                    <div style={{ marginLeft: "12px", paddingRight: "20px" }}>
                      <label for="Day type" className={classes.grayfont}>
                        Consultation Type
                      </label>
                      <br />
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                        style={{ width: "130px" }}
                        inputProps={{
                          style: { fontSize: 12, fontFamily: "poppin" },
                        }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={consultation}
                          onChange={handleChangeconsultation}
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          className={classes.grayfont}
                        >
                          <MenuItem
                            value=""
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            <em>None</em>
                          </MenuItem>
                          <MenuItem
                            value={"morning"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            Direct
                          </MenuItem>
                          <MenuItem
                            value={"Afternoon"}
                            style={{ fontSize: "12px", fontFamily: "poppin" }}
                          >
                            Remote
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {/* <!---------------------------button----------------------> */}

                  <div
                    style={{
                      display: "flex",
                      marginTop: "165px",
                      float: "right",
                      paddingBottom: "20px",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFFFFF",
                        border: "none",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderRadius: "5px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        width: "90px",
                      }}
                    >
                      cancel
                    </button>
                    <button
                      onClick={handleClick}
                      style={{
                        backgroundColor: "#0071F2",
                        color: "white",
                        border: "none",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderRadius: "5px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                      }}
                    >
                      Save & add another{" "}
                    </button>
                    <button
                      style={{
                        backgroundColor: "#0071F2",
                        color: "white",
                        border: "none",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        borderRadius: "5px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Typography>
      </Popover>
    </div>
  );
}
