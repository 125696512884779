import React from "react";
import { Grid, Paper, Typography, Divider, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Components
import Capsule from "../capsule";
// Icons
import { CloseRounded } from "@material-ui/icons";
// Contexts
import { withAllContexts } from "../../HOCs";
// Styles
import qdmstyles from "./styles.json";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  },
  grid: {
    outline: 0,
  },
  closeIconContainer: {
    width: "26px",
    height: "26px",
    borderRadius: "50%",
    backgroundColor: "#E5E8EE",
    display: "grid",
    cursor: "pointer",
  },
}));

const StrengthModal = (props) => {
  const { parent_id, showModal, handleCloseModal, data } = props;
  const classes = useStyles();
  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Grid
        className={classes.grid}
        key={"0"}
        id={"Z6EBo"}
        container={true}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        lg={"4"}
        md={"4"}
        sm={"4"}
        xl={"4"}
        xs={"4"}
      >
        <Grid
          key={"0"}
          id={"Z9Hs0"}
          container={""}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item={true}
          lg={"12"}
          md={"12"}
          sm={"12"}
          xl={"12"}
          xs={"12"}
        >
          <Paper
            key={"0"}
            id={"pCtsN"}
            elevation={"{3}"}
            style={qdmstyles.pCtsN}
          >
            <Grid
              key={"0"}
              id={"p9FEL"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
            >
              <Grid
                key={"0"}
                id={"Y6MRQ"}
                container={true}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.YMRQ}
              >
                <Grid
                  key={"0"}
                  id={"Z8H2H"}
                  container={true}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  lg={"7"}
                  md={"7"}
                  sm={"7"}
                  xl={"7"}
                  xs={"7"}
                >
                  <Typography
                    key={"0"}
                    id={"6qn9l"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"AVAILABLE STRENGTH"}
                    style={qdmstyles.qnl}
                  />
                </Grid>
                <Grid
                  key={"1"}
                  id={"5WDf9"}
                  container={""}
                  direction={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  lg={"2"}
                  md={"2"}
                  sm={"2"}
                  xl={"2"}
                  xs={"2"}
                  style={qdmstyles.WDf}
                >
                  <Paper
                    className={classes.closeIconContainer}
                    elevation={false}
                    onClick={handleCloseModal}
                  >
                    <CloseRounded
                      fontSize="small"
                      style={{ placeSelf: "center" }}
                    />
                  </Paper>
                </Grid>
              </Grid>
              <Grid
                key={"1"}
                id={"PLePk"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.PLePk}
              >
                <Divider
                  key={"0"}
                  id={"u6B8U"}
                  variant={"fullWidth"}
                  flexItem={""}
                />
              </Grid>
              <Grid
                key={"2"}
                id={"T7xTu"}
                container={true}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.TxTu}
              >
                <Grid
                  key={"0"}
                  id={"oEUGh"}
                  container={true}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  lg={"12"}
                  item={true}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                >
                  <Grid
                    key={"0"}
                    id={"pYP7X"}
                    container={true}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    lg={"12"}
                    item={true}
                    style={{ margin: "0 16px" }}
                  >
                    {data?.length === 0 ? (
                      <Typography
                        style={{
                          margin: "8px 0px 18px",
                          textAlign: "center",
                          flex: "1",
                        }}
                      >
                        No strength available
                      </Typography>
                    ) : (
                      <React.Fragment>
                        <Grid
                          key={"0"}
                          id={"S5mqN"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          lg={"5"}
                          md={"5"}
                          sm={"5"}
                          xl={"5"}
                          xs={"5"}
                        >
                          <Typography
                            key={"0"}
                            id={"U5nAb"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Strength Value"}
                            style={qdmstyles.UnAb}
                          />
                        </Grid>
                        <Grid
                          key={"1"}
                          id={"w8Av4"}
                          container={true}
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          lg={"5"}
                          xl={"5"}
                          md={"5"}
                          sm={"5"}
                          xs={"5"}
                        >
                          <Typography
                            key={"0"}
                            id={"GFYVy"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Strength Volume"}
                            style={qdmstyles.GFYVy}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                  {data?.length > 0 &&
                    data?.map((item, index) => {
                      return (
                        <Grid
                          key={"1"}
                          id={"4eEz8"}
                          container={true}
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          lg={"12"}
                          md={"12"}
                          sm={"12"}
                          xl={"12"}
                          xs={"12"}
                          item={true}
                          style={qdmstyles.eEz}
                        >
                          <Grid
                            key={"0"}
                            id={"0yFDe"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            item={true}
                            lg={"5"}
                            xl={"5"}
                            md={"5"}
                            sm={"5"}
                            xs={"5"}
                          >
                            <Capsule
                              value={{
                                StrengthValue: item?.StrengthValue,
                                StrengthVolumeUoM: item?.StrengthUOM?.display,
                              }}
                            />
                          </Grid>
                          <Grid
                            key={"1"}
                            id={"XLXZi"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"2"}
                            item={true}
                            md={"2"}
                            sm={"2"}
                            xl={"2"}
                            xs={"2"}
                            style={qdmstyles.YKtx}
                          >
                            <Typography
                              key={"0"}
                              id={"JMvWO"}
                              align={"inherit"}
                              color={"initial"}
                              display={"initial"}
                              variant={"body1"}
                              children={" /"}
                              style={qdmstyles.YBla}
                            />
                          </Grid>
                          <Grid
                            key={"2"}
                            id={"xTaHq"}
                            container={""}
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            lg={"5"}
                            item={true}
                            md={"5"}
                            sm={"5"}
                            xl={"5"}
                            xs={"5"}
                          >
                            <Capsule
                              value={{
                                StrengthValue: item?.StrengthVolume,
                                StrengthVolumeUoM:
                                  item?.StrengthVolumeUoM?.display,
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withAllContexts(StrengthModal);
