import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
// import EditOutlined from "@material-ui/icons/EditOutlined";
// import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Drawer from "@material-ui/core/Drawer";
// import AddHoliday from "./addnewholiday";
import TextField from "@material-ui/core/TextField";
import searchicon from "../../assets/icons - Search.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import { withHoverDrawer } from "../../HOCs";
// import { withNavBars } from "../../HOCs";
// import RuleBuilder from "./ruleBuilder";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
import "../font.css";
import CreateTimeTable from "./createNewTimeTable";
import backarrow from "../../assets/icons8-expand-arrow.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import edit from "../../assets/icons - Edit.svg";
import Delete from "../../assets/icons8-trash.svg";
// import FormControl from "@material-ui/core/FormControl";
import Dropdown from "./dropdown";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import {
  Div,
  // H6,
  // TextInput,
  // Divider,
  Select,
  // Row,
  // Col,
  // Image,
} from "qdm-component-library";
// import { useHistory } from "react-router-dom";

// import DenseTabletwo from "./viewholidayentities";
// import Grid from "@material-ui/core/Grid";
const drawerWidth = "94%";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#fff",
    border: "none",
    height: "30px",
  },
  heigthinput: {
    border: "blue",

    "& input": {
      height: "30px !important",
      color: "#101010",
    },
  },
  entity: {
    width: "70% ",
    marginTop: "10px",
    color: "#6F6F6F",
    marginLeft: "8px",
    fontSize: "10px",
    fontFamily: "poppin",
  },
  entity1: {
    width: "35% ",
    marginTop: "10px",
    color: "#6F6F6F",
    marginLeft: "8px",
    fontSize: "10px",
    fontFamily: "poppin",
  },
  selectbox: {
    height: "30px !important",
  },
  bgcolor: {
    backgroundColor: "#F9F9F9",
    paddingTop: "10px",
    fontFamily: "poppin",
    color: "gray",
    fontSize: "12px",
  },
  font: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  boxsize: {
    "& .MuiOutlinedInput-inputMarginDense": {
      height: "10px !important",
    },
  },
  root: {
    "& .MuiTableCell-sizeSmall": {
      paddingBottom: "0px",
      paddingTop: "0px",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 18,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#0071F2",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 12,
    marginTop: "1px",
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

function createData(
  serialno,
  title,
  resourcetype,
  resourcerole,
  periodfrom,
  periodto,
  status,
  action
) {
  return {
    serialno,
    title,
    resourcetype,
    resourcerole,
    periodfrom,
    periodto,
    status,
    action,
  };
}

const rows = [
  createData(
    "1",
    "Timetable - Normal Days",
    "Practitioner",
    "Consultant",
    "01-01-2021",
    "30-06-2021"
  ),
  createData(
    "2",
    "Timetable - Normal Days",
    "Practitioner",
    "Consultant",
    "01-01-2021",
    "30-06-2021"
  ),
  createData(
    "3",
    "Timetable - Normal Days",
    "Practitioner",
    "Consultant",
    "01-01-2021",
    "30-06-2021"
  ),
  createData(
    "4",
    "Timetable - Normal Days",
    "Practitioner",
    "Consultant",
    "01-01-2021",
    "30-06-2021"
  ),
  createData(
    "5",
    "Timetable - Normal Days",
    "Practitioner",
    "Consultant",
    "01-01-2021",
    "30-06-2021"
  ),
  createData(
    "6",
    "Timetable - Normal Days",
    "Practitioner",
    "Consultant",
    "01-01-2021",
    "30-06-2021"
  ),
  createData(
    "7",
    "Timetable - Normal Days",
    "Practitioner",
    "Consultant",
    "01-01-2021",
    "30-06-2021"
  ),
  createData(
    "8",
    "Timetable - Normal Days",
    "Practitioner",
    "Consultant",
    "01-01-2021",
    "30-06-2021"
  ),
  createData(
    "9",
    "Timetable - Normal Days",
    "Practitioner",
    "Consultant",
    "01-01-2021",
    "30-06-2021"
  ),
];

function TimeTable() {
  const history = useHistory();
  const classes = useStyles();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  //const [state, setState] = React.useState("checkedC: true");
  const handleDrawerOpen = () => {
    // setOpen(true);
    history.push(routes.createTimeTable);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  // const [dropdownfacility, setdropdownfacility] = React.useState("");
  // const [dropdown, setdropdown] = React.useState("");

  // const handleChangedropFac = (event) => {
  //   setdropdownfacility(event.target.value);
  // };
  // const handleChangedrop = (event) => {
  //   setdropdown(event.target.value);
  // };
  const optiondata = [{ value: "Facility" }];

  return (
    <div>
      <div style={{ marginLeft: "59%" }}>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", marginBottom: "12px" }}>
            <Div
              style={{
                display: "flex",
                border: "1px solid#E0E0E0",
                height: "32px",
                width: "162px",
                borderRadius: "5px",
                backgroundColor: "white",
              }}
            >
              <h5 className={classes.entity}>Entity Type :</h5>{" "}
              <Select
                className={classes.selectbox}
                options={optiondata}
                label={false}
                variant="none"
                onChangeValue={(e) => this.setDetails("entity_type", e)}
                showArrow
                placeholder="select..."
                labelStyle={classes.labelStyle}
                inLineStyles={{
                  borderColor: "#fff",
                  border: "none",
                  height: "30px",
                }}
                hoverColor="#0071F2"
              />
            </Div>

            <Div
              style={{
                display: "flex",
                border: "1px solid#E0E0E0",
                height: "32px",
                width: "282px",
                marginLeft: "20px",
                borderRadius: "5px",
                backgroundColor: "white",
              }}
            >
              <h5 className={classes.entity1}>Entity Name :</h5>
              <Select
                options={optiondata}
                label={false}
                variant="none"
                onChangeValue={(e) => this.setDetails("entity_Name", e)}
                showArrow
                placeholder="select..."
                labelStyle={classes.labelStyle}
                inLineStyles={{
                  borderColor: "#fff",
                  border: "none",
                  height: "30px",
                }}
                hoverColor="#0071F2"
              />
            </Div>
          </div>
        </div>
      </div>
      <Card style={{ borderRadius: "12px" }}>
        <div
          style={{
            borderRadius: "6px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginLeft: "18px" }}>
              <p style={{ fontFamily: "poppinsemibold" }}>Time Table</p>
            </div>
            <div
              style={{ marginRight: "18px", marginTop: "16px" }}
              className={classes.boxsize}
            >
              <TextField
                id="outlined-search"
                placeholder="Search field"
                size="small"
                style={{ width: "231px", height: "32px" }}
                type="search"
                variant="outlined"
                InputProps={{
                  style: {
                    fontFamily: "poppin",
                    fontSize: "12px",
                    marginRight: "10px",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchicon} alt="Icon" />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { fontSize: 12 },
                }}
              />

              <button
                onClick={() => handleDrawerOpen()}
                style={{
                  backgroundColor: "#0071F2",
                  color: "white",
                  height: "32px",
                  width: "108px",
                  border: "none",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderRadius: "5px",
                  fontFamily: "poppin",
                  fontSize: "12px",
                }}
              >
                +Add New
              </button>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {
                  <div style={{ backgroundColor: "#F6F6F6" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <button
                          onClick={handleDrawerClose}
                          style={{
                            border: "none",
                            backgroundColor: "#DCDCDC",
                            borderRadius: "20px",
                            padding: "5px",
                            height: "25px",
                            marginTop: "11px",
                          }}
                        >
                          <img
                            src={backarrow}
                            alt="icon"
                            style={{ transform: "rotate(90deg)" }}
                          />
                        </button>
                        <p>Create New Time Table</p>
                      </div>
                      <div style={{ marginTop: "8px", marginRight: "30px" }}>
                        <Dropdown />
                      </div>
                    </div>
                    <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                    <div style={{ marginTop: "10px" }}>
                      <CreateTimeTable />
                    </div>
                  </div>
                }
              </Drawer>
            </div>
          </div>
          <TableContainer
            component={Paper}
            style={{
              borderRadius: "5px",
              marginTop: "20px",
              paddingBottom: "18px",
            }}
            className={classes.root}
          >
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.bgcolor}
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "210px",
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "150px",
                    }}
                    align="left"
                  >
                    Resource type
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "150px",
                    }}
                    align="left"
                  >
                    Resource Role
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "100px",
                    }}
                    align="left"
                  >
                    Start Date
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    align="left"
                  >
                    End Date
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    align="left"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    align="left"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.serialno}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.font}
                      style={{ width: "120px", padding: "12px" }}
                    >
                      {row.serialno}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.font}
                      style={{ width: "170px", padding: "12px" }}
                    >
                      {row.title}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.font}
                      style={{ width: "120px" }}
                    >
                      {row.resourcetype}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.font}
                      style={{ width: "100px" }}
                    >
                      {row.resourcerole}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.font}
                      style={{ width: "120px" }}
                    >
                      {row.periodfrom}
                    </TableCell>
                    <TableCell align="left" className={classes.font}>
                      {row.periodto}
                    </TableCell>
                    <TableCell align="left" className={classes.font}>
                      <FormControlLabel
                        style={{ marginLeft: "0px", marginRight: "0px" }}
                        control={<IOSSwitch />}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          padding: "13px",
                          paddingLeft: "0px",
                        }}
                      >
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <img
                            src={edit}
                            alt="Icon"
                            style={{
                              height: "12px",
                              backgroundColor: "transparent",
                              marginTop: "5px",
                            }}
                          />
                        </button>
                        {/* <AntSwitch
                          checked={state.checkedC}
                          onChange={handleChange}
                          name="checkedC"
                        /> */}

                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <img
                            src={Delete}
                            alt="Icon"
                            style={{
                              height: "12px",
                              backgroundColor: "transparent",
                              marginTop: "5px",
                            }}
                          />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </div>
  );
}

export default TimeTable;
