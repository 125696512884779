/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2020-11-27
 * @modify date 2020-12-01
 * @desc The private route check wheather the user is logged in or not and also check
 * wheather the request route from the user is accessible to them or not using the Access(role,path)
 * function then allow to the particular route or else it will redirect to login page.
 */

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { routes } from "./routes";
// import { Access } from "./access";
// import AppDrawer from "../App.drawer";
//import { useDispatch } from "react-redux";
//import { actions } from "primary_care_admin_binder";
import config from "../config";
import axios from "axios";
import { useHistory } from "react-router";


const PrivateRoute = (routeprops) => {
  //const dispatch = useDispatch();
  const history = useHistory();
  let { component: Component, path, ...rest } = routeprops;
  // const isAuthenticated = (router) => {   
  //   if (1) {
  //     const _ = Access(1, router?.match?.path);
  //     if (_ >= 0) {
  //       return true;
  //     };
  //     return false;
  //   } else {
  //     return false;
  //   }
  // };

  const checkAccess =  async() => {
    try {
      const token = localStorage.getItem("token");
      // if (token) {
      //   // dispatch(actions.REFRESH_TOKEN({ token, isOrg: true })).then(userInfo => {
      //   //   localStorage.setItem("token", userInfo.payload?.data || token);
      //   // }).catch(err => {
      //   //   console.log(err);
      //   // }
      //   var myHeaders = { "Content-Type": "text/plain" };
      //   var encoded = token;
      //   var requestOptions = {
      //     method: 'POST',
      //     headers: myHeaders,
      //     data: encoded,
      //     url: `${config.keylock_url}/api/v1/refreshOrgAuthToken`
    
      //   };
      //   await axios(requestOptions)
      //   .then((res) => {
      //     localStorage.setItem("token",  btoa(JSON.stringify(res?.data)) || token);
      //   })
      //   .catch((err) => {
      //     localStorage.removeItem("token")
      //     localStorage.removeItem("role_name")
      //     localStorage.removeItem("role_data")
      //     // localStorage.clear();
      //     history.push({
      //       pathname: "/",
      //     });
      //     return false
      //   })
 
      // }
      let data = localStorage.getItem('role_data');
      const accessObj = JSON.parse(atob(data))?.[localStorage.getItem('role_name')]?.access || [];
      if (accessObj?.length === 0) {
        throw new Error("Invalid Permissions")
      }
      return localStorage.getItem('role_name') && accessObj.indexOf(path) > -1 && localStorage.token;
    } catch (err) {
      localStorage.removeItem("token")
      localStorage.removeItem("role_name")
      localStorage.removeItem("role_data")
      // localStorage.clear();
      history.push({
        pathname: "/",
      });
      return false;
    }
  };


  return (
    <Route
      {...rest} render={(props) => (checkAccess()) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{
          pathname: routes.signIn,
        }} />
      )
      }
    />

  );
};

export default PrivateRoute;
