import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { EditorState, convertToRaw, convertFromRaw, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "./style.css";

const customToolbarOptions = {
  options: ["inline", "list"],
  inline: {
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "superscript",
      "subscript",
    ],
    className: "rtf-toolbar-inline",
  },
  list: {
    options: ["unordered", "ordered"],
    className: "rtf-toolbar-list",
  },
};

const insertCharacter = (characterToInsert, editorState) => {
  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    characterToInsert
  );

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    "insert-characters"
  );

  return newEditorState;

  // return EditorState.forceSelection(
  //   newEditorState,
  //   newContent.getSelectionAfter()
  // );
};

export class RichTextfield extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    this.setState({
      // editorState: convertFromRaw(this.props.initialValue)
      editorState: EditorState.moveSelectionToEnd(
        EditorState.createWithContent(stateFromHTML(this.props.initialValue))
      ),
    });
  }

  getButtonAction = (text, createFieldMentions = false) => {
    const contentState = this.state.editorState.getCurrentContent();
    const selection = this.state.editorState.getSelection();

    let newContentState = Modifier.insertText(contentState, selection, text);
    // console.log(convertToRaw(this.state.editorState.getCurrentContent()));

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContentState,
      "insert-characters"
    );

    this.setState({
      editorState: EditorState.forceSelection(
        newEditorState,
        newContentState.getSelectionAfter()
      ),
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const prevEditorState = this.props.initialValue;
    const nextEditorState = nextProps.initialValue;
    if (prevEditorState !== nextEditorState) {
      // If Empty initial value provided then update the editor state with empty content.
      if (!Boolean(nextEditorState)) {
        this.setState({
          editorState: EditorState.createWithContent(
            stateFromHTML(nextEditorState)
          ),
        });
      }
    }

    const prevAppendValue = this.props.appendValue;
    const nextAppendValue = nextProps.appendValue;

    if (prevAppendValue !== nextAppendValue) {
      if (Boolean(nextAppendValue)) {
        const newEditorState = insertCharacter(
          nextAppendValue,
          this.state.editorState
        );
        this.setState(
          {
            editorState: newEditorState,
          },
          () => {
            this.props.onChange(
              stateToHTML(this.state.editorState.getCurrentContent())
            );
            this.props.resetAppendValue();
          }
        );
      }
    }

    return true;
  }

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        // const currentContent = editorState.getCurrentContent()

        // const raw = convertToRaw(editorState.getCurrentContent());
        const html = stateToHTML(editorState.getCurrentContent());
        this.props.onChange(html);
      }
    );
  };

  render() {
    const { withDirectionTags } = this.props;
    const { editorState } = this.state;
    return (
      <>
        <Editor
          editorState={editorState}
          toolbar={customToolbarOptions}
          toolbarClassName={
            withDirectionTags
              ? "rtf-toolbar-with-direction-control"
              : "rtf-toolbar"
          }
          wrapperClassName={
            withDirectionTags ? "rtf-wrapper-with-direction" : "rtf-wrapper"
          }
          editorClassName="rtf-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        {withDirectionTags && (
          <div
            style={{
              display: "flex",
              border: "1px solid #DEE5EC",
              borderTop: "0px",
              padding: "8px",
              borderRadius: "8px",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
                border: "none",
              }}
              onClick={() => this.getButtonAction("<Frequency>")}
            >
              Frequency
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Route>")}
            >
              Route
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Order Dose>")}
            >
              Order Dose
            </Button>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#DEE5EC",
                color: "#2A3C50",
                fontSize: "12px",
              }}
              onClick={() => this.getButtonAction("<Admin Qty>")}
            >
              Admin Qty
            </Button>
          </div>
        )}
      </>
    );
  }
}
