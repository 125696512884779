import React from "react";
import { withAllContexts, withHoverDrawer } from "../../../../HOCs";
import { FrequencyAddEditWrapper } from "clmm-masters";
import { useLocation, withRouter } from "react-router";
import { routes } from "../../../../router/routes";
import { AlertProps } from "../../../../utils";

function FrequencyAddEditComponent(props) {
  const location = useLocation();

  const successMessage = (isEdit) => {
    if (isEdit) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Updated Successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Added Successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  return (
    <FrequencyAddEditWrapper
      _key={location?.state?._key}
      isView={location?.state?.isView}
      onBackClick={() => {
        props.history.push(routes.frequency);
      }}
      onCancelClick={() => {
        props.history.push(routes.frequency);
      }}
      onSaveSuccess={(_key) => {
        props.history.push(routes.frequency);
        successMessage(location?.state?._key);
      }}
    />
  );
}

export default withAllContexts(
  withRouter(withHoverDrawer(FrequencyAddEditComponent))
);
