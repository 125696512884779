import { withStyles } from "@material-ui/core";
import React from "react";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import CustomGeneralMaster from "./customGeneralMaster";

const useStyles = (theme) => ({
  paper: { minWidth: "800px" },
});
class VisitTypeGMTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownData: [],
    };
  }

  getlistData = async () => {
    // GENERAL_MASTERS_READ
    let dropdownDta_ = await this.props.VISIT_TYPE_INDICATOR();
    let dropdownDta = dropdownDta_?.payload?.data;
    this.state.dropDownData = dropdownDta ?? [];
    this.setState({ ...this.state });
  };
  componentDidMount() {
    this.getlistData();
  }

  render() {
    return (
      <CustomGeneralMaster 
          type={"VISITTYPE"} 
          type_={"Visit Type (GM)"}
          disableList={["Code"]}
          uppercaseList={["Code"]}
          dropDownDat={this.state?.dropDownData} 
          options={{"Visit Type Indicator": this.state?.dropDownData}} 
      />
    );
  }
}

const mapStateToProps = (state) => ({
  general_master_list: state?.generalMasterSlice?.generalMastersRead,
});

export default connect(
  mapStateToProps,
  actions
)(withStyles(useStyles)(VisitTypeGMTable));
