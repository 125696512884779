import React from "react";
import { CommonTable, GenerateForm } from "../../../../../components";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

class OrderNature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditFrom: false,
      perPage : 10,
        page: 0
      // saveloading: false,
      // edit_data: null
    };
    // this.saveForm = this.saveForm.bind(this)
    // this.handleEdit = this.handleEdit.bind(this)
    // this.handleCheckBox = this.handleCheckBox.bind(this)
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    await this.props.ORDERNATUREREAD({ page: (currentPage - 1) * PerPage, perPage: PerPage });

  };


  componentDidMount() {
    this.props?.currentTab("orderNature");
    const getdata = async () => {
      await this.props.ORDERNATUREREAD({page: this.state.page, perPage: this.state.perPage});
      //   await this.props.ORDERCATEGORYMASTER();
    };
    getdata();
  }
  handleCloseForm = () => {
    const { changeState } = this.props;
    changeState("open", false);
    this.setState({
      openEditFrom: false,
      edit_data: null,
    });
  };

  statusChange = async (e, values, index) => {
    
    let data = {
      code: values.code,
      description: values.short,
      status: e.target.checked,
      _key: values._key,
      id: values.id,
    };
    await this.props.UPDATEORDERNATURETABLESTATE({
      data,
    });
    await this.props.ORDERNATUREREAD({page: this.state.page, perPage: this.state.perPage});
     this.props.alert.setSnack("update");
    this.handleCloseForm();
  };

  handleEdit = (e, values, index) => {
    const { changeState } = this.props;
    changeState("open", true);
    changeState("openIndex", 1);
    let data = {
      // _key: values?._key,
      _key: values?.coding?.[0]?._key,
      short: values?.short,
      long: values?.long,
      code: values?.code,
      status: values?.status,
    };
    this.setState({
      ...this.state,
      edit_data: data,
    });
  };

  saveOrderNature = async (values) => {
    if (this.state.edit_data) {
      let data = {
        ordertype: values?.order_nature,
        shortdesc: values?.short_Description,
        longdesc: values?.long_Description,
        status: this.state.edit_data.status,
        _key: this.state.edit_data._key,
        code: values?.order_nature,
      };
      let edit_data = await this.props.UPDATESETORDERNATURETABLE({ data });
      if(edit_data?.payload?.data?.error){
        this.props.alert.setSnack("error");
        return false;
      }else{
        this.props.alert.setSnack("update");
      }

      await this.props.ORDERNATUREREAD({page: this.state.page, perPage: this.state.perPage});
      this.setState({
        openEditFrom: false,
        edit_data: null,
      });
      this.handleCloseForm();
    } else {
      let data = {
        ordertype: values?.order_nature,
        shortdesc: values?.short_Description,
        longdesc: values?.long_Description,
        status: true,
      };
      let addnew = await this.props.INSERTORDERNATURETABLE({ data });
      if(addnew?.payload?.data?.error){
        this.props.alert.setSnack("error");
        return false;
      }else{
        this.props.alert.setSnack("success");
      }
      await this.props.ORDERNATUREREAD({page: this.state.page, perPage: this.state.perPage});
      this.setState({
        openEditFrom: false,
        edit_data: null,
      });
      this.handleCloseForm();
    }
  };
  render() {
    const { open, openIndex, parent_id } = this.props;
    return (
      <React.Fragment>
        <CommonTable
          style={{ height: "calc(100vh - 245px)" }}
          parent_id={"order_nature"}
          // handleEdit={this.handleEdit}
          Header={[
            "S.No",
            "Order Nature",
            "Short Description",
            "Long Description",
            "Status",
            "Action",
          ]}
          dataList={
            this.props?.OrderNature?.order_nature_read?.data
              ? this.props?.OrderNature?.order_nature_read?.data
              : []
          }
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "code" },
            { type: ["TEXT"], name: "short" },
            { type: ["TEXT"], name: "long" },
            { type: ["CHECKBOX"], name: "status" },
            { type: ["EDIT"], name: "" },
          ]}
          handleCheckBox={this.statusChange}
          handleEdit={this.handleEdit}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={this.props?.OrderNature?.order_nature_read?.data?.[0]?.total_count}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <GenerateForm
          parent_id={"order_nature"}
          open={open && openIndex === 1}
          handlecancel={() => this.handleCloseForm()}
          header={ this.state?.edit_data?._key ? "Edit Order Nature" : "Add New Order Nature"}
          nocancel
          save={this.saveOrderNature}
          json={[
            {
              componet_type: "text",
              label: "Order Nature Code",
              state_name: "order_nature",
              value: this.state?.edit_data?.code ?? "",
              required: true,
              isUpperCase: true,
              disabled: this.state?.edit_data?.code ? true : false,
              textLength: 20
            },
            {
              componet_type: "text",
              label: "Short Description",
              state_name: "short_Description",
              value: this.state?.edit_data?.short ?? "",
              required: true,
              textLength:30
            },
            {
              componet_type: "text",
              label: "Long Description",
              state_name: "long_Description",
              value: this.state?.edit_data?.long ?? "",
              required: true,
              textLength:60
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  OrderNature: state?.ordernatureSlice,
});

export default connect(mapStateToProps, actions)(withAllContexts(withRouter(OrderNature)));
