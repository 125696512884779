import React from "react";
import NavbarDrawer from "./components/navbars/sideNavbar/sidenavbar";
// import Sidemenu from "./components/navbars/sideNavbar/sidemenu";
import TabPanel from "./components/tab";
import Grid from "@material-ui/core/Grid";

function Combine(props) {
  return (
    <div style={{ display: "flex" }}>
      <Grid container spacing={0} style={{ width: "5%", height: "100%" }}>
        <Grid item md={2}>
          {props.drawer}
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ width: "100%" }}>
        <Grid item md={12}>
          {props.tab}
        </Grid>
      </Grid>
    </div>
  );
}

function Insert(props) {
  return <Combine tab={<TabPanel />} drawer={<NavbarDrawer />} />;
}
export default Insert;
