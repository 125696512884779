import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../font.css";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { valitationFunc } from "../../utils";
import { withAllContexts } from "../../HOCs";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    "&. .makeStyles-formControl-100": {
      margin: "0px !important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
    fontFamily: "poppin",
  },
  req_red: {
    color: "red",
  },
}));

const errorlist = ["ResourceRole", "ResourceType", "Duration"];

function AppointmentPopover(props) {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [state, setState] = React.useState({
    SeqNumber: null,
    ResourceType: "",
    ResourceRole: "",
    ResourceInclusionCriteria: "",
    Duration: null,
    DurnUOM: "",
    RequiredYN: true,
    error: {},
  });

  const [Alertstate, setAlertState] = React.useState(false);
  const dispatch = useDispatch();
  const resourceAppointment = useSelector(
    (state) => state.resourceByAppointmentTypesSlice
  );

  React.useLayoutEffect(() => {
    const getdata = async () => {
      await dispatch(actions.RESOURCE_TYPE_DROP_DOWN());
    };
    getdata();
  }, []);

  React.useEffect(() => {
    if (props.props?.editData?.length > 0) {
      setState({
        ...state,
        ResourceRole: props.props?.editData[0].ResourceRole,
        ResourceType: props.props?.editData[0].ResourceType,
        Duration: props.props?.editData[0].Duration,
      });
    }

    const getdata = async () => {
      if (props.props?.editData?.length > 0) {
        await dispatch(
          actions.RESOURCE_ROLE_DROP_DOWN({
            title: props.props?.editData[0].ResourceType.title,
          })
        );
      }
    };
    getdata();
  }, [props?.editData]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState(false);
  };
  const resoucetype = (e, val, types, limit) => {
    if (limit) {
      debugger;
      if (e.target.value?.length > limit) {
        return false;
      }
    }

    if (state.error[types]) {
      state.error[types] = false;
    }
    if (types === "ResourceType") {
      let title = val.title;
      const getdata = async () => {
        await dispatch(
          actions.RESOURCE_ROLE_DROP_DOWN({
            title,
          })
        );
      };
      getdata();
      setState({ ...state, [types]: val });
    } else if (types === "Duration") {
      setState({ ...state, [types]: e.target.value });
    } else {
      setState({ ...state, [types]: val });
    }
  };
  const appointmentFunClick = async () => {
    let { error, errorState } = await valitationFunc(state, errorlist);
    if (!error) {
      props.props.onChangeAll("", state, "TotNoOfResourcesRequired");
      props.Close();
    } else {
      // setAlertState(true);
      props.alert.setSnack("mandatory");
      setState({
        ...state,
        error: errorState,
      });
    }
  };
  return (
    <div className={classes.main}>
      <Snackbar
        open={Alertstate}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Alert severity="error">Please fill all Mandatory Fields</Alert>
      </Snackbar>
      <hr />
      <div style={{ padding: 16 }}>
        <div style={{ marginBottom: 14, marginTop: 20 }}>
          <span
            style={{
              color: "#101010",
              fontSize: 16,
              fontFamily: "poppinsemibold",
            }}
          >
            ADD NEW RESOURCE
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "2%" }}>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Resource Type<span className={classes.req_red}>*</span>
            </span>
            <Autocomplete
              id="combo-box-demo"
              options={
                resourceAppointment?.resource_type_drop_down?.data
                  ? resourceAppointment?.resource_type_drop_down?.data
                  : null
              }
              getOptionLabel={(option) => option.title}
              style={{ width: 300, marginTop: "1%" }}
              size={"small"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={state?.error?.ResourceType}
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) =>
                resoucetype(event, newValue, "ResourceType")
              }
              value={state.ResourceType}
            />
          </div>

          <div>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Resource Role <span className={classes.req_red}>*</span>
            </span>

            <Autocomplete
              id="combo-box-demo"
              options={
                resourceAppointment?.resource_role_drop_down?.data
                  ? resourceAppointment?.resource_role_drop_down?.data
                  : null
              }
              getOptionLabel={(option) => option.title}
              style={{ width: 300, marginTop: "1%" }}
              size={"small"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={state?.error?.ResourceRole}
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) =>
                resoucetype(event, newValue, "ResourceRole")
              }
              value={state.ResourceRole}
            />
          </div>
          <div style={{ marginLeft: "2%" }}>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Slot Duration <span className={classes.req_red}>*</span>
            </span>
            <div>
              <TextField
                size="small"
                variant="outlined"
                onChange={(event, newValue) =>
                  resoucetype(event, newValue, "Duration", 3)
                }
                value={state.Duration}
                // type={""}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                error={state?.error?.Duration}
              />
            </div>
          </div>
        </div>
        <Card
          style={{ padding: 16, paddingTop: 0, marginTop: "1%" }}
          elevation="2"
        >
          <p style={{ fontFamily: "poppinsemibold" }}>
            RESOURCE INCLUSION CRITERIA
          </p>
          <Button variant="outlined" color="secondary">
            + Add New Rule
          </Button>
        </Card>
        <div
          style={{
            display: "flex",
            marginTop: "30px",
            float: "right",
            paddingBottom: "20px",
          }}
        >
          <Button
            onClick={() => {
              props.Close();
            }}
            variant="outlined"
          >
            cancel
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={appointmentFunClick}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withAllContexts(AppointmentPopover);
