import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    borderRadius: 8,
    "&.MuiToggleButton-root": {
      padding: theme.spacing(1.5),
      "&.Mui-selected": {
        background: theme.palette.primary.main,

        color: "#fff",
      },
    },
  },
}))(ToggleButtonGroup);

function ToggleButtonsMultiple(props) {
  const handleFormat = (event, newFormats) => {
    props.handleToggle(props.name, newFormats);
  };

  return (
    <StyledToggleButtonGroup
      value={props.values ?? []}
      onChange={handleFormat}
      aria-label="Toggle"
    >
      {props.data.map((v) => {
        return (
          <ToggleButton value={v.value} aria-label={v.value}>
            {v.label}
          </ToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
}

export default ToggleButtonsMultiple;
