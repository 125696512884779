import React from "react";
import { VerticalList, CommonTable } from "../../../../components";
import { DrugClass } from "./drugClass";
import { DrugType } from "./drugType";
import { DrugCategory } from "./drugCategory";
import { DrugForm } from "./drugForm";
import { DrugRoutes } from "./drugRoutes";
import { DrugUOM } from "./drugUOM";
import { DrugRouteDirection } from "./drugRoutesDirection";
import FrequencyMaster from "./frequencyMaster";
import DrugMaster from "./drugMaster";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";

class Drugs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openIndex: null,
      currentTab:'',
      search:'',
      page: 0,
      perPage: 10
    };
    this.handleAddBtn = this.handleAddBtn.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeState = this.changeState.bind(this);
  }
  handleAddBtn = (index) => {
    let { open } = this.state;
    this.setState({
      open: !open,
      openIndex: index,
    });
  };
  changeState = (name, value) => {
    this.setState({ [name]: value });
  };
  changeTab = () => {
    this.setState({ open: false, search: "" });
  };



  currentTab = (val) => {
    this.setState({
      currentTab:val
  })
    return val
  };

  getPlaceHolder = () => {

    if(this.state?.currentTab === "drugClass"){
      return "search code & description"
    }
    
}


  handleSearch = async(value = "") => {
debugger
    if(this.state?.currentTab === "drugClass"){
 
     if (value?.length > 0) {
       await this.props.DRUG_CLASS_READ({
         search:value.trim(),
         page: this.state?.page,
         perPage: this.state?.perPage,
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
       debugger
     } else if (!value) {
      await this.props.DRUG_CLASS_READ({
        page: this.state?.page,
        perPage: this.state?.perPage,
      });
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };

    if(this.state?.currentTab === "drugType"){
 
     if (value?.length > 0) {
       await this.props.DRUG_TYPE_READ({
         search:value.trim(),
         page: this.state?.page,
         perPage: this.state?.perPage,
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.DRUG_TYPE_READ({
        page: this.state?.page,
        perPage: this.state?.perPage,
      });
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };

    if(this.state?.currentTab === "drugCategory"){
 
     if (value?.length > 0) {
       await this.props.DRUG_CATEGORY_READ({
         search:value.trim(),
         page: this.state?.page,
         perPage: this.state?.perPage,
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.DRUG_CATEGORY_READ({
        page: this.state?.page,
        perPage: this.state?.perPage,
      });
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };

    if(this.state?.currentTab === "drugUOM"){
 
     if (value?.length > 0) {
       await this.props.DRUG_UOM_READ({
         search:value.trim(),
         page: this.state?.page,
         perPage: this.state?.perPage,
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.DRUG_UOM_READ({
        page: this.state?.page,
        perPage: this.state?.perPage,
      });
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };

    if(this.state?.currentTab === "drugDosage"){
 
     if (value?.length > 0) {
       await this.props.DRUG_DOSAGE_READ({
         search:value.trim(),
         page: this.state?.page,
         perPage: this.state?.perPage,
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.DRUG_DOSAGE_READ({
        page: this.state?.page,
        perPage: this.state?.perPage,
      });
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };

    if(this.state?.currentTab === "drugRoutes"){
 
     if (value?.length > 0) {
       await this.props.DRUG_ROUTES_READ({
         search:value.trim(),
         page: this.state?.page,
         perPage: this.state?.perPage,
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.DRUG_ROUTES_READ({
        page: this.state?.page,
        perPage: this.state?.perPage,
      });
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };

    if(this.state?.currentTab === "drugRoutesDirection"){
 
     if (value?.length > 0) {
       await this.props.DRUG_ROUTES_DIRECTION_READ({
         search:value.trim(),
         page: this.state?.page,
         perPage: this.state?.perPage,
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.DRUG_ROUTES_DIRECTION_READ({
        page: this.state?.page,
        perPage: this.state?.perPage,
      });
     }
     this.setState({
       ...this.state,
       search: ""
     });
   };

    if(this.state?.currentTab === "drugMaster"){
 
     if (value?.length > 0) {
       await this.props.DRUG_MASTER_READ({
         search:value.trim(),
         page: this.state?.page,
         perPage: this.state?.perPage,
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.DRUG_MASTER_READ({
        page: this.state?.page,
        perPage: this.state?.perPage,
      });
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };

    if(this.state?.currentTab === "frequencyMaster"){
 
     if (value?.length > 0) {
       await this.props.DRUG_FREQUENCY_READ({
         search:value.trim(),
         page: this.state?.page,
         perPage: this.state?.perPage,
       });
       this.setState({
         ...this.state,
         search: value.trim()
       });
     } else if (!value) {
      await this.props.DRUG_FREQUENCY_READ({
        page: this.state?.page,
        perPage: this.state?.perPage,
      });
     }
     this.setState({
       ...this.state,
       search: ""
     });
 
   };
   }






















  render() {
    const { open, openIndex } = this.state;
    return (
      <React.Fragment>
        <VerticalList
          parent_id={"drugs"}
          isLeftList={true}
          addFunc={this.handleAddBtn}
          changeTab={this.changeTab}
          hiddenButton={[1, 2]}
          // searchCmpProps starts here
          id={"orders"}
          placeholder={this?.getPlaceHolder()}
          // loading={this.props?.searchLoading}
          onSearchChange={this?.handleSearch}
          hideSearchBar={false}
          hideFilter={true}
          // onFilterClicked={this.props?.onFilterClicked}
          // isFilterApplied={this.props?.isFilterApplied}
          TabList={[
            {
              name: "Drug Class",
              component: (
                <DrugClass
                  parent_id={"drugs"}
                  changeState={this.changeState}
                  open={open}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            {
              name: "Drug Type",
              component: (
                <DrugType
                  parent_id={"drugs"}
                  open={open}
                  changeState={this.changeState}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
              hiddenButton: true,
            },
            {
              name: "Drug Category",
              component: (
                <DrugCategory
                  parent_id={"drugs"}
                  open={open}
                  changeState={this.changeState}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
              hiddenButton: true,
            },
            {
              name: "Drug UOM",
              component: (
                <DrugUOM
                  parent_id={"drugs"}
                  open={open}
                  closeFrom={this.handleAddBtn}
                  changeState={this.changeState}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            {
              name: "Dosage Form",
              component: (
                <DrugForm
                  parent_id={"drugs"}
                  open={open}
                  changeState={this.changeState}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            {
              name: "Drug Routes",
              component: (
                <DrugRoutes
                  parent_id={"drugs"}
                  open={open}
                  changeState={this.changeState}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            {
              name: "Drug Route Direction",
              component: (
                <DrugRouteDirection
                  parent_id={"drugs"}
                  open={open}
                  closeFrom={this.handleAddBtn}
                  changeState={this.changeState}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            // {
            //   name: "Drug Master",
            //   component: (
            //     <DrugMaster
            //       parent_id={"drugs"}
            //       open={open}
            //       closeFrom={this.handleAddBtn}
            //       changeState={this.changeState}
            //       openIndex={openIndex}
            //       search={this.state.search}
            //       setSearch={(value) => this.changeState("search", value)}
            //       currentTab={this?.currentTab}
            //     />
            //   ),
            //   searchable: true,
            //   search: {
            //     title: "Search Code & Description",
            //     value: this.state?.search,
            //     handleSearch: (value) => this.changeState("search", value),
            //   },
            // },
            // {
            //   name: "Frequency Master",
            //   component: (
            //     <FrequencyMaster
            //       parent_id={"FrequencyMaster"}
            //       open={open}
            //       changeState={this.changeState}
            //       openIndex={openIndex}
            //       currentTab={this?.currentTab}
            //     />
            //   ),
            // },
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
    
});

export default connect(
  mapStateToProps,
  actions
)(Drugs);
