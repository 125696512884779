import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AppointmentPopover from "./appointmentPopover";
import expandarrow from "../../assets/icons8-expand-arrow.svg";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/CreateOutlined";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  dot: {
    height: 6,
    width: 6,
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "4px",
    marginLeft: "4px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  newRes: {
    fontSize: 13,
    fontWeight: "bold",
  },
}));

const ResourceCard = (props) => {
  const classes = useStyles();

  const [show, setshow] = React.useState(false);

  const handleClick = () => {
    setshow(!show);
  };
  return (
    <div>
      <Card
        style={{
          padding: "18px",
          width: "95%",
          marginLeft: "16px",
          marginTop: "0px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <p style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
                RESOURCES
              </p>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  className={classes.newRes}
                  color="primary"
                  onClick={handleClick}
                >
                  + Add New Resources
                </Button>
                <div style={{ marginBottom: "5px" }}>
                  <img
                    src={expandarrow}
                    alt="icon"
                    style={{
                      transform: show ? "rotate(180deg)" : "rotate(359deg)",
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {props.data.map((val, i) => {
            return (
              <Grid
                container
                style={{
                  border: "1px solid #E0E0E0",
                  padding: 12,
                  marginBottom: 8,
                  backgroundColor: "#F9F9F9",
                  position: "relative",
                }}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{
                        backgroundColor: "#E0E0E0",
                        position: "absolute",
                        left: 0,
                        padding: "6px 12px",
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                      }}
                    >
                      {i + 1}
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: "4%" }}>
                      <div>{val.ResourceType.title}</div>
                      <div>
                        {val.ResourceRole.title} <div className={classes.dot} />
                        {val.Duration}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        style={{ color: "#0071F2", fontSize: 18 }}
                        onClick={() => {
                          setshow(true);
                          props.editFun("TotNoOfResourcesRequired", i);
                        }}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="DeleteIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <DeleteIcon
                        onClick={() =>
                          props.deleteFun("TotNoOfResourcesRequired", i)
                        }
                        style={{ color: "#FF4D4A", fontSize: 18 }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          {show && <AppointmentPopover Close={handleClick} props={props} />}
        </Card>
      </Card>
    </div>
  );
};
export default ResourceCard;
