import React from "react";
import { Upload, Row, Col, TextInput } from "qdm-component-library";
import {
	Avatar,
	makeStyles,
	Grid,
	TextField,
	FormLabel,
} from "@material-ui/core";
import { uploadImage, getImgUrl, 
	//AlertProps, getEntityTypeCode
 } from "../../../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../../HOCs";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => ({
	main: {
		backgroundColor: "white",
		// marginTop: "2vw",
		// marginLeft: "2vw",
		// width: "90vw",
		borderRadius: 8,
		padding: "14px 14px 14px 0px",
	},
	paddingLeft0: {
		paddingLeft: 0,
	},
	req_red: {
		color: "red",
	},
	disableFiled: {
		backgroundColor: "#f0f0f0 !important"
	},
	labelStyleNew: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
		marginBottom: 10
	},
}));
const styles = {
	labelStyle: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
	},
	borderStyle: {
		borderRadius: 8,
		borderColor: "#E0E0E0",
	},
	errorBorderStyle: {
		borderRadius: 8,
		border: "1px solid red",
	}
};
const EntityDetails = (props) => {
	const classes = useStyles();
	//const details = {};
	const { parent_id } = props;
	const { labelStyle, 
		//errorBorderStyle 
	} = styles;

	const dispatch = useDispatch();
	const organizationSlice = useSelector((state) => state.organizationSlice);

	React.useEffect(() => {
		const getdata = async () => {
			await dispatch(actions.ORGANIZATION_ENTITY_TYPE());
			await dispatch(actions.ORGANIZATION_GET_LEVEL_CARE());
		};
		getdata();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setDataUpload = async (name, value, next, limit) => {
		
		if (!value.type.includes("image")) {
			props.alert.setSnack("error","Please Select an Image with size less than 1MB.");
			return false;
		}
		var UploadedFiles = [];
		if (limit) {
			if (limit < value.length) {
				return false;
			}
		}
		UploadedFiles = await uploadImage([value]);
		// 
		props.onChangeAll("e", UploadedFiles, "imageDetails");
	};
	//
	return (
		<div style={{padding: "24px 20px 0px 26px"}} >
		<Grid
			id={`${parent_id}-parent-div`}
			className={classes.main} 
			container
			direction="row"
			justifyContent="flex-start"
			alignItems="flex-start"
			>
			<Grid
				id={`${parent_id}-parent-sub-grid`}
				item 
				// style={{ width: "158px", marginRight: 12 }}
				md={2}
			>
				{props?.data?.imageDetails ? (
					<div
						id={`${parent_id}-parent-sub-div`}
					>
						<Avatar
							id={`${parent_id}-fileName-Avatar`}
							alt={props?.data?.imageDetails[0]?.fileName}
							variant={"square"}
							style={{ width: "158px", height: "158px", margin: "auto" }}
							src={getImgUrl(props?.data?.imageDetails[0]?.fileid)}
						/>
						<Upload
							id={`${parent_id}-img-Upload`}
							label="Upload Image"
							className={"pn-image"}
							labelStyle={{
								fontSize: 14,
								letterSpacing: "0px",
								color: "#6F6F6F !important",
								backgroundColor: "#fff !important",
								height: "40px",
								width: "150px",
								paddingRight: "10px",
								paddingTop: "8px",
								textAlign: "center",
								marginTop: "5px",
								border: "2px solid #00000014",
							}}
							inLineStyles={{ padding: 0, width: "100%" }}
							showIcon={false}
							handleChange={(e, value) => setDataUpload("photo", value)}
						// imgUrl="https://fileupload.dev.ainqaplatform.in/primarycare/10204"
						/>
					</div>
				) : (
					<div>
						<Upload
							id={`${parent_id}-img-Upload`}
							label="Upload Image"
							className={"pn-image"}
							labelStyle={{
								fontSize: 14,
								letterSpacing: "0px",
								color: "#6F6F6F !important",
								backgroundColor: "#00000014 !important",
								height: "146px",
								width: "150px",
								margin: "auto",
								paddingRight: "10px",
								// paddingTop: "44%",
								textAlign: "center",
								justifyContent: "center",
    							alignItems: "center",
    							display: "flex"
							}}
							inLineStyles={{ padding: 0, width: "100%" }}
							showIcon={false}
							handleChange={(e, value) => setDataUpload("photo", value)}
						// imgUrl="https://fileupload.dev.ainqaplatform.in/primarycare/10204"
						/>
					</div>
				)}
			</Grid>
			<Grid id={`${parent_id}-ENTITY-DETAILS-parent-grid`} container item md={10} xs={10}>
				<p id={`${parent_id}-ENTITY-DETAILS-title-p`}>{props?.isexternal ? "EXTERNAL ENTITY " : "ENTITY "} DETAILS</p>
				<Grid id={`${parent_id}-ENTITY-DETAILS-grid`} container direction="column">
					<Row id={`${parent_id}-ENTITY-DETAILS-row`} inLineStyles={{ paddingBottom: "2%" }}>
						<Col
							id={`${parent_id}-ENTITY-DETAILS-col`}
							md={4} lg={4} sm={4} xs={12} className={classes.paddingLeft0}>
							<FormLabel
								id={`${parent_id}-Entity-Type-FormLabel`}
								component="legend"
								// style={{ ...labelStyle, marginBottom: 10 }}
								classes={{root:classes.labelStyleNew}}
							>
								Entity Type <span className={classes.req_red}>*</span>
							</FormLabel>
							<Autocomplete
								id={`${parent_id}-Entity-Type-autocomplete`}
								getOptionLabel={(option) => option.title}
								style={{ width: "auto", marginTop: "1%" }}
								size={"small"}
								renderInput={(params) => (
									<TextField
										id={`${parent_id}-Entity-Type-textField`}
										{...params}
										variant="outlined"
										error={props.data?.error?.EntityType}
									// placeholder={"Entity Type"}
									/>
								)}
								options={
									organizationSlice?.organizationEntityType?.data
										? organizationSlice?.organizationEntityType?.data
										: []
								}
								onChange={(e, val) => props.onChangeAll(e, val, "EntityType")}
								value={props?.data?.EntityType}
								disabled={props?.edit ? true : false}
							/>
						</Col>
						<Col
							id={`${parent_id}-Entity-code-col`}
							//className="no-padding-left"
							md={4}
							lg={4}
							sm={4}
							xs={12}
							className={classes.paddingLeft0}
						>
							<TextInput
								id={`${parent_id}-Entity-code-textInput`}
								label="Entity Code"
								isRequired={true}
								placeholder="Entity Code"
								labelStyle={labelStyle}
								hoverColor="#0071F2"
								onChange={(e, val) =>
									props.onChangeAll(e, e.target?.value?.replaceAll(" ", "")?.toLocaleUpperCase(), "EntityCode")
								}
								disabled={props?.edit ? true : false}
								value={props?.data?.EntityCode}
								error={props.data?.error?.EntityCode}
							/>
						</Col>
						<Col
							id={`${parent_id}-Entity-name-col`}
							md={4} lg={4} sm={3} xs={12} className={classes.paddingLeft0}>
							<TextInput
								id={`${parent_id}-Entity-name-textInput`}
								label="Entity Name"
								isRequired={true}
								placeholder="Entity Name"
								labelStyle={labelStyle}
								hoverColor="#0071F2"
								onChange={(e, val) =>
									props.onChangeAll(e, e.target.value, "EntityName")
								}
								value={props?.data?.EntityName}
								error={props.data?.error?.EntityName}
							/>
						</Col>
						{/* {props?.isexternal &&
						<Col id={`${parent_id}-Entity-name-col`} md={2} lg={2} sm={4} xs={12} className={classes.paddingLeft0}>
							<FormControlLabel
								control={
								<Checkbox
									checked={props?.data?.external?true:false}
									onChange={(e) => props.onChangeAll(e, e.target.checked, "external")}
									// name="External"
									color="primary"
								/>
								}
								label="External"
								style={{paddingTop: "18px", paddingLeft: "12px"}}
							/>
						</Col>} */}
					</Row>
					<Row
						id={`${parent_id}-parent-title-row`}
					>
						<Col
							id={`${parent_id}-Parent-Entity-title-col`}
							md={4} lg={4} sm={4} xs={12} className={classes.paddingLeft0}>
							<FormLabel
								id={`${parent_id}-Parent-Entity-FormLabel`}
								component="legend"
								// style={{ ...labelStyle, marginBottom: 10 }}
								classes={{root:classes.labelStyleNew}}
							>
								Parent Entity {" "}
								{props?.data?.EntityType?.title !== "Enterprise" && <span className={classes.req_red}>*</span>}
							</FormLabel>
							<Autocomplete
								id={`${parent_id}-Parent-Entity-autocomplete`}
								getOptionLabel={(option) => option.title}
								style={{ width: "auto", marginTop: "1%" }}
								size={"small"}
								renderInput={(params) => (
									<TextField
										id={`${parent_id}-Parent-Entity-textField`}
										{...params}
										variant="outlined"
										error={props.data?.error?.ParentEntity}
									// placeholder={"Parent Entity"}
									/>
								)}
								options={
									organizationSlice?.orgParEntity?.data
										? organizationSlice?.orgParEntity?.data
										: []
								}
								onChange={(e, val) => props.onChangeAll(e, val, "ParentEntity")}
								value={props?.data?.ParentEntity}
								disabled={
									props?.data?.EntityType?.title === "Enterprise" ? true : false
								}
								className={props?.data?.EntityType?.title === "Enterprise" ? classes.disableFiled : ""}
							/>
						</Col>
						<Col
							id={`${parent_id}-Level-Of-Care-col`}
							//className="no-padding-left"
							md={4}
							lg={4}
							sm={4}
							xs={12}
							className={classes.paddingLeft0}
						>
							<FormLabel
								id={`${parent_id}-Level-Of-Care-FormLabel`}
								component="legend"
								// style={{ ...labelStyle, marginBottom: 10 }}
								classes={{root:classes.labelStyleNew}}
							>
								Level Of Care <span
									id={`${parent_id}-Level-Of-Care-star-span`}
									className={classes.req_red}>*</span>
							</FormLabel>
							<Autocomplete
								id={`${parent_id}-Level-Of-Care-autocomplete`}
								getOptionLabel={(option) => option.title}
								style={{ width: "auto", marginTop: "1%" }}
								size={"small"}
								renderInput={(params) => (
									<TextField
										id={`${parent_id}-Level-Of-Care-textField`}
										{...params}
										variant="outlined"
										placeholder={"Level Of Care"}
										error={props.data?.error?.LevelOfCare}
									/>
								)}
								options={
									organizationSlice?.orgLevelCare?.data
										? organizationSlice?.orgLevelCare?.data
										: []
								}
								onChange={(e, val) => props.onChangeAll(e, val, "LevelOfCare")}
								value={props?.data?.LevelOfCare}
							/>
						</Col>
					</Row>
				</Grid>
			</Grid>
		</Grid>
		</div>
	);
};
export default withAllContexts(EntityDetails);
