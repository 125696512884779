import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/CreateOutlined";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  req_red: {
    color: "red",
  },
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  line: {
    borderLeft: "3px solid #e1e1e1",
    marginRight: "8px",
    marginLeft: "8px",
  },
}));

export default function GeneralAppointmentCard(props) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Alertstate, setAlertState] = React.useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [state, setState] = React.useState({
    fcode: null,
    description: "",
  });

  const resourceAppointment = useSelector(
    (state) => state.resourceByAppointmentTypesSlice
  );
  React.useLayoutEffect(() => {
    const getdata = async () => {
      await dispatch(actions.RES_GENERAL_INSTRUCTION());
    };
    getdata();
    if (props?.editData?.length > 0) {
      setState({
        ...state,
        fcode: {
          title: props?.editData[0]?.fcode?.title,
          value: props?.editData[0]?._id,
        },
        description: props?.editData[0]?.description,
      });
    }
  }, [props?.editData]);

  React.useEffect(() => {
    if (props?.editData?.length > 0) {
      document.getElementById("general_appointment_card_Id").click();
    }
  }, [props?.editData]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setState({
      ...state,
      fcode: null,
      description: "",
      _id: {},
    });
    setAnchorEl(null);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertState(false);
  };
  const generateFun = (e, val, type) => {
    if (type === "dis") {
      setState({
        ...state,
        description: e?.target?.value,
      });
    } else {
      setState({
        ...state,
        fcode: { title: val?.title, value: val?._id },
        description: val?.value,
        _id: val?._id,
      });
    }
  };
  const savegenerateFun = () => {
    if (state.fcode) {
      // props.onChangeAll("", state, "generalInstruction");
      handleClose();
    } else {
      setAlertState(true);
    }
  };
  console.log("resourceAppointment", resourceAppointment);
  return (
    <div>
      <Snackbar
        open={Alertstate}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleCloseAlert}
      >
        <Alert severity="error">Fill all Mandatory Fields</Alert>
      </Snackbar>
      <Card
        style={{
          padding: "18px",
          width: "95%",
          marginLeft: "16px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} style={{ display: "flex" }}>
              <p style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
                GENERAL APPOINTMENT INSTRUCTIONS{" "}
              </p>
              {props?.required ? (
                <span style={{ color: "red" }}>&nbsp;{"*"}</span>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  className={classes.newRes}
                  color="primary"
                  onClick={handleClick}
                  id={"general_appointment_card_Id"}
                >
                  + Add New
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {props?.data?.map((val, i) => {
            return (
              <Grid
                container
                style={{
                  border: "1px solid #E0E0E0",
                  padding: 12,
                  marginBottom: 8,
                  backgroundColor: "#F9F9F9",
                  position: "relative",
                }}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{
                        backgroundColor: "#E0E0E0",
                        position: "absolute",
                        left: 0,
                        padding: "6px 12px",
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                      }}
                    >
                      {i + 1}
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: "4%" }}>
                      <div>General Instrctions</div>
                      <div>
                        {val?.fcode?.title ? val?.fcode.title : val?.fcode}{" "}
                        <div className={classes.dot} />
                        {val?.description}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        onClick={() => props.editFun("generalInstruction", i)}
                        style={{ color: "#0071F2", fontSize: 18 }}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="DeleteIcon"
                      className={classes.margin}
                      size="small"
                      onClick={() => props.deleteFun("generalInstruction", i)}
                    >
                      <DeleteIcon style={{ color: "#FF4D4A", fontSize: 18 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Card>
      </Card>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div style={{ padding: 20 }}>
          <p
            style={{
              color: "#6F6F6F",
              fontSize: 14,
              marginBottom: 4,
              marginTop: 0,
            }}
          >
            Instruction Code <span className={classes.req_red}>*</span>
          </p>
          <Autocomplete
            id="combo-box-demo"
            options={
              resourceAppointment?.res_general_instruction?.data
                ? resourceAppointment?.res_general_instruction?.data
                : null
            }
            getOptionLabel={(option) => option.title}
            style={{ width: 400, marginTop: "1%" }}
            size={"small"}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            onChange={(e, val) => generateFun(e, val)}
            value={state?.fcode}
          />
          <div>
            <p style={{ color: "#6F6F6F", fontSize: 14, marginBottom: 4 }}>
              Description
            </p>
          </div>
          <div>
            <textarea
              style={{
                width: "100%",
                height: "108px",
                fontSize: 16,
                resize: "none",
                padding: 14,
              }}
              onChange={(e, val) => generateFun(e, val, "dis")}
              value={state?.description}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              float: "right",
              paddingBottom: "30px",
            }}
          >
            <Button
              // variant="contained"
              variant="outlined"
              size="medium"
              style={{ marginRight: "14px" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={savegenerateFun}
            >
              Save
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
