import React from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { WebDataRocksTable } from '../../../components';
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Transfer, Reschedule, Cancel } from './components';
import { GenerateForm } from '../../../components';

const useStyles = makeStyles(() => ({
    header: {
        fontSize: '16px',
        color: '#101010',
        fontFamily: 'pc_semibold',
    },
    rowCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    iconWrapper: {
        backgroundColor: '#DCDCDC',
        width: 26,
        height: 26,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 12,
        borderRadius: '50%',
        cursor: 'pointer'
    },
    blueBtn: {
        backgroundColor: '#E5F1FE',
        color: '#0071F2',
        '&:hover': {
            backgroundColor: '#E5F1FE',
            boxShadow: 'none'
        }
    },
    redBtn: {
        backgroundColor: '#FFEDED',
        color: '#FF4D4A',
        '&:hover': {
            backgroundColor: '#FFEDED',
            boxShadow: 'none'
        }
    },
    btn: {
        height: 32,
        borderRadius: 8,
        boxShadow: 'none',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontFamily: 'pc_medium',
        marginLeft: 10,
    }
}));

export const PatientListUi = (props) => {
    const { parent_id } = props;

    const classes = useStyles(props);

    const PENDINGLIST = {
        dataSource: {
            dataSourceType: 'json',
            data: props?.data
        },
        options: {
            grid: {
                type: 'flat',
                showTotals: "off",
                showGrandTotals: "off",
            },
        },
        slice: {
            columns: [
                { uniqueName: 'select' },
                { uniqueName: 'name' },
                { uniqueName: 'mrn' },
                { uniqueName: 'age' },
                { uniqueName: 'mobile no' },
                { uniqueName: 'appt ref no.' },
                { uniqueName: 'appt date & time' },
                { uniqueName: 'reason' },
            ]
        },
        formats: [{
            "thousandsSeparator": "",
            "decimalPlaces": 0,
        }]
    }

    const [state, setState] = React.useState({
        // pendinglist:PENDINGLIST,
        open: false,
        drawerIndex: null,
        selectPractitioner: {},
        scheduleAt: '',
        selectedPendingList: [],
    })

    const customizeCellFunction = (cellBuilder, cellData) => {
        if (cellData?.type === "header" && cellData?.label === "select") {
            cellBuilder.text = "select All"
        }
        else if (cellData?.measure?.uniqueName === "select" && cellData?.type === "value") {
            let checked = cellData.label == "true" ? 'checked' : ''
            cellBuilder.text = `<div style='text-align:center'><input style='zoom:1.3' type="checkbox" ${checked} /></div>`;
        }
    }

    const cellClick = (data, setState) => {
        if (data.columnIndex === 0 && data.measure.uniqueName === "select") {
            PENDINGLIST.dataSource.data[data["rowIndex"] - 1].select = !PENDINGLIST.dataSource.data[data["rowIndex"] - 1].select;
            if (PENDINGLIST.dataSource.data[data["rowIndex"] - 1].select) {
                setState({
                    ...state,
                    selectedPendingList: [...state.selectedPendingList, PENDINGLIST.dataSource.data[data["rowIndex"] - 1]]
                })
            }
            else {

                const filteredData = state.selectedPendingList?.filter((l, i) => l['appt ref no.'] != PENDINGLIST.dataSource.data[data?.["rowIndex"] - 1]?.['appt ref no.']);
                setState({
                    ...state,
                    selectedPendingList: filteredData
                })
            }
        }

    }

    const switchDrawerIndex = (index, open) => {
        setState({
            ...state,
            open,
            drawerIndex: index
        })
    }

    const refreshPendingData = () => {
        // switchDrawerIndex(null,false)
        setState({
            ...state,
            selectedPendingList: [],
            open: false,
            drawerIndex: null
        })
        props?.viewClick(props?.doctorDetails)
    }

    const switchDrawer = (index) => {
        switch (index) {
            case 0:
                return (
                    <Transfer
                        parent_id={'Transfer'}
                        selectedList={state?.selectedPendingList}
                        doctorDetails={props?.doctorDetails}
                        refreshPendingData={refreshPendingData}
                    />
                )
            case 1:
                return (
                    <Reschedule
                        parent_id={'Reschedule'}
                        selectedList={state?.selectedPendingList}
                        doctorDetails={props?.doctorDetails}
                        refreshPendingData={refreshPendingData}
                    // switchDrawerIndex={switchDrawerIndex}
                    />
                )

            case 2:
                return (
                    <Cancel
                        parent_id={'Cancel'}
                        selectedList={state?.selectedPendingList}
                        doctorDetails={props?.doctorDetails}
                        refreshPendingData={refreshPendingData}
                    />
                )
            default:
                return <div />
        }
    }

    const returnHeader = (index) => {
        switch (index) {
            case 0:
                return 'Transfer'
            case 1:
                return 'Reschedule'
            case 2:
                return 'Cancel'
            default:
                return ''
        }
    }

    return (
        <div
            id={`${parent_id}-parent-div`}
        >
            <div
                id={`${parent_id}-parent-sub-div`}
                className={classes.rowCenter}>
                <div
                    id={`${parent_id}-NavigateBeforeIcon-div`}
                    className={classes.rowCenter} style={{ flex: 1 }}>
                    <div
                        id={`${parent_id}-NavigateBeforeIcon-sub-div`}
                        className={classes.iconWrapper} onClick={props?.navigateBack}>
                        <NavigateBeforeIcon
                            id={`${parent_id}-NavigateBeforeIcon`}
                        />
                    </div>
                    <Typography
                        id={`${parent_id}-header-title-typography`}
                        variant="body1" className={classes.header}>{props?.header}</Typography>
                </div>
                <div
                    id={`${parent_id}-button-div`}
                    className={classes.rowCenter}>
                    <Button
                        id={`${parent_id}-Transfer-button`}
                        variant="contained"
                        className={`${classes.btn}
                        ${classes.blueBtn}`}
                        onClick={() => switchDrawerIndex(0, true)}
                        disabled={!Boolean(state?.selectedPendingList?.length)}
                    >
                        Transfer
                    </Button>
                    <Button
                        id={`${parent_id}-Reschedule-button`}
                        variant="contained"
                        className={`${classes.btn}
                        ${classes.blueBtn}`}
                        onClick={() => switchDrawerIndex(1, true)}
                        disabled={!Boolean(state?.selectedPendingList?.length)}
                    >
                        Reschedule
                    </Button>
                    <Button
                        id={`${parent_id}-Cancel-button`}
                        variant="contained"
                        className={`${classes.btn}
                        ${classes.redBtn}`}
                        onClick={() => switchDrawerIndex(2, true)}
                        disabled={!Boolean(state?.selectedPendingList?.length)}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
            {
                state.open && state.drawerIndex > -1 &&
                <GenerateForm
                    parent_id={'patient-GenerateForm'}
                    open={state.open}
                    handlecancel={() => switchDrawerIndex(null, false)}
                    header={returnHeader(state.drawerIndex)}
                    nocancel
                    nosave
                    drawerWidth={500}
                >
                    {switchDrawer(state.drawerIndex)}
                </GenerateForm>
            }
            <div
                id={`${parent_id}-WebDataRocksTable-div`}
                style={{ marginTop: 25 }}>
                <WebDataRocksTable
                    parent_id={'patient-WebDataRocksTable'}
                    report={props?.data?.length ? PENDINGLIST : []}
                    customizeCellFunction={customizeCellFunction}
                    cellClick={(data) => cellClick(data, setState)}
                />
            </div>
        </div>
    )
}