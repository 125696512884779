import React from "react";
import { Button, Grid } from "@material-ui/core";
import { CommonTabList } from "../../../../../../components";
import LoationAlerts from "./locationAlerts";
import ApprovalLevel from "./approvalLevel";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../../../../../HOCs";
import { valitationFunc } from "../../../../../../utils"

let errorList = [
  "ruleType",
  "PrivilegeType",
  "ServiceApplicabiliy",
  "orderingFacility",
];

class AlertRestrictionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleType: {},
      PrivilegeType: {},
      ServiceApplicabiliy: {},
      approvalData: [],
      orderingFacility: '',
      error: {},
      orderCriteria: [],
      patientCriteria: [],
      practitionerCriteria: [],
    };
    this.handleFormState = this.handleFormState.bind(this);
    this.handleApprovalLevel = this.handleApprovalLevel.bind(this);
  }

  handleFormState = (name, value) => {
    let errorObj = JSON.parse(JSON.stringify(this.state.error));
    errorObj[name] = false;
    this.setState({ [name]: value, error: errorObj });
  };

  saveForm = async () => {
    // let finalSaveData = this.state;
    let { error, errorState } = await valitationFunc(this.state, errorList);
    if (error) {
      this.props.alert.setSnack("mandatory");
      this.setState({
        ...this.state,
        error: errorState,
      });
      return;
    }

    await this.handleSave()
    let type = this.props.editData?.orgn_code ? "update" : "success"
    this.props.alert.setSnack(type)
    await this.props.closeForm();
  };
  handleSave = async () => {

    let finalSaveData = this.state;
    const { editData } = this.props;
    finalSaveData.status = editData?.status === undefined ? true : editData?.status;
    await this.props.UPSERT_ALERT({
      list: finalSaveData,
      _key: editData?._key ?? ''
    })
  }

  handleApprovalLevel = (data) => {
    let state = this.state;
    state.approvalData.push(data)
    this.setState({ ...state })
  }

  handleApproveDelete = (val, i) => {
    let state = this.state;
    state.approvalData.splice(i, 1);
    this.setState({ ...state })
  }

  handleApproveEdit = (val, i,) => {
    let state = this.state;
    state.approvalData.splice(i, 1, val)
    this.setState({ ...state })
  }
  async componentDidMount() {

    await this.props.GET_ALL_MASTER();
    const state = this.state;
    const { editData } = this.props;
    if (editData?.orgn_code) {
      state['orderingFacility'] = {
        label: editData?.orgn_code?.name,
        value: editData?.orgn_code?.name,
        _id: editData?.orgn_code?._id,
      }
      state['orderCriteria'] = editData?.order_catalog_criteria ?? []
      state['patientCriteria'] = editData?.patient_criteria ?? []
      state['practitionerCriteria'] = editData?.pract_criteria ?? []
      state['alertText'] = editData?.alert_test_message ?? "";

      state['ServiceApplicabiliy'] = {
        label: editData?.service_applicablity?.display,
        value: editData?.service_applicablity?.display,
        _id: editData?.service_applicablity?._id,
      }
      state['ruleType'] = {
        label: editData?.rule_type?.display,
        value: editData?.rule_type?.display,
        _id: editData?.rule_type?._id,
      }

      state['PrivilegeType'] = {
        label: editData?.privilege_type?.display,
        value: editData?.privilege_type?.display,
        _id: editData?.privilege_type?._id,
      }

      state.approvalData = editData?.appr_leveles?.map(v => {
        return {
          approvalLevel: {
            label: v?.appr_level?.display,
            value: v?.appr_level?.display,
            _id: v?.appr_level?._id,
          },
          approvalDescription: v?.appr_level_description
        }
      })
      this.setState({
        ...state
      })
    }
  }
  render() {
    const { closeForm = () => null, allMaster, parent_id } = this.props;
    return (
      <React.Fragment>
        <CommonTabList
          parent_id={"AlertRestrictionForm"}
          backbtn
          backFun={closeForm}
          title="Alerts, Restrictions, Approvals"
          list={[]}
        />
        <Grid
          id={`${parent_id}-LoationAlerts-parent-grid`}
          style={{ padding: "20px" }}>
          <LoationAlerts
            parent_id={"AlertRestrictionForm"}
            handleFormState={this.handleFormState}
            allMaster={allMaster}
            {...this.state}
          />
        </Grid>
        {this.state.ruleType?.value?.toLocaleLowerCase() === "restriction" && this.state.PrivilegeType?.value?.toLocaleLowerCase() === "approval required" ?
          <Grid
            id={`${parent_id}-ApprovalLevel-parent-grid`}
            style={{ padding: "0px 20px 20px 20px" }}>
            <ApprovalLevel
              parent_id={"ApprovalLevel"}
              allMaster={allMaster}
              approvalOption={allMaster?.app_level ?? []}
              handleApprovalLevel={this.handleApprovalLevel}
              handleApproveDelete={this.handleApproveDelete}
              handleApproveEdit={this.handleApproveEdit}
              orderingFacility={this.state.orderingFacility}
              {...this.state}
              {...this.props}
            />
          </Grid>
          : null}

        <Grid
          id={`${parent_id}-AlertRestrictionForm-buttons-grid`}
          style={{ padding: "20px" }}>
          <Button
            id={`${parent_id}-AlertRestrictionForm-Save-button`}
            onClick={() => this.saveForm()}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            id={`${parent_id}-AlertRestrictionForm-Cancel-button`}
            onClick={() => closeForm()}
            style={{ float: "right", marginRight: "20px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  allMaster: state?.alertRestictionsMasterSlice?.get_all_masters?.data
});

export default connect(mapStateToProps, actions)(withAllContexts(AlertRestrictionForm))