import React from "react";
const SVG = ({ width, height, className, fill }) => (
    <svg
        // fill={fill} 
        width={width}
        height={height}
        // viewBox={"0 0 24 17.685"}
        viewBox="0 0 17.143 15"
        x="0px" y="0px"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"

    >
        <path
            id="Icon_-Appointment_Period"
            data-name="Icon -Appointment Period"
            class="cls-1"
            d="M8.571,3a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,8.571,3Zm0,1.429A1.071,1.071,0,1,1,7.5,5.5,1.073,1.073,0,0,1,8.571,4.429Zm-5.357.714A1.786,1.786,0,1,0,5,6.929,1.785,1.785,0,0,0,3.214,5.143Zm0,1.23a.555.555,0,1,1-.555.555A.556.556,0,0,1,3.214,6.373ZM8.571,9.429a8.684,8.684,0,0,0-3.89,1,4.172,4.172,0,0,0-1.468-.285c-1.6,0-3.214,1-3.214,2.094v2.192H8.637a4.283,4.283,0,1,0,4.22-5,4.24,4.24,0,0,0-1.809.407A9.173,9.173,0,0,0,8.571,9.429Zm0,1.429a6.213,6.213,0,0,1,1.018.095A4.271,4.271,0,0,0,8.637,13H5V11.976A5.82,5.82,0,0,1,8.571,10.857Zm4.286,0A2.857,2.857,0,1,1,10,13.714,2.866,2.866,0,0,1,12.857,10.857Zm-9.643.714a2.5,2.5,0,0,1,.4.045,1.222,1.222,0,0,0-.042.313V13H1.429v-.707A2.371,2.371,0,0,1,3.214,11.571Zm10.286.142-1.215,2.072,1.572,1.572.643-.714-1.071-1,.857-1.5Z"
            transform="translate(0 -3)"
            fill={fill ? fill : "#6f6f6f"}
        />
    </svg>
);
export default SVG;