import React from 'react';
import { makeStyles, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    label: {
        color: '#6F6F6F',
        fontSize: 12,
        fontFamily: 'pc_regular',
        marginBottom: 8
    },
    toggleButton: {
        '& .MuiToggleButton-root': {
            borderRadius: 8,
            borderColor: '#E0E0E0',
            color: '#101010',
            fontSize: 14,
            textTransform: 'capitalize',
            marginRight: 8,
            padding: 6,
            minWidth: 80,
            fontFamily: 'pc_regular',
            marginBottom: 10,
            '&:hover': {
                backgroundColor: '#0071F2',
                color: '#fff'
            },
            '&:last-of-type': {
                marginRight: 0
            }
        },
        '& .Mui-selected': {
            backgroundColor: '#0071F2',
            color: '#fff'
        },
    }
}))

export const ToggleButtonComponent = (props) => {
    const classes = useStyles(props);
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}-parent-div`}
        >
            {
                props.label &&
                <Typography
                    id={`${parent_id}-${props?.label.replaceAll(" ", "-")}-typography`}
                    className={classes.label}>{props?.label}
                    {props?.required && <span style={{ color: "red" }} >*</span>}
                </Typography>
            }
            <ToggleButtonGroup
                id={`${parent_id}-toggleButtonGroup`}
                value={props?.value}
                exclusive={props?.type === "single" ? true : false}
                onChange={(e, data) => props?.onChange(data)}
                orientation={props?.orientation}
                size={props?.size}
                aria-label="toggle-button-group"
                className={classes.toggleButton}
                style={{ display: "block" }}
            >
                {
                    props?.options && props?.options?.map((_, index) => {
                        return (
                            <ToggleButton
                                id={`${parent_id}-${index}-toggleButton`}
                                key={index} value={_?.value} aria-label="toggle-button">
                                {_?.[props?.optionsLabel]}
                            </ToggleButton>
                        )
                    })
                }
            </ToggleButtonGroup>
        </div>
    )
}

ToggleButtonComponent.propTypes = {
    type: PropTypes.oneOf('single', 'multiple'),
    orientation: PropTypes.oneOf('horizontal', 'vertical'),
    size: PropTypes.oneOf('large', 'medium', 'small'),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), //if single select value must be string in type whereas it is multi select value must be an array of string
    onChange: PropTypes.func,
    options: PropTypes.array,
    optionsLabel: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
}

ToggleButtonComponent.defaultProps = {
    type: 'single',
    onChange: null,
    options: [], // {label:'',value:''},
    optionsLabel: 'label',
    orientation: 'horizontal',
    size: 'small',
    label: '',
    required: false,
}