import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from "@material-ui/core/Avatar";
// import ImageIcon from "@material-ui/icons/Image";
// import WorkIcon from "@material-ui/icons/Work";
// import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import availabilitylogo from "../assets/icons8-management (5).svg";
import practionerlogo from "../assets/icons8-user-folder (1).svg";
import "./font.css";
import routes from "../router/routes";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,

    "& .MuiTypography-displayBlock": {
      fontSize: "12px",
    },
  },
}));

export default function FolderList(props) {
  const classes = useStyles();
  const { parent_id } = props;
  const history = useHistory();
  return (
    <List
      id={`${parent_id}-parent-div`}
      className={classes.root}>
      <ListItem
        id={`${parent_id}-listitem`}
      >
        <ListItemAvatar
          id={`${parent_id}-availabilitylogo-listitemavatar`}
        >
          <img
            id={`${parent_id}-availabilitylogo-img`}
            src={availabilitylogo}
            alt="logo"
            style={{ paddingLeft: "10px", height: "12px" }}
          />
        </ListItemAvatar>
        <ListItemText
          id={`${parent_id}-availability-title`}
          style={{ fontSize: "12px" }} />
        <button
          id={`${parent_id}-availability-button`}
          style={{
            border: "none",
            backgroundColor: "#ffffff",
            fontFamily: "poppin",
          }}
          onClick={() => history.push(routes.availabilitymanagement)}
        >
          Availability management
        </button>
      </ListItem>
      <ListItem
        id={`${parent_id}-practionerlogo-Listitem`}
      >
        <ListItemAvatar
          id={`${parent_id}-practionerlogo-listitemavatar`}
        >
          <img
            id={`${parent_id}-practionerlogo-img`}
            src={practionerlogo}
            alt="logo"
            style={{ paddingLeft: "10px", height: "13px" }}
          />
        </ListItemAvatar>
        <ListItemText
          id={`${parent_id}-practionerlogo-title`}
          style={{ marginTop: "45px" }} />
        <button
          id={`${parent_id}-practioner-button`}
          style={{
            border: "none",
            backgroundColor: "#ffffff",
            marginRight: "27px",
            fontFamily: "poppin",
          }}
          className={classes.button}
        >
          Practetioner master
        </button>
      </ListItem>
      <ListItem
        id={`${parent_id}-practionerlogo-Listitem`}
      >
        <ListItemAvatar
          id={`${parent_id}-practionerlogo-listitemavatar`}
        >
          <img
            id={`${parent_id}-practionerlogo-img`}
            src={practionerlogo}
            alt="logo"
            style={{ paddingLeft: "10px", height: "13px" }}
          />
        </ListItemAvatar>
        <ListItemText />
        <button
          id={`${parent_id}-Usermanagement-button`}
          style={{
            border: "none",
            backgroundColor: "#ffffff",
            marginRight: "27px",
            fontFamily: "poppin",
          }}
          className={classes.button}
          onClick={() => history.push(routes.repository)}
        >
          User Management
        </button>
      </ListItem>
      {/* <ListItem>
        <ListItemAvatar>
          <Avatar>
            <BeachAccessIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Vacation" secondary="July 20, 2014" />
      </ListItem> */}
    </List>
  );
}
