import { Divider, Grid, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "primary_care_admin_binder";
import { SelectBox } from '../../../../components';
import CustomInputDatePicker from '../../../../components/common/datePicker/inputDatePicker';
import { useStyles } from './styles';
import ConfirmationDialog from "./confirmationDialog";
import { Autocomplete } from '@material-ui/lab';

const ScheduleDefDetails = (props) => {
    const classes = useStyles();
    let dispatch = useDispatch();
    const state = useSelector(state => state.timetableMasterSlice)
    const {parent_id,stateData = {}, setState = () => null, editData = null,} = props
    const [modalOpen,setModalOpen] = useState({
        open:false
    })

    const handleModalClose = () => {
        setModalOpen({open:false})
    }

    const handleRemove = (end_date) => {
        setState({ ...stateData, "end_date": end_date })
        setModalOpen({open:false})
    }

    const updateState = async (name, value) => {
        if(name === "end_date"){
            setModalOpen({open:true, [name]:value})
        }else{
        setState({ ...stateData, [name]: value })
        if (name === "entity_type") {
            await dispatch(actions.GET_ENTITY_NAME_WITH_TYPE({ type: value.value }))
        }
        if (name === "resource_type") {
            debugger;
            if(value?.label === "Location"){
                await dispatch(actions.GET_RESOURCE_ROLE_LOCATION({orgId:stateData?.facility_name?._id}))
            }else{
                await dispatch(actions.GET_RESOURCE_ROLE({ type: value, ename: stateData?.entity_name ?? null }))
            }
        }
        if(name === "resource_role"){
            if(stateData?.resource_type?.label === "Location"){
                await dispatch(actions.GET_RESOURCES_LOCATION({orgId:stateData?.facility_name?._id,locationId:value?.locationRoleType}));
            }else{
                debugger;
                const pracRole = value?.coding?.[0]?._id
                const getResourcePractDrop = await dispatch(actions.GET_RESOURCES_PRAC({orgId:stateData?.facility_name?._id,pracRole:pracRole}));
            }
        }}
        let errState =JSON.parse(JSON.stringify(props.error))
         errState[name] = false;
        props.seterr(errState);
    }
    return (
        <React.Fragment>
             <Paper
                id={`${parent_id}-parent-paper`}
                className={classes.root}>
                    <Grid
                    id={`${parent_id}-SCHEDULED-PERIOD-grid`}
                    className={classes.stitleData} >
                <Typography
                    id={`${parent_id}-ORGANIZATION-title-typography`}
                    className={classes.title} variant="h6">SCHEDULE DEFINITION DETAILS</Typography>
                <Divider
                        id={`${parent_id}-SCHEDULED-PERIOD-divider`}
                        className={classes.divider} /></Grid>
                <Grid
                    id={`${parent_id}-ORGANIZATION-parent-grid`}
                    container className={classes.form}>
                    <Grid
                        id={`${parent_id}-Entity-Type-title-grid`}
                        md="4" lg="4" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-Type-title-typography`}
                            className={classes.formtitle} variant="body1">Schedule Definition Title <span
                                id={`${parent_id}-Entity-type-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <TextField
                            id={`${parent_id}-Timetable-textField`}
                            disabled={Boolean(editData)}
                            fullWidth variant="outlined" size="small"
                            InputProps={{
                                classes: {
                                  root: classes.input,
                                },
                              }}
                            value={stateData?.scheduletitle} onChange={e => updateState("scheduletitle", e.target.value)}
                            error={props.error?.scheduletitle}
                            inputProps={{ maxLength: 40 }}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Entity-name-grid`}
                        md="8" lg="8" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-name-title-typography`}
                            className={classes.formtitle} variant="body1">Facility Name <span
                                id={`${parent_id}-Entity-name-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'Entity-name'}
                            disabled={Boolean(editData)}
                            value={stateData?.facility_name ?? null}
                            onchange={(e, value) => updateState("facility_name", value)}
                            list={state.entitynamewithType_list.data}
                            error={props.error?.facility_name}
                        />
                    </Grid>
                </Grid>

                <Grid
                    id={`${parent_id}-ORGANIZATION-parent-grid`}
                    container className={classes.form}>
                    <Grid
                        id={`${parent_id}-Entity-Type-title-grid`}
                        md="2" lg="2" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-Type-title-typography`}
                            className={classes.formtitle} variant="body1">Resource Type<span
                                id={`${parent_id}-Entity-type-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'Entity-name'}
                            disabled={Boolean(editData) || !stateData?.facility_name}
                            value={stateData?.resource_type ?? null}
                            onchange={(e, value) => updateState("resource_type", value)}
                            list={state.resourceType_list.data}
                            error={props.error?.resource_type}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Entity-name-grid`}
                        md="2" lg="2" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-name-title-typography`}
                            className={classes.formtitle} variant="body1">Resource Role<span
                                id={`${parent_id}-Entity-name-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'Entity-name'}
                            disabled={Boolean(editData)}
                            value={stateData?.resource_role ?? null}
                            onchange={(e, value) => updateState("resource_role", value)}
                            list={stateData?.resource_type?.label === "Location" ? state.resource_role_location.data : state.resourcerole_list.data}
                            error={props.error?.resource_role}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Entity-name-grid`}
                        md="4" lg="4" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-name-title-typography`}
                            className={classes.formtitle} variant="body1">Resources</Typography>
                        <SelectBox
                            parent_id={'Entity-name'}
                            multi
                            value={stateData?.resources }
                            onchange={(e, value) => updateState("resources", value)}
                            list={stateData?.resource_type?.label === "Location" ? state.resource_location.data : state.resource_practitioner.data }
                            // error={props.error?.resources}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Entity-name-grid`}
                        md="2" lg="2" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-name-title-typography`}
                            className={classes.formtitle} variant="body1">Start Date <span
                                id={`${parent_id}-Entity-name-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <CustomInputDatePicker
                            disabled={editData ? true : false}
                            placeholderText="dd / mm / yyyy"
                            minDate={editData ? null : new Date()}
                            selectedDate={
                                stateData.start_date ? new Date(stateData.start_date) : null
                            }
                            handleChange={(value) => updateState("start_date", value)}
                            inputField={
                                <TextField
                                    id={"schedule-datetimepicker"}
                                    //  label = {data.label}
                                    error={props.error?.start_date}
                                    fullWidth
                                    variant="outlined"
                                    required={true}
                                    disabled={editData ? true : false}
                                    type={"text"}
                                    value={
                                        stateData.start_date
                                            ? moment(new Date(stateData.start_date)).format(
                                                "DD-MM-YYYY"
                                            )
                                            : null
                                    }
                                    inputProps={{
                                        style: {
                                            padding: "10.5px 14px",
                                            fontFamily: "poppin",
                                            fontSize: "12px",
                                            backgroundColor:"#fff",
                                        },
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Entity-name-grid`}
                        md="2" lg="2" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-name-title-typography`}
                            className={classes.formtitle} variant="body1">End Date <span
                                id={`${parent_id}-Entity-name-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <CustomInputDatePicker
                            // disabled={editData && !ttOverRide ? true : false}
                            placeholderText="dd / mm / yyyy"
                            minDate={editData ? null : new Date()}
                            selectedDate={
                                stateData.end_date ? new Date(stateData.end_date) : null
                            }
                            handleChange={(value) => updateState("end_date", value)}
                            inputField={
                                <TextField
                                    id={"schedule-datetimepicker"}
                                    //  label = {data.label}
                                    fullWidth
                                    variant="outlined"
                                    required={true}
                                    error={props.error?.end_date}
                                    // disabled={editData && !ttOverRide ? true : false}
                                    type={"text"}
                                    value={
                                        stateData.end_date
                                            ? moment(new Date(stateData.end_date)).format(
                                                "DD-MM-YYYY"
                                            )
                                            : null
                                    }
                                    inputProps={{
                                        style: {
                                            padding: "10.5px 14px",
                                            fontFamily: "poppin",
                                            fontSize: "12px",
                                            backgroundColor:"#fff",
                                        },
                                    }}
                                />
                            }
                        />
                    </Grid>
                </Grid>

                <Grid
                    id={`${parent_id}-ORGANIZATION-parent-grid`}
                    container className={classes.form}>
                    <Grid
                        id={`${parent_id}-Entity-name-grid`}
                        md="2" lg="2" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-name-title-typography`}
                            className={classes.formtitle} variant="body1">Schedule Generation Mode <span
                                id={`${parent_id}-Entity-name-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'Entity-name'}
                            disabled={Boolean(editData)}
                            value={stateData?.schedule_generation_mode ?? null}
                            onchange={(e, value) => updateState("schedule_generation_mode", value)}
                            list={state.schedule_generation_mode?.data?.result?.map((val) => {
                                return {
                                    label: val?.display,
                                    value: val?._id
                                }
                            })}
                            error={props.error?.schedule_generation_mode}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Entity-Type-title-grid`}
                        md="3" lg="3" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-Type-title-typography`}
                            className={classes.formtitle} variant="body1">Schedule Availability Management</Typography>
                        <TextField
                            id={`${parent_id}-Timetable-textField`}
                            disabled={Boolean(editData)}
                            fullWidth variant="outlined" size="small"
                            InputProps={{
                                classes: {
                                  root: classes.input,
                                },
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Typography style={{color:"#B6B6B6",fontSize:"14px"}}>Days</Typography>
                                    </InputAdornment>
                                )
                              }}
                            value={stateData?.schedule_available_days} onChange={e => updateState("schedule_available_days", e.target.value)}
                             error={props.error?.schedule_available_days}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-Entity-name-grid`}
                        md="7" lg="7" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-Entity-name-title-typography`}
                            className={classes.formtitle} variant="body1">Applicable App Types <span
                                id={`${parent_id}-Entity-name-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'Entity-name'}
                            multi
                            // disabled={Boolean(editData)}
                            value={stateData?.applicable_app_type}
                            onchange={(e, value) => updateState("applicable_app_type", value)}
                            list={props.applicableData ?? []}
                            error={props.error?.applicable_app_type}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <ConfirmationDialog modalOpen={modalOpen} handleModalClose={handleModalClose} handleRemove={handleRemove} />
        </React.Fragment>
    );
}

export default ScheduleDefDetails;