import React, { useState } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
import { RuleSet } from "../../../../../../components"
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  btn: {
    padding: "6px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    margin: "15px 10px 15px 0px",
  },
  btnErr: {
    padding: "6px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    margin: "15px 10px 15px 0px",
    border: "1px solid red"
  },
  slectedbtn: {
    backgroundColor: "#0071F2",
    color: "#fff",
    padding: "6px 16px",
    fontFamily: "poppin",
    fontSize: "12px",
    textTransform: "none",
    margin: "15px 10px 15px 0px",
    "&:hover": {
      background: "#0071F2",
    },
  },
  lowerdiv: {
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    padding: "15px",
  },
}));

function PerformService(props) {
  const classes = useStyles();
  const [state, setState] = useState("Service Order");
  const { handleFormState, ServiceApplicabiliy, serviceApplicable, parent_id, error, ruleSet } = props;
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes.root}>
        <Typography
          id={`${parent_id}-SERVICE-typography`}
          className={classes.title} variant="h6">
          SERVICE APPLICABILITY <span style={{ color: "red" }}>*</span>
        </Typography>
        <Grid
          id={`${parent_id}-button-grid`}
        >

          {serviceApplicable?.map((v, i) => {
            return <Button
              id={`${parent_id}-${i}-${v?.value}-button`}
              variant="outlined"
              onClick={(e) => handleFormState("ServiceApplicabiliy", v)}
              className={
                ServiceApplicabiliy?.value === v?.value ? classes.slectedbtn : error?.ServiceApplicabiliy ? classes.btnErr : classes.btn
              }
            >
              {v?.value}
            </Button>
          })}
        </Grid>
        <Grid
          id={`${parent_id}-ServiceApplicabiliy-grid`}
          className={classes.lowerdiv}>
          <Typography
            id={`${parent_id}-ServiceApplicabiliy-typography`}
            className={classes.title} variant="h6">
            {ServiceApplicabiliy?.value === "serviceorder" ? "ORDER CRITERIA" : "MEDICATION SELECTION CRITERIA"}
          </Typography>
          {/* <Button
            id={`${parent_id}-Add-New-Rule-Set-button`}
            variant="outlined"
            className={classes.btn}
            style={{ borderColor: "#ff6384", color: "#ff6384" }}
          >
            + Add New Rule Set
          </Button> */}
          <RuleSet
            parent_id={'RuleSet'}
            stateData={ruleSet} setState={(data) => handleFormState("ruleSet", data)} 
          />
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default PerformService;
