import React from "react";
import { Button, IconButton, Grid, Drawer, Avatar } from "@material-ui/core";

import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { green } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import GenerateBlock from "./generateBlock";
import ReleaseBlock from "./releaseBlock";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import Calendar from "./calendar";
import { getUtcTime, utcTOLocal, valitationFunc } from "../../../utils";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../../HOCs";
import { SelectBox } from "../../../components/common/smartForm/component";

const drawerWidth = 360;

const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    position: "relative",
    minHeight: "80vh",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: "10000",
  },
  bottomnav: {
    position: "absolute",
    //bottom: 0,
    right: 0,
    width: "100%",
    padding: 5,
    paddingRight: 0,
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #DDDFE1",
    zIndex: "10000",
  },
  fabrelease: {
    position: "absolute",
    bottom: theme.spacing(18),
    right: theme.spacing(2),
    background: "#fff",
    zIndex: "10000",
  },
  fabgenerate: {
    position: "absolute",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    background: "#fff",
    zIndex: "10000",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  slot: {
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: 10,
  },
  topGrid: {
    backgroundColor: "#fff",
    padding: 5,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  large: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    position: "fixed",
    right: "376px",
    cursor: "pointer",
    top: 10,
    "& img": {
      objectFit: "scale-down",
      backgroundColor: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      right: "313px",
    },
  },
});


class ScheduleManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enter: false,
      generateOpen: false,
      releaseOpen: false,
      selected: false,
      generateMode: "fullAbsence",
      startOn: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      endOn: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      day: [],
      startTime: "12:00",
      endTime: "12:00",
      partialStartDate: new Date(),
      partialEndDate: new Date(),
      ReleaseStartDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      ReleaseEndDate: null,
      generateBlock: [],
      generate_Reason: null,
      Reason: "",
      currentEvents: [],
      selectedEvents: [],
      selectedType: "",
      moreEvents: null,
      selectedDate: new Date(),
      roleType: null,
      Name: null,
      open: false,
      initialEvents: [],
      practitoners: [],
      error: {},
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleFormState = this.handleFormState.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.handleEvents = this.handleEvents.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.moreEventClick = this.moreEventClick.bind(this);
    this.calendarRef = React.createRef();
  }

  async componentDidMount() {
    await this.props.ROLE_TYPE();
    await this.props.BLOCK_RELEASE_REASON();
  }

  //handleState
  handleFormState = async (name, value) => {
     let errorObj = JSON.parse(JSON.stringify(this.state.error));
     errorObj[name] = false;
    
    if (name === "ReleaseStartDate") {
      this.state['ReleaseEndDate']=''
    }
    await this.setState({ [name]: value, error: errorObj });

    if (name === "Name") {
      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.refetchEvents();
    }
    if (name === "roleType" && value?.label === "Location") {
      this.setState({
        practitoners: [],
      });
    } else if (name === "roleType" && value?.label === "Device") {
      this.setState({
        practitoners: [],
      });
    } else if (name === "roleType" && value?.label === "Practitioner") {
      await this.props.ALL_PRACTITIONER();
      this.setState({
        practitoners: this.props.AllPractitioner?.data ?? [],
      });
    }

    if (
      this.state.ReleaseStartDate &&
      this.state.ReleaseEndDate &&
      name === "ReleaseEndDate"
    ) {
      let date = moment(this.state.ReleaseStartDate).unix(); //getUtcTime(moment(this.state.ReleaseStartDate));
      let formateDate = moment.unix(date).format("YYYY-MM-DD"); //utcTOLocal(date,"YYYY-MM-DD");
      let daystart = getUtcTime(moment(formateDate));
      let slotstart = getUtcTime(moment(this.state.ReleaseStartDate)); // moment(this.state.ReleaseStartDate).unix(); //
      let slotend = getUtcTime(moment(this.state.ReleaseEndDate)); //moment(this.state.ReleaseEndDate).unix(); //
      let id = this.state?.Name?.id ? this.state?.Name?.id : 0;
      let releaseslots = await this.props.RELEASE_SLOTS({
        dayStart: daystart,
        slotStart: slotstart,
        slotEnd: slotend,
        practitonerID: id,
      });

      if (!releaseslots.payload.error) {
        this.setState({
          generateBlock: releaseslots?.payload?.data,
        });
      }
    }
  };



  // onhover Icon Open function
  handleOpen = () => {
    this.setState({
      ...this.state,
      enter: !this.state.enter,
    });
  };

  // modal open function for generate and release block
  handleClickOpen = (type, fun) => {
    if (type === "generate") {
      fun === "open"
        ? this.setState({
          ...this.state,
          generateOpen: true,
          selected: false,
        })
        : this.setState({
          ...this.state,
          generateOpen: false,
          selected: false,
        });
    } else if (type === "Release") {
      fun === "open"
        ? this.setState({
          ...this.state,
          releaseOpen: true,
          selected: false,
        })
        : fun === "openCalendar"
          ? this.setState({
            ...this.state,
            releaseOpen: true,
            selected: true,
          })
          : this.setState({
            ...this.state,
            releaseOpen: false,
            selected: false,
            generateBlock: [],
            selectedEvents: [],
            Reason: null
          });
    }
  };

  // onselect specific date in calendar
  handleSelected = (action, events) => {
    if (action === "Select") {
      this.setState((prevState) => ({
        ...prevState,
        selected: true,
        generateBlock: events,
      }));
    } else if (action === "UnSelect") {
      this.setState((prevState) => ({
        ...prevState,
        selected: events.length === 0 ? false : true,
        generateBlock: events,
      }));
    } else if (action === "close") {
      this.setState((prevState) => ({
        ...prevState,
        selected: false,
        generateBlock: [],
      }));
    }
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
  };

  handleEventClick = (clickInfo) => {
    let currentDate = utcTOLocal(moment(),"YYYY-MM-DDTHH:mm");
    let selectedStart = utcTOLocal(
      moment(clickInfo.event.startStr),
      "YYYY-MM-DDTHH:mm"
    ); ;
    
    if (selectedStart >= currentDate) {
      console.log(clickInfo.event.startStr);
      if (this.state.selectedEvents.length === 0) {
        this.setState({
          selectedType: clickInfo?.event?._def?.extendedProps?.isBlocked
            ? "Blocked"
            : "Available",
        });
      }
      let currentType = clickInfo?.event?._def?.extendedProps?.isBlocked
        ? "Blocked"
        : "Available";
      if (this.state.selectedType === currentType) {
        let obj = {
          title: clickInfo?.event?._def?.title,
          start: clickInfo.event.startStr,
          end: clickInfo.event.endStr,
          isBlocked: clickInfo?.event?._def?.extendedProps?.isBlocked,
          type: clickInfo?.event?._def?.extendedProps?.type,
          appointment: clickInfo?.event?._def?.extendedProps?.appointment,
          slab: clickInfo?.event?._def?.extendedProps?.slab,
          overTime: clickInfo?.event?._def?.extendedProps?.overTime,
        };

        let dup = this.state?.selectedEvents?.filter(
          (v) => v?.title === clickInfo?.event?._def?.title
        );

        if (dup.length === 0) {
          clickInfo.el.style.border = "1.5px solid #007BDA";
          clickInfo.el.style.backgroundColor = "#f5f5f5";
          this.setState((state) => ({
            selectedEvents: [...state.selectedEvents, obj],
          }));
          this.handleSelected("Select", this.state.selectedEvents);
        } else {
          clickInfo.el.style.border = "1px solid #f5f5f5";
          clickInfo.el.style.backgroundColor = "#f5f5f5";
          let filteredArray = this.state?.selectedEvents?.filter(
            (v) => v?.title !== clickInfo?.event?._def?.title
          );
          this.setState((state) => ({
            selectedEvents: filteredArray,
          }));
          this.handleSelected("UnSelect", this.state.selectedEvents);
        }
      } else {
        this.props.alert.setSnack( "error","Cannot Select different Status");
      }
    }else{
       this.props.alert.setSnack("error","Please select Upcoming  Date and Time!");
    }
    console.log(this.state.clickCnt);
  }; // handleEventClick

  moreEventClick = (info) => {
    let event = info.allSegs.map((v) => {
      return {
        id: v.event.id,
        title: v.event?._def?.title,
        start: utcTOLocal(moment(v.event?.startStr),"YYYY-MM-DDTHH:mm"),
        end:utcTOLocal(moment(v.event?.endStr),"YYYY-MM-DDTHH:mm"),
        isBlocked: v.event?._def?.extendedProps.isBlocked,
      };
    });

    this.setState({
      open: true,
      moreEvents: event,
      selectedDate: info.date,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false,
      moreEvents: [],
      selectedDate: null,
    });
  };

  getCalendarData = async (fetchInfo, successCallback) => {
    try {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: "Fetching Slots...",
      });
      let startdate = getUtcTime(moment(fetchInfo.startStr)); // 1631817000; //
      let enddate = getUtcTime(moment(fetchInfo.endStr)); //1631903399; //
      let id = this.state?.Name?.id ? this.state?.Name?.id : 0; //fetchInfo?.id; //81

      let slots = await this.props.PRACTITIONER_SLOTS({
        startDate: startdate,
        endDate: enddate,
        practitonerId: id,
      });

      successCallback(
        slots.payload.data.map((event) => {
          return {
            id: event.id,
            title: event.title,
            start: event.start,
            end: event.end,
            isBlocked: event.isBlocked,
            appointment: event.appointment,
            slab: event.slab,
            overTime: event.overTime,
            type: event.type,
          };
        })
      );

      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  SlotSlapCount = () => {
    let SlabCount = 0;
    let SlotCount = 0;
    this.state?.generateBlock?.forEach((v) => {
      if (v.type === "Slab") {
        SlabCount = SlabCount + 1;
      } else {
        SlotCount = SlotCount + 1;
      }
    });

    return `${SlotCount} Slots & ${SlabCount} Slab`;
  };

  //block generate function
  generateBlockFunc = async () => {
    //for full absence
    let calendarApi = this.calendarRef.current.getApi();

    if (this.state.generateMode === "fullAbsence") {
      let startdate = getUtcTime(moment(this.state.startOn)); // 1631845800;
      let enddate = getUtcTime(moment(this.state.endOn)); // 1631847600;
      let id = this?.state?.Name?.id;
      let blockReason = this.state.generate_Reason?._id;

       const errorList = ["startOn", "endOn", "generate_Reason", "Name"];
       let { error, errorState } = valitationFunc(this.state, errorList);

      //&& startDate >= endDate;
      if (!error && startdate <= enddate) {
        await this.props.BLOCK_SCHEDULE({
          startDate: startdate,
          endDate: enddate,
          practitonerId: id, //id,
          reason: blockReason,
        });
        calendarApi.refetchEvents();

        if (this.props.generateBlock.data.result?.[0]?.error) {
          let customMsg=this.props.generateBlock.data.result?.[0].errorMessage
          await this.props.alert.setSnack("error", customMsg);
          return
        } else {
          await this.props.alert.setSnack("success", "Block Generated Successfully");
        }
      } else {
        await this.props.alert.setSnack("mandatory");
        this.setState({
          ...this.state,
          error:errorState
        })
        return
      } //for partial absence
    } else if (this.state.generateMode === "partialAbsence") {
      let startDate = this.state.partialStartDate;
      let endDate = this.state.partialEndDate;
      let starttime = this.state.startTime;
      let endtime = this.state.endTime;
      let practitonerId = this?.state?.Name?.id;
      let days = this.state.day;
      let blockReason = this.state.generate_Reason?._id;

       const errorList = [
         "partialStartDate",
         "partialEndDate",
         "startTime",
         "endTime",
         "day",
         "generate_Reason",
         "Name",
       ];
       let { error, errorState } = valitationFunc(this.state, errorList);

      if (
       !error&&
        startDate <= endDate
      ) {
        await this.props.BLOCK_SCHEDULE_PARTIAL({
          startDate: startDate,
          endDate: endDate,
          starttime: starttime,
          endtime: endtime,
          practitonerId: practitonerId,
          days: days,
          reason: blockReason,
        });
        calendarApi.refetchEvents();

        if (this.props.partialBLock.data.result?.[0]?.error) {
          await this.props.alert.setSnack("error");
          return
        } else {
          let customMsg = this.props.partialBLock.data.result?.[0].message
          await this.props.alert.setSnack("success", customMsg);
        }
      } else {
        await this.props.alert.setSnack("mandatory");
        this.setState({
          ...this.state,
          error: errorState,
        });

        return
      }
    }
    this.handleClickOpen("generate", "false")
    this.setState({
      startOn: null,
      endOn: null,
      generate_Reason: null,
      partialStartDate: new Date(),
      partialEndDate: new Date(),
      startTime: "12:00",
      endTime: "12:00",
      day: [],
    });

  };

  //block and generate choosen from calendar
  ReleaseGenerateCalendar = async () => {
    let calendarApi = this.calendarRef.current.getApi();
    if (this.state.selectedType === "Blocked") {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: "Releasing Slots..",
      });
      let array = this.state.generateBlock;
      for (let index = 0; index < array.length; index++) {
        let startdate = getUtcTime(moment(array[index].start));
        let enddate =getUtcTime(moment(array[index].end));
        let id = this?.state?.Name?.id;
        let releaseReason = this.state.Reason?._id;
        if (releaseReason) {
          await this.props.RELEASE_SCHEDULE({
            startDate: startdate,
            endDate: enddate,
            practitonerId: id,
            reason: releaseReason,
          });
          if (this.props.ReleaseSchedule.data.result?.[0]?.error) {
             let customMsg =
               this.props.generateBlock.data.result?.[0].errorMessage;
            await this.props.alert.setSnack("error", customMsg);
            this.props.backDrop.setBackDrop({
              ...this.props.backDrop,
              open: false,
              message: "",
            });
            return false;
          } else {
            let customMsg=  this.props.ReleaseSchedule.data.result?.[0].message
            await this.props.alert.setSnack("success", customMsg);
          }
        } else {
          await this.props.alert.setSnack("mandatory");
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "",
          });
          return false;
        }
      
      }
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
    } else if (this.state.selectedType === "Available") {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: "Generate Block..",
      });
      let array = this.state.generateBlock;
      for (let index = 0; index < array.length; index++) {
        let startdate = getUtcTime(moment(array[index].start)); // 1631845800;
        let enddate =getUtcTime(moment(array[index].end)); // 1631847600;
        let id = this?.state?.Name?.id;
        let blockReason = this.state.Reason?._id;
        if (blockReason) {
          await this.props.BLOCK_SCHEDULE({
            startDate: startdate,
            endDate: enddate,
            practitonerId: id, //id,
            reason: blockReason,
          });
          if (this.props.generateBlock.data.result?.[0]?.error) {
             let customMsg =
               this.props.generateBlock.data.result?.[0].errorMessage;
            await this.props.alert.setSnack("error", customMsg);
            this.props.backDrop.setBackDrop({
              ...this.props.backDrop,
              open: false,
              message: "",
            });
            return false;
          } else {
            let customMsg =this.props.generateBlock.data.result?.[0].message
            await this.props.alert.setSnack("success", customMsg);
          }
        } else {
          await this.props.alert.setSnack("mandatory");
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "",
          });
          return false;
        }
      }
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
    }
    calendarApi.refetchEvents();
    this.handleClickOpen("Release", "false");
    this.setState({
      selectedType: "",
      Reason: null,
    });
  };

  //Release calendar
  ReleaseBlock = async () => {
    if (this.state.selectedType) {
      this.ReleaseGenerateCalendar();
    } else {
      let calendarApi = this.calendarRef.current.getApi();
      let startdate = getUtcTime(moment(this.state.ReleaseStartDate)); // 1631845800;
      let enddate = getUtcTime(moment(this.state.ReleaseEndDate)); // 1631847600;
      let id = this?.state?.Name?.id;
      let releaseReason = this.state.Reason?._id;
      const errorList = ["ReleaseStartDate", "ReleaseEndDate", "Reason"];
      let { error, errorState } = valitationFunc(this.state, errorList);
      //full release
      if (!error && startdate <= enddate) {
        await this.props.RELEASE_SCHEDULE({
          startDate: startdate,
          endDate: enddate,
          practitonerId: id,
          reason: releaseReason,
        });
        calendarApi.refetchEvents();

        if (this.props.ReleaseSchedule.data.result?.[0].error) {
           let customMsg =
             this.props.ReleaseSchedule.data.result?.[0].errorMessage;
          await this.props.alert.setSnack("error", customMsg);
          return
        } else {
          let customMsg =this.props.ReleaseSchedule.data.result?.[0].message
          await this.props.alert.setSnack("success",customMsg);
        }
      } else {
        await this.props.alert.setSnack("mandatory");
        this.setState({
          ...this.state,
          error: errorState,
        });
        return
      }
      this.handleClickOpen("Release", "false");
      this.setState({
        ReleaseStartDate: null,
        ReleaseEndDate: null,
        Reason: null,
        selectedType: "",
      });
    }
  };

  render() {
    const { classes, parent_id } = this.props;
    const {
      generateOpen,
      releaseOpen,
      selected,
      enter,
      open,
      roleType,
      Name,
      practitoners,
    } = this.state;
    return (
      <div
        id={`${parent_id}-parent-div`}
        className={classes.root}>
        <Grid
          id={`${parent_id}-Selectbox-grid`}
          container className={classes.topGrid}>
          <Grid
            id={`${parent_id}-roleType-grid`}
            item style={{ padding: 5 }} xs={3}>
            <SelectBox
              id={`${parent_id}-roleType-selectbox`}
              list={this.props.RoleType?.data ?? []}
              placeholder={"Select"}
              value={roleType}
              onchange={(e, value) => this.handleFormState("roleType", value)}
              
            />
          </Grid>
          <Grid
            id={`${parent_id}-Name-grid`}
            style={{ padding: 5 }} item xs={3}>
            <SelectBox
              id={`${parent_id}-Name-selectbox`}
              list={practitoners}
              placeholder={"Select"}
              value={Name}
              onchange={(e, value) => this.handleFormState("Name", value)}
              error={this.state.error?.Name}
            />
          </Grid>
        </Grid>
        <Calendar
          id={`${parent_id}-calendar`}
          calendarRef={this.calendarRef}
          initialView={"timeGridWeek"}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          handleEvents={this.handleEvents}
          openGenerate={this.handleSelected}
          handleEventClick={this.handleEventClick}
          moreEventClick={this.moreEventClick}
          handleDrawerClose={this.handleDrawerClose}
          open={this.state.open}
          selectedDate={this.state.selectedDate}
          moreEvents={this.state.moreEvents}
          initialEvents={false}
          handleClickOpen={this.handleClickOpen}
          handleSelected={this.handleSelected}
          {...this.state}
          handleFormState={this.handleFormState}
          events={this.getCalendarData}
        //events={this.events}
        />

        <Drawer
          id={`${parent_id}-parent-calendar-drawer`}
          className={classes.drawer}
          // variant="persistent"
          anchor="right"
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            id={`${parent_id}-calendar-close-div`}
          >
            <Avatar
              id={`${parent_id}-calendar-close-avatar`}
              src="/images/close.svg"
              className={classes.large}
              onClick={this.handleDrawerClose}
            />

            <Calendar
              id={`${parent_id}-calendar`}
              calendarRef={this.calendarRef}
              initialView={"timeGridDay"}
              headerToolbar={false}
              initialDate={this.state.selectedDate}
              handleEvents={this.handleEvents}
              handleEventClick={this.handleEventClick}
              moreEventClick={this.moreEventClick}
              handleDrawerClose={this.handleDrawerClose}
              open={this.state.open}
              selectedDate={this.state.selectedDate}
              initialEvents={false}
              handleClickOpen={this.handleClickOpen}
              handleSelected={this.handleSelected}
              {...this.state}
              events={this.getCalendarData}
            />

            {this.state.open && this.state.selected && (
              <div
                id={`${parent_id}-button-div`}
                className={classes.bottomnav}>
                <div
                  id={`${parent_id}-Selected-div`}
                  className={classes.slot}>
                  {this.SlotSlapCount()} Selected
                </div>
                <div
                  id={`${parent_id}-Release-button-div`}
                >
                  <Button
                    id={`${parent_id}-Release-button`}
                    variant="contained"
                    onClick={() =>
                      this.handleClickOpen("Release", "openCalendar")
                    }
                    size="small"
                    autoFocus
                    color="primary"
                  >
                    {this.state.selectedType === "Blocked"
                      ? "Release BLock"
                      : "Generate Block"}
                  </Button>
                  <IconButton
                    id={`${parent_id}-close-iconbutton`}
                    size="small"
                    onClick={() => this.handleSelected("close")}
                    aria-label="delete"
                    className={classes.margin}
                  >
                    <CloseIcon
                      id={`${parent_id}-close-closeicon`}
                    />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </Drawer>

        {/* generate Block Dialog */}
        <GenerateBlock
          parent_id={'generateblock'}
          handleFormState={this.handleFormState}
          opengenerate={generateOpen}
          generateBlockFunc={this.generateBlockFunc}
          modalOpen={this.handleClickOpen}
          generateReason={this.props?.reason?.data ?? []}
          {...this.state}
        />
        {/* Release BLock Dialog.. */}
        <ReleaseBlock
          parent_id={'Releaseblock'}
          handleFormState={this.handleFormState}
          openrelease={releaseOpen}
          modalOpen={this.handleClickOpen}
          ReleaseBlock={this.ReleaseBlock}
          releaseReason={this.props?.reason?.data ?? []}
          {...this.state}
        />
        {/* floating Button of Generate and Release Block */}
        {enter && (
          <>
            <Tooltip
              id={`${parent_id}-release-tooltip`}
              title="Release Block" placement="left">
              <Fab
                id={`${parent_id}-release-fab`}
                aria-label={"Release"}
                className={classes.fabrelease}
                variant="outlined"
                onClick={() => this.handleClickOpen("Release", "open")}
              >
                <img
                  id={`${parent_id}-release-img`}
                  src="/images/icons8-undo (1).svg" alt="Release" />
              </Fab>
            </Tooltip>
            <Tooltip
              id={`${parent_id}-generate-tooltip`}
              title="Generate Block" placement="left">
              <Fab
                id={`${parent_id}-generate-fab`}
                aria-label={"Generate"}
                className={classes.fabgenerate}
                variant="outlined"
                onClick={() => this.handleClickOpen("generate", "open")}
              >
                <img
                  id={`${parent_id}-generate-img`}
                  src="/images/icons8-leave.svg" alt="Generate" />
              </Fab>
            </Tooltip>
          </>
        )}
        {/* floating app bar and plus Botton */}
        <Zoom
          id={`${parent_id}-selected-zoom`}
          in={true} unmountOnExit>
          {selected && !open && !releaseOpen ? (
            // Bottom App bar
            <div
              id={`${parent_id}-selected-div`}
              className={classes.bottomnav}>
              <div
                id={`${parent_id}-selected-sub-div`}
                className={classes.slot}>
                {this.SlotSlapCount()} Selected
              </div>
              <div
                id={`${parent_id}-button-div`}
              >
                <Button
                  id={`${parent_id}-Release-Generate-button`}
                  variant="contained"
                  onClick={() =>
                    this.handleClickOpen("Release", "openCalendar")
                  }
                  autoFocus
                  color="primary"
                >
                  {this.state.selectedType === "Blocked"
                    ? "Release BLock"
                    : "Generate Block"}
                </Button>
                <IconButton
                  id={`${parent_id}-delete-closeicon`}
                  onClick={() => this.handleSelected("close")}
                  aria-label="delete"
                  className={classes.margin}
                >
                  <CloseIcon
                    id={`${parent_id}-delete-closeicon`}
                  />
                </IconButton>
              </div>
            </div>
          ) : (
            // Bottom plus icon
            <Fab
              id={`${parent_id}-Add-fab`}
              onMouseEnter={() => this.handleOpen()}
              aria-label={"Add"}
              className={classes.fab}
              color={"primary"}
            >
              {enter ? (
                <CloseIcon
                  id={`${parent_id}-Add-closeicon`}
                />
              ) : (
                <>
                  <AddIcon
                    id={`${parent_id}-AddIcon`}
                  />
                </>
              )}
            </Fab>
          )}
        </Zoom>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  RoleType: state.scheduleManagementSlice.role_type,
  AllPractitioner: state.scheduleManagementSlice.all_practitioner,
  PractitonerSlots: state.scheduleManagementSlice.practitioner_slots,
  ReleaseSchedule: state.scheduleManagementSlice.release_schedule,
  generateBlock: state.scheduleManagementSlice.block_schedule,
  reason: state.scheduleManagementSlice.block_release_reason,
  partialBLock: state.scheduleManagementSlice.block_schedule_partial,
  releaseSlots: state.scheduleManagementSlice.release_slots,
});

export default connect(
  mapStateToProps,
  actions
)(withStyles(useStyles)(withAllContexts(ScheduleManagement)));
