import React, { useEffect } from "react";
import { useState } from "react";
import { actions } from "primary_care_admin_binder";
import { CommonTable, OverlayCompt, VerticalList } from "../../../components";
import TimeTableManagementForm from "./TimeTableManagementForm";
import { withAllContexts } from "../../../HOCs";
import { useDispatch, useSelector } from "react-redux";
import { AlertProps } from "../../../utils";
import ConfirmationDialog from "./TimeTableManagementForm/confirmationDialog";
import OverRideTimeTable from "./OverrideTimeTable";

const list = [
  "GET_APPLICABLE_APP_TYPES",
  "GET_SCHEDULE_MODE",
  "GET_DAY_TYPE",
  "GET_CONSULTATION_TYPE",
  "GET_RESOURCE_TYPE",
  "GET_RESOURCE_ROLE",
  "READ_GROUPS",
  "GET_ENTITY_TYPE",
];

const TimeTableManagement = (props) => {
  const [state, setState] = useState({
    openForm: false,
    isOverRide: false,
    editData: null,
    perPage: 10,
    page: 0,
    search: "",
    list: [],
    overRideData: {},
  });
  const [modalOpen, setModalOpen] = React.useState({
    open: false,
    data: "",
  });
  const dispatch = useDispatch();
  const tableMaster = useSelector(
    (state) => state.timetableMasterSlice.resource_time_table_read?.data
  );
  // onLoad fnctions
  useEffect(async () => {
    await Promise.all(
      list.map(async (val) => {
        return await dispatch(actions[val]());
      })
    );
    await dispatch(actions.APPLICABLE_APP_TYPE());
    await dispatch(actions.SCHEDULE_GENERATION_MODE());
    await dispatch(
      actions.RESOURCE_TIME_TABLE_READ({
        page: state.page,
        perPage: state.perPage,
      })
    );
  }, []);

  // handle Confirmation modal close
  const handleModalClose = () => {
    setModalOpen({
      open: false,
      data: "",
    });
  };

  // handle delete the data
  const handleDeleteData = async (data) => {
    const id = data?._id;
    const deleteData = await dispatch(
      actions.RESOURCE_TIME_TABLE_DELETE({ id: id })
    );
    const deletePayload = {
      error: deleteData?.payload?.data?.error,
      msg: deleteData?.payload?.data?.message,
    };
    setModalOpen({ open: false, data: "" });
    props.alert.setSnack({
      open: true,
      severity: deletePayload?.error
        ? AlertProps.severity.error
        : AlertProps.severity.success,
      msg: deletePayload?.msg,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
    await dispatch(
      actions.RESOURCE_TIME_TABLE_READ({
        page: state.page,
        perPage: state.perPage,
      })
    );
  };

  // Handling the pagination of the table
  const handlepageChange = async (currentPage, PerPage) => {
    setState({ ...state, page: (currentPage - 1) * PerPage, perPage: PerPage });
    await dispatch(
      actions.RESOURCE_TIME_TABLE_READ({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      })
    );
  };

  // Handle the search
  const handleSearch = async (value = "") => {
    if (value?.length > 0) {
      setState({
        ...state,
        search: value.trim(),
      });
    } else if (!value) {
    }
    this.setState({
      ...state,
      search: "",
    });
  };

  // handle the time table form
  const handleForm = async (type) => {
    let { openForm } = state;
    setState({
      openForm: !openForm,
      editData: null,
    });
    await dispatch(
      actions.RESOURCE_TIME_TABLE_READ({
        page: 0,
        perPage: 10,
      })
    );
  };

  // handle the delete and open the dialog
  const handleDelete = async (e, data, index) => {
    setModalOpen({ open: true, data: data });
  };

  // handle edit
  const handleEdit = (e, data, index) => {
    setState({
      ...state,
      openForm: true,
      editData: data,
    });
  };

  // handle OverRide
  const handleOverRide = async (e, data, index) => {
    setState({
      ...state,
      isOverRide: true,
      editData: null,
      overRideData: data,
    });
  };

  // handle OverideForm
  const handleOverideForm = async () => {
    setState({
      ...state,
      isOverRide: false,
      editData: null,
    });
    await dispatch(
      actions.RESOURCE_TIME_TABLE_READ({
        page: state.page,
        perPage: state.perPage,
      })
    );
  };

  return (
    <div>
      <React.Fragment>
        <VerticalList
          parent_id={"timetable"}
          addFunc={() => handleForm(1)}
          id={"timetable"}
          placeholder={"Search"}
          // loading={this.props?.searchLoading}
          onSearchChange={handleSearch}
          hideSearchBar={false}
          hideFilter={true}
          // onFilterClicked={this.props?.onFilterClicked}
          // isFilterApplied={this.props?.isFilterApplied}
          TabList={[
            {
              name: "Resource Schedule Definition",
              component: (
                <CommonTable
                  parent_id={"timetable"}
                  rightAlign={["Status", "Action"]}
                  handleEdit={handleEdit}
                  handleOverRide={handleOverRide}
                  //   handleCheckBox={this.handleCheckBox}
                  handleDelete={handleDelete}
                  Header={[
                    { label: "S.No", showSort: false, showFilter: false },
                    {
                      label: "Entity Name",
                      showSort: false,
                      showFilter: false,
                    },
                    {
                      label: "Resource Type",
                      showSort: false,
                      showFilter: false,
                    },
                    {
                      label: "Resource Role",
                      showSort: false,
                      showFilter: false,
                    },
                    {
                      label: "Resource Name",
                      showSort: false,
                      showFilter: false,
                    },
                    { label: "Start Date", showSort: false, showFilter: false },
                    { label: "End Date", showSort: false, showFilter: false },
                    {
                      label: "Applicable App",
                      showSort: false,
                      showFilter: false,
                    },
                    { label: "Action", showSort: false, showFilter: false },
                  ]}
                  dataList={tableMaster}
                  tableData={[
                    { type: ["WITHDRAFT"], name: "draft_" },
                    {
                      type: ["TEXT"],
                      name: "timetable_entity",
                    },
                    { type: ["TEXT"], name: "resource_type" },
                    { type: ["TEXT"], name: "resource_role" },
                    { type: ["ARRAY"], name: "resource_name" },
                    { type: ["TEXT"], name: "start_date" },
                    { type: ["TEXT"], name: "end_date" },
                    { type: ["ARRAY"], name: "applicable_app" },
                    { type: ["EDIT", "DELETE", "OVERRIDE"], align: "right" },
                  ]}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={tableMaster?.[0]?.TotalCount}
                  incrementCount={state.page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />
              ),
            },
          ]}
        />
        <OverlayCompt
          parent_id={"timetable"}
          open={state?.openForm}
          children={
            <React.Fragment>
              {state?.openForm && (
                <TimeTableManagementForm
                  parent_id={"timetableform"}
                  editData={state?.editData}
                  closeForm={handleForm}
                />
              )}
            </React.Fragment>
          }
        />

        <OverlayCompt
          parent_id={"timetable_over_rde"}
          open={state?.isOverRide}
          children={
            <React.Fragment>
              <OverRideTimeTable
                closeOverRide={handleOverideForm}
                overRideData={state.overRideData}
              />
            </React.Fragment>
          }
        />
      </React.Fragment>
      <ConfirmationDialog
        modalOpen={modalOpen}
        parent_id={"delete_time_table"}
        handleModalClose={handleModalClose}
        handleDeleteData={handleDeleteData}
      />
    </div>
  );
};

export default withAllContexts(TimeTableManagement);
