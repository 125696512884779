import React from "react";
import { VerticalList, CommonTable, GenerateForm } from "../../components"

class GeographicMaster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openForm: false
        }
        this.handleNewForm = this.handleNewForm.bind(this);
    }
    handleNewForm = () => {
        let { openForm } = this.state;
        this.setState({
            openForm: !openForm
        })
    }
    render() {
        return <React.Fragment>
            <VerticalList
                parent_id={'geographic-VerticalList'}
                isLeftList={true}
                addFunc={this.handleNewForm}
                TabList={[
                    // {
                    //     name: "Country", component: <CommonTable
                    //         Header={["S.No", "Code", "Short Description", "Long Description", "Status", "Action"]}
                    //         dataList={[{ code: "#123", short: "des", long: "description" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "short" },
                    //             { type: ["TEXT"], name: "long" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "State", component: <CommonTable
                    //         Header={["S.No", "Code", "Short Description", "Long Description", "Status", "Action"]}
                    //         dataList={[{ code: "#123", short: "des", long: "description" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "short" },
                    //             { type: ["TEXT"], name: "long" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "District", component: <CommonTable
                    //         Header={["S.No", "Code", "Short Description", "Long Description", "Status", "Action"]}
                    //         dataList={[{ code: "#123", short: "des", long: "description" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "short" },
                    //             { type: ["TEXT"], name: "long" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    {
                        name: "City", component: <CommonTable
                            parent_id={'geographic-CommonTable'}
                            Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                            dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                            { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                            tableData={[
                                { type: ["INCRIMENT"], name: "" },
                                { type: ["TEXT"], name: "code" },
                                { type: ["TEXT"], name: "levelName" },
                                { type: ["TEXT"], name: "parentName" },
                                { type: ["TEXT"], name: "parentCode" },
                                { type: ["CHECKBOX"], name: "" },
                                { type: ["EDIT"], name: "" },
                            ]}
                        />
                    }
                ]}
            />
            <GenerateForm
                parent_id={'geographic-GenerateForm'}
                // loading={saveloading}
                open={this.state.openForm}
                handlecancel={this.handleNewForm}
                header={'Add Form'}
                json={[{
                    componet_type: "text",
                    label: "Level code",
                    state_name: "level_code",
                    value: this.state?.edit_data?.code ?? "",
                    required: true
                },
                {
                    componet_type: "text",
                    label: "Level Name",
                    state_name: "level_name",
                    value: this.state?.edit_data?.description ?? "",
                    required: true
                },
                {
                    componet_type: "text",
                    label: "Parent Level Name",
                    state_name: "parent_level_name",
                    value: this.state?.edit_data?.description ?? "",
                    required: true
                },
                {
                    componet_type: "text",
                    label: "Parent Level Code",
                    state_name: "parent_level_code",
                    value: this.state?.edit_data?.description ?? "",
                    required: true
                },
                {
                    componet_type: "switch",
                    label: "Status",
                    state_name: "status",
                    value: this.state?.edit_data?.status ?? false
                }
                ]}
            />
        </React.Fragment>
    }
}

export default GeographicMaster