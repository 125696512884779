import React from "react";
import { CommonTable, GenerateForm } from "../../../../../components";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";
class DrugRouteDirection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: null,
      perPage : 10,
      page: 0
    };
  }

  componentDidMount() {
    this.props?.currentTab("drugRoutesDirection");
    const getdata = async () => {
      await this.props.DRUG_ROUTES_DIRECTION_READ({page: this.state.page, perPage: this.state.perPage});
      await this.props.DRUG_ROUTES_DIRECTION_MASTER();
      //await this.props.DRUG_ROUTES_DIRECTION_ACTION_MASTER();
    };
    getdata();
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    await this.props.DRUG_ROUTES_DIRECTION_READ({ page: (currentPage - 1) * PerPage, perPage: PerPage });

  };
  handleCloseForm = () => {
    const { changeState } = this.props;
    changeState("open", false);
    this.setState({
      editData: null,
    });
  };

  handleEdit = (e, data) => {
    const { changeState } = this.props;
    changeState("open", true);
    changeState("openIndex", 6);
    let dataobj = {
      code: data.code,
      // action: {
      //   label: data?.action,
      //   value: data?.action_id,
      // },
      action: data.action,
      key: data.key,
      longDesc: data.longDesc,
      shortDesc: data.shortDesc,
      DoseFormData: {
        label: data?.DoseForm_longdesc,
        value: data?.DoseForm_id,
      },
    };
    debugger
    this.setState({
      ...this.state,
      editData: dataobj,
    });
  };

  statusChange = async (e, values, index) => {
    let data = {
      status: e.target.checked,
      _key: values.key,
    };
    await this.props.DRUG_ROUTES_DIRECTION_STATUS({
      data,
    });
    await this.props.DRUG_ROUTES_DIRECTION_READ({page: this.state.page, perPage: this.state.perPage});
    this.props.alert.setSnack("update");
  };
  saveForm = async (data) => {
    let type
    if (this.state.editData) {
      let Senddata = {
        // action: data.action.value,
        action: data.action,
        route_direction_code: data.route_direction_code,
        longdesc: data.longdesc,
        shortdesc: data.shortdesc,
        dose_form: data.dose_form.value,
        Status: true,
        key: this.state.editData.key,
      };
      let save = await this.props.DRUG_ROUTES_DIRECTION_UPDATE({
        Senddata,
      });
      if (save.payload?.data?.Code === 1111) {
        type = save.payload?.data?.validation_error?.[0]?.Errormsg.includes("unique")? "unique" : "error"
        this.props.alert.setSnack(type);
      } else {
        this.props.alert.setSnack("update");
        await this.props.DRUG_ROUTES_DIRECTION_READ({page: this.state.page, perPage: this.state.perPage});
        this.handleCloseForm();
      }
    } else {
      let Senddata = {
        // action: data.action.value,
        action: data.action,
        route_direction_code: data.route_direction_code,
        longdesc: data.longdesc,
        shortdesc: data.shortdesc,
        dose_form: data.dose_form.value,
        Status: true,
      };
      let save = await this.props.DRUG_ROUTES_DIRECTION_INSERT({
        Senddata,
      });
      if (save.payload?.data?.Code === 1111) {
        type = save.payload?.data?.validation_error?.[0]?.Errormsg.includes(
          "unique"
        )
          ? "unique"
          : "error";
        this.props.alert.setSnack(type);
      } else {
        this.props.alert.setSnack("success");
        await this.props.DRUG_ROUTES_DIRECTION_READ({page: this.state.page, perPage: this.state.perPage});
        this.handleCloseForm();
      }
    }

  };

  render() {
    const { open, openIndex, 
      //parent_id 
    } = this.props;
    return (
      <React.Fragment>
        <CommonTable
          style={{ maxHeight: "calc(100vh - 245px)" }}
          parent_id={"drugDirection"}
          rightAlign={["Status", "Action"]}
          handleEdit={this.handleEdit}
          handleCheckBox={this.statusChange}
          Header={[
            "S.No",
            "Route Direction Code",
            "Short Description",
            "Long Description",
            "Status",
            "Action",
          ]}
          dataList={
            this.props?.drugRoutesDirectionSlice?.drug_routes_direction_list
              ?.data
              ? this.props?.drugRoutesDirectionSlice?.drug_routes_direction_list
                ?.data
              : []
          }
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "code" },
            { type: ["TEXT"], name: "shortDesc" },
            { type: ["TEXT"], name: "longDesc" },
            { type: ["CHECKBOX"], name: "status", align: "right" },
            { type: ["EDIT"], name: "", align: "right" },
          ]}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={this.props?.drugRoutesDirectionSlice?.drug_routes_direction_list?.data?.[0]?.total_count}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <GenerateForm
          parent_id={"drugDirection"}
          open={open && openIndex === 6}
          save={this.saveForm}
          nocancel
          handlecancel={() => this.handleCloseForm()}
          header={
            this.state?.editData
              ? "Edit Drug Route Direction"
              : "Add New Drug Route Direction"
          }
          json={[
            {
              componet_type: "text",
              label: "Route Direction Code",
              state_name: "route_direction_code",
              value: this.state?.editData?.code ?? "",
              required: true,
              isUpperCase: true,
              disabled: this.state?.editData?.code ? true : false,
              textLength: 20
            },
            {
              componet_type: "text",
              label: "Short Description",
              state_name: "shortdesc",
              value: this.state?.editData?.shortDesc ?? "",
              required: true,
            },
            {
              componet_type: "text",
              label: "Long Description",
              state_name: "longdesc",
              value: this.state?.editData?.longDesc ?? "",
              required: true,
            },
            {
              componet_type: "text",
              label: "Action",
              state_name: "action",
              value: this.state?.editData?.action ?? "",
              required: false,
            },
            // {
            //   componet_type: "select",
            //   label: "Action",
            //   state_name: "action",
            //   value: this.state?.editData?.action ?? "",
            //   required: true,
            //   options: this.props?.drugRoutesDirectionSlice
            //     ?.drug_routes_action_master_list?.data
            //     ? this.props?.drugRoutesDirectionSlice?.drug_routes_action_master_list
            //       ?.data
            //     : [],
            // },
            {
              componet_type: "select",
              options: this.props?.drugRoutesDirectionSlice
                ?.drug_routes_master_list?.data
                ? this.props?.drugRoutesDirectionSlice?.drug_routes_master_list
                  ?.data
                : [],
              label: "Dose Form",
              state_name: "dose_form",
              value: this.state?.editData?.DoseFormData ?? "",
              required: true,
            },
          ]}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  drugRoutesDirectionSlice: state?.drugRoutesDirectionSlice,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(DrugRouteDirection));
