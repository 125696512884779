import React from "react";
import OrderSetForm from "./form";
import {
  VerticalList,
  CommonTable,
  OverlayCompt, 
} from "../../../../components";
import { withAllContexts } from "../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { AlertProps } from "../../../../utils";
import VisibilityIcon from "@material-ui/icons/Visibility";
import OrderSetApplicabilityTabel from "../../odersetapplicability";

const dummyTabelData = [
  {
    id: 1,
    orderSetCode: "ADM-CARDIAC",
    shortDescription: "Medical Assistant",
    longDescription: "Medical Assistant",
    orderSetApplicability: "icon",
    status: false,
  },
  {
    id: 2,
    orderSetCode: "PN",
    shortDescription: "Advanced Practice Nurse",
    longDescription: "Advanced Practice Nurse",
    orderSetApplicability: "icon",
    status: true,
  },
  {
    id: 3,
    orderSetCode: "RN",
    shortDescription: "Registered Nurse",
    longDescription: "Registered Nurse",
    orderSetApplicability: "icon",
    status: true,
  },
  {
    id: 4,
    orderSetCode: "PHARMD",
    shortDescription: "Doctor Of Pharmacy",
    longDescription: "Doctor Of Pharmacy",
    orderSetApplicability: "icon",
    status: true,
  },
];

class OrderSetMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      openForm_: false,
      editData: null,
      editShortDes: "",
      edit_id: "",
      perPage : 10,
      page: 0,
      search:"",
      short_desc: true,
      long_desc: true,
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }
  async componentDidMount() {
    await this.props.GET_ORDER_SET_LIST({page: this.state.page, perPage: this.state.perPage});
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    await this.props.GET_ORDER_SET_LIST({ page: (currentPage - 1) * PerPage, perPage: PerPage });

  };

  handleForm = async () => {
    let { openForm } = this.state;
    this.setState({
      openForm: !openForm,
      editData: null,
    });
  };
  handleForm_ = async () => {
    let { openForm_ } = this.state;
    this.setState({
      openForm_: !openForm_,
      editData: null,
    });
  };
  saveForm = async () => {
    await this.props.GET_ORDER_SET_LIST({page: this.state.page, perPage: this.state.perPage});
    let { openForm } = this.state;
    this.setState({
      openForm: !openForm,
      editData: null,
    });
  };
  handleEdit = async (e, data, index) => {
    let { openForm, editData } = this.state;
    this.setState({
      openForm: !openForm,
      editData: data?._key,
    });
  };
  handleView = (e, data, index) => {
    let { openForm_ } = this.state;
    this.setState({
      openForm_: !openForm_,
      editData: data?._id,
      editShortDes: data?.shortDesc,
      edit_id: data?._id,
    });
  };
  handleCheckBox = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Status Updating...",
    });
    await this.props.ORDER_SET_MASTER_STATUS_CHANGE({
      _key: data._key,
      status: e.target.checked,
    });
    await this.props.GET_ORDER_SET_LIST({page: this.state.page, perPage: this.state.perPage});
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
    this.props.alert.setSnack("update");
  };


  handleSearch = async(value = "") => {
    if (value?.length > 0) {
      await this.props.GET_ORDER_SET_LIST({
        search:value.trim(),
        page: this.state.page,
        perPage: this.state.perPage
      });
      this.setState({
        ...this.state,
        search: value.trim()
      });
    } else if (!value) {
     await this.props.GET_ORDER_SET_LIST({
      page: this.state.page,
      perPage: this.state.perPage
     });
    }
    this.setState({
      ...this.state,
      search: ""
    });
  }

  handleSort = async (val, key, direction) => {
    await this.props.GET_ORDER_SET_LIST({
      page: this.state.page,
      perPage: this.state.perPage,
      sort: val,
      direction: direction,
     });
     this.setState({
      ...this.state,
      [key]: !this.state?.[key]
    })
  }

  render() {
    const { openForm, openForm_, editData } = this.state;
    return (
      <React.Fragment>
        <VerticalList
          parent_id={"orderSet_master"}
          addFunc={this.handleForm}
          // searchCmpProps starts here
          id={"orderSetMaster"}
          placeholder={"search code & Description"}
          // loading={this.props?.searchLoading}
          onSearchChange={this?.handleSearch}
          hideSearchBar={false}
          hideFilter={true}
          // onFilterClicked={this.props?.onFilterClicked}
          // isFilterApplied={this.props?.isFilterApplied}
          TabList={[
            {
              name: "Order Set",
              component: (
                <CommonTable
                  parent_id={"consult_master"}
                  rightAlign={["Status", "Action"]}
                  handleEdit={this.handleEdit}
                  handleCheckBox={this.handleCheckBox}
                  handleView={this.handleView}
                  Header={[
                    "S.No",
                    "Order Set Code",
                    {label: "Short Description", showSort:true, sortValue: this.state.short_desc, onclick: (val) => this.handleSort(val, 'short_desc', this.state.short_desc)},
                    {label: "Long Description", showSort:true, sortValue: this.state.long_desc, onclick: (val) => this.handleSort(val, 'long_desc', this.state.long_desc)},
                    "Order Set Applicability",
                    "Status",
                    "Action",
                  ]}
                  dataList={this.props?.orderSetMasterList?.data ?? []}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "orderSetCode" },
                    { type: ["TEXT"], name: "shortDesc" },
                    { type: ["TEXT"], name: "longDesc" },
                    {
                      type: ["VIEWICON"],
                      name: "",
                      icon: (
                        <VisibilityIcon
                          style={{
                            color: "#0071F2",
                            fontSize: 16,
                            marginRight: "5px",
                          }}
                        />
                      ),
                      label: "View",
                      // toolTip: {
                      //   title: "Draft",
                      //   placement: "top",
                      //   arrow: true,
                      // },
                    },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    { type: ["EDIT"], name: "", align: "right" },
                  ]}
                  handlepageChange={(currentPage, PerPage) =>
                    this.handlepageChange(currentPage, PerPage)
                  }
                  TableCount={this.props?.orderSetMasterList?.data?.[0]?.TotalCount}
                  incrementCount={this.state.page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />
              ),
            },
          ]}
        />
        <OverlayCompt
          parent_id={"consult-master"}
          open={openForm}
          children={
            <React.Fragment>
              {openForm && (
                <OrderSetForm
                  parent_id={"consult-master"}
                  editData={editData}
                  closeForm={this.handleForm}
                  saveForm={this.saveForm}
                />
              )}
            </React.Fragment>
          }
        />
        <OverlayCompt
          parent_id={"consult-master"}
          open={openForm_}
          children={
            <React.Fragment>
              {openForm_ && (
                <OrderSetApplicabilityTabel
                  parent_id={"consult-master"}
                  editData={editData}
                  closeForm_={this.handleForm_}
                  saveForm_={this.saveForm}
                  editShortDes={this.state?.editShortDes}
                  edit_id={this.state?.edit_id}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orderSetMasterList: state?.orderSetMasterSlice?.order_set_master_list,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(OrderSetMaster));
