/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Button, Grid } from "@material-ui/core";
import expandarrow from "../../assets/icons8-expand-arrow.svg";
import { CommonTable } from "../../components";
import { SearchWithFilter } from "../../components";
import GenerateFormVersion2 from "../../components/common/smartForm/genreateFormversion2";
import { YearPicker } from "../../components/common/smartForm/component/YearPicker";
import { Row, Col } from "qdm-component-library";
import { FilterSelect } from "./filterSelect";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../HOCs";
import moment from "moment";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  leftButton: {
    border: "none",
    backgroundColor: "#fff",
    width: 32,
    height: 32,
    borderRadius: "50%",
    cursor: "pointer",
  },
  addNewModal: {
    padding: "15px",
    width: "86%",
    height: "230px",
  },
  entityAdd: {
    padding: "15px",
    width: "86%",
    height: "700px",
  },
  copyYearModal: {
    padding: "18px",
    width: "27%",
    height: "230px !important",
  },
  copyNote: {
    border: "none",
    color: "#0071F2",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    fontSize: "15px",
    fontFamily: "poppin",
    cursor: "pointer",
    paddingRight: 10,
    paddingBottom: 9,
    paddingLeft: 20,
  },
  confirmNote: {
    border: "none",
    color: "#ff5c5a",
    borderRadius: 5,
    background: "#fff2f2",
    fontSize: "15px",
    fontFamily: "poppin",
    cursor: "pointer",
    padding: 6,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 21px 0px 15px",
  },
  saveBtn: {
    padding: "6px 20px",
    borderRadius: 7,
    textTransform: "none !important",
    fontSize: 16,
    fontFamily: "pc_semibold",
    background: "#0071f2",
    color: "#fff",
    "&:hover": {
      background: "#0071f2",
    },
  },
  pickYear: {
    width: 380,
  },
}));

function HolidayTableVersion2(props) {
  const { parent_id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [Thdata, setThdata] = React.useState([]);
  const listData = useSelector(
    (state) => state?.holidaySlice?.holidy_list?.data
  );
  console.log(listData, "s;ldijrtttt");
  const master = useSelector(
    (state) => state?.holidaySlice?.holiday_master?.data
  );
  // work in progress
  const EntityMasters = useSelector(
    (state) => state?.holidaySlice?.entity_master?.data
  );
  const EditMaster = useSelector(
    (state) => state?.holidaySlice?.holidy_single_list?.data?.result
  );

  const EditMasters = useSelector(
    (state) => state?.holidaySlice?.holidy_single_list_selected?.data?.result
  );
  console.log(EditMasters, "EntityMasters");
  // const upsertDatas = useSelector(
  //   (state) => state?.holidaySlice?.holiday_upsert?.data
  // );

  // const HolidaySingleRead = useS

  const saveloading = useSelector(
    (state) => state?.holidaySlice?.holiday_upsert?.loading
  );

  const [allMasters, setAllMasters] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [isAdd, setIsAdd] = React.useState(false);
  const [editId, setEditId] = React.useState("");
  const [copyNewHolidayYear, setCopyNewHolidayYear] = React.useState(false);
  const [copyYear, setCopyYear] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const [showStatus, setShowStatus] = React.useState(false);
  const [treeData, setTreeData] = React.useState();
  const [treeListData, setTreeListData] = React.useState([]);
  const [controler, setControler] = React.useState(false);
  const [showFacility, setShowFacility] = React.useState(true);
  const [currentYear, setCurrentYear] = React.useState(moment().year());
  const [refresh, setRefresh] = React.useState(false);
  const [calendarYear, setConfirmCalenderYear] = React.useState({
    copy_calander_year: "",
  });
  const [copyYearData, setCopyYearData] = React.useState([]);
  const [copiedData, setCopiedData] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  console.log(treeListData, "treeListDataweer");
  useEffect(() => {
    dispatch(
      actions.HOLIDAY_READ({
        page: page,
        perPage: perPage,
        CalYear: currentYear,
      })
    );
  }, [currentYear, dispatch, page, perPage]);
  const AppointmentTypeUpdsert = useSelector(
    (state) => state?.resourceByAppointmentTypesSlice.table_add
  );
  console.log(AppointmentTypeUpdsert, "sldkfkslfit");
  // useEffect(() => {
  //   dispatch(actions.HOLIDAY_UPSERT(copyYearData));
  // }, [copyYearData, dispatch]);

  useEffect(() => {
    dispatch(actions.HOLIDAY_SINGLE_READ(editId));
    dispatch(actions.HOLIDAY_SINGLE_READ_SELECTED(editId));
  }, [editId]);
  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(
      actions.HOLIDAY_READ({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
        CalYear: currentYear,
      })
    );
  };

  const getallmasters = async () => {
    debugger;
    await dispatch(
      actions.HOLIDAY_READ({
        page: page,
        perPage: perPage,
        CalYear: currentYear,
      })
    );
    const data = await props.PRACTITIONER_MASTERS();
    setAllMasters(data?.payload?.data);
  };
  // const Thdata = [
  //   {
  //     Title: "Entity",
  //     Size: 9,
  //   },
  //   {
  //     Title: "Status",
  //     Size: 3,
  //   },
  // ];
  React.useEffect(() => {
    if (EntityMasters !== undefined && !editId) {
      setTreeData(EntityMasters);
    }
    if (EditMaster !== undefined && editId) {
      setTreeData(EditMaster);
    }
  }, [EditMaster, EntityMasters, editId]);

  const [valueDef, setValueDef] = React.useState([]);
  const [id, setId] = React.useState("");
  React.useEffect(() => {
    let arr = [];
    const iddd = ["Organization/10068", "Organization/10063"];
    if (!editId) {
      // let main = [];

      // main.push({
      //   entity: "AINQA - INDIA",
      //   id: "1",
      //   reason: "",
      //   // status: "true",
      //   children: arr,
      // });
      // treeData?.map((item) => {
      //   console.log(item, "lslssdrts");

      //   let subArr = [];
      //   if (item?.Organization?.length) {
      //     item?.Organization?.map((value) => {
      //       return subArr.push({
      //         entity: value?.name,
      //         id: value?._id,
      //         // effectiveFrom: "test",
      //         // effectiveTo: "test1",
      //         reason: "",
      //         check: valueDef.includes(value._key),
      //         // status: value?.activestatus,
      //         _key: value?._key,
      //       });
      //     });
      //   }

      //   return arr.push({
      //     entity: item?.name,
      //     id: item?._id,
      //     // effectiveFrom: "test",
      //     // effectiveTo: "test1",
      //     check: valueDef.includes(item._key),
      //     // status: item?.activestatus,
      //     children: subArr,
      //     _key: item?._key,
      //   });
      // });
      // setTreeListData(main);
      const constructFacility = (data) => {
        // debugger
        let resultData__ = [];
        if (Array.isArray(data) && data?.length > 0) {
          data.map((val, i) => {
            resultData__.push({
              OrgType: "Facility",
              id: val?._id ?? "",
              entity: val?.name ?? "",
              reason: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.reason ?? "",
              status: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.status ?? "",
            });
          });
        }
        return resultData__;
      };
      const constructOrganization = (data) => {
        // debugger
        let resultData_ = [];
        if (Array.isArray(data) && data?.length > 0) {
          data.map((val, i) => {
            resultData_.push({
              OrgType: "Organization",
              id: val?._id ?? "",
              entity: val?.name ?? "",
              reason: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.reason ?? "",
              status: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.status ?? "",
              children:
                Array.isArray(val?.facility) && val?.facility?.length > 0
                  ? constructFacility(val?.facility)
                  : [],
            });
          });
        }
        return resultData_;
      };

      const ConstructJSONNew = (data) => {
        debugger;
        let resultData = [];
        if (Array.isArray(data) && data?.length > 0) {
          data.map((val, i) => {
            resultData.push({
              OrgType: "Enterprise",
              Checked: true,
              id: val?._id ?? "",
              entity: val?.name ?? "",
              reason: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.reason ?? "",
              status: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.status ?? "",
              children:
                Array.isArray(val?.Organization) &&
                val?.Organization?.length > 0
                  ? constructOrganization(val?.Organization)
                  : [],
            });
          });
        }
        debugger;
        return resultData;
      };
      const result = ConstructJSONNew(EntityMasters);
      setTreeListData(result);
    }
    if (editId) {
      // treeData[0]?.HolidayDtls?.map((item) => {
      //   //   const subArr1 = [];
      //   if (item?.orgid) {
      //     item?.orgid.map((values) => {
      //       if (values?.OrgTree?.child1) {
      //         values?.OrgTree?.child1.map((val) => {
      //         });
      //       }
      //       if (values?.OrgTree?.parent1) {
      //         // alert("parent1");
      //       }
      //       //       return subArr1.push({
      //       //         entity: values?.name,
      //       //         // id: values?._id,
      //       //         // reason: "",
      //       //         // // check: valueDef.includes(value._key),
      //       //         // status: values?.activestatus,
      //       //         // // _key: value?._key,
      //       //       });
      //     });
      //   }
      //   //   return main.push({
      //   //     entity: item?.orgid[0].name,
      //   //     id: item?.orgid[0]._id,
      //   //     reason: "",
      //   //     // check: valueDef.includes(item._key),
      //   //     status: item?.orgid[0].activestatus,
      //   //     children: subArr1,
      //   //     // _key: item?._key,
      //   //   });
      // });
      console.log(EditMaster?.[0]?.Holidaydtls, "slkdfkji");
      //FUNCTION CALL
      const ConstructOrganizationFromOrganizationJSON = (data) => {
        let OrganizationArray = [];
        OrganizationArray.push({
          OrgType: data?.orgid?.[0]?.OrgType ?? "",
          id: data?.orgid?.[0]?._id ?? "",
          entity: data?.orgid?.[0]?.name ?? "",
          reason: data?.reason ?? "",
          status: data?.holidayStatus?.display ?? "",
          key: data?._key ?? "",
          children:
            Array.isArray(data?.orgid?.[0]?.OrgTree?.orgChild) &&
            data?.orgid?.[0]?.OrgTree?.orgChild?.length > 0
              ? ConstructFacilityJSON(data?.orgid?.[0]?.OrgTree?.orgChild)
              : [],
        });
        return OrganizationArray;
      };
      const ConstructFacilityJSON = (data) => {
        let FacilityArray = [];
        if (Array.isArray(data) && data?.length > 0) {
          data.map((val, i) => {
            FacilityArray.push({
              OrgType: val?.OrgType ?? "",
              id: val?._id ?? "",
              entity: val?.name ?? "",
              // reason: val?.reason ?? "",
              status: val?.holidayStatus?.display ?? "",
              key: val?._key ?? "",
            });
          });
        }
        return FacilityArray;
      };
      const ConstructFacilityFromFacilityJSON = (data) => {
        let FacilityArray = [];
        FacilityArray.push({
          OrgType: data?.orgid?.[0]?.OrgType ?? "",
          id: data?.orgid?.[0]?._id ?? "",
          entity: data?.orgid?.[0]?.name ?? "",
          // reason: data?.reason ?? "",
          key: data?.orgid?.[0]?._key ?? "",
          status: data?.holidayStatus?.display ?? "",
        });
        return FacilityArray;
      };
      const ConstructOrganizationJSON = (data) => {
        let OrganizationArray = [];
        if (Array.isArray(data) && data?.length > 0) {
          data.map((val, i) => {
            OrganizationArray.push({
              OrgType: val?.OrgType ?? "",
              id: val?._id ?? "",
              entity: val?.name ?? "",
              // reason: val?.reason ?? "",
              status: val?.holidayStatus?.display ?? "",
              key: val?._key ?? "",
              children:
                Array.isArray(val?.child2) && val?.child2?.length > 0
                  ? ConstructFacilityJSON(val?.child2)
                  : [],
            });
          });
        }
        return OrganizationArray;
      };
      const ConstructOrganizationFacilityJSON = (data) => {
        let OrganizationArray = [];
        OrganizationArray.push({
          OrgType: data?.orgid?.[0]?.OrgTree?.parent1?.[0]?.OrgType ?? "",
          id: data?.orgid?.[0]?.OrgTree?.parent1?.[0]?._id ?? "",
          entity: data?.orgid?.[0]?.OrgTree?.parent1?.[0]?.name ?? "",
          // reason: data?.orgid?.[0]?.OrgTree?.parent1?.[0]?.reason ?? "",
          key: data?.orgid?.[0]?.OrgTree?.parent1?.[0]?._key ?? "",
          status:
            data?.orgid?.[0]?.OrgTree?.parent1?.[0]?.holidayStatus?.display ??
            "",
          children: ConstructFacilityFromFacilityJSON(data),
        });
        return OrganizationArray;
      };
      // //CONSTRUCT JSON
      const ConstructJSON = (data) => {
        let EnterpriseArray = [];
        if (Array.isArray(data) && data?.length > 0) {
          data.map((val, i) => {
            console.log(val?.orgid?.[0]?._key, "slkdjslkdjit");

            //CONSTRUCT ENTERPRISE JSON

            //IF ORGANIZATION TYPE IS "Enterprise"
            if (val?.orgid?.[0]?.OrgType === "Enterprise") {
              EnterpriseArray.push({
                OrgType: val?.orgid?.[0]?.OrgType ?? "",
                id: val?.orgid?.[0]?._id ?? "",
                entity: val?.orgid?.[0]?.name ?? "",
                // reason: val?.reason ?? "",
                status: val?.orgid?.[0]?.holidayStatus?.display ?? "",
                key: val?.orgid?.[0]?._key ?? "",
                children:
                  Array.isArray(val?.orgid?.[0]?.OrgTree?.child1) &&
                  val?.orgid?.[0]?.OrgTree?.child1?.length > 0
                    ? ConstructOrganizationJSON(
                        val?.orgid?.[0]?.OrgTree?.child1
                      )
                    : [],
              });
            }
            //IF ORGANIZATION TYPE IS "Organization"
            else if (val?.orgid?.[0]?.OrgType === "Organization") {
              //CREATE PARENT (ENTERPRISE) JSON
              EnterpriseArray.push({
                OrgType: val?.orgid?.[0]?.OrgTree?.parent1?.[0]?.OrgType ?? "",
                id: val?.orgid?.[0]?.OrgTree?.parent1?.[0]?._id ?? "",
                entity: val?.orgid?.[0]?.OrgTree?.parent1?.[0]?.name ?? "",
                // reason: val?.orgid?.[0]?.OrgTree?.parent1?.[0]?.reason ?? "",
                status:
                  val?.orgid?.[0]?.OrgTree?.parent1?.[0]?.holidayStatus
                    ?.display ?? "",
                children: ConstructOrganizationFromOrganizationJSON(val),
              });
            }
            //IF ORGANIZATION TYPE IS "Facility"
            else if (val?.orgid?.[0]?.OrgType === "Facility") {
              //CREATE PARENT (ENTERPRISE) JSON
              EnterpriseArray.push({
                OrgType:
                  val?.orgid?.[0]?.OrgTree?.parent1?.[0]?.parent2?.[0]
                    ?.OrgType ?? "",
                id:
                  val?.orgid?.[0]?.OrgTree?.parent1?.[0]?.parent2?.[0]?._id ??
                  "",
                entity:
                  val?.orgid?.[0]?.OrgTree?.parent1?.[0]?.parent2?.[0]?.name ??
                  "",
                // reason:
                //   val?.orgid?.[0]?.OrgTree?.parent1?.[0]?.parent2?.[0]
                //     ?.reason ?? "",
                status:
                  val?.orgid?.[0]?.OrgTree?.parent1?.[0]?.parent2?.[0]
                    ?.holidayStatus?.display ?? "",
                children: ConstructOrganizationFacilityJSON(val),
              });
            }
          });
        }
        // debugger
        return EnterpriseArray;
      };
      const res = ConstructJSON(EditMaster?.[0]?.Holidaydtls);
      // main.push(res);
      // //CONSTRUCT CONSTRUCT ORGANIZATION FROM FACILITY JSON

      // //CONSTRUCT CONSTRUCT ORGANIZATION FROM ORGANIZATION JSON

      // //CONSTRUCT ORGANIZATION JSON

      // //CONSTRUCT FACILITY JSON

      // //CONSTRUCT FACILITY JSON
      setTreeListData(res);
    }
  }, [editId, treeData, valueDef]);

  useEffect(() => {
    const ids = [];

    if (editId) {
      EditMasters[0]?.HolidayDtls?.map((item) => {
        return ids.push(item?.orgid._key);
      });
      setValueDef(ids);
    }
  }, [editId, treeData]);
  React.useEffect(() => {
    getallmasters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTypeDropdown = () => {
    let tmpArr = [];
    master["holiday_type"]?.map((item) => {
      return tmpArr.push({
        label: item.display,
        _id: item._id,
      });
    });
    return tmpArr;
  };

  const handleDrawerOpen = () => {
    setValues([]);

    setThdata([
      {
        Title: "Entity",
      },
    ]);
    setEditId(false);
    setClicked(true);
    setIsAdd(false);
    const TypeDropdown = getTypeDropdown();
    setState({
      ...state,
      open: true,
    });
    setData([
      {
        componet_type: "yearSelector",
        label: "Calendar Year",
        state_name: "calender_year",
        value: moment().year(),
        required: true,
      },

      {
        componet_type: "date",
        label: "Date",
        state_name: "date",
        value: state?.edit_data?.date ?? "",
        dateFormat: "dd MMM",
        required: true,
        grid: {
          md: 2,
        },
      },
      {
        componet_type: "select",
        options: TypeDropdown ?? [],
        label: "Holiday Type",
        state_name: "holiday_type",
        value: state?.edit_data?.holiday_type ?? {},
        grid: {
          md: 2,
        },
      },
      {
        componet_type: "text",
        label: "Holiday Reason",
        state_name: "holiday_reason",
        value: state?.edit_data?.holiday_reason ?? "",
        pattern: /^[A-Za-z]+$/,
        grid: {
          md: 3,
        },
      },
      {
        componet_type: "Switch",
        label: "Status",
        state_name: "Status",
        value: state?.edit_data?.Status ?? false,
        defaultChecked: true,
        grid: {
          md: 1,
        },
      },
      {
        componet_type: "entity",
        label: " Add Applicable Entity",
        grid: {
          md: 1,
        },
      },
    ]);
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null,
    });
  };
  React.useLayoutEffect(() => {
    dispatch(
      actions.HOLIDAY_READ({
        page: page,
        perPage: perPage,
        CalYear: currentYear,
      })
    );
    dispatch(actions.HOLIDAY_MASTERS());
    dispatch(actions.ENTITY_MASTERS());
  }, [currentYear, dispatch, page, perPage]);
  const getUtcTime = (date) => {
    if (date) {
      return moment.utc(date).unix();
    } else {
      return moment.utc().unix();
    }
  };
  const [values, setValues] = React.useState([]);
  React.useLayoutEffect(() => {
    dispatch(
      actions.HOLIDAY_READ({
        page: page,
        perPage: perPage,
        CalYear: currentYear,
      })
    );
    dispatch(actions.HOLIDAY_SINGLE_READ(editId));
    dispatch(actions.HOLIDAY_MASTERS());
    dispatch(actions.ENTITY_MASTERS());
  }, [currentYear, dispatch, editId, page, perPage]);
  useEffect(() => {
    console.log("copiedData", copiedData);
  }, [copiedData]);

  const saveHoliday = async (value) => {
    if (!controler) {
      console.log(value?.Status, "editId");
      setRefresh(!refresh);
      if (copyNewHolidayYear) {
        let newEditedCopyData = [...copiedData];
        newEditedCopyData?.map((val, index) => {
          if (val._key === editId) {
            console.log(index, val, "indexindex");
            newEditedCopyData.splice(index, 1, {
              CalYear: value?.CalYear,
              date: val?.date,
              day: val?.day,
              holiday_type: value?.holiday_type?.label,
              holiday_reason: value?.holiday_reason,
              status: value?.Status === true ? true : false,
              holidayDetails: values ? values : [],
            });
          }
          // return null;
        });
        setCopiedData(newEditedCopyData);
        setCopyNewHolidayYear(false);
      } else {
        let type = "success";

        let data = [
          {
            CalYear: parseInt(moment(value.calander_year).format("YYYY")),
            CalDate: getUtcTime(moment(value.date)),
            CalDay: "CodingMaster/10250",
            HolidayType: value?.holiday_type?._id,
            HolidayReason: value?.holiday_reason,
            status: value?.Status === true ? true : false,
            holidayDetails: values ? values : [],
          },
        ];
        let dataEdit = [
          {
            CalYear: value.calender_year,
            CalDate: getUtcTime(moment(value.date)),
            CalDay: "CodingMaster/10250",
            HolidayType: value?.holiday_type?._id,
            HolidayReason: value?.holiday_reason,
            status: value?.Status === true ? true : false,
            holidayDetails: values ? values : [],
            _key: id,
          },
        ];
        // if (editId.length) {
        //   data._key = editId;
        // }
        const upsertdata = await dispatch(
          actions.HOLIDAY_UPSERT(isEdit ? dataEdit : data)
        );
        if (upsertdata?.payload?.data?.error) {
          // type = ("error", upsertdata?.payload?.data?.message);
          props.alert.setSnack("error", " Holiday Already Exist");
        } else {
          props.alert.setSnack("success");
        }
        await dispatch(
          actions.HOLIDAY_READ({
            page: page,
            perPage: perPage,
            CalYear: currentYear,

            entity_name: filterData?.entity_name?._id ?? null,
            entity_type: filterData?.entity_type?.value ?? null,
          })
        );
        await handleDrawerClose();
        // props.alert.setSnack("error", upsertdata?.payload?.data?.message);
        // setEditId(true);
      }
    } else {
      setEditId(id);
      setControler(false);
      setClicked(false);
    }
  };

  const getDatePickerTimeFormat = (val) => {
    // const unixTimestamp = 1575909015;
    const milliseconds = val * 1000; // 1575909015000

    const dateObject = new Date(milliseconds);

    const humanDateFormat = dateObject.toLocaleString();

    return humanDateFormat;
  };

  const handleEdit = (e, values, index) => {
    setValues([]);
    setControler(false);
    setThdata([
      {
        Title: "Entity",
        Size: 7,
      },
      {
        Title: "Status",
        Size: 2,
      },
      {
        Title: "Reason",
        Size: 1,
      },
    ]);
    setState({
      ...state,
      open: true,
    });
    setClicked(false);
    setIsAdd(true);
    setIsEdit(true);

    const newDate = getDatePickerTimeFormat(values.date);
    const TypeDropdown = getTypeDropdown();
    dispatch(actions.HOLIDAY_SINGLE_READ(values._key));

    setEditId(values._key);
    setId(values._key);
    let data = {
      ...values,
      repeat: values.repeat === "Yes" ? true : false,
      date: newDate,
      holiday_type:
        TypeDropdown.filter((val) => val.label === values.holiday_type) &&
        TypeDropdown.filter((val) => val.label === values.holiday_type)[0]
          ? TypeDropdown.filter((val) => val.label === values.holiday_type)[0]
          : null,
    };
    setState({
      ...state,
      open: true,
      edit_data: data,
    });
    setData([
      {
        componet_type: "yearSelector",
        label: "Calendar Year",
        state_name: "calender_year",
        value: values?.CalYear || moment().year(),
        disabled: true,
      },
      // {
      //   componet_type: "date",
      //   label: "Date",
      //   state_name: "date",
      //   value: values?.date ?? "",
      //   dateFormat: "dd MMM",
      //   disabled: true,
      //   grid: {
      //     md: 2,
      //   },
      // },
      {
        componet_type: "select",
        options: TypeDropdown ?? [],
        label: "Holiday Type",
        state_name: "holiday_type",
        value:
          TypeDropdown.filter((val) => val.label === values.holiday_type) &&
          TypeDropdown.filter((val) => val.label === values.holiday_type)[0]
            ? TypeDropdown.filter((val) => val.label === values.holiday_type)[0]
            : null ?? {},
        grid: {
          md: 2,
        },
      },
      {
        componet_type: "text",
        label: "Reason for Holiday",
        state_name: "holiday_reason",
        value: values?.holiday_reason ?? "",
        grid: {
          md: 3,
        },
      },
      {
        componet_type: "Switch",
        label: "Status",
        state_name: "Status",
        value: values?.status === true ? true : false,
        defaultChecked: values?.status === true ? true : false,
        grid: {
          md: 1,
        },
      },
      {
        componet_type: "entity",
        label: "Add Applicable Entity",
        grid: {
          md: 1,
        },
      },
    ]);
    setShowFacility(false);
  };
  /**
   *
   */
  const handleshowData = () => {
    setClicked(true);
    setIsAdd(true);
    setEditId(false);
    setShowStatus(true);
    setControler(true);
  };

  const statusChange = async (e, values, index) => {
    alert("test");
    let data = {
      _key: values._key,
      status: e.target.checked,
    };
    await dispatch(actions.HOLIDAY_STATUS_CHANGE(data));
    await dispatch(
      actions.HOLIDAY_READ({
        page: page,
        perPage: perPage,
        CalYear: currentYear,

        entity_name: filterData?.entity_name?._id ?? null,
        entity_type: filterData?.entity_type?.value ?? null,
      })
    );
    setState({
      ...state,
      edit_data: null,
      open: true,
    });
    props.alert.setSnack("update");
  };

  // old state

  const [state, setState] = React.useState({
    holiday_reason: "",
    holiday_type: {},
    date: "",
    repeat: true,
  });
  const [filterData, setFilterData] = React.useState({
    entity_type: {},
    entity_name: {},
  });
  const changeState = (key, value) => {
    if (key === "entity_type" && value?.value) {
      props.GET_ENTITY_BY_ID({ id: value.value });
      filterData.entity_name = {};
    }
    if (key === "entity_type") {
      filterData.entity_name = {};
    }
    filterData[key] = value;
    if (filterData?.entity_name?._id && filterData?.entity_type?.value) {
      dispatch(
        actions.HOLIDAY_READ({
          page: page,
          perPage: perPage,
          CalYear: currentYear,

          entity_name: filterData?.entity_name?._id,
          entity_type: filterData?.entity_type?.value,
        })
      );
    } else {
      dispatch(
        actions.HOLIDAY_READ({
          page: page,
          perPage: perPage,
          CalYear: currentYear,
        })
      );
    }
    setFilterData({ ...filterData });
  };

  const [show, setshow] = React.useState(false);
  // const [entity, setentity] = React.useState(true);

  // const handleshow = () => {
  //   setFilterData({
  //     entity_type: {},
  //     entity_name: {},
  //   });
  //   setshow(true);
  //   setentity(false);
  // };
  const handlehide = () => {
    setFilterData({
      entity_type: {},
      entity_name: {},
    });
    setshow(false);
    // setentity(true);
    dispatch(
      actions.HOLIDAY_READ({
        page: page,
        perPage: perPage,
        CalYear: currentYear,
      })
    );
  };

  const onSearchChange = (value = "") => {
    if (value?.length > 1) {
      dispatch(
        actions.HOLIDAY_READ({
          search: value.trim(),
          page: page,
          perPage: perPage,
          CalYear: currentYear,
        })
      );
    } else if (!value) {
      dispatch(
        actions.HOLIDAY_READ({
          page: page,
          perPage: perPage,
          CalYear: currentYear,
        })
      );
    }
  };
  const handleModalOpenNew = () => {
    setConfirmCalenderYear({
      ...calendarYear,
      open: true,
    });
  };
  const handleDrawerCloseNew = () => {
    setConfirmCalenderYear({
      ...calendarYear,
      open: false,
    });
  };
  const onConfirmHoliday = async (value) => {
    setCopyNewHolidayYear(true);
    setCopyYear(parseInt(moment(value.calander_year).format("YYYY")));
    handleDrawerCloseNew();
    await dispatch(
      actions.HOLIDAY_READ({
        CalYear: currentYear,
        page: page,
        perPage: perPage,
      })
    );
    setCopiedData(listData);
  };
  const getDataList = () => {
    if (copiedData.length) {
      return copiedData;
    }
    return listData;
  };
  const onConfirmation = () => {
    const TypeDropdown = getTypeDropdown();

    let finalCopyData = [];

    listData[0]?.copyData?.map((value, index) => {
      return finalCopyData.push({
        CalYear: moment().year() + 1,
        CalDate: value.CalDate,
        CalDay: value._id,
        HolidayType:
          TypeDropdown.filter(
            (val) => val.label === copiedData[index]?.holiday_type
          ) &&
          TypeDropdown.filter(
            (val) => val.label === copiedData[index]?.holiday_type
          )[0]
            ? TypeDropdown.filter(
                (val) => val.label === copiedData[index]?.holiday_type
              )[0]?._id
            : null,
        //value.HolidayType._id,
        HolidayReason: copiedData[index]?.holiday_reason,
        status: true,
        holidayDetails: [],
      });
    });
    setCopyYearData(finalCopyData);
    setCopyNewHolidayYear(false);
    let type = "success";
    props.alert.setSnack(type);
  };
  /**
   *
   */
  const prevYear = () => {
    setCurrentYear(currentYear - 1);
  };
  /**
   *
   */
  const nextYear = () => {
    setCurrentYear(currentYear + 1);
  };
  const handleDelete = (e, values, index) => {
    let newEditedCopyData = [...copiedData];
    newEditedCopyData.splice(index, 1);
    setCopiedData(newEditedCopyData);
  };
  return (
    <div id={`${parent_id}-parent-div`} style={{ borderRadius: "12px" }}>
      <div id={`${parent_id}-parent-sub-div`} style={{ width: "100%" }}>
        {show && (
          <div id={`${parent_id}-parent-sub-div`} style={{ marginBottom: 14 }}>
            <Row id={`${parent_id}-parent-row`}>
              <Col
                id={`${parent_id}-expandarrow-col`}
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                inLineStyles={{ padding: 0 }}
              >
                <button
                  id={`${parent_id}-expandarrow-button`}
                  className={classes.leftButton}
                  onClick={() => handlehide()}
                >
                  <img
                    id={`${parent_id}-expandarrow-img`}
                    src={expandarrow}
                    alt="icon"
                    style={{ transform: "rotate(90deg)", width: 14 }}
                  />
                </button>
              </Col>
              <Col
                id={`${parent_id}-parent-col`}
                xs={10}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                inLineStyles={{ padding: 0 }}
              >
                <Row
                  id={`${parent_id}-parent-sub-row`}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Col
                    id={`${parent_id}-FilterSelect-col`}
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    inLineStyles={{ padding: "0px 0px 0px 8px" }}
                  >
                    <FilterSelect
                      parent_id={"entity-type"}
                      value={filterData?.entity_type}
                      onChange={(value) => changeState("entity_type", value)}
                      options={allMasters?.["entity"] ?? []}
                      label="Entity Type :"
                    />
                  </Col>
                  <Col
                    id={`${parent_id}-FilterSelect-col`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    inLineStyles={{ padding: "0px 0px 0px 8px" }}
                  >
                    <FilterSelect
                      parent_id={"entity-name"}
                      value={filterData?.entity_name}
                      onChange={(value) => changeState("entity_name", value)}
                      options={props?.entity_name_options ?? []}
                      label="Entity Name :"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        <Card id={`${parent_id}-parent-card`} style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              container
              item
              md={12}
              id={`${parent_id}-discription-sub-div`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px",
                borderBottom: "1px solid lightgray",
              }}
            >
              <div id={`${parent_id}-List-of-Holidays-div`}>
                <p
                  id={`${parent_id}-List-of-Holidays-title-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    paddingTop: "12px",
                  }}
                >
                  {copyNewHolidayYear
                    ? `Copying Calendar year from ${
                        copyYear - 1
                      } to ${copyYear}`
                    : "Holidays"}
                </p>
              </div>
              {!copyNewHolidayYear && (
                <div style={{ paddingTop: "20px" }}>
                  <YearPicker
                    label="Calendar Year"
                    currentYear={currentYear}
                    prevYear={prevYear}
                    nextYear={nextYear}
                  />
                </div>
              )}
              <div
                id={`${parent_id}-entity-div`}
                style={{ marginTop: "16px", float: "right", display: "flex" }}
              >
                {/* {!copyNewHolidayYear && (
                  <SearchWithFilter
                    id="Holiday"
                    placeholder="Search by entity,holiday type & holiday reason"
                    onSearchChange={onSearchChange}
                    hideSearchBar={false}
                    hideFilter={true}
                  />
                )} */}
                {/* <CustomTooltip /> */}

                {copyNewHolidayYear ? (
                  <div>
                    <p
                      style={{
                        fontFamily: "poppinsemibold",
                        fontSize: "16px",
                      }}
                    >
                      Calendar Year : {copyYear}
                    </p>
                  </div>
                ) : (
                  <div>
                    <Button
                      id={`${parent_id}-Add-New-button`}
                      disabled={
                        show
                          ? filterData?.entity_type?.value &&
                            filterData?.entity_name?.value
                            ? false
                            : true
                          : false
                      }
                      variant={"contained"}
                      onClick={handleDrawerOpen}
                      className={classes.saveBtn}
                      style={{
                        "&.MuiButton-contained": {
                          "&:hover": {
                            backgroundColor: "#0071f2 !important",
                          },
                        },
                      }}
                    >
                      +Add Holiday
                    </Button>
                  </div>
                )}

                <GenerateFormVersion2
                  parent_id={"holidaytable"}
                  loading={saveloading}
                  open={state.open}
                  save={saveHoliday}
                  handlecancel={handleDrawerClose}
                  header={state.edit_data ? "Edit Holiday" : "Add New Holiday"}
                  json={data}
                  handleshow={handleshowData}
                  nocancel
                  isAdd={isAdd}
                  modalStructure={
                    isAdd ? classes.entityAdd : classes.addNewModal
                  }
                  isEditHoliday={showFacility}
                  nosave={false}
                  clicked={clicked}
                  showStatus={showStatus}
                  treeListData={treeListData}
                  setValues={setValues}
                  values={values}
                  isEdit={isEdit}
                  refresh={refresh}
                  Thdata={Thdata}
                  controler={controler}
                />
              </div>
            </div>
            <div id={`${parent_id}-CommonTable-div`} style={{ padding: 20 }}>
              <CommonTable
                parent_id={"holidaytable"}
                Header={[
                  "S.No",
                  "Date",
                  "Day",
                  "Holiday Type",
                  "Holiday Reason",
                  "Status",
                  "Action",
                ]}
                customStyles={[
                  {
                    key: "Holiday Reason",
                    styles: {
                      width: "538px",
                    },
                  },
                  {
                    key: "Status ",
                    styles: {
                      width: "70px",
                    },
                  },
                  {
                    key: "Action  ",
                    styles: {
                      width: "70px",
                    },
                  },
                ]}
                dataList={getDataList()}
                tableData={[
                  { type: ["INCRIMENT"], name: "" },
                  { type: ["TEXT"], name: "date" },
                  { type: ["TEXT"], name: "day" },
                  { type: ["TEXT"], name: "holiday_type" },
                  { type: ["TEXT"], name: "holiday_reason" },

                  { type: ["STATUS"], name: "status" },
                  {
                    type: copyNewHolidayYear ? ["COPYACTION"] : ["ACTION"],
                    name: "action",
                  },
                ]}
                handleCheckBox={statusChange}
                handleEdit={handleEdit}
                handlepageChange={(currentPage, PerPage) =>
                  handlepageChange(currentPage, PerPage)
                }
                TableCount={listData?.[0]?.total_count}
                incrementCount={page}
                showPagination={true}
                rowsPerPageOptions={[
                  { label: "10 Rows", value: 10 },
                  { label: "50 Rows", value: 50 },
                  { label: "100 Rows", value: 100 },
                ]}
                handleDelete={handleDelete}
              />
            </div>
            <div className={classes.footer}>
              <button
                id={`${parent_id}-entity-button`}
                className={
                  copyNewHolidayYear ? classes.confirmNote : classes.copyNote
                }
                onClick={handleModalOpenNew}
              >
                <div
                  id={`${parent_id}-View-holidays-entities-div`}
                  style={{ display: "flex" }}
                >
                  <div
                    id={`${parent_id}-View-holidays-entities-sub-div`}
                    className={classes.noteDiv}
                  >
                    {copyNewHolidayYear
                      ? "Note: Edit (or) Delete holidays as per the new calender year"
                      : "Copy Holidays to New Calendar Year"}
                  </div>
                </div>
              </button>
              {copyNewHolidayYear && (
                <Button
                  id={`${parent_id}_generateform_Save_button`}
                  disabled={props?.loading ?? false}
                  variant={"contained"}
                  className={classes.saveBtn}
                  size={"small"}
                  contained
                  onClick={() => onConfirmation()}
                  disableElevation
                >
                  Confirm & Save
                </Button>
              )}
            </div>
            <Grid width="27%">
              <GenerateFormVersion2
                parent_id={"copyholidaytable"}
                loading={saveloading}
                open={calendarYear.open}
                save={(data) => {
                  onConfirmHoliday(data);
                }}
                treeListData={treeListData}
                handlecancel={handleDrawerCloseNew}
                header="Copy to Calendar Year"
                json={[
                  {
                    componet_type: "yearSelector",
                    dateFormat: "yyyy",
                    label: "Select the year to copy",
                    state_name: "calander_year",
                    value: moment().year(),
                    className: classes.pickYear,
                  },
                ]}
                modalStructure={classes.copyYearModal}
                nosave
                handleClick={() => onConfirmHoliday()}
                clicked={clicked}
                editId={editId}
              />
            </Grid>
          </div>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  practitioner_masters: state?.practitionerMasterSlice?.practitioner_masters,
  entity_name_options: state?.practitionerSlice?.get_entity_by_id?.data ?? [],
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(HolidayTableVersion2));
