import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Button } from "@material-ui/core";
import { CommonTable } from "../../components";
import { GenerateForm, SearchWithFilter } from "../../components";
import { AlertProps } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../HOCs";



const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  leftButton: {
    border: "none",
    backgroundColor: "#fff",
    width: 32,
    height: 32,
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

function SymtomsAndSpecialtyTable(props) {
  const { parent_id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const listData = useSelector(
    (state) => state?.symptomAndSpecialtySlice?.symptom_and_specialty_list?.data
  );
  const symptomAndSpecialtyMasterData = useSelector(
    (state) =>
      state?.symptomAndSpecialtySlice?.symptom_and_specialty_master?.data
  );
  const loading = useSelector(
    (state) => state?.holidaySlice?.holidy_list?.loading
  );
  const saveloading = useSelector(
    (state) => state?.holidaySlice?.holiday_upsert?.loading
  );

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [symptom_code, setSymptomCode] = React.useState(false)
  const [symptom_desc, setSymptomDesc] = React.useState(false)

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.SYMPTOM_AND_SPECIALTY_READ({ page: (currentPage - 1) * PerPage, perPage: PerPage }));

  };

  const handleDrawerOpen = () => {
    setState({
      ...state,
      open: true,
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null,
    });
  };

  React.useLayoutEffect(() => {
    dispatch(actions.SYMPTOM_AND_SPECIALTY_READ({ page: page, perPage: perPage}));
    dispatch(actions.SYMPTOM_AND_SPECIALTY_MASTERS());
  }, []);

  const constructSpecialtyData = (data) => {
    let arry = [];
    data.map((val) => {
      arry.push(val.value);
    });
    return arry;
  };

  const saveSymptomAndSpecialties = async (values) => {
    let severity = AlertProps.severity.success;
    let data_ = {};
    if (state?.edit_data) {
      data_ = {
        _key: state?.edit_data._key,
        id: state?.edit_data.id,
        status: state?.edit_data.status,
        symptom_code: values.symptom_code,
        symtom_description: values.symtom_description,
        specialities: constructSpecialtyData(values.specialities),
      };
    } else {
      data_ = {
        symptom_code: values.symptom_code,
        symtom_description: values.symtom_description,
        specialities: constructSpecialtyData(values.specialities),
        status: true,
      };
    }
    const upsertdata = await dispatch(
      actions.SYMPTOM_AND_SPECIALTY_UPSERT(data_)
    );
    if (upsertdata?.payload?.data?.error) {
      severity = AlertProps.severity.error;
      //ERROR ALERT
      props.alert.setSnack({
        open: true,
        severity: severity,
        msg: upsertdata?.payload?.data?.validation_error[0].Errormsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      await dispatch(actions.SYMPTOM_AND_SPECIALTY_READ({ page: page, perPage: perPage}));
      await handleDrawerClose();
      //SUCCESS ALERT
      props.alert.setSnack({
        open: true,
        severity: severity,
        msg: state?.edit_data?._key
          ? "Symptom & Specialties updated successfully"
          : "Symptom & Specialties added successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const handleEdit = async (e, values, index) => {
    if (values?._key) {
      let d = await dispatch(
        actions.SYMPTOM_AND_SPECIALTY_SINGLE_READ({ kay: values?._key })
      );
      let data = {
        ...values,
        symptom_code: d?.payload?.data?.[0]?.symptom_code ?? "",
        symtom_description: d?.payload?.data?.[0]?.symtom_description ?? "",
        specialities: d?.payload?.data?.[0]?.specialities ?? null,
      };
      setState({
        ...state,
        edit_data: data,
        open: true,
      });
    }
  };

  const statusChange = async (e, values, index) => {
    let data = {
      _key: values._key,
      status: e.target.checked,
    };
    await dispatch(actions.SYMPTOM_AND_SPECIALTY_STATUS_CHANGE(data));
    await dispatch(actions.SYMPTOM_AND_SPECIALTY_READ({ page: page, perPage: perPage}));
    setState({
      ...state,
      edit_data: null,
    });
    props.alert.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "Symptom & Specialties status updated",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  };
  const specialties_data = [
    { value: "1", label: "val 1" },
    { value: "2", label: "val 2" },
    { value: "3", label: "val 3" },
  ];
  // old state
  const [state, setState] = React.useState({
    code: "",
    symptom_code: "",
    symtom_description: "",
    specialities: [],
  });


  const onSearchChange = (value = "") => {
    if (value?.length > 2) {
      dispatch(actions.SYMPTOM_AND_SPECIALTY_READ({
        search: value.trim(),
        page: page,
        perPage: perPage
      }));
    } else if (!value) {
      dispatch(actions.SYMPTOM_AND_SPECIALTY_READ({
        page: page,
        perPage: perPage
      }));
    }
  };

  const handleSort = async (val, key, direction) => {

    let sort_val = ''
    if(val === 'Symptom Code'){
      sort_val = symptom_code
      setSymptomCode(!symptom_code)
    } else {
      sort_val = symptom_desc
      setSymptomDesc(!symptom_desc)
    } 

    await dispatch(actions.SYMPTOM_AND_SPECIALTY_READ({
      page: page,
      perPage: perPage,
      sort: val,
      direction: sort_val ? `` : 'DESC'
    }));
  }

  return (
    <div id={`${parent_id}-parent-div`} style={{ borderRadius: "12px" }}>
      <div id={`${parent_id}-parent-sub-div`} style={{ width: "100%" }}>
        <Card id={`${parent_id}-parent-card`} style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-discription-sub-div`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px",
              }}
            >
              <div id={`${parent_id}-List-of-symptonsAndSpecialties-div`}>
                <p
                  id={`${parent_id}-List-of-symptonsAndSpecialties-title-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  Symptoms & Specialties
                </p>
              </div>
              <div
                id={`${parent_id}-entity-div`}
                style={{ marginTop: "16px", float: "right", display: 'flex', alignItems: 'center' }}
              >
                <SearchWithFilter
                  id="symptomyandspeciality"
                  placeholder="Search code and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                //  onFilterClicked={onFilterClicked}
                //  isFilterApplied={props?.isFilterApplied}
                />
                <Button
                  id={`${parent_id}-Add-New-button`}
                  disabled={false}
                  variant={"contained"}
                  color={"primary"}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </Button>

                <GenerateForm
                  parent_id={"SymptomAndSpecialtiestable"}
                  loading={saveloading}
                  open={state.open}
                  save={saveSymptomAndSpecialties}
                  handlecancel={handleDrawerClose}
                  header={state.edit_data ? "Edit Symptom" : "Add New Symptom"}
                  json={[
                    {
                      componet_type: "text",
                      label: "Symptom Code",
                      isUpperCase: true,
                      disabled: state.edit_data ? true : false,
                      state_name: "symptom_code",
                      value: state?.edit_data?.symptom_code ?? "",
                      required: true,
                      textLength: 20,
                    },
                    {
                      componet_type: "text",
                      label: "Symptom Description",
                      state_name: "symtom_description",
                      value: state?.edit_data?.symtom_description ?? "",
                      required: true,
                      textLength: 60,
                    },
                    {
                      componet_type: "select",
                      options: symptomAndSpecialtyMasterData ?? [],
                      label: "Specialties",
                      state_name: "specialities",
                      value: state?.edit_data?.specialities ?? [],
                      required: true,
                      multi: true,
                    },
                  ]}
                  nocancel
                />
              </div>
            </div>
            <div
              id={`${parent_id}-CommonTable-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}
            >
              <CommonTable
                parent_id={"symptonsAndSpecialtiestable"}
                Header={[
                  "S.No",
                  {label:"Symptom Code", showSort: true, sortValue: symptom_code, onclick:(value) => handleSort(value, 'symptom_code' ,symptom_code)},
                  {label:"Symptom Description", showSort: true, sortValue: symptom_desc, onclick:(value) => handleSort(value, 'symptom_desc' ,symptom_desc)},
                  "Specialties",
                  "Status",
                  "Action",
                ]}
                dataList={listData}
                tableData={[
                  { type: ["INCRIMENT"], name: "" },
                  { type: ["TEXT"], name: "symptom_code" },
                  { type: ["TEXT"], name: "symtom_description" },
                  { type: ["TEXT"], name: "specialities" },
                  { type: ["CHECKBOX"], name: "status" },
                  { type: ["EDIT"], name: "action" },
                ]}
                handleCheckBox={statusChange}
                handleEdit={handleEdit}
                handlepageChange={(currentPage, PerPage) =>
                  handlepageChange(currentPage, PerPage)
                }
                TableCount={listData?.[0]?.total_count}
                incrementCount={page}
                showPagination={true}
                rowsPerPageOptions={[
                  { label: "10 Rows", value: 10 },
                  { label: "50 Rows", value: 50 },
                  { label: "100 Rows", value: 100 },
                ]}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
export default withAllContexts(SymtomsAndSpecialtyTable);
