import React from 'react';
import { makeStyles, Typography, Collapse, Tooltip } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { WeekCalendar, Tab, Tabpanel, Text } from 'qdm-component-library';
import moment from 'moment';
import { Direct, Suggested, Videoconsultation } from '../../../../components';
import { CustomTab } from './';

const useStyles = makeStyles(() => ({
    root: {
        border: '1px solid #E0E0E0',
        borderRadius: 8,
        '& .rowCenter': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    imgWrapper: {
        width: 38,
        height: 38,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8,
        '& img': {
            maxWidth: '100%',
            height: 'auto'
        }
    },
    nameStyle: {
        color: '#101010',
        fontSize: 14,
        fontFamily: 'pc_medium',
        // maxWidth:"170px",
        // // width: "170px", 
        // overflow: "hidden",
        // textOverflow: "ellipsis",
    },
    regularFont: {
        fontSize: 12,
        color: '#646464',
        fontFamily: 'pc_regular'
    },
    header1Column: {
        padding: 16,
        borderBottom: '1px solid #E0E0E0'
    },
    header2Column: {
        padding: '12px 16px',
        display: 'flex',
        alignItems: 'center'
    },
    btnText: {
        fontSize: 14,
        fontFamily: 'pc_regular',
        cursor: 'pointer'
    },
    timeFont: {
        color: '#2B2B2B',
        fontSize: 14,
        fontFamily: 'pc_regular',
        display: 'flex',
        alignItems: 'center'
    },
    slotAvailable: {
        border: '1px solid #E5F1FE',
        backgroundColor: '#E5F1FE',
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        borderRadius: 4,
        marginBottom: 12,
        '& .MuiTypography-body1': {
            color: '#0071F2',
            fontSize: 12,
            fontFamily: 'pc_medium'
        },
        '& .MuiTypography-body2': {
            color: '#0071F2',
            fontSize: 11,
            fontFamily: 'pc_regular'
        }
    },
    iconText: {
        marginLeft: 8,
        color: "rgb(111, 111, 111)",
        fontSize: 12,
        fontFamily: "pc_regular !important",
    },
    body: {
        padding: 12,
        paddingTop: 0
    },
    weekcalendar: {
        '& .fadedDate,.activeDate': {
            fontSize: 14
        }
    },
    icons: {
        display: "flex",
        alignItems: "center",
        margin: "4% 0px",
        justifyContent: "space-between",
    },
}));

export const CustomCollapse = (props) => {

    const classes = useStyles(props);
    const { parent_id, isOpen = false } = props;
    const [state, setState] = React.useState({
        open: isOpen
    });

    React.useEffect(()=>{
        onChangeState('open', isOpen);
    },[isOpen]);

    const onChangeState = (key, value) => {
        setState({ ...state, [key]: value })
    }

    const isSlotAvailable = () => {
        if (
            props?.slotsData?.morning?.length === 0 &&
            props?.slotsData?.afternoone?.length === 0 &&
            props?.slotsData?.evening?.length === 0 &&
            props?.slotsData?.night?.length === 0
        ) {
            return 'Slot Unavailable'
        }
        else {
            return 'Slot Available'
        }
    }

    const { patientDetails, doctorDetails, scheduleAt, scheduleDateFormat = "DD MMM,YYYY" } = props;

    return (
        <div
            id={`${parent_id}-parent-div`}
            className={classes.root}>
            <div
                id={`${parent_id}-parent-sub-div`}
                className={classes.header}>
                <div
                    id={`${parent_id}-parent-div`}
                    className={`rowCenter ${classes.header1Column}`}>
                    <div
                        id={`${parent_id}-parent-styled-div`}
                        className={'rowCenter'} style={{ flex: 1 }}>
                        <div
                            id={`${parent_id}-img-div`}
                            className={classes.imgWrapper}>
                            <img
                                id={`${parent_id}-img`}
                                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
                        </div>
                        <div
                            id={`${parent_id}-parent-title-div`}
                        >
                            <div
                                id={`${parent_id}-title-div`}
                                className={'rowCenter'} style={{ marginBottom: 4 }}>
                                <Typography
                                    id={`${parent_id}-name-title-typography`}
                                    variant="body1" className={classes.nameStyle} style={{ marginRight: 8,}}>
                                        <Tooltip title={patientDetails?.name} arrow><div style={{overflow: "hidden",whiteSpace: "nowrap", width: "170px" ,textOverflow: "ellipsis", }}> {patientDetails?.name ?? ''} </div></Tooltip>
                                    
                                </Typography>
                                <Typography
                                    id={`${parent_id}-age-title-typography`}
                                    variant="body1" className={classes.regularFont}>
                                    {patientDetails?.age ?? ''} {patientDetails?.gender ? `| ${patientDetails?.gender}` : ''}
                                </Typography>
                            </div>
                            <div
                                id={`${parent_id}-title-div`}
                                className={'rowCenter'}>
                                <Typography
                                    id={`${parent_id}-mrn-title-typography`}
                                    variant="body1" className={classes.regularFont}>
                                    {patientDetails?.mrn ?? ''}
                                </Typography>
                                <Typography
                                    id={`${parent_id}-contact-title-typography`}
                                    variant="body1" className={classes.regularFont}>
                                    &nbsp; {patientDetails?.contact && '●'} &nbsp;{patientDetails?.contact ?? ''}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div
                        id={`${parent_id}-parent-title-div`}
                    >
                        <Typography
                            id={`${parent_id}-name-title-typography`}
                            variant="body1" className={classes.nameStyle} style={{ marginBottom: 4 }}>
                            <Tooltip title={doctorDetails?.name} arrow><div style={{overflow: "hidden",whiteSpace: "nowrap", width: "170px" ,textOverflow: "ellipsis", }}> {doctorDetails?.name ?? ''} </div></Tooltip>
                        </Typography>
                        <div
                            id={`${parent_id}-title-div`}
                            className={'rowCenter'}>
                            <Typography
                                id={`${parent_id}-role-title-typography`}
                                variant="body1" className={classes.regularFont}>
                                &nbsp;{doctorDetails?.role ? ( ' ● ' + doctorDetails?.role) : ''}
                            </Typography>
                            <Typography
                                id={`${parent_id}-speciality-title-typography`}
                                variant="body1" className={classes.regularFont}>
                                &nbsp; {doctorDetails?.speciality && '●'} &nbsp;{doctorDetails?.speciality ?? ''}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div
                    id={`${parent_id}-header2Column-div`}
                    className={classes.header2Column}>
                    <div
                        id={`${parent_id}-header2Column-div`}
                        style={{ flex: 1 }}>
                        <Typography
                            id={`${parent_id}-appointmentDateTime-title-typography`}
                            variant="body1" className={classes.timeFont}>
                            {patientDetails?.appointmentDateTime ?? ''}
                            {
                                scheduleAt &&
                                <>
                                    <ArrowRightAltIcon
                                        id={`${parent_id}-appointmentDateTime-ArrowRightAltIcon`}
                                        style={{ margin: '0px 5px' }} />
                                    {scheduleAt ? moment(scheduleAt).format(scheduleDateFormat) : ''} {props?.selectedSlot?.label ?? ''}
                                </>
                            }
                        </Typography>
                    </div>
                    {
                        !props?.hideSlotAvaible &&
                        <Typography
                            id={`${parent_id}-isSlotAvailable-typography`}
                            variant="body1"
                            className={classes.btnText}
                            onClick={() => {
                                onChangeState('open', !state.open)
                                props?.toggle && props?.toggle(!state.open)
                            }}
                            style={{
                                color: isSlotAvailable() === 'Slot Available' ? '#38C20A' : '#FF4D4A'
                            }}
                        >
                            {isSlotAvailable()}
                        </Typography>
                    }
                </div>
            </div>
            <Collapse
                id={`${parent_id}-collapse-admin-collapse`}
                in={state.open}
                timeout="auto"
                unmountOnExit
            >
                <div
                    id={`${parent_id}-collapse-admin-div`}
                    className={classes.body}>
                    {
                        props?.selectedSlot?.label &&
                        <div
                            id={`${parent_id}-choose-another-title-div`}
                            className={classes.slotAvailable}>
                            <InfoOutlinedIcon
                                id={`${parent_id}-choose-another-InfoOutlinedIcon`}
                                htmlColor="#0071F2" />
                            <div
                                id={`${parent_id}-choose-another-div`}
                                style={{ marginLeft: 16 }}>
                                <Typography
                                    id={`${parent_id}-choose-another-selectedSlot-div`}
                                    variant="body1" className={classes.btnText}>
                                    {props?.selectedSlot?.label} Slot available.
                                </Typography>
                                <Typography
                                    id={`${parent_id}-choose-another-typography`}
                                    variant="body2" className={classes.btnText}>
                                    {/* Want to choose another slot. */}
                                </Typography>
                            </div>
                        </div>
                    }
                    <div>
                        <WeekCalendar
                            id={`${parent_id}_dateVal_weekcalendar`}
                            activeColor={"#0071F2"}
                            key={"0"}
                            //   weekStartDate={moment(new Date(date * 1000)).format(
                            //     "YYYY-MM-DD"
                            //   )}
                            style={{ borderWidth: "0px", fontSize: 14 }}
                            className={classes.weekcalendar}
                            onChange={(dateVal) =>
                                props?.handleCalenderChange && props?.handleCalenderChange(dateVal)
                            }
                            currentDate={moment(props?.scheduleAt).format('MM-DD-YYYY')}
                        />
                        <Tab
                            id={`${parent_id}_parent_tab`}
                            align="center"
                            fullWidth={true}
                            size="small"
                            backgroundColor="#3f51b5"
                            elevation="0"
                            color=""
                            border={false}
                            activetabs={0}
                            variant="withoutBordered"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            className={"pc_regular"}
                        >
                            <Tabpanel
                                id={`${parent_id}_slotsData_morning_tabpanel`}
                                key={"0"}
                                label="MORNING"
                                title="MORNING"
                                subTitle={`${props?.slotsData?.morning?.length || "No"} slots`}
                                color={props?.slotsData?.morning?.length ? "#38C20A" : "#FF4F4A"}
                                style={{
                                    maxHeight: "26vh",
                                    overflow: "auto",
                                    marginBottom: "5%",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                                className={"pc_regular"}
                            >
                                <div
                                    id={`${parent_id}-CustomTab-div`}
                                    style={{ marginTop: 10 }}>
                                    <CustomTab
                                        parent_id={'CustomTab'}
                                        data={props?.slotsData?.morning}
                                        getSelected={props?.getSelected}
                                        selectedSlot={props?.selectedSlot}
                                    />

                                </div>
                            </Tabpanel>

                            <Tabpanel
                                id={`${parent_id}_label_afternoon_tabpanel`}
                                key={"1"}
                                label="AFTERNOON"
                                title="AFTERNOON"
                                subTitle={`${props?.slotsData?.afternoone?.length || "No"} slots`}
                                color={props?.slotsData?.afternoone?.length ? "#38C20A" : "#FF4F4A"}
                                style={{
                                    maxHeight: "26vh",
                                    overflow: "auto",
                                    marginBottom: "5%",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                                className={"pc_regular"}
                            >
                                <div
                                    id={`${parent_id}_label_CustomTab_div`}
                                    style={{ marginTop: 10 }}>
                                    <CustomTab
                                        parent_id={'CustomTab'}
                                        data={props?.slotsData?.afternoone}
                                        getSelected={props?.getSelected}
                                        selectedSlot={props?.selectedSlot}
                                    />
                                </div>
                            </Tabpanel>

                            <Tabpanel
                                id={`${parent_id}_label_evening_tabpanel`}
                                key={"2"}
                                label="EVENING"
                                title="EVENING"
                                subTitle={`${props?.slotsData?.evening?.length || "No"} slots`}
                                color={props?.slotsData?.evening?.length ? "#38C20A" : "#FF4F4A"}
                                style={{
                                    maxHeight: "26vh",
                                    overflow: "auto",
                                    marginBottom: "5%",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                                className={"pc_regular"}
                            >
                                <div
                                    id={`${parent_id}_label_CustomTab_div`}
                                    style={{ marginTop: 10 }}>
                                    <CustomTab
                                        parent_id={'CustomTab'}
                                        data={props?.slotsData?.evening}
                                        getSelected={props?.getSelected}
                                        selectedSlot={props?.selectedSlot}
                                    />
                                </div>
                            </Tabpanel>

                            <Tabpanel
                                id={`${parent_id}_label_night_tabpanel`}
                                key={"3"}
                                label="NIGHT"
                                title="NIGHT"
                                subTitle={`${props?.slotsData?.night?.length || "No"} slots`}
                                color={props?.slotsData?.night?.length ? "#38C20A" : "#FF4F4A"}
                                style={{
                                    maxHeight: "26vh",
                                    overflow: "auto",
                                    marginBottom: "5%",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                                className={"pc_regular"}
                            >
                                <div
                                    id={`${parent_id}_label_CustomTab_div`}
                                    style={{ marginTop: 10 }}>
                                    <CustomTab
                                        parent_id={'CustomTab'}
                                        data={props?.slotsData?.night}
                                        getSelected={props?.getSelected}
                                        selectedSlot={props?.selectedSlot}
                                    />
                                </div>
                            </Tabpanel>
                        </Tab>
                        <span
                            id={`${parent_id}_icons_span`}
                            className={classes.icons}>
                            <span
                                id={`${parent_id}_suggested_span`}
                                className={'rowCenter'}>
                                <Suggested color={"#0071F2"} />
                                <Text
                                    id={`${parent_id}_suggested_text`}
                                    className={classes.iconText}>Suggested Slot</Text>
                            </span>
                            <span
                                id={`${parent_id}_videoconsultation_span`}
                                className={'rowCenter'}>
                                <Videoconsultation color={"#B6B6B6"} />
                                <Text
                                    id={`${parent_id}_videoconsultation_label_text`}
                                    className={classes.iconText}>
                                    Video Consultation
                                </Text>
                            </span>
                            <span
                                id={`${parent_id}_direct_consultaton_span`}
                                className={'rowCenter'}>
                                <Direct color={"#B6B6B6"} />
                                <Text
                                    id={`${parent_id}_direct_consultaton_label_text`}
                                    className={classes.iconText}>
                                    Direct Consultation
                                </Text>
                            </span>
                        </span>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}