import React from "react";
import { withHoverDrawer } from "../../HOCs";
import TabPanel from "../../components/tab";
import AvailabilityTabPanel from "./availabilitytab";
class AvailabilityManagement extends React.Component {
  render() {
    return (
      <div id={"AvailabilityManagement-parent-div"}>
        {/* <TabPanel /> */}
        <AvailabilityTabPanel id={"AvailabilityManagement"} />
      </div>
    );
  }
}

export default withHoverDrawer(AvailabilityManagement);
