import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { checkWithCasbin } from "../../utils"
import PractitionerTable from "./practitionertable";
// import PractitionerTable from "../../components/practitionermaster/practitionertable";
import PractitionerRoleTable from "./practitionerroletable";

import { CommonTabList } from "../../components/common";
import "../../components/font.css";
import CustomGeneralMaster from "../administration/customGeneralMaster/customGeneralMaster"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,

    "& .MuiTab-textColorPrimary": {
      "&.Mui-selected": {
        color: "#0071F2",
      },
    },
    "&.MuiPaper-elevation4": {
      shadow: "none",
    },
    "& .MuiTab-root": {
      [theme.breakpoints.up("xs")]: {
        minWidth: "inherit !important",
      },
    },
    "& .MuiAppBar-root": {
      width: "80%",
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      "@media (min-width: 0px)": {
        minWidth: 0,
      },
    },
  },
  box: {
    padding: "24px",
    paddingBottom: "43px",
    backgroundColor: "#F6F6F6",
    // height: "100vh",
    height: "100%",
    paddingTop: "14px",
  },
}));
function PractitionerTabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      id={'practitionertab-div'}
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          id={'practitionertab-box'}
          p={3} className={classes.box}>
          <Grid
            id={'practitionertab-grid'}
          >{children}</Grid>
        </Box>
      )}
    </div>
  );
}

PractitionerTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [listData, setListData] = React.useState([])

  const json = {
    practitioner: {
      name: "Practitioner",
      component: <PractitionerTable
        parent_id={'Practitioner'}
      />
    },
    practitionerRole: {
      name: "Practitioner Role",
      component: <PractitionerRoleTable
        parent_id={'PractitionerRole'}
        type={"PRACTROLE"} header={"Practitioner Role"} codeLength={20} descriptionLength={60} />
    },
    practitionerType: {
      name: "Practitioner Type",
      component: <PractitionerRoleTable
        parent_id={'PractitionerType'}
        type={"PRACTTYPE"} header={"Practitioner Type"} codeLength={20} descriptionLength={60} />
    },
    qualification: {
      name: "Qualification",
      component: <PractitionerRoleTable
        parent_id={'PractitionerType'}
        type={"QUALIFICATION"} header={"Qualification"} codeLength={20} descriptionLength={100} />
    },
    position: {
      name: "Position & Grades (GM)",
      component: <CustomGeneralMaster 
          disableList={["Code"]}
          uppercaseList={["Code"]}
          type={"POSITION"}
          type_={"Position & Grades (GM)"}
      />
    }
  }
  React.useEffect(() => {
    const baseFun = async () => {
      let permission = await checkWithCasbin(["practitionerMaster"]);
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      let arr = [];
      var unique = [...permission.read, ...permission.write].filter(onlyUnique);
      unique.map(val => {
        arr.push(json[val])
      })
      setListData(arr)
    }
    baseFun()
  }, [])
  return (
    <Grid
      id={'practitionertab-parent-grid'}
      container spacing={0}>
      <Grid
        id={'practitionertab-parent-sub-grid'}
        item md={12}>
        <div
          id={'practitionertab-parent-div'}
          className={classes.root}>
          <CommonTabList
            parent_id={'Practitioner-Master'}
            title="Practitioner Master"
            list={listData}
          />
        </div>
      </Grid>
    </Grid>
  );
}
