import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
// Styles
import qdmstyles from "./styles.json";

function Availablestrengthtableheader(props) {
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {}, [state]);

  const { data, index } = props;

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"UYSha"}
        container={true}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        lg={"12"}
        md={"12"}
        sm={"12"}
        xl={"12"}
        xs={"12"}
        style={qdmstyles.UYSha}
      >
        <Grid
          key={"0"}
          id={"8k4Lv"}
          container={true}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"3"}
          md={"3"}
          sm={"3"}
          xl={"3"}
          xs={"3"}
        >
          <Typography
            key={"0"}
            id={"c3m1q"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={"Strength Value"}
            style={qdmstyles.cmq}
          ></Typography>
        </Grid>
        <Grid
          key={"1"}
          id={"XTsif"}
          container={true}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"7"}
          md={"7"}
          sm={"7"}
          xl={"7"}
          xs={"7"}
        >
          <Typography
            key={"0"}
            id={"6PsUC"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={"Allowed Values for Order"}
            style={qdmstyles.PsUC}
          ></Typography>
        </Grid>
        <Grid
          key={"2"}
          id={"S0IIq"}
          container={true}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          lg={"2"}
          md={"2"}
          sm={"2"}
          xl={"2"}
          xs={"2"}
        >
          <Typography
            key={"0"}
            id={"Qzi02"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={"Action"}
            style={qdmstyles.Qzi}
          ></Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(Availablestrengthtableheader);
