import React from "react";
import { makeStyles } from "@material-ui/core";
import { CommonTabList } from "../../components";
import { Frequency } from "./components/frequency";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));

const FrequencyMaster = (props) => {
  const classes = useStyles();
  const [listData, setListData] = React.useState([
    {
      name: "Frequency Master",
      component: <Frequency />
    }
  ])

  React.useEffect(() => {}, [])
  // React.useEffect(() => {
  //   const baseFun = async () => {
  //     let permission = await checkWithCasbin(["consultMaster"]);
  //     function onlyUnique(value, index, self) {
  //       return self.indexOf(value) === index;
  //     }
  //     let arr = [];
  //     var unique = [...permission.read, ...permission.write].filter(onlyUnique);
  //     unique.map(val => {
  //       arr.push(json[val])
  //     })
  //     setListData(arr)
  //   }
  //   baseFun()
  // }, [])

  return (
    <div
      id={"consult-master-parent-div"}
      className={classes.root}>
      <CommonTabList
        parent_id={"frequency-master"}
        title="Frequency Masters"
        list={listData}
      />
    </div>
  );
};

export default FrequencyMaster;