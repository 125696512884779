import React from "react";
import { Row, Col, Select, TextInput } from "qdm-component-library";
import {
	makeStyles,
	Grid,
	TextField,
	FormLabel,
	Button,
	IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { DateTimePicker } from "../../../components/common/smartForm/component";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";

import { Typography } from "@material-ui/core";
import moment from "moment";
import CustomInputDatePicker from "../../../components/common/datePicker/inputDatePicker";

const useStyles = makeStyles(() => ({
	main: {
		backgroundColor: "white",
		marginTop: "2vw",
		marginLeft: "2vw",
		width: "90vw",
		borderRadius: 8,
		padding: 14,
	},
	paddingLeft0: {
		paddingLeft: 0,
	},
	req_red: {
		color: "red",
	},
	datePicker: {
		"& .MuiTypography-root": {
			fontFamily: "pc_regular",
		},
		"& .MuiFormControl-root": {
			marginTop: 5,
			"& input": {
				fontSize: 12,
				fontFamily: "pc_medium",
				color: "#101010",
				padding: "12px !important",
			},
			"& fieldset": {
				borderColor: "#E0E0E0",
			},
		},
	},
	labelStyleNew: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
		marginBottom: 10
	},
}));
const styles = {
	labelStyle: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
	},
	borderStyle: {
		borderRadius: 8,
		borderColor: "#E0E0E0",
	},
	errorBorderStyle: {
		borderRadius: 8,
		border: "1px solid red",
	},
};
const Identification = (props) => {
	const classes = useStyles();
	const details = {};
	const { parent_id } = props;
	const { labelStyle, borderStyle, errorBorderStyle } = styles;

	const dispatch = useDispatch();
	const organizationSlice = useSelector((state) => state.organizationSlice);

	React.useEffect(() => {
		const getdata = async () => {
			await dispatch(actions.ORGANIZATION_GET_USE_IDENTIFICATION());
			await dispatch(actions.ORGANIZATION_GET_IDTYPE_IDENTIF());
		};
		getdata();
	}, []);
	const handleClick = () => {

		props.Identificationdata.push({
			startdate: "",
			enddate: "",
			use: {},
			idtype: {},
			idnumber: "",
		});
		props.onChangeAll();
	};
	return (
		<Grid
			id={`${parent_id}-parent-grid`}
			className={classes.main} container direction="row">
			<Grid
				id={`${parent_id}-parent-sub-grid`}
				container item xs={12}>
				<Grid
					id={`${parent_id}-title-grid`}
					item xs={8} style={{ textAlign: "left" }}>
					<p
						id={`${parent_id}-title-p`}
						style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
						IDENTIFICATION{" "}
					</p>
				</Grid>
				<Grid
					id={`${parent_id}-Add-New-grid`}
					item xs={4}>
					<Grid
						id={`${parent_id}-Add-New-button-grid`}
						container
						direction="row"
						justify="flex-end"
						alignItems="center"
					>
						<Button
							id={`${parent_id}-Add-New-button`}
							className={classes.newRes}
							color="primary"
							onClick={handleClick}
						>
							+ Add New
						</Button>
					</Grid>
				</Grid>
				{props?.Identificationdata?.length > 0
					? props?.Identificationdata?.map((val, i) => {
						return (
							<Grid
								id={`${parent_id}-parent-grid` + i}
								container direction="column" key={i}>
								<Row
									id={`${parent_id}-parent-row` + i}
									inLineStyles={{ paddingBottom: "2%" }}>
									<Col
										id={`${parent_id}-parent-col` + i}
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										<FormLabel
											id={`${parent_id}-Use-title-FormLabel`}
											component="legend"
											classes={{ root: classes.labelStyleNew }}
										>
											Use
											{/* <span
												id={`${parent_id}-Use-star-span`}
												className={classes.req_red}>*</span> */}
										</FormLabel>
										<Autocomplete
											id={`${parent_id}-Use-Autocomplete`}
											getOptionLabel={(option) => option.title}
											style={{ width: "auto", marginTop: "1%" }}
											size={"small"}
											renderInput={(params) => (
												<TextField
													id={`${parent_id}-Use-textField`}
													{...params}
													variant="outlined"
													placeholder={"Use"}
												/>
											)}
											options={
												organizationSlice?.orgUseIdenifi?.data
													? organizationSlice?.orgUseIdenifi?.data
													: []
											}
											onChange={(e, val) =>
												props.onChangeAll(e, val, "Identification", i, "use")
											}
											value={val?.use}
										/>
									</Col>
									<Col
										id={`${parent_id}-ID-Type-col`}
										className="no-padding-left"
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										<FormLabel
											id={`${parent_id}-ID-Type-title-FormLabel`}
											component="legend"
											classes={{ root: classes.labelStyleNew }}
										>
											ID Type
											{/* <span
												id={`${parent_id}-ID-Type-star-span`}
												className={classes.req_red}>*</span> */}
										</FormLabel>
										<Autocomplete
											id={`${parent_id}-ID-Type-Autocomplete`}
											getOptionLabel={(option) => option.title}
											style={{ width: "auto", marginTop: "1%" }}
											size={"small"}
											renderInput={(params) => (
												<TextField
													id={`${parent_id}-ID-Type-textField`}
													{...params}
													variant="outlined"
													placeholder={"ID Type"}
												/>
											)}
											options={
												organizationSlice?.orgIdTypeIdenifi?.data
													? organizationSlice?.orgIdTypeIdenifi?.data
													: []
											}
											onChange={(e, val) =>
												props.onChangeAll(
													e,
													val,
													"Identification",
													i,
													"idtype"
												)
											}
											value={val?.idtype}
										/>
									</Col>
									<Col
										id={`${parent_id}-ID-Number-col`}
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										<TextInput
											id={`${parent_id}-ID-Number-textInput`}
											label="ID Number"
											// isRequired={true}
											placeholder="ID Number"
											labelStyle={labelStyle}
											hoverColor="#0071F2"
											onChange={(e) =>
												props.onChangeAll(
													e,
													e.target.value,
													"Identification",
													i,
													"idnumber"
												)
											}
											value={val?.idnumber}
										/>
									</Col>
									<Col
										id={`${parent_id}-Start-Date-Col`}
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										{/* <DateTimePicker
											id={`${parent_id}-Start-Date-DateTimePicker`}
											// required={true}
											label={"Start Date"}
											value={val?.startdate ?? null}
											onChange={(data) =>
												props.onChangeAll(
													data,
													data,
													"Identification",
													i,
													"startdate"
												)
											}
											className={classes.datePicker}
											dateFormat={"dd/MM/yyyy"}
										// minDate={props?.data?.effectiveFrom ? moment(props?.data?.effectiveFrom).diff(moment(new Date()), 'days') >= 0 ? new Date() : moment(props?.data?.effectiveFrom) : new Date()}
										// error={props?.data?.error?.effectiveFrom??false}
										/> */}
										<Typography
											style={{
												fontFamily: "poppin",
												color: "#6F6F6F",
												fontSize: "12px",
												paddingBottom: "8px",
											}}
										>
											{"Start Date"}
											{/* <span style={{ color: "red" }}>*</span> */}
										</Typography>

										<CustomInputDatePicker
											// disabled={data?.disabled}
											// placeholderText="dd / mm / yyyy"
											// minDate={state?.states?.[data?.minDateState] ?? null}
											selectedDate={
												val?.startdate ? new Date(val?.startdate) : null
											}
											handleChange={(value) =>
												props.onChangeAll(
													value,
													value,
													"Identification",
													i,
													"startdate"
												)
											}
											inputField={
												<TextField
													id={`${parent_id}-Start-Date-DateTimePicker`}
													//  label = {data.label}
													fullWidth
													variant="outlined"
													// required={data?.required}
													// disabled={data?.disabled}
													// type={data?.type ?? "text"}
													value={
														val?.startdate
															? moment(new Date(val?.startdate)).format(
																"DD-MM-YYYY"
															)
															: null
													}
													inputProps={{
														style: {
															padding: "10.5px 14px",
															fontFamily: "poppin",
															fontSize: "12px",
															// backgroundColor: data?.disabled
															//   ? "#E0E0E0"
															//   : "#fff",
														},
													}}
												/>
											}
										/>
									</Col>
									<Col
										id={`${parent_id}-End-Date-col`}
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										{/* {val?.startdate ?? null} */}
										{/* <DateTimePicker
											id={`${parent_id}-End-Date-DateTimePicker`}
											// required={true}
											label={"End Date"}
											value={val?.enddate ?? null}
											onChange={(data) =>
												props.onChangeAll(
													data,
													data,
													"Identification",
													i,
													"enddate"
												)
											}
											className={classes.datePicker}
											dateFormat={"dd/MM/yyyy"}
											minDate={val?.startdate}
										// error={props?.data?.error?.effectiveFrom??false}
										/> */}
										<Typography
											style={{
												fontFamily: "poppin",
												color: "#6F6F6F",
												fontSize: "12px",
												paddingBottom: "8px",
											}}
										>
											{"End Date"}
											{/* <span style={{ color: "red" }}>*</span> */}
										</Typography>

										<CustomInputDatePicker
											// disabled={data?.disabled}
											placeholderText="dd / mm / yyyy"
											minDate={val?.startdate ? new Date(val?.startdate) : null}
											selectedDate={
												val?.enddate
													? new Date(val?.enddate)
													: null
											}
											handleChange={(value) =>
												props.onChangeAll(
													value,
													value,
													"Identification",
													i,
													"enddate"
												)
											}
											inputField={
												<TextField
													id={`${parent_id}-End-Date-DateTimePicker`}
													//  label = {data.label}
													fullWidth
													variant="outlined"
													// required={data?.required}
													// disabled={data?.disabled}
													// type={data?.type ?? "text"}
													value={
														val?.enddate
															? moment(
																new Date(val?.enddate)
															).format("DD-MM-YYYY")
															: null
													}
													inputProps={{
														style: {
															padding: "10.5px 14px",
															fontFamily: "poppin",
															fontSize: "12px",
															// backgroundColor: data?.disabled
															//   ? "#E0E0E0"
															//   : "#fff",
														},
													}}
												/>
											}
										/>
									</Col>
									<Col
										id={`${parent_id}-DeleteIcon-col`}
										md={2}
										lg={2}
										sm={2}
										xs={12}
										style={{
											display: "flex",
											alignItems: "center",
											marginTop: "2%",
										}}
									>
										<IconButton
											id={`${parent_id}-DeleteIcon-IconButton`}
											aria-label="DeleteIcon"
											className={classes.margin}
											size="small"
											onClick={() => props.deleteFun("Identification", i)}
										>
											<DeleteIcon
												id={`${parent_id}-DeleteIcon-IconButton`}
												style={{ color: "#FF4D4A", fontSize: 18 }}
											/>
										</IconButton>
									</Col>
								</Row>
							</Grid>
						);
					})
					: null}
			</Grid>
		</Grid>
	);
};
export default Identification;
