import React from "react";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputBase } from "./inputfield";

export function Autocomplete(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <MuiAutocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => {
        return (
          <InputBase
            {...(props?.multiple && params.InputProps)}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            placeholder={props.placeholder}
            required={props.required}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={handleOpen}
                  style={{ ...(open && { transform: "rotate(180deg)" }) }}
                >
                  <ExpandMoreIcon
                    htmlColor="#6A7888"
                    style={{ fontSize: "1.425rem" }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
        );
      }}
      {...props}
    />
  );
}

export default Autocomplete;
