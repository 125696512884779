import React from "react";
import { Paper, Typography, makeStyles, Grid, TextField, Button } from "@material-ui/core";
import { CommonTable, GenerateForm } from "../../../../../components";
import { useSelector } from "react-redux";
import { withAllContexts } from "../../../../../HOCs";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10
    },
    addBtn: {
        float: "right",
        fontSize: "14px",
        fontWeight: 600
    },
    title: {
        fontFamily: "poppinsemibold",
        display: "inline",
        fontSize: "12px",
    },
    form: {
        paddingTop: 15
    },
}));

function CategoryDetails(props) {
    const [state, setState] = React.useState({
        open: false, saveloading: false, edit_data: false, editIndex: null
    })
    const unitUomList = useSelector((state) => state?.ageRangeMasterSlice?.unit_uom_list?.data);
    const classes = useStyles();
    const { categoryDetail, handleFormState, parent_id } = props;

    const handleDrawerClose = () => {
        setState({ ...state, open: false, editIndex: null })
    }
    const saveAgeRange = (data) => {
        debugger
        const isDuplicate = categoryDetail?.find((val) => val.code === data.code)
        if (isDuplicate) {
          props.alert.setSnack(
            "mandatory",
            "'Age Category Code already exists'"
          );
        } else {
          if (parseInt(data?.age_maximum) > parseInt(data?.age_minimum)) {
            let list = JSON.parse(JSON.stringify(categoryDetail));
            if (state.editIndex || state.editIndex === 0) {
              list[state.editIndex] = {
                ...list[state.editIndex],
                ...data,
              };
            } else {
              list.push(data);
            }
            handleFormState("categoryDetail", list);
            handleDrawerClose();
          } else {
            props.alert.setSnack(
              "mandatory",
              "'Age maximum' should be greater than 'Age minimum'"
            );
          }
        }
    }
    const handleDelete = (e, data, index) => {
        let list = JSON.parse(JSON.stringify(categoryDetail));
        list.splice(index, 1)
        handleFormState("categoryDetail", list);
    }
    const condition = (value, type) => {
        if (type === "DELETE") {
            if (value._id) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }
    return (
        <React.Fragment>
            <Paper
                id={`${parent_id}_parent_paper`}
                className={classes.root}>
                <Typography
                    id={`${parent_id}_AGE_CATEGORY_typography`}
                    className={classes.title} variant="h6">AGE CATEGORY DETAILS</Typography>
                <Button
                    id={`${parent_id}_Add_New_button`}
                    className={classes.addBtn} onClick={() => setState({ ...state, open: true })} color="primary" >+ Add New</Button>
                <Grid
                    id={`${parent_id}_table_grid`}
                    container className={classes.form}>
                    <CommonTable
                        parent_id={"category_details"}
                        handleEdit={(e, data, index) => setState({ ...state, editIndex: index, open: true })}
                        handleDelete={handleDelete}
                        rightAlign={["Action"]}
                        Header={["S.No", "Age Category Code", "Description", "Age Minimum", "Age Maximum", "Unit", "Action"]}
                        dataList={categoryDetail}
                        tableData={[
                            { type: ["INCRIMENT"], name: "" },
                            { type: ["TEXT"], name: "code" },
                            { type: ["TEXT"], name: "description" },
                            { type: ["TEXT"], name: "age_minimum" },
                            { type: ["TEXT"], name: "age_maximum" },
                            { type: ["TEXT"], name: "UOM", optionLabel: "label" },
                            { type: ["EDIT", "DELETE"], name: "", align: "right" },
                        ]}
                        condition={condition}
                    />
                </Grid>
                <GenerateForm
                    parent_id={"category_details"}
                    nocancel
                    loading={state.saveloading}
                    open={state.open}
                    save={saveAgeRange}
                    handlecancel={handleDrawerClose}
                    header={state.editIndex !== null ? "Edit Age Category" : 'Add Age Category'} 
                    json={[{
                        componet_type: "text",
                        label: "Age Category Code",
                        state_name: "code",
                        value: categoryDetail?.[state.editIndex]?.code ?? "",
                        required: true,
                        isUpperCase: true,
                        textLength: 20
                    },
                    {
                        componet_type: "text",
                        label: "Description",
                        state_name: "description",
                        value: categoryDetail?.[state.editIndex]?.description ?? "",
                        required: true
                    },
                    {
                        componet_type: "text",
                        label: "Age Minimum",
                        type: "number",
                        state_name: "age_minimum",
                        value: categoryDetail?.[state.editIndex]?.age_minimum ?? "",
                        required: true,
                        disabled: state.editIndex !== null ? true : false
                    },
                    {
                        componet_type: "text",
                        label: "Age Maximum",
                        type: "number",
                        state_name: "age_maximum",
                        value: categoryDetail?.[state.editIndex]?.age_maximum ?? "",
                        required: true,
                        disabled: state.editIndex !== null ? true : false
                    },
                    {
                        componet_type: "select",
                        label: "Unit",
                        options: unitUomList,
                        state_name: "UOM",
                        value: categoryDetail?.[state.editIndex]?.UOM ?? "",
                        required: true
                    }
                    ]}
                />
            </Paper>
        </React.Fragment>
    )
}

export default withAllContexts(CategoryDetails);