import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";

import PropTypes from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "poppin",
    color: "#6F6F6F",
    fontSize: "15px",
    paddingBottom: 10,
  },
  disable: {
    background: "#c8d1dc1f",
    marginTop: "5px",
    paddingTop: "0px !important",
  },
  root: {
    "& .MuiTextField-root": {
      display: "block",
      paddingTop: "6px",
    },
    "& .MuiInputBase-input": {
      padding: "9.5px 14px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 4,
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.25rem",
    },
    "& .MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputAdornment-root": {
      position: "absolute",
      right: 0,
    },
    "&.MuiFormControl-root &.MuiTextField-root": {
      paddingTop: 0.6,
    },
  },
  "&.MuiPickersBasePicker-pickerView": {
    minWidth: "223px !important",
    maxWidth: "293px !important",
  },
  "&.MuiPopover-paper": {
    top: "424px !important",
    left: "135px !important",
  },
}));

export const DateTimePicker = (props) => {
  const classes = useStyles(props);
  const { parent_id } = props;
  const handleChange = (date) => {
    props?.onChange && props?.onChange(date);
  };
  let minDateVar = {};
  console.log(props.views, "lksliieee");
  if (props.minDate) {
    minDateVar = { minDate: props.minDate };
  }
  let maxDateVar = {};
  if (props.maxDate) {
    maxDateVar = { maxDate: props.maxDate };
  }
  return (
    <div
      id={`${parent_id}-parent-div`}
      className={`${classes.root} ${props?.className}`}
    >
      {props?.label && (
        <Typography
          id={`${parent_id}-${props?.label.replaceAll(" ", "-")}-typography`}
          variant="caption"
          className={classes.title}
        >
          {props?.label}
          {props?.required && (
            <span
              style={{
                color: "red",
                marginLeft: 5,
              }}
            >
              *
            </span>
          )}
        </Typography>
      )}
      <MuiPickersUtilsProvider
        id={`${parent_id}-MuiPickersUtilsProvider`}
        utils={DateFnsUtils}
      >
        {props.views &&
        props.views.length > 0 &&
        ["year", "date", "month"].includes(props.views) ? (
          <DatePicker
            id={`${parent_id}-DatePicker`}
            placeholder={props.placeholder}
            inputVariant="outlined"
            disabled={props.disabled ? true : false}
            disableToolbar={props.disableToolbar}
            views={[props.views]}
            autoOk
            disablePast={true}
            // label="Year only"
            value={props?.value ? props?.value : null}
            variant="inline"
            style={{ width: "100%" }}
            format={props?.dateFormat}
            onChange={(date) => handleChange(date)}
            className={`${props.disabled && classes.disable} ${
              classes.datePic
            }`}
            error={props?.error}
            {...minDateVar}
            {...maxDateVar}
          />
        ) : (
          <KeyboardDatePicker
            id={`${parent_id}-KeyboardDatePicker`}
            placeholder={props.placeholder}
            disableToolbar={props.disableToolbar}
            variant="inline"
            style={{ width: "100%" }}
            autoOk
            format={props?.dateFormat}
            disabled={props.disabled ? true : false}
            // margin="normal"
            // id="date-picker-inline"
            // label="Date picker inline"
            value={props?.value ? props?.value : null}
            onChange={(date) => handleChange(date)}
            inputVariant="outlined"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            PopoverProps={{
              anchorOrigin: { horizontal: "center", vertical: "bottom" },
              // transformOrigin: { horizontal: "center", vertical: "center" }
            }}
            error={props?.error}
            {...minDateVar}
            {...maxDateVar}
          />
        )}
      </MuiPickersUtilsProvider>
    </div>
  );
};

DateTimePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  minDate: PropTypes.string,
  views: PropTypes.string,
  disableToolbar: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

DateTimePicker.defaultProps = {
  dateFormat: "MM/dd/yyyy",
  error: false,
  required: false,
  minDate: null,
  maxDate: null,
  views: null,
  disableToolbar: true,
  className: "",
  placeholder: "",
};
