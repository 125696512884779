import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    fontSize: 13,
    fontFamily: "pc_medium",
    textTransform: "unset",
    borderRadius: "8px !important",
    ...(props.color && {
      color: props.color,
    }),
    ...(props.backgroundColor && {
      backgroundColor: `${props.backgroundColor} !important`,
      "&:hover": {
        backgroundColor: `${props.backgroundColor} !important`,
      },
    }),
  }),
}));

export const CustomButton = (props) => {
  const buttonStyles = useStyles(props);
  return <Button classes={buttonStyles} disableElevation {...props} />;
};
