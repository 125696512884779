import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  Button,
  TextField,
  Drawer,
  Avatar,
} from "@material-ui/core";
import { CommonTable } from "../../../../../../components";
import styles from "../../../../../../components/common/smartForm/styles";
import { generateComponent } from "../../../../../../components/common/smartForm/formsList";
import { withAllContexts } from "../../../../../../HOCs";
import { validationForm } from "../../../../../../utils";
import { useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "40px 30px",
    borderRadius: "0px 0px 8px 8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .MuiInputBase-input": {
      "& .Mui-disabled": {
        background: "#0000",
      },
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    border: "1px solid #E0E0E0",
    borderRadius: 8,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    fontFamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  labelGrid: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
}));

function AttributesComp(props) {
  const classes1 = useStyles();
  const classes = styles();
  const storeData = useSelector((state) => state?.orderCatalogSlice);

  const { attribute, AttributeList, handleFormState, parent_id } = props;
  const [state, setState] = React.useState({
    open: false,
    saveloading: false,
    edit_data: false,
    list: [
      {
        componet_type: "select",
        options: storeData?.order_catalog_masters?.data?.attrcode ?? [],
        label: "Attribute Code",
        state_name: "Attcode",
        value: "",
        required: true,
      },
      {
        componet_type: "text",
        label: "Attribute Label",
        state_name: "label",
        value: "",
        required: false,
        disabled: true,
      },
      {
        componet_type: "text",
        label: "Attribute Type",
        state_name: "Atype",
        value: "",
        required: false,
        disabled: true,
      },
      {
        componet_type: "select",
        options: [],
        label: "Attribute Default Value",
        state_name: "defaultValue",
        value: {},
        required: false,
      },
    ],
    states: {
      Attcode: "",
      label: "",
      Atype: "",
      defaultValue: "",
      _id: "",
    },
  });
  let dispatch = useDispatch();
  const handleChange = (type, val) => {
    if (type === "Attcode") {
      if (val?.type === "Text" || val?.type === "Number") {
        state.list[3] = {
          componet_type: "text",
          label: "Attribute Default Value",
          state_name: "defaultValue",
          value: "",
          type: val.type === "Number" ? "number" : "text",
          required: false,
        };
      } else {
        state.list[3] = {
          componet_type: "select",
          options: val ? val.AttribValidValues : [],
          label: "Attribute Default Value",
          state_name: "defaultValue",
          value: {},
          required: false,
        };
      }
      let defaltVal =
        val?.AttribValidValues?.length > 0
          ? val.AttribValidValues.find((v) => {
            return v.Dfltflag !== "";
          })
          : "";
      setState({
        ...state,
        states: {
          ...state.states,
          [type]: val,
          label: val?.name ?? "",
          Atype: val?.type?.display ?? "",
          defaultValue: defaltVal,
        },
      });
    } else {
      setState({
        ...state,
        states: {
          ...state.states,
          [type]: val,
        },
      });
    }
    
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      editIndex: null,
      states: {
        Attcode: "",
        label: "",
        Atype: "",
        defaultValue: "",
      },
    });
  };
  const saveAgeRange = () => {

     let { error, errorState } =validationForm(state.list, state.states);
    if (!error) {
      let data = state.states;

      let obj = {};
      obj["Attcode"] = data.Attcode;
      obj["label"] = data.label;
      obj["type"] = data.Atype;
      obj["defaultValue"] =
        data.Atype === "Number" || data.Atype === "Text"
          ? { label: data.defaultValue }
          : data.defaultValue;
      obj["_id"] = data._id;

      let list = JSON.parse(JSON.stringify(AttributeList));

      if (state.editIndex || state.editIndex === 0) {
        list[state.editIndex] = {
          ...list[state.editIndex],
          ...obj,
        };
      } else {
        list.push(obj);
      }
      handleFormState("AttributeList", list);

      handleDrawerClose();
    } else {
      props.alert.setSnack("mandatory");
       setState({
         ...state,
         error: errorState,
       });
    }
  };

  const handleEdit = (e, data, index) => {
    if (data.type === "Number" || data.type === "Text") {
      state.list[3] = {
        componet_type: "text",
        label: "Attribute Default Value",
        state_name: "defaultValue",
        value: "",
        type: data.type === "Number" ? "number" : "text",
      };
    } else {
      state.list[3] = {
        componet_type: "select",
        options: data.Attcode.AttribValidValues,
        label: "Attribute Default Value",
        state_name: "defaultValue",
        value: {},
        required: true,
      };
    }
    setState({
      ...state,
      editIndex: index,
      open: true,
      states: {
        Attcode: data?.Attcode,
        label: data?.label,
        Atype: data?.type,
        defaultValue:
          data.type === "Number" || data.type === "Text"
            ? data?.defaultValue?.label
            : data?.defaultValue,
        _id: data?._id,
      },
    });
  };

  const handleDelete = async (e, data, i) => {
    
    let AttriData = JSON.parse(JSON.stringify(AttributeList));
    AttriData.splice(i, 1);
    await dispatch(
      actions.DELETE_ATTRIBUTE_DELETE({
        id: data?._id,
      })
    );

    handleFormState("AttributeList", AttriData);
  };
  const condition = (value, type) => {
    if (type === "DELETE") {
      if (value._id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes1.root} elevation={0}>
        <Grid
          id={`${parent_id}-parent-container`}
          container className={classes1.form} spacing={3}>
          <Grid
            id={`${parent_id}-parent-grid`}
            className={classes1.labelGrid} item xs={3}>
            <Grid
              id={`${parent_id}-Label-div`}
            >
              <Typography
                id={`${parent_id}-Label-typography`}
                className={classes1.formtitle} variant="h6">
                Label Name <span style={{color:"red"}}>*</span>
              </Typography>

              <TextField
                id={`${parent_id}-attribute-textField`}
                value={attribute}
                onChange={(e) => handleFormState("attribute", e.target.value)}
                fullWidth
                type="text"
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
          <Grid
            id={`${parent_id}-List-attribute-textField`}
            item xs={9}>
            <Typography
              id={`${parent_id}-List-attribute-typography`}
              className={classes1.title} variant="h6">
              LIST OF ATTRIBUTES
            </Typography>
            <Button
              id={`${parent_id}-Add-New-button`}
              className={classes1.addBtn}
              onClick={() => setState({ ...state, open: true })}
              color="primary"
            >
              + Add New
            </Button>
            <CommonTable
              parent_id={"attributes"}
              Header={[
                "Sequence No",
                "Attribute Code",
                "Attribute Label",
                "Attribute Type",
                "Attribute Default Value",
                "Action",
              ]}
              handleDelete={handleDelete}
              handleEdit={(e, data, index) => handleEdit(e, data, index)}
              dataList={AttributeList}
              tableData={[
                { type: ["INCRIMENT"], name: "" },
                { type: ["TEXT"], name: "Attcode", optionLabel: "label" },
                { type: ["TEXT"], name: "label" },
                { type: ["TEXT"], name: "type" },
                { type: ["TEXT"], name: "defaultValue", optionLabel: "label" },
                { type: ["EDIT", "DELETE"], name: "", align: "left" },
              ]}
              condition={condition}
            />
          </Grid>

          <Drawer
            id={`${parent_id}-Add-New-drawer`}
            className={classes.drawer}
            // variant="persistent"
            anchor="right"
            open={state.open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {
              <div
                id={`${parent_id}-Add-New-drawer`}
              >
                <Avatar
                  id={`${parent_id}-close-avatar`}
                  src="/images/close.svg"
                  className={classes.large}
                  onClick={() => handleDrawerClose()}
                />
                <div>
                  <div
                    id={`${parent_id}-Attributes-div`}
                    className={classes.drawerHead}>
                    <Typography
                      id={`${parent_id}-Attributes-typography`}
                      className={classes.headerMain}>
                      Add Attributes
                    </Typography>
                  </div>

                  <Grid
                    id={`${parent_id}-Button-grid`}
                    container
                    direction="row"
                    style={{ padding: "10px 20px" }}
                  >
                    <Grid
                      id={`${parent_id}-Button-sub-grid`}
                      item xs={12}>
                      {state?.list?.length > 0 &&
                        state?.list?.map((val) => {
                          return generateComponent({
                            data: val,
                            handleChange: handleChange,
                            state: state,
                            classes: classes,
                            parent_id: parent_id
                          });
                        })}
                      <div
                        id={`${parent_id}-Button-div`}
                        className={classes.btnPosition}>
                        <Button
                          id={`${parent_id}-Button`}
                          disabled={props?.loading ?? false}
                          variant={"contained"}
                          color={"primary"}
                          style={{ marginTop: 20 }}
                          size={"small"}
                          contained
                          onClick={() => saveAgeRange()}
                        >
                          {props?.loading ? "Saving..." : "Save"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            }
          </Drawer>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default withAllContexts(AttributesComp);
