import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";
// Styles
import qdmstyles from "./styles.json";

function Capsule(props) {
  const { value } = props;

  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {}, [state]);

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"3dJV7"}
        container={""}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        lg={"12"}
        md={"12"}
        sm={"12"}
        xl={"12"}
        xs={"12"}
      >
        <Paper key={"0"} id={"etc9W"} elevation={"{3}"} style={qdmstyles.etcW}>
          <Grid
            key={"0"}
            id={"mTFOI"}
            container={true}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
            style={qdmstyles.mTFOI}
          >
            <Grid
              key={"0"}
              id={"56Ktj"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              lg={"5"}
              md={"5"}
              sm={"5"}
              xl={"5"}
              xs={"5"}
              item={true}
              style={qdmstyles.Ktj}
            >
              <Typography
                key={"0"}
                id={"available_strength_left_side_value"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={value?.StrengthValue}
                aliasName={"availablestrengthleftsidevalue"}
                style={qdmstyles.wWamE}
              />
            </Grid>
            <Divider
              key={"1"}
              id={"i68WA"}
              variant={"fullWidth"}
              flexItem={true}
              orientation={"vertical"}
            />
            <Grid
              key={"2"}
              id={"VtLuv"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              lg={"5"}
              md={"5"}
              sm={"5"}
              xl={"5"}
              xs={"5"}
              item={true}
            >
              <Typography
                key={"0"}
                id={"available_strength_right_side_value"}
                align={"inherit"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={value?.StrengthVolumeUoM}
                aliasName={"availablestrengthrightsidevalue"}
                style={qdmstyles.PMrs}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(Capsule);
