import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
}));

function OrderDescription(props) {
  const classes = useStyles();
  const storeData = useSelector((state) => state?.orderCatalogSlice);
  const {
    code,
    longdes,
    shortdes,
    orderCategory,

    orderType,

    healthcareService,

    catalogSynonym,
    handleFormState,
    parent_id,
    error
  } = props;

  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}_description_paper`}
        className={classes.root}>
        <Typography
          id={`${parent_id}_ORDER_description_typography`}
          className={classes.title} variant="h6">
          ORDER DESCRIPTION
        </Typography>
        <Grid
          id={`${parent_id}_ORDER_description_grid`}
          container className={classes.form}>
          <Grid
            id={`${parent_id}_ORDER_code_grid`}
            md="2" lg="2" className={classes.formParent}>
            <Typography
              id={`${parent_id}_ORDER_code_typography`}
              className={classes.formtitle} variant="body1">
              Order Catalog Code <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              disabled={props?.isEdit}
              id={`${parent_id}_order_catalog_code_textField`}
              onChange={(e) => handleFormState("code", e.target.value, 20)}
              onInput={(e) => {
                // e.target.value = e.target.value.replace(/[^A-Za-z]/g, "");
                // e.target.value = e.target.value?.replaceAll(/[^A-Za-z]/g, "")?.toLocaleUpperCase() 
                e.target.value = e.target.value?.replaceAll(" ", "")?.toLocaleUpperCase()
              }}
              value={code}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              error={error?.code}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Short_Description_grid`}
            md="3" lg="3" className={classes.formParent}>
            <Typography
              id={`${parent_id}_Short_Description_typography`}
              className={classes.formtitle} variant="body1">
              Short Description <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id={`${parent_id}_Short_Description_textField`}
              value={shortdes}
              onChange={(e) => handleFormState("shortdes", e.target.value, 60)}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
               error={error?.shortdes}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Long_Description_grid`}
            md="6" lg="6" className={classes.formParent}>
            <Typography
              id={`${parent_id}_Long_Description_typography`}
              className={classes.formtitle} variant="body1">
              Long Description <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id={`${parent_id}_Long_Description_textField`}
              value={longdes}
              onChange={(e) => handleFormState("longdes", e.target.value, 100)}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
               error={error?.longdes}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Order_Category_grid`}
            md="4" lg="4" className={classes.formParent}>
            <Typography
              id={`${parent_id}_Order_Category_typography`}
              className={classes.formtitle} variant="body1">
              Order Category <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_orderCategory`}
              list={storeData?.order_catalog_masters?.data?.orderCategory ?? []}
              placeholder={"Select"}
              value={orderCategory}
              onchange={(e, value) => handleFormState("orderCategory", value)}
             error={error?.orderCategory}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Order_type_grid`}
            md="4" lg="4" className={classes.formParent}>
            <Typography
              id={`${parent_id}_Order_type_typography`}
              className={classes.formtitle} variant="body1">
              Order Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_ordertype`}
              list={storeData?.order_type_data?.data ?? []}
              placeholder={"Select"}
              value={orderType}
              onchange={(e, value) => handleFormState("orderType", value)}
              error={error?.orderType}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Order_type_typography`}
            md="4" lg="4" className={classes.formParent}>
            <Typography
              id={`${parent_id}_Healthcare_service_typography`}
              className={classes.formtitle} variant="body1">
              Healthcare Service
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_healthcare_service`}
              list={storeData?.order_catalog_masters?.data?.healthService ?? []}
              placeholder={"Select"}
              value={healthcareService}
              onchange={(e, value) =>
                handleFormState("healthcareService", value)
              }
            />
          </Grid>
          <Grid
            id={`${parent_id}_Healthcare_service_grid`}
            md="12" lg="12" className={classes.formParent}>
            <Typography
              id={`${parent_id}_Catalog_Synonym_typography`}
              className={classes.formtitle} variant="body1">
              Catalog Synonym
            </Typography>
            <TextField
              id={`${parent_id}_Catalog_Synonym_textField`}
              value={catalogSynonym}
              onChange={(e) =>
                handleFormState("catalogSynonym", e.target.value)
              }
              fullWidth
              multiline
              type="text"
              variant="outlined"
              size="small"
            />
            {/* <SelectBox
              list={catalogSynonymOption ?? []}
              multi
              placeholder={"Select"}
              value={catalogSynonym}
              onchange={(e, value) => handleFormState("catalogSynonym", value)}
            /> */}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default OrderDescription;
