import React, { useEffect, useState } from "react";
import backarrow from "../../../assets";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { SelectBox } from "../../../components";
import { tr } from "date-fns/locale";
import { findAllByDisplayValue } from "@testing-library/react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    margin: 15,
    borderRadius: 10,
    width: "95%",
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 4,
    color: "grey",
  },
  formParent: {
    paddingRight: 10,
    marginLeft: 0,
  },
}));

function CreateServiceDefinition(props) {
  const classes = useStyles();
  const { parent_id, setFinalValue, finalValue, SingleReadData } = props;
  console.log("abc", SingleReadData);

  const dispatch = useDispatch();
  const service = useSelector((state) => state.resourceByAppointmentTypesSlice);
  useEffect(() => {
    getMasterData();
  }, []);

  const getMasterData = async () => {
    let a = await dispatch(actions.RES_SERVICE_GROUP());
    let b = await dispatch(actions.RES_SERVICE_CATEGORY());
    let c = await dispatch(actions.RES_SERVICE_CODE());
    debugger;
  };

  const [state, setState] = useState({
    servicetype: [],
    servicecategory: [],
    serviccode: [],
  });
  console.log(state.servicecategory, "SingleReadData");
  React.useEffect(() => {
    const arr1 = [];
    const arr2 = [];
    const arr3 = [];
    console.log(arr2, "arr22");
    SingleReadData?.result?.[0]?.servicetype.forEach((object) => {
      // setState({
      //   ...state,
      //   servicetype: [
      //     {
      //       label: object.display,
      //       value: object._id,
      //     },
      //   ],
      // });
      arr1.push({
        label: object.display,
        value: object._id,
      });
    });
    SingleReadData?.result?.[0]?.servicecategory.forEach((object) => {
      arr2.push({
        label: object.display,
        value: object._id,
      });
    });
    SingleReadData?.result?.[0]?.serviccode.forEach((object) => {
      arr3.push({
        label: object.display,
        value: object._id,
      });
    });
    if (props.Controller) {
      setState({
        ...state,
        servicetype: arr1,
        servicecategory: arr2,
        serviccode: arr3,
      });
    }
  }, [SingleReadData]);

  const onClick = (name, val) => {
    const permittedValues = val.map((value) => value.value);
    console.log(val, "val111");
    setState({
      ...state,
      [name]: val,
    });
    setFinalValue({
      ...finalValue,
      [name]: permittedValues,
    });
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Typography
          id={`${parent_id}-serviceDefinition-title-typography`}
          className={classes.title}
          variant="h6"
        >
          SERVICE DEFINITION
        </Typography>

        <Grid
          container
          id={`${parent_id}-serviceDefinition -parent-Grid`}
          className={classes.form}
          lg={12}
          md={12}
          xs={12}
          xl={12}
          direction="row"
          justifyContent="flex-start"
        >
          <Grid
            id={`${parent_id}-Code title-grid`}
            md={4}
            lg={4}
            xs={4}
            xl={4}
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}-Code -title-typography`}
              className={classes.formtitle}
              variant="body1"
              color="inherit"
            >
              Service Group/Type{" "}
              <span id={`${parent_id}-Code-star-span`} style={{ color: "red" }}>
                *
              </span>
            </Typography>

            <SelectBox
              parent_id={"serviceDefinition-select"}
              multi={true}
              value={state?.servicetype ?? []}
              onchange={(e, value) => onClick("servicetype", value)}
              list={
                Array.isArray(service?.res_service_group?.data)
                  ? service?.res_service_group?.data
                  : []
              }
            />
          </Grid>
          <Grid
            id={`${parent_id} ServiceCategory-title-grid`}
            md={4}
            lg={4}
            xl={4}
            xs={4}
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}-service category-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Service Category
              <span
                id={`${parent_id}-service category-star-span`}
                style={{ color: "red" }}
              >
                *
              </span>
            </Typography>

            <SelectBox
              parent_id={"serviceDefinition-select"}
              multi={true}
              value={state?.servicecategory ?? {}}
              onchange={(e, value) => onClick("servicecategory", value)}
              list={
                Array.isArray(service?.res_service_category?.data)
                  ? service?.res_service_category?.data
                  : []
              }
            />
          </Grid>
          <Grid
            id={`${parent_id} Service Code-title-grid`}
            md={4}
            lg={4}
            xl={4}
            xs={4}
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}-Service Code-typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Service Code
              <span
                id={`${parent_id}-Service Code-star-span`}
                style={{ color: "red" }}
              >
                *
              </span>
            </Typography>
            <SelectBox
              parent_id={"serviceDefinition-select"}
              multi={true}
              disabled={false}
              value={state?.serviccode ?? []}
              onchange={(e, value) => onClick("serviccode", value)}
              list={
                Array.isArray(service?.res_service_code?.data)
                  ? service?.res_service_code?.data
                  : []
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default CreateServiceDefinition;
