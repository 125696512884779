import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tabs, Tab } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, parent_id, ...other } = props;

  return (
    <div
      id={`${parent_id}-parent-div`}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ borderTop: '1px solid #E0E0E0', }}
      {...other}
    >
      {value === index && (
        <div
          id={`${parent_id}-${children}-div`}
        >
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: '0px 10px 25px #0000000A',
    borderRadius: 8,
    backgroundColor: '#fff',
    '& .MuiTabs-root': {
      '& .MuiTab-root': {
        padding: '0px',
      },
      '& .MuiTabs-flexContainer': {
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0px 17px',
        padding: '0px'
      },
      '& .MuiTabs-indicator': {
        display: 'block',
        backgroundColor: '#0071F2'
      },
      '& .Mui-selected': {
        backgroundColor: 'transparent',
        color: 'inherit'
      }
    }
  },
}));

export const CustomTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props?.activeTab);
  const { parent_id } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props?.handleChange && props?.handleChange(newValue)
  };

  useEffect(() => {
    if (value > (props?.tabs.length - 1)) {
      setValue(0);
    }
  }, [props?.tabs])

  return (
    <div
      id={`${parent_id}-parent-div`}
      className={classes.root}>
      {/* <AppBar position="static"> */}
      <Tabs
        id={`${parent_id}-parent-tabs`}
        value={value} onChange={handleChange} aria-label="simple tabs example">
        {props?.tabs && props?.tabs?.map((tab, index) => {
          return (
            <Tab
              id={`${parent_id}-${index}-tab`}
              label={tab?.header ?? ''} {...a11yProps(index)} />
          )
        })}
      </Tabs>
      {/* </AppBar> */}
      {
        props?.tabs && props?.tabs?.map((comp, index) => {
          return (
            <TabPanel
              id={`${parent_id}-${index}-tabPanel`}
              value={value} index={index}>
              {comp?.component ?? ''}
            </TabPanel>
          )
        })
      }
    </div>
  );
}

CustomTabs.defaultProps = {
  activeTab: 0,
  handleChange: null
}