import React from "react";
import { Row, Col, TextInput } from "qdm-component-library";
import {
	FormLabel,
	makeStyles,
	Grid,
	Button,
	Popover,
	IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/CreateOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../../HOCs";
import { valitationFunc } from "../../../utils";
//import { AlertProps } from "../../../utils";

const useStyles = makeStyles(() => ({
	main: {
		backgroundColor: "white",
		width: "90vw",
		borderRadius: 8,
		padding: 14,
	},
	paddingLeft0: {
		paddingLeft: 0,
		marginBottom: "1%",
	},
	dot: {
		height: "7px",
		width: "7px",
		backgroundColor: "#bbb",
		borderRadius: "50%",
		display: "inline-block",
		marginTop: "5px",
		marginRight: "7px",
		marginLeft: "7px",
	},
	req_red: {
		color: "red",
	},
	labelStyleNew: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
		marginBottom: 10
	},
}));
const styles = {
	labelStyle: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
	},
	borderStyle: {
		borderRadius: 8,
		borderColor: "#E0E0E0",
	},
	errorBorderStyle: {
		borderRadius: 8,
		border: "1px solid red",
	},
};
const Address = (props) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [state, setState] = React.useState({
		use: {},
		type: {},
		address1: "",
		address2: "",
		address3: "",
		city: {},
		district: {},
		state: {},
		country: {},
		pincode: {},
		error:{}
	});
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const { parent_id } = props;

	const dispatch = useDispatch();
	const organizationSlice = useSelector((state) => state.organizationSlice);

	React.useLayoutEffect(() => {
		const getdata = async () => {
			await dispatch(actions.ORGANIZATION_GET_ADD_TYPE());
			await dispatch(actions.ORGANIZATION_GET_ADD_USE());
			await dispatch(actions.ORGANIZATION_GET_ADD_City());
		};
		getdata();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (props?.editData?.length > 0) {
			setState({
				...state,
				use: props?.editData[0]?.use,
				type: props?.editData[0]?.type,
				address1: props?.editData[0]?.address1,
				address2: props?.editData[0]?.address2,
				address3: props?.editData[0]?.address3,
				city: props?.editData[0]?.city,
				district: props?.editData[0]?.district,
				state: props?.editData[0]?.state,
				country: props?.editData[0]?.country,
				pincode: props?.editData[0]?.pincode,
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.editData]);

	const { labelStyle, 
		//borderStyle, errorBorderStyle 
	} = styles;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const generateFun = async (e, val, types) => {
		if (state.error[types]) {
      state.error[types] = false;
    }
		if (types === "city") {
			if (val?.id) {
				await dispatch(
					actions.ORGANIZATION_GET_ADD_DISTRICT({
						val: val.id,
					})
				);
				await dispatch(
					actions.ORGANIZATION_GET_PINCODE({
						val: val?.value,
					})
				);
			}
			setState({
				...state,
				district: {},
				state: {},
				country: {},
				pincode: {},
				[types]: val,
			});
		} else if (types === "district") {
			if (val?.id) {
				await dispatch(
					actions.ORGANIZATION_GET_AddSTATE({
						val: val.id,
					})
				);
			}
			setState({
				...state,
				state: {},
				country: {},
				pincode: {},
				[types]: val,
			});
		} else if (types === "state") {
			if (val?.id) {
				await dispatch(
					actions.ORGANIZATION_GET_COUNTRY({
						val: val.id,
					})
				);
			}
			setState({
				...state,
				country: {},
				pincode: {},
				[types]: val,
			});
		} else {
			setState({ ...state, [types]: val });
		}
	};
	const handleClose = () => {
		setState({
			...state,
			use: {},
			type: {},
			address1: "",
			address2: "",
			address3: "",
			city: {},
			district: {},
			state: {},
			country: {},
			pincode: {},
		});
		setAnchorEl(null);
	};
	const SaveBtn = async(e) => {

		let errorList = [
      "use",
      "type",
      "city",
      "district",
      "country",
      "state",
      "address1",
      "address2",
      "address3",
      "pincode",
    ];
		let { error, errorState } = await valitationFunc(state, errorList);
		
		if (!error) {
			props?.onChangeAll(e, state, "Address");
			setAnchorEl(null);
    	} else {
		setState({
		...state,
		error: errorState,
    	});
      props.alert.setSnack("mandatory");
    }
	};
	return (
		<Grid
			id={`${parent_id}-parent-div`}
			className={classes.main} container direction="row">
			<Grid
				id={`${parent_id}-parent-sub-grid`}
				container item xs={12}>
				<Grid
					id={`${parent_id}-title-sub-grid`}
					item xs={12}>
					<Grid
						id={`${parent_id}-title-container-grid`}
						container>
						<Grid
							id={`${parent_id}-title-grid`}
							item xs={8} style={{ textAlign: "left" }}>
							<p
								id={`${parent_id}-ADDRESS-title-p`}
								style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
								ADDRESS{" "}
							</p>
						</Grid>
						<Grid
							id={`${parent_id}-Add-New-Address-grid`}
							item xs={4}>
							<Grid
								id={`${parent_id}-Add-New-Address-sub-grid`}
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
							>
								<Button
									className={classes.newRes}
									color="primary"
									onClick={handleClick}
									id={`${parent_id}-Add-New-Address-button`}
								>
									+ Add New Address
								</Button>
							</Grid>
						</Grid>
						{props?.Addressdata?.map((val, i) => {
							
							return (
								<Grid
									id={`${parent_id}-parent-grid` + i}
									container
									style={{
										border: "1px solid #E0E0E0",
										padding: 12,
										marginBottom: 8,
										backgroundColor: "#F9F9F9",
										position: "relative",
									}}
									direction="row"
									alignItems="left"
								>
									<Grid
										id={`${parent_id}-Work-grid` + i}
										item xs={6}>
										<Grid
											id={`${parent_id}-Work-sub-grid` + i}
											container
											direction="row"
											justify="flex-end"
											alignItems="left"
										>
											<Grid
												id={`${parent_id}-Work-sublevel-grid` + i}
												item
												xs={1}
												style={{
													backgroundColor: "#E0E0E0",
													position: "absolute",
													left: 0,
													padding: "6px 12px",
													borderTopRightRadius: 9,
													borderBottomRightRadius: 9,
												}}
											>
												{i + 1}
											</Grid>
											<Grid
												id={`${parent_id}-Work-title-grid` + i}
												item
												xs={12}
												style={{ marginLeft: "6%", textAlign: "left" }}
											>
												<div
													id={`${parent_id}-Work-title-div` + i}
												>Work</div>
												<div
													id={`${parent_id}-Work-val-div` + i}
												>
													{val.address1 +
														" " +
														val.address2 +
														" " +
														val.address3 +
														", " +
														val.district.title +
														", " +
														val.city.title +
														", " +
														val.pincode.title}
												</div>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										id={`${parent_id}-icon-grid` + i}
										item xs={6}>
										<Grid
											id={`${parent_id}-icon-container-grid` + i}
											container
											direction="row"
											justifyContent="flex-end"
											alignItems="center"
										>
											<IconButton
												id={`${parent_id}-EditIcon-iconbutton` + i}
												aria-label="EditIcon"
												className={classes.margin}
												style={{ marginTop: "1%" }}
												size="small"
											>
												<EditIcon
													id={`${parent_id}-EditIcon` + i}
													onClick={(e) => {
														handleClick(e);
														props.editFun("Address", i);
													}}
													style={{ color: "#0071F2", fontSize: 18 }}
												/>
											</IconButton>
											<IconButton
												id={`${parent_id}-EditIcon-deleteicon` + i}
												aria-label="DeleteIcon"
												className={classes.margin}
												size="small"
												onClick={() => props.deleteFun("Address", i)}
												style={{ marginTop: "1%" }}
											>
												<DeleteIcon
													id={`${parent_id}-deleteicon` + i}
													style={{ color: "#FF4D4A", fontSize: 18 }}
												/>
											</IconButton>
										</Grid>
									</Grid>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					PaperProps={{
						style: { width: "80%", padding: 20 },
					}}
				>
					<Grid
						id={`${parent_id}-Use-parent-grid`}
						container direction="column">
						<Row
							id={`${parent_id}-Use-parent-row`}
							inLineStyles={{ paddingBottom: "2%" }}>
							<Col
								id={`${parent_id}-Use-parent-Col`}
								md={2}
								lg={2}
								sm={2}
								xs={12}
								className={classes.paddingLeft0}
							>
								<FormLabel
									id={`${parent_id}-Use-title-FormLabel`}
									component="legend"
									classes={{root:classes.labelStyleNew}}
								>
									Use <span
										id={`${parent_id}-Use-star-span`}
										className={classes.req_red}>*</span>
								</FormLabel>

								<Autocomplete
									id={`${parent_id}-Use-autocomplete`}
									getOptionLabel={(option) => option.title}
									style={{ width: "auto", marginTop: "1%" }}
									size={"small"}
									renderInput={(params) => (
										<TextField
											id={`${parent_id}-Use-textField`}
											{...params} variant="outlined"
											error={state?.error?.use}
											 />
									)}
									options={
										organizationSlice?.orgAddUSE?.data
											? organizationSlice?.orgAddUSE?.data
											: []
									}
									onChange={(e, val) => generateFun(e, val, "use")}
									value={state?.use}
								/>
							</Col>
							<Col
								md={2}
								lg={2}
								sm={2}
								xs={12}
								className={classes.paddingLeft0}
							>
								<FormLabel
									id={`${parent_id}-Type-title-FormLabel`}
									component="legend"
									classes={{root:classes.labelStyleNew}}
								>
									Type <span className={classes.req_red}>*</span>
								</FormLabel>

								<Autocomplete
									id={`${parent_id}-Type-autocomplete`}
									getOptionLabel={(option) => option.title}
									style={{ width: "auto", marginTop: "1%" }}
									size={"small"}
									renderInput={(params) => (
										<TextField
											id={`${parent_id}-Type-textField`}
											{...params} variant="outlined"
											error={state?.error?.type}
											 />
									)}
									options={
										organizationSlice?.orgAddType?.data
											? organizationSlice?.orgAddType?.data
											: []
									}
									onChange={(e, val) => generateFun(e, val, "type")}
									value={state?.type}
								/>
							</Col>
							<Col
								id={`${parent_id}-Line-1-col`}
								//className="no-padding-left"
								md={5}
								lg={5}
								sm={5}
								xs={12}
								className={classes.paddingLeft0}
							>
								<TextInput
									id={`${parent_id}-Line-1-textInput`}
									label="Address Line 1"
									placeholder="Address Line 1"
									labelStyle={labelStyle}
									hoverColor="#0071F2"
									onChange={(e, val) =>
										generateFun(e, e.target.value, "address1")
									}
									
									isRequired={true}
									value={state.address1}
									error={state?.error?.address1}
								/>
							</Col>
							<Col
								id={`${parent_id}-Line-2-col`}
								//className="no-padding-left"
								md={5}
								lg={5}
								sm={5}
								xs={12}
								className={classes.paddingLeft0}
							>
								<TextInput
									id={`${parent_id}-Line-2-textInput`}
									label="Address Line 2"
									placeholder="Address Line 2"
									labelStyle={labelStyle}
									hoverColor="#0071F2"
									onChange={(e, val) =>
										generateFun(e, e.target.value, "address2")
									}
									value={state.address2}
									isRequired={true}
									error={state?.error?.address2}
								/>
							</Col>
							<Col
								id={`${parent_id}-Line-3-col`}
								//className="no-padding-left"
								md={5}
								lg={5}
								sm={5}
								xs={12}
								className={classes.paddingLeft0}
							>
								<TextInput
									id={`${parent_id}-Line-3-textInput`}
									label="Address Line 3"
									placeholder="Address Line 2"
									labelStyle={labelStyle}
									hoverColor="#0071F2"
									onChange={(e, val) =>
										generateFun(e, e.target.value, "address3")
									}
									value={state.address3}
									isRequired={true}
									error={state?.error?.address3}
								/>
							</Col>
							<Col
								id={`${parent_id}-City-col`}
								md={3}
								lg={3}
								sm={3}
								xs={12}
								className={classes.paddingLeft0}
							>
								<FormLabel
									id={`${parent_id}-City-FormLabel`}
									component="legend"
									classes={{root:classes.labelStyleNew}}
								>
									City <span className={classes.req_red}>*</span>
								</FormLabel>

								<Autocomplete
									id={`${parent_id}-City-autocomplete`}
									getOptionLabel={(option) => option.title}
									style={{ width: "auto", marginTop: "1%" }}
									size={"small"}
									renderInput={(params) => (
										<TextField
											id={`${parent_id}-City-textField`}
											{...params} variant="outlined"
											error={state?.error?.city}
											 />
									)}
									options={
										organizationSlice?.orgAddCity?.data
											? organizationSlice?.orgAddCity?.data
											: []
									}
									onChange={(e, val) => generateFun(e, val, "city")}
									value={state?.city}
								/>
							</Col>
							<Col
								id={`${parent_id}-District-col`}
								md={3}
								lg={3}
								sm={3}
								xs={12}
								className={classes.paddingLeft0}
							>
								<FormLabel
									id={`${parent_id}-District-FormLabel`}
									component="legend"
									classes={{root:classes.labelStyleNew}}
								>
									District <span className={classes.req_red}>*</span>
								</FormLabel>

								<Autocomplete
									id={`${parent_id}-District-autocomplete`}
									getOptionLabel={(option) => option.title}
									style={{ width: "auto", marginTop: "1%" }}
									size={"small"}
									renderInput={(params) => (
										<TextField
											id={`${parent_id}-District-textField`}
											{...params} variant="outlined"
											error={state?.error?.district}
											 />
									)}
									options={
										organizationSlice?.orgAddDISTRICT?.data
											? organizationSlice?.orgAddDISTRICT?.data
											: []
									}
									onChange={(e, val) => generateFun(e, val, "district")}
									value={state?.district}
								/>
							</Col>
							<Col
								id={`${parent_id}-State-col`}
								md={3}
								lg={3}
								sm={3}
								xs={12}
								className={classes.paddingLeft0}
							>
								<FormLabel
									id={`${parent_id}-State-FormLabel`}
									component="legend"
									classes={{root:classes.labelStyleNew}}
								>
									State <span className={classes.req_red}>*</span>
								</FormLabel>
								<Autocomplete
									id={`${parent_id}-State-autocomplete`}
									getOptionLabel={(option) => option.title}
									style={{ width: "auto", marginTop: "1%" }}
									size={"small"}
									renderInput={(params) => (
										<TextField
											id={`${parent_id}-State-textField`}
											{...params} variant="outlined"
											error={state?.error?.state}
											/>
									)}
									options={
										organizationSlice?.orgAddSTATE?.data
											? organizationSlice?.orgAddSTATE?.data
											: []
									}
									onChange={(e, val) => generateFun(e, val, "state")}
									value={state?.state}
								/>
							</Col>
							<Col
								id={`${parent_id}-Country-col`}
								md={3}
								lg={3}
								sm={3}
								xs={12}
								className={classes.paddingLeft0}
							>
								<FormLabel
									id={`${parent_id}-Country-FormLabel`}
									component="legend"
									classes={{root:classes.labelStyleNew}}
								>
									Country <span className={classes.req_red}>*</span>
								</FormLabel>
								<Autocomplete
									id={`${parent_id}-Country-Autocomplete`}
									getOptionLabel={(option) => option.title}
									style={{ width: "auto", marginTop: "1%" }}
									size={"small"}
									renderInput={(params) => (
										<TextField
											id={`${parent_id}-Country-textField`}
											{...params} variant="outlined"
											error={state?.error?.country}
											 />
									)}
									options={
										organizationSlice?.orgAddCOUNTRY?.data
											? organizationSlice?.orgAddCOUNTRY?.data
											: []
									}
									onChange={(e, val) => generateFun(e, val, "country")}
									value={state?.country}
								/>
							</Col>
							<Col
								id={`${parent_id}-Pincode-Col`}
								md={3}
								lg={3}
								sm={3}
								xs={12}
								className={classes.paddingLeft0}
							>
								<FormLabel
									id={`${parent_id}-Pincode-FormLabel`}
									component="legend"
									classes={{root:classes.labelStyleNew}}
								>
									Pincode <span className={classes.req_red}>*</span>
								</FormLabel>
								<Autocomplete
									id={`${parent_id}-Pincode-Autocomplete`}
									getOptionLabel={(option) => option.title}
									style={{ width: "auto", marginTop: "1%" }}
									size={"small"}
									renderInput={(params) => (
										<TextField
											id={`${parent_id}-Pincode-textField`}
											{...params} variant="outlined"
											error={state?.error?.pincode}
											 />
									)}
									options={
										organizationSlice?.orgAddPINCODE?.data
											? organizationSlice?.orgAddPINCODE?.data
											: []
									}
									onChange={(e, val) => generateFun(e, val, "pincode")}
									value={state?.pincode}
								/>
							</Col>
						</Row>
					</Grid>
					<div
						id={`${parent_id}-buttons-div`}
						style={{ textAlign: "right" }}>
						<Button
							id={`${parent_id}-Cancel-button`}
							variant={"outlined"} color="primary" onClick={handleClose}>
							Cancel
						</Button>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Button
							id={`${parent_id}-Save-button`}
							variant={"contained"} color="primary" onClick={SaveBtn}>
							Save
						</Button>
					</div>
				</Popover>
			</Grid>
		</Grid>
	);
};

export default withAllContexts(Address);
