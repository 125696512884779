import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import { TextInput } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  modalHead: {
    letterSpacing: "0px",
    color: "#242424",
    opacity: 1,
    fontSize: "14px",
    fontWeight: "600",
    fontWeight: 600,
  },
  modalIcon: {
    font: "normal normal medium 14px/20px Poppins",
    letterSpacing: "0px",
    color: "#0071F2",
    textTransform: "capitalize",
    opacity: 1,
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  title: {
    fontFamily: "poppinsemibold",
    display: "inline",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  modalHeader: {
    font: "normal normal 600 16px/25px Poppins",
    color: "#101010",
    textTransform: "capitalize",
    opacity: 1,
    fontSize: "16px",
  },
  label: {
    letterSpacing: "var(--unnamed-character-spacing-0)",
    color: "var(--secondary-text-6f6f6f)",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#6F6F6F",
    textTransform: "capitalize",
    opacity: 1,
    fontSize: "12px",
    padding: "10px 0px 5px 0px",
  },
  req_red: {
    color: "#FF4D4A",
  },
  insideLabel: {
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "center",
    font: "normal normal normal 14px/20px Poppins",
    letterSpacing: "0px",
    color: "#323232",
    opacity: 1,
    fontSize: "14px",
  },
}));

function PharmacyMedicationFormModal(props) {
  const classes = useStyles();
  const { parent_id, modalerror } = props;
  const storeData = useSelector((state) => state?.orderSetMasterSlice);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={1}>
          <Typography className={classes.label}>Seq.No</Typography>
          <TextInput
            label=""
            placeholder=""
            hoverColor="#0071F2"
            onChange={(e) =>
              props?.handleModalState(
                e.target.value,
                "seqNo",
                "pharmacyMedication"
              )
            }
            value={
              props?.isEdit
                ? props?.pharmacyMedication?.seqNo
                : props?.sequencyNumber
            }
            isRequired={false}
            disabled
          />
        </Grid>
        <Grid item sm={2}>
          <Typography className={classes.label}>
            Order Category <span className={classes.req_red}>*</span>
          </Typography>
          <Autocomplete
            getOptionLabel={(option) => option.label}
            style={{ width: "auto" }}
            size={"small"}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}-Pincode-textField`}
                {...params}
                variant="outlined"
                error={modalerror?.orderCategory}
              />
            )}
            options={storeData?.order_set_masters?.data?.orderCategory ?? []}
            onChange={(e, val) =>
              props?.handleModalChange(
                val,
                "orderCategory",
                "pharmacyMedication"
              )
            }
            value={props?.pharmacyMedication?.orderCategory ?? {}}
          />
        </Grid>
        <Grid item sm={2}>
          <Typography className={classes.label}>
            Drug Type <span className={classes.req_red}>*</span>
          </Typography>
          <Autocomplete
            getOptionLabel={(option) => option.label}
            style={{ width: "auto" }}
            size={"small"}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}-Pincode-textField`}
                {...params}
                variant="outlined"
                error={modalerror?.orderTypeDrugType}
              />
            )}
            options={storeData?.drug__masters?.data ?? []}
            onChange={(e, val) =>
              props?.handleModalChange(
                val,
                "orderTypeDrugType",
                "pharmacyMedication"
              )
            }
            value={props?.pharmacyMedication?.orderTypeDrugType ?? {}}
          />
        </Grid>
        <Grid item sm={6}>
          <Typography className={classes.label}>
            Drug Catalog <span className={classes.req_red}>*</span>
          </Typography>
          <Autocomplete
            getOptionLabel={(option) => option.label}
            style={{ width: "auto" }}
            size={"small"}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}-Pincode-textField`}
                {...params}
                variant="outlined"
                error={modalerror?.orderDrugCatalog}
              />
            )}
            options={storeData?.drug__catalog_masters?.data ?? []}
            onChange={(e, val) =>
              props?.handleModalState(
                val,
                "orderDrugCatalog",
                "pharmacyMedication"
              )
            }
            value={props?.pharmacyMedication?.orderDrugCatalog ?? {}}
          />
        </Grid>
        <Grid item sm={1}>
          <Typography className={classes.label}>Required</Typography>
          <Switch
            checked={props?.pharmacyMedication?.required ?? false}
            onChange={(e) => props?.handleChange(e, "pharmacyMedication")}
            color="primary"
            name="required"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Grid>
        <Grid item sm={5}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography className={classes.label}>Route</Typography>
              <Autocomplete
                getOptionLabel={(option) => option.label}
                style={{ width: "auto" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    id={`${parent_id}-Pincode-textField`}
                    {...params}
                    variant="outlined"
                  />
                )}
                options={storeData?.order_set_masters?.data?.route ?? []}
                onChange={(e, val) =>
                  props?.handleModalState(val, "route", "pharmacyMedication")
                }
                value={props?.pharmacyMedication?.route ?? {}}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography className={classes.label}>
                Quantity <span className={classes.req_red}>*</span>
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  {" "}
                  <TextField
                    id="standard-basic"
                    label=""
                    onChange={(e) =>
                      props?.handleModalState(
                        e.target.value,
                        "quantity_number",
                        "pharmacyMedication",
                        2
                      )
                    }
                    value={props?.pharmacyMedication?.quantity_number ?? ""}
                    error={modalerror?.quantity_number}
                    type="number"
                  />
                </Grid>
                <Grid item sm={9}>
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    style={{ width: "auto" }}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Pincode-textField`}
                        {...params}
                        variant="outlined"
                        error={modalerror?.quantity_type}
                      />
                    )}
                    options={
                      storeData?.order_set_masters?.data?.commonUOM ?? []
                    }
                    onChange={(e, val) =>
                      props?.handleModalState(
                        val,
                        "quantity_type",
                        "pharmacyMedication"
                      )
                    }
                    value={props?.pharmacyMedication?.quantity_type ?? {}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Typography className={classes.label}>
                Dosage Freq Code
              </Typography>

              <Autocomplete
                getOptionLabel={(option) => option.label}
                style={{ width: "auto" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    id={`${parent_id}-Pincode-textField`}
                    {...params}
                    variant="outlined"
                  />
                )}
                options={storeData?.order_set_masters?.data?.drugFreqCode ?? []}
                onChange={(e, val) =>
                  props?.handleModalState(
                    val,
                    "dosage_freq_code",
                    "pharmacyMedication"
                  )
                }
                value={props?.pharmacyMedication?.dosage_freq_code ?? {}}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography className={classes.label}>
                Duration <span className={classes.req_red}>*</span>
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={4}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  {" "}
                  <TextField
                    id="standard-basic"
                    label=""
                    onChange={(e) =>
                      props?.handleModalState(
                        e.target.value,
                        "duration_number",
                        "pharmacyMedication",
                        3
                      )
                    }
                    value={props?.pharmacyMedication?.duration_number ?? ""}
                    error={modalerror?.duration_number}
                    type="number"
                    // disabled={
                    //   props?.pharmacyMedication?.orderDrugCatalog
                    //     ?.freqApplicable
                    //     ? false
                    //     : true
                    // }
                  />
                </Grid>
                <Grid item sm={8}>
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    style={{ width: "auto" }}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Pincode-textField`}
                        {...params}
                        variant="outlined"
                        error={modalerror?.duration_type}
                      />
                    )}
                    options={
                      storeData?.order_set_masters?.data?.durationDropDown ?? []
                    }
                    onChange={(e, val) =>
                      props?.handleModalState(
                        val,
                        "duration_type",
                        "pharmacyMedication"
                      )
                    }
                    value={props?.pharmacyMedication?.duration_type ?? {}}
                    // disabled={
                    //   props?.pharmacyMedication?.orderDrugCatalog
                    //     ?.freqApplicable
                    //     ? false
                    //     : true
                    // }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2}>
              <Typography className={classes.label}>
                Start Day <span className={classes.req_red}>*</span>
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={9}>
                  <TextInput
                    label=""
                    placeholder=""
                    hoverColor="#0071F2"
                    onChange={(e) =>
                      props?.handleModalState(
                        e.target.value,
                        "startDay",
                        "pharmacyMedication",
                        2
                      )
                    }
                    value={props?.pharmacyMedication?.startDay ?? ""}
                    error={modalerror?.startDay}
                    type="number"
                    isRequired={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <Typography className={classes.label}>Explanatory Note</Typography>
          <TextInput
            label=""
            placeholder=""
            hoverColor="#0071F2"
            onChange={(e) =>
              props?.handleModalState(
                e.target.value,
                "explanatoryNote",
                "pharmacyMedication"
              )
            }
            value={props?.pharmacyMedication?.explanatoryNote ?? ""}
            isRequired={false}
          />
        </Grid>
        <Grid item sm={6}>
          <Typography className={classes.label}>
            Practitioner Instructions
          </Typography>
          <TextInput
            label=""
            placeholder=""
            hoverColor="#0071F2"
            onChange={(e) =>
              props?.handleModalState(
                e.target.value,
                "practitionerInstructions",
                "pharmacyMedication"
              )
            }
            value={props?.pharmacyMedication?.practitionerInstructions ?? ""}
            isRequired={false}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default PharmacyMedicationFormModal;
