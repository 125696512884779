import React from "react";
import { Div, Paper, H6, Row, Divider } from "qdm-component-library";
import moment from "moment";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Dialog,
  DialogContent,
  Checkbox,
  Button,
  Tooltip,
} from "@material-ui/core";
//import bussinessIcon from "../../../assets/icons8-database-administrator (1).svg";
import clock_ from "../../../assets/clock.svg";
import calender_ from "../../../assets/calendar-Blue.svg";
import { EditIcon } from "../../../components/svg/editIcon";
import DeleteIcon from "../../../assets/icons8-trash.svg";
import { DateTimePicker } from "../../../components/common/smartForm/component/datePicker";
import { TimePicker } from "../../../components/common/timePicker/index";
//import moment from "moment";
import { utcTOLocal } from "../../../utils";

class WorkingDays extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  styles = {
    label: {
      fontSize: "14px",
    },
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    borderStyleTime: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      width: "85%",
      padding: "0px",
    },
    entity: {
      margin: "0px",
      color: "#6F6F6F",
      paddingRight: "10px",
    },
    borderCover: {
      width: "160px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      border: "1px solid #E0E0E0",
      borderRadius: "8px",
    },
    content: {
      display: "flex",
      alignItems: "baseline",
      padding: "0 24px 20px",
    },
    btnEdit1: {
      backgroundColor: "#999999",
      color: "#fff",
      padding: "6px 16px",
      fontFamily: "poppin",
      fontSize: "12px",
      textTransform: "none",
      borderRadius: "10px",
      width: "100px",
    },
    btnEdit: {
      backgroundColor: "#0071F2",
      color: "#fff",
      padding: "6px 16px",
      fontFamily: "poppin",
      fontSize: "12px",
      textTransform: "none",
      borderRadius: "10px",
      width: "100px",
    },
    iconBorder: {
      border: "1px solid #e0e0e0",
      cursor: "pointer",
      borderRadius: "8px",
      marginLeft: "10px",
      padding: "0px 12px",
      color: "#0071f2",
      fontSize: "20px",
    },
    cancelEdit: {
      backgroundColor: "#fff",
      marginRight: "15px",
      color: "#242424",
      padding: "6px 16px",
      fontFamily: "poppin",
      fontSize: "12px",
      textTransform: "none",
      borderRadius: "10px",
      width: "100px",
      border: "1px solid #F3F3F3",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      //marginLeft: theme.spacing(1),
      //marginRight: theme.spacing(1),
      width: 200,
    },
  };

  render() {
    //let { communication } = this.props.patientData;
    const {
      label,
      // labelStyle,
      // borderStyle,
      // borderStyleTime,
      // entity,
      // borderCover,
      content,
      btnEdit,
      btnEdit1,
      iconBorder,
      cancelEdit,
      // container,
      // textField,
    } = this.styles;
    const { parent_id } = this.props;
    return (
      <div id={`${parent_id}-parent-div`} style={{ margin: "20px 0px" }}>
        <Div id={`${parent_id}-parent-sub-div`} className="pi-root">
          <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
            <Div id={`${parent_id}-title-div`}>
              <H6 id={`${parent_id}-title-h6`} className="pi-title">
                WORKING DAYS
              </H6>
            </Div>
            <Row id={`${parent_id}-Radio-row`} className="pi-actions-root">
              <div
                id={`${parent_id}-Radio-div`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  id={`${parent_id}-24-x-7-title-typography`}
                  style={{ fontSize: "12px", color: "#6f6f6f" }}
                >
                  24 x 7
                </Typography>

                <RadioGroup
                  id={`${parent_id}-position-radioGroup`}
                  aria-label="position"
                  name="position"
                  value={this.props.working_days_yes_no}
                  row
                  style={{ paddingLeft: "15px" }}
                >
                  <FormControlLabel
                    id={`${parent_id}-workingday-FormControlLabel`}
                    style={{ color: "#6f6f6f" }}
                    control={
                      <Radio
                        id={`${parent_id}-workingday-radio`}
                        value="Yes"
                        onChange={(e) =>
                          this.props.handleRadioChange(e, "workingday")
                        }
                        checked={this.props.working_days_yes_no === "Yes"}
                        size="small"
                        style={{ color: "#0071F2" }}
                      />
                    }
                    classes={{ label: label }}
                    label="Yes"
                  />
                  <FormControlLabel
                    id={`${parent_id}-workingday-FormControlLabel`}
                    style={{ color: "#6f6f6f" }}
                    control={
                      <Radio
                        id={`${parent_id}-workingday-radio`}
                        value="No"
                        onChange={(e) =>
                          this.props.handleRadioChange(e, "workingday")
                        }
                        checked={this.props.working_days_yes_no === "No"}
                        size="small"
                        style={{ color: "#0071F2" }}
                      />
                    }
                    label="No"
                    classes={{ label: label }}
                  />
                </RadioGroup>
              </div>
            </Row>
            {this.props.is_visible === true ? (
              <Div
                id={`${parent_id}-is-visible-div`}
                style={{
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  padding: "18px 24px",
                }}
              >
                <Div
                  id={`${parent_id}-is-visible-sub-div`}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <Div
                    id={`${parent_id}-Year-title-div`}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      id={`${parent_id}-Year-calender-img`}
                      src={calender_}
                      width="18px"
                      style={{ margin: "0px 16px 0px 0px" }}
                      alt="pic"
                    />
                    <div>
                      <Typography
                        id={`${parent_id}-Year-title-typography`}
                        style={{ fontSize: "10px", color: "#6f6f6f" }}
                      >
                        Year
                      </Typography>
                      <H6
                        id={`${parent_id}-Year-title-h6`}
                        className="pi-title"
                      >
                        {this.props.workingday_year}
                      </H6>
                    </div>
                    <div
                      id={`${parent_id}-clock-styled-div`}
                      style={{
                        border: "1px solid #eee",
                        height: "50px",
                        marginLeft: "100px",
                        backgroundColor: "#E0E0E0",
                      }}
                    ></div>
                    <img
                      id={`${parent_id}-clock-img`}
                      src={clock_}
                      width="18px"
                      style={{ margin: "0px 0px 0px 16px" }}
                      alt="pic"
                    />
                  </Div>
                  <Grid
                    id={`${parent_id}-item-map-grid`}
                    container
                    direction="row"
                  >
                    {this.props.workingday_daySlots?.map((item, i) => (
                      <Grid
                        id={`${parent_id}-${i}-daySlots-grid`}
                        item
                        md={3}
                        xs={6}
                      >
                        <div
                          id={`${parent_id}-${i}-daySlots-div`}
                          style={{
                            display: "flex",
                            marginLeft: "20px",
                            marginBottom: "10px",
                            borderRight: "1px solid #E0E0E0",
                          }}
                        >
                          <div id={`${parent_id}-${i}-itemday-title-div`}>
                            <Typography
                              id={`${parent_id}-${i}-itemday-title-typography`}
                              style={{ fontSize: "12px", color: "#6f6f6f" }}
                            >
                              {item.day}
                            </Typography>
                            {item.timeSlots.map((itm) => (
                              <H6
                                id={`${parent_id}-${i}-itemday-time-h6`}
                                className="pi-title"
                              >
                                {utcTOLocal(itm.fromTime, "HH:mm")} -
                                {utcTOLocal(itm.toTime, "HH:mm")};
                              </H6>
                            ))}
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                  <div
                    id={`${parent_id}-EditIcon-div`}
                    style={{
                      margin: "10px 15px",
                      cursor: "pointer",
                    }}
                    onClick={this.props.handleworkdayedit}
                  >
                    <EditIcon id={`${parent_id}-EditIcon`} color="#0071f2" />
                  </div>
                </Div>
              </Div>
            ) : null}
          </Paper>
        </Div>

        <Dialog
          id={`${parent_id}-popup-parent-div`}
          open={this.props.open_working_days_popup}
        >
          <div
            id={`${parent_id}-OPERATIONAL-TIMINGS-div`}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "16px 24px 8px",
            }}
          >
            <H6
              id={`${parent_id}-OPERATIONAL-TIMINGS-title-h6`}
              className="pi-title"
            >
              OPERATIONAL TIMINGS
            </H6>
            <div
              id={`${parent_id}-Select-Year-div`}
              style={{ flexGrow: 1 }}
            ></div>
            <Typography
              id={`${parent_id}-Select-Year-title-typography`}
              style={{ fontSize: "12px", color: "#6f6f6f" }}
            >
              Select Year
            </Typography>
            <div
              id={`${parent_id}-Select-Year-datetimepicker-div`}
              style={{ width: "30%", marginLeft: "10px" }}
            >
              {console.log(
                this.props.workingday_year,
                "this.props.workingday_year"
              )}
              <DateTimePicker
                id={`${parent_id}-Select-Year-datetimepicker`}
                placeholder="YYYY"
                views="year"
                dateFormat="yyyy"
                value={moment().set("year", this.props.workingday_year)}
                onChange={(data) => this.props.onworkdayyearState(data)}
              />
              {/* <TextField
                type="date"
                placeholder="2233"
                value={this.props.workingday_year}
                onChange={(e) => this.props.onworkdayyearState(e)}
              /> */}
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["year"]}
                  label="Year only"
                  value={this.props.workingday_year}
                  onChange={(newValue) => {
                    this.props.onYearChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider> */}
            </div>
          </div>
          <Divider
            id={`${parent_id}-Select-Year-divider`}
            style={{ margin: "0" }}
          ></Divider>
          <DialogContent
            id={`${parent_id}-daySlots-dialogContent`}
            style={{ padding: "20px 0" }}
          >
            {this.props.workingday_daySlots?.map((item, i) => (
              // <Div className={classes.content}>
              <Div id={`${parent_id}-${i}-daySlots-div`} style={content}>
                <div
                  id={`${parent_id}-${i}-daySlots-checkbox-div`}
                  style={{ display: "flex" }}
                >
                  <Checkbox
                    id={`${parent_id}-${i}-daySlots-checkbox`}
                    checked={item.checked}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    style={{
                      padding: "0px 10px 0px 0px",
                      color: "#0071F2",
                    }}
                    size="small"
                  />
                  <H6
                    id={`${parent_id}-${i}-daySlots-itemday-h6`}
                    className="pi-title"
                    style={{ width: "50px", margin: 0 }}
                  >
                    {item.day}
                  </H6>
                </div>
                <div
                  id={`${parent_id}-${i}-daySlots-itemday-div`}
                  style={{ flexGrow: 1 }}
                ></div>
                <div>
                  {item.timeSlots?.map((itm, time_i) => (
                    <div
                      id={`${parent_id}-${i}-daySlots-TimePicker-div`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        paddingBottom: "10px",
                      }}
                    >
                      {/* <div style={borderCover}>
                        <TextInput
                          labelStyle={labelStyle}
                          style={borderStyleTime}
                          size="small"
                          placeholder={"08:00"}
                          variant="none"
                          value={itm.fromTime}
                          onChange={(e) =>
                            this.props.handleworkdaytimeChange(
                              e,
                              i,
                              time_i,
                              "fromTime",
                              item
                            )
                          }
                        />
                        <h5 style={entity}> 24Hrs </h5>
                      </div> */}
                      <TimePicker
                        id={`${parent_id}-${i}-daySlots-TimePicker`}
                        value={itm.fromTime}
                        defaultValue={"08:00"}
                        onChange={(date) =>
                          this.props.handleworkdaytimeChange(
                            date,
                            i,
                            time_i,
                            "fromTime",
                            item
                          )
                        }
                      />
                      <div
                        id={`${parent_id}-${i}-daySlots-TimePicker-div`}
                        style={{
                          border: "0.5px solid #E0E0E0",
                          width: "8px",
                          margin: "0 15px",
                          background: "#E0E0E0",
                        }}
                      ></div>
                      {/* <div style={borderCover}>
                        <TextInput
                          labelStyle={labelStyle}
                          style={borderStyleTime}
                          size="small"
                          placeholder={"08:00"}
                          variant="none"
                          value={itm.toTime}
                          onChange={(e) =>
                            this.props.handleworkdaytimeChange(
                              e,
                              i,
                              time_i,
                              "toTime",
                              item
                            )
                          }
                        />
                        <h5 style={entity}> 24Hrs </h5>
                      </div> */}
                      <TimePicker
                        id={`${parent_id}-${i}-daySlots-TimePicker`}
                        value={itm.toTime}
                        defaultValue={"08:00"}
                        onChange={(e) =>
                          this.props.handleworkdaytimeChange(
                            e,
                            i,
                            time_i,
                            "toTime",
                            item
                          )
                        }
                      />
                      {item.timeSlots.length === 0 ||
                      item.timeSlots.length - 1 === time_i ? (
                        <div
                          id={`${parent_id}-${i}-addworkdayfield-div`}
                          style={iconBorder}
                          onClick={(e) =>
                            this.props.addworkdayfield(e, i, itm, time_i)
                          }
                        >
                          +
                        </div>
                      ) : (
                        <div
                          id={`${parent_id}-${i}-deleteworkdayfield-div`}
                          style={iconBorder}
                          onClick={(e) =>
                            this.props.deleteworkdayfield(e, i, time_i)
                          }
                        >
                          <Tooltip
                            id={`${parent_id}-${i}-DeleteIcon-tooltip`}
                            title="Delete"
                            arrow
                          >
                            <img
                              id={`${parent_id}-${i}-DeleteIcon-img`}
                              src={DeleteIcon}
                              width="12px"
                              alt="pic"
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </Div>
            ))}
          </DialogContent>
          <div
            id={`${parent_id}-buttons-div`}
            style={{ margin: "10px 0", textAlign: "center" }}
          >
            {this.props.workday_edit === true ? (
              <Button
                id={`${parent_id}-Update-button`}
                // id={`${parent_id}-buttons-div`}
                variant="contained"
                style={this.props.workdaydisabled() ? btnEdit1 : btnEdit}
                disabled={this.props.workdaydisabled()}
                onClick={() =>
                  this.props.handleworkdayClose("update", "workingdays")
                }
              >
                {"Update"}
              </Button>
            ) : (
              <>
                <Button
                  id={`${parent_id}-cancel-button`}
                  variant="contained"
                  // style={{
                  //   backgroundColor: "#fff",
                  //   marginRight: "15px",
                  //   color: "#000",
                  // }}
                  style={cancelEdit}
                  onClick={() =>
                    this.props.handleworkdayClose("cancel", "workingdays")
                  }
                >
                  Cancel
                </Button>
                <Button
                  id={`${parent_id}-Save-button`}
                  variant="contained"
                  style={this.props.workdaydisabled() ? btnEdit1 : btnEdit}
                  disabled={this.props.workdaydisabled()}
                  onClick={() =>
                    this.props.handleworkdayClose("save", "workingdays")
                  }
                >
                  {"Save"}
                </Button>
              </>
            )}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withAllContexts(WorkingDays);
