import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
// import EditOutlined from "@material-ui/icons/EditOutlined";
// import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { makeStyles } from "@material-ui/core/styles";
// import editdelete from "../../assets/Group 91087.svg";
import EditIcon from "../../assets/icons - Edit.svg";
import DeleteIcon from "../../assets/icons8-trash.svg";

const useStyles = makeStyles((theme) => ({
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  line: {
    borderLeft: "3px solid #e1e1e1",
    marginRight: "5px",
    marginLeft: "5px",
    marginTop: "5px",
    height: "25px",
    // padding: "3px",
    // height: "10px",
  },
  mar30: {
    marginLeft: "57px",
    marginTop: 15,
  },
  mar20: {
    marginTop: 15,
    marginLeft: "15px",
  },
}));

export default function InnerCard(props) {
  const classes = useStyles();
  const { list = [], onEdit = () => null, onDelete = () => null } = props;

  return (
    <div style={{ width: "100%" }}>
      {list.map((val, i) => {
        return (
          <Card
            key={i}
            style={{
              padding: "12px 12px 12px 0px",
              marginTop: "5px",
              marginBottom: "20px",
              borderRadius: "5px",
              background: "#f9f9f9",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={1}>
                    {/*First line */}
                    <div
                      style={{
                        background: "#e0e0e0",
                        width: "20px",
                        padding: "6px 0px 6px 0px",
                        borderRadius: "0px 5px 5px 0px",
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex" }}>
                        <div>{i + 1}</div>
                        <div style={{ marginLeft: "30px" }}>{val.duration_type}</div>
                      </div>
                    </div>
                  </Grid>

                  {/*1*/}
                  <Grid item style={{ marginTop: "2px", marginLeft: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className={classes.grayfont}>
                          {val?.day_type?.label ?? ""}
                        </div>
                        <div style={{ marginTop: "2px" }}>{val.start_time} - {val.end_time}</div>
                      </div>
                      <div className={classes.line}></div>
                    </div>
                  </Grid>

                  {/*2*/}
                  <Grid
                    item
                    style={{ marginTop: "2px", marginLeft: "5px" }}
                  // className={classes.line}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className={classes.grayfont}>
                          Consultation Type
                        </div>
                        <div style={{ marginTop: "2px" }}>{val?.consultation_type?.label ?? ""}</div>
                      </div>
                      <div className={classes.line}></div>
                    </div>
                  </Grid>

                  {/*3*/}
                  {val.slot_duration ? (
                    <Grid
                      item
                      style={{ marginTop: "2px", marginLeft: "5px" }}
                    // className={classes.line}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          <div className={classes.grayfont}>
                            Slot duration
                          </div>
                          <div style={{ marginTop: "2px" }}>{val.slot_duration}</div>
                        </div>
                        <div className={classes.line}></div>
                      </div>
                    </Grid>
                  ):""}

                  {/*4*/}
                  <Grid
                    item
                    style={{ marginTop: "2px", marginLeft: "5px" }}
                  // className={classes.line}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className={classes.grayfont}>
                          Max appointment / {val.duration_type}
                        </div>
                        <div style={{ marginTop: "2px" }}>{val.max_appoinment}</div>
                      </div>
                      <div
                        className={classes.line}
                        style={{
                          height: "25px",
                          marginLeft: "8px",
                          marginTop: "5px",
                        }}
                      ></div>
                    </div>
                  </Grid>

                  {/*5*/}
                  <Grid
                    item
                    style={{ marginTop: "2px", marginLeft: "5px" }}
                  // className={classes.line}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className={classes.grayfont}>
                          Max appointment allowed
                        </div>
                        <div style={{ marginTop: "2px" }}>{val.max_appoinment_allowed}</div>
                      </div>
                      <div className={classes.line}></div>
                    </div>
                  </Grid>

                  {/*6*/}
                  <Grid
                    item
                    style={{ marginTop: "2px", marginLeft: "5px" }}
                  // className={classes.line}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className={classes.grayfont}>
                          Max overbooking
                        </div>
                        <div style={{ marginTop: "2px" }}>{val.max_over_booking}</div>
                      </div>
                      <div className={classes.line}></div>
                    </div>
                  </Grid>

                  {/*7*/}
                  <Grid
                    item
                    style={{ marginTop: "2px", marginLeft: "5px" }}
                  // className={classes.line}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className={classes.grayfont}>
                          Max walkins
                        </div>
                        <div style={{ marginTop: "2px" }}>{val.max_walking}</div>
                      </div>
                      <div className={classes.line}></div>
                    </div>
                  </Grid>

                  {/*8*/}
                  <Grid
                    item
                    style={{
                      marginTop: "2px",
                      marginLeft: "5px",
                      marginRight: "10px",
                    }}
                  // className={classes.line}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <div className={classes.grayfont}>
                          Max waitlist
                        </div>
                        <div style={{ marginTop: "2px" }}>{val.max_wait_list}</div>
                      </div>
                      {/* <div className={classes.line}></div> */}
                    </div>

                    {/* <div
                        style={{
                          marginRight: "8px",
                          marginLeft: "8px",
                        }}
                      ></div> */}
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      display="flex"
                      direction="row"
                      justify="flex-end"
                      alignItems="flex-end"
                      className={!val.slot_duration ? classes.mar30 : classes.mar20}
                    >
                      {/* <img  src={editdelete} alt="edit delete image" /> */}
                      <img
                        src={EditIcon}
                        onClick={() => onEdit(val, i)}
                        width="12px"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                      />
                      <img
                        src={DeleteIcon}
                        width="12px"
                        style={{ cursor: "pointer" }}
                        onClick={() => onDelete(i)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        );
      })}
    </div>
  );
}
