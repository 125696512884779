import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { CommonTable } from "../../components/common";
import { validationForm } from "../../utils";
import { withAllContexts } from "../../HOCs";
import styles from "../../components/common/smartForm/styles";
import { Typography, Button, Grid, Drawer, Avatar } from "@material-ui/core";
import { generateComponent } from "../../components/common/smartForm/formsList";

function Admin(props) {
  const classes_ = styles();
  const dispatch = useDispatch();
  const { parent_id } = props;
  const storeData_ = useSelector((state) => state?.parameterSetupSlice);
  const storeMasterData = useSelector(
    (state) => state?.parameterSetupMasterSlice
  );
  console.log(
    "------------------------",
    storeData_?.parameterSetupAdminRead?.data?.[0]?.total_count
  );
  let entityTypeMater = storeMasterData?.entityTypeMater?.data ?? [];
  let facilityByEntityType = storeMasterData?.facilityByEntityType?.data ?? [];
  let applicationParameter = storeMasterData?.applicationParameter?.data ?? [];

  const [state, setState] = React.useState({
    open: false,
    saveloading: false,
    loading: false,
    _key_id: null,
    moduleid: "",
    type: "",
    list: [
      {
        componet_type: "select",
        options: entityTypeMater ?? [],
        label: "Entity Type",
        state_name: "entityType",
        value: "",
        required: true,
        disabled: true,
      },
      {
        componet_type: "select",
        options: facilityByEntityType ?? [],
        label: "Facility",
        state_name: "facility",
        value: "",
        required: true,
      },
      {
        componet_type: "select",
        options: applicationParameter ?? [],
        label: "Application Parameter",
        state_name: "applicationParameter",
        value: "",
        required: true,
      },
    ],
    states: {
      entityType: entityTypeMater?.[0] ?? "",
      facility: "",
      applicationParameter: "",
      applicationParameterValue: "",
      _id: "",
      id: "",
      _key: "",
      status: true,
    },
    error: {},
  });

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    debugger;
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(
      actions.PARAMETER_SETUP_ADMIN_READ({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      })
    );
  };

  const handleDrawerOpen = async () => {
    setState({
      ...state,
      open: true,
      loading: false,
      _key_id: null,
      type: "",
      moduleid: "",
      list: [
        {
          componet_type: "select",
          options: entityTypeMater ?? [],
          label: "Entity Type",
          state_name: "entityType",
          value: "",
          required: true,
          disabled: true,
        },
        {
          componet_type: "select",
          options: facilityByEntityType ?? [],
          label: "Facility",
          state_name: "facility",
          value: "",
          required: true,
        },
        {
          componet_type: "select",
          options: applicationParameter ?? [],
          label: "Application Parameter",
          state_name: "applicationParameter",
          value: "",
          required: true,
        },
      ],
      states: {
        entityType: entityTypeMater?.[0] ?? "",
        facility: "",
        applicationParameter: "",
        applicationParameterValue: "",
        _id: "",
        id: "",
        _key: "",
        status: true,
      },
      error: {},
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      loading: false,
      _key_id: null,
      type: "",
      moduleid: "",
      list: [
        {
          componet_type: "select",
          options: entityTypeMater ?? [],
          label: "Entity Type",
          state_name: "entityType",
          value: "",
          required: true,
          disabled: true,
        },
        {
          componet_type: "select",
          options: facilityByEntityType ?? [],
          label: "Facility",
          state_name: "facility",
          value: "",
          required: true,
        },
        {
          componet_type: "select",
          options: applicationParameter ?? [],
          label: "Application Parameter",
          state_name: "applicationParameter",
          value: "",
          required: true,
        },
      ],
      states: {
        entityType: entityTypeMater?.[0] ?? "",
        facility: "",
        applicationParameter: "",
        applicationParameterValue: "",
        _id: "",
        id: "",
        _key: "",
        status: true,
      },
      error: {},
    });
  };

  React.useLayoutEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Loading...",
    });

    await dispatch(
      actions.PARAMETER_SETUP_ADMIN_READ({ page: page, perPage: perPage })
    );
    await dispatch(actions.ENTITY_TYPE_MASTER());
    await dispatch(actions.FACILITY_BY_ENTITY_TYPE());
    await dispatch(actions.APPLICATION_PARAMETER());
    setState({
      ...state,
      open: false,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  };

  const handleChange = async (type, val) => {
    const state_ = JSON.parse(JSON.stringify(state));

    if (type === "applicationParameter") {
      state_.states["applicationParameterValue"] = "";
      if (val?.parameterType) {
        if (val?.parameterType === "Boolean") {
          state_.type = val?.parameterType;
          state_.list[3] = {
            componet_type: "checkbox",
            label: val?.label ?? "",
            state_name: "applicationParameterValue",
            value: true,
          };
        } else if (val?.parameterType === "List Box") {
          let a = await dispatch(
            actions.APPLICATION_PARAMETER_SOURCE_MASTER({
              type: val?.parameterTypeSourceMaster,
            })
          );
          state_.type = val?.parameterType;
          state_.list[3] = {
            componet_type: "select",
            options: [],
            label: val?.label ?? "",
            state_name: "applicationParameterValue",
            value: "",
            required: true,
          };
        } else if (val?.parameterType === "Text") {
          state_.type = val?.parameterType;
          state_.list[3] = {
            componet_type: "text",
            label: val?.label ?? "",
            state_name: "applicationParameterValue",
            value: "",
            required: true,
          };
        } else if (val?.parameterType === "Number") {
          state_.type = val?.parameterType;
          state_.list[3] = {
            componet_type: "text",
            label: val?.label ?? "",
            state_name: "applicationParameterValue",
            value: "",
            required: true,
            type: "number",
          };
        }
      }
    }

    state_.states[type] = val ?? "";
    setState({ ...state_ });
  };

  const condition = (val) => {
    if (val?.hidden) {
      return false;
    } else {
      return true;
    }
  };

  const saveParameterAdminSetup = async () => {
    debugger;
    let { error, errorState } = validationForm(state.list, state.states);
    if (!error) {
      let paraVal = "";
      if (state?.type === "List Box") {
        paraVal = state?.states?.applicationParameterValue?.value;
      } else if (state?.type === "Boolean") {
        if (state?.states?.applicationParameterValue === true) {
          paraVal = "Y";
        } else {
          paraVal = "N";
        }
      } else {
        paraVal = state?.states?.applicationParameterValue;
      }
      debugger;
      let data = {
        entitytype: state?.states?.entityType?.value,
        entityid: state?.states?.facility?.value,
        moduleid: "CodingMaster/10264",
        parameterid: state?.states?.applicationParameter?.value,
        parametervalue: paraVal,
        status: true,
        id: 0,
        _key: null,
      };
      if (state?._key_id) {
        data["_key"] = state?.states._key;
        data["id"] = state?.states.id;
        data["status"] = state?.states.status;
      }
      let save_res = await dispatch(actions.PARAMETER_SETUP_ADMIN_UPSERT(data));
      debugger;
      if (save_res?.payload?.data?.error === "Validation Not Passed") {
        if (
          save_res?.payload?.data?.error === "Validation Not Passed" &&
          save_res?.payload?.data?.validation_error?.[0]?.Errormsg ===
            '["entitytype","entityid","parameterid"] fields must be unique'
        ) {
          props.alert.setSnack(
            "error",
            "Entity type, Facility & Application parameter fields must be unique"
          );
        } else {
          props.alert.setSnack("error");
        }
      } else {
        if (state?._key_id) {
          if (save_res?.payload?.data?.Code === 201) {
            handleDrawerClose();
            props.alert.setSnack("update");
          } else {
            props.alert.setSnack("error");
          }
        } else {
          if (save_res?.payload?.data?.Code === 201) {
            handleDrawerClose();
            props.alert.setSnack("success");
          } else {
            props.alert.setSnack("error");
          }
        }
        await fetchInfo();
      }
    } else {
      props.alert.setSnack("mandatory");
      setState({
        ...state,
        error: errorState,
      });
    }
  };

  const handleEdit = async (e, values) => {
    let response = await dispatch(
      actions.PARAMETER_SETUP_ADMIN_SINGLE_READ({ _key: values?._id })
    );

    let constructResponse = response?.payload?.data;
    debugger;
    const entitytype_ =
      constructResponse?.entitytype?._id &&
      constructResponse?.entitytype?.display
        ? {
            value: constructResponse?.entitytype?._id,
            label: constructResponse?.entitytype?.display,
          }
        : {};
    const facilityType_ =
      constructResponse?.entityid?._id && constructResponse?.entityid?.name
        ? {
            value: constructResponse?.entityid?._id,
            label: constructResponse?.entityid?.name,
          }
        : {};
    const appParameter_ =
      constructResponse?.parameterid?._id &&
      constructResponse?.parameterid?.display
        ? {
            value: constructResponse?.parameterid?._id,
            label: constructResponse?.parameterid?.display,
          }
        : {};

    var applicationParameterValue_ = "";
    let list_ = [
      {
        componet_type: "select",
        options: entityTypeMater ?? [],
        label: "Entity Type",
        state_name: "entityType",
        value: entitytype_,
        required: true,
        disabled: true,
      },
      {
        componet_type: "select",
        options: facilityByEntityType ?? [],
        label: "Facility",
        state_name: "facility",
        value: facilityType_,
        required: true,
      },
      {
        componet_type: "select",
        options: applicationParameter ?? [],
        label: "Application Parameter",
        state_name: "applicationParameter",
        value: appParameter_,
        required: true,
      },
    ];
    if (
      constructResponse?.parameterid?.gmconfigvalues?.ParameterType ===
      "Boolean"
    ) {
      applicationParameterValue_ =
        constructResponse?.parametervalue === "N" ? false : true;
      list_[3] = {
        componet_type: "checkbox",
        label: constructResponse?.parameterid?.display ?? "",
        state_name: "applicationParameterValue",
        value: applicationParameterValue_,
      };
    } else if (
      constructResponse?.parameterid?.gmconfigvalues?.ParameterType ===
      "List Box"
    ) {
      await dispatch(
        actions.APPLICATION_PARAMETER_SOURCE_MASTER({
          type: constructResponse?.parameterid?.gmconfigvalues
            ?.PARAMTYPESOURCEMASTER,
        })
      );
      if (
        constructResponse?.parametervalue?._id &&
        constructResponse?.parametervalue?.display
      ) {
        applicationParameterValue_ = {
          value: constructResponse?.parametervalue?._id,
          label: constructResponse?.parametervalue?.display,
        };
      } else {
        applicationParameterValue_ = { value: "", label: "" };
      }
      list_[3] = {
        componet_type: "select",
        options: [],
        label: constructResponse?.parameterid?.display ?? "",
        state_name: "applicationParameterValue",
        value: applicationParameterValue_,
        required: true,
      };
    } else if (
      constructResponse?.parameterid?.gmconfigvalues?.ParameterType === "Text"
    ) {
      applicationParameterValue_ = constructResponse?.parametervalue ?? "";
      list_[3] = {
        componet_type: "text",
        label: constructResponse?.parameterid?.display ?? "",
        state_name: "applicationParameterValue",
        value: applicationParameterValue_,
        required: true,
      };
    } else if (
      constructResponse?.parameterid?.gmconfigvalues?.ParameterType === "Number"
    ) {
      applicationParameterValue_ = constructResponse?.parametervalue ?? "";
      list_[3] = {
        componet_type: "text",
        label: constructResponse?.parameterid?.display ?? "",
        state_name: "applicationParameterValue",
        value: applicationParameterValue_,
        required: true,
        type: "number",
      };
    }
    setState({
      ...state,
      _key_id: values?._id,
      open: true,
      moduleid: constructResponse?.moduleid ?? "",
      type: constructResponse?.parameterid?.gmconfigvalues?.ParameterType ?? "",
      list: list_,
      states: {
        entityType: entitytype_ ?? "",
        facility: facilityType_ ?? "",
        applicationParameter: appParameter_ ?? "",
        applicationParameterValue: applicationParameterValue_ ?? "",
        _id: constructResponse?._id ?? "",
        id: constructResponse?.id ?? "",
        _key: constructResponse?._key ?? "",
        status: constructResponse?.status ?? true,
      },
      error: {},
    });
  };

  return (
    <Card id={`${parent_id}-parent-card`} style={{ borderRadius: "12px" }}>
      <div id={`${parent_id}-parent-div`} style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-Card`}
          style={{ borderRadius: "12px" }}
        >
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-div`}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                id={`${parent_id}-header-sub-div`}
                style={{ marginLeft: "18px" }}
              >
                <p
                  id={`${parent_id}-header-title-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div
                id={`${parent_id}-Add-New-button-div`}
                style={{
                  marginTop: "16px",
                  display: "flex",
                  marginRight: "20px",
                }}
              >
                <button
                  id={`${parent_id}-Add-New-button`}
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
              </div>
            </div>
            {/* table */}
            <div
              id={`${parent_id}-parent-table-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}
            >
              {/* {storeData_?.parameterSetupAdminRead?.loading ? (
                <div
                  id={`${parent_id}-CircularProgress-div`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "66vh",
                  }}
                >
                  <CircularProgress id={`${parent_id}-CircularProgress`} />
                </div>
              ) : ( */}
                <CommonTable
                  parent_id={"CommonTable"}
                  Header={[
                    "S.No",
                    "Entity",
                    "Facility",
                    "Application Parameter",
                    "Parameter Value",
                    "Action",
                  ]}
                  dataList={storeData_?.parameterSetupAdminRead?.data ?? []}
                  rightAlign={["Status", "Action"]}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "entityType" },
                    { type: ["TEXT"], name: "facility" },
                    { type: ["TEXT"], name: "parameterId" },
                    { type: ["TEXT"], name: "parameterValue" },
                    { type: ["EDIT"], name: "", align: "right" },
                  ]}
                  handleEdit={handleEdit}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={
                    storeData_?.parameterSetupAdminRead?.data?.[0]?.total_count
                  }
                  incrementCount={page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />
              {/* )} */}
            </div>
          </div>
        </Card>
      </div>
      <Drawer
        id={`${parent_id}-parent-drawer`}
        className={classes_.drawer}
        anchor="right"
        open={state.open}
        classes={{
          paper: classes_.drawerPaper,
        }}
      >
        {
          <div id={`${parent_id}-parent-drawer-div`}>
            <Avatar
              id={`${parent_id}-close-Avatar`}
              src="/images/close.svg"
              className={classes_.large}
              onClick={() => handleDrawerClose()}
            />
            <div id={`${parent_id}-sub-parent-div`}>
              <div
                id={`${parent_id}-title-div`}
                className={classes_.drawerHead}
              >
                <Typography
                  id={`${parent_id}-title-typography`}
                  className={classes_.headerMain}
                >
                  {state._key_id !== null ? "Edit Admin" : "Add Admin"}
                </Typography>
              </div>

              <Grid
                id={`${parent_id}-button-grid`}
                container
                direction="row"
                style={{ padding: "10px 20px" }}
              >
                <Grid id={`${parent_id}-button-sub-grid`} item xs={12}>
                  {state?.list?.length > 0 &&
                    state?.list?.map((val) => {
                      // let opt = [];
                      let opt =
                        val.state_name === "entityType"
                          ? storeMasterData?.entityTypeMater?.data ?? []
                          : val.state_name === "facility"
                          ? storeMasterData?.facilityByEntityType?.data ?? []
                          : val.state_name === "applicationParameter"
                          ? storeMasterData?.applicationParameter?.data ?? []
                          : val.state_name === "applicationParameterValue"
                          ? storeMasterData?.applicationParameterSourceMaster
                              ?.data ?? []
                          : [];
                      if (condition(val)) {
                        return generateComponent({
                          data: {
                            ...val,
                            options: opt,
                            disabled:
                              val?.state_name === "entityType" ? true : false,
                          },
                          handleChange: handleChange,
                          state: state,
                          classes: classes_,
                        });
                      } else {
                        return false;
                      }
                    })}
                  <div
                    id={`${parent_id}-button-sub-div`}
                    className={classes_.btnPosition}
                  >
                    <Button
                      id={`${parent_id}-Saving-and-Save-button`}
                      disabled={storeData_?.geoGraphicInsert?.loading ?? false}
                      variant={"contained"}
                      color={"primary"}
                      style={{ marginTop: 20 }}
                      size={"small"}
                      contained
                      onClick={() => saveParameterAdminSetup()}
                    >
                      {storeData_?.geoGraphicInsert?.loading
                        ? "Saving..."
                        : "Save"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        }
      </Drawer>
    </Card>
  );
}

export default withAllContexts(Admin);
