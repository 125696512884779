import React from "react";
import {
  Div,
  Paper,
  H6,
  Text,
  Image,
} from "qdm-component-library";
import "../../practitionermaster/addPractotioner/style.css";
import { withAllContexts } from "../../../HOCs";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { AlertProps } from "../../../utils";
import AddNewAchievements from "./addAchievements";
import ShowList from "./showList";
import moment from "moment";
class Awards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Showfilter: this.props?.[this.props.saveto]?.names,
      limited: false,
      Collapse: false,
      addCollapseForm: false,
      contact: [{}]
    };
  }
  IOSSwitch = withStyles((theme) => ({
    root: {
      width: 37,
      height: 21,
      padding: 0,
      margin: theme.spacing(1),
      marginTop: "-2px",
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#0071F2",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
  };
  onChangeState = (key, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  validateCheck = (data) => {
    if (data?.organization?.length > 0 && data?.title?.length > 0 && data?.type?.length > 0) {
      return false
    } else {
      let errors = {
        organization: data?.organization ? false : true,
        title: data?.title ? false : true,
        type: data?.type ? false : true
      }
      this.setState({ ...this.state, erorr: errors });
      return true
    }
  }

  saveAddress = (data) => {
    // contact: [{…}]
    // date: "2021-08-16"
    // notes: "note"
    // organization: "Org"
    // title: "title"
    // type: "type"

    if (this.validateCheck(data)) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      })
      return false;
    }
    let patientData = this.props[this.props.saveto];
    if (this.state?.editindex !== null) {
      patientData.awards[this.state?.editindex] = data;
    } else {
      patientData.awards.push(data);
    }
    patientData[this.props.setTo]({
      ...patientData,
    });
    this.Clear();
  };

  Clear = () => {
    this.setState({
      select_role: null,
      speciality: null,
      select_entity: null,
      entity_name: null,
      select_location: null,
      addCollapseForm: false,
      contact: [{}]
    });
  };

  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };
  OpenAddress = async (e) => {
    this.setState({
      addAddress: e?.currentTarget ?? null,
    });
  };
  handleClear = () => {
    this.setState({
      editadd: {},
      editindex: null,
      addCollapseForm: false
    });
  };

  Collapse(e) {
    this.setState({
      Collapse: !this.state.Collapse,
    });
  }

  addCollapse(e) {
    this.setState({
      addCollapseForm: !this.state.addCollapseForm,
      Collapse: true,
    });
  }

  onedit = async (v, i, e) => {

    this.setState({
      ...this.state,
      ...v,
      editadd: { ...v },
      editindex: i,
    });
    this.addCollapse(e);
  };


  ondelete = (v, i) => {
    let patientData = this.props[this.props.saveto];
    patientData.awards.splice(i, 1);
    patientData[this.props.setTo]({
      ...patientData,
    });
    this.Clear();
  };

  setCustomContact = (name, value, index) => {
     if (name === "mode") {
       this.state.contact[index]["mobile"] = "";
     }

    this.state.contact[index][name] = value;
    this.setState({
      ...this.state,
    })
  }

  addNewContact = () => {
    this.state.contact.push({});
    this.setState({
      ...this.state,
    })
  }

  deleteContect = (index) => {
    let contact = this.state.contact.splice(0, index)
    this.setState({
      ...this.state,
      contact: contact
    })
  }

  render() {
    let { addCollapseForm, Collapse } = this.state;
    const { awards } = this.props[this.props.saveto];
    //const { labelStyle, borderStyle, containedButton } = this.styles;
    const { 
      //onClose,
       parent_id } = this.props;

    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-Acheivement-div`}
            style={{ display: "flex", alignItems: "center" }}>
            <H6
              id={`${parent_id}-Acheivement-title-h6`}
              className="pi-title"
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                flex: 1,
              }}
            >
              Acheivement/Awards
              {awards?.length > 0 && (<>
                {" (" + JSON.stringify(awards?.length) + ")"}
                <Image
                  id={`${parent_id}-Acheivement-image`}
                  style={{ width: 16, height: 16, marginLeft: 5 }}
                  src="images/icons8-ok.svg"
                  alt="tick"
                /></>
              )}
              {/* )} */}
            </H6>
            <Text
              id={`${parent_id}-Add-New-text`}
              inLineStyles={{
                color: "#0071F2",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
              }}
              className="pi-addbtn"
            >{addCollapseForm ? <Text
              className="pi-addbtn"
              style={{ marginRight: 10, color: "gray" }}
            >
              + Add New
            </Text> :
              <Text
                id={`${parent_id}-addCollapse-Add-New-text`}
                className="pi-addbtn"
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  this.handleClear();
                  this.addCollapse(e);
                }}
              >
                + Add New
              </Text>
              }
              {awards?.length > 0 && (
                <React.Fragment>
                  {Collapse ? (
                    //  <i onClick={(e) => this.addCollapse(e)} className="fa fa-angle-down ad-arrow" aria-hidden="true" /> : <i className="fa fa-angle-up ad-arrow" aria-hidden="true" onClick={(e) => this.addCollapse(e)} />}
                    <Image
                      id={`${parent_id}-downArrow-image`}
                      src="images/Group 3873.svg"
                      style={{ width: 20, height: 20 }}
                      alt="downArrow"
                      onClick={(e) => this.Collapse(e)}
                    />
                  ) : (
                    <>
                      <Image
                        id={`${parent_id}-upArrow-image`}
                        style={{ width: 20, height: 20 }}
                        src="images/Group -1.svg"
                        alt="upArrow"
                        onClick={(e) => this.Collapse(e)}
                      />
                    </>
                  )}
                </React.Fragment>
              )}
            </Text>
          </Div>
          {(Collapse && awards?.length > 0) && (
            <Div
              id={`${parent_id}-ShowList-div`}
              style={{ paddingTop: "10px" }} >
              {awards?.map((val, i) => {
                return (
                  <div
                    id={`${parent_id}-ShowList-sub-div`}
                    style={{ marginBottom: 12 }}>
                    <ShowList
                      parent_id={'Awards' + i}
                      index={i + 1}
                      title={val?.organization ?? ""}
                      description={
                        (val?.type?.length > 0 ? val?.type : "") +
                        (val.title?.length > 0 ? ", " + val.title : "") +
                        (val.date?.length > 0 ? ", " + moment(val.date).format("Do' MMM YYYY") : "") +
                        (val.notes?.length > 0 ? ", " + val.notes : "")
                      }
                      ondelete={() => this.ondelete(val, i)}
                      onedit={(e) => this.onedit(val, i, e)}
                    />
                  </div>
                );
              })}
            </Div>
          )}

          {addCollapseForm && (
            <Div
              id={`${parent_id}-AddNewAchievements-div`}
            >
              <AddNewAchievements
                parent_id={'AddNewAchievements'}
                saveAddress={this.saveAddress}
                allMasters={this.props?.allMasters}
                handleClear={this.handleClear}
                index={this.state.editindex}
                editData={this.state.editadd}
                setTo={this.props.setTo}
                saveto={this.props.saveto}
                onclose={() => this.OpenAddress(null)}
              />
            </Div>
          )}
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(Awards);
