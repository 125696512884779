import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
}));

function PerformOrderingDetails(props) {
  const classes = useStyles();
  const { facilityOption, locationTypeOption, sourceLocationOption, handleFormState, orderingFacility, locationType, sourceLocation, parent_id,error } = props;
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-ORDERING-parent-paper`}
        className={classes.root}>
        <Typography
          id={`${parent_id}-ORDERING-label-typography`}
          className={classes.title} variant="h6">
          ORDERING DETAILS
        </Typography>
        <Grid
          id={`${parent_id}-ORDERING-Facility-grid`}
          container className={classes.form}>
          <Grid
            id={`${parent_id}-ORDERING-Facility-sub-grid`}
            md="6" lg="6" className={classes.formParent}>
            <Typography
              id={`${parent_id}-ORDERING-Facility-typography`}
              className={classes.formtitle} variant="body1">
              Ordering Facility <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={"orderingFacility"}
              list={facilityOption ?? []}
              placeholder={"Select"}
              value={orderingFacility}
              onchange={(e, value) => handleFormState("orderingFacility", value)}
              error={error?.orderingFacility}
            />
          </Grid>
          <Grid
            id={`${parent_id}-Source-Location-grid`}
            md="3" lg="3" className={classes.formParent}>
            <Typography
              id={`${parent_id}-Source-Location-typography`}
              className={classes.formtitle} variant="body1">
              Source Location Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={"locationType"}
              list={locationTypeOption ?? []}
              placeholder={"Select"}
              value={locationType}
              onchange={(e, value) => handleFormState("locationType", value)}
               error={error?.locationType}
            />
          </Grid>
          <Grid
            id={`${parent_id}-Source-Location-type-grid`}
            md="3" lg="3" className={classes.formParent}>
            <Typography
              id={`${parent_id}-Source-Location-type-typography`}
              className={classes.formtitle} variant="body1">
              Source Location <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={"sourceLocation"}
              list={sourceLocationOption ?? []}
              placeholder={"Select"}
              value={sourceLocation}
              onchange={(e, value) => handleFormState("sourceLocation", value)}
               error={error?.sourceLocation}
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default PerformOrderingDetails;