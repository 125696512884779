import // makeStyles,
"@material-ui/core";
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Avatar,
  Div,
  Icons,
  Text,
  Divider,
  Button,
  BackgroundImage,
  Paper,
  H6,
  TextInput,
} from "qdm-component-library";
import { routes } from "../../router/routes";
// import { Axios } from "../../function/commonapi";
import axios from "axios";
import config from "../../../src/config";
import { withAllContexts } from "../../HOCs";
import { AlertProps, LocalStorageKeys } from "../../utils";
import Typography from "@material-ui/core/Typography";
import { actions } from "primary_care_admin_binder";
import { useDispatch } from "react-redux";
// import ainqaLogo from "../../../assets/AINQA_Logo-WHITE.svg";
import ainqaLogo from "../../assets/ainqa_logo_black.svg";
import Config from "../../../src/config";
import md5 from "md5";

//   const useStyles = makeStyles((theme) => ({

//   }));

function SignIn(props) {
  // const classes = useStyles();
  const dispatch = useDispatch();

  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      marginBottom: 10,
    },
  };

  const [state, setState] = useState({
    userId: "",
    password: "",
  });
  // const [error, setError] = useState({
  //   userId: "Please enter userid",
  //   password: "Please  enter password",
  // });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    if (localStorage.getItem("payload")) {
      try {
        let basicRoute = [
          "/availabilitymanagement",
          "/repository",
          "/administration",
          "/organization",
          "/consultMaster",
          "/organizationConfig",
          "/practitionermaster",
        ];
        let data = localStorage.getItem("role_data");
        const accessObj =
          JSON.parse(atob(data))?.[localStorage.getItem("role_name")]?.access ||
          [];

        basicRoute = basicRoute.filter((val) => accessObj.indexOf(val) > -1);
        if (basicRoute.length > 0) {
          props.history.push({
            pathname: basicRoute[0],
          });
        } else {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong. Please contact your admin.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
      } catch (e) {
        console.log(e);
        localStorage.removeItem("token");
        localStorage.removeItem("role_name");
        localStorage.removeItem("role_data");
        // localStorage.clear()
      }
    }
  }, []);

  // const handlesendOtp = () => {
  //     props.history.push({
  //         pathname: routes.availabilitymanagement,
  //       })
  //   }
  const validate = () => {
    let isValidated = true;

    if (!state.userId) {
      isValidated = false;
      state.error.userId = "Enter email id";
    }

    if (!state.password) {
      isValidated = false;
      state.error.password = "Enter password";
    }

    setState({ ...state });
    return isValidated;
  };
  const handlesendOtp = async () => {
    // props.history.push({
    //     pathname: routes.availabilitymanagement,
    //   })
    if (!validate()) {
      return false;
    }
    // const readSignIN = await dispatch(actions.SIGN_IN_READ({list :state.userId}))
    // const userType = readSignIN?.payload?.data?.result[0]?.usertype;
    let params = {
      username: state.userId,
      password: state.password,
    };
    // var myHeaders = { "Content-Type": "text/plain" };
    var myHeaders = {
      'tenantid': process.env.REACT_APP_TENANTID, 
      'Content-Type': 'application/json'
    };

    // var myHeaders ={"Content-Type": "application/x-www-form-urlencoded"};
    //     var urlencoded = new URLSearchParams();
    //     urlencoded.append("client_id", config.clientName);
    //     urlencoded.append("client_secret", config.secretKey);
    //     urlencoded.append("username", params.email);
    //     urlencoded.append("password", params.password);
    //     urlencoded.append("grant_type", "password");
    //var encoded = btoa(JSON.stringify(params));
    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   data: encoded,
    //   // redirect: 'follow',
    //   // url:`${config.keylock_url}/auth/realms/${config.realm}/protocol/openid-connect/token`
    //   url: `${config.keylock_url}/api/v1/orgUserSignIn`,
    // };
    let loginconfig = {
      method: 'post',
      url: `${config.IDM}/login_keycloackuser`,
      headers:myHeaders,
      data : params
    };
    let keyClock = await axios(loginconfig)
      // .then((res) => {
      //   localStorage.setItem("token", btoa(JSON.stringify(res?.data)));
      // })
      .catch((error) => {
        setState({
          ...state,
          message: "Invalid Credentials",
        });
      });
    if (!keyClock || keyClock?.data?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: keyClock?.data?.error
          ? keyClock?.data?.error_description
          : "Please try again later",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      localStorage.clear();
      return;
    } else {
      localStorage.setItem("token", btoa(JSON.stringify(keyClock?.data)));
      debugger;
      // let params = {
      //   roleid: process.env.REACT_APP_ROLE_ID,
      //   facilityid: process.env.REACT_APP_FACILITY_ID,
      //   locationid: process.env.REACT_APP_LOCATION_ID,
      //   orgid: process.env.REACT_APP_ORG_ID,
      //   enterpriseid: process.env.REACT_APP_ENTERPRISE_ID,
      //   practitionerid: process.env.REACT_APP_PRACTITIONER_ID,
      //   personid: process.env.REACT_APP_PERSON_ID,
      //   CheckSum: process.env.REACT_APP_CHECK_SUM,
      // };

      let params = {
        roleid: process.env.REACT_APP_ROLE_ID,
        facilityid: process.env.REACT_APP_FACILITY_ID,
        locationid: process.env.REACT_APP_LOCATION_ID,
        orgid: process.env.REACT_APP_ORG_ID,
        enterpriseid: process.env.REACT_APP_ENTERPRISE_ID,
        practitionerid: process.env.REACT_APP_PRACTITIONER_ID,
        personid: process.env.REACT_APP_PERSON_ID,
      }
      params.apikey = Config.TokenApikey;
      let CheckSum = md5(JSON.stringify(params));
      delete params.apikey;
      params.CheckSum = CheckSum;
      debugger
      //SET JWT TOKEN
      let Generated_Token = await dispatch(actions.GET_TOKEN({params}));
      debugger;
      localStorage.setItem("userInfo", Generated_Token?.payload?.data?.Result);
    }
    console.log(actions);
    const userData = await dispatch(
      actions.SEARCH_PRACTIONER({
        email: state.userId,
      })
    );
    if (
      !userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole
        .length > 0
    ) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something went wrong. Please contact your admin.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      localStorage.clear();
      return;
    }
 
    const permissionValue = await dispatch(
      actions.GET_PERMISSION_ROLE_DATA({
        // id: userData?.payload?.data?.[0]?.practitioner?.[0]
        //   ?.PractitionerRole?.[0]?.code?.[0]?.id,
         id: process.env.REACT_APP_PERMISSIONROLEID,
      })
    );
   
     if ((permissionValue?.payload?.data?.length || 0) === 0) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something went wrong. Please contact your admin.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      localStorage.clear();
      return;
    }
    try {
      let Arr = [];
      let pageJson = 
         permissionValue?.payload?.data?.[0]?.PermissionRoleMapping?.permission
           ?.permission?.[0]?.repo_mapping;
        //permissionValue?.payload?.data?.[0]?.repo_mapping;
        
      localStorage.setItem("permissionData", btoa(JSON.stringify(pageJson)));

      let Pages = Object.keys(pageJson)?.filter(
        (v) =>
          v && (pageJson[v]?.permission.read || pageJson[v]?.permission.write)
      );

      let data = Pages?.filter((v) => {
        Arr.push(pageJson[v]?.routes ?? "");
      });
      let extraRoute = [
        "/addpractitioner",
        "/addperson",
        "/addhealthcareservice",
        "/addspeciality",
        "/addlocationmaster",
        "/createappointment",
      ];
      let basicRoute = [
        "/availabilitymanagement",
        "/repository",
        "/administration",
        "/organization",
        "/consultMaster",
        "/organizationConfig",
        "/practitionermaster",
      ];

      basicRoute = basicRoute.filter((val) => Arr.indexOf(val) > -1);
      const permission = {
        // [userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]?.code?.[0]?.coding?.[0]?.display?.toLowerCase()]:
        [userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]?.code?.[0]?.display?.toLowerCase()]:
          {
            access: [...Arr, ...extraRoute],
          },
      };

      localStorage.setItem(
        "role_name",
        // userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]?.code?.[0]?.coding?.[0]?.display?.toLowerCase()
        userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]?.code?.[0]?.display?.toLowerCase()
      );

      localStorage.setItem("role_data", btoa(JSON.stringify(permission)));

      localStorage.setItem("payload", JSON.stringify(keyClock.data));
      if (basicRoute.length > 0) {
        props.history.push({
          pathname: basicRoute[0],
        });
      } else {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong. Please contact your admin.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        localStorage.clear();
      }
    } catch (e) {
      debugger
      console.log(e)
    }
  };

  return (
    <div id={"signin-header-parent-div"}>
      <Container
        id={"signin-header-parent-container"}
        key={"0"}
        name="container"
        fluid={true}
        style={{
          backgroundColor: "#e8e8e8",
          height: "100vh",
          padding: "0px",
        }}
      >
        <Row
          id={"signin-header-parent-row"}
          key={"0"}
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          spacing="2"
          style={{ backgroundColor: "#ffffff", height: "" }}
        >
          <Col
            id={"signin-header-parent-col"}
            key={"0"}
            name="qdm-col-12"
            alignItems="center"
            direction="row"
            justify="space-between"
            textAlign="left"
            lg="12"
            xs="12"
            md="12"
            sm="12"
            style={{
              height: "55px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              id={"signin-header-avatar-div"}
              style={{ display: "flex", alignItems: "baseline" }}
            >
              <Avatar
                id={"signin-header-apollo-logo-avatar"}
                key={"0"}
                variant="rounded"
                letter="Logo"
                src={ainqaLogo}
                // src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c5/Apollo_Hospitals_Logo.svg/800px-Apollo_Hospitals_Logo.svg.png"
                alt="Logo"
                style={{
                  borderColor: "#ffffff",
                  backgroundColor: "#ffffff",
                  padding: "10px 13px",
                  width: "134px",
                }}
              />
              <Typography
                id={"signin-header-apollo-version-typography"}
                style={{
                  fontSize: "10px",
                  fontFamily: "poppin",
                  color: "#9b9b9b",
                }}
              >
                {localStorage.getItem(LocalStorageKeys.version)
                  ? "version:" + localStorage.getItem(LocalStorageKeys.version)
                  : " "}
              </Typography>
            </div>
            <Div
              id={"signin-header-rightside-div"}
              key={"1"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Icons
                id={"signin-header-phone-Icon"}
                key={"0"}
                fontIcon="phone"
                ariaHidden="true"
                size="small"
                className=""
                style={{ color: "#6F6F6F", margin: "10px" }}
              />

              <Text
                id={"signin-header-phone-text"}
                className="pc_regular"
                key={"1"}
                name="+91 9821315141 , +91 9551482612"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#6F6F6F",
                }}
              />

              <Divider
                id={"signin-header-phone-divider"}
                key={"2"}
                className=""
                inLineStyles="[object Object]"
                label=""
                borderColor=""
                textColor=""
                orientation="vertical"
                variant="middle"
                style={{ height: "24px", marginTop: "6px" }}
              />

              <Button
                id={"signin-header-Support-button"}
                className="pc_medium"
                key={"3"}
                type="button"
                variant="text"
                name="Support"
                style={{
                  borderColor: "#0071F2",
                  borderRadius: "8px",
                  color: "#0071F2",
                  height: "35px",
                  lineHeight: 0,
                  fontSize: 14,
                  minWidth: 88,
                }}
              />
            </Div>
          </Col>
        </Row>

        <Col
          id={"signin-content-parent-col"}
          key={"1"}
          name="qdm-col-12"
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          textAlign="left"
          lg="12"
          xs="12"
          md="12"
          sm="12"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 55px)",
            backgroundColor: "#F0F7FF",
          }}
        >
          <BackgroundImage
            id={"signin-content-parent-backgroundimage"}
            key={"0"}
            url="https://i.ibb.co/sm1YmHk/Mask-Group-71-2x.png"
            alt="Mask-Group-71-2x"
            backgroundPosition="0% 0%"
            backgroundPositionX="left"
            backgroundPositionY="top"
            backgroundRepeat="no-repeat"
            backgroundRepeatX="no-repeat"
            backgroundRepeatY="no-repeat"
            backgroundAttachment="scroll"
            backgroundSize="contain"
            backgroundOrigin="padding-box"
            inLineStyles="[object Object]"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 55px)",
            }}
          >
            <Paper
              id={"signin-content-parent-paper"}
              key={"0"}
              children="Paper"
              variant="elevation"
              elevation="1"
              style={{
                width: "350px",
                // height: "400px",
                backgroundColor: "#ffffff",
                borderRadius: "16px",
                padding: "32px",
                boxShadow: "0px 8px 69px #0000001A",
                display: "",
              }}
            >
              <H6
                id={"signin-content-Sign-h6"}
                key={"0"}
                name="Sign In"
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  color: "#101010",
                }}
                inLineStyles={{ fontFamily: "pc_semibold !important" }}
              />

              <H6
                id={"signin-content-valid-User-h6"}
                className="pc_regular"
                key={"1"}
                name="Sign In with Valid User Id"
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  textAlign: "left",
                  marginBottom: "30px",
                  marginTop: "10px",
                }}
              />

              <TextInput
                id={"signin-content-userId-textinput"}
                isRequired={true}
                label="User Id"
                placeholder=""
                labelStyle={styles.labelStyle}
                style={styles.borderStyle}
                hoverColor="#0071F2"
                value={state.userId}
                onChange={(e) => changeState("userId", e.target.value)}
              />

              <TextInput
                id={"signin-content-Password-textinput"}
                isRequired={true}
                type="password"
                label="Password"
                placeholder=""
                labelStyle={styles.labelStyle}
                style={styles.borderStyle}
                hoverColor="#0071F2"
                value={state.password}
                onChange={(e) => changeState("password", e.target.value)}
              />
              {state.error && (
                <H6
                  id={"signin-content-Password-error-textinput"}
                  name={state.error}
                  style={{
                    fontSize: "12px",
                    color: "red",
                    textAlign: "left",
                  }}
                />
              )}
              <Button
                id={"signin-content-Sign-In-button"}
                className="pc_medium"
                key={"3"}
                onClick={() => handlesendOtp()}
                // onClick={() => props.history.push({
                //           pathname: routes.availabilitymanagement,
                //         })}
                disabled={props.oraganizationSignin?.loading}
                type="button"
                variant="outlined"
                name={
                  props.oraganizationSignin?.loading ? "Sign In..." : "Sign In"
                }
                style={{
                  margin: "",
                  marginTop: "25px",
                  width: "100%",
                  backgroundColor: "#0071F2",
                  borderColor: "#0071F2",
                  borderRadius: "8px",
                  fontSize: "14px",
                  height: "40px",
                }}
              />
            </Paper>
          </BackgroundImage>
        </Col>
      </Container>
    </div>
  );
}

export default withAllContexts(SignIn);
