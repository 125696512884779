import React from "react";
import { CommonTable, OverlayCompt } from "../../../../../components";
import  AddNewFrequencyMaster  from "./addNewFrequencyMaster";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

 const FrequencyMaster = (props) => {
  const dispatch = useDispatch();
  const resourceFREQUENCY = useSelector((state) => state.drugFrequencySlice);
  const [key, setkey] = React.useState(null);

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.DRUG_FREQUENCY_READ({ page: (currentPage - 1) * PerPage, perPage: perPage }));

  };

  React.useLayoutEffect(() => {
    const getdata = async () => {
      await dispatch(actions.DRUG_FREQUENCY_READ({ page: page, perPage: perPage}));
    };
    getdata();
  }, []);
  React.useEffect(() => { props?.currentTab("frequencyMaster"); }, []);
  const handleEdit = (e, value) => {
    
    setkey(value.key);
    props.changeState("open", true);
    props.changeState("openIndex", 8);
  };
  const checkBox = async (e, values) => {
    let id = values.key;
    let checked = e.target.checked;
    await dispatch(
      actions.UOM_DRUG_FREQ_STATUS({
        key: id,
        data: checked,
      })
    );

    await dispatch(actions.DRUG_FREQUENCY_READ({ page: page, perPage: perPage}));
    props.alert.setSnack("update");
  };
  const FromClose = () => {
    setkey(null);
    props?.changeState("open", false);
  };
  return (
    <div>
      <CommonTable
        style={{ maxHeight: "calc(100vh - 245px)" }}
        parent_id={"frequency-master"}
        handleEdit={handleEdit}
        handleCheckBox={checkBox}
        Header={[
          "S.No",
          "Freq Code",
          "Short Description",
          "Long Description",
          "Status",
          "Action",
        ]}
        dataList={
          resourceFREQUENCY?.drug_frequncy_list?.data
            ? resourceFREQUENCY?.drug_frequncy_list?.data
            : ""
        }
        tableData={[
          { type: ["INCRIMENT"], name: "" },
          { type: ["TEXT"], name: "code" },
          { type: ["TEXT"], name: "shortDesc" },
          { type: ["TEXT"], name: "longDesc" },
          { type: ["CHECKBOX"], name: "status" },
          { type: ["EDIT"], name: "" },
        ]}
        handlepageChange={(currentPage, PerPage) =>
          handlepageChange(currentPage, PerPage)
        }
        TableCount={resourceFREQUENCY?.drug_frequncy_list?.data?.[0]?.total_count}
        incrementCount={page}
        showPagination={true}
        rowsPerPageOptions={[
          { label: "10 Rows", value: 10 },
          { label: "50 Rows", value: 50 },
          { label: "100 Rows", value: 100 },
        ]}
      />
      <OverlayCompt
        parent_id={"frequency-master"}
        open={props?.open && props?.openIndex === 8}
        children={
          <React.Fragment>
            {props?.open && props?.openIndex === 8 && (
              <AddNewFrequencyMaster
                parent_id={"frequency-master"}
                data={key} closeForm={FromClose} />

            )}
          </React.Fragment>
        }
      />
    </div>
  );
};

export default withAllContexts(FrequencyMaster);