import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
import { actions } from "primary_care_admin_binder";
import IconButton from "@material-ui/core/IconButton";
import { withAllContexts, withHoverDrawer } from "../../HOCs";
import backarrow from "../../assets/icons8-expand-arrow.svg";
import CreateAppointmentTypes from "./createAppointmentTypes/CreateAppointmentTypes";
import CreatePatientCategory from "./createAppointmentTypes/CreatePatientCategory";
import ResourceDefinitionCard from "./createAppointmentTypes/resourceDefinition";
import SpecialAppoitmentInstructions from "./createAppointmentTypes/specialAppoitmentInstructions";
import StatusBar from "./createAppointmentTypes/StatusBar";

import GeneralAppointmentCard from "./generalappointmentcard";
import Check from "../../screens/Check";
import GeneralAppoitmentInstructions from "./createAppointmentTypes/GeneralAppointmentIns";
import CreateServiceDefinition from "./createAppointmentTypes/serviceDefinition";
import { GenerateFormVersion2 } from "../common";

const useStyles = makeStyles((theme) => ({
  modalStructure: {
    padding: "18px",
    width: "86%",
  },
}));
const data = [
  {
    id: "1",
    entity: "AINQA - INDIA",
    reason: "",
    effectiveFrom: "",
    effectiveTo: "",
    status: "true",
    children: [
      {
        id: "1.1",
        entity: "Apollo Multi-Speciality, Tamilnadu",
        reason: "",
        effectiveFrom: "",
        effectiveTo: "",
        status: "true",
        children: [
          {
            id: "1.1.1",
            entity: "Apollo Multi-Speciality, Madurai",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
          {
            id: "5",
            entity: "Apollo Multi-Speciality, Chennai",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
          {
            id: "6",
            entity: "Apollo Multi-Speciality, Trichy",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
        ],
      },
      {
        id: "1.2",
        entity: "Apollo Multi-Speciality, Kerala",
        reason: "",
        effectiveFrom: "",
        effectiveTo: "",
        status: "true",
        children: [
          {
            id: "1.2.1",
            entity: "Apollo Multi-Speciality, Kollam",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
          {
            id: "9",
            entity: "Apollo Multi-Speciality, Idukki",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
        ],
      },
    ],
  },
];

const AddAppointmentType = (props) => {
  // const [state, setState] = useState({});
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [treeListData, setTreeListData] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const Controller = location.IsEdit;
  const [finalValue, setFinalValue] = React.useState({
    id: "",
    code: "",
    shortdesc: "",
    longdesc: "",
    servicetype: [],
    specialapptinstructions: [],
    resourcedefinition: [],
    generalappinstruction: [],
    status: true,
    SourceSystemID: "string",
    SourceSystemRefId: "string",
    servicecategory: [],
    serviccode: [],
    patientcategory: [],
    statusbyentity: values,
  });
  console.log(finalValue, "valuesjjdhhf");
  const EntityMasters = useSelector(
    (state) => state?.holidaySlice?.entity_master?.data
  );
  const backfun = () => {
    history.push({
      pathname: routes.availabilitymanagement,
      state: { index: 3 },
    });
  };
  const [steperSave, setSteperSave] = React.useState(false);
  const SingleReadData = useSelector(
    (state) =>
      state.resourceByAppointmentTypesSlice.res_appointment_single_read.data
  );
  const saveFuntion = () => {
    // setSteperSave(true);
    // if (steperSave === false) {
    // }
    if (Controller) {
      const App_Type_Add = dispatch(
        actions.RES_UPDATE_APPOINTMENT({
          data: SingleReadData?.result,
          final: finalValue,
        })
      );
    } else {
      dispatch(actions.RES_UPSERT_APPOINTMENT(finalValue));
    }
    // console.log(App_Type_Add?._result?._result?.error, "App_Type_Add");

    props.alert.setSnack("success");
    history.goBack();
  };
  const [state, setState] = React.useState({
    code: "11",
    longdesc: "test",
    shortdesc: "tedtt",
    patientcategory: [],
    // Instructioncode: "",
    // Description: "",
    resourcedefinition: [],
    ResourceRole: [],
    ResourceDepartment: [],
    ResourceName: [],
    Resourse: [],
    SpecialAppointments: [],
    curreteditresourceindex: "",
    CriteriaDoc: [],
  });
  console.log(state.patientcategory, "slkstate");
  const PatientCategory = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_patient_category_list_doc
        ?.data
  );
  const ResourceDefinitionDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_resource_type_list_doc?.data
  );
  const ResourceRoleDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_resource_role_list_doc?.data
  );
  const ResourceDepartmentDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_resource_department_list_doc
        ?.data
  );
  const ResourceNametDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_resource_name_list_doc?.data
  );
  const ResourceInstructionCodeDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_special_instruction_list_doc
        ?.data
  );
  const appointmantAdd = useSelector(
    (state) => state?.resourceByAppointmentTypesSlice?.insert_appointment_add
  );

  const Criteria = useSelector(
    (state) =>
      state.resourceByAppointmentTypesSlice?.res_criteria_instruction?.data
  );
  console.log(PatientCategory, "ResourceInstructionCodeDrop");
  useEffect(() => {
    if (Controller) {
      setSteperSave(true);
    } else {
      setSteperSave(false);
    }
  }, [Controller]);

  React.useEffect(() => {
    const arr = [];
    SingleReadData?.result?.[0]?.resourcedefinition?.map((item) => {
      arr.push({
        type: {
          value: item?.resourcetype?._id,
          label: item?.resourcetype?.display,
        },
        role: {
          value: item?.resourcerole._id,
          label: item?.resourcerole.display,
          title: "PRACTROLE",
        },
        department: [
          {
            value: "CodingMaster/10177",
            label: "Radiology Department",
          },
        ],
        namelist: [
          {
            label: "sum",
            value: "cro",
          },
        ],
      });
    });
    const arr1 = [];
    SingleReadData?.result?.[0]?.patientcategory?.map((item) => {
      console.log(item, "dkjsldkfjslkjt");
      arr1.push({
        label: item?.display,
        value: item?._id,
      });
    });
    if (Controller) {
      setFinalValue({
        // id: "",
        code: SingleReadData?.result?.[0]?.code,
        shortdesc: SingleReadData?.result?.[0]?.shortdesc,
        longdesc: SingleReadData?.result?.[0]?.longdesc,
        servicetype: [],
        specialapptinstructions: [],
        resourcedefinition: arr,
        generalappinstruction: [],
        status: true,
        SourceSystemID: "string",
        SourceSystemRefId: "string",
        servicecategory: [],
        serviccode: [],
        patientcategory: SingleReadData?.result?.[0]?.patientcategory,
        statusbyentity: [],
      });
      setState({
        ...state,
        Resourse: arr,
        Patientcategory: arr1,
      });
    }
  }, [SingleReadData]);
  const patientcategoryData = {};
  const handleshowPopup = () => {
    setState({
      ...state,
      open: true,
      isAdd: true,
    });
  };
  React.useEffect(() => {
    // let main = [];
    // let arr = [];
    // main.push({
    //   entity: "AINQA - INDIA",
    //   id: "1",
    //   reason: "",
    //   // status: "true",
    //   children: arr,
    // });
    // EntityMasters?.map((item) => {
    //   let subArr = [];
    //   console.log(item, "lslssdrts");
    //   if (item?.Organization?.length) {
    //     item?.Organization?.map((value) => {
    //       return subArr.push({
    //         entity: value?.name,
    //         id: value?._id,
    //         // effectiveFrom: "test",
    //         // effectiveTo: "test1",
    //         reason: "",
    //         check: false,
    //         // status: value?.activestatus,
    //         _key: value?._key,
    //       });
    //     });
    //   }
    //   return arr.push({
    //     entity: item?.name,
    //     id: item?._id,
    //     // effectiveFrom: "test",
    //     // effectiveTo: "test1",
    //     check: false,
    //     // status: item?.activestatus,
    //     children: subArr,
    //     _key: item?._key,
    //   });
    // });
    // setTreeListData(main);
    const constructFacility = (data) => {
      // debugger
      let resultData__ = [];
      if (Array.isArray(data) && data?.length > 0) {
        data.map((val, i) => {
          resultData__.push({
            OrgType: "Facility",
            id: val?._id ?? "",
            effectiveFrom: "20-06-2021",
            effectiveTo: "20-12-2021",
            entity: val?.name ?? "",
            reason: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.reason ?? "",
            status: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.status ?? "",
          });
        });
      }
      return resultData__;
    };
    const constructOrganization = (data) => {
      // debugger
      let resultData_ = [];
      if (Array.isArray(data) && data?.length > 0) {
        data.map((val, i) => {
          resultData_.push({
            OrgType: "Organization",
            id: val?._id ?? "",
            entity: val?.name ?? "",
            effectiveFrom: "20-06-2021",
            effectiveTo: "20-12-2021",
            reason: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.reason ?? "",
            status: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.status ?? "",
            children:
              Array.isArray(val?.facility) && val?.facility?.length > 0
                ? constructFacility(val?.facility)
                : [],
          });
        });
      }
      return resultData_;
    };

    const ConstructJSONNew = (data) => {
      debugger;
      let resultData = [];
      if (Array.isArray(data) && data?.length > 0) {
        data.map((val, i) => {
          resultData.push({
            OrgType: "Enterprise",
            id: val?._id ?? "",
            entity: val?.name ?? "",
            effectiveFrom: "01-03-2021",
            effectiveTo: "24-09-2021",
            reason: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.reason ?? "",
            status: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.status ?? "",
            children:
              Array.isArray(val?.Organization) && val?.Organization?.length > 0
                ? constructOrganization(val?.Organization)
                : [],
          });
        });
      }
      debugger;
      return resultData;
    };

    const result = ConstructJSONNew(EntityMasters);
    setTreeListData(result);
  }, [EntityMasters]);
  const savefunction = () => {
    let data = {
      type: state.ResourceTypeList,
      role: state.ResourceRole,
      department: state.ResourceDepartment,
      namelist: state.ResourceName,
    };
    console.log("ssss", { ...state, Resourse: [...state.Resourse, data] });
    // setState({ ...state, Resourse: [...state.Resourse, data] });
    setState({
      ...state,
      Resourse: [...state.Resourse, data],
      ResourceName: [],
      ResourceDepartment: [],
      ResourceRole: [],
      ResourceTypeList: [],
    });
  };
  const specialSave = () => {
    let information = {
      code: state.Instructioncode,
      desc: state.Description,
      criteria: state.CriteriaDoc,
    };
    console.log("loppppp", {
      ...state,
      SpecialAppointments: [...state.SpecialAppointments, information],
    });
    setState({
      ...state,
      SpecialAppointments: [...state.SpecialAppointments, information],
      Instructioncode: "",
      CriteriaDoc: [],
    });
  };
  const handleshow = () => {
    setSteperSave(false);
  };
  const handleCancel = () => {
    setState({
      ...state,
      open: false,
    });
    setSteperSave(true);
  };
  const handleUpdate = () => {
    let Allresource = state.Resourse;
    Allresource[state.curreteditresourceindex].namelist = state.ResourceName;
    Allresource[state.curreteditresourceindex].department =
      state.ResourceDepartment;
    Allresource[state.curreteditresourceindex].role = state.ResourceRole;
    Allresource[state.curreteditresourceindex].type = state.ResourceTypeList;
    console.log({ Allresource });
    setState({
      ...state,
      Resourse: Allresource,
      curreteditresourceindex: "",
      ResourceName: [],
      ResourceDepartment: [],
      ResourceRole: [],
      ResourceTypeList: [],
    });
  };
  const handlechange = (key, value, Autocomp, order) => {
    if (key === "Patientcategory") {
      const permittedValues = order.map((val) => {
        return val.value;
      });
      console.log(permittedValues, "permittedValues");
      setFinalValue({
        ...finalValue,
        patientcategory: permittedValues,
      });
    }

    // if (Object.keys(value)?.length > 0) {
    //   setState({ ...state, [name]: value });
    // } else if (Array.isArray(value)) {
    //   setState({ ...state, [name]: [...state[name], value] });
    // } else {
    //   setState({ ...state, [name]: value });
    // }
    // setState({ ...state, [name]: value });
    // switch (order) {
    //   case "PRACTROLE":
    //     dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC({ title: value.type }));
    //     break;

    //   case "DEVICEROLE":
    //     dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC({ title: value.type }));
    //     break;

    //   case "LOCATIONROLE":
    //     dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC({ title: value.type }));
    // }
    if (typeof key === "object") {
      setState({
        ...state,
        ...key,
      });
    } else {
      if (typeof value === "string") {
        setState({ ...state, [key]: value });
      } else if (typeof value === "object") {
        if (Array.isArray(value)) {
          if (Autocomp) {
            setState({
              ...state,
              [key]: value?.[0] ?? null,
            });
          } else {
            setState({
              ...state,
              [key]: [...state?.[key], ...value],
            });
          }
        } else {
          setState({
            ...state,
            [key]: { ...state?.[key], ...value },
          });
        }
      }
    }
  };
  const getMastersData = async () => {
    await dispatch(actions.RES_PATIENT_CATEGORY_LIST_DOC());
    await dispatch(actions.ENTITY_MASTERS());
    await dispatch(actions.RES_RESOURCE_TYPE_LIST_DOC());
    await dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC());
    await dispatch(actions.RES_RESOURCE_DEPARTMENT_LIST_DOC());
    await dispatch(actions.RES_RESOURCE_NAME_LIST_DOC());
    await dispatch(actions.RES_SPECIAL_INSTRUCTION_LIST_DOC());
    await dispatch(actions.RES_UPDATE_APPOINTMENT());
    await dispatch(actions.RES_CRITERIA_INSTRUVTION());
  };
  React.useEffect(() => {
    getMastersData();
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#F6F6F6" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
            padding: "8px 16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={backfun}
              style={{
                backgroundColor: "#DCDCDC",
                width: 28,
                height: 28,
                marginRight: 12,
              }}
            >
              <img
                src={backarrow}
                alt="icon"
                style={{ transform: "rotate(90deg)" }}
              />
            </IconButton>
            <p>
              {history?.location?.state?.key
                ? "Edit Appointment type"
                : "Create Appointment Types"}
            </p>
          </div>
        </div>
        <hr style={{ marginTop: "0px" }} />
      </div>
      <CreateAppointmentTypes
        setFinalValue={setFinalValue}
        finalValue={finalValue}
      />
      <CreatePatientCategory
        SelectorPatientAuto={PatientCategory}
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        handlechange={handlechange}
        state={state}
      />
      <CreateServiceDefinition
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        SingleReadData={SingleReadData}
        Controller={Controller}
      />

      <ResourceDefinitionCard
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        ResourceTypeAuto={ResourceDefinitionDrop}
        ResourceRoleAuto={ResourceRoleDrop}
        ResourceDepartmentAuto={ResourceDepartmentDrop}
        ResourceNameAuto={ResourceNametDrop}
        handlechange={handlechange}
        savefunction={savefunction}
        state={state}
        setState={setState}
      />
      <GeneralAppoitmentInstructions
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        SingleReadData={SingleReadData}
      />
      {/* <Check /> */}
      <SpecialAppoitmentInstructions
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        handlechange={handlechange}
        state={state}
        specialSave={specialSave}
        InstructionCodeAuto={ResourceInstructionCodeDrop}
        criteriaAuto={Criteria}
      />
      <StatusBar handleshow={handleshowPopup} Controller={Controller} />
      <GenerateFormVersion2
        parent_id={"appointmenttype"}
        setValues={setValues}
        // handleshow={handleshow}
        values={values}
        steperSave={steperSave}
        setSteperSave={setSteperSave}
        handleshow={handleshow}
        // steperSave={steperSave}
        // loading={saveloading}
        handlecancel={() => handleCancel()}
        open={state.open}
        clicked={true}
        isAdd={state.isAdd}
        Thdata={[
          {
            Title: "Entity",
            Size: 7,
          },
          {
            Title: "EffectiveFrom",
            Size: 2,
          },
          {
            Title: "EffectiveTo",
            Size: 2,
          },
        ]}
        save={() =>
          setState({
            ...state,
            open: false,
          })
        }
        // handlecancel={handleDrawerClose}
        treeListData={treeListData}
        header="Add New Holiday"
        modalStructure={classes.modalStructure}
        json={[
          {
            componet_type: "typography",
            value: (
              <div>
                Reason for Holiday:
                <span style={{ fontWeight: "bold" }}>Test</span>
              </div>
            ),

            grid: {
              md: 2.5,
            },
            // state_name: "holiday_reason",
            // value: "",
            // required: true,
          },
          {
            componet_type: "typography",
            value: (
              <div>
                Reason for Holiday:
                <span style={{ fontWeight: "bold" }}>Test</span>
              </div>
            ),
            grid: {
              md: 2.5,
            },
            // state_name: "holiday_reason",
            // value: "",
            // required: true,
          },
          {
            componet_type: "typography",
            value: (
              <div>
                Reason for Holiday:
                <span style={{ fontWeight: "bold" }}>Test</span>
              </div>
            ),
            grid: {
              md: 2.5,
            },
            // state_name: "holiday_reason",
            // value: "",
            // required: true,
          },
          {
            componet_type: "typography",
            value: (
              <div>
                Reason for Holiday:
                <span style={{ fontWeight: "bold" }}>Test</span>
              </div>
            ),
            grid: {
              md: 2.5,
            },
            // state_name: "holiday_reason",
            // value: "",
            // required: true,
          },
          {
            componet_type: "typography",
            value: (
              <div>
                Reason for Holiday:
                <span style={{ fontWeight: "bold" }}>Test</span>
              </div>
            ),
            grid: {
              md: 2,
            },
            // state_name: "holiday_reason",
            // value: "",
            // required: true,
          },
          steperSave && {
            componet_type: "entity",
            label: "Add Applicable Entity",
            grid: {
              md: 1,
            },
          },

          // {
          //   componet_type: "date",
          //   label: "Date",
          //   state_name: "date",
          //   value: state?.edit_data?.date ?? "",
          //   dateFormat: "dd-MM-yyyy",
          //   required: true,
          // },
          // {
          //   componet_type: "checkbox",
          //   label: "Repeat Every Year",
          //   state_name: "repeat",
          //   value: state?.edit_data?.repeat ?? false,
          // },
        ]}
        nocancel
      />
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          float: "right",
          marginRight: "4%",
          paddingBottom: "30px",
        }}
      >
        <Button
          // variant="contained"
          variant="outlined"
          size="medium"
          style={{ marginRight: "14px" }}
          onClick={() => backfun()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => saveFuntion()}
        >
          {/* {history?.location?.state?.key ? "Edit" : "Save"} */}
          Save
        </Button>
      </div>
    </>
  );
};
export default withHoverDrawer(withAllContexts(AddAppointmentType));
