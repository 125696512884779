import React from "react";
import { Paper, Typography, makeStyles, Grid, TextField, Button } from "@material-ui/core";
import { CommonTable, GenerateForm } from "../../../components";
import { useSelector } from "react-redux";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        margin: "10px 6px 10px 6px"

    },
    addBtn: {
        float: "right",
        fontSize: "14px",
        fontWeight: 600
    },
    title: {
        fontFamily: "poppinsemibold",
        display: "inline",
        fontSize: "12px",
    },
    form: {
        paddingTop: 15
    },
}));

function ApplicableFacilityDetail(props) {
    const [state, setState] = React.useState({
        open: false, saveloading: false, edit_data: false, editIndex: null
    })
    const unitUomList = useSelector((state) => state?.ageRangeMasterSlice?.unit_uom_list?.data);
    const classes = useStyles();
    const { applicableFacilities, handleFormState, parent_id } = props;

    const handleDrawerClose = () => {
        setState({ ...state, open: false, editIndex: null })
    }
    const saveAgeRange = (data) => {
        //alert(moment(data.effectiveTo).format("DD MMM,YYYY"))
        let list = JSON.parse(JSON.stringify(applicableFacilities));
        let storedData = {
            facilityName: data.facilityName,
            effectiveFrom: moment(data.effectiveFrom).format("DD MMM,YYYY"),
            effectiveTo: moment(data.effectiveTo).format("DD MMM,YYYY"),
          };
        if (state.editIndex || state.editIndex === 0) {
            list[state.editIndex] = {
                ...list[state.editIndex],
                ...storedData
            }
        } else {
            list.push(storedData)
        }
        handleFormState("applicableFacilities", list);
        handleDrawerClose()
    }
    const handleDelete = (e, data, index) => {
        let list = JSON.parse(JSON.stringify(applicableFacilities));
        list.splice(index, 1)
        handleFormState("applicableFacilities", list);
    }

    return (
        <React.Fragment>
            <Paper
                id={`${parent_id}-parent-paper`}
                className={classes.root}>
                <Typography
                    id={`${parent_id}-AGE-CATEGORY-typography`}
                    className={classes.title} variant="h6">APPLICABLE FACILITIES</Typography>
                <Button
                    id={`${parent_id}-Add-New-button`}
                    className={classes.addBtn} onClick={() => setState({ ...state, open: true })} color="primary" >+ Add New</Button>
                <Grid
                    id={`${parent_id}-table-grid`}
                    container className={classes.form}>
                    <CommonTable
                        parent_id={"category-details"}
                        handleEdit={(e, data, index) => setState({ ...state, editIndex: index, open: true })}
                        handleDelete={handleDelete}
                        rightAlign={["Action"]}
                        Header={["Facility Name", "Effective From", "Effective To", "Action"]}
                        dataList={applicableFacilities}
                        tableData={[
                            { type: ["TEXT"], name: "facilityName", optionLabel: "label"},
                            { type: ["TEXT"], name: "effectiveFrom" },
                            { type: ["TEXT"], name: "effectiveTo"},
                            { type: ["EDIT"], name: "", align: "right" }
                        ]}
                        // condition={condition}
                    />
                </Grid>
                <GenerateForm
                    parent_id={"category-details"}
                    nocancel
                    loading={state.saveloading}
                    open={state.open}
                    save={saveAgeRange}
                    handlecancel={handleDrawerClose}
                    header={state.editIndex !== null ? "Edit Applicable Facility" : 'Add Applicable Facility'}
                    json={[{
                        componet_type: "select",
                        options: props?.facilityMasterData ?? [],
                        label: "Facility Name",
                        state_name: "facilityName",
                        value: applicableFacilities?.[state.editIndex]?.facilityName ?? "",
                        required: true
                    },
                    {
                        componet_type: "date",
                        label: "Effective From",
                        state_name: "effectiveFrom",
                        value: applicableFacilities?.[state.editIndex]?.effectiveFrom ? 
                        new Date(applicableFacilities?.[state.editIndex]?.effectiveFrom)
                        : "",
                        dateFormat:'dd-MM-yyyy',
                        required:true
                    },
                    {
                        componet_type: "date",
                        label: "Effective To",
                        state_name: "effectiveTo",
                        value: applicableFacilities?.[state.editIndex]?.effectiveTo ? 
                        new Date(applicableFacilities?.[state.editIndex]?.effectiveTo)
                        : "",
                        dateFormat:'dd-MM-yyyy',
                        //required:true
                      },
                    ]}
                />
            </Paper>
        </React.Fragment>
    )
}

export default ApplicableFacilityDetail;