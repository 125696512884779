import React from "react";
import {
  Typography,
  makeStyles,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { TextInput } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  emptyRight: {
    display: "flex",
    justifyContent: "center",
    color: "#6F6F6F",
  },
  modalHead: {
    letterSpacing: "0px",
    color: "#242424",
    opacity: 1,
    fontSize: "14px",
    fontWeight: "600",
    fontWeight: 600,
  },
  modalIcon: {
    font: "normal normal medium 14px/20px Poppins",
    letterSpacing: "0px",
    color: "#0071F2",
    textTransform: "capitalize",
    opacity: 1,
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  title: {
    fontFamily: "poppinsemibold",
    display: "inline",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  modalHeader: {
    font: "normal normal 600 16px/25px Poppins",
    color: "#101010",
    textTransform: "capitalize",
    opacity: 1,
    fontSize: "16px",
  },
  label: {
    letterSpacing: "var(--unnamed-character-spacing-0)",
    color: "var(--secondary-text-6f6f6f)",
    textAlign: "left",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: "0px",
    color: "#6F6F6F",
    textTransform: "capitalize",
    opacity: 1,
    fontSize: "12px",
    padding: "10px 0px 5px 0px",
  },
  req_red: {
    color: "#FF4D4A",
  },
  insideLabel: {
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "center",
    font: "normal normal normal 14px/20px Poppins",
    letterSpacing: "0px",
    color: "#323232",
    opacity: 1,
    fontSize: "14px",
  },
}));

function PharmacyIVFormModal(props) {
  const classes = useStyles();
  const { parent_id, modalerror } = props;
  const storeData = useSelector((state) => state?.orderSetMasterSlice);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={1}>
          <Typography className={classes.label}>Seq.No</Typography>
          <TextInput
            label=""
            placeholder=""
            hoverColor="#0071F2"
            onChange={(e) =>
              props?.handleModalState(e.target.value, "seqNo", "pharmacyIV")
            }
            value={
              props?.isEdit ? props?.pharmacyIV?.seqNo : props?.sequencyNumber
            }
            isRequired={false}
            disabled
          />
        </Grid>
        <Grid item sm={2}>
          <Typography className={classes.label}>
            Order Category <span className={classes.req_red}>*</span>
          </Typography>
          <Autocomplete
            getOptionLabel={(option) => option.label}
            style={{ width: "auto" }}
            size={"small"}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}-Pincode-textField`}
                {...params}
                variant="outlined"
                error={modalerror?.orderCategory}
              />
            )}
            options={storeData?.order_set_masters?.data?.orderCategory ?? []}
            onChange={(e, val) =>
              props?.handleModalChange(val, "orderCategory", "pharmacyIV")
            }
            value={props?.pharmacyIV?.orderCategory ?? {}}
          />
        </Grid>
        <Grid item sm={2}>
          <Typography className={classes.label}>
            Drug Type <span className={classes.req_red}>*</span>
          </Typography>
          <Autocomplete
            getOptionLabel={(option) => option.label}
            style={{ width: "auto" }}
            size={"small"}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}-Pincode-textField`}
                {...params}
                variant="outlined"
                error={modalerror?.orderTypeDrugType}
              />
            )}
            options={storeData?.drug__masters?.data ?? []}
            onChange={(e, val) =>
              props?.handleModalChange(val, "orderTypeDrugType", "pharmacyIV")
            }
            value={props?.pharmacyIV?.orderTypeDrugType ?? ""}
          />
        </Grid>
        <Grid item sm={6}>
          <Typography className={classes.label}>
            Drug Catalog <span className={classes.req_red}>*</span>
          </Typography>
          {/* <SelectBox
              id={`${parent_id}_defaultUnit_SelectBox`}
              parent_id={`${parent_id}_defaultUnit_SelectBox`}
              list={storeData.default_unit.data ?? []}
              //list={unitAllowed}
              placeholder={"Select"}
              value={defaultUnit}
              onchange={(e, value) => handleFormState("defaultUnit", value)}
            /> */}
          <Autocomplete
            getOptionLabel={(option) => option.label}
            style={{ width: "auto" }}
            size={"small"}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}-Pincode-textField`}
                {...params}
                variant="outlined"
                error={modalerror?.orderDrugCatalog}
              />
            )}
            options={storeData?.drug__catalog_masters?.data ?? []}
            onChange={(e, val) =>
              props?.handleModalState(val, "orderDrugCatalog", "pharmacyIV")
            }
            value={props?.pharmacyIV?.orderDrugCatalog ?? {}}
          />
        </Grid>
        <Grid item sm={1}>
          <Typography className={classes.label}>Required</Typography>
          <Switch
            checked={props?.pharmacyIV?.required ?? false}
            onChange={(e) => props?.handleChange(e, "pharmacyIV")}
            color="primary"
            name="required"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Grid>
        <Grid item sm={5}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography className={classes.label}>Route</Typography>
              <Autocomplete
                getOptionLabel={(option) => option.label}
                style={{ width: "auto" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    id={`${parent_id}-Pincode-textField`}
                    {...params}
                    variant="outlined"
                  />
                )}
                options={storeData?.order_set_masters?.data?.route ?? []}
                onChange={(e, val) =>
                  props?.handleModalState(val, "route", "pharmacyIV")
                }
                value={props?.pharmacyIV?.route ?? {}}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography className={classes.label}>
                Quantity <span className={classes.req_red}>*</span>
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  {" "}
                  <TextField
                    id="standard-basic"
                    label=""
                    onChange={(e) =>
                      props?.handleModalState(
                        e.target.value,
                        "quantity_number",
                        "pharmacyIV",
                        2
                      )
                    }
                    value={props?.pharmacyIV?.quantity_number ?? ""}
                    error={modalerror?.quantity_number}
                    type="number"
                  />
                </Grid>
                <Grid item sm={9}>
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    style={{ width: "auto" }}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Pincode-textField`}
                        {...params}
                        variant="outlined"
                        error={modalerror?.quantity_type}
                      />
                    )}
                    options={
                      storeData?.order_set_masters?.data?.commonUOM ?? []
                    }
                    onChange={(e, val) =>
                      props?.handleModalState(
                        val,
                        "quantity_type",
                        "pharmacyIV"
                      )
                    }
                    value={props?.pharmacyIV?.quantity_type ?? ""}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={5}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography className={classes.label}>
                IV Infusion Rate Volume{" "}
                <span className={classes.req_red}>*</span>
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  {" "}
                  <TextField
                    id="standard-basic"
                    label=""
                    onChange={(e) =>
                      props?.handleModalState(
                        e.target.value,
                        "in_infusion_rate_volume_number",
                        "pharmacyIV",
                        2
                      )
                    }
                    value={
                      props?.pharmacyIV?.in_infusion_rate_volume_number ?? ""
                    }
                    type="number"
                    error={modalerror?.in_infusion_rate_volume_number}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    style={{ width: "auto" }}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Pincode-textField`}
                        {...params}
                        variant="outlined"
                      />
                    )}
                    options={
                      storeData?.order_set_masters?.data?.commonUOM ?? []
                    }
                    onChange={(e, val) =>
                      props?.handleModalState(
                        val,
                        "in_infusion_rate_volume_type",
                        "pharmacyIV"
                      )
                    }
                    value={
                      props?.pharmacyIV?.in_infusion_rate_volume_type ?? {}
                    }
                  />
                </Grid>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography className={classes.insideLabel}>Every</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Typography className={classes.label}>
                IV Infusion Rate Duration{" "}
                <span className={classes.req_red}>*</span>
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  {" "}
                  <TextField
                    id="standard-basic"
                    label=""
                    onChange={(e) =>
                      props?.handleModalState(
                        e.target.value,
                        "in_infusion_rate_duration_number",
                        "pharmacyIV",
                        2
                      )
                    }
                    value={
                      props?.pharmacyIV?.in_infusion_rate_duration_number ?? ""
                    }
                    type="number"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    style={{ width: "auto" }}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Pincode-textField`}
                        {...params}
                        variant="outlined"
                      />
                    )}
                    options={
                      storeData?.order_set_masters?.data?.commonUOM ?? []
                    }
                    onChange={(e, val) =>
                      props?.handleModalState(
                        val,
                        "in_infusion_rate_duration_type",
                        "pharmacyIV"
                      )
                    }
                    value={
                      props?.pharmacyIV?.in_infusion_rate_duration_type ?? {}
                    }
                  />
                </Grid>
                <Grid
                  item
                  sm={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography className={classes.insideLabel}>For</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={2}>
          <Typography className={classes.label}>
            IV Dosage Duration <span className={classes.req_red}>*</span>
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={4} style={{ display: "flex", alignItems: "end" }}>
              {" "}
              <TextField
                id="standard-basic"
                label=""
                onChange={(e) =>
                  props?.handleModalState(
                    e.target.value,
                    "iv_dosage_duration_number",
                    "pharmacyIV",
                    2
                  )
                }
                value={props?.pharmacyIV?.iv_dosage_duration_number ?? ""}
                error={modalerror?.iv_dosage_duration_number}
                type="number"
              />
            </Grid>
            <Grid item sm={8}>
              <Autocomplete
                getOptionLabel={(option) => option.label}
                style={{ width: "auto" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    id={`${parent_id}-Pincode-textField`}
                    {...params}
                    variant="outlined"
                  />
                )}
                options={storeData?.order_set_masters?.data?.commonUOM ?? []}
                onChange={(e, val) =>
                  props?.handleModalState(
                    val,
                    "iv_dosage_duration_type",
                    "pharmacyIV"
                  )
                }
                value={props?.pharmacyIV?.iv_dosage_duration_type ?? {}}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={3}>
              <Typography className={classes.label}>
                Duration <span className={classes.req_red}>*</span>
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={4}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  {" "}
                  <TextField
                    id="standard-basic"
                    label=""
                    onChange={(e) =>
                      props?.handleModalState(
                        e.target.value,
                        "duration_number",
                        "pharmacyIV",
                        3
                      )
                    }
                    value={props?.pharmacyIV?.duration_number ?? ""}
                    type="number"
                    // disabled={
                    //   props?.pharmacyIV?.orderDrugCatalog?.freqApplicable
                    //     ? false
                    //     : true
                    // }
                    error={modalerror?.duration_number}
                  />
                </Grid>
                <Grid item sm={8}>
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    style={{ width: "auto" }}
                    size={"small"}
                    renderInput={(params) => (
                      <TextField
                        id={`${parent_id}-Pincode-textField`}
                        {...params}
                        variant="outlined"
                      />
                    )}
                    options={
                      storeData?.order_set_masters?.data?.durationDropDown ?? []
                    }
                    onChange={(e, val) =>
                      props?.handleModalState(
                        val,
                        "duration_type",
                        "pharmacyIV"
                      )
                    }
                    value={props?.pharmacyIV?.duration_type ?? {}}
                    // disabled={
                    //   props?.pharmacyIV?.orderDrugCatalog?.freqApplicable
                    //     ? false
                    //     : true
                    // }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={1}>
              <Typography className={classes.label}>
                Start Day <span className={classes.req_red}>*</span>
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={9}>
                  <TextInput
                    label=""
                    placeholder=""
                    hoverColor="#0071F2"
                    onChange={(e) =>
                      props?.handleModalState(
                        e.target.value,
                        "startDay",
                        "pharmacyIV",
                        2
                      )
                    }
                    value={props?.pharmacyIV?.startDay ?? ""}
                    error={modalerror?.startDay}
                    type="number"
                    isRequired={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <Typography className={classes.label}>Explanatory Note</Typography>
          <TextInput
            label=""
            placeholder=""
            hoverColor="#0071F2"
            onChange={(e) =>
              props?.handleModalState(
                e.target.value,
                "explanatoryNote",
                "pharmacyIV"
              )
            }
            value={props?.pharmacyIV?.explanatoryNote ?? ""}
            isRequired={false}
          />
        </Grid>
        <Grid item sm={6}>
          <Typography className={classes.label}>
            Practitioner Instructions
          </Typography>
          <TextInput
            label=""
            placeholder=""
            hoverColor="#0071F2"
            onChange={(e) =>
              props?.handleModalState(
                e.target.value,
                "practitionerInstructions",
                "pharmacyIV"
              )
            }
            value={props?.pharmacyIV?.practitionerInstructions ?? ""}
            isRequired={false}
          />
        </Grid>
        <Grid item sm={12}>
          <Card className={classes.root}>
            {/* <CardHeader
                  className={classes.titl}
                  action={
                    <Button
                      id={`${parent_id}_Add_New_button`}
                      className={classes.addBtn}
                      onClick={() => props?.addNewAdditive()}
                      color="primary"
                    >
                      + Add New
                    </Button>
                  }
                  title="Additives to be used for IV"
                /> */}
            <Grid container spacing={2}>
              <Grid item sm={10} className={classes.modalHead}>
                Additives to be used for IV
              </Grid>
              <Grid item sm={2}>
                <Button
                  id={`${parent_id}_Add_New_button`}
                  className={classes.modalIcon}
                  onClick={() => props?.addNewAdditive()}
                  color="primary"
                >
                  + Add New
                </Button>
              </Grid>
            </Grid>
            <Divider style={{ margin: "0px -10px" }} />

            <CardContent>
              {props?.pharmacyIV?.additiveArray?.length > 0 ? (
                <Grid container spacing={2}>
                  <Grid item sm={8}>
                    <Typography className={classes.label}>
                      Drug Catalog <span className={classes.req_red}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography className={classes.label}>
                      Quantity <span className={classes.req_red}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item sm={1}>
                    <Typography className={classes.label}>Required</Typography>
                  </Grid>

                  {props?.pharmacyIV?.additiveArray.map((valu, i) => {
                    return (
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "10px" }}
                      >
                        <Grid item sm={8}>
                          <Autocomplete
                            getOptionLabel={(option) => option.label}
                            style={{ width: "auto" }}
                            size={"small"}
                            renderInput={(params) => (
                              <TextField
                                id={`${parent_id}-Pincode-textField`}
                                {...params}
                                variant="outlined"
                              />
                            )}
                            options={
                              storeData?.drug__catalog_masters?.data ?? []
                            }
                            onChange={(e, val) =>
                              props?.additiveStateChange(val, i, "drugeCatalog")
                            }
                            value={valu?.drugeCatalog ?? {}}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <Grid container spacing={2}>
                            <Grid item sm={4}>
                              <TextInput
                                label=""
                                placeholder=""
                                hoverColor="#0071F2"
                                onChange={(e) =>
                                  props?.additiveStateChange(
                                    e.target.value,
                                    i,
                                    "quantity_number",
                                    2
                                  )
                                }
                                value={valu?.quantity_number ?? ""}
                                isRequired={true}
                                type="number"
                              />
                            </Grid>
                            <Grid item sm={8}>
                              <Autocomplete
                                getOptionLabel={(option) => option.label}
                                style={{ width: "auto" }}
                                size={"small"}
                                renderInput={(params) => (
                                  <TextField
                                    id={`${parent_id}-Pincode-textField`}
                                    {...params}
                                    variant="outlined"
                                  />
                                )}
                                options={
                                  storeData?.order_set_masters?.data
                                    ?.commonUOM ?? []
                                }
                                onChange={(e, val) =>
                                  props?.additiveStateChange(
                                    val,
                                    i,
                                    "quantity_type",
                                    2
                                  )
                                }
                                value={valu?.quantity_type ?? {}}
                                type="number"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={1}>
                          <Switch
                            checked={valu?.required ?? false}
                            onChange={(e) => props?.additiveHandleChange(e, i)}
                            color="primary"
                            name="required"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <div className={classes.emptyRight}>No Data Found!</div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default PharmacyIVFormModal;
