import React, { useContext } from "react";
import { Icons } from "qdm-component-library";
import {
  Grid,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { SelectWithLabel } from "../../../../components";
import { CustomCollapse } from "./customCollapse";
import { DateTimePicker } from "../../../../components/common/smartForm/component";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import moment from "moment";
import {
  getTimeSlot,
  getTimeString,
  checkError,
  AlertProps,
  getUtcTime,
  utcTOLocal,
} from "../../../../utils";
import { BackdropContext, AlertContext } from "../../../../contexts";

import CustomInputDatePicker from "../../../../components/common/datePicker/inputDatePicker";

const icons = {
  video: (
    <Icons
      key={"0"}
      fontIcon="video-camera"
      ariaHidden="true"
      size="small"
      style={{ color: "#B6B6B6" }}
    ></Icons>
  ),
  direct: (
    <Icons
      key={"0"}
      fontIcon="user"
      ariaHidden="true"
      size="small"
      style={{ color: "#B6B6B6" }}
    ></Icons>
  ),
  suggested: (
    <Icons
      key={"0"}
      fontIcon="star"
      ariaHidden="true"
      size="small"
      style={{ color: "#0071F2" }}
    ></Icons>
  ),
};

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: "#0071F2",
    borderRadius: 8,
    textTransform: "capitalize",
  },
  date: {
    "& .MuiFormControl-root": {
      marginTop: 5,
      "& .MuiInputBase-root": {
        borderRadius: 8,
        "& input": {
          padding: "9px 14px",
        },
        "& fieldset": {
          borderColor: "#E0E0E0",
        },
      },
    },
  },
}));

export const Transfer = (props) => {
  const { parent_id } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();

  const { setBackDrop, open: loading } = useContext(BackdropContext);
  const { setSnack } = useContext(AlertContext);

  const [state, setState] = React.useState({
    selectPractitioner: {},
    scheduleAt: "",
    selectedPendingList: props?.selectedList ?? [],
    data: {},
    appointmentInfo: [],
    reason: {},
  });

  const practitionerOptionList = useSelector(
    (state) =>
      state.pendingRescheduleListSlice.practitionerDataAgainstSpeciality
  );

  const reasonOptionList = useSelector(
    (state) => state.appointmentApiSlice.reasonForApptCancelModify
  );

  const onChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {
    if (props?.selectedList?.length) {
      getPractitionerData(props?.selectedList?.[0]?.appointmentId);
      fetchInfo(props?.selectedList);
    }
  }, []);

  React.useEffect(() => {
    if (state?.scheduleAt) {
      onChangeDate(new Date(state?.scheduleAt));
    }
  }, [state?.selectPractitioner]);

  const getPractitionerData = async (id) => {
    await dispatch(actions.GET_PRACTITIONER_AGAINST_SPECIALITY({ appId: id }));
  };

  const fetchInfo = async (list) => {
    await dispatch(
      actions.REASON_FOR_APPT_CANCEL_MODIFY({ type: "APPTRANSFERREDBYORG" })
    );

    const listData = await Promise.all(
      list?.map(async (_, i) => {
        const data = await dispatch(
          actions.APPOINTMENT_READ({ appointmentId: _?.appointmentId })
        );
        return data?.payload?.data;
      })
    );

    onChange("appointmentInfo", listData);
  };

  const onChangeDate = async (date) => {
    if (date !== "Invalid Date") {
      let payload = {
        id: state?.selectPractitioner?.id,
        startdate: getUtcTime(moment(date).startOf("day")),
        enddate: getUtcTime(moment(date).endOf("day")),
        type: "practionerid",
      };

      if (state?.selectPractitioner?.id) {
        let slotsAvailable = await dispatch(actions.READ_SLOT(payload));
        if (slotsAvailable?.payload?.data) {
          updateSlot(slotsAvailable?.payload?.data, date);
        }
      }

      state?.selectedPendingList?.map(
        (_) => _?.selectedSlot && delete _?.selectedSlot
      );

      setState({
        ...state,
        scheduleAt: moment(date).format("YYYY-MM-DD"),
        selectedPendingList: state?.selectedPendingList,
      });
    }

    // onChange('scheduleAt',moment(date).format("YYYY-MM-DD"))
  };

  const updateSlot = (info, date) => {
    const slot = JSON.parse(JSON.stringify(info));
    filterSlots(
      date,
      slot.resourceInfo,
      slot.slots,
      slot.morning,
      slot.afternoone,
      slot.evening,
      slot.night,
      slot.allSession,
      slot.isClinic,
      slot.healthCareId
    );
    const newState = { ...state };
    newState.data.morning = slot.morning;
    newState.data.afternoone = slot.afternoone;
    newState.data.evening = slot.evening;
    newState.data.night = slot.night;
    newState.data.allSession = slot.allSession;
    newState.date = date;
    newState.selectedTime = getTimeSlot(date);
    setState(newState);
  };

  const filterSlots = (
    date,
    resourceInfo,
    slots,
    morning,
    afternoone,
    evening,
    night,
    allSession,
    isClinic,
    healthCareId,
    isUpdate = false,
    selectedSlot = 0
  ) => {
    const obj = {
      selectedTimeSlot: {},
      selectedTime: getTimeSlot(
        moment(date).startOf("day").unix() * 1000
        // moment(this.props.data?.weekCalendar).format("DD-MM-YYYY") //current date startData*1000
      ),
    };
    slots.forEach((slot) => {
      if (slot) {
        let {
          start,
          id: slotId,
          status,
          end,
          isdirect = false,
          issuggest = false,
          isvideo = false,
          Maxbooking = 0,
          Maxwaiting = 0,
          bookedCount = 0,
          maxgeneral = 0,
          maxwalkin = 0,
          appointmentType,
          _id,
        } = slot;
        const preferedSlotType = appointmentType
          .split(",")
          .map((a) => a.toLowerCase());
        if (status !== "closed" && status !== "booked") {
          //const slotTime = new Date(start * 1000);
          const slotTime = utcTOLocal(start).toDate();
          const hours = slotTime.getHours();
          let label = getTimeString(hours, slotTime.getMinutes());
          if (isClinic) {
            const endTime = new Date(end * 1000);
            const endHours = endTime.getHours();
            const endTimeLabel = getTimeString(endHours, endTime.getMinutes());
            label = `${label} - ${endTimeLabel}`;
          }
          if (
            preferedSlotType.includes("direct") ||
            preferedSlotType.includes("both")
          ) {
            isdirect = true;
          }
          if (
            preferedSlotType.includes("video") ||
            preferedSlotType.includes("both")
          ) {
            isvideo = true;
          }
          const iconsArr = [];
          if (isdirect) {
            iconsArr.push(icons["direct"]);
          }
          if (isvideo) {
            iconsArr.push(icons["video"]);
          }
          if (issuggest) {
            iconsArr.push(icons["suggested"]);
          }
          const slotData = {
            value: slotId,
            label,
            _id,
            date: slotTime.getTime(),
            dateEnd: end,
            dateStart: start,
            status,
            isdirect,
            issuggest,
            isvideo,
            booked: bookedCount,
            maxBooking: Maxbooking,
            maxWaiting: Maxwaiting,
            resourcetype: "",
            resourcerole: "",
            resourcecode: "",
            waiting: bookedCount > Maxbooking ? bookedCount - Maxbooking : 0,
            icon: iconsArr,
            healthCareId,
            maxgeneral,
            isWalkin:
              moment().diff(moment(start * 1000), "d") === 0 ? true : false,
            maxwalkin,
            ...resourceInfo,
          };
          if (isClinic) {
            allSession.push(slotData);
          } else {
            if (slot?.DayType?.display?.toLowerCase() === "morning") {
              morning.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "afternoon") {
              afternoone.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "evening") {
              evening.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "night") {
              night.push(slotData);
            } else {
              morning.push(slotData);
            }
          }
        }
      }
    });
    return obj;
  };

  const getSelected = async (val, index) => {
    if (val?.value) {
      const data = await dispatch(
        actions.SLOT_AVAILABILITY({ slotId: val.value })
      );
      const { isError, errMsg } = checkError(data?.payload);
      if (!isError) {
        if (
          Array.isArray(data?.payload?.data) &&
          data.payload.data[0]?.status !== "closed" &&
          data.payload.data[0]?.status !== "booked" &&
          !data.payload.data[0]?.overbooked
        ) {
          state.selectedPendingList[index]["selectedSlot"] = val;
          onChange("selectedPendingList", state?.selectedPendingList);
        } else {
          alert("Slot is Overbooked");
        }
      }
    }
  };

  const transferAppointment = async () => {
    if (state?.reason?.value) {
      let updateStatus = [];

      setBackDrop({
        open: true,
        message: "Transferring Appointment",
      });

      await Promise.all(
        state?.appointmentInfo?.map(async (_, i) => {
          let payload = {
            ..._,
            cancelationReason: [state?.reason?._id],
            resourcecode: state?.selectPractitioner?.practitionerDetails?._id,
            slotID: [state?.selectedPendingList?.[i]?.selectedSlot?.value],
            start: state?.selectedPendingList?.[i]?.selectedSlot?.dateStart,
            end: state?.selectedPendingList?.[i]?.selectedSlot?.dateEnd,
            oldStart: state?.selectedPendingList?.[i]?.["appt date & time"]
              ? moment.unix(
                  new Date(
                    state?.selectedPendingList?.[i]?.["appt date & time"]
                  )
                )?._i
              : "",
            oldEnd: state?.selectedPendingList?.[i]?.["appt date & time"]
              ? moment.unix(
                  new Date(
                    state?.selectedPendingList?.[i]?.["appt date & time"]
                  )
                )?._i
              : "",
            requestedPeriod: [
              {
                start: state?.selectedPendingList?.[i]?.selectedSlot?.dateStart,
                end: state?.selectedPendingList?.[i]?.selectedSlot?.dateEnd,
              },
            ],
          };

          const updatedData = await dispatch(
            actions.APPOINTMENT_UPDATE(payload)
          );
          if (updatedData?.payload?.error) {
            updateStatus.push(_);
          }
          return _;
        })
      );

      if (updateStatus?.length) {
        await Promise.all(
          updateStatus?.map(async (_, i) => {
            const data = await dispatch(actions.APPOINTMENT_MODIFY_STATUS(_));
            return data?.payload?.data;
          })
        );

        let appNoList = updateStatus?.map((_) => _?.appno)?.join(",");

        let customMsg = `Slot is already filled, Kindly select some other slot for ${appNoList}!.`;

        setSnack("error", customMsg);
      }

      setSnack("success", "Transferred Successfully.");

      props?.refreshPendingData && props?.refreshPendingData();

      setBackDrop({
        open: false,
        message: "",
      });
    } else {
      setSnack("mandatory");
    }
  };

  const isDisable = () => {
    return state?.selectPractitioner?.id &&
      state?.scheduleAt &&
      state?.selectedPendingList?.filter((_) => _?.selectedSlot?.value)
        ?.length === state?.selectedPendingList?.length &&
      state?.reason?.value
      ? false
      : true;
  };

  return (
    <div id={`${parent_id}-parent-div`}>
      <Grid id={`${parent_id}-parent-grid`} container spacing={2}>
        <Grid
          id={`${parent_id}-SelectWithLabel-grid`}
          item
          xs={12}
          sm={12}
          md={6}
        >
          <SelectWithLabel
            parent_id={"transfer"}
            options={
              Array.isArray(practitionerOptionList?.data)
                ? practitionerOptionList?.data?.filter((_) => _?.label) ?? []
                : []
            }
            label={`Select Practitioner`}
            value={state?.selectPractitioner}
            onChange={(data) => onChange("selectPractitioner", data)}
            // error={props?.data?.error?.category??false}
          />
        </Grid>
        <Grid
          id={`${parent_id}-DateTimePicker-grid`}
          item
          xs={12}
          sm={12}
          md={6}
        >
          <div id={`${parent_id}-DateTimePicker-div`} className={classes.date}>
            {/* <DateTimePicker
                            parent_id={'transfer'}
                            // required={data.required}
                            label={'Schedule at'}
                            dateFormat={'dd MMM,yyyy'}
                            value={state?.scheduleAt}
                            onChange={(date) => onChangeDate(date)}
                        // error={state?.error?.[data?.state_name] ?? false}
                        /> */}
            <Typography
              style={{
                fontSize: "12px",
                Fontfamily: "pc_regular",
                color: "#6F6F6F",
                paddingBottom: 3,
              }}
              variant="body1"
            >
              Schedule at
            </Typography>
            <CustomInputDatePicker
              //   disabled={data?.disabled}
              placeholderText="dd / mm / yyyy"
              //   minDate={state?.states?.[data?.minDateState] ?? null}
              selectedDate={
                state?.scheduleAt ? new Date(state?.scheduleAt) : null
              }
              handleChange={(value) => onChangeDate(value)}
              inputField={
                <TextField
                  id={"transfer"}
                  // label={"Date"}
                  fullWidth
                  variant="outlined"
                  //   required={data?.required}
                  //   disabled={data?.disabled}
                  //   type={data?.type ?? "text"}
                  value={
                    state?.scheduleAt
                      ? moment(new Date(state?.scheduleAt)).format("DD-MM-YYYY")
                      : null
                  }
                  inputProps={{
                    style: {
                      padding: "10.5px 14px",
                      fontFamily: "poppin",
                      fontSize: "12px",
                      borderRadius: 8,
                      borderColor: "#e0e0e0",
                      //   backgroundColor: data?.disabled ? "#E0E0E0" : "#fff",
                    },
                  }}
                />
              }
              customInput={{
                top: 10,
                right: 4,
              }}
            />
          </div>
        </Grid>
      </Grid>
      {state?.selectedPendingList?.length > 0 && (
        <div id={`${parent_id}-CustomCollapse-div`}>
          {state?.selectedPendingList?.map((_, i) => {
            return (
              <div
                id={`${parent_id}-CustomCollapse-div` + i}
                style={{
                  marginTop: 12,
                }}
              >
                <CustomCollapse
                  parent_id={"transfer" + i}
                  patientDetails={{
                    name: _?.name,
                    age: _?.age,
                    gender:
                      _?.gender === "male"
                        ? "M"
                        : _?.gender === "male"
                        ? "F"
                        : "",
                    mrn: _?.mrn,
                    contact: _?.["mobile no"],
                    appointmentDateTime: _?.["appt date & time"],
                  }}
                  doctorDetails={{
                    name: state?.selectPractitioner?.label,
                    role: state?.selectPractitioner?.value,
                    speciality: state?.selectPractitioner?.speciality,
                  }}
                  scheduleAt={state?.scheduleAt}
                  slotsData={state?.data}
                  getSelected={(data) => getSelected(data, i)}
                  selectedSlot={_?.selectedSlot}
                  isOpen={state?.scheduleAt && state?.selectPractitioner?.id}
                  hideSlotAvaible={
                    state?.scheduleAt && state?.selectPractitioner?.label
                      ? false
                      : true
                  } //need to change
                  handleCalenderChange={(date) => onChangeDate(date)}
                  scheduleDateFormat="DD MMM,YYYY"
                />
              </div>
            );
          })}
        </div>
      )}
      <div id={`${parent_id}-Transfer-div`} style={{ marginTop: 12 }}>
        <SelectWithLabel
          id={`${parent_id}-reason-selectwithlabel`}
          options={reasonOptionList?.data}
          label={`Reason`}
          required={true}
          value={state?.reason}
          onChange={(data) => onChange("reason", data)}
          // error={props?.data?.error?.category??false}
        />
      </div>
      <div
        id={`${parent_id}-Transfer-button-div`}
        style={{ marginTop: 30, textAlign: "center" }}
      >
        <Button
          id={`${parent_id}-Transfer-button`}
          className={classes.button}
          variant="contained"
          onClick={transferAppointment}
          color="primary"
          disabled={isDisable()}
        >
          Transfer
        </Button>
      </div>
    </div>
  );
};
