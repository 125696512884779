import React from 'react';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    // height: 264,
    flexGrow: 1,
    // maxWidth: 400,
  },
  chipStyles: {
    background: "#F0F0F0",
    borderRadius: "4px",
    color: "#6F6F6F",
    fontSize: "12px",
    border: "1px solid #1010100D",
    marginLeft: 10
  },
  listStyles: {
    display: "flex", 
    justifyContent: "space-between", 
    padding: 6, 
    alignItems: "center"
  },
  titleStyles: {
    fontSize: "14px",
    fontWeight: 500
  },
  fullview: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  }
});


export default function DiagnosisTreeView(props) {
  const classes = useStyles();

  const handleToggle = (event, nodeId) => {
    let element = event?.target?.parentElement?.parentElement?.parentElement
		let ele = (element?.getAttribute("id")) || (element?.parentElement?.getAttribute("id") ?? null)
      // if(nodeId[0] !== props?.activeNode){
      if(!props?.activeNode?.includes?.(nodeId[0])){
        props?.updateChild(nodeId[0], ele)
      }
  };

  const handleSelect = (event, nodeId) => {
    // debugger
    // alert(nodeId)
    // let element = event?.target?.parentElement?.parentElement?.parentElement
		// let ele = (element?.getAttribute("id")) || (element?.parentElement?.getAttribute("id") ?? null)
    //   if(nodeId !== props?.activeNode){
    //     props?.updateChild(nodeId, ele)
    //   }
  };

    const onEdit = (data, e) => {
      props.editList(data)
      e.defaultPrevented = true;
      e.preventDefault();
      return false
    }


    const StyledTreeItem = withStyles((theme) => ({
      iconContainer: {
        '& .close': {
          opacity: 0.3,
        },
      },
      group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
      },
      root: {
        position: "relative",
        margin: "4px 0px",
        borderRadius: "6px",
        "&:before": {
          pointerEvents: "none",
          content: '""',
          position: "absolute",
          width: 14,
          left: -19,
          top: (props) => (props?.children ? 18 : 16),
          borderBottom: "1px solid #B6B6B6",
        },
        "&:focus > $content": {
          backgroundColor: "E0EBF9",
        },
      },
    }))((props) => <TreeItem {...props}
                        label={
                            <div className={props?.nodeId ? classes.listStyles : {}}>
                              {props?.nodeId ? 
                                  <Typography variant={"body1"} color={props?.nodeId?.split("-")[0] === "no_data" ? "textSecondary"  : "textPrimary"} className={classes.titleStyles}>
                                      {props?.label}
                                      {/* {"-------"}{props?.nodeId} */}
                                      {props?.startDate && 
                                        <Chip 
                                          className={classes.chipStyles} 
                                          size="small" 
                                          label={(props?.startDate && props?.endDate) ? "("+props?.startDate+")"+" - "+"("+props?.endDate+")" : "("+props?.startDate+")"} 
                                      /> }
                                  </Typography>
                                  :
                                  <Typography variant={"body1"} style={{width: "100%"}}>
                                      <Skeleton animation="wave" height={40} />
                                  </Typography>
                              }
                              {props?.nodeId === props?.activeNodeId[props?.activeNodeId.length-1] && 
                                  <Typography onClick={(e)=>onEdit(props, e)} variant="body2" color="inherit" style={{background: "#fff", padding: "0px 4px", borderRadius: "6px"}}>
                                      <EditIcon color='#6F6F6F' fontSize={"small"} style={{marginTop: "3px", fontSize: "16px", color:"#6F6F6F"}} />
                                  </Typography>
                              }
                            </div>}
                    />);

    const getTreeItemsFromData = (treeItems, presntIndex) => {
      return treeItems?.length > 0 && treeItems?.map((treeItemData, index) => {
        let children = null;
        if (treeItemData) {
          if (treeItemData?.child && treeItemData?.child.length > 0) {
            children = getTreeItemsFromData(treeItemData?.child, presntIndex? presntIndex+ "" +index:index);
          }
          // allkeys.push(treeItemData.nodeId);
          return (
            <StyledTreeItem
                {...treeItemData}
                children={children}
                nodeId={treeItemData?._key ? treeItemData?._key+"-"+(presntIndex? presntIndex+ "" +index:index):treeItemData?._key} 
                label={treeItemData?.icdcode + " " + treeItemData?.icdname }
                startDate={treeItemData?.effFrom?moment.unix(treeItemData?.effFrom).format("DD-MM-YYYY"):null}
                endDate={treeItemData?.effTo?moment.unix(treeItemData?.effTo).format("DD-MM-YYYY"):null}
                activeNodeId={props?.activeNode}
                id={presntIndex? presntIndex+ "" +index:index}
            />
          );
        }
      });
    };

  return (
    <>
    {(props?.data?.length > 0 && !props?.loading) && 
      <TreeView
        className={classes.root}
        defaultExpanded={['1']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={""}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        // disableSelection={true}
        // selected={""}
      >
	      {getTreeItemsFromData(props?.data)}
      </TreeView>}
      {(props?.data?.length === 0 && !props?.loading) && <div className={classes.fullview} >
        <Typography variant={"body1"} color={"textSecondary"} className={classes.titleStyles}>
            {"No Data Found"}
        </Typography>
      </div>}
      {props?.loading && <div className={classes.fullview} ><CircularProgress /></div>}
    </>
  );
}
