import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Drawer from "@material-ui/core/Drawer";
// import AddHoliday from "./addnewholiday";
import TextField from "@material-ui/core/TextField";
import "../font.css";
import CreateAppoointment from "./createNewAppointment";
import backarrow from "../../assets/icons8-expand-arrow.svg";
import searchicon from "../../assets/icons - Search.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import edit from "../../assets/icons - Edit.svg";
import Delete from "../../assets/icons8-trash.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dropdown from "./dropdown";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
// import DenseTabletwo from "./viewholidayentities";
// import Grid from "@material-ui/core/Grid";
const drawerWidth = "94%";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  bgcolor: {
    backgroundColor: "#F9F9F9",
    paddingTop: "10px",
    fontFamily: "poppin",
    color: "gray",
    fontSize: "12px",
  },
  font: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  boxsize: {
    "& .MuiOutlinedInput-inputMarginDense": {
      height: "10px !important",
      width: "150px",
    },
  },
  root: {
    "& .MuiTableCell-sizeSmall": {
      paddingBottom: "0px",
      paddingTop: "0px",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 18,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#0071F2",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 12,
    marginTop: "1px",
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(Switch);
// const AntSwitch = withStyles((theme) => ({
//   root: {
//     width: "28px",
//     height: "17px",
//     padding: "2px",
//     display: "flex",
//     marginTop: "4px",
//   },
//   switchBase: {
//     padding: 2,
//     color: theme.palette.grey[500],
//     "&$checked": {
//       transform: "translateX(12px)",
//       color: theme.palette.common.white,
//       "& + $track": {
//         opacity: 1,
//         backgroundColor: theme.palette.primary.main,
//         borderColor: theme.palette.primary.main,
//       },
//     },
//   },
//   thumb: {
//     width: 12,
//     height: 12,
//     marginTop: "1px",
//     boxShadow: "none",
//   },
//   track: {
//     border: `1px solid ${theme.palette.grey[500]}`,
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.common.white,
//   },
//   checked: {},
// }))(Switch);

function createData(serialcode, code, description, resources, status, action) {
  return {
    serialcode,
    code,
    description,
    resources,
    status,
    action,
  };
}

const rows = [
  createData("1", "Timetable - Normal Days", "Practitioner", "Consultant"),
  createData("2", "Timetable - Normal Days", "Practitioner", "Consultant"),
  createData("3", "Timetable - Normal Days", "Practitioner", "Consultant"),
];

export default function ResourcebyType() {
  const classes = useStyles();
  const history = useHistory();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState("checkedC: true");
  const handleDrawerOpen = () => {
    setOpen(true);
    history.push(routes.createappointment);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <div style={{ marginLeft: "59%" }}>
        <Dropdown />
      </div>
      <Card style={{ borderRadius: "12px" }}>
        <div
          style={{
            borderRadius: "6px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginLeft: "18px", marginTop: "15px" }}>
              <p style={{ fontFamily: "poppinsemibold" }}>
                Resource by Appointment Types
              </p>
            </div>
            <div
              style={{ marginRight: "18px", marginTop: "16px" }}
              className={classes.boxsize}
            >
              <TextField
                id="outlined-search"
                placeholder="Search field"
                size="small"
                style={{ width: "231px" }}
                type="search"
                variant="outlined"
                InputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchicon} alt="Icon" />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                style={{ marginRight: "10px", width: "231px" }}
              />
              <button
                onClick={handleDrawerOpen}
                style={{
                  backgroundColor: "#0071F2",
                  color: "white",
                  width: "108px",
                  height: "32px",
                  border: "none",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderRadius: "5px",
                  fontFamily: "poppin",
                  fontSize: "12px",
                }}
              >
                +Add New
              </button>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {
                  <div style={{ backgroundColor: "#F6F6F6" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <button
                          onClick={handleDrawerClose}
                          style={{
                            border: "none",
                            backgroundColor: "#DCDCDC",
                            borderRadius: "20px",
                            padding: "5px",
                            height: "25px",
                            marginTop: "11px",
                            marginRight: "5px",
                          }}
                        >
                          <img
                            src={backarrow}
                            alt="icon"
                            style={{ transform: "rotate(90deg)" }}
                          />
                        </button>
                        <p>Create New Appointment type</p>
                      </div>
                      <div style={{ marginTop: "8px", marginRight: "35px" }}>
                        <Dropdown />
                      </div>
                    </div>
                    <hr style={{ marginTop: "0px" }} />
                    <CreateAppoointment />
                  </div>
                }
              </Drawer>
            </div>
          </div>
          <TableContainer
            component={Paper}
            style={{
              borderRadius: "5px",
              marginTop: "20px",
              paddingBottom: "18px",
            }}
            className={classes.root}
          >
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.bgcolor}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "140px",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "240px",
                    }}
                  >
                    code
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "150px",
                    }}
                    align="left"
                  >
                    Description
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "16%",
                    }}
                    align="left"
                  >
                    Resources
                  </TableCell>
                  <TableCell
                    className={classes.bgcolor}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      width: "25%",
                    }}
                    align="left"
                  >
                    Status
                  </TableCell>

                  <TableCell
                    className={classes.bgcolor}
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    align="left"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.serialcode}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.font}
                      style={{ width: "120px", padding: "12px" }}
                    >
                      {row.serialcode}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.font}
                      style={{ width: "170px", padding: "12px" }}
                    >
                      {row.code}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.font}
                      style={{ width: "120px" }}
                    >
                      {row.description}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.font}
                      style={{ width: "120px" }}
                    >
                      {row.resources}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.font}
                      style={{ width: "120px" }}
                    >
                      <FormControlLabel
                        style={{ marginLeft: "0px", marginRight: "0px" }}
                        control={<IOSSwitch />}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          padding: "13px",
                          paddingLeft: "0px",
                        }}
                      >
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <img
                            src={edit}
                            alt="Icon"
                            style={{
                              height: "12px",
                              backgroundColor: "transparent",
                              marginTop: "5px",
                            }}
                          />
                        </button>
                        {/* <AntSwitch
                        checked={state.checkedC}
                        onChange={handleChange}
                        name="checkedC"
                      /> */}

                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <img
                            src={Delete}
                            alt="Icon"
                            style={{
                              height: "12px",
                              backgroundColor: "transparent",
                              marginTop: "5px",
                            }}
                          />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </div>
  );
}
