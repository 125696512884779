import React, { useState, useEffect } from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
// import Typography from "@material-ui/core/Typography";
import { Button, Divider } from "@material-ui/core";
// import TooltipContent from "./TooltipContent/TooltipContent";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import InputBase from "@material-ui/core/InputBase";
import Cross from "../../../../../src/assets/CrossIcon.svg";
import Checked from "../../../../assets/checked.svg";
import SelectBox from "../../../../components/common/Select";
// import { StatusComponent } from "../../../../components/common/smartForm/component/StatusComponent/statusComponent";
import { Typography } from "@material-ui/core";
import { actions } from "primary_care_admin_binder";
import "./Tooltip.css";
import StatusComponent from "../StatusComponent/statusComponent";

const useStyles = makeStyles((theme) => ({
  popover: {
    "&. MuiPopover-paper": {
      width: 225,
      height: 163,
    },
    "&. MuiPaper-root": {
      width: 225,
      height: 163,
    },
  },
  "&. MuiPopover-paper": {
    width: 225,
    height: 163,
  },
  root: {
    width: 225,
    height: 163,
  },
}));

export default function BasicPopover(props) {
  const { enable, setValues, values, data } = props;
  const [anchorEl, setAnchorEl] = useState();
  const [popoverValue, setPopoverValue] = React.useState("");
  const dispatch = useDispatch();
  const reason = useSelector(
    (state) => state?.holidaySlice?.holidy_status_reason?.data
  );
  useEffect(() => {
    dispatch(actions.HOLIDAY_STATUS_REASON());
  }, [dispatch]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getTypeDropdown = () => {
    let tmpArr = [];
    reason?.result?.map((item) => {
      return tmpArr.push({
        label: item.display,
        _id: item._id,
      });
    });
    return tmpArr;
    // setHolidayList(tmpArr);
  };
  const TypeDropdown = getTypeDropdown();
  const handleClose = () => {
    setAnchorEl(null);
    // setPopoverValue("");
  };
  useEffect(() => {
    setPopoverValue("");
  }, [data]);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const id = open ? "simple-popover" : undefined;
  const [holidayList, setHolidayList] = React.useState([]);

  const handleChange = (e, value) => {
    let tmpArr = [];
    reason?.result?.map((item) => {
      return tmpArr.push({
        label: item.display,
        _id: item.display,
      });
    });
    // return tmpArr;
    setHolidayList(tmpArr);
  };
  const handleTreeStatus = (Action) => {
    if (values === !undefined) {
      setValues([
        ...values,
        {
          orgid: data.id,
          status:
            Action === "Active" ? "CodingMaster/11302" : "CodingMaster/11303",
          reason: "Active",
          _key: data.key,
          personId: "Holiday/10391",
        },
      ]);
    }

    setPopoverValue(Action === "Active" ? true : false);
  };
  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        onMouseOver={handleClick}
        style={{ background: "none" }}
        disableElevation
        disableRipple
        disableFocusRipple
      >
        <StatusComponent enable={popoverValue !== "" ? popoverValue : enable} />
      </Button>

      <Popover
        PaperProps={{ onMouseLeave: handleClose }}
        transformOrigin={{ top: 231, left: 526 }}
        style={{ width: 500, height: 500, maxHeight: "unset" }}
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {
          <div style={{ padding: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography className="tooltipHead">Status</Typography>
              <div className="reasonHead">
                <span className="divCancel">
                  <img
                    src={Cross}
                    alt="cancel"
                    className="cancelPic"
                    aria-hidden
                  />
                </span>
                <span className="divCheck">
                  <img src={Checked} alt="check" aria-hidden />
                </span>
              </div>
            </div>
            <Divider style={{ border: "1px solid #ECF0F7", width: "100%" }} />
            <div style={{ width: 450 }} className="selectDiv">
              <SelectBox
                parent_id="__selectbox"
                top_title="Reason"
                list={TypeDropdown}
                returnValue={false}
                // placeholder={data?.placeholder ?? "Search here..."}
                value={holidayList.label}
                onchange={(e) => handleChange(e)}
                requireStart
              />
            </div>
            <div>
              <Typography className="actionHead">Status</Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                size={"small"}
                contained
                className="activeButton"
                disableRipple
                disableElevation
                onClick={() => handleTreeStatus("Active")}
              >
                Active
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                size={"small"}
                contained
                className="inActiveButton"
                disableRipple
                disableElevation
                onClick={() => handleTreeStatus("InActive")}
              >
                InActive
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                size={"small"}
                contained
                className="cancel"
                disableRipple
                disableElevation
                onClick={handlePopoverClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        }
        {/* jkdfjf */}
      </Popover>
    </div>
  );
}
