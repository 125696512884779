import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Button, Grid } from "@material-ui/core";
import door from "../../assets/Icon - View holidays for entities.svg";
import expandarrow from "../../assets/icons8-expand-arrow.svg";
import { CommonTable } from "../../components";
import { GenerateForm, SearchWithFilter } from "../../components";
import { Row, Col } from "qdm-component-library";
import { FilterSelect } from "./filterSelect";
//import { AlertProps } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../HOCs";
import moment from "moment";
import { connect } from "react-redux";
import { differenceInCalendarYears } from "date-fns";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  leftButton: {
    border: "none",
    backgroundColor: "#fff",
    width: 32,
    height: 32,
    borderRadius: "50%",
    cursor: "pointer",
  },
  addNewModal: {
    padding: "10px",
    width: "86%",
    height: "223px",
  },
  copyYearModal: {
    padding: "18px",
    width: "22%",
    height: "223px",
  },
  copyNote: {
    border: "none",
    color: "#0071F2",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    fontSize: "12px",
    fontFamily: "poppin",
    cursor: "pointer",
    paddingRight: 10,
  },
  confirmNote: {
    border: "none",
    color: "#ff5c5a",
    borderRadius: 5,
    background: "#fff2f2",
    fontSize: "17px",
    fontFamily: "poppin",
    cursor: "pointer",
    padding: 6,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    height: "9vh",
    padding: "0px 10px 12px 10px",
  },
}));

function HolidayTable(props) {
  const { parent_id } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const listData = useSelector(
    (state) => state?.holidaySlice?.holidy_list?.data
  );
  const master = useSelector(
    (state) => state?.holidaySlice?.holiday_master?.data
  );
  console.log(master, "master");
  const EntityMasters = useSelector(
    (state) => state?.holidaySlice?.entity_master?.data
  );
  //const loading = useSelector((state) => state?.holidaySlice?.holidy_list?.loading);
  const saveloading = useSelector(
    (state) => state?.holidaySlice?.holiday_upsert?.loading
  );
  const saveloadings = useSelector((state) => state?.holidaySlice);
  console.log(saveloadings, "saveloading");

  const [allMasters, setAllMasters] = React.useState();
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [handleData, setHandleData] = React.useState("");
  const [data, setData] = React.useState([]);
  const [tempAddData, setTempAddData] = React.useState();
  const [isAdd, setIsAdd] = React.useState(false);
  const [copyNewHolidayYear, setCopyNewHolidayYear] = React.useState(false);

  const handlepageChange = async (currentPage, PerPage) => {
    console.log(currentPage, PerPage, "page");
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(
      actions.HOLIDAY_READ({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      })
    );
  };

  const getallmasters = async () => {
    const data = await props.PRACTITIONER_MASTERS();
    setAllMasters(data?.payload?.data);
  };

  React.useEffect(() => {
    const arr = [];
    // EntityMasters?.result.map((item) => {
    //   arr.push({
    //     entity: item.name,
    //     children: [{}],
    //   });
    // });
  }, [EntityMasters]);
  React.useEffect(() => {
    getallmasters();
    // getallmasters(page, perPage)
  }, []);

  const handleModalOpen = (calendar) => {
    setState({
      ...state,
      open: true,
    });
    setData([
      // {
      //   componet_type: "select",
      //   options: master["holiday_type"] ?? [],
      //   label: "Calander Year",
      //   state_name: "Calander_Year",
      //   value: state?.edit_data?.holiday_type ?? {},
      //   required: true,
      //   //   multi: true,
      //   grid: {
      //     md: 2,
      //   },
      // },
      {
        componet_type: "date",
        label: "Date",
        state_name: "date",
        value: state?.edit_data?.date ?? "",
        dateFormat: "dd-MM-yyyy",
        required: true,
        grid: {
          md: 3,
        },
      },
      {
        componet_type: "select",
        options: master["holiday_type"] ?? [],
        label: "Holiday Type",
        state_name: "holiday_type",
        value: state?.edit_data?.holiday_type ?? {},
        required: true,
        //   multi: true,
        grid: {
          md: 3,
        },
      },
      {
        componet_type: "text",
        label: "Reason for Holiday",
        state_name: "holiday_reason",
        value: state?.edit_data?.holiday_reason ?? "",
        required: true,
        grid: {
          md: 3,
        },
      },
      {
        componet_type: "switch",
        label: "Status",
        state_name: "Status",
        grid: {
          md: 1,
        },
      },
      {
        componet_type: "entity",
        label: "Applicable Entity",
        grid: {
          md: 2,
        },
      },
    ]);
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null,
    });
  };
  const handleModalOpenNew = () => {
    setConfirmCalenderYear({
      ...calendarYear,
      open: true,
    });
  };
  const handleDrawerCloseNew = () => {
    setConfirmCalenderYear({
      ...calendarYear,
      open: false,
    });
  };
  React.useLayoutEffect(() => {
    dispatch(actions.HOLIDAY_READ({ page: page, perPage: perPage }));
    dispatch(actions.HOLIDAY_MASTERS());
    dispatch(actions.ENTITY_MASTERS());
  }, [dispatch, page, perPage]);

  const getUtcTime = (date) => {
    if (date) {
      return moment.utc(date).unix();
    } else {
      return moment.utc().unix();
    }
  };

  const saveHoliday = async (values) => {
    console.log(values, "values");
    let type = "success";
    // let entity_type = "CodeableConceptMaster/11290";
    let entity_type = filterData?.entity_type?.value ?? "ALL";
    let entity_name = filterData?.entity_name?._id ?? "ALL";

    let data = {
      CalYear: 2022,
      CalDate: getUtcTime(moment(values.date)),
      // CalDate: values.date,
      CalDay: "CodingMaster/10250",
      HolidayType: values.holiday_type.value,
      HolidayReason: values.holiday_reason,
      status: values.Status,
      // holidayDetails: [
      //   {
      //     orgid: "Organization/10000",
      //     status: "CodingMaster/11302",
      //     reason: "Active",
      //   },
      //   {
      //     orgid: "Organization/10001",
      //     status: "CodingMaster/11302",
      //     reason: "Active",
      //   },
      //   {
      //     orgid: "Organization/10002",
      //     status: "CodingMaster/11302",
      //     reason: "Active",
      //   },
      //   {
      //     orgid: "Organization/10074",
      //     status: "CodingMaster/11303",
      //     reason: "x`",
      //   },
      // ],
    };
    if (state?.edit_data) {
      data["_key"] = state?.edit_data._key;
      data["id"] = state?.edit_data.id;
      data["status"] = state?.edit_data.status;
      data["entity_type"] = state?.edit_data.entity_type;
      data["entity_name"] = state?.edit_data.entity_name;
      type = "update";
    }

    const upsertdata = await dispatch(actions.HOLIDAY_UPSERT(data));
    if (upsertdata?.payload?.data?.error) {
      type = "error";
    }
    await dispatch(
      actions.HOLIDAY_READ({
        entity_name: filterData?.entity_name?._id ?? null,
        entity_type: filterData?.entity_type?.value ?? null,
      })
    );
    await handleDrawerClose();
    props.alert.setSnack(type);
  };

  const handleEdit = (e, values, index) => {
    let data = {
      ...values,
      repeat: values.repeat === "Yes" ? true : false,
      date: values.CalDate ? moment.unix(values.CalDate) : null,
      holiday_type:
        master["holiday_type"].filter(
          (val) => val.label === values.holiday_type
        ) &&
        master["holiday_type"].filter(
          (val) => val.label === values.holiday_type
        )[0]
          ? master["holiday_type"].filter(
              (val) => val.label === values.holiday_type
            )[0]
          : null,
    };
    setState({
      ...state,
      edit_data: data,
      open: true,
    });
  };
  const handleshowData = () => {
    setIsAdd(true);
    // setData([
    //   {
    //     componet_type: "select",
    //     options: master["holiday_type"] ?? [],
    //     label: "Calander Year",
    //     state_name: "Calander_Year",
    //     value: state?.edit_data?.holiday_type ?? {},
    //     required: true,
    // disabled: true,
    //     //   multi: true,
    //     grid: {
    //       md: 1.5,
    //     },
    //   },
    //   {
    //     componet_type: "date",
    //     label: "Date",
    //     state_name: "date",
    //     value: state?.edit_data?.date ?? "",
    //     dateFormat: "dd-MM-yyyy",
    //     required: true,
    //     grid: {
    //       md: 1.2,
    //     },
    //   },
    //   {
    //     componet_type: "select",
    //     options: master["holiday_type"] ?? [],
    //     label: "Holiday Type",
    //     state_name: "holiday_type",
    //     value: state?.edit_data?.holiday_type ?? {},
    //     required: true,
    //     //   multi: true,
    //     grid: {
    //       md: 1.2,
    //     },
    //   },
    //   {
    //     componet_type: "text",
    //     label: "Reason for Holiday",
    //     state_name: "holiday_reason",
    //     value: state?.edit_data?.holiday_reason ?? "",
    //     required: true,
    //     grid: {
    //       md: 3,
    //     },
    //   },
    //   {
    //     componet_type: "Divider",
    //   },
    //   {
    //     componet_type: "TextField",
    //     Text: "Facility",
    //     grid: {
    //       md: 9,
    //     },
    //   },

    //   {
    //     componet_type: "SearchBox",
    //     grid: {
    //       md: 3,
    //     },
    //   },
    // ]);
  };

  const statusChange = async (e, values, index) => {
    let data = {
      _key: values._key,
      status: e.target.checked,
    };
    await dispatch(actions.HOLIDAY_STATUS_CHANGE(data));
    await dispatch(
      actions.HOLIDAY_READ({
        entity_name: filterData?.entity_name?._id ?? null,
        entity_type: filterData?.entity_type?.value ?? null,
      })
    );
    setState({
      ...state,
      edit_data: null,
    });
    props.alert.setSnack("update");
  };

  // old state
  const [state, setState] = React.useState({
    holiday_reason: "",
    holiday_type: {},
    date: "",
    repeat: true,
  });
  const [calendarYear, setConfirmCalenderYear] = React.useState({
    copy_calander_year: "",
  });

  const [filterData, setFilterData] = React.useState({
    entity_type: {},
    entity_name: {},
  });

  const changeState = (key, value) => {
    if (key === "entity_type" && value?.value) {
      props.GET_ENTITY_BY_ID({ id: value.value });
      filterData.entity_name = {};
    }
    if (key === "entity_type") {
      filterData.entity_name = {};
    }
    filterData[key] = value;
    if (filterData?.entity_name?._id && filterData?.entity_type?.value) {
      dispatch(
        actions.HOLIDAY_READ({
          entity_name: filterData?.entity_name?._id,
          entity_type: filterData?.entity_type?.value,
        })
      );
    } else {
      dispatch(actions.HOLIDAY_READ());
    }
    setFilterData({ ...filterData });
  };

  const [show, setshow] = React.useState(false);
  const [entity, setentity] = React.useState(true);

  const handleshow = () => {
    setFilterData({
      entity_type: {},
      entity_name: {},
    });
    setshow(true);
    setentity(false);
  };
  const handlehide = () => {
    setFilterData({
      entity_type: {},
      entity_name: {},
    });
    setshow(false);
    setentity(true);
    dispatch(actions.HOLIDAY_READ());
  };

  const onSearchChange = (value = "") => {
    if (value?.length > 1) {
      dispatch(
        actions.HOLIDAY_READ({
          search: value.trim(),
          page: page,
          perPage: perPage,
        })
      );
    } else if (!value) {
      dispatch(
        actions.HOLIDAY_READ({
          page: page,
          perPage: perPage,
        })
      );
    }
  };
  const onConfirmHoliday = () => {
    setCopyNewHolidayYear(true);
    handleDrawerCloseNew();
  };
  const onConfirmation = () => {
    setCopyNewHolidayYear(false);
  };

  return (
    <div id={`${parent_id}-parent-div`} style={{ borderRadius: "12px" }}>
      <div id={`${parent_id}-parent-sub-div`} style={{ width: "100%" }}>
        {show && (
          <div id={`${parent_id}-parent-sub-div`} style={{ marginBottom: 14 }}>
            <Row id={`${parent_id}-parent-row`}>
              <Col
                id={`${parent_id}-expandarrow-col`}
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                inLineStyles={{ padding: 0 }}
              >
                <button
                  id={`${parent_id}-expandarrow-button`}
                  className={classes.leftButton}
                  onClick={() => handlehide()}
                >
                  <img
                    id={`${parent_id}-expandarrow-img`}
                    src={expandarrow}
                    alt="icon"
                    style={{ transform: "rotate(90deg)", width: 14 }}
                  />
                </button>
              </Col>
              <Col
                id={`${parent_id}-parent-col`}
                xs={10}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                inLineStyles={{ padding: 0 }}
              >
                <Row
                  id={`${parent_id}-parent-sub-row`}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Col
                    id={`${parent_id}-FilterSelect-col`}
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    inLineStyles={{ padding: "0px 0px 0px 8px" }}
                  >
                    <FilterSelect
                      parent_id={"entity-type"}
                      value={filterData?.entity_type}
                      onChange={(value) => changeState("entity_type", value)}
                      options={allMasters?.["entity"] ?? []}
                      label="Entity Type :"
                    />
                  </Col>
                  <Col
                    id={`${parent_id}-FilterSelect-col`}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    inLineStyles={{ padding: "0px 0px 0px 8px" }}
                  >
                    <FilterSelect
                      parent_id={"entity-name"}
                      value={filterData?.entity_name}
                      onChange={(value) => changeState("entity_name", value)}
                      options={props?.entity_name_options ?? []}
                      label="Entity Name :"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        <Card id={`${parent_id}-parent-card`} style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-discription-sub-div`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px",
              }}
            >
              <div id={`${parent_id}-List-of-Holidays-div`}>
                <p
                  id={`${parent_id}-List-of-Holidays-title-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {copyNewHolidayYear
                    ? "Copying Calendar from 2022 to 2023"
                    : "List of Holidays"}
                </p>
              </div>
              <div
                id={`${parent_id}-entity-div`}
                style={{ marginTop: "16px", float: "right", display: "flex" }}
              >
                {!copyNewHolidayYear && entity && (
                  <button
                    id={`${parent_id}-entity-button`}
                    style={{
                      border: "none",
                      color: "#0071F2",
                      textDecoration: "underline",
                      backgroundColor: "#ffffff",
                      fontSize: "12px",
                      fontFamily: "poppin",
                      cursor: "pointer",
                      paddingRight: 10,
                    }}
                    onClick={() => handleshow()}
                  >
                    <div
                      id={`${parent_id}-View-holidays-entities-div`}
                      style={{ display: "flex" }}
                    >
                      <div
                        id={`${parent_id}-View-holidays-entities-sub-div`}
                        style={{ fontFamily: "poppin", fontSize: "12px" }}
                      >
                        View holidays for entities
                      </div>
                      <img
                        id={`${parent_id}-View-holidays-entities-door-img`}
                        src={door}
                        alt="Icon"
                        style={{ marginLeft: "5px" }}
                      />
                    </div>
                  </button>
                )}
                {!copyNewHolidayYear && (
                  <SearchWithFilter
                    id="Holiday"
                    placeholder="Search Reason & Type!"
                    //  loading={data?.length > 0 ? loading : false}
                    onSearchChange={onSearchChange}
                    hideSearchBar={false}
                    hideFilter={true}
                    //  onFilterClicked={onFilterClicked}
                    //  isFilterApplied={props?.isFilterApplied}
                  />
                )}
                {copyNewHolidayYear ? (
                  <div>
                    <p
                      // id={`${parent_id}-List-of-Holidays-title-p`}
                      style={{
                        fontFamily: "poppinsemibold",
                        fontSize: "16px",
                        marginBottom: "0px",
                      }}
                    >
                      Calendar Year : 2023
                    </p>
                  </div>
                ) : (
                  <Button
                    id={`${parent_id}-Add-New-button`}
                    disabled={
                      show
                        ? filterData?.entity_type?.value &&
                          filterData?.entity_name?.value
                          ? false
                          : true
                        : false
                    }
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleModalOpen}
                  >
                    +Add New
                  </Button>
                )}

                <GenerateForm
                  parent_id={"holidaytable"}
                  loading={saveloading}
                  open={state.open}
                  save={saveHoliday}
                  handlecancel={handleDrawerClose}
                  header={state.edit_data ? "Edit From" : "Add New Holiday"}
                  json={data}
                  handleshow={handleshowData}
                  nocancel
                  isAdd={isAdd}
                  modalStructure={classes.addNewModal}
                />
              </div>
            </div>
            <div
              id={`${parent_id}-CommonTable-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}
            >
              {console.log(listData)}
              <CommonTable
                parent_id={"holidaytable"}
                Header={[
                  "S.No",
                  "Date",
                  "Day",
                  "Holiday Reason",
                  "Holiday Type",
                  "Repeat",
                  "Status",
                  "Action",
                ]}
                dataList={listData}
                tableData={[
                  { type: ["INCRIMENT"], name: "" },
                  { type: ["TEXT"], name: "date" },
                  { type: ["TEXT"], name: "day" },
                  { type: ["TEXT"], name: "holiday_reason" },
                  { type: ["TEXT"], name: "holiday_type" },
                  { type: ["TEXT"], name: "repeat" },
                  { type: ["STATUS"], name: "status" },
                  { type: ["ACTION"], name: "action" },
                ]}
                handleCheckBox={statusChange}
                // enable={}
                handleEdit={handleEdit}
                // handleView={handleView}
                handlepageChange={(currentPage, PerPage) =>
                  handlepageChange(currentPage, PerPage)
                }
                TableCount={listData?.[0]?.total_count}
                incrementCount={page}
                showPagination={true}
                rowsPerPageOptions={[
                  { label: "10 Rows", value: 10 },
                  { label: "50 Rows", value: 50 },
                  { label: "100 Rows", value: 100 },
                ]}
              />
            </div>
            <div className={classes.footer}>
              <button
                id={`${parent_id}-entity-button`}
                className={
                  copyNewHolidayYear ? classes.confirmNote : classes.copyNote
                }
                onClick={handleModalOpenNew}
              >
                <div
                  id={`${parent_id}-View-holidays-entities-div`}
                  style={{ display: "flex" }}
                >
                  <div
                    id={`${parent_id}-View-holidays-entities-sub-div`}
                    className={classes.noteDiv}
                  >
                    {copyNewHolidayYear
                      ? "Note: Edit (or) Delete holidays as per the new calender year"
                      : "Copy Holidays to New Calendar Year"}
                  </div>
                </div>
              </button>
              {copyNewHolidayYear && (
                <Button
                  id={`${parent_id}_generateform_Save_button`}
                  disabled={props?.loading ?? false}
                  variant={"contained"}
                  color={"primary"}
                  style={{ marginTop: 20, fontFamily: "Poppins" }}
                  size={"small"}
                  contained
                  onClick={() => onConfirmation()}
                >
                  Confirm & Save
                </Button>
              )}
            </div>

            <Grid width="22%">
              <GenerateForm
                parent_id={"copyholidaytable"}
                loading={saveloading}
                open={calendarYear.open}
                save={saveHoliday}
                handlecancel={handleDrawerCloseNew}
                header="Copy to Calendar Year"
                json={[
                  {
                    componet_type: "yearSelector",
                    dateFormat: "yyyy",
                    label: "Select the year to copy",
                    state_name: "calander_year",
                    // value: state?.edit_data?.date ?? "",
                  },
                ]}
                modalStructure={classes.copyYearModal}
                nosave
                handleClick={() => onConfirmHoliday()}
              />
            </Grid>
          </div>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  practitioner_masters: state?.practitionerMasterSlice?.practitioner_masters,
  entity_name_options: state?.practitionerSlice?.get_entity_by_id?.data ?? [],
});

export default connect(mapStateToProps, actions)(withAllContexts(HolidayTable));
