import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import GeneralMaster from "./generalMaster/generalMaster";
import LocationMaster from "./locationMaster";
import GeographicMaster from "./geographicMaster";
import PatientDemograpics from "./patientDemograpics";
// import PractitionerTable from "../../components/practitionermaster/practitionertable";
// import PractitionerRoleTable from "./practitionerroletable";
import { checkWithCasbin } from "../../utils"
// import { PendingResheduleList } from './pendingRescheduleList'
import { PendingResheduleList } from './pendingRescheduleList/index'
import VisitTypeGMTable from './customGeneralMaster/visitTypeGM';
import SymtomsAndSpecialtyTable from './symptomsandspeciality';

import { CommonTabList } from "../../components/common";
import "../../components/font.css";
import GeographicMasterTable from "./GeographicMaster/table";
import CustomGeneralMaster from "./customGeneralMaster/customGeneralMaster";
import EncounterType from "./encounterType";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: theme.palette.background.gary,

    "& .MuiTab-textColorPrimary": {
      "&.Mui-selected": {
        color: "#0071F2",
      },
    },
    "&.MuiPaper-elevation4": {
      shadow: "none",
    },
    "& .MuiTab-root": {
      [theme.breakpoints.up("xs")]: {
        minWidth: "inherit !important",
      },
    },
    "& .MuiAppBar-root": {
      width: "80%",
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      "@media (min-width: 0px)": {
        minWidth: 0,
      },
    },
  },
  box: {
    padding: "24px",
    // paddingBottom: "43px",
    backgroundColor: "#F6F6F6",
    // height: "100vh",
    height: "100%",
    paddingTop: "14px",
    paddingBottom: "1px !important"
  },
}));
function AdminTabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          id={'admintabpanel-Box'}
          p={3} className={classes.box}>
          <Grid
            id={'admintabpanel-children-grid'}
          >{children}</Grid>
        </Box>
      )}
    </div>
  );
}

AdminTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [listData, setListData] = React.useState([])
  const json = {
    // patientDemograpics: {
    //   name: "Patient demograpics",
    //   component: (
    //     <PatientDemograpics
    //       parent_id={'PatientDemograpics'}
    //       type={"PATIENT_DEMOGRAPICS"}
    //       header={"Patient demograpics"}
    //     />
    //   ),
    // },
    generalMaster: {
      name: "General Master",
      component: (
        <GeneralMaster
          type={"GENERAL_MASTER"}
          header={"General Master"}
        />
      ),
    },
    // geographicHierarchy: {
    //   name: "Geographic hierarchy",
    //   component: (
    //     <GeographicMaster
    //       parent_id={'GeographicMaster'}
    //       type={"GEOGRAPHIC"}
    //       header={"Geographic hierarchy"}
    //     />
    //   ),
    // },
    geographicMaster: {
      name: "Geographic Master",
      component: (
        <GeographicMasterTable
          parent_id={'GeographicMasterTable'}
        />
      ),
    },
    location: {
      name: "Location",
      component: (
        <LocationMaster
          parent_id={'LocationMaster'}
          type={"LOCATION"}
          header={"Location"}
          val={true}
        />
      ),
    },
    // numberSeries: {
    //   name: "Number series",
    //   // component: <PractitionerRoleTable type={"QUALIFICATION"} header={"Qualification"} />
    // },
    pendingRescheduleList: {
      name: "Pending Reschedule List",
      component: (
        <PendingResheduleList
          parent_id={'PendingResheduleList'}
        />
      )
    },
    //visitTypeGM
    visitTypeGM: {
      name: "Visit Type (GM)",
      component: (
        <VisitTypeGMTable
          parent_id={'VisitTypeGMList'}
        />
      )
    },
    //symtomsAndSpecialities
    symptomsAndSpecialty: {
      name: "Symptoms & Specialities",
      component: (
        <SymtomsAndSpecialtyTable
          parent_id={'SymtomsAndSpecialitiesList'}
        />
      )
    },
    // patienttype
    patienttype: {
      name: "Patient Importance type (GM)",
      component : <CustomGeneralMaster
        disableList={["Code"]}
        uppercaseList={["Code"]} 
        type={"PATIENTIMPORTANCETYPE"}
        type_={"Patient Importance Type (GM)"}
    />
    },
    locationroletype: {
      name: "Location Role Type (GM)",
      component: <CustomGeneralMaster 
        disableList={["Code"]}
        uppercaseList={["Code"]}
        type={"LOCATIONROLE"}
        displayType={'Location Role Type(GM)'}
    />
    },
    encountertype: {
      name: "Encounter Type",
      component: <EncounterType 
        parent_id={'EncounterType'}
        header={"Encounter Type"}
      />
    }
  }
  React.useEffect(() => {
    const baseFun = async () => {
      let permission = await checkWithCasbin(["administrations"]);
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      let arr = [];
      var unique = [...permission.read, ...permission.write].filter(onlyUnique);
      unique.map(val => {
        arr.push(json[val])
      })
      
      setListData(arr)
    }
    baseFun()
  }, [])

  return (
    <Grid
      id={'admintabpanel-parent-div'}
      container spacing={0}>
      <Grid
        id={'admintabpanel-parent-grid'}
        item md={12}>
        <div
          id={'admintabpanel-parent-div'}
          className={classes.root}>
          <CommonTabList
            parent_id={'admintabpanel'}
            title="Administrations"
            list={listData}
          />
        </div>
      </Grid>
    </Grid>
  );
}
