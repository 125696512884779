import React from "react";
import { Upload, Div, H6, Text, Divider, Image, Icons } from "qdm-component-library";
import { ToBase64 } from "../../../utils";
// import FileViewer from 'react-file-viewer';
import { Modal, Grid, Typography, Button } from "@material-ui/core";

function UploadFiles(props) {
    const { uploadDocument } = props;
    const [images, setImages] = React.useState([])
    const [state, setState] = React.useState({
        openFile: null,
        viewFileName: null,
        fileType: null,
        open: false
    })
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const uploadFun = (e, data) => {
        let list = images;
        list.push(data)
        setImages(list)
        uploadDocument(list)
        forceUpdate()
    }
    const getSize = (size) => {
        let fileSize = size / 1024 / 1024;
        if (fileSize < 1) {
            return `${parseInt(size / 1024) + 1} KB`
        } else {
            return `${parseInt(fileSize)} MB`
        }
    }
    const deleteFile = (index) => {
        let list = images;
        list.splice(index, 1);
        setImages(list)
        uploadDocument(list)
        forceUpdate()
    }
    const handleClose = () => {
        setState({
            openFile: null,
            fileType: null,
            open: false
        })
        forceUpdate()
    }
    const viewFile = async (file) => {
        let data = await ToBase64(file)
        setState({
            openFile: data,
            viewFileName: file.name,
            fileType: file.type.split('/')[1],
            open: true
        })
        forceUpdate()
    }
    return <React.Fragment>
        <Div className={"up-parent"}>
            <Upload
                handleChange={uploadFun}
                label="Upload Documents"
                labelStyle={{ fontSize: 14, fontFamily: 'pc_medium !important' }}
                className={"up-btn"}
                showIcon={false}
            />

        </Div>
        {images.map((val, i) => <React.Fragment>
            {i !== 0 && <Divider style={{ margin: '0px 10px' }} />}
            <Div className="up-list-parent">
                <Div style={{ width: 22, height: 22 }}>
                    <Image src={'images/icons8-file.svg'} alt="file" />
                </Div>
                <Div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    marginLeft: 10
                }}>
                    <H6 className="up-title">{val.name}</H6>
                    <Text className="up-size">{getSize(val.size)}</Text>
                </Div>
                <Div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Image onClick={() => viewFile(val)} src="images/icons8-eye.svg" alt="view" style={{ cursor: 'pointer', marginRight: 16 }} />
                    <Image src="images/icons8-trash.svg" onClick={() => deleteFile()} alt="view" style={{ cursor: 'pointer' }} />
                </Div>
            </Div>
            <Modal
                open={state.open}
                onClose={handleClose}
                className='docc-modal'
                id="UMHit_13"
            >
                <div style={{ width: "100%", height: "100vh" }}>
                    <Grid container style={{ background: "#d3d3d3e0" }}>
                        <Grid item style={{ width: "50%" }} className={"documenthead"}>
                            <Grid>
                                <Typography id="UMHit_14" style={{ color: "black", marginTop: "9px" }}>{state.viewFileName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "50%", paddingRight: "30px" }}>
                            <Typography variant="h6" >
                                <Button>
                                    <Icons
                                        id="UMHit_15"
                                        inLineStyles={{ color: "black", fontSize: '20px ​!importan' }}
                                        className="pi-upload-doc-close"
                                        fontIcon={"close"}
                                        ariaHidden={"true"}
                                        onClick={() => handleClose()}
                                        size={"large"}
                                    />
                                </Button>
                            </Typography>
                        </Grid>

                    </Grid>
                    <Grid id="UMHit_17" className="openFile-parent">
                        {/* <FileViewer id="UMHit_16" fileType={state.fileType} filePath={state.openFile} /> */}
                    </Grid>
                </div>
            </Modal>
        </React.Fragment>)
        }

    </React.Fragment>
}

export default UploadFiles;