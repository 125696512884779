import { Button, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import { EditIcon } from "../../../../components";
import { useState } from "react";
import { useEffect } from "react";

const LoadPeriods = (props) => {
  const classes = useStyles();
  const [loadedPeriods,setLoadedPeriods] = useState([]);
  useEffect(() => {
    const filterKeys = Object.keys(props?.val);
    if(props?.type === "month"){
      const returnMonths = month.filter((val) =>  filterKeys.includes(val?.value));
      setLoadedPeriods(returnMonths)
    }else if(props?.type === "week"){
      const returnWeek = Week.filter((val) => filterKeys.includes(val?.value));
      setLoadedPeriods(returnWeek)
    }else{
      const reuturnDays = Days.filter((val) => filterKeys.includes(val?.value));
      setLoadedPeriods(reuturnDays)
    }
  }, []);
  return(
    <div className={classes.align}>
      {loadedPeriods.map((values, i) => (
        <Typography className={classes.infoTypo}>
          {(i === loadedPeriods.length - 1 && loadedPeriods.length > 1
            ? " & "
            : "") +
            values.label +
            (i === loadedPeriods.length - 1 ||
            i === loadedPeriods.length - 2
              ? ""
              : ", ")}
        </Typography>
      ))}
    </div>
  )
}

const OverRideDetail = (props) => {
  const classes = useStyles();

  const { resourceList, handleDelete = () => null,handleEdit = () => null } = props;
  return (
    <div className={classes.box} style={{ padding: 16 }}>
      <Typography className={classes.headerTypo}>
        Override Schedule Detail (Schedule Name)
      </Typography>
      {resourceList?.length > 0 && <div
        className={classes.align}
        style={{ justifyContent: "space-between", paddingTop: "8px" }}
      >
        <div className={classes.align} style={{ gap: "20px" }}>
          <div className={classes.align} style={{ gap: "8px" }}>
            <img src="/images/icons8-hospital-3.svg" />
            <Typography className={classes.subHeaderTypo}>
              Apollo Hospital Coimbatore
            </Typography>
          </div>
          <div className={classes.align} style={{ gap: "8px" }}>
            <img src="/images/icons8-identification-documents.svg" />
            <Typography className={classes.subHeaderTypo}>
              Practitioner
            </Typography>
          </div>
          <div className={classes.align} style={{ gap: "8px" }}>
            <img src="/images/Group 94306.svg" />
            <Typography className={classes.subHeaderTypo}>
              Consultant
            </Typography>
          </div>
          <div className={classes.align} style={{ gap: "8px" }}>
            <img src="/images/icons8-doctor-male.svg" />
            <Typography className={classes.subHeaderTypo}>
              Doctor1, Doctor2 & Doctor3
            </Typography>
          </div>
        </div>
        <div>
          <Button
            className={classes.noBtn}
            onClick={props?.handleOverRide}
            contained
          >
            +Add New
          </Button>
        </div>
      </div>}

      {resourceList?.map((val, i) => (
        <div
          className={classes.align}
          key={val?.id}
          style={{
            backgroundColor: "#fff",
            border: "1px solid #DCDCDC",
            borderRadius: "10px",
            margin: "12px 0px",
            justifyContent: "space-between",
          }}
        >
          <div className={classes.align}>
            <div
              className={classes.align}
              style={{
                gap: 8,
                padding: "16px",
                borderRight: "1px solid #DCDCDC",
              }}
            >
              <Typography className={classes.headerTypo}>({i + 1})</Typography>
              <div className={classes.align}>
                <LoadPeriods type="month" val={val} />
              </div>
              <div className={classes.dot}></div>
              <div className={classes.align}>
                <LoadPeriods type="week" val={val} />
              </div>
              <div className={classes.dot}></div>
              <div className={classes.align}>
                <LoadPeriods type="day" val={val} />
              </div>
            </div>
            <div className={classes.align} style={{ gap: 8, padding: "16px" }}>
              <Typography className={classes.headerTypo}>TIMINGS</Typography>
              {val?.appointmentList?.map((value,index) => (
                <>
                  <div className={classes.align}>
                    <Typography className={classes.infoTypo}>
                      {value?.start_time} - {value?.end_time}
                    </Typography>
                  </div>
                  {((val?.appointmentList?.length - 1) !== index) &&  <div className={classes.dot}></div>}
                </>
              ))}
            </div>
          </div>
          <div
            className={classes.align}
            style={{ marginRight: "16px", gap: "8px" }}
          >
            <div onClick={() => handleEdit(i,val)} style={{ cursor: "pointer" }}><EditIcon color="#6A7888" /></div>
            <div onClick={() => handleDelete(i)} style={{ cursor: "pointer" }}>
              <img src="/images/icons8-trash.svg" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OverRideDetail;

const month = [
  { value: "Month1", label: "Jan", disabled: false, name: "Jan" },
  { value: "Month2", label: "Feb", disabled: false, name: "Feb" },
  { value: "Month3", label: "Mar", disabled: false, name: "Mar" },
  { value: "Month4", label: "Apr", disabled: false, name: "Apr" },
  { value: "Month5", label: "May", disabled: false, name: "May" },
  { value: "Month6", label: "Jun", disabled: false, name: "Jun" },
  { value: "Month7", label: "Jul", disabled: false, name: "Jul" },
  { value: "Month8", label: "Aug", disabled: false, name: "Aug" },
  { value: "Month9", label: "Sep", disabled: false, name: "Sep" },
  { value: "Month10", label: "Oct", disabled: false, name: "Oct" },
  { value: "Month11", label: "Nov", disabled: false, name: "Nov" },
  { value: "Month12", label: "Dec", disabled: false, name: "Dec" },
];
const Week = [
  { value: "Week1", label: "Week 1", disabled: false, name: 1 },
  { value: "Week2", label: "Week 2", disabled: false, name: 2 },
  { value: "Week3", label: "Week 3", disabled: false, name: 3 },
  { value: "Week4", label: "Week 4", disabled: false, name: 4 },
  { value: "Week5", label: "Week 5", disabled: false, name: 5 },
];
const Days = [
  { value: "Day1", label: "Sun", disabled: false, name: "Sunday" },
  { value: "Day2", label: "Mon", disabled: false, name: "Monday" },
  { value: "Day3", label: "Tue", disabled: false, name: "Tuesday" },
  { value: "Day4", label: "Wed", disabled: false, name: "Wednesday" },
  { value: "Day5", label: "Thu", disabled: false, name: "Thursday" },
  { value: "Day6", label: "Fri", disabled: false, name: "Friday" },
  { value: "Day7", label: "Sat", disabled: false, name: "Saturday" },
];
const Holidays = []