import React, { useEffect } from "react";

import CreateAppointmentType from "../components/availabilitymanagement/createAppointmentTypes/CreateAppointmentTypes";
import createNewAppointment from "../components/availabilitymanagement/createNewAppointment";
import CreatePatientCategory from "../components/availabilitymanagement/createAppointmentTypes/CreatePatientCategory";
import ResourceDefinitionCard from "../components/availabilitymanagement/createAppointmentTypes/resourceDefinition";
//import GeneralAppointmentInstructions from "../components/availabilitymanagement/createAppointmentTypes/generalAppointmentInstructions";
import { useState } from "react";
import { Button } from "@material-ui/core";
import SpecialAppoitmentInstructions from "../components/availabilitymanagement/createAppointmentTypes/specialAppoitmentInstructions";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { ChildCare } from "@material-ui/icons";

function Check() {
  const [state, setState] = useState({
    code: "",
    longdes: "",
    shortdes: "",
    Patientcategory: [],
    Instructioncode: "",
    Description: "",
    ResourceTypeList: [],
    ResourceRole: [],
    ResourceDepartment: [],
    ResourceName: [],
    Resourse: [],
    SpecialAppointments: [],
    curreteditresourceindex: "",
    CriteriaDoc: [],
  });
  const [buttonClick, setButtonClick] = useState();

  const savefunction = () => {
    debugger;
    let data = {
      type: state.ResourceTypeList,
      role: state.ResourceRole,
      department: state.ResourceDepartment,
      namelist: state.ResourceName,
    };
    console.log("ssss", { ...state, Resourse: [...state.Resourse, data] });
    // setState({ ...state, Resourse: [...state.Resourse, data] });
    setState({
      ...state,
      Resourse: [...state.Resourse, data],
      ResourceName: [],
      ResourceDepartment: [],
      ResourceRole: [],
      ResourceTypeList: [],
    });
  };
  const specialSave = () => {
    let information = {
      code: state.Instructioncode,
      desc: state.Description,
      criteria: state.CriteriaDoc,
    };
    console.log("loppppp", {
      ...state,
      SpecialAppointments: [...state.SpecialAppointments, information],
    });
    setState({
      ...state,
      SpecialAppointments: [...state.SpecialAppointments, information],
      Instructioncode: "",
      CriteriaDoc: [],
    });
  };

  const handleUpdate = () => {
    debugger;
    let Allresource = state.Resourse;
    Allresource[state.curreteditresourceindex].namelist = state.ResourceName;
    Allresource[state.curreteditresourceindex].department =
      state.ResourceDepartment;
    Allresource[state.curreteditresourceindex].role = state.ResourceRole;
    Allresource[state.curreteditresourceindex].type = state.ResourceTypeList;
    console.log({ Allresource });
    setState({
      ...state,
      Resourse: Allresource,
      curreteditresourceindex: "",
      ResourceName: [],
      ResourceDepartment: [],
      ResourceRole: [],
      ResourceTypeList: [],
    });
  };

  const deleteSpecial = (obj) => {
    console.log("obj", obj);
    let specialArray = state.SpecialAppointments;
    const resultOfSpecial = specialArray.filter((v, vIndex) => vIndex !== obj);
    setState({ ...state, SpecialAppointments: resultOfSpecial });
  };

  const deleteResource = (objj) => {
    let resourceArray = state.Resourse;
    const resultOfResource = resourceArray.filter(
      (v, rIndex) => rIndex !== objj
    );
    setState({ ...state, Resourse: resultOfResource });
  };
  const handlechange = (key, value, Autocomp, order) => {
    console.log(key, value);
    debugger;
    if (typeof key === "object") {
      setState({
        ...state,
        ...key,
      });
    } else {
      if (typeof value === "string") {
        setState({ ...state, [key]: value });
      } else if (typeof value === "object") {
        if (Array.isArray(value)) {
          if (Autocomp) {
            setState({
              ...state,
              [key]: value?.[0] ?? null,
            });
          } else {
            setState({
              ...state,
              [key]: [...state?.[key], ...value],
            });
          }
        } else {
          setState({
            ...state,
            [key]: { ...state?.[key], ...value },
          });
        }
      }
    }
  };
  const handleEdit = (obj, i) => {
    console.log(
      "piee",

      obj.namelist,
      obj.department,
      obj.role,
      obj.type,
      i
    );
    setState({
      ...state,
      ResourceName: obj.namelist,
      ResourceDepartment: obj.department,
      ResourceRole: obj.role,
      ResourceTypeList: obj.type,
      curreteditresourceindex: i.toString(),
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    debugger;
    let result = dispatch(actions.RES_PATIENT_CATEGORY_LIST_DOC());
    result
      .then((res) => {
        console.log(res, "one");
      })
      .catch((err) => console.log());
    let results = dispatch(actions.RES_RESOURCE_TYPE_LIST_DOC());
    results
      .then((res) => {
        console.log(res, "two");
      })
      .catch((err) => console.log());
    let total = dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC());
    total.then((res) => {
      console.log(res, "three");
    });
    let info = dispatch(actions.RES_RESOURCE_DEPARTMENT_LIST_DOC());
    info.then((res) => {
      console.log(res, "four");
    });
    let entire = dispatch(actions.RES_RESOURCE_NAME_LIST_DOC());
    entire.then((res) => {
      console.log(res, "five");
    });
    let overall = dispatch(actions.RES_SPECIAL_INSTRUCTION_LIST_DOC());
    overall.then((res) => {
      console.log(res, "six");
    });

    let sumAll = dispatch(actions.RES_CRITERIA_LIST_DOC());
    sumAll.then((res) => {
      console.log(res, "seven");
    });

    console.log("lpo", actions);
    console.log("mop", Selector.Patientcategory, result);
    return () => {};
  }, []);

  const Selector = useSelector((state) => ({
    ResourceTypeList:
      state.resourceByAppointmentTypesSlice?.res_resource_type_list_doc?.data,
    Patientcategory:
      state.resourceByAppointmentTypesSlice?.res_patient_category_list_doc
        ?.data,
    ResourceRole:
      state.resourceByAppointmentTypesSlice?.res_resource_role_list_doc?.data,
    ResourceDepartment:
      state.resourceByAppointmentTypesSlice?.res_resource_department_list_doc
        ?.data,
    ResourceName:
      state.resourceByAppointmentTypesSlice?.res_resource_name_list_doc?.data,
    Instructioncode:
      state.resourceByAppointmentTypesSlice?.res_special_instruction_list_doc
        ?.data,
    CriteriaDoc:
      state.resourceByAppointmentTypesSlice?.res_criteria_list_doc?.data,
  }));

  console.log("mango", Selector);

  console.log("ji", state);
  // console.log("ki", savefunction);
  return (
    <>
      {/* <Navbar /> */}
      <CreateAppointmentType handlechange={handlechange} state={state} />
      <CreatePatientCategory
        SelectorPatientAuto={Selector.Patientcategory}
        handlechange={handlechange}
        state={state}
      />
      {/* <CreateServiceDefinition /> */}
      <ResourceDefinitionCard
        handleEdit={handleEdit}
        handlechange={handlechange}
        state={state}
        ResourceTypeAuto={Selector.ResourceTypeList}
        ResourceRoleAuto={Selector.ResourceRole}
        ResourceDepartmentAuto={Selector.ResourceDepartment}
        ResourceNameAuto={Selector.ResourceName}
        savefunction={savefunction}
        deleteResource={deleteResource}
        handleUpdate={handleUpdate}
        setState={setState}
      />

      {/* <GeneralAppointmentCard /> */}
      {/* <SpecialAppoitmentInstructions
        handlechange={handlechange}
        state={state}
        InstructionCodeAuto={Selector.Instructioncode}
        specialSave={specialSave}
        deleteSpecial={deleteSpecial}
        criteriaAuto={Selector.CriteriaDoc}
      /> */}

      <div
        style={{
          display: "flex",
          marginTop: "18px",
          float: "right",
          paddingBottom: "16px",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          // onClick={handleClose}
          button
          disableTouchRipple
          style={{ marginRight: "20px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          // onClick={handleClose}
          button
          disableTouchRipple
          style={{ marginRight: "52px" }}
        >
          Save
        </Button>
      </div>
    </>
  );
}
export default Check;
