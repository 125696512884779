import React from "react";
import {
  Div,
  Paper,
  //Checkbox,
  H6,
  Col,
  Row,
  //Select,
  TextInput,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
// import Chip from '@material-ui/core/Chip';
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";

class GeoLocationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedOptions: [],
    };
  }
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
  };

  setDetails = (name, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let { patientData } = this.props;
    patientData.communication[name] = value;
    patientData.setPatientData({
      ...patientData,
    });
  };

  setDetailslanguage = (name, value) => {
    let { patientData } = this.props;
    if (patientData.communication?.clanguage?.length > 0) {
      const seen = new Set();
      const filteredArr = value.filter((el) => {
        const duplicate = seen.has(el.value);
        seen.add(el.value);
        return !duplicate;
      });

      patientData.communication[name] = filteredArr;
      patientData.setPatientData({
        ...patientData,
      });
      return;
    }

    patientData.communication[name] = value;
    patientData.setPatientData({
      ...patientData,
    });
  };
  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
        label: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  render() {
    //let { communication } = this.props.patientData;
    const { labelStyle, borderStyle,
       //outlinedButton, containedButton 
      } =
      this.styles;
    const { parent_id } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-title-div`}
          >
            <H6
              id={`${parent_id}-title-h6`}
              className="pi-title">GEO LOCATION</H6>
          </Div>
          <Row
            id={`${parent_id}-parent-row`}
            className="pi-actions-root">
            <Col
              id={`${parent_id}-longitude-col`}
              md={4} lg={4} sm={12} xs={12} inLineStyles={{ padding: 5 }}>
              <TextInput
                id={`${parent_id}-longitude-textInput`}
                placeholder="Longitude"
                onChange={(e) =>
                  this.props.onTextChangeState("longitude", e.target.value, 30)
                }
                value={this.props.longitude ? this.props.longitude : ""}
                label="Longitude"
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor="#0071F2"
                maxLength="30"
              />
            </Col>
            <Col
              id={`${parent_id}-latitude-col`}
              md={4} lg={4} sm={12} xs={12} inLineStyles={{ padding: 5 }}>
              <TextInput
                id={`${parent_id}-latitude-textInput`}
                placeholder="Latitude"
                onChange={(e) =>
                  this.props.onTextChangeState("latitude", e.target.value, 30)
                }
                value={this.props.latitude ? this.props.latitude : ""}
                label="Latitude"
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor="#0071F2"
                maxLength="30"
              />
            </Col>
            <Col
              id={`${parent_id}-altitude-col`}
              md={4} lg={4} sm={12} xs={12} inLineStyles={{ padding: 5 }}>
              <TextInput
                id={`${parent_id}-altitude-textInput`}
                placeholder="Altitude"
                onChange={(e) =>
                  this.props.onTextChangeState("altitude", e.target.value, 30)
                }
                value={this.props.altitude ? this.props.altitude : ""}
                label="Altitude"
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor="#0071F2"
                maxLength="30"
              />
            </Col>
          </Row>
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(GeoLocationCard);
